import { Component, OnInit, Renderer2 } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { environment } from "../environments/environment";
// Moment timezone
import Moment from "moment-timezone";
import { defineLocale } from "ngx-bootstrap/chronos";
import { BsLocaleService } from "ngx-bootstrap/datepicker";
import {
  arLocale,
  deLocale,
  enGbLocale,
  esLocale,
  frLocale,
  nlLocale,
  ruLocale,
  zhCnLocale,
} from "ngx-bootstrap/locale";
// Models
import { AuthenticationService } from "./services/authentication/authentication.service";
import { filter, map, tap } from "rxjs/operators";
import { NavigationEnd, Router, ActivatedRoute, ResolveEnd, ResolveStart } from "@angular/router";
import { Title } from "@angular/platform-browser";
import { NgSelectConfig } from "@ng-select/ng-select";
import { setTheme } from "ngx-bootstrap/utils";
import { timer } from "rxjs";
import { ColorService } from "./services/common/color.service";

window["moment"] = Moment;

defineLocale("nl", nlLocale);
defineLocale("de", deLocale);
// defineLocale('en', ); // when undefined it takes default
defineLocale("gb", enGbLocale);
defineLocale("ug", enGbLocale);
defineLocale("ar", arLocale);
defineLocale("ru", ruLocale);
defineLocale("es", esLocale);
defineLocale("fr", frLocale);
defineLocale("cn", zhCnLocale);

@Component({
  selector: "fh-app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent implements OnInit {
  title = "fleet-management";
  isLoading = true;
  previousUrl: string;

  countSub$ = timer(0, 1000).pipe(
    tap((_) => (window["reloadTime"] = window["reloadTime"] || 1)),
    map((_) => --window["reloadTime"])
  );

  constructor(
    private config: NgSelectConfig,
    private colorService: ColorService,
    private localeService: BsLocaleService,
    private renderer: Renderer2,
    private translate: TranslateService,
    private authenticationService: AuthenticationService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title
  ) {
    this.translate.setDefaultLang("en");
    setTheme("bs4"); // or 'bs5'

    this.checkRoute();

    // Redirect SSO callback url for development only
    if (!environment.production && window["server_variables"].SSOEnabled) {
      let callBackUrl = window["server_variables"].SSORedirectUrl.replace(window.location.origin, "");
      if (window.location.pathname == callBackUrl || window.location.search.includes("code")) {
        if (callBackUrl == "") {
          callBackUrl = "/Auth-callback";
        }
        console.log(`Redirect SSO callback: from ${callBackUrl}`);
        const params = new URLSearchParams(window.location.search);
        // Retrieve the 'code', 'search' and 'iss' parameters
        const code = params.get("code");
        const state = params.get("state");
        const iss = params.get("iss");
        this.router.navigate([callBackUrl], { queryParams: { code: code, state: state, iss: iss } });
      }
    }

    // Set the token and handle refresh logic globally
    $(document).on("ajaxSend", (event, req: JQueryXHR, settings: JQueryAjaxSettings) => {
      var origXhrFunc = settings.xhr;

      settings.xhr = () => {
        var xhr = origXhrFunc();

        // Store the original send function
        var origSend = xhr.send;

        // Override the send function to delay its execution
        xhr.send = (...args) => {
          if (xhr.readyState === 1) {
            // Add token to request headers if it's available
            const isValid = this.authenticationService.isTokenValid();
            const isSSO = this.authenticationService.user !== null;

            if (isSSO == false && !isValid) {
              console.log("Token not valid anymore.. getting refresh");
              const isImpersonated = this.authenticationService.getIsImpersonated();

              // Return a new observable to handle the token refresh logic
              this.authenticationService.refreshTokenHandler(settings.url, isImpersonated).subscribe({
                next: (result) => {
                  if (result) {
                    // Update the authorization header on the xhr object directly
                    xhr.setRequestHeader("Authorization", "Bearer " + this.authenticationService.getAuthToken());
                    origSend.apply(xhr, args);
                  }
                },
                error: (error) => {
                  console.error("Error during token refresh", error);
                },
              });
            } else {
              // Token is still valid, directly set the authorization header and send the request
              let token = this.authenticationService.getAuthToken();
              if (this.authenticationService.user) {
                token = this.authenticationService.user.id_token;
              }
              xhr.setRequestHeader("Authorization", "Bearer " + token);
              origSend.apply(xhr, args);
            }
          }
        };
        return xhr;
      };
    });

    this.config.notFoundText = "No data found";
    // this.config.appendTo = 'body';
    // this.config.bindValue = 'value';

    if (!translate.currentLang) {
      let currentSavedLang = this.authenticationService.getCultureLang();
      console.log(currentSavedLang);

      if (currentSavedLang === "" || currentSavedLang == null) {
        currentSavedLang = "en";
      }

      if (this.authenticationService.environment.Debug) {
        console.log("No currentlang... setting : " + currentSavedLang);
      }

      translate.use(currentSavedLang);
      Moment.locale(currentSavedLang);
      this.localeService.use(currentSavedLang);

      translate.onLangChange.subscribe((event) => {
        Moment.locale(event.lang);
        this.localeService.use(event.lang);
        if (event.lang === "ur" || event.lang === "ar") {
          this.renderer.addClass(document.body, "rtls");
        } else {
          this.renderer.removeClass(document.body, "rtls");
        }
      });
    }

    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => {
          let child = this.activatedRoute.firstChild;
          while (child) {
            if (child.firstChild) {
              child = child.firstChild;
            } else if (child.snapshot.data && child.snapshot.data["title"]) {
              const id = child.snapshot && child.snapshot.params && child.snapshot.params["id"];
              return "[" + child.snapshot.data["title"] + (id ? ": " + id : "") + "]";
            } else {
              return null;
            }
          }
          return null;
        })
      )
      .subscribe((data: any) => {
        if (data) {
          this.titleService.setTitle(data + " - [Fleet Management]");
        }
      });
  }

  checkRoute() {
    // Check if path is recognized and force theme based on url.
    // When this is loaded it will overwrite the logged in theme and force the url theme
    const url = window.location.origin;

    let overwriteWhitelabel = "";
    overwriteWhitelabel = this.colorService.getOverwriteTheme(url);

    if (overwriteWhitelabel !== "") {
      console.log("Overwriting whitelabel with path " + url + " setting theme " + overwriteWhitelabel);

      localStorage.setItem(AuthenticationService.WHITELABEL_IMPERSONATE, overwriteWhitelabel);
      localStorage.setItem(AuthenticationService.WHITELABEL, overwriteWhitelabel);

      this.colorService.getThemeColors(overwriteWhitelabel).subscribe((result) => {
        this.colorService.addTheme(result);
      });
    }
  }

  ngOnInit() {
    this.router.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
      }
      if (e instanceof ResolveStart) {
      }
      if (e instanceof ResolveEnd) {
        this.isLoading = false;
      }
    });
  }
}
