<div class="row">
    <div class="col-lg-12">
        <fh-error [success]="success" [error]="error"></fh-error>
    </div>
</div>

<div class="row">
    <div class="col-lg-12">
        <div class="ibox" style="position: relative">
            <div class="ibox-title">
                <h5><i class="fas fa-fw fa-garage-car"></i> {{ "general.workOrder" | translate }}</h5>
            </div>
            <div class="ibox-content slick" style="position: relative">
                <ngx-loading [show]="loading"></ngx-loading>
                <table style="width: 100%" class="table table-fixed nowrap dtr-inline no-footer">
                    <tbody *ngIf="!workOrder">
                        <tr>
                            <td colspan="2">
                                {{ "general.noMaintenance" | translate }}
                            </td>
                        </tr>
                    </tbody>
                    <tbody *ngIf="workOrder">
                        <ng-container *ngIf="formMode != 2">
                            <tr>
                                <td class="row_opening_small">
                                    {{ "general.resellerDescription" | translate }}
                                </td>
                                <td class="padding-readonly-correct row_content">
                                    <a
                                        class="secondary link_bolder"
                                        href="/#/ResellerDetails/Index/{{ workOrder?.resellerId }}"
                                        >{{ workOrder?.resellerDescription }}</a
                                    >
                                </td>
                                <td class="row_opening_small">
                                    {{ "general.companyName" | translate }}
                                </td>
                                <td class="row_content padding-readonly-correct">
                                    <a
                                        class="secondary link_bolder"
                                        href="/#/AccountDetails/Index/{{ workOrder?.accountId }}"
                                        >{{ workOrder?.companyName }}</a
                                    >
                                </td>
                            </tr>
                            <tr>
                                <td class="row_opening_small">
                                    {{ "general.asset" | translate }}
                                </td>
                                <td colspan="3" class="row_content padding-readonly-correct">
                                    <a
                                        class="secondary link_bolder"
                                        href="/#/DeviceDetails/Index/{{ workOrder?.deviceId }}"
                                        >{{ workOrder?.assetName }}</a
                                    >
                                </td>
                            </tr>
                        </ng-container>
                        <ng-container *ngIf="formMode == 2">
                            <tr>
                                <td class="row_opening_small">
                                    {{ "general.reseller" | translate }}
                                    <span class="error" *ngIf="resellerVar.invalid">*</span>
                                </td>
                                <td class="row_content" style="position: relative; overflow: visible">
                                    <fh-reseller-input
                                        name="reseller"
                                        #resellerVar="ngModel"
                                        (onChanged)="resellerChanged($event)"
                                        required
                                        [tableInline]="true"
                                        placeholder="{{ 'general.chooseReseller' | translate }}"
                                        [(ngModel)]="selectedResellerId"
                                    >
                                    </fh-reseller-input>
                                </td>
                                <td class="row_opening_small">
                                    {{ "general.account" | translate }}
                                    <span class="error" *ngIf="accountVar.invalid">*</span>
                                </td>
                                <td class="row_content" style="position: relative; overflow: visible">
                                    <fh-account-input
                                        name="account"
                                        #accountVar="ngModel"
                                        (onChanged)="accountChanged($event)"
                                        required
                                        [tableInline]="true"
                                        [selectedResellerId]="selectedResellerId"
                                        placeholder="{{
                                            (selectedResellerId
                                                ? 'general.chooseAccount'
                                                : 'general.firstChooseReseller'
                                            ) | translate
                                        }}"
                                        [(ngModel)]="workOrder.accountId"
                                    >
                                    </fh-account-input>
                                </td>
                            </tr>
                            <tr>
                                <td class="row_opening_small">
                                    {{ "general.asset" | translate }}
                                    <span class="error" *ngIf="!(workOrder.assetId > 0)">*</span>
                                </td>
                                <td colspan="3" class="row_content" style="position: relative; overflow: visible">
                                    <fh-asset-chooser
                                        name="asset"
                                        [assetId]="workOrder.assetId"
                                        [accountId]="workOrder.accountId"
                                        [formMode]="formMode"
                                        [activeOnly]="true"
                                        (onAssetChanged)="handleAssetChanged($event)"
                                    >
                                    </fh-asset-chooser>
                                </td>
                            </tr>
                        </ng-container>

                        <tr>
                            <td class="row_opening_small">
                                {{ "general.odo" | translate }}
                                <span class="error" *ngIf="odoMeter.invalid && formMode != 1">*</span>
                            </td>
                            <td class="row_content">
                                <input
                                    type="number"
                                    [readonly]="formMode == 1"
                                    required
                                    name="odoMeter"
                                    class="form-control table-inline"
                                    [(ngModel)]="workOrder.odometer"
                                    #odoMeter="ngModel"
                                />
                            </td>
                            <td class="row_opening_small">
                                {{ "general.engineHours" | translate }}
                                <span class="error" *ngIf="engineHours.invalid && formMode != 1">*</span>
                            </td>
                            <td class="row_content">
                                <input
                                    type="number"
                                    [readonly]="formMode == 1"
                                    required
                                    name="engineHours"
                                    class="form-control table-inline"
                                    [(ngModel)]="workOrder.engineHours"
                                    #engineHours="ngModel"
                                />
                            </td>
                        </tr>
                        <tr>
                            <td class="row_opening_small">
                                {{ "general.completed" | translate }}
                                <span class="error" *ngIf="completed.invalid && formMode != 1">*</span>
                            </td>
                            <td class="row_content">
                                <div class="fa_checkbox">
                                    <input
                                        id="completed"
                                        name="completed"
                                        type="checkbox"
                                        [disabled]="formMode == 1"
                                        [(ngModel)]="workOrder.completed"
                                        #completed="ngModel"
                                    />
                                    <label for="completed"> </label>
                                </div>
                            </td>
                            <td class="row_opening_small">
                                {{ "general.occurrenceDate" | translate }}
                                <span class="error" *ngIf="serviceDate.invalid && formMode != 1">*</span>
                            </td>
                            <td class="row_content">
                                <input
                                    type="text"
                                    [disabled]="formMode == 1"
                                    name="serviceDate"
                                    #serviceDate="ngModel"
                                    class="form-control table-inline"
                                    [ngModel]="workOrder.serviceDate | amDateFormat : 'lll'"
                                    (ngModelChange)="workOrder.serviceDate = $event"
                                    placeholder="{{ 'placeholder.occurrenceDate' | translate }}"
                                    [bsConfig]="dpConfig"
                                    required
                                    bsDatepicker
                                    autocomplete="off"
                                    #serviceDateDp="bsDatepicker"
                                />
                                <span class="input-group-calendar"
                                    ><i class="fa fa-calendar-alt" (click)="serviceDateDp.toggle()"></i
                                ></span>
                            </td>
                        </tr>
                        <tr>
                            <td class="row_opening_small">
                                {{ "general.description" | translate }}
                                <span class="error" *ngIf="description.invalid && formMode != 1">*</span>
                            </td>
                            <td colspan="3">
                                <textarea
                                    [readonly]="formMode == 1"
                                    name="description"
                                    #description="ngModel"
                                    required
                                    class="form-control noresize"
                                    style="height: 100px; width: 100%; resize: none"
                                    [(ngModel)]="workOrder.description"
                                >
                                </textarea>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <fh-save-footer
                [valid]="!form.invalid && workOrder?.workOrderItems.length > 0"
                (onInsert)="onInsert()"
                [formMode]="formMode"
                [allowEdit]="permissions['Maintenances_Edit']"
                [allowDelete]="permissions['Maintenances_Delete']"
                (setFormMode)="setFormMode($event)"
                (onSave)="onSave()"
                (onDelete)="onDelete()"
            >
            </fh-save-footer>
        </div>
    </div>

    <div class="col-lg-12">
        <div class="ibox" style="position: relative">
            <div class="ibox-title">
                <h5><i class="fas fa-fw fa-wrench-simple"></i> {{ "general.workOrderItems" | translate }}</h5>
                <span class="float-right"
                    ><button
                        type="button"
                        class="btn btn-primary btn-grid"
                        [disabled]="formMode == 1 || loading"
                        (click)="addWorkOrderItem()"
                    >
                        <i class="fas fa-fw fa-plus"></i></button
                ></span>
            </div>
            <div class="ibox-content slick" style="position: relative; overflow-y: auto">
                <ngx-loading [show]="loading"></ngx-loading>
                <table style="width: 100%" class="table nowrap dtr-inline">
                    <thead>
                        <tr>
                            <th>{{ "general.maintenanceType" | translate }}</th>
                            <th>{{ "general.description" | translate }}</th>                                                        
                            <th style="text-align: right;">{{ "general.partNumber" | translate }}</th>
                            <th style="text-align: right;">{{ "general.materialReceiptInspectionVoucherNumber" | translate }}</th>
                            <th style="text-align: right;">{{ "general.localPurchaseOrderNumber" | translate }}</th>
                            <th style="text-align: right;">{{ "general.supplierName" | translate }}</th>
                            <th style="text-align: right;">{{ "general.remarks" | translate }}</th>
                            <th style="text-align: right">{{ "general.itemPrice" | translate }}</th>
                            <th style="text-align: right">{{ "general.quantity" | translate }}</th>
                            <th style="text-align: right">{{ "general.laborCost" | translate }}</th>
                            <th style="text-align: right;">{{ "general.discount" | translate }}</th>
                            <th style="text-align: right">{{ "general.total" | translate }}</th>
                            <th style="min-width: 58px"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngIf="workOrder?.workOrderItems?.length == 0 && !loading">
                            <td colspan="7">{{ "general.noDataFound" | translate }}</td>
                        </tr>
                        <tr *ngFor="let item of workOrder?.workOrderItems; let i = index">
                            <ng-container>
                                <td
                                    class="elipses_overflow"
                                    style="max-width: 150px"
                                    *ngIf="item.serviceItemId && item.serviceItemName == undefined; else workOrderItem"
                                >
                                    <span>{{ "general.serviceItemDeleted" | translate }}</span>
                                </td>
                                <ng-template #workOrderItem>
                                    <td *ngIf="item.serviceItemId && item.serviceItemName; else unplannedItem">
                                        <a
                                            class="secondary link_bolder"
                                            href="/#/MaintenancesV2/ServiceItems/Details/Index/{{
                                                item?.serviceItemId
                                            }}"
                                            >{{ item?.serviceItemName }}</a
                                        >
                                    </td>
                                    <ng-template #unplannedItem>
                                        <td>-</td>
                                    </ng-template>
                                </ng-template>
                            </ng-container>
                            <td class="elipses_overflow" style="max-width: 150px">
                                {{ item.description }}
                            </td>                            
                            <td style="text-align: right">{{ item.partNumber }}</td>
                            <td style="text-align: right">{{ item.materialReceiptInspectionVoucherNumber }}</td>
                            <td style="text-align: right">{{ item.localPurchaseOrderNumber }}</td>
                            <td style="text-align: right">{{ item.supplierName }}</td>
                            <td style="text-align: right">{{ item.remarks }}</td>
                            <td style="text-align: right">{{ roundAsString(item.itemPrice, 2) }}</td>
                            <td style="text-align: right">{{ item.quantity }}</td>
                            <td style="text-align: right">{{ roundAsString(item.laborCost, 2) }}</td>
                            <td style="text-align: right">{{ roundAsString(item.discount, 2) }}</td>
                            <td style="text-align: right">
                                {{ roundAsString(item.quantity * item.itemPrice + item.laborCost - item.discount, 2) }}
                            </td>
                            <td>
                                <button
                                    class="btn btn-danger btn-grid float-right"
                                    [disabled]="formMode == 1"
                                    (click)="showModal(item.id)"
                                >
                                    <i class="fa fa-fw fa-trash-alt"></i>
                                </button>

                                <button
                                    class="btn btn-primary btn-grid float-right"
                                    [disabled]="formMode == 1"
                                    (click)="editWorkOrderItem(item)"
                                >
                                    <i class="fa fa-fw fa-edit"></i>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                    <tfoot *ngIf="formMode != 2">
                        <tr>
                            <td id="total" colspan="11">{{ "general.cost" | translate }}</td>
                            <td style="text-align: right">{{ roundAsString(workOrder?.totalCost, 2) }}</td>
                            <td></td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </div>

        <form #addItem="ngForm">
            <div *ngIf="workOrderItem" class="ibox" style="position: relative">
                <div class="ibox-title">
                    <h5><i class="fas fa-fw fa-wrench-simple"></i> {{ "general.workOrderItem" | translate }}</h5>
                </div>
                <div class="ibox-content slick" style="position: relative">
                    <ngx-loading [show]="loading"></ngx-loading>
                    <table style="width: 100%" class="table nowrap dtr-inline no-footer">
                        <tbody>
                            <tr *ngIf="!(editServiceItem && editItem)">
                                <td class="row_opening_small">{{ "general.maintenanceType" | translate }}</td>
                                <td class="row_content" style="overflow: visible">
                                    <ng-select
                                        [searchable]="false"
                                        [clearable]="true"
                                        [multiple]="false"
                                        [(ngModel)]="workOrderItem.serviceItemId"
                                        [bindLabel]="'serviceItemName'"
                                        [bindValue]="'serviceItemId'"
                                        placeholder="{{ 'general.chooseServiceItem' | translate }}"
                                        [name]="'serviceItem'"
                                        #serviceItem="ngModel"
                                        class="form-control table-inline"
                                    >
                                        <ng-option
                                            *ngFor="let serviceItem of serviceItems | orderBy : 'name' : false"
                                            [value]="serviceItem.id"
                                        >
                                            <i class="fas fa-fw fa-screwdriver-wrench"></i> {{ serviceItem.name }}
                                        </ng-option>
                                    </ng-select>
                                </td>
                            </tr>
                            <tr>
                                <td class="row_opening_small">
                                    {{ "general.description" | translate }}
                                    <span class="error" *ngIf="description.invalid && formMode != 1">*</span>
                                </td>
                                <td>
                                    <textarea
                                        [readonly]="formMode == 1"
                                        [name]="'description'"
                                        #description="ngModel"
                                        required
                                        class="form-control noresize"
                                        style="height: 100px; width: 100%; resize: none"
                                        [(ngModel)]="workOrderItem.description"
                                    >
                                    </textarea>
                                </td>
                            </tr>                            
                            <tr>
                                <td class="row_opening_small">
                                    {{ "general.remarks" | translate }}
                                    <span class="error" *ngIf="remarks.invalid && formMode != 1">*</span>
                                </td>
                                <td>
                                    <textarea
                                        [readonly]="formMode == 1"
                                        [name]="'remarks'"
                                        #remarks="ngModel"
                                        
                                        class="form-control noresize"
                                        style="height: 100px; width: 100%; resize: none"
                                        [(ngModel)]="workOrderItem.remarks"
                                    >
                                    </textarea>
                                </td>
                            </tr>
                            <tr>
                                <td class="row_opening_small">
                                    {{ "general.partNumber" | translate }}                                    
                                </td>
                                <td>
                                    <input
                                        type="text"
                                        [readonly]="formMode == 1"
                                        [name]="'partNumber'"
                                        #partNumber="ngModel"                                        
                                        class="form-control table-inline"                                        
                                        [(ngModel)]="workOrderItem.partNumber"
                                    />                                    
                                </td>
                            </tr>
                            <tr>
                                <td class="row_opening_small">
                                    {{ "general.materialReceiptInspectionVoucherNumber" | translate }}                                    
                                </td>
                                <td>
                                    <input
                                        type="text"
                                        [readonly]="formMode == 1"
                                        [name]="'materialReceiptInspectionVoucherNumber'"
                                        #materialReceiptInspectionVoucherNumber="ngModel"                                        
                                        class="form-control table-inline"                                        
                                        [(ngModel)]="workOrderItem.materialReceiptInspectionVoucherNumber"
                                    />                                    
                                </td>
                            </tr>
                            <tr>
                                <td class="row_opening_small">
                                    {{ "general.localPurchaseOrderNumber" | translate }}                                    
                                </td>
                                <td>
                                    <input
                                        type="text"
                                        [readonly]="formMode == 1"
                                        [name]="'localPurchaseOrderNumber'"
                                        #localPurchaseOrderNumber="ngModel"                                        
                                        class="form-control table-inline"                                        
                                        [(ngModel)]="workOrderItem.localPurchaseOrderNumber"
                                    />                                    
                                </td>
                            </tr>
                            <tr>
                                <td class="row_opening_small">
                                    {{ "general.supplierName" | translate }}                                    
                                </td>
                                <td>
                                    <input
                                        type="text"
                                        [readonly]="formMode == 1"
                                        [name]="'supplierName'"
                                        #supplierName="ngModel"                                        
                                        class="form-control table-inline"                                        
                                        [(ngModel)]="workOrderItem.supplierName"
                                    />                                    
                                </td>
                            </tr>
                            <tr>
                                <td class="row_opening_small">
                                    {{ "general.quantity" | translate }}
                                    <span class="error" *ngIf="quantity.invalid && formMode != 1">*</span>
                                </td>
                                <td class="row_content">
                                    <input
                                        type="number"
                                        [readonly]="formMode == 1"
                                        [name]="'quantity'"
                                        class="form-control table-inline"
                                        [(ngModel)]="workOrderItem.quantity"
                                        required
                                        #quantity="ngModel"
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td class="row_opening_small">
                                    {{ "general.itemPrice" | translate }}
                                </td>
                                <td class="row_content">
                                    <input
                                        type="number"
                                        [readonly]="formMode == 1"
                                        [name]="'itemPrice'"
                                        class="form-control table-inline"
                                        [(ngModel)]="workOrderItem.itemPrice"
                                        #itemPrice="ngModel"
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td class="row_opening_small">
                                    {{ "general.laborCost" | translate }}
                                </td>
                                <td class="row_content">
                                    <input
                                        type="number"
                                        [readonly]="formMode == 1"
                                        [name]="'laborCost'"
                                        class="form-control table-inline"
                                        [(ngModel)]="workOrderItem.laborCost"
                                        #laborCost="ngModel"
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td class="row_opening_small">
                                    {{ "general.discount" | translate }}
                                </td>
                                <td class="row_content">
                                    <input
                                        type="number"
                                        [readonly]="formMode == 1"
                                        [name]="'discount'"
                                        class="form-control table-inline"
                                        [(ngModel)]="workOrderItem.discount"
                                        #itemPrice="ngModel"
                                    />
                                </td>
                            </tr>
                            <tr *ngIf="formMode == 1">
                                <td class="row_opening_small">
                                    {{ "general.cost" | translate }}
                                </td>
                                <td class="row_content">
                                    <input
                                        type="number"
                                        [readonly]="true"
                                        [name]="'totalCost'"
                                        class="form-control table-inline"
                                        [(ngModel)]="workOrderItem.totalCost"
                                        #itemPrice="ngModel"
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="ibox-footer">
                    <button class="btn btn-primary" [disabled]="addItem.invalid" (click)="addItemToTable()">
                        <i class="fa fa-fw fa-save"></i>
                        {{ (editItem ? "general.save" : "general.add") | translate }}
                    </button>
                    <button class="btn btn-info" (click)="workOrderItem = null">
                        <i class="fa fa-fw fa-times"></i> {{ "general.cancel" | translate }}
                    </button>
                </div>
            </div>
        </form>
    </div>
</div>
