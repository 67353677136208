import { Component, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { GridBase360Directive } from "app/common/360Grid.base";
import { roundAsNumber, roundAsString } from "app/common/globals";
import { getGridButtons, getGridLanguages } from "app/common/gridhelper";
import { StorageHelper } from "app/common/storagehelper";
import { AuthenticationService } from "app/services/authentication/authentication.service";
import { ColorService } from "app/services/common/color.service";
import { MaintenanceV2Service } from "app/services/maintenancev2/maintenanceV2.service";
import * as Moment from "moment";

@Component({
  selector: "fh-maintenance-work-order",
  templateUrl: "workOrders.template.html",
})
export class MaintenanceWorkOrderViewComponent extends GridBase360Directive implements OnInit {
  loading = false;
  languageLoaded: boolean;
  permissions: {};

  permissionName = "Maintenances_View";
  constructorName = "MaintenanceWorkOrderViewComponent";

  success;
  error;
  warning;

  timezoneIana: string;

  constructor(
    private translateService: TranslateService,
    protected storageHelper: StorageHelper,
    private maintenanceService: MaintenanceV2Service,
    private authenticationService: AuthenticationService,
    private colorService: ColorService
  ) {
    super(storageHelper);

    this.timezoneIana = this.authenticationService.getTimeZoneIana();
    this.permissions = this.authenticationService.permissions;
  }

  ngOnInit() {
    this.translateService.get("general.date").subscribe((value) => {
      this.languageLoaded = true;
      this.initGrid();
    });
  }

  initGrid(): void {
    console.log("Init grid");

    const that = this;

    $.fn["dataTable"].ext.search.pop();

    this.loading = true;

    this.columns = [
      {
        name: "id",
        data: "id",
        orderable: false,
        title: '<div class="hideDropdown"></div>',
        width: "20",
        render: function (data, type, row) {
          return (
            "<a class='btn btn-primary btn-grid' title='" +
            that.translateService.instant("general.details") +
            "' href='/#/MaintenancesV2/WorkOrders/Details/Index/" +
            data +
            '\'><span class="d-none d-md-inline-flex" style="padding-left: 7px">' +
            that.translateService.instant("general.details") +
            " </span><i class='fas fa-fw fa-angle-right'></i></a>"
          );
        },
      },
      {
        name: "id_export",
        data: "id",
        className: "noVis",
        title: this.translateService.instant("general.id"),
        visible: false,
      },
      {
        name: "assetName",
        data: "assetName",
        title: this.translateService.instant("general.assetName"),
      },
      {
        name: "serviceDate",
        data: "serviceDate",
        defaultContent: "-",
        title: this.translateService.instant("general.date"),
        render: function (data, type, row) {
          if (data) {
            if (type && type === "display") {
              const date = Moment.utc(data)["tz"](that.timezoneIana);
              return data ? '<span title=" ' + date.toLocaleString() + '">' + date.format("lll") + "</span>" : "";
            } else {
              return data;
            }
          }
          return "-";
        },
      },
      {
        name: "description",
        data: "description",
        title: this.translateService.instant("general.description"),
      },
      {
        name: "itemCount",
        data: "itemCount",
        defaultContent: "-",
        title: this.translateService.instant("general.itemCount"),
        render: function (data, type, row) {
          if (type && type === "display") {
            if (data) {
              const value = roundAsNumber(data as number, 2).toLocaleString();
              return value;
            }
            return "-";
          }
          return data;
        },
      },
      {
        name: "odometer",
        data: "odometer",
        defaultContent: "-",
        title: this.translateService.instant("general.odometer"),
        // className: "dt-body-right",
        render: function (data, type, row) {
          if (type && type === "display") {
            if (data) {
              const value = roundAsNumber(data as number, 0).toLocaleString();
              return value;
            }
            return "-";
          }
          return data;
        },
      },
      {
        name: "engineHours",
        data: "engineHours",
        defaultContent: "-",
        title: this.translateService.instant("general.engineHours"),
        // className: "dt-body-right",
        render: function (data, type, row) {
          if (type && type === "display") {
            if (data) {
              const value = roundAsNumber(data as number, 0).toLocaleString();
              return value;
            }
            return "-";
          }
          return data;
        },
      },
      {
        name: "cost",
        data: "totalCost",
        defaultContent: "-",
        title: this.translateService.instant("general.cost"),
        // className: "dt-body-right",
        render: function (data, type, row) {
          if (type && type === "display") {
            if (data) {
              const value = roundAsString(data as number, 2).toLocaleString();
              return value;
            }
            return "-";
          }
          return data;
        },
      },
      {
        name: "companyName",
        data: "companyName",
        visible: false,
        title: this.translateService.instant("general.companyName"),
      },
      {
        name: "resellerDescription",
        data: "resellerDescription",
        visible: false,
        title: this.translateService.instant("general.reseller"),
      },
    ];

    this.dtOptions = {
      buttons: getGridButtons(
        this.commonExportOptions,
        "workorder_overview",
        this.translateService.instant("menu.messagesoverview"),
        this.colorService
      ),
      pagingType: "simple_numbers",
      serverSide: false,
      processing: false,
      scrollX: true,
      colReorder: { fixedColumnsLeft: 1 },
      order: [[2, "asc"]],
      stateSave: false,
      ajax: (dataTablesParameters: any, callback) => {
        $(".dataTables_info").html(this.translateService.instant("grid.loadingData"));
        this.loading = true;

        this.maintenanceService.getWorkOrders().subscribe({
          next: (resp) => {
            callback({
              recordsTotal: resp.length,
              recordsFiltered: resp.length,
              data: resp,
            });
          },
          error: (error) => {
            this.success = null;
            this.error = error;
            this.loading = false;
          },
        });
      },
      initComplete: function (settings, json) {
        that.loading = false;

        console.log("init complete");
        that.checkFilters();
        that.drawFilterRow();
        that.loading = false;
      },
      colVis: {
        restore: this.translateService.instant("general.restore"),
        showAll: this.translateService.instant("general.showAll"),
        showNone: this.translateService.instant("general.hideAll"),
      },
      columns: this.columns,
      pageLength: 17,
      lengthMenu: [
        [10, 17, 25, 50, 200, -1],
        [10, 17, 25, 50, 200, this.translateService.instant("general.all")],
      ],
      language: getGridLanguages(this.translateService),
      rowCallback: (row, data) => {},
    };
  }
}
