<div class="vertical-center">
    <div
        class="page-content-inner"
        style="background-image: url(../assets/images/garage.jpg); display: table; height: 100%; width: 100%"
    >
        <div class="container loginscreen" style="display: table-cell; vertical-align: middle; height: 100% !important">
            <div class="login-box well single-page-block-inner blur-placeholder effect-3d-element">
                <div class="text-center" style="min-height: 145px">
                    <div class="alert alert-danger" *ngIf="error">
                        <strong>{{ failed() ? ("login.ssoError" | translate) : "" }}</strong>
                    </div>
                    <ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '14px' }"></ngx-loading>
                    <div class="form-group col-sm-12"><img src="/assets/images/360_logo.png" alt="" /></div>
                    <h1 style="font-size: 40px; padding-bottom: 20px; display: none" class="text-center">
                        {{ "general.title" | translate }}
                    </h1>

                    {{ failed() ? "" : ("login.loading" | translate) }}
                    {{ failed() ? redirectToLogin() : "" }}

                    <footer class="col-sm-12">
                        <div class="copy-text col-sm-12">{{ "login.footer" | translate }} - {{ appVersion }}</div>
                    </footer>
                </div>
            </div>
        </div>
    </div>
</div>
