import { Component, ChangeDetectionStrategy, ChangeDetectorRef, ViewChild, OnInit } from "@angular/core";
import { ControlContainer, NgForm } from "@angular/forms";
import { FormMode } from "app/common/enums";
import { ServiceItem } from "app/models/serviceItem.model";
import { AuthenticationService } from "app/services/authentication/authentication.service";
import { MaintenanceServiceItemDetailsComponent } from "app/views/shared/usercontrols/serviceItemDetails.component";
import { ActivatedRoute } from "@angular/router";
import { MaintenanceV2Service } from "app/services/maintenancev2/maintenanceV2.service";

@Component({
  selector: "fh-fh-maintenance-service-item-details",
  templateUrl: "serviceItemDetails.template.html",
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MaintenanceServiceItemDetailsViewComponent implements OnInit {
  @ViewChild(MaintenanceServiceItemDetailsComponent, { static: true })
  serviceItemDetails: MaintenanceServiceItemDetailsComponent;

  formMode = FormMode.read;
  permissions: {};

  serviceItem = new ServiceItem();
  serviceItemId;

  permissionName = "MaintenanceTypes_View";

  constructor(
    private cd: ChangeDetectorRef,
    private authenticationService: AuthenticationService,
    private maintenceService: MaintenanceV2Service,
    private route: ActivatedRoute
  ) {
    this.permissions = this.authenticationService.permissions;
  }

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.serviceItemId = params["id"];
      this.serviceItemDetails.serviceItemId = this.serviceItemId;

      this.serviceItemDetails.loading = true;

      this.maintenceService.getServiceItemById(this.serviceItemId).subscribe((serviceItem) => {
        this.serviceItem = serviceItem;
        this.serviceItemDetails.loading = false;
        this.cd.markForCheck();
      });
    });
  }

  onSave() {
    this.serviceItemDetails.loading = true;

    this.maintenceService.updateServiceItem(this.serviceItemId, this.serviceItem).subscribe({
      next: (result) => {
        this.serviceItemDetails.error = null;
        this.serviceItemDetails.success = {
          statusText: "Success",
          success: "Service item is successfully updated.",
        };

        this.serviceItemDetails.loading = false;
        this.cd.markForCheck();

        this.serviceItemDetails.setFormMode(FormMode.read);
      },
      error: (error) => {
        this.serviceItemDetails.loading = false;
        this.serviceItemDetails.success = null;
        this.serviceItemDetails.error = error;
        this.cd.markForCheck();
      },
    });
  }
}
