<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12 flex header-content">
        <nav class="breadcrumb-container" aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a href="/#/"> <i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
                </li>
                <li class="breadcrumb-item">
                    <a href="/#/Fuel/Thresholds">{{'general.fuel' | translate }}</a>
                </li>
                <li class="breadcrumb-item active">{{'menu.thresholds' | translate }}</li>
            </ol>
        </nav>

        <fh-filter-toggler
            style="margin-left: auto"
            [constructorName]="constructorName"
            (filterChanged)="showFilter = $event"
            (presetsChanged)="showPresets = $event"
        ></fh-filter-toggler>
    </div>
    <fh-fuel-navigation> </fh-fuel-navigation>
</div>

<div class="wrapper wrapper-content">
    <div class="row">
        <div class="col-lg-12">
            <!-- Custom filters -->
            <div class="ibox" [hidden]="!showFilter">
                <div class="ibox-title">
                    <h5><i class="fas fa-fw fa-filter"></i> {{'general.filter' | translate }}</h5>
                    <span class="float-right hand btn btn-info filter-margin-fix" (click)="clearAllFilters();">
                        <i class="fa fa-fw fa-times"></i>
                        <span class="d-none d-md-inline-flex"> {{'general.clearFilters' | translate }}</span>
                    </span>
                </div>
                <div class="ibox-content slick" style="position: relative">
                    <ngx-loading [show]="loading"></ngx-loading>
                    <div id="dynamicFilters" class="flex-container"></div>
                    <div style="clear: both"></div>
                </div>
            </div>
            <!-- End default dynamic filters -->

            <div class="ibox">
                <div class="ibox-title">
                    <h5><i class="fas fa-fw fa-gas-pump"></i> {{'general.thresholds' | translate }}</h5>
                    <iboxsearch
                        [searchTerm]="searchTerm"
                        (searchChanged)="searchChanged($event)"
                        [constructorName]="constructorName"
                        (filterChanged)="showFilter = $event"
                        (presetLoaded)="onPresetLoaded($event)"
                        (columnsChanged)="drawFilterRow()"
                    ></iboxsearch>
                </div>
                <div class="ibox-content slick" style="position: relative" *ngIf="languageLoaded">
                    <table
                        style="width: 100%; min-height: 50px"
                        fh-datatable
                        [dtOptions]="dtOptions"
                        [dtTrigger]="dtTrigger"
                        class="table nowrap margin-bottom-0 dataTable dtr-inline"
                    ></table>
                </div>
            </div>
        </div>
    </div>
</div>
