import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
  ChangeDetectorRef,
  ViewChild,
} from "@angular/core";
import { AuthenticationService } from "../../services/authentication/authentication.service";
import { FhChartService } from "../../services/charts/charts.service";
import { TranslateService } from "@ngx-translate/core";

import { SearchService } from "app/services/search/search.service";
import { slideInOut } from "app/common/animations";
import { AccountService } from "app/services/account/account.service";
import { DeviceService } from "app/services/device/device.service";
import { Device } from "app/models/device.model";
import { mergeMap } from "rxjs/internal/operators/mergeMap";
declare var L;

import { AccountInventory } from "app/models/account.model";
import {
  BOUND_CHECK,
  MAX_LATITUDE,
  MAX_LONGITUDE,
  contains,
  roundAsNumber,
  roundMinutes,
  roundSeconds,
} from "app/common/globals";
import { Observable, debounceTime, distinctUntilChanged, forkJoin, of, timer } from "rxjs";
import { getDefaultDpConfig } from "app/common/gridhelper";
import { AuditLog } from "app/models/auditlog.model";
import { TypeaheadMatch } from "ngx-bootstrap/typeahead";
import { DeviceTypeService } from "app/services/devicetypes/devicetypes.service";
import { DeviceType } from "app/models/devicetype.model";
import { LeafletMapComponent } from "../shared/usercontrols/leafletMap.component";
import { LocationService } from "app/services/locations/locations.service";

// Moment timezone
import Moment from "moment-timezone";
window["moment"] = Moment;

import * as Highcharts from "highcharts";
import { StorageHelper } from "app/common/storagehelper";
import { StorageType } from "app/common/enums";
import { AssetGroupInputComponent } from "app/modules/customInputs/assetGroupSelector.component";

@Component({
  selector: "fh-home-live",
  templateUrl: "homeLive.template.html",
  providers: [FhChartService, SearchService],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  animations: [slideInOut],
})
export class HomeLiveComponent implements OnDestroy, OnInit {
  @ViewChild(LeafletMapComponent, { static: false }) leafletMapComponent: LeafletMapComponent;
  @ViewChild(AssetGroupInputComponent, { static: false }) assetGroupInputComponent: AssetGroupInputComponent;

  Highcharts: typeof Highcharts = Highcharts;

  permissionName = "FleetManagement_Home";
  constructorName = "HomeLiveComponent";
  timezoneIana: string;
  permissions: {};
  loading = false;

  activeDevices = 0;
  activeGeofences = 0;
  activeDrivers = 0;
  firstAssetDate: any;
  error: any;

  devices = [];
  loadingDevices = false;
  includeAssetsOtherAccounts = false;
  skipIncludingGroupColors = false;
  geofences = [];

  locationData = [];
  loadingAccount: boolean;
  account: AccountInventory;

  mapHeight = 250;

  loadingUtilization = false;
  daterangepickerModel: Date[];
  utilization: any;
  accountUtilization;
  dpConfig: any;

  loadingAuditLog = false;
  auditLogs: AuditLog[] = [];
  currentPageAuditLog;
  filterAuditLog;

  loadingNotifications = false;
  notifications: AuditLog[] = [];
  currentPageNotifications;
  filterNotifications;

  loadingCount: boolean;
  distanceChartData: any;
  chartGeofences;
  chartActivity;
  chartAssetGroups;
  chartCommunicating;
  culture;

  // Impersonation
  asyncSelected: any;
  typeaheadLoading: boolean;

  autoComplete$: Observable<string[]>;
  autoCompleteSearchTerm: string;
  deviceTypes: DeviceType[] = [];

  // mapping
  search;
  status;
  selectedAssetGroups;
  selectedAccountId;
  loadingDeviceTypes = false;
  deviceType;
  deviceTypeOptions = [];

  // Location updates
  isSpinning = false;
  deviceSubscription;
  locationSubscription;
  statusText = "Init";
  previousLookupTimestamp;
  previousLookupDisplay: Moment.Moment;

  storageType = StorageType.LocalStorage;

  // trends
  loadingTrends: boolean;
  groupBy = 1;
  trends: any;
  selectedResellerId;

  resellerChanged(resellerId) {
    this.selectedAccountId = null;

    this.selectedResellerId = resellerId;
  }

  accountChanged(accountId) {
    this.selectedAccountId = accountId;
    this.selectedAssetGroups = null;

    this.error = null;

    if (this.selectedAccountId) {
      this.getData();
    }
  }

  public constructor(
    private deviceTypeService: DeviceTypeService,
    private searchService: SearchService,
    private locationService: LocationService,
    private chartService: FhChartService,
    private deviceService: DeviceService,
    private storageHelper: StorageHelper,
    private accountService: AccountService,
    private cd: ChangeDetectorRef,
    private authenticationService: AuthenticationService,
    private translateService: TranslateService
  ) {
    this.timezoneIana = this.authenticationService.getTimeZoneIana();
    this.permissions = this.authenticationService.permissions;

    this.daterangepickerModel = [
      Moment().tz(this.timezoneIana).subtract(14, "days").startOf("day").toDate(),
      Moment().tz(this.timezoneIana).subtract(0, "days").startOf("day").toDate(),
    ];

    this.deviceTypeService.getDeviceTypes().subscribe((result) => {
      this.deviceTypes = result;
    });

    this.dpConfig = getDefaultDpConfig(authenticationService);

    this.storageHelper
      .loadStoreState(this.storageType, "settings_", "includeAssetsOtherAccounts")
      .subscribe((result) => {
        this.includeAssetsOtherAccounts = JSON.parse(result) === true;
      });

    this.storageHelper.loadStoreState(this.storageType, "settings_", "skipIncludingGroupColors").subscribe((result) => {
      this.skipIncludingGroupColors = JSON.parse(result) === true;
    });

    const culture = this.authenticationService.getCultureLang();
    switch (culture) {
      case "en":
        this.culture = "en-US";
        break;
      case "nl":
        this.culture = "nl-NL";
        break;
      case "ar":
        this.culture = "ar-AE";
        break;
      default:
        this.culture = "en-US";
        break;
    }
  }

  // includeAssetsOtherAccounts
  flipIncludeAssetsOtherAccounts() {
    this.includeAssetsOtherAccounts = !this.includeAssetsOtherAccounts;
    this.storageHelper.saveStoreState(
      this.storageType,
      "settings_",
      "includeAssetsOtherAccounts",
      this.includeAssetsOtherAccounts.toString()
    );

    this.getData();
  }

  ngOnInit(): void {
    this.loading = true;
    this.loadingAccount = true;
    this.loadingDevices = true;

    this.loadingDeviceTypes = true;

    this.deviceTypeService.getDeviceTypes(false, false, true).subscribe((deviceTypes) => {
      this.loadingDeviceTypes = false;
      deviceTypes
        .sort((a, b) => (a.modelName > b.modelName ? 1 : -1))
        .forEach((item, index) => {
          if (item.modelName !== "") {
            this.deviceTypeOptions.push({ id: item.id, value: item.modelName + " (" + item.deviceCount + ")" });
          }
          this.cd.markForCheck();
        });
    });

    this.getData();
  }

  getData() {
    if (!this.selectedAccountId) {
      this.loadingDevices = false;
      this.loadingAccount = false;
      this.cd.markForCheck();
      return;
    }

    this.getLocations();

    this.autoComplete$ = Observable.create((observer: any) => {
      this.actualSearch(this.asyncSelected).subscribe((result: any) => {
        observer.next(result);
      });
    });
  }

  getDeviceCount(index) {
    if (index === 6) {
      const markers = this.leafletMapComponent?.markerList?.filter(
        (x) => x.filtered == false && x.position != null && !((x.data.location?.locationType & 2) > 0)
      );
      return markers?.length ?? "-";
    } else {
      return (
        this.leafletMapComponent?.markerList?.filter((x) => x.filtered == false && x.category === index.toString())
          .length ?? "-"
      );
    }
  }

  getLocations() {
    this.loadingDevices = true;
    this.loadingAccount = true;

    if (!this.selectedAccountId) {
      return;
    }

    this.setLocationUpdates();

    forkJoin([
      this.accountService.getAccountById(this.selectedAccountId),
      this.deviceService.getDevicesWithLocation(
        this.includeAssetsOtherAccounts ? null : this.selectedAccountId,
        true,
        !this.skipIncludingGroupColors,
        true
      ),
      this.accountService.getGeofencesByAccount(this.selectedAccountId),
    ]).subscribe((data) => {
      this.account = data[0];
      this.devices = data[1].filter((x) => x.isActive === true && x.isArchived === false);
      this.geofences = data[2];

      this.loadingDevices = false;
      this.loadingAccount = false;

      this.drawLocations(this.devices);

      // Do the initial location states
      this.locationService
        .getDeviceStates(
          this.devices.map((x) => x.id),
          null,
          this.previousLookupTimestamp,
          0
        )
        .subscribe((result) => {
          this.parseLocationUpdates(result);
        });
    });
  }

  setLocationUpdates() {
    this.statusText = "Getting locations";

    console.log("Subscribe to updates for " + this.devices.map((x) => x.id).length + " devices");
    if (this.locationSubscription !== undefined) {
      console.log("Unsubscribe updates locations");
      this.locationSubscription.unsubscribe();
    }

    this.locationSubscription = timer(0, 30000)
      .pipe(
        mergeMap(() => {
          // Start the spinner
          this.isSpinning = true;
          this.cd.markForCheck();

          if (this.devices.length == 0) {
            console.log("Halt get state");
            return of(null);
          }

          return this.locationService.getDeviceStates(
            this.devices.map((x) => x.id),
            null,
            this.previousLookupTimestamp,
            0
          );
        })
      )
      .subscribe((result) => {
        this.parseLocationUpdates(result);
      });
  }

  parseLocationUpdates(result) {
    if (result == null) {
      return;
    }

    // Stop the spinner
    setTimeout(() => {
      this.isSpinning = false;
      this.cd.markForCheck();
    }, 500);

    this.loading = false;

    if (result?.deviceStates.length > 0) {
      this.statusText = "Last updated: ";

      console.log("Updating " + result?.deviceStates.length + " locations");

      this.cd.markForCheck();

      this.leafletMapComponent.markerList.forEach((theMarker) => {
        const deviceState = result.deviceStates.find((x) => x.id === theMarker.data.deviceId);

        if (deviceState && deviceState?.currentPosition) {
          if (
            BOUND_CHECK(deviceState?.currentPosition?.latitude, MAX_LATITUDE) &&
            BOUND_CHECK(deviceState?.currentPosition?.longitude, MAX_LONGITUDE)
          ) {
            theMarker.Move(deviceState?.currentPosition?.latitude, deviceState?.currentPosition?.longitude);
            theMarker.data.forceIconRedraw = true;
            theMarker.data.deviceState = deviceState?.calculatedDeviceState?.deviceState ?? 0;
            theMarker.data.lastCommunication = Moment.utc(deviceState?.communicationState?.updateTimestamp)["tz"](
              this.timezoneIana
            );
            theMarker.data.location.locationType = deviceState?.communicationState?.locationType;
            theMarker.category = Math.ceil(deviceState?.calculatedDeviceState?.deviceState).toString();
          }
        } else {
          // dont update
        }
      });

      if (this.leafletMapComponent.pruneCluster) {
        if (typeof this.leafletMapComponent.pruneCluster.ProcessView === "function") {
          this.leafletMapComponent.pruneCluster.ProcessView();
        }
        if (typeof this.leafletMapComponent.pruneCluster.RedrawIcons === "function") {
          this.leafletMapComponent.pruneCluster.RedrawIcons();
        }
      }

      this.previousLookupTimestamp = new Date(result.timestamp);
      this.previousLookupDisplay = Moment.utc(result.timestamp)["tz"](this.timezoneIana);
      this.cd.markForCheck();
    } else {
      console.log("No data updated");
      this.previousLookupTimestamp = new Date(result.timestamp);
      this.previousLookupDisplay = Moment.utc(result.timestamp)["tz"](this.timezoneIana);
      this.cd.markForCheck();
    }
  }

  actualSearch(search) {
    return this.searchService.searchUsers(search).pipe(debounceTime(300), distinctUntilChanged());
  }

  onSubmit() {
    console.log("Search on");
  }

  changeTypeaheadLoading(e: boolean): void {
    this.typeaheadLoading = e;
  }

  typeaheadOnSelect(e: TypeaheadMatch): void {
    console.log("Selected value: ", e);
    this.authenticationService.impersonate(e.item.id);
  }

  ngOnDestroy(): any {
    if (this.deviceSubscription !== undefined) {
      console.log("Unsubscribe updates");

      this.deviceSubscription.unsubscribe();
    }
    if (this.locationSubscription !== undefined) {
      console.log("Unsubscribe updates");
      this.locationSubscription.unsubscribe();
    }
  }

  actualRound(value, decimals) {
    return roundAsNumber(value, decimals);
  }

  actualRoundSeconds(value) {
    return roundSeconds(value);
  }

  actualRoundMinutes(value) {
    return roundMinutes(value);
  }

  drawLocations(devices: Device[]) {
    const localLocationData = [];
    this.leafletMapComponent.clearLocations();
    this.locationData = [];

    let resellerDefaultAssetIcon;
    if (this.account?.resellerDefaultAssetIcon > 999) {
      resellerDefaultAssetIcon = this.account.resellerDefaultAssetIcon;
    }

    devices.forEach((device) => {
      if (device.deviceState === null) {
        return;
      }

      let latitude = device.deviceState.currentPosition?.latitude ?? null;
      let longitude = device.deviceState.currentPosition?.longitude ?? null;
      let lastCommunication = Moment.utc(device.deviceState.currentPosition?.updateTimestamp)["tz"](this.timezoneIana);

      const hasGpsFix = (device.deviceState.communicationState?.locationType & 2) > 0;

      if (device.deviceState.cellPosition && ((latitude === null && longitude === null) || !hasGpsFix)) {
        latitude = device.deviceState.cellPosition.latitude ?? null;
        longitude = device.deviceState.cellPosition.longitude ?? null;
        lastCommunication = Moment.utc(device.deviceState.cellPosition.updateTimestamp)["tz"](this.timezoneIana);
      }

      if (latitude === null || longitude === null) {
        return;
      }

      if (!BOUND_CHECK(latitude, MAX_LATITUDE) || !BOUND_CHECK(longitude, MAX_LONGITUDE)) {
        console.log("No valid bounds for device " + device.id + " latlng: " + latitude + ":" + longitude);
        return;
      }

      const location = {
        assetName: device.asset?.name ?? device.name,
        assetBrand: device?.asset?.brand,
        assetModel: device?.asset?.model,
        assetCode: device?.asset?.code,
        assetPlateNumber: device?.asset?.plateNumber,
        companyName: device?.companyName,
        icon: device.asset?.icon,
        deviceState: device.deviceState?.calculatedDeviceState?.deviceState ?? 0,
        stateChangedTimestamp: Moment.utc(device.deviceState?.calculatedDeviceState?.stateChangedTimestamp)["tz"](
          this.timezoneIana
        ),
        headingInDegrees: device.deviceState.currentPosition?.heading,
        latitude: latitude,
        longitude: longitude,
        unitId: device.unitId,
        deviceId: device.id,
        deviceTypeId: device.deviceTypeId,
        lastCommunication: lastCommunication,
        radiusInMeters: device.deviceState.currentPosition?.radiusInMeters,
        assetGroupIds: device.asset?.assetGroupIds,
        assetGroups: device.asset?.assetGroups,
        locationType: device.deviceState.communicationState?.locationType,
        insideGeofences: device.deviceState.insideGeofences,
      };

      localLocationData.push(location);
    });

    this.leafletMapComponent.clearLocations();
    this.locationData = localLocationData;

    setTimeout(() => {
      this.filterMarkers(true);
    }, 10);

    this.cd.markForCheck();
  }

  // Mapping

  filterMarkers(centerMap) {
    console.log("filtering markers");
    // filter the events on map

    this.leafletMapComponent.markerList.forEach((theMarker) => {
      if (theMarker) {
        theMarker.filtered = false;

        if (this.search) {
          theMarker.filtered = true;
          if (
            theMarker.data?.title?.toLowerCase().indexOf(this.search.toLowerCase()) > -1 ||
            theMarker.data?.companyName?.toLowerCase().indexOf(this.search.toLowerCase()) > -1
          ) {
            theMarker.filtered = false;
          }
        }

        if (this.status) {
          if (this.status === 6) {
            if ((theMarker.data.location?.locationType & 2) > 0) {
              theMarker.filtered = true;
            }
          } else {
            if (theMarker.category.toString() !== this.status.toString()) {
              theMarker.filtered = true;
            }
          }
        }

        if (this.selectedAssetGroups && this.selectedAssetGroups.length > 0) {
          const found = theMarker.data.assetGroupIds?.some((ag) => this.selectedAssetGroups.includes(ag));
          if (!found) {
            theMarker.filtered = true;
          }
        } else if (this.selectedAssetGroups && this.selectedAssetGroups > 0) {
          const found = theMarker.data.assetGroupIds?.some((ag) => this.selectedAssetGroups === ag);
          if (!found) {
            theMarker.filtered = true;
          }
        }

        if (this.deviceType && this.deviceType > 0) {
          if (theMarker.data.deviceTypeId.toString() !== this.deviceType.toString()) {
            theMarker.filtered = true;
          }
        }
      }
    });

    this.centerMap();

    if (typeof this.leafletMapComponent.pruneCluster.ProcessView === "function") {
      this.leafletMapComponent.pruneCluster.ProcessView();
    }
    this.generateActivityReport(this.leafletMapComponent.markerList);
    this.generateGeofences(this.leafletMapComponent.markerList);
    this.generateAssetGroups(this.leafletMapComponent.markerList);
    this.generateCommunicating(this.leafletMapComponent.markerList);

    this.cd.markForCheck();
  }

  centerMap() {
    const arrBounds = [];
    this.leafletMapComponent.markerList.forEach((theMarker) => {
      if (theMarker.data && theMarker.data.location) {
        if (
          BOUND_CHECK(theMarker.data.location.latitude, MAX_LATITUDE) &&
          BOUND_CHECK(theMarker.data.location.longitude, MAX_LONGITUDE)
        ) {
          if (!theMarker.filtered) {
            arrBounds.push(new L.LatLng(theMarker.data.location.latitude, theMarker.data.location.longitude));
          }
        }
      }
    });

    const bounds = L.latLngBounds(arrBounds);
    if (bounds) {
      if (bounds.isValid()) {
        if (this.leafletMapComponent.map) {
          this.leafletMapComponent.map.fitBounds(bounds, { padding: [50, 50], maxZoom: 15 });
        }
      }
    }
  }

  // Generate chart engineHours
  generateActivityReport(data) {
    if (data == null) {
      return;
    } else {
      data = data.filter((x) => x?.filtered == false);
    }

    const drivingValue = data.filter((x) => +x?.category == 1).length;
    const ignitionOffValue = data.filter((x) => +x?.category == 2).length;
    const idlingValue = data.filter((x) => +x?.category == 3).length;
    const bufferedValue = data.filter((x) => +x?.category == 4).length;
    const externalPowerLostValue = data.filter((x) => +x?.category == 5).length;
    const gpsLostValue = data.filter((x) => +x?.category == 6).length;

    const dataActivity = [
      {
        name: this.translateService.instant("general.activity"),
        colorByPoint: true,
        minPointSize: 20,
        innerSize: "20%",
        zMin: 0,
        data: [
          {
            name: this.translateService.instant("general.deviceState_1"),
            y: drivingValue,
            color: "#008E43",
          },
          {
            name: this.translateService.instant("general.deviceState_2"),
            y: ignitionOffValue,
            color: "#971C24",
          },
          {
            name: this.translateService.instant("general.deviceState_3"),
            y: idlingValue,
            color: "#EE9234",
          },
          {
            name: this.translateService.instant("general.deviceState_4"),
            y: bufferedValue,
            color: "#206EB4",
          },
          {
            name: this.translateService.instant("general.deviceState_5"),
            y: externalPowerLostValue,
            color: "#00000",
          },
          {
            name: this.translateService.instant("general.deviceState_6"),
            y: gpsLostValue,
            color: "#7D177B",
          },
        ],
      },
    ];

    this.chartActivity = this.chartService.generateVarPieChartDevice(dataActivity, {}, null, false);

    this.cd.markForCheck();
  }

  // Generate chart engineHours
  generateGeofences(data) {
    if (data == null) {
      return;
    } else {
      data = data.filter((x) => x?.filtered == false);
    }

    let geofences = this.geofences.map((x) => ({ id: x.id, name: x.name, count: 0, deviceState: [] }));
    geofences.forEach((geofence) => {
      geofence.count = data.filter(
        (x) => x.data.insideGeofences != null && contains(Object.keys(x.data.insideGeofences), geofence.id.toString())
      )?.length;

      data
        .filter(
          (x) => x.data.insideGeofences != null && contains(Object.keys(x.data.insideGeofences), geofence.id.toString())
        )
        .forEach((device) => {
          if (geofence.deviceState[device.data.deviceState]) {
            geofence.deviceState[device.data.deviceState] += 1;
          } else {
            geofence.deviceState[device.data.deviceState] = 1;
          }
        });
    });

    geofences = geofences.filter((x) => x.count > 0).sort((a, b) => (a.count > b.count ? -1 : 1));

    const series = [
      {
        name: this.translateService.instant("general.deviceState_1"),
        color: "#008E43",
        data: geofences.map((x) => {
          return { y: x.deviceState[1] ?? 0, id: x.id };
        }),
      },
      {
        name: this.translateService.instant("general.deviceState_2"),
        color: "#971C24",
        data: geofences.map((x) => {
          return { y: x.deviceState[2] ?? 0, id: x.id };
        }),
      },
      {
        name: this.translateService.instant("general.deviceState_3"),
        color: "#EE9234",
        data: geofences.map((x) => {
          return { y: x.deviceState[3] ?? 0, id: x.id };
        }),
      },
      {
        name: this.translateService.instant("general.deviceState_4"),
        color: "#206EB4",
        data: geofences.map((x) => {
          return { y: x.deviceState[4] ?? 0, id: x.id };
        }),
      },
      {
        name: this.translateService.instant("general.deviceState_5"),
        color: "#00000",
        data: geofences.map((x) => {
          return { y: x.deviceState[5] ?? 0, id: x.id };
        }),
      },
      {
        name: this.translateService.instant("general.deviceState_6"),
        color: "#7D177B",
        data: geofences.map((x) => {
          return { y: x.deviceState[6] ?? 0, id: x.id };
        }),
      },
    ];

    const xAxis = geofences.map((x) => x.name);

    this.chartGeofences = this.chartService.generateColumnChart(
      series,
      {},
      xAxis,
      true,
      "/#/GeofenceDetails/Index/",
      "id"
    );

    this.cd.markForCheck();
  }

  // Generate chart engineHours
  generateAssetGroups(data) {
    if (data == null) {
      return;
    } else {
      data = data.filter((x) => x?.filtered == false);
    }

    let assetGroups = this.assetGroupInputComponent.assetGroups.map((x) => ({
      id: x.id,
      name: x.displayName,
      count: 0,
      deviceState: [],
    }));
    assetGroups.forEach((assetGroup) => {
      assetGroup.count = data.filter(
        (x) => x.data.assetGroupIds != null && contains(x.data.assetGroupIds, assetGroup.id.toString())
      )?.length;

      data
        .filter((x) => x.data.assetGroupIds != null && contains(x.data.assetGroupIds, assetGroup.id.toString()))
        .forEach((device) => {
          if (assetGroup.deviceState[device.data.deviceState]) {
            assetGroup.deviceState[device.data.deviceState] += 1;
          } else {
            assetGroup.deviceState[device.data.deviceState] = 1;
          }
        });
    });

    assetGroups = assetGroups.filter((x) => x.count > 0).sort((a, b) => (a.count > b.count ? -1 : 1));

    const series = [
      {
        name: this.translateService.instant("general.deviceState_1"),
        color: "#008E43",
        data: assetGroups.map((x) => {
          return { y: x.deviceState[1] ?? 0, id: x.id };
        }),
      },
      {
        name: this.translateService.instant("general.deviceState_2"),
        color: "#971C24",
        data: assetGroups.map((x) => {
          return { y: x.deviceState[2] ?? 0, id: x.id };
        }),
      },
      {
        name: this.translateService.instant("general.deviceState_3"),
        color: "#EE9234",
        data: assetGroups.map((x) => {
          return { 3: x.deviceState[1] ?? 0, id: x.id };
        }),
      },
      {
        name: this.translateService.instant("general.deviceState_4"),
        color: "#206EB4",
        data: assetGroups.map((x) => {
          return { y: x.deviceState[4] ?? 0, id: x.id };
        }),
      },
      {
        name: this.translateService.instant("general.deviceState_5"),
        color: "#00000",
        data: assetGroups.map((x) => {
          return { y: x.deviceState[5] ?? 0, id: x.id };
        }),
      },
      {
        name: this.translateService.instant("general.deviceState_6"),
        color: "#7D177B",
        data: assetGroups.map((x) => {
          return { y: x.deviceState[6] ?? 0, id: x.id };
        }),
      },
    ];

    const xAxis = assetGroups.map((x) => x.name);

    this.chartAssetGroups = this.chartService.generateColumnChart(
      series,
      {},
      xAxis,
      true,
      "/#/AssetGroupDetails/Index/",
      "id"
    );

    this.cd.markForCheck();
  }

  // Generate chart engineHours
  generateCommunicating(data) {
    if (data == null) {
      return;
    } else {
      data = data.filter((x) => x?.filtered == false);
    }

    const deviceState = [];
    deviceState[1] = [];
    deviceState[2] = [];
    deviceState[3] = [];
    deviceState[4] = [];
    deviceState[5] = [];
    deviceState[6] = [];

    data.forEach((device) => {
      if (deviceState[device.data.deviceState]) {
        deviceState[device.data.deviceState].push(device.data.lastCommunication);
      }
    });

    const series = [
      {
        name: this.translateService.instant("general.deviceState_1"),
        color: "#008E43",
        data: [deviceState[1]?.length ?? 0, 0, 0],
      },
      {
        name: this.translateService.instant("general.deviceState_2"),
        color: "#971C24",
        data: [deviceState[2]?.length ?? 0, 0, 0],
      },
      {
        name: this.translateService.instant("general.deviceState_3"),
        color: "#EE9234",
        data: [deviceState[3]?.length ?? 0, 0, 0],
      },
      {
        name: this.translateService.instant("general.deviceState_4"),
        color: "#206EB4",
        data: [deviceState[4]?.length ?? 0, 0, 0],
      },
      {
        name: this.translateService.instant("general.deviceState_5"),
        color: "#00000",
        data: [deviceState[5]?.length ?? 0, 0, 0],
      },
      {
        name: this.translateService.instant("general.deviceState_6"),
        color: "#7D177B",
        data: [deviceState[6]?.length ?? 0, 0, 0],
      },
    ];

    const xAxis = ["Communicating", "Unknown", "Not communicating"];

    this.chartCommunicating = this.chartService.generateColumnChart(series, {}, xAxis, true);

    this.cd.markForCheck();
  }
}
