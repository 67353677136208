import { ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { getDefaultDpConfig, getGridLanguages, getGridButtons, createdCellCheckbox } from "app/common/gridhelper";
import { AuthenticationService } from "app/services/authentication/authentication.service";
import { BsDaterangepickerConfig } from "ngx-bootstrap/datepicker";
import { GridBase360Directive } from "app/common/360Grid.base";
import { forkJoin } from "rxjs";

// Moment timezone
import Moment from "moment-timezone";
import { getIconPath, roundAsNumber, roundAsString, roundMinutes, roundSeconds } from "app/common/globals";
import { AccountService } from "app/services/account/account.service";
import { FhChartService } from "app/services/charts/charts.service";

window["moment"] = Moment;

import * as Highcharts from "highcharts";
import { DeviceTypeService } from "app/services/devicetypes/devicetypes.service";
import { WorkingHoursService } from "app/services/workinghours/workingHours.service";
import { StorageHelper } from "app/common/storagehelper";
import { VehicleType } from "app/common/enums";
import { TriggerScheduleService } from "app/services/triggerSchedule/triggerSchedule.service";
import { ColorService } from "app/services/common/color.service";

@Component({
  providers: [FhChartService],
  selector: "fh-account-equipment",
  templateUrl: "equipment.template.html",
})
export class AccountEquipmentViewComponent extends GridBase360Directive implements OnInit, OnDestroy {
  Highcharts: typeof Highcharts = Highcharts;

  loading: boolean;
  asset: any;
  sub: any;
  messages: any;

  theMarker;

  excludingColumns = ["timestamp"];

  // Datepicker
  public dpConfig: Partial<BsDaterangepickerConfig> = new BsDaterangepickerConfig();
  to: any;
  from: any;
  daterangepickerModel: any[];
  permissions: {};
  languageLoaded: boolean;

  maxDate = new Date();
  sensors = [];
  constructorName = "AccountUtilizationViewComponent";

  filterZeroValues = true;

  timezoneIana: string;

  error: any;
  warning: any;
  success: any;
  limit = 1000;

  loadingLocations = false;
  previousLookupTimestamp;
  locationSubscription: any;
  loadingLocation: boolean;

  updatesActive = true;
  isLoaded = false;
  loadingCount = false;
  randomKey: number;
  distanceChartData = [];
  devices = [];
  chartDistance;
  chartLocationCount;
  chartActiveDevices;
  chartDistanceCompare;
  chartUtilization;
  kpis;

  selectedResellerId;
  selectedAccountId;
  selectedAssetGroup = null;
  selectedAssetType = null;
  selectedDeviceType = null;
  selectedProjectId = null;
  selectedVehicleType = null;

  utilizationCalculationFactor = 1;
  deviceTypeOptions = [];
  deviceTypes = [];

  trends: any;
  loadingTrends = false;

  chartEquipmentUtilization;
  chartEquipmentIdling;
  chartEquipmentCrossover;
  trendsLoaded: boolean;
  vehicleTypes: { id: string; name: any }[];

  resellerChanged(resellerId) {
    this.selectedAccountId = null;

    this.selectedResellerId = resellerId;
  }

  accountChanged(accountId) {
    this.selectedAccountId = accountId;
    this.selectedAssetGroup = null;

    this.error = null;
    this.warning = null;

    this.dateChanged(true);
  }

  constructor(
    private cd: ChangeDetectorRef,
    private triggerScheduleService: TriggerScheduleService,
    private accountService: AccountService,
    private workingHoursService: WorkingHoursService,
    private deviceTypeService: DeviceTypeService,
    private chartService: FhChartService,
    private translateService: TranslateService,
    private authenticationService: AuthenticationService,
    private route: ActivatedRoute,
    private router: Router,
    protected storageHelper: StorageHelper,
    private colorService: ColorService
  ) {
    super(storageHelper);

    this.sensors = [];

    this.timezoneIana = this.authenticationService.getTimeZoneIana();

    this.daterangepickerModel = [
      Moment().tz(this.timezoneIana).subtract(15, "days").startOf("day").toDate(),
      Moment().tz(this.timezoneIana).endOf("day").toDate(),
    ];

    this.dpConfig = getDefaultDpConfig(authenticationService);
  }

  ngOnInit() {
    this.vehicleTypes = Object.keys(VehicleType)
      .filter((k) => typeof VehicleType[k] === "string")
      .map((n) => ({ id: n.toString(), name: VehicleType[n] }));

    // Get all the date for dropdown boxes
    forkJoin([this.translateService.get("general.date"), this.deviceTypeService.getDeviceTypes()]).subscribe({
      next: (data) => {
        this.languageLoaded = true;
        this.loading = false;

        this.deviceTypes = data[1].filter((x) => x.deviceCount > 0);
        this.deviceTypes = this.deviceTypes.sort((a, b) => (a.modelName > b.modelName ? 1 : -1));

        this.initGrid();
      },
      error: (err) => {
        this.error = err;
        this.languageLoaded = true;
        this.loading = false;
      },
    });
  }

  actualRound(value, decimals) {
    return roundAsNumber(value, decimals);
  }

  actualRoundMinutes(value) {
    return roundMinutes(value);
  }

  actualRoundSeconds(value) {
    return roundSeconds(value);
  }

  async processData(data) {
    if (this.filterZeroValues) {
      data = data.filter((x) => x.active === true);
    }

    // Set utilization target
    let utilizationCalculator = 168 / 168;
    let scedules = [];

    if (this.utilizationCalculationFactor) {
      if (this.utilizationCalculationFactor === 2) {
        utilizationCalculator = 168 / 40;
      }

      if (this.utilizationCalculationFactor === 3) {
        await this.triggerScheduleService
          .getTriggerSchedulesByAccount(this.selectedAccountId)
          .toPromise()
          .then((schedules) => {
            scedules = schedules;
          });
      }
    }

    if (this.utilizationCalculationFactor) {
      data.forEach((device) => {
        var schedule = scedules.find((x) => x.id == device.assignedScheduleId);
        if (schedule) {
          utilizationCalculator = 168 / this.triggerScheduleService.determineScheduleDuration(schedule);
        }

        device.utilizationCalculator = utilizationCalculator;
        device.utilizationTarget = device.totalDurationInSeconds / utilizationCalculator;

        if (this.utilizationCalculationFactor === 2) {
          device.utilization = device.utilization * utilizationCalculator;
          device.equipmentUtilization = device.equipmentUtilization * utilizationCalculator;
        }

        if (this.utilizationCalculationFactor === 3) {
          device.utilization = device.utilization * utilizationCalculator;
          device.equipmentUtilization = device.equipmentUtilization * utilizationCalculator;
        }

        if (device.utilization > 100) {
          device.utilizationOverUse = device.utilization - 100;
        } else {
          device.utilizationOverUse = null;
        }

        if (device.equipmentUtilization > 100) {
          device.equipmentUtilizationOverUse = device.equipmentUtilization - 100;
        } else {
          device.equipmentUtilizationOverUse = null;
        }
      });

      // Calculate totals
      this.kpis = {
        assetCount: data.length,
        assetCountWithTrips: data.filter((x) => x.tripCount > 0).length,
        tripCount: data.reduce((partial_sum, d) => partial_sum + d.tripCount, 0),
        totalDistance: data.reduce((partial_sum, d) => partial_sum + d.segmentsDistance, 0),
        totalWorkingHours: data.reduce((partial_sum, d) => partial_sum + d.pureDrivingDurationInSeconds, 0),
        segmentsDistancePrivate: data.reduce((partial_sum, d) => partial_sum + d.segmentsDistancePrivate, 0),
        totalDuration: data.reduce((partial_sum, d) => partial_sum + d.tripDurationInSeconds, 0),
        totalIdling: data.reduce((partial_sum, d) => partial_sum + d.idlingDurationInSeconds, 0),
        utilization: data.reduce((partial_sum, d) => partial_sum + d.utilization, 0),
        totalEquipmentIdling: data.reduce((partial_sum, d) => partial_sum + d.equipmentIdlingDurationInSeconds, 0),
        equipmentUtilization: data.reduce((partial_sum, d) => partial_sum + d.equipmentWorkDurationInSeconds, 0),
      };

      const theDataUtilization = [];
      const theContentUtilization = [];
      const categoriesUtilization = [];

      data.sort((a, b) => (a.equipmentUtilization < b.equipmentUtilization ? 1 : -1));
      data.forEach((device) => {
        const utilization = (device.equipmentWorkDurationInSeconds / device.totalDurationInSeconds) * 100;
        categoriesUtilization.push(device.assetName);
        theContentUtilization.push({
          y: roundAsNumber(utilization * utilizationCalculator, 1),
          deviceId: device.deviceId,
        });
      });

      theDataUtilization.push({
        data: theContentUtilization,
        type: "column",
        name: "Efficiency",
        turboThreshold: 5000,
      });

      this.chartUtilization = this.chartService.generateUtilizationScoreChart(
        theDataUtilization,
        categoriesUtilization,
        null,
        " %"
      );

      return data;
    }
  }

  async processTrendsData(data) {
    const categories = [];
    const equipmentUtilizationData = [];
    const equipmentCrossoverData = [];
    const equipmentIdlingData = [];

    let utilizationCalculator = 168 / 168;
    // utilizationCalculator = await this.setUtilizationCalculator(utilizationCalculator);

    data.forEach((value) => {
      const utilization = (value.workDurationInMinutes / value.totalDurationInMinutes) * 100 * utilizationCalculator;
      const crossover = (value.crossOverDurationInMinutes / value.totalDurationInMinutes) * 100;
      const idling = (value.equipmentIdlingDurationInMinutes / value.totalDurationInMinutes) * 100;

      equipmentUtilizationData.push({
        x: Moment.utc(value.endDate).toDate().getTime(),
        y: this.actualRound(utilization, 1),
      });
      equipmentCrossoverData.push({
        x: Moment.utc(value.endDate).toDate().getTime(),
        y: this.actualRound(crossover, 1),
      });
      equipmentIdlingData.push({ x: Moment.utc(value.endDate).toDate().getTime(), y: this.actualRound(idling, 1) });
    });

    const theEquipmentUtilizationData = [
      {
        data: equipmentUtilizationData,
        type: "area",
        name: "",
        dashStyle: "dash",
        fillOpacity: 0.5,
        opacity: 1,
        labels: {
          format: "{value} %",
        },
        marker: {
          enabled: false,
          lineWidth: 1,
          symbol: "square",
        },
      },
    ];

    const theEquipmentCrossoverData = [
      {
        data: equipmentCrossoverData,
        type: "area",
        name: "",
        dashStyle: "dash",
        fillOpacity: 0.5,
        opacity: 1,
        labels: {
          format: "{value} %",
        },
        marker: {
          enabled: false,
          lineWidth: 1,
          symbol: "square",
        },
      },
    ];

    const theEquipmentIdlingData = [
      {
        data: equipmentIdlingData,
        type: "area",
        name: "",
        dashStyle: "dash",
        fillOpacity: 0.5,
        opacity: 1,
        labels: {
          format: "{value} %",
        },
        marker: {
          enabled: false,
          lineWidth: 1,
          symbol: "square",
        },
      },
    ];

    this.chartEquipmentUtilization = this.chartService.generateColumnChartDates(
      theEquipmentUtilizationData,
      {},
      categories,
      null,
      null,
      false,
      " %"
    );
    this.chartEquipmentCrossover = this.chartService.generateColumnChartDates(
      theEquipmentCrossoverData,
      {},
      categories,
      null,
      null,
      false,
      " %"
    );
    this.chartEquipmentIdling = this.chartService.generateColumnChartDates(
      theEquipmentIdlingData,
      {},
      categories,
      null,
      null,
      false,
      " %"
    );

    this.cd.markForCheck();
  }

  dateChanged(event) {
    console.log("Changed date");

    if (event !== null) {
      this.error = null;
      this.warning = null;

      this.datatableElement?.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.ajax.reload();
      });
    }
  }

  cancel() {
    // this.mySubscription.unsubscribe();

    this.loading = false;

    this.warning = {};
    this.warning.warning = "Call was cancelled.";
    this.warning.statusText = "Warning";
  }

  initGrid(): void {
    console.log("Init grid");

    const commonExportOptions = {
      modifier: {
        page: "all",
        search: "none",
      },
      columns: ["id_export:name", ":visible[tabindex]"],
    };

    this.columns = [
      {
        name: "deviceId",
        data: "deviceId",
        visible: false,
        defaultContent: "-",
        title: this.translateService.instant("general.deviceId"),
      },
      {
        name: "assetId",
        data: "assetId",
        visible: false,
        defaultContent: "-",
        title: this.translateService.instant("general.assetId"),
      },
      {
        name: "accountId",
        data: "accountId",
        visible: false,
        defaultContent: "-",
        title: this.translateService.instant("general.accountId"),
      },
      {
        name: "assetName",
        data: "assetName",
        defaultContent: "-",
        title: this.translateService.instant("general.assetName"),
        render: (data, type, row) => {
          if (type && type === "display") {
            const imgSrc =
              row.iconId > 0
                ? getIconPath(row.iconId)[1]
                : "/assets/images/icons/vista/Trucks/32x32/TankerTruck_Black.png";

            const icon = row.iconId
              ? `<img style="height: 32px; width: 32px; margin-top: -20px; margin-bottom: -10px;" src="${imgSrc}">&nbsp;&nbsp;`
              : "";
            return `<a class='secondary link_bolderd' title='${this.translateService.instant(
              "general.details"
            )}' href='/#/DeviceDetails/Index/${row.deviceId}'>${icon}${data}</a>`;
          }
          return data;
        },
      },
      {
        name: "plateNumber",
        data: "plateNumber",
        defaultContent: "-",
        title: this.translateService.instant("general.plateNumber"),
      },
      {
        name: "brand",
        data: "brand",
        defaultContent: "-",
        title: this.translateService.instant("general.brand"),
      },
      {
        name: "model",
        data: "model",
        defaultContent: "-",
        title: this.translateService.instant("general.model"),
      },
      {
        name: "unitId",
        data: "unitId",
        defaultContent: "-",
        title: this.translateService.instant("general.unitId"),
      },
      {
        name: "active",
        data: "active",
        type: "checkBox",
        defaultContent: "",
        title: this.translateService.instant("general.active"),
        visible: true,
        createdCell: createdCellCheckbox,
        width: "40",
      },
      {
        name: "vehicleType",
        data: "vehicleType",
        title: this.translateService.instant("general.vehicleType"),
        render: (data, type, row) => {
          return this.translateService.instant("enums.vehicleType." + data);
        },
      },
      {
        name: "assetTypeName",
        data: "assetTypeName",
        defaultContent: "-",
        title: this.translateService.instant("general.assetType"),
        render: (data, type, row) => {
          return data;
        },
      },
      {
        name: "projectName",
        data: "projectName",
        defaultContent: "-",
        title: this.translateService.instant("general.projectName"),
        render: (data, type, row) => {
          return data;
        },
      },
      {
        name: "equipmentUtilization",
        data: "equipmentUtilization",
        title: this.translateService.instant("general.equipmentUtilizationPercentage"),
        render: (data, type, row) => {
          if (type && type === "display") {
            return data ? `${roundAsString(data, 2)} %` : "-";
          }
          return data;
        },
      },
      {
        name: "equipmentUtilizationOverUse",
        data: "equipmentUtilizationOverUse",
        title: this.translateService.instant("general.equipmentUtilizationOverUse"),
        render: (data, type, row) => {
          if (type && type === "display") {
            return data ? `${roundAsString(data, 2)} %` : "-";
          }
          return data;
        },
      },
      {
        name: "totalDurationInSeconds",
        data: "totalDurationInSeconds",
        visible: false,
        title: this.translateService.instant("general.totalDuration"),
        render: (data, type, row) => {
          if (type && type === "display") {
            return data ? roundSeconds(data) : "-";
          }
          return data;
        },
      },
      {
        name: "workingHoursInSeconds",
        data: "workingHoursInSeconds",
        visible: false,
        title: this.translateService.instant("general.workingHours"),
        render: (data, type, row) => {
          if (type && type === "display") {
            return data ? roundSeconds(data) : "-";
          }
          return data;
        },
      },
      {
        name: "pureDriving",
        data: "pureDrivingDurationInSeconds",
        visible: false,
        title: this.translateService.instant("general.pureDriving"),
        render: (data, type, row) => {
          if (type && type === "display") {
            return data ? roundSeconds(data) : "-";
          }
          return data;
        },
      },
      {
        name: "idlingDurationInSeconds",
        data: "idlingDurationInSeconds",
        visible: false,
        title: this.translateService.instant("general.idlingDuration"),
        render: (data, type, row) => {
          if (type && type === "display") {
            return data ? roundSeconds(data) : "-";
          }
          return data;
        },
      },
      {
        name: "idlingPercentage",
        data: "idlingDurationInSeconds",
        visible: false,
        title: this.translateService.instant("general.idlingPercentage"),
        render: (data, type, row) => {
          if (type && type === "display") {
            return data
              ? `${roundAsString((row.idlingDurationInSeconds / row.totalDurationInSeconds) * 100, 2)} %`
              : "-";
          }
          return data;
        },
      },
      {
        name: "equipmentWorkDurationInSeconds",
        data: "equipmentWorkDurationInSeconds",
        title: this.translateService.instant("general.equipmentWorkDurationInSeconds"),
        render: (data, type, row) => {
          if (type && type === "display") {
            return data ? roundSeconds(data) : "-";
          }
          return data;
        },
      },
      {
        name: "equipmentCrossOverDurationInSeconds",
        data: "equipmentCrossOverDurationInSeconds",
        title: this.translateService.instant("general.equipmentCrossOverDurationInSeconds"),
        render: (data, type, row) => {
          if (type && type === "display") {
            return data ? roundSeconds(data) : "-";
          }
          return data;
        },
      },
      {
        name: "equipmentIdlingDurationInSeconds",
        data: "equipmentIdlingDurationInSeconds",
        title: this.translateService.instant("general.equipmentIdlingDurationInSeconds"),
        render: (data, type, row) => {
          if (type && type === "display") {
            return data ? roundSeconds(data) : "-";
          }
          return data;
        },
      },
      {
        name: "equipmentIdlingPercentage",
        data: "equipmentIdlingPercentage",
        title: this.translateService.instant("general.equipmentIdlingPercentage"),
        render: (data, type, row) => {
          if (type && type === "display") {
            return data ? `${roundAsString(data, 2)} %` : "-";
          }
          return data;
        },
      },
      {
        name: "utilizationTarget",
        data: "utilizationTarget",
        visible: false,
        title: this.translateService.instant("general.utilizationTarget"),
        render: (data, type, row) => {
          if (type && type === "display") {
            return data ? roundSeconds(data) : "-";
          }
          return data;
        },
      },
      {
        name: "utilizationCalculator",
        data: "utilizationCalculator",
        visible: false,
        title: this.translateService.instant("general.utilizationCalculator"),
        render: (data, type, row) => {
          if (type && type === "display") {
            return data ? `${roundAsString(1 / data, 2)}x (${(1 / data) * 168} per week)` : "-";
          }
          return data;
        },
      },
      {
        name: "assetGroups",
        data: "assetGroups",
        title: this.translateService.instant("general.assetGroups"),
        render: (data, type, row) => {
          if (type && type === "display") {
            return data ? data.map((k) => k.name).join(", ") : "-";
          }
          return null;
        },
      },
    ];

    this.dtOptions = {
      buttons: getGridButtons(
        this.commonExportOptions,
        "utilization_overview",
        this.translateService.instant("menu.messagesoverview"),
        this.colorService
      ),
      pagingType: "simple_numbers",
      serverSide: false,
      processing: false,
      scrollX: true,
      colReorder: { fixedColumnsLeft: 1 },
      order: [[3, "asc"]],
      stateSave: true,
      stateSaveCallback: (settings, data) => {
        this.saveState(this.constructorName, data);
      },
      stateLoadCallback: (_, callback) => {
        (async () => {
          try {
            const columnSettings = await this.loadState(this.constructorName);
            this.searchTerm = columnSettings && columnSettings.search && columnSettings.search.search;
            return columnSettings;
          } catch (e) {
            this.error = {};
            this.error.error = e;
            this.error.statusText = "Error fetching column settings";

            return null;
          }
        })().then((result) => {
          callback(result);
        });
      },
      ajax: (dataTablesParameters: any, callback) => {
        $(".dataTables_info").html(this.translateService.instant("grid.loadingData"));

        this.loading = true;
        this.accountService
          .getAccountUtilization(
            this.selectedAccountId,
            Moment.utc(this.daterangepickerModel[0]).tz(this.timezoneIana).startOf("day"),
            Moment.utc(this.daterangepickerModel[1]).tz(this.timezoneIana).endOf("day"),
            false,
            this.selectedVehicleType,
            this.selectedAssetType,
            this.selectedDeviceType,
            this.selectedProjectId,
            this.selectedAssetGroup,
            true
          )
          .subscribe({
            next: async (resp) => {
              this.devices = await this.processData(resp);
              this.fetchTrends();
              this.loading = false;
              callback({
                recordsTotal: resp.length,
                recordsFiltered: resp.length,
                data: this.devices,
              });
            },
            error: (error) => {
              this.success = null;
              this.error = error;
              this.error.statusText = "Error fetching data";
              this.loading = false;
            },
          });
      },
      initComplete: (settings, json) => {
        this.loading = false;
        this.loadingLocations = false;

        console.log("init complete");
        this.checkFilters();
        this.drawFilterRow();
        this.loading = false;
      },
      colVis: {
        restore: this.translateService.instant("general.restore"),
        showAll: this.translateService.instant("general.showAll"),
        showNone: this.translateService.instant("general.hideAll"),
      },
      columns: this.columns,
      pageLength: 25,
      lengthMenu: [
        [10, 17, 25, 50, 200, -1],
        [10, 17, 25, 50, 200, this.translateService.instant("general.all")],
      ],
      language: getGridLanguages(this.translateService),
      rowCallback: (row, data) => {},
    };
  }

  fetchTrends() {
    this.loadingTrends = true;

    this.error = null;
    this.warning = null;

    this.accountService
      .getAccountTrends(
        this.selectedAccountId,
        1,
        0,
        Moment.utc(this.daterangepickerModel[0]).tz(this.timezoneIana).startOf("day"),
        Moment.utc(this.daterangepickerModel[1]).tz(this.timezoneIana).endOf("day"),
        {
          targetIdling: 0,
          targetDistance: 0,
          targetUtilization: 0,
        },
        this.selectedVehicleType,
        this.selectedAssetType,
        this.selectedDeviceType,
        this.selectedProjectId,
        this.selectedAssetGroup,
        true
      )
      .subscribe({
        next: (resp) => {
          this.trends = resp;

          if (resp.length === 0) {
            this.warning = {};
            this.warning.warning = "No data found for selection.";
            this.warning.statusText = "Warning";
          }

          this.processTrendsData(resp);
          this.loadingTrends = false;
        },
        error: (error) => {
          this.loadingTrends = false;
          this.error = error;
        },
      });
  }
}
