import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { RegisterOperatingCompany } from "app/models/account.model";
import { RegisterOperatingAsset, VehiclePlate, WaslAsset } from "app/models/asset.model";
import { RegisterOperatingDriver } from "app/models/driver.model";

import { throwError } from "rxjs/internal/observable/throwError";
import { Observable, catchError } from "rxjs";
import { AuthenticationService } from "../authentication/authentication.service";

@Injectable()
export class WaslService {
  timestamp;
  url = "";

  constructor(private http: HttpClient, private authenticationService: AuthenticationService) {
    this.url = this.authenticationService.getWebserviceURL("");
  }

  getInquireAccount(assetId: number, thirdPartyProvider: string): Observable<any> {
    console.log("Fetch account by id " + assetId);
    return this.http
      .get(this.url + "Account/" + assetId + "/Inquire/" + thirdPartyProvider, {
        headers: this.authenticationService.headers,
      })
      .pipe(catchError(this.handleError));
  }

  getInquireVehicle(assetId: number, thirdPartyProvider: string): Observable<any> {
    console.log("Inquire device by id " + assetId);
    return this.http
      .get(this.url + "Asset/" + assetId + "/InquireVehicle/" + thirdPartyProvider, {
        headers: this.authenticationService.headers,
      })
      .pipe(catchError(this.handleError));
  }

  getInquireDriver(driver: string, thirdPartyProvider: string): Observable<any> {
    console.log("Inquire driver by id " + driver);
    return this.http
      .get(this.url + "Driver/" + driver + "/InquireDriver/" + thirdPartyProvider, {
        headers: this.authenticationService.headers,
      })
      .pipe(catchError(this.handleError));
  }

  registerAsset(
    assetId: any,
    unitId: any,
    waslAsset: RegisterOperatingAsset,
    thirdPartyProvider: string
  ): Observable<any> {
    console.log("register asset");

    const waslPostAsset = new WaslAsset();
    waslPostAsset.imeiNumber = unitId;
    waslPostAsset.storingCategory = waslAsset.storingCategory;
    waslPostAsset.plateType = waslAsset.plateType;
    waslPostAsset.referenceKey = waslAsset.referenceKey;
    waslPostAsset.sequenceNumber = waslAsset.sequenceNumber;

    waslPostAsset.registerDateWasl = waslAsset.registerDateWasl;
    waslPostAsset.registerDateSfda = waslAsset.registerDateSfda;
    waslPostAsset.registerDateTow = waslAsset.registerDateTow;

    waslPostAsset.vehiclePlate = new VehiclePlate();
    waslPostAsset.vehiclePlate.number = waslAsset.vehiclePlateNumber;
    waslPostAsset.vehiclePlate.leftLetter = waslAsset.vehiclePlateLeftLetter;
    waslPostAsset.vehiclePlate.middleLetter = waslAsset.vehiclePlateMiddleLetter;
    waslPostAsset.vehiclePlate.rightLetter = waslAsset.vehiclePlateRightLetter;

    return this.http
      .post(
        this.url +
          "Asset/" +
          assetId +
          "/Vehicle_Register/" +
          thirdPartyProvider +
          "?accountId=" +
          waslAsset.overrideAccountId,
        waslPostAsset,
        { headers: this.authenticationService.headers }
      )
      .pipe(catchError(this.handleError));
  }

  unregisterAsset(assetId: any, overrideAccountId: any, thirdPartyProvider: string): Observable<any> {
    console.log("unregister asset");

    return this.http
      .post(
        this.url + "Asset/" + assetId + "/Vehicle_Unregister/" + thirdPartyProvider + "?accountId=" + overrideAccountId,
        null,
        { headers: this.authenticationService.headers }
      )
      .pipe(catchError(this.handleError));
  }

  registerCompany(accountId: any, waslCompany: RegisterOperatingCompany, thirdPartyProvider: string): Observable<any> {
    console.log("register asset");

    return this.http
      .post(this.url + "Account/" + accountId + "/Company_Register/" + thirdPartyProvider, waslCompany, {
        headers: this.authenticationService.headers,
      })
      .pipe(catchError(this.handleError));
  }

  unregisterCompany(accountId: any, thirdPartyProvider: string): Observable<any> {
    console.log("unregister asset");

    return this.http
      .post(this.url + "Account/" + accountId + "/Company_Unregister/" + thirdPartyProvider, null, {
        headers: this.authenticationService.headers,
      })
      .pipe(catchError(this.handleError));
  }

  updateStoringCategory(assetId: any, storingCategory: string): Observable<any> {
    console.log("update asset storing category");
    const object = {
      assetId: assetId,
      storingCategory: storingCategory,
    };

    return this.http
      .post(this.url + "Asset/" + assetId + "/Vehicle_UpdateStoringCategory", object, {
        headers: this.authenticationService.headers,
      })
      .pipe(catchError(this.handleError));
  }

  // Warehouses

  getInquireAccountWarehouses(accountId: number, companyReference: string): Observable<any> {
    console.log("Fetch warehouse by accountid " + accountId);
    return this.http
      .get(this.url + "Account/" + accountId + "/Warehouses/" + companyReference, {
        headers: this.authenticationService.headers,
      })
      .pipe(catchError(this.handleError));
  }

  registerWarehouse(warehouseId: any, warehouse: any, companyReference: string): Observable<any> {
    console.log("register warehouse");

    return this.http
      .post(this.url + "Warehouse/" + warehouseId + "/Warehouse_Register/" + companyReference, warehouse, {
        headers: this.authenticationService.headers,
      })
      .pipe(catchError(this.handleError));
  }

  unregisterWarehouse(warehouseId: any): Observable<any> {
    console.log("unregister warehouse");

    return this.http
      .post(this.url + "Warehouse/" + warehouseId + "/Warehouse_Unregister", null, {
        headers: this.authenticationService.headers,
      })
      .pipe(catchError(this.handleError));
  }

  registerInventory(warehouseId: any, inventory, inventoryIdentifier): Observable<any> {
    console.log("register inventory");

    return this.http
      .post(this.url + "Warehouse/" + warehouseId + "/Inventory_Register/" + inventoryIdentifier, inventory, {
        headers: this.authenticationService.headers,
      })
      .pipe(catchError(this.handleError));
  }

  unregisterInventory(warehouseId: any, inventoryIdentifier): Observable<any> {
    console.log("unregister inventory");

    return this.http
      .post(this.url + "Warehouse/" + warehouseId + "/Inventory_Unregister/" + inventoryIdentifier, null, {
        headers: this.authenticationService.headers,
      })
      .pipe(catchError(this.handleError));
  }

  registerDriver(driverId: any, waslDriver: RegisterOperatingDriver, thirdPartyProvider: string): Observable<any> {
    console.log("register driver");

    return this.http
      .post(this.url + "Driver/" + driverId + "/Driver_Register/" + thirdPartyProvider, waslDriver, {
        headers: this.authenticationService.headers,
      })
      .pipe(catchError(this.handleError));
  }

  unregisterDriver(driverId: any, thirdPartyProvider: string): Observable<any> {
    console.log("unregister driver");

    return this.http
      .post(this.url + "Driver/" + driverId + "/Driver_Unregister/" + thirdPartyProvider, null, {
        headers: this.authenticationService.headers,
      })
      .pipe(catchError(this.handleError));
  }

  private handleError(error: Response) {
    return throwError(() => error);
  }
}
