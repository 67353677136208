import { Component, EventEmitter, Input, Output, TemplateRef } from "@angular/core";
import { isNil } from "lodash";
import { TreeviewItem } from "../../models/treeview-item";
import { TreeviewConfig } from "../../models/treeview-config";
import { TreeviewItemTemplateContext } from "../../models/treeview-item-template-context";

@Component({
  selector: "ngx-treeview-item",
  templateUrl: "./treeview-item.component.html",
  styleUrls: ["./treeview-item.component.scss"],
})
export class TreeviewItemComponent {
  @Input() config: TreeviewConfig;
  @Input() template: TemplateRef<TreeviewItemTemplateContext>;
  @Input() item: TreeviewItem;
  @Output() checkedChange = new EventEmitter<boolean>();

  constructor(private defaultConfig: TreeviewConfig) {
    this.config = this.defaultConfig;
  }

  onCollapseExpand = () => {
    this.item.collapsed = !this.item.collapsed;
  };

  ngOnChanges() {
    // Sort
    this.item.children?.sort((a, b) => {
      if (a.children && !b.children) return -1;
      if (!a.children && b.children) return 1;

      // If both or neither have children, sort alphabetically by text
      return a.text.localeCompare(b.text);
    });
  }

  onCheckedChange = () => {
    const checked = this.item.checked;
    if (!isNil(this.item.children)) {
      this.item.children.forEach((child) => child.setCheckedRecursive(checked));
    }
    this.checkedChange.emit(checked);
  };

  onChildCheckedChange(child: TreeviewItem, checked: boolean): void {
    let itemChecked: boolean = null;
    for (const childItem of this.item.children) {
      if (itemChecked === null) {
        itemChecked = childItem.checked;
      } else if (itemChecked !== childItem.checked) {
        itemChecked = undefined;
        break;
      }
    }

    if (itemChecked === null) {
      itemChecked = false;
    }

    if (this.item.checked !== itemChecked) {
      this.item.checked = itemChecked;
    }
    this.checkedChange.emit(checked);
  }
}
