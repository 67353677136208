<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12 flex header-content">
        <nav class="breadcrumb-container" aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a href="/#/"> <i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
                </li>
                <li class="breadcrumb-item" *ngIf="user?.accountId">
                    <a href="/#/AccountDetails/Index/{{user?.accountId}}">{{user?.companyName}}</a>
                </li>
                <li class="breadcrumb-item">
                    <a href="/#/Users/Overview">{{'general.users' | translate }}</a>
                </li>
                <li class="breadcrumb-item">
                    <a href="/#/UserDetails/Index/{{user?.id}}">{{user?.name}}</a>
                </li>
                <li class="breadcrumb-item active">{{'general.dashboard' | translate }}</li>
            </ol>
        </nav>
    </div>
    <fh-user-details-navigation [userId]="userId"></fh-user-details-navigation>
</div>

<fh-permission-handler [permissionName]="permissionName">
    <div class="wrapper wrapper-content">
        <div class="row" data-equalizer="">
            <div class="col-lg-6">
                <fh-error [error]="error" [success]="success" [copyText]="copyText" [autoRemove]="autoRemove">
                </fh-error>

                <div class="ibox">
                    <div class="ibox-title">
                        <h5><i class="fas fa-fw fa-users"></i> {{ user?.name }}</h5>
                    </div>
                    <form #userForm="ngForm" style="position: relative">
                        <ngx-loading [show]="loading || saving"></ngx-loading>
                        <fh-user-details [user]="user" [formMode]="formMode"></fh-user-details>
                        <fh-save-footer
                            [valid]="!userForm.invalid"
                            [allowDelete]="permissions['Users_Delete']"
                            (onDelete)="onDelete()"
                            (onSave)="onSave()"
                            [allowEdit]="permissions['Users_Edit']"
                            (setFormMode)="setFormMode($event)"
                            [formMode]="formMode"
                            [showButtons]="true"
                        >
                            <span class="float-right">
                                <div
                                    class="ibox-content slick"
                                    *ngIf="formMode == 1 && (permissions['Platform_IsReseller'] || permissions['Platform_IsFleetManager'])"
                                    style="position: relative; border: 0"
                                >
                                    <div dropdown [dropup]="true" style="display: flex; width: 100%">
                                        <button
                                            style="margin-right: 0"
                                            type="button"
                                            *ngIf="permissions['Platform_IsReseller']"
                                            (click)="impersonate()"
                                            title="{{'general.impersonate' | translate }}"
                                            class="btn btn-info"
                                        >
                                            <i class="fa fa-fw fa-user-secret"></i>
                                            <span class="d-none d-md-inline-flex" style="padding-left: 7px">
                                                {{'general.impersonate' | translate }}</span
                                            >
                                        </button>
                                        <button
                                            id="button-basic"
                                            dropdownToggle
                                            type="button"
                                            class="btn btn-info dropdown-toggle dropdown-toggle-split"
                                            aria-controls="dropdown-basic"
                                            style="margin-right: 0"
                                        >
                                            <span
                                                *ngIf="!permissions['Platform_IsReseller']"
                                                style="padding-right: 10px"
                                            >
                                                {{'general.actions' | translate }}</span
                                            >
                                            <span class="sr-only">Toggle Dropright</span>
                                            <span class="caret"></span>
                                        </button>
                                        <ul
                                            id="dropdown-basic"
                                            *dropdownMenu
                                            class="dropdown-menu dropdown-menu-fix dropdown-menu-right"
                                            role="menu"
                                            aria-labelledby="button-basic"
                                        >
                                            <li role="menuitem" *ngIf="!isUserDisabled">
                                                <a
                                                    class="dropdown-item"
                                                    title="{{'general.disableUser' | translate }}"
                                                    (click)="disableUser()"
                                                >
                                                    <i class="fa fa-fw fa-user-slash"></i>
                                                    <span style="padding-left: 7px">
                                                        {{'general.disableUser' | translate }}</span
                                                    ></a
                                                >
                                            </li>
                                            <li role="menuitem" *ngIf="isUserDisabled">
                                                <a
                                                    class="dropdown-item"
                                                    title="{{'general.enableUser' | translate }}"
                                                    (click)="enableUser()"
                                                >
                                                    <i class="fa fa-fw fa-user-check"></i>
                                                    <span style="padding-left: 7px">
                                                        {{'general.enableUser' | translate }}</span
                                                    ></a
                                                >
                                            </li>
                                            <li role="menuitem" *ngIf="!onlySSO">
                                                <a
                                                    class="dropdown-item"
                                                    title="{{'general.temporaryPassword' | translate }}"
                                                    (click)="temporaryPassword()"
                                                >
                                                    <i class="fa fa-fw fa-timer"></i>
                                                    <span style="padding-left: 7px">
                                                        {{'general.temporaryPassword' | translate }}</span
                                                    ></a
                                                >
                                            </li>
                                            <li role="menuitem" *ngIf="!onlySSO">
                                                <a
                                                    class="dropdown-item"
                                                    title="{{'general.resetPassword' | translate }}"
                                                    (click)="changePassword()"
                                                >
                                                    <i class="fa fa-fw fa-key"></i>
                                                    <span style="padding-left: 7px">
                                                        {{'general.resetPassword' | translate }}</span
                                                    ></a
                                                >
                                            </li>
                                            <li role="menuitem" *ngIf="!onlySSO">
                                                <a
                                                    class="dropdown-item"
                                                    title="{{'general.newUserWelcome' | translate }}"
                                                    (click)="newUserWelcome()"
                                                >
                                                    <i class="fa fa-fw fa-key"></i>
                                                    <span style="padding-left: 7px">
                                                        {{'general.newUserWelcome' | translate }}</span
                                                    ></a
                                                >
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </span>
                        </fh-save-footer>
                    </form>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="row7">
                    <div class="col-lg-4 col-md-12">
                        <div class="ibox hand">
                            <div
                                class="kpi-item"
                                style="position: relative"
                                [routerLink]="['/Devices/Overview/']"
                                [queryParams]="{companyName: user?.linkName}"
                                title="{{'general.activeAssetsHover' | translate:{ value: ''} }}"
                            >
                                <h3 class="margin-bottom font-bold">
                                    <i class="fas fa-fw fa-car"></i>
                                    {{activeDevices?.toLocaleString() }}
                                </h3>
                                <div>{{'general.activeAssets' | translate }}</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-12">
                        <div class="ibox hand">
                            <div
                                class="kpi-item"
                                style="position: relative"
                                [routerLink]="['/Drivers/Overview/']"
                                [queryParams]="{companyName: user?.linkName}"
                                title="{{'general.activeAssetsHover' | translate:{ value: ''} }}"
                            >
                                <h3 class="margin-bottom font-bold">
                                    <i class="fas fa-fw fa-id-card-alt"></i>
                                    {{activeDrivers?.toLocaleString() }}
                                </h3>
                                <div>{{'general.drivers' | translate }}</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-12">
                        <div class="ibox hand">
                            <div
                                class="kpi-item"
                                style="position: relative"
                                [routerLink]="['/Geofences/Overview/']"
                                [queryParams]="{companyName: user?.linkName}"
                                title="{{'general.activeAssetsHover' | translate:{ value: ''} }}"
                            >
                                <h3 class="margin-bottom font-bold">
                                    <i class="fas fa-fw fa-draw-polygon"></i>
                                    {{activeGeofences?.toLocaleString() }}
                                </h3>
                                <div>{{'general.geofences' | translate }}</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div style="clear: both"></div>

                <div *ngIf="!loading && user && !user.viewerId" class="alert alert-warning shadow">
                    <span>
                        <p style="font-size: 15px"><strong> {{'general.warning' | translate }}</strong></p>
                    </span>
                    <span>
                        <p style="margin: 0">{{'general.noViewerActive' | translate }}</p>
                    </span>
                </div>

                <div class="ibox" *ngIf="assignMode">
                    <div class="ibox-title">
                        <h5><i class="fa fa-fw fa-podcast"></i> {{ 'general.viewerGroups' | translate }}</h5>
                        <span class="float-right">
                            <div class="filter-form d-none d-md-block">
                                <input
                                    type="text"
                                    name="filter"
                                    class="form-control table-inline top-filter d-none d-md-block"
                                    [(ngModel)]="filter"
                                />
                            </div>
                        </span>
                    </div>
                    <div class="ibox-content slick" style="position: relative; overflow-y: auto">
                        <ngx-loading [show]="loading || loadingAssetGroups"></ngx-loading>
                        <table
                            width="100%"
                            cellpadding="0"
                            cellspacing="0"
                            class="table nowrap dtr-inline no-footer"
                            style="padding: 0px"
                        >
                            <thead>
                                <tr>
                                    <th style="width: 50px">
                                        <div class="fa_checkbox">
                                            <input
                                                [checked]="checkedAllGroups()"
                                                (change)="selectAllGroups($event)"
                                                id="all_viewerGroups"
                                                type="checkbox"
                                                name="all_viewerGroups"
                                            />
                                            <label for="all_viewerGroups"> </label>
                                        </div>
                                    </th>
                                    <th style="width: auto">{{ 'general.name' | translate}}</th>
                                    <th style="width: 70px">{{ 'general.deviceCount' | translate}}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    *ngFor="let group of assetGroups | orderBy : 'groupName':false | stringFilter: filter | paginate: { itemsPerPage: 10, currentPage: currentPage, id: 'assetGroupsPages'}"
                                >
                                    <td style="width: 50px">
                                        <div class="fa_checkbox" title="{{  group.groupName  }}">
                                            <input
                                                [checked]="true"
                                                [disabled]="group.userId == user.id"
                                                [id]="'g_' + group.id"
                                                type="checkbox"
                                                [name]="'g_' + group.id"
                                                [(ngModel)]="group.isChecked"
                                            />
                                            <label [for]="'g_' + group.id"> </label>
                                        </div>
                                    </td>
                                    <td title="{{ group.groupName }} {{ group.ownerName }}">
                                        {{ group.groupName }} ({{ group.accountName }})
                                    </td>
                                    <td>({{ group.assetCount }})</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="ibox-footer noselect dataTables_wrapper">
                        <button
                            class="btn btn-primary"
                            (click)="saveAccountViewers()"
                            [disabled]="loadingAssetGroups || loading || !user.viewerId"
                        >
                            <i class="fa fa-fw fa-save"></i> {{'general.save' | translate }}
                        </button>

                        <button class="btn btn-info" (click)="assignMode = !assignMode; fetchAssetGroupSharing();">
                            <i class="fa fa-fw fa-times"></i> {{'general.cancel' | translate }}
                        </button>

                        <pagination-template
                            #pAssetGroups="paginationApi"
                            (pageChange)="currentPage = $event"
                            id="assetGroupsPages"
                        >
                            <div class="dataTables_paginate paging_simple_numbers" id="dataTable_paginate">
                                <ul class="pagination">
                                    <li
                                        class="paginate_button page-item previous"
                                        [class.disabled]="pAssetGroups.isFirstPage()"
                                    >
                                        <a
                                            class="page-link"
                                            (click)="pAssetGroups.previous()"
                                            *ngIf="!pAssetGroups.isFirstPage()"
                                            >{{ 'grid.previous' | translate }}</a
                                        >
                                        <a *ngIf="pAssetGroups.isFirstPage()">{{ 'grid.previous' | translate }}</a>
                                    </li>

                                    <li
                                        class="paginate_button page-item"
                                        *ngFor="let page of pAssetGroups.pages"
                                        [class.current]="pAssetGroups.getCurrent() === page.value"
                                    >
                                        <a
                                            class="page-link"
                                            (click)="pAssetGroups.setCurrent(page.value)"
                                            *ngIf="pAssetGroups.getCurrent() !== page.value"
                                        >
                                            <span>{{ page.label }}</span>
                                        </a>
                                        <a
                                            class="page-link"
                                            (click)="pAssetGroups.setCurrent(page.value)"
                                            *ngIf="pAssetGroups.getCurrent() === page.value"
                                        >
                                            <span style="font-weight: bold">{{ page.label }}</span>
                                        </a>
                                    </li>

                                    <li
                                        class="paginate_button page-item next"
                                        [class.disabled]="pAssetGroups.isLastPage()"
                                    >
                                        <a
                                            class="page-link"
                                            (click)="pAssetGroups.next()"
                                            *ngIf="!pAssetGroups.isLastPage()"
                                        >
                                            {{ 'grid.next' | translate }}
                                        </a>
                                        <a class="page-link" *ngIf="pAssetGroups.isLastPage()">
                                            {{ 'grid.next' | translate }}
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </pagination-template>
                        <div style="clear: both"></div>
                    </div>
                </div>

                <div class="ibox" *ngIf="!assignMode">
                    <div class="ibox-title">
                        <h5><i class="fa fa-fw fa-podcast"></i> {{ 'general.viewerGroups' | translate }}</h5>
                        <span class="float-right">
                            <div class="filter-form d-none d-md-block">
                                <input
                                    type="text"
                                    name="filter"
                                    class="form-control table-inline top-filter d-none d-md-block"
                                    [(ngModel)]="filter"
                                />
                            </div>
                        </span>
                    </div>
                    <div class="ibox-content slick" style="position: relative; overflow-y: auto">
                        <ngx-loading [show]="loading || loadingViewerGroups"></ngx-loading>
                        <table
                            width="100%"
                            cellpadding="0"
                            cellspacing="0"
                            class="table nowrap dtr-inline no-footer"
                            style="padding: 0px"
                        >
                            <thead>
                                <tr>
                                    <th style="width: auto">{{ 'general.name' | translate}}</th>
                                    <th style="width: 70px">{{ 'general.deviceCount' | translate}}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    *ngFor="let group of groupList | orderBy : 'groupName':false | stringFilter: filter | paginate: { itemsPerPage: 10, currentPage: currentPage, id: 'viewerGroupsPages'}"
                                >
                                    <td title="{{ group.groupName }} {{ group.userName }}">
                                        <a href="/#/AssetGroupDetails/Index/{{group.id}}">
                                            <span class="secondary link_bolder"
                                                ><i class="fas fa-fw fa-angle-right"></i
                                            ></span>
                                            {{ group.groupName }} ({{ group.accountName }})</a
                                        >
                                    </td>
                                    <td>{{ group.itemCount ?? group.assetCount }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="ibox-footer noselect dataTables_wrapper">
                        <span style="float: left">
                            <button class="btn btn-primary" (click)="assignMode = !assignMode">
                                <i class="fa fa-fw fa-edit"></i> {{'general.assign' | translate }}
                            </button>
                        </span>

                        <pagination-template
                            #pViewerGroups="paginationApi"
                            (pageChange)="currentPage = $event"
                            id="viewerGroupsPages"
                        >
                            <div class="dataTables_paginate paging_simple_numbers" id="dataTable_paginate">
                                <ul class="pagination">
                                    <li
                                        class="paginate_button page-item previous"
                                        [class.disabled]="pViewerGroups.isFirstPage()"
                                    >
                                        <a
                                            class="page-link"
                                            (click)="pViewerGroups.previous()"
                                            *ngIf="!pViewerGroups.isFirstPage()"
                                            >{{ 'grid.previous' | translate }}</a
                                        >
                                        <a *ngIf="pViewerGroups.isFirstPage()">{{ 'grid.previous' | translate }}</a>
                                    </li>

                                    <li
                                        class="paginate_button page-item"
                                        *ngFor="let page of pViewerGroups.pages"
                                        [class.current]="pViewerGroups.getCurrent() === page.value"
                                    >
                                        <a
                                            class="page-link"
                                            (click)="pViewerGroups.setCurrent(page.value)"
                                            *ngIf="pViewerGroups.getCurrent() !== page.value"
                                        >
                                            <span>{{ page.label }}</span>
                                        </a>
                                        <a
                                            class="page-link"
                                            (click)="pViewerGroups.setCurrent(page.value)"
                                            *ngIf="pViewerGroups.getCurrent() === page.value"
                                        >
                                            <span style="font-weight: bold">{{ page.label }}</span>
                                        </a>
                                    </li>

                                    <li
                                        class="paginate_button page-item next"
                                        [class.disabled]="pViewerGroups.isLastPage()"
                                    >
                                        <a
                                            class="page-link"
                                            (click)="pViewerGroups.next()"
                                            *ngIf="!pViewerGroups.isLastPage()"
                                        >
                                            {{ 'grid.next' | translate }}
                                        </a>
                                        <a class="page-link" *ngIf="pViewerGroups.isLastPage()">
                                            {{ 'grid.next' | translate }}
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </pagination-template>
                        <div style="clear: both"></div>
                    </div>
                </div>

                <fh-tagging [objectId]="user?.id" [objectType]="tagType"> </fh-tagging>
            </div>
        </div>
    </div>

    <fh-confirmation-modal
        [message]="'general.areyousure'"
        [showButtons]="true"
        [invalid]="groupForm.invalid"
        #confirmModal
        (onFinish)="processPasswordChanges($event)"
        (onHide)="resetPasswordChangeModal($event)"
    >
        <h4 header class="modal-title pull-left">
            <i class="fa fa-fw fa-key"></i> {{('general.resetPassword') | translate }}
        </h4>

        <form body #groupForm="ngForm" style="width: 100%; border-top: 0px">
            <!-- Change user password -->
            <table
                *ngIf="userModalAction == UserModalAction.UpdatePassword"
                style="width: 100%"
                class="table table-fixed nowrap dtr-inline no-footer"
            >
                <tbody>
                    <tr>
                        <td style="width: 50px">
                            <div style="margin-left: 20px" class="fa_checkbox">
                                <input
                                    id="radio1"
                                    type="radio"
                                    name="resetUserPassword"
                                    [value]="true"
                                    [(ngModel)]="resetUserPassword"
                                />
                                <label for="radio1"></label>
                            </div>
                        </td>
                        <td style="width: auto">
                            <label for="radio1">{{'general.changePasswordAutomatic' | translate }}</label>
                        </td>
                    </tr>
                    <tr>
                        <td style="width: 50px">
                            <div style="margin-left: 20px" class="fa_checkbox">
                                <input
                                    id="radio2"
                                    type="radio"
                                    name="resetUserPassword"
                                    [value]="false"
                                    [(ngModel)]="resetUserPassword"
                                />
                                <label for="radio2"></label>
                            </div>
                        </td>
                        <td style="width: auto">
                            <div class="wizard-content" style="padding-bottom: 15px; white-space: pre-line">
                                <label for="radio2">{{'general.changePasswordInfo' | translate }}</label>
                            </div>
                            <div class="wizard-content" style="padding-bottom: 25px">
                                <fh-password-input
                                    name="new-password"
                                    autocomplete="off"
                                    [(ngModel)]="newUserPassword"
                                    required
                                    minlength="4"
                                    pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@!%?#.&])[\S]{8,}$"
                                    placeholder="{{ 'placeholder.newPassword' | translate}}"
                                    #password="ngModel"
                                    behaviour="click"
                                >
                                </fh-password-input>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </form>
    </fh-confirmation-modal>
</fh-permission-handler>
