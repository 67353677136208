import { Config } from "app/models/config.model";

export const environment: Config = {
  version: "0.0.0.0",
  production: false,
  AuthenticationUrl: "https://localhost:44315/api/",
  Environment: "local",
  Debug: true,
  SSOEnabled: false,
  SSOAuthenticationUrl: "https://auth.pingone.eu/91c3f74a-4319-43db-b51d-246351d9a2bb/as",
  SSOClientId: "3e5208c8-df66-4d92-b50e-51c8644ba16b",
  SSORedirectUrl: "http://localhost:4200/Auth-callback",
  SSOPostLogoutRedirectUrl: "http://localhost:4200/#/Login",
  SSOClientScope: "openid email",
  ApplicationName: "360Locate - Fleet Management",
  OnlySSO: false,
};
