import { ChangeDetectorRef, Component, OnDestroy } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { AuthenticationService } from "app/services/authentication/authentication.service";

import { GridBase360Directive } from "app/common/360Grid.base";
import { getDefaultDpConfig, getGridButtons, getGridLanguages } from "app/common/gridhelper";
import { BsDaterangepickerConfig } from "ngx-bootstrap/datepicker";

import { CarSharingService } from "app/services/carsharing/carsharing.service";
import { StorageHelper } from "app/common/storagehelper";

// Moment
import Moment from "moment-timezone";
import { ColorService } from "app/services/common/color.service";

window["moment"] = Moment;

@Component({
  selector: "fh-garages",
  templateUrl: "garages.template.html",
})
export class CarSharingGaragesViewComponent extends GridBase360Directive implements OnDestroy {
  loading = false;

  permissions = {};

  timezoneIana: string;

  languageLoaded = false;
  episodesLoaded = false;

  randomKey: number;

  permissionName = "Modules_HasPrivateFleet";
  constructorName = "CarSharingGarageViewComponent";

  error;
  success;

  // Daterange
  public dpConfig: Partial<BsDaterangepickerConfig> = new BsDaterangepickerConfig();
  daterangepickerModel: Date[];

  constructor(
    private carsharingService: CarSharingService,
    private translateService: TranslateService,
    private authenticationService: AuthenticationService,
    private cd: ChangeDetectorRef,
    protected storageHelper: StorageHelper,
    private colorService: ColorService
  ) {
    super(storageHelper);

    this.permissions = this.authenticationService.permissions;

    this.timezoneIana = this.authenticationService.getTimeZoneIana();

    this.daterangepickerModel = [
      Moment().tz(this.timezoneIana).subtract(7, "days").startOf("day").toDate(),
      Moment().tz(this.timezoneIana).endOf("day").toDate(),
    ];

    this.dpConfig = getDefaultDpConfig(authenticationService);

    this.randomKey = Math.floor(Math.random() * (999999 - 100000)) + 100000;

    // Get all the date for dropdown boxes
    this.translateService.get("general.date").subscribe({
      next: (_) => {
        this.languageLoaded = true;
        this.loading = false;
        this.cd.markForCheck();

        this.initGrid();
      },
      error: (err) => {
        this.error = err;
        this.languageLoaded = true;
        this.loading = false;
        this.cd.markForCheck();
      },
    });
  }

  initGrid(): void {
    this.columns = [
      {
        name: "id",
        data: "id",
        className: "noVis",
        orderable: false,
        title: '<div class="hideDropdown"></div>',
        width: "20",
        render: (data, type, row) => {
          const generalDetails = this.translateService.instant("general.details");
          return `
            <a class='btn btn-primary btn-grid' title='${generalDetails}' href='/#/CarSharing/GarageDetails/Index/${data}'>
                <span class="d-none d-md-inline-flex" style="padding-left: 7px">${generalDetails}</span>
                <i class='fas fa-fw fa-angle-right'></i>
            </a>`;
        },
      },
      {
        name: "name",
        data: "name",
        title: this.translateService.instant("general.name"),
        render: (data, type, row) => {
          return data ?? 0;
        },
      },
      {
        name: "assetCount",
        data: "assetCount",
        type: "num",
        title: this.translateService.instant("general.assetCount"),
        ender: (data, type, row) => {
          return data ?? 0;
        },
      },
      {
        name: "driverCount",
        data: "driverCount",
        type: "num",
        title: this.translateService.instant("general.driverCount"),
        ender: (data, type, row) => {
          return data ?? 0;
        },
      },
      {
        name: "timestamp",
        data: "timestamp",
        type: "date",
        title: this.translateService.instant("general.timestamp"),
        ender: (data, type, row) => {
          const date = Moment.utc(data)["tz"](this.timezoneIana);
          return data ? '<span title=" ' + date.toLocaleString() + '">' + date.format("lll") + "</span>" : "";
        },
      },
    ];

    this.dtOptions = {
      buttons: getGridButtons(
        this.commonExportOptions,
        "carsharing_overview",
        this.translateService.instant("general.carsharing"),
        this.colorService
      ),
      pagingType: "simple_numbers",
      serverSide: true,
      processing: true,
      searchDelay: 500,
      deferRender: true,
      scrollX: true,
      deferLoading: 0,
      stateSave: true,
      stateSaveCallback: (settings, data) => {
        this.saveState(this.constructorName, data);
      },
      stateLoadCallback: (_, callback) => {
        (async () => {
          try {
            const columnSettings = await this.loadState(this.constructorName);
            this.searchTerm = columnSettings && columnSettings.search && columnSettings.search.search;
            return columnSettings;
          } catch (e) {
            this.error = {};
            this.error.error = e;
            this.error.statusText = "Error fetching column settings";

            return null;
          }
        })().then((result) => {
          callback(result);
        });
      },
      order: [[1, "asc"]],
      ajax: {
        beforeSend: () => {
          this.drawFilterRow();
          $(".dataTables_info").html(this.translateService.instant("grid.loadingData"));
        },
        url: this.carsharingService.getGaragesPagingUrl(),
        data: (d) => {
          return d;
        },
        dataSrc: (json) => {
          // // if selection and returning keys
          // if (this.processing.tableIdsSelection && this.processing.tableIdsSelection.length === 0) {
          // this.processing.tableIdsSelection = json.keys;
          // }
          return json.data;
        },
        type: "POST",
      },
      initComplete: (settings, json) => {
        this.loading = false;
        this.episodesLoaded = false;

        console.log("init complete");
        this.checkFilters();
        this.drawFilterRow();
        this.loading = false;
      },
      colVis: {
        restore: this.translateService.instant("general.restore"),
        showAll: this.translateService.instant("general.showAll"),
        showNone: this.translateService.instant("general.hideAll"),
        exclude: ["id", "id_export", "plusButton"],
      },
      columns: this.columns,
      pageLength: 25,
      lengthMenu: [
        [10, 17, 25, 50, -1],
        [10, 17, 25, 50, this.translateService.instant("general.all")],
      ],
      language: getGridLanguages(this.translateService),
    };
  }
}
