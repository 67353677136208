import { ChangeDetectorRef, Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { FormMode } from "app/common/enums";
import { WorkOrder } from "app/models/workOrder.model";
import { AuthenticationService } from "app/services/authentication/authentication.service";
import { MaintenanceV2Service } from "app/services/maintenancev2/maintenanceV2.service";
import { ConfirmationModalComponent } from "app/views/shared/usercontrols/confirmationModal.component";
import { MaintenanceWorkOrderDetailsComponent } from "app/views/shared/usercontrols/workOrderDetails.component";

@Component({
  selector: "fh-fh-maintenance-work-order-details",
  templateUrl: "workOrderDetails.template.html",
})
export class MaintenanceWorkOrderDetailsViewComponent implements OnInit {
  @ViewChild(MaintenanceWorkOrderDetailsComponent, { static: true })
  workOrderDetails: MaintenanceWorkOrderDetailsComponent;
  @ViewChild("deleteModal")
  deleteModal: ConfirmationModalComponent;
  formMode = FormMode.read;

  workOrderId: number;
  workOrder: WorkOrder;

  permissions: {};
  permissionName = "Maintenances_View";

  constructor(
    private cd: ChangeDetectorRef,
    private authenticationService: AuthenticationService,
    private maintenanceService: MaintenanceV2Service,
    private route: ActivatedRoute
  ) {
    this.permissions = this.authenticationService.permissions;
  }

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.workOrderId = params["id"];
      this.workOrderDetails.workOrderId = this.workOrderId;

      this.getMaintenanceInfo();
    });
  }

  showDeleteModal(itemId: number) {
    this.deleteModal.showModal(itemId);
  }

  deleteWorkOrderItem() {
    const item = this.workOrder.workOrderItems.find((x) => x.id === this.deleteModal.activeId);
    const index: number = this.workOrder.workOrderItems.indexOf(item);
    if (index !== -1) {
      this.workOrder.workOrderItems.splice(index, 1);
    }
    this.deleteModal.hideModal();
  }

  OnSave(workOrder) {
    this.workOrderDetails.loading = true;

    this.maintenanceService.updateWorkOrder(this.workOrderId, workOrder).subscribe({
      next: (result) => {
        this.workOrderDetails.error = null;
        this.workOrderDetails.success = {
          statusText: "Success",
          success: "Work order successfully updated",
        };

        this.workOrderDetails.loading = false;
        this.cd.markForCheck();

        this.setFormMode(FormMode.read);
      },
      error: (error) => {
        this.workOrderDetails.loading = false;
        this.workOrderDetails.success = null;
        this.workOrderDetails.error = error;
        this.cd.markForCheck();
      },
    });
  }

  setFormMode(mode) {
    this.workOrderDetails.formMode = mode;
    this.cd.markForCheck();

    if (this.workOrderDetails.formMode === FormMode.read) {
      this.getMaintenanceInfo();
    }
  }

  getMaintenanceInfo() {
    this.workOrderDetails.loading = true;

    this.maintenanceService.getWorkOrderById(this.workOrderId).subscribe({
      next: (result) => {
        this.workOrder = result;

        //Setting that an item is retrieved from database
        this.workOrder.workOrderItems.forEach((item) => {
          item.exists = true;
        });
        this.workOrderDetails.loading = false;
        this.cd.markForCheck();
      },
      error: (error) => {
        this.workOrderDetails.loading = false;
        this.workOrderDetails.success = null;
        this.workOrderDetails.error = error;
        this.cd.markForCheck();
      },
    });
  }
}
