import { ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import {
  getGridButtons,
  getGridLanguages,
  setTableStars,
  setSelection,
  setSelectionRows,
  createdCellCheckbox,
} from "app/common/gridhelper";

import { DriverService } from "app/services/driver/driver.service";
import { AuthenticationService } from "../../services/authentication/authentication.service";

import { forkJoin } from "rxjs";
import { BatchStatus, EntityType } from "app/common/enums";
import { ActivatedRoute } from "@angular/router";
import { GridBase360Directive } from "app/common/360Grid.base";

// Moment timezone
import Moment from "moment-timezone";
import { StorageHelper } from "app/common/storagehelper";
import { ColorService } from "app/services/common/color.service";

window["moment"] = Moment;

@Component({
  selector: "fh-drivers",
  templateUrl: "drivers.template.html",
})
export class DriversViewComponent extends GridBase360Directive implements OnInit, OnDestroy {
  loading = false;
  languageLoaded: boolean;
  permissions: {};

  showActiveOnly = true;

  permissionName = "Drivers_View";
  constructorName = "DriversViewComponent";

  error: any;
  success: any;

  enableSelecting = true;

  entityTypes = EntityType.Driver;
  timezoneIana: string;

  constructor(
    private route: ActivatedRoute,
    private translateService: TranslateService,
    private cd: ChangeDetectorRef,
    private authenticationService: AuthenticationService,
    private driverService: DriverService,
    protected storageHelper: StorageHelper,
    private colorService: ColorService
  ) {
    super(storageHelper);

    this.permissions = this.authenticationService.permissions;

    this.timezoneIana = this.authenticationService.getTimeZoneIana();

    // Get all the date for dropdown boxes
    forkJoin({ dateTranslation: this.translateService.get("general.date") }).subscribe({
      next: ({ dateTranslation }) => {
        this.languageLoaded = true;
        this.loading = false;

        this.initGrid();
      },
      error: (err) => console.error(err),
    });
  }

  // Fire custom filters to update grid and call server again
  fireFilter(event): void {
    if (event != null) {
      console.log("Fire update");
      this.datatableElement?.dtInstance.then((dtInstance: DataTables.Api) => {
        if (this.showActiveOnly === true) {
          dtInstance.column("isActive:name").search("true");
        } else {
          dtInstance.column("isActive:name").search("@ignore");
        }
        // Fire the request
        dtInstance.draw();
      });
    }
  }

  ngOnInit() {
    this.permissions = this.authenticationService.permissions;

    this.translateService.get("general.date").subscribe((value) => {
      this.languageLoaded = true;
      this.initGrid();
    });
  }

  // Check custom filters from grid save
  checkFilters() {
    this.datatableElement?.dtInstance.then((dtInstance: DataTables.Api) => {
      this.showActiveOnly = dtInstance.column("isActive:name").search() !== "@ignore";
    });

    this.fireFilter(1);
  }

  // Check route to apply filters
  checkRoute() {
    this.route.queryParams.subscribe((params) => {
      const companyName = params["companyName"];
      const resellerDescription = params["resellerDescription"];
      let requireDraw = false;

      this.datatableElement?.dtInstance.then((dtInstance: DataTables.Api) => {
        if (companyName) {
          dtInstance.column("resellerDescription:name").search("");
          dtInstance.column("companyName:name").search(companyName);
          dtInstance.column("name:name").search("");
          requireDraw = true;
        }
        if (resellerDescription) {
          dtInstance.column("resellerDescription:name").search(resellerDescription);
          dtInstance.column("companyName:name").search("");
          dtInstance.column("name:name").search("");
          requireDraw = true;
        }
        // Fire the request
        if (requireDraw) {
          dtInstance.draw();
        }
      });
    });
  }

  initGrid(): void {
    $.fn["dataTable"].ext.search.pop();

    this.loading = true;

    const excludedColumns = ["id", "id_export", "isSelected", "form"];
    let selectorVisible = true;

    if (!this.permissions["FleetManagement_Actions"]) {
      selectorVisible = false;
    }

    this.columns = [
      {
        name: "isSelected",
        data: "id",
        className: "noVis",
        title: '<div class="hideDropdown" id="selectorHeader"></div>',
        orderable: false,
        visible: selectorVisible,
        width: "20",
        render: (data, type, row) => {
          if (!this.processing.tableIdsSelection) {
            this.processing.tableIdsSelection = [];
          }
          if (this.processing.tableIdsSelection.indexOf(row.id.toString()) === -1) {
            this.processing.tableIdsSelection.push(row.id.toString());
          }

          if (!this.processing.tableIds) {
            this.processing.tableIds = [];
          }
          if (this.processing.tableIds.indexOf(row.id.toString()) === -1) {
            this.processing.tableIds.push(row.id.toString());
          }

          const isSelected = this.processing.gridSelection.indexOf(data.toString()) !== -1;
          return (
            '<i id="selection_' +
            row.id.toString() +
            '" class="far fa-fw fa-lg ' +
            (isSelected ? "fa-check-square " : "fa-square ") +
            '" style="cursor: pointer;"></i>'
          );
        },
      },
      {
        name: "id",
        data: "id",
        className: "noVis",
        orderable: false,
        title: '<div class="hideDropdown"></div>',
        width: "20",
        render: (data, type, row) => {
          const detailsText = this.translateService.instant("general.details");
          return `
          <a class='btn btn-primary btn-grid' title='${detailsText}' href='/#/DriverDetails/Index/${data}'>
            <span class="d-none d-md-inline-flex" style="padding-left: 7px">${detailsText}</span>
            <i class='fas fa-fw fa-angle-right'></i>
          </a>`;
        },
      },
      {
        name: "id_export",
        data: "id",
        className: "noVis",
        title: this.translateService.instant("general.id"),
        visible: false,
      },
      {
        name: "name",
        data: "name",
        defaultContent: "-",
        title: this.translateService.instant("general.name"),
      },
      {
        name: "dateOfBirth",
        data: "dateOfBirth",
        defaultContent: "-",
        title: this.translateService.instant("general.dateOfBirth"),
        visible: false,
      },
      {
        name: "mobilePhone",
        data: "mobilePhone",
        defaultContent: "-",
        title: this.translateService.instant("general.mobilePhone"),
      },
      {
        name: "officePhone",
        data: "officePhone",
        defaultContent: "-",
        title: this.translateService.instant("general.officePhone"),
        visible: false,
      },
      {
        name: "homePhone",
        data: "homePhone",
        defaultContent: "-",
        title: this.translateService.instant("general.homePhone"),
        visible: false,
      },
      {
        name: "code",
        data: "code",
        defaultContent: "-",
        title: this.translateService.instant("general.code"),
      },
      {
        name: "companyName",
        data: "companyName",
        defaultContent: "-",
        title: this.translateService.instant("general.companyName"),
      },
      {
        name: "erpCode",
        data: "erpCode",
        defaultContent: "-",
        visible: false,
        title: this.translateService.instant("general.erpCode"),
      },
      {
        name: "accountId",
        data: "accountId",
        defaultContent: "-",
        title: this.translateService.instant("general.accountId"),
        visible: false,
      },
      {
        name: "resellerDescription",
        data: "resellerDescription",
        defaultContent: "-",
        title: this.translateService.instant("general.resellerDescription"),
        visible: false,
      },
      {
        name: "driverLicence",
        data: "driverLicence",
        defaultContent: "-",
        title: this.translateService.instant("general.driverLicence"),
        visible: false,
      },
      {
        name: "driverLicenceExpirationDate",
        data: "driverLicenceExpirationDate",
        defaultContent: "-",
        title: this.translateService.instant("general.driverLicenceExpirationDate"),
        visible: false,
      },
      {
        name: "identificationCardNumber",
        data: "identificationCardNumber",
        defaultContent: "-",
        title: this.translateService.instant("general.driverIdentification"),
        visible: false,
      },
      {
        name: "identificationCardExpirationDate",
        data: "identificationCardExpirationDate",
        defaultContent: "-",
        title: this.translateService.instant("general.driverIdentificationExpirationDate"),
        visible: false,
      },
      {
        name: "timeStamp",
        data: "timestamp",
        type: "date",
        title: this.translateService.instant("general.timeStamp"),
        render: (data, type, row) => {
          const date = Moment.utc(data)["tz"](this.timezoneIana);
          return data ? '<span title=" ' + date.toLocaleString() + '">' + date.format("lll") + "</span>" : "";
        },
        visible: true,
      },
      {
        name: "activeAssignments",
        data: "activeAssignments",
        type: "num",
        defaultContent: "-",
        title: this.translateService.instant("general.activeAssignments"),
      },
      {
        name: "isActive",
        data: "isActive",
        title: this.translateService.instant("general.isActive"),
        type: "checkBox",
        visible: true,
        createdCell: createdCellCheckbox,
        width: "40",
      },
    ];

    this.dtOptions = {
      buttons: getGridButtons(
        this.commonExportOptions,
        "drivers_overview",
        this.translateService.instant("menu.driversoverview"),
        this.colorService
      ),
      pagingType: "simple_numbers",
      serverSide: true,
      processing: true,
      searchDelay: 500,
      deferRender: true,
      scrollX: true,
      colReorder: { fixedColumnsLeft: 2 },
      deferLoading: 0,
      stateSave: true,
      stateSaveCallback: (settings, data) => {
        this.saveState(this.constructorName, data);
      },
      stateLoadCallback: (_, callback) => {
        (async () => {
          try {
            const columnSettings = await this.loadState(this.constructorName);
            this.searchTerm = columnSettings && columnSettings.search && columnSettings.search.search;
            return columnSettings;
          } catch (e) {
            this.error = {};
            this.error.error = e;
            this.error.statusText = "Error fetching column settings";

            return null;
          }
        })().then((result) => {
          callback(result);
        });
      },
      order: [[2, "asc"]],
      ajax: {
        beforeSend: () => {
          this.drawFilterRow();

          $(".dataTables_info").html(this.translateService.instant("grid.loadingData"));
        },
        url: this.driverService.getPagingUrl(),
        data: (d) => {
          return d;
        },
        dataSrc: (json) => {
          // if selection and returning keys
          if (this.processing.tableIdsSelection && this.processing.tableIdsSelection.length === 0) {
            this.processing.tableIdsSelection = json.keys;
          }
          return json.data;
        },
        type: "POST",
      },
      initComplete: (settings, json) => {
        this.checkRoute();
        this.checkFilters();
        this.setEvents();
        this.drawFilterRow();
        $("#grid_select_or").appendTo($("th > #selectorHeader"));
        this.loading = false;
      },
      colVis: {
        restore: this.translateService.instant("general.restore"),
        showAll: this.translateService.instant("general.showAll"),
        showNone: this.translateService.instant("general.hideAll"),
        exclude: excludedColumns,
      },
      columns: this.columns,
      pageLength: 17,
      lengthMenu: [
        [10, 17, 25, 50, -1],
        [10, 17, 25, 50, this.translateService.instant("general.all")],
      ],
      language: getGridLanguages(this.translateService),
      drawCallback: (data) => {
        setTableStars(this.processing.tableIds);
        setSelection(this.processing.tableIds, this.processing.gridSelection);
      },
      rowCallback: (row, data) => {
        setSelectionRows(this, row, data, this.processing.gridSelection);
      },
    };
  }

  // batch processing
  onClose(event) {
    console.log("Closing batch popup");

    this.success = null;
    this.error = null;

    if (event && event.status === BatchStatus.Success) {
      console.log("success");

      this.success = {};
      this.success.statusText = "Success";
      this.success.success = "Your batch was accepted and scheduled to be proccesed";
      this.success.unique = Math.floor(Math.random() * (999999 - 100000)) + 100000;
      this.cd.markForCheck();
    }
    if (event && event.status === BatchStatus.Error) {
      this.error = {};
      this.error.statusText = "Error";
      this.error.error = "Error in processing the batch";
      this.error.unique = Math.floor(Math.random() * (999999 - 100000)) + 100000;
      this.cd.markForCheck();
    }
  }
}
