import { Component, Input, OnInit, Output, EventEmitter, ChangeDetectorRef } from "@angular/core";
import { ControlContainer, NgForm } from "@angular/forms";
import { FormMode } from "app/common/enums";
import { TriggerSchedule } from "app/models/triggerSchedule.model";
import { BsDatepickerConfig } from "ngx-bootstrap/datepicker";
import { AuthenticationService } from "app/services/authentication/authentication.service";

@Component({
  selector: "fh-trigger-schedule-details",
  templateUrl: "triggerScheduleDetails.component.html",
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class TriggerScheduleDetailsComponent implements OnInit {
  @Input() triggerSchedule: TriggerSchedule;
  @Input() loading = false;
  @Input() formMode: any = FormMode.read;
  @Input() hideTimezoneSelector = true;
  @Output() onAccountChanged = new EventEmitter();
  loadingAccounts = false;

  // Datepicker
  public dpConfig: Partial<BsDatepickerConfig> = new BsDatepickerConfig();

  accountPlatforms = [];
  accounts = [];
  permissions: {};

  constructor(private cd: ChangeDetectorRef, private authenticationService: AuthenticationService) {
    this.permissions = this.authenticationService.permissions;
  }

  ngOnInit(): void {
    // Datepicker
    this.dpConfig.containerClass = "theme-default"; // or whatever color
    this.dpConfig.dateInputFormat = "lll";
    this.dpConfig.isAnimated = false;
    this.dpConfig.withTimepicker = true;
    this.dpConfig.keepDatepickerOpened = true;

    this.loadingAccounts = true;
  }

  resellerChanged(resellerId) {
    this.triggerSchedule.accountId = null;

    this.triggerSchedule.resellerId = resellerId;
  }

  accountChanged(accountId) {
    this.onAccountChanged.emit(this.triggerSchedule.accountId);
  }
}
