import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ServiceAnnouncement } from "app/models/serviceAnnouncement.model";

import { throwError } from "rxjs/internal/observable/throwError";
import { Observable, catchError } from "rxjs";
import { AuthenticationService } from "../authentication/authentication.service";

@Injectable()
export class ServiceAnnouncementService {
  url = "";

  constructor(private http: HttpClient, private authenticationService: AuthenticationService) {
    this.url = this.authenticationService.getWebserviceURL("serviceAnnouncement");
  }

  getPagingUrl() {
    return this.url + "Paging";
  }

  getServerAnnouncements(accountId?: number): Observable<ServiceAnnouncement[]> {
    return this.http
      .get<ServiceAnnouncement[]>(this.url + (accountId ? `?accountId=${accountId}` : ""), {
        headers: this.authenticationService.headers,
      })
      .pipe(catchError(this.handleError));
  }

  getServiceAnnouncementById(id: string): Observable<ServiceAnnouncement> {
    return this.http
      .get<ServiceAnnouncement>(this.url + id, { headers: this.authenticationService.headers })
      .pipe(catchError(this.handleError));
  }

  saveServiceAnnouncement(serviceAnnouncement: ServiceAnnouncement): Observable<any> {
    console.log("save serviceAnnouncement");
    return this.http
      .post(this.url, serviceAnnouncement, { headers: this.authenticationService.headers })
      .pipe(catchError(this.handleError));
  }

  updateServiceAnnouncement(serviceAnnouncement: ServiceAnnouncement): Observable<any> {
    console.log("save serviceAnnouncement");
    return this.http
      .put(this.url + serviceAnnouncement.id, serviceAnnouncement, { headers: this.authenticationService.headers })
      .pipe(catchError(this.handleError));
  }

  deleteServiceAnnouncement(serviceAnnouncement: ServiceAnnouncement): Observable<any> {
    console.log("save serviceAnnouncement");
    return this.http
      .delete(this.url + serviceAnnouncement.id, { headers: this.authenticationService.headers })
      .pipe(catchError(this.handleError));
  }

  private handleError(error: Response) {
    return throwError(() => error);
  }
}
