export class AppUser {
  referenceId: any;
  name: string;
  id: string;
  createdDate: any;
  accountId: any;
  companyName: any;
  firstName: string;
  lastName: string;
  fullName: string;
  language: any;
  password: string = "";
  whitelabel: string;
  timezoneIana: string = "Europe/Amsterdam";
  countryIsoCode: any;
  cronTabPattern: string;
  email: string;
  deviceCount: any;
  isBackofficeOwner: any;
  isSetupUser: any;
  created: any;
  isActive: any;
  assetDisplayName: number = 3;
  volumeUnit: number = 0;
  distanceUnit: number = 0;
  userType: number;
  userLevel: number;
  assetGroups: any[];
  userRoleId: any;
  userRoleName: any;
  clientPermissionId: any;
  userName: any;
  linkName: any;
  driverId;
  driverName;
  resellerDescription;
  resellerId;
  viewerId: any;

  createAssetGroup = true;
  createDriver = true;
  featureFlags: any;
  lastActivity: any;
}

export class UpdatePasswordRequest {
  constructor(public id: string, public userName: string, public oldPassword: string, public newPassword: string) {}
}

export class UserRole {
  name: any;
  id: any;
  permissions: any;
  userLevel: any;
}
