import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Device } from "app/models/device.model";
import { AuditLog } from "../../models/auditlog.model";
import { DeviceService } from "../../services/device/device.service";
import { TranslateService } from "@ngx-translate/core";
import { getDefaultDpConfig } from "app/common/gridhelper";

import { BsDaterangepickerConfig } from "ngx-bootstrap/datepicker";
import { AuthenticationService } from "app/services/authentication/authentication.service";

// Moment
import Moment from "moment-timezone";
import { MaintenanceOccurrence } from "app/models/maintenance.model";
import { FormMode } from "app/common/enums";
import { MaintenanceV2Service } from "app/services/maintenancev2/maintenanceV2.service";
import { WorkOrder, WorkOrderItem } from "app/models/workOrder.model";
import { ServiceItem } from "app/models/serviceItem.model";
window["moment"] = Moment;

@Component({
  selector: "fh-device-maintenance-v2",
  templateUrl: "maintenanceV2.template.html",
})
export class DeviceMaintenanceV2ViewComponent implements OnInit {
  deviceLog: AuditLog[];
  sub;
  device: Device;
  deviceId;

  loading = false;

  error: any;
  success: any;

  maintenanceHistory = [];
  maintenanceDue = [];

  showProp = false;

  // FormAdd
  formModeAdd: FormMode = FormMode.add;
  workOrder: WorkOrder;

  permissions = {};

  filter;
  currentPage;

  // Daterange
  public dpConfig: Partial<BsDaterangepickerConfig> = new BsDaterangepickerConfig();
  daterangepickerModel: Date[];
  timezoneIana: string;
  serviceItems: ServiceItem[] = [];

  constructor(
    private maintenanceService: MaintenanceV2Service,
    private deviceService: DeviceService,
    private route: ActivatedRoute,
    private router: Router,
    private cd: ChangeDetectorRef,
    private translateService: TranslateService,
    private authenticationService: AuthenticationService
  ) {
    this.device = null;
    this.deviceLog = [];

    this.permissions = this.authenticationService.permissions;

    this.timezoneIana = this.authenticationService.getTimeZoneIana();

    this.daterangepickerModel = [
      Moment().tz(this.timezoneIana).subtract(10, "years").startOf("day").toDate(),
      Moment().tz(this.timezoneIana).endOf("day").toDate(),
    ];

    this.dpConfig = getDefaultDpConfig(this.authenticationService);

    this.dpConfig.containerClass = "theme-default"; // or whatever color
    this.dpConfig.dateInputFormat = "lll";
    this.dpConfig.isAnimated = false;
    this.dpConfig.withTimepicker = false;
  }

  setBadgeColor(dueStatus: string) {
    if (dueStatus?.toString() == "1") {
      return "badge badge-success";
    } else if (dueStatus?.toString() == "2") {
      return "badge badge-warning";
    }
    return "badge badge-danger";
  }

  dateChanged(event) {
    this.loading = true;
    if (event !== null) {
      this.maintenanceService.getMaintenancesHistoryByAssetId(this.device.asset?.id).subscribe({
        next: (maintenances) => {
          this.maintenanceHistory = maintenances;
          this.loading = false;
        },
        error: (error) => {
          this.error = error;
          this.loading = false;
        },
      });

      this.maintenanceService.getMaintenancesDueByAssetId(this.device.asset?.id).subscribe({
        next: (maintenanceDue) => {
          this.maintenanceDue = maintenanceDue;
          this.loading = false;
        },
        error: (error) => {
          this.error = error;
          this.loading = false;
        },
      });
    }
  }

  ngOnInit() {
    this.workOrder = new WorkOrder();
    this.workOrder.serviceDate = new Date();

    this.loading = true;
    this.device = new Device();
    this.device.id = "";

    this.sub = this.route.params.subscribe({
      next: (params) => {
        const id = params["id"];

        this.deviceId = id;
        this.deviceService.getDeviceById(id).subscribe((device) => {
          this.device = device;

          this.prepareNewMaintenance();

          if (this.device == null) {
            this.router.navigate(["/Devices/Overview"]);
          }

          this.dateChanged(true);
        });
      },
      error: (error) => {
        this.error = error;
        this.error.statusText = "Error fetching device";

        setTimeout(() => {
          this.router.navigate(["/Devices/Overview"]);
        }, 3000);
      },
    });
  }

  prepareNewMaintenance() {
    this.workOrder = new WorkOrder();
    this.workOrder.serviceDate = new Date();

    this.workOrder.assetId = this.device.asset?.id;
    this.workOrder.accountId = this.device.accountId;

    this.workOrder.engineHours = Math.round(
      ((this.device?.asset?.engineHourCalibrationOdoOffset ?? 0) + (this.device?.engineHourRunningInSeconds ?? 0)) /
        3600
    );

    this.workOrder.odometer = Math.round(
      ((this.device?.asset?.incrementalOdoDistanceOffset ?? 0) + (this.device?.incrementalEndOdo ?? 0)) / 1000
    );

    var workItem = new WorkOrderItem();

    this.workOrder.workOrderItems = [];
    this.workOrder.workOrderItems.push(workItem);

    this.getServiceItems();
  }

  showProperties() {
    this.showProp = !this.showProp;
  }

  getServiceItems(): void {
    if (!this.workOrder?.accountId) {
      return;
    }

    this.maintenanceService.getServiceItemsByAccount(this.workOrder.accountId).subscribe((result) => {
      this.serviceItems = result;
      this.cd.markForCheck();
    });
  }

  onInsertMaintenance() {
    this.loading = true;

    this.workOrder.assetId = this.device.asset?.id;
    this.workOrder.accountId = this.device.accountId;
    this.workOrder.completed = true;

    this.workOrder.description = "";

    this.workOrder.workOrderItems[0].quantity = 1;
    this.workOrder.workOrderItems[0].laborCost = 1;
    this.workOrder.workOrderItems[0].itemPrice = 1;
    this.workOrder.workOrderItems[0].description = "";

    this.maintenanceService.createWorkOrder(this.workOrder).subscribe({
      next: (result) => {
        this.prepareNewMaintenance();
      },
      error: (error) => {
        this.error = error;
      },
    });

    this.success = {
      statusText: "Success",
      success: this.translateService.instant("general.saveSuccessRedirect", { entity: "maintenance" }),
    };

    setTimeout(() => {
      this.dateChanged(true);
    }, 3000);

    this.loading = false;
  }
}
