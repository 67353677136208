<div class="dropdown form-control table-inline ng-select" ngxDropdown>
    <span ngxDropdownToggle style="display: block; padding: 5px; width: 100%">
        {{ buttonLabel }}
    </span>

    <span class="ng-arrow-wrapper" style="position: absolute; right: 4px; top: 7px">
        <span class="ng-arrow"></span>
    </span>

    <div ngxDropdownMenu aria-labelledby="dropdownMenu" style="padding: 5px" (click)="$event.stopPropagation()">
        <div class="dropdown-container">
            <ngx-treeview
                [config]="config"
                [headerTemplate]="headerTemplate"
                [items]="items"
                [itemTemplate]="itemTemplate"
                (selectedChange)="onSelectedChange($event)"
                (filterChange)="onFilterChange($event)"
            >
            </ngx-treeview>
        </div>
    </div>
</div>
