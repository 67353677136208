import { AfterViewInit, Component, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Subject, forkJoin } from "rxjs";
import { getGridButtons, getGridLanguages } from "app/common/gridhelper";
import { GridBase360Directive } from "app/common/360Grid.base";
import { DeviceTypeService } from "app/services/devicetypes/devicetypes.service";
import { StorageHelper } from "app/common/storagehelper";
import { ColorService } from "app/services/common/color.service";

@Component({
  selector: "fh-device-types",
  templateUrl: "deviceTypes.template.html",
  styleUrls: ["deviceTypes.template.css"],
})
export class DevicesTypeViewComponent extends GridBase360Directive implements OnInit, AfterViewInit {
  assets = new Subject();
  loading = false;

  data = 0;
  sub: any;
  languageLoaded: boolean;

  permissionName = "Entities_DeviceTypes";
  constructorName = "DevicesTypeViewComponent";

  error;

  constructor(
    private translateService: TranslateService,
    private deviceTypeService: DeviceTypeService,
    protected storageHelper: StorageHelper,
    private colorService: ColorService
  ) {
    super(storageHelper);

    // Get all the date for dropdown boxes
    forkJoin({ dateTranslation: this.translateService.get("general.date") }).subscribe({
      next: ({ dateTranslation }) => {
        this.languageLoaded = true;
        this.loading = false;

        this.initGrid();
      },
      error: (err) => console.error(err),
    });
  }

  ngAfterViewInit() {}

  ngOnInit(): void {
    this.translateService.get("general.date").subscribe((value) => {
      this.languageLoaded = true;
      this.initGrid();
    });
  }

  fireFilter(): void {
    console.log("fire update");
    this.datatableElement?.dtInstance.then((dtInstance: DataTables.Api) => {
      // Fire the request
      dtInstance.draw();
    });
  }

  checkFilters() {
    this.fireFilter();
  }

  initGrid(): void {
    this.loading = true;

    this.columns = [
      {
        name: "id",
        data: "id",
        className: "noVis",
        orderable: false,
        title: '<div class="hideDropdown"></div>',
        width: "20",
        render: (data, type, row) => {
          const detailsText = this.translateService.instant("general.details");
          return `
            <a class='btn btn-primary btn-grid' title='${detailsText}' href='/#/Devices/DeviceTypeDetails/Index/${data}'>
              <span class="d-none d-md-inline-flex" style="padding-left: 7px">${detailsText}</span>
              <i class='fas fa-fw fa-angle-right'></i>
            </a>`;
        },
      },
      {
        name: "id_export",
        data: "id",
        orderable: false,
        title: this.translateService.instant("general.id"),
        visible: false,
      },
      {
        name: "supplier.name",
        data: "supplier.name",
        title: this.translateService.instant("general.supplierName"),
        render: (data, type, row) => {
          return data === "Unknown" ? this.translateService.instant("general.unknown") : data;
        },
      },
      {
        name: "name",
        data: "name",
        title: this.translateService.instant("general.modelName"),
      },
      {
        name: "number",
        data: "number",
        title: this.translateService.instant("general.modelNumber"),
      },
      {
        name: "description",
        data: "description",
        title: this.translateService.instant("general.description"),
      },
      {
        name: "deviceCount",
        data: "deviceCount",
        type: "num",
        visible: false,
        title: this.translateService.instant("general.deviceCount"),
      },
      {
        name: "deviceCountActive",
        data: "deviceCountActive",
        type: "num",
        title: this.translateService.instant("general.deviceCountActive"),
      },
      {
        name: "port",
        data: "port",
        type: "num",
        title: this.translateService.instant("general.port"),
      },
    ];

    this.dtOptions = {
      buttons: getGridButtons(
        this.commonExportOptions,
        "deviceTypes_overview",
        this.translateService.instant("menu.devicetypesoverview"),
        this.colorService
      ),
      pagingType: "simple_numbers",
      serverSide: true,
      processing: true,
      searchDelay: 500,
      deferRender: true,
      scrollX: true,
      colReorder: { fixedColumnsLeft: 1 },
      deferLoading: 0,
      stateSave: true,
      stateSaveCallback: (settings, data) => {
        this.saveState(this.constructorName, data);
      },
      stateLoadCallback: (_, callback) => {
        (async () => {
          try {
            const columnSettings = await this.loadState(this.constructorName);
            this.searchTerm = columnSettings && columnSettings.search && columnSettings.search.search;
            return columnSettings;
          } catch (e) {
            this.error = {};
            this.error.error = e;
            this.error.statusText = "Error fetching column settings";

            return null;
          }
        })().then((result) => {
          callback(result);
        });
      },
      order: [[1, "asc"]],
      ajax: {
        beforeSend: () => {
          this.drawFilterRow();

          $(".dataTables_info").html(this.translateService.instant("grid.loadingData"));
        },
        url: this.deviceTypeService.getPagingUrl(),
        type: "POST",
      },
      initComplete: (settings, json) => {
        console.log("init complete");
        this.checkFilters();
        this.drawFilterRow();
        this.loading = false;
      },
      colVis: {
        restore: this.translateService.instant("general.restore"),
        showAll: this.translateService.instant("general.showAll"),
        showNone: this.translateService.instant("general.hideAll"),
        exclude: ["id", "id_export"],
      },
      columns: this.columns,
      pageLength: 17,
      lengthMenu: [
        [10, 17, 25, 50, -1],
        [10, 17, 25, 50, this.translateService.instant("general.all")],
      ],
      language: getGridLanguages(this.translateService),
    };
  }
}
