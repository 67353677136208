<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12 flex header-content">
        <nav class="breadcrumb-container" aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a href="/#/"> <i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
                </li>
                <li class="breadcrumb-item">
                    <a href="/#/EcoDriving">{{'general.ecoDriving' | translate }}</a>
                </li>
                <li class="breadcrumb-item active">{{'general.assets' | translate }}</li>
            </ol>
        </nav>

        <span style="margin-left: auto" class="fa-filter-container noselect">
            <a
                style="margin-left: 2px"
                class="btn btn-info margin-fix d-none d-md-inline-flex"
                title="Help"
                href="https://help.360locate.com/knowledge-base/eco-driving/"
                target="_blank"
                ><i class="fa fa-fa fa-book" style="margin-right: 6px"></i>
                <span class="d-none d-md-inline-flex">{{'general.needHelp' | translate }}</span></a
            >
        </span>
    </div>
    <fh-ecodriving-navigation> </fh-ecodriving-navigation>
</div>

<div class="wrapper wrapper-content">
    <div class="row" data-equalizer="">
        <div class="col-lg-12">
            <fh-error [error]="error" [success]="success" [warning]="warning"></fh-error>

            <!-- Custom filters -->
            <div class="ibox" [hidden]="!showFilter">
                <div class="ibox-title">
                    <h5><i class="fas fa-fw fa-filter"></i> {{'general.filter' | translate }}</h5>
                    <span class="float-right hand btn btn-info filter-margin-fix" (click)="clearAllFilters();">
                        <i class="fa fa-fw fa-times"></i>
                        <span class="d-none d-md-inline-flex"> {{'general.clearFilters' | translate }}</span>
                    </span>
                </div>
                <div class="ibox-content slick_toppadding flex-container" style="position: relative">
                    <ngx-loading [show]="loading"></ngx-loading>
                    <div class="col-lg-4">
                        <div class="form-group input-group" style="margin-bottom: 6px; display: block">
                            <fh-reseller-input
                                name="reseller"
                                #resellerVar="ngModel"
                                (onChanged)="resellerChanged($event)"
                                required
                                placeholder="{{ 'general.chooseReseller' | translate}}"
                                [(ngModel)]="selectedResellerId"
                            >
                            </fh-reseller-input>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="form-group input-group" style="margin-bottom: 6px; display: block">
                            <fh-account-input
                                name="account"
                                #accountVar="ngModel"
                                (onChanged)="accountChanged($event)"
                                required
                                [selectedResellerId]="selectedResellerId"
                                [showEmptyAccountsButton]="false"
                                placeholder="{{ (selectedResellerId ? 'general.chooseAccount' : 'general.firstChooseReseller') | translate}}"
                                [(ngModel)]="selectedAccountId"
                            >
                            </fh-account-input>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="form-group" style="margin-bottom: 6px; display: block">
                            <fh-asset-group-input
                                name="assetGroups"
                                #assetGroupVar="ngModel"
                                required
                                [selectedAccountId]="selectedAccountId"
                                [tableInline]="false"
                                (onChanged)="dateChanged(true)"
                                [multiple]="false"
                                [autoSelect]="false"
                                placeholder="{{ (selectedAccountId? 'general.chooseAssetGroups' : 'general.firstChooseAccount') | translate}}"
                                [(ngModel)]="selectedAssetGroup"
                            >
                            </fh-asset-group-input>
                        </div>
                    </div>

                    <div class="col-lg-6">
                        <div class="form-group input-group" style="margin-bottom: 0px">
                            <input
                                placeholder="Daterangepicker"
                                style="width: 100%"
                                placeholder="Datepicker"
                                type="text"
                                [bsConfig]="dpConfig"
                                class="form-control"
                                bsDaterangepicker
                                (onHidden)="dateChanged('hidden')"
                                [(ngModel)]="daterangepickerModel"
                            />
                            <span class="input-group-calendar-datatables">
                                <i class="fa fa-calendar-alt"></i>
                            </span>
                            <div style="clear: both"></div>
                        </div>
                    </div>

                    <div class="col-lg-3">
                        <div class="form-group input-group" style="margin-bottom: 6px; display: block">
                            <ng-select
                                placeholder="{{ 'general.chooseVehicleType' | translate}}"
                                [clearable]="true"
                                class="form-control"
                                name="vehicleType"
                                required
                                #vehicleType="ngModel"
                                [(ngModel)]="selectedVehicleType"
                                style="width: 100%"
                                (ngModelChange)="dateChanged(true)"
                            >
                                <ng-option *ngFor="let vehicleType of vehicleTypes" [value]="vehicleType.id">
                                    {{ ('enums.vehicleType.' + vehicleType.id) | translate }}
                                </ng-option>
                            </ng-select>
                            <div style="clear: both"></div>
                        </div>
                    </div>

                    <div class="col-lg-3">
                        <div class="form-group input-group" style="margin-bottom: 6px" title="Filter on 0 values">
                            <span id="checkBox11" class="input-group-prepend hand" title="Filter on 0 values">
                                <i
                                    id="checkBox_11"
                                    class="auto_checkbox fa fa-fw"
                                    (click)="filterZeroValues = !filterZeroValues; dateChanged('hidden')"
                                    [ngClass]="filterZeroValues ? 'fa-check' : 'fa-times'"
                                    title="Filter on 0 values"
                                    [style.color]="filterZeroValues ? 'rgba(0, 142, 67, 0.8)' : 'rgba(151, 28, 36, 0.8)'"
                                ></i>
                            </span>
                            <input
                                name="checkboxName_11"
                                id="checkboxName_11"
                                style="width: 100%; cursor: pointer !important"
                                placeholder="{{'general.filterZeroValues' | translate }}"
                                disabled="disabled"
                                class="form-control auto_search"
                            />

                            <div style="clear: both"></div>
                        </div>
                    </div>

                    <div style="clear: both"></div>
                </div>
                <div class="ibox-content" style="position: relative; display: none">
                    <ngx-loading [show]="loading"></ngx-loading>
                    <div id="dynamicFilters" class="flex-container"></div>
                    <div style="clear: both"></div>
                </div>
                <div class="ibox-footer">
                    <button
                        *ngIf="!loading"
                        type="button"
                        name="search"
                        class="btn btn-primary"
                        (click)="dateChanged('hidden')"
                        [disabled]="selectedAccountId == null"
                    >
                        {{ 'general.fetchData' | translate}}
                    </button>

                    <button *ngIf="loading" type="button" name="cancel" class="btn btn-info" (click)="cancel()">
                        <i class="fas fa-fw fa-times"></i> {{ 'general.cancel' | translate}}
                    </button>
                </div>
            </div>
            <!-- End default dynamic filters -->
        </div>
    </div>

    <div class="row" style="position: relative" *ngIf="assets?.length > 0">
        <ngx-loading [show]="loading"></ngx-loading>
        <div class="col-6 col-md-4 col-lg-2">
            <div class="ibox hand" [routerLink]="['/Assets/Overview/']">
                <div class="kpi-item" style="position: relative">
                    <h3 class="margin-bottom font-bold">
                        <i class="fas fa-fw fa-id-card-alt"></i> {{kpis?.assetCountWithTrips?.toLocaleString()}} /
                        {{kpis?.assetCount?.toLocaleString()}}
                    </h3>
                    <div>{{'general.assets' | translate }}</div>
                </div>
            </div>
        </div>
        <div class="col-6 col-md-4 col-lg-2">
            <div class="ibox">
                <div class="kpi-item" style="position: relative">
                    <h3 class="margin-bottom font-bold">
                        <i class="fas fa-fw fa-route"></i> {{actualRound(kpis?.tripCount,0)?.toLocaleString()}}
                    </h3>
                    <div>{{'general.trips' | translate }}</div>
                </div>
            </div>
        </div>
        <div class="col-6 col-md-4 col-lg-2">
            <div class="ibox">
                <div class="kpi-item" style="position: relative">
                    <h3 class="margin-bottom font-bold">
                        <i class="fa fa-fw fa-drafting-compass"></i>
                        {{actualRound(kpis?.totalDistance,0)?.toLocaleString()}} {{ translatedKm }}
                    </h3>
                    <div>{{'general.distance' | translate }}</div>
                </div>
            </div>
        </div>
        <div class="col-6 col-md-4 col-lg-2">
            <div class="ibox">
                <div class="kpi-item" style="position: relative">
                    <h3 class="margin-bottom font-bold">
                        <i class="fas fa-fw fa-clock"></i> {{actualRoundMinutes(kpis?.totalDuration / 60)}}
                    </h3>
                    <div>{{'general.workingHours' | translate }}</div>
                </div>
            </div>
        </div>
        <div class="col-6 col-md-4 col-lg-2">
            <div class="ibox">
                <div class="kpi-item" style="position: relative">
                    <h3 class="margin-bottom font-bold">
                        <i class="fa fa-fw fa-snooze"></i> {{actualRoundMinutes(kpis?.totalIdling / 60)}}
                    </h3>
                    <div>{{'general.idling' | translate }}</div>
                </div>
            </div>
        </div>
        <div class="col-6 col-md-4 col-lg-2">
            <div class="ibox">
                <div class="kpi-item" style="position: relative">
                    <h3 class="margin-bottom font-bold">
                        <i class="fa fa-fw fa-diagnoses"></i> {{actualRound(((kpis?.score /
                        kpis?.assetCount)),1)?.toLocaleString() }}
                    </h3>
                    <div>{{'general.score' | translate }}</div>
                </div>
            </div>
        </div>
    </div>

    <div class="row" *ngIf="assets?.length > 0">
        <div class="col-lg-6">
            <div class="ibox">
                <div class="ibox-title">
                    <h5><i class="fas fa-fw fa-sort-numeric-down"></i> {{'general.score' | translate }}</h5>
                    <span class="float-right">
                        <ibox-chart-export [chartObject]="chartUtilization"> </ibox-chart-export>
                    </span>
                </div>
                <div class="ibox-content slicker" style="position: relative">
                    <ngx-loading [show]="loading"></ngx-loading>
                    <table style="width: 100%" class="table table-fixed nowrap dtr-inline no-footer">
                        <tbody>
                            <highcharts-chart
                                [runOutsideAngular]="true"
                                style="display: block; width: 100%; height: 150px"
                                [Highcharts]="Highcharts"
                                [options]="chartUtilization"
                            ></highcharts-chart>
                        </tbody>
                    </table>
                </div>
            </div>

            <div class="ibox">
                <div class="ibox-title">
                    <h5><i class="fas fa-fw fa-sort-numeric-down"></i> {{'general.distance' | translate }}</h5>
                    <span class="float-right">
                        <ibox-chart-export [chartObject]="chartDistance"> </ibox-chart-export>
                    </span>
                </div>
                <div class="ibox-content slicker" style="position: relative">
                    <ngx-loading [show]="loading"></ngx-loading>
                    <table style="width: 100%" class="table table-fixed nowrap dtr-inline no-footer">
                        <tbody>
                            <highcharts-chart
                                [runOutsideAngular]="true"
                                style="display: block; width: 100%; height: 150px"
                                [Highcharts]="Highcharts"
                                [options]="chartDistance"
                            >
                            </highcharts-chart>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="col-lg-3">
            <div class="ibox float-e-margins">
                <div class="ibox-title">
                    <h5><i class="fas fa-fw fa-sort-numeric-down"></i> {{'general.violationsPerType' | translate }}</h5>
                    <span class="float-right">
                        <ibox-chart-export [chartObject]="chartViolationsPerType"> </ibox-chart-export>
                    </span>
                </div>
                <div class="ibox-content slicker" style="position: relative">
                    <ngx-loading [show]="loading"></ngx-loading>

                    <highcharts-chart
                        [runOutsideAngular]="true"
                        style="display: block; width: 100%; height: 363px"
                        [Highcharts]="Highcharts"
                        [options]="chartViolationsPerType"
                    >
                    </highcharts-chart>
                </div>
            </div>
        </div>

        <div class="col-lg-3">
            <div class="ibox float-e-margins">
                <div class="ibox-title">
                    <h5><i class="fas fa-fw fa-sort-numeric-down"></i>{{ 'general.roadSpeedingCount' | translate}}</h5>
                    <span class="float-right">
                        <ibox-chart-export [chartObject]="chartSpeedViolations"> </ibox-chart-export>
                    </span>
                </div>
                <div class="ibox-content slicker" style="position: relative">
                    <ngx-loading [show]="loading"></ngx-loading>

                    <highcharts-chart
                        [runOutsideAngular]="true"
                        style="display: block; width: 100%; height: 363px"
                        [Highcharts]="Highcharts"
                        [options]="chartSpeedViolations"
                    >
                    </highcharts-chart>
                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-lg-12">
            <div class="ibox">
                <div class="ibox-title">
                    <h5><i class="fas fa-fw fa-sort-numeric-down"></i> {{ 'general.assetScore' | translate }}</h5>
                    <iboxsearch
                        [searchTerm]="searchTerm"
                        (searchChanged)="searchChanged($event)"
                        [constructorName]="constructorName"
                        (filterChanged)="showFilter = $event"
                        (presetLoaded)="onPresetLoaded($event)"
                        (columnsChanged)="drawFilterRow()"
                    ></iboxsearch>
                </div>
                <div class="ibox-content slick" style="position: relative" *ngIf="languageLoaded">
                    <table
                        style="width: 100%; min-height: 50px"
                        fh-datatable
                        [dtOptions]="dtOptions"
                        [dtTrigger]="dtTrigger"
                        class="table nowrap margin-bottom-0 dataTable dtr-inline"
                    ></table>
                </div>
            </div>
        </div>
    </div>
</div>
