import { Injectable } from "@angular/core";
import {
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HTTP_INTERCEPTORS,
  HttpErrorResponse,
  HttpEvent,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError, switchMap } from "rxjs/operators";
import { AuthenticationService } from "./authentication.service";
import { TranslateService } from "@ngx-translate/core";

@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {
  private isRefreshing = false;

  constructor(private authenticationService: AuthenticationService, private translate: TranslateService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    req = req.clone({
      withCredentials: true,
    });

    return next.handle(req).pipe(
      catchError((error) => {
        if (
          error instanceof HttpErrorResponse &&
          !req.url.includes("RefreshToken") &&
          error.status === 401 &&
          error.headers.get("WWW-Authenticate") != null
        ) {
          if (
            error.headers.get("WWW-Authenticate").includes("invalid_token") &&
            !req.url.includes("StateLookup") &&
            !req.url.includes("Search")
          ) {
            console.log("Token is invalid, logging out.");
            this.authenticationService.clearToken(true);
            return throwError(() => error);
          }
          return this.handle401Error(req, next);
        }

        if (error.status === 401 || error.status === 400 || error.status === 500 || error.status === 409) {
          error.statusText = this.translate.instant("general.somethingWentWrong");
        }

        return throwError(() => error);
      })
    );
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    console.log("Handling intercepting refresh");
    if (!this.isRefreshing) {
      this.isRefreshing = true;

      var isValid = this.authenticationService.isTokenValid();

      if (isValid == false) {
        console.log("Token not valid anymore.. getting refresh");
        const isImpersonated = this.authenticationService.getIsImpersonated();
        return this.authenticationService.refreshTokenHandler(request.url, isImpersonated).pipe(
          switchMap((result) => {
            if (result) {
              this.isRefreshing = false;

              const newRequest = request.clone({ headers: this.authenticationService.headers });
              return next.handle(newRequest);
            }
          }),
          catchError((error) => {
            this.isRefreshing = false;
            return throwError(() => error);
          })
        );
      }
    }
  }
}

export const httpInterceptorProviders = [{ provide: HTTP_INTERCEPTORS, useClass: HttpRequestInterceptor, multi: true }];
