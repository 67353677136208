import { Component, inject, OnInit } from "@angular/core";
import { AuthenticationService } from "../../services/authentication/authentication.service";
import { Router } from "@angular/router";

@Component({
  selector: "fh-app-sso-signin-callback",
  template: `<p>Processing signin callback</p>`,
  styles: "",
  standalone: true,
  imports: [],
})
export class SsoSignInCallbackComponent implements OnInit {
  private readonly router = inject(Router);
  private readonly authService = inject(AuthenticationService);

  ngOnInit() {
    const newUrl = window.location.href.replace("/#", "");
    this.authService
      .SSOSignInCallback(newUrl)
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        this.router.navigate([""]);
      });
  }
}
