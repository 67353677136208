import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { Observable, catchError, tap, throwError } from "rxjs";
import { AuthenticationService } from "../authentication/authentication.service";
import { ArchiveRetentionRule } from "app/models/archiveretentionrule.model";

@Injectable()
export class ArchiveRetentionRuleService {
  url = "";
  base_url = "";
  inventoryUrl = "";
  ArchiveRetentionRules: ArchiveRetentionRule[] = [];

  constructor(private http: HttpClient, private authenticationService: AuthenticationService) {
    this.url = this.authenticationService.getWebserviceURL("archiveRetentionRule");
    this.base_url = this.authenticationService.getWebserviceURL("");
  }

  getPagingUrl() {
    return this.url + `Paging`;
  }

  getArchiveRetentionRules(): Observable<ArchiveRetentionRule[]> {
    console.log("getting archiveRetentionRule from service");
    return this.http.get<ArchiveRetentionRule[]>(this.url, { headers: this.authenticationService.headers }).pipe(
      tap((data) => {
        this.ArchiveRetentionRules = data;
      }),
      catchError(this.handleError)
    );
  }

  private handleError(error: Response) {
    console.log(error);
    return throwError(() => error);
  }
}
