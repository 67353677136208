export function createPermissionsMap(theAvailablePermissions) {
  const permissionsMap = [];

  theAvailablePermissions.forEach((permissionCategory) => {
    const subPermissionArray = [];

    permissionCategory.subPermissions.forEach((subPermission) => {
      subPermissionArray.push({
        name: permissionCategory.name + "_" + subPermission.name,
        info: subPermission.info,
        parent: permissionCategory.parent,
        value: false,
      });
    });

    if (permissionCategory.parent) {
      permissionsMap.push({
        name: permissionCategory.name,
        parent: permissionCategory.parent,
        value: subPermissionArray,
        info: permissionCategory.info,
      });
    } else {
      permissionsMap.push({ name: permissionCategory.name, value: subPermissionArray, info: permissionCategory.info });
    }
  });

  return permissionsMap;
}

export function updatePermissionMapWithCurrentUserRoleValues(permissionsMap, userRole) {
  permissionsMap.forEach((permissionCategory) => {
    const matchingPermissionCategory = userRole.permissions && userRole.permissions[permissionCategory.name];
    if (matchingPermissionCategory !== undefined) {
      permissionCategory.value.forEach((subPermission) => {
        const formattedName = subPermission.name.split("_")[1];
        if (matchingPermissionCategory.includes(formattedName)) {
          subPermission.value = true;
        }
      });
    }
  });
}

export function formatSelectedUserPermissions(permissionsMap) {
  const selectedPermissionCategories = new Object();

  const flattened = [];
  flattenObject(flattened, permissionsMap);

  permissionsMap.forEach((category) => {
    const selectedPermissions = [];

    category.value.forEach((permission) => {
      const disabledBuyParent = permission.parent != null && !hasPermission(flattened, permission.parent);
      if (disabledBuyParent) {
        console.log("Returning due to disabled parents");
        return;
      }

      if (permission.value === true) {
        const formattedPermissionName = permission.name.split("_")[1];

        // Always grant 'View'permission if Add, Edit or Delete permission is granted even when it is not selected
        if (
          (formattedPermissionName === "Add" ||
            formattedPermissionName === "Edit" ||
            formattedPermissionName === "Delete") &&
          !selectedPermissions.includes("View")
        ) {
          selectedPermissions.push("View");
        }

        selectedPermissions.push(formattedPermissionName);
      }
    });

    if (selectedPermissions.length > 0) {
      selectedPermissionCategories[category.name] = selectedPermissions;
    }
  });

  return selectedPermissionCategories;
}

export function hasPermission(flattened, name) {
  // console.log(this.permissionsMap);

  const index = flattened.find((x) => x.name === name);
  if (index) {
    return index.value;
  }
  return true; // this.permissionsMap.find(e => e.value.name === name).value == true
}

export function flattenObject(returnObject, permissionMap) {
  Object.keys(permissionMap).forEach((key) => {
    if (
      permissionMap[key].value &&
      permissionMap[key].value.toString() !== "false" &&
      permissionMap[key].value.toString() !== "true" &&
      permissionMap[key].value !== null
    ) {
      flattenObject(returnObject, permissionMap[key].value);
    } else {
      returnObject.push(permissionMap[key]);
    }
  });
}

export const availablePermissions = [
  {
    name: "Platform",
    subPermissions: [
      { name: "IsAdministrator", info: "The highest power in our system" },
      { name: "IsReseller", info: "Used for resellers" },
      { name: "IsFleetManager", info: "Used for clients" },
      { name: "IsInstaller", info: "Used for installers" },
    ],
    info: "Platform-wide user-level-related permissions",
  },
  {
    name: "Modules",
    subPermissions: [
      { name: "HasFleetManagement", info: "" },
      { name: "HasFleetOverview", info: "" },
      { name: "HasSetup", info: "" },
      { name: "HasMileageRegistration", info: "" },
      { name: "HasFuel", info: "" },
      { name: "HasMaintenance", info: "" },
      { name: "HasApi360", info: "" },
      { name: "HasReporting", info: "" },
      { name: "HasInstallerApp", info: "" },
      { name: "HasDeclaration", info: "" },
      { name: "HasPrivateFleet", info: "" },
      { name: "HasMobileApp", info: "" },
    ],
    info: "Set which modules the user has access to",
  },
  {
    name: "Entities",
    subPermissions: [
      { name: "Accounts", info: "" },
      { name: "Assets", info: "" },
      { name: "AssetGroups", info: "" },
      { name: "AssetTypes", info: "" },
      { name: "Devices", info: "" },
      { name: "DeviceTypes", info: "" },
      { name: "Drivers", info: "" },
      { name: "DriverGroups", info: "" },
      { name: "Geofences", info: "" },
      { name: "GeofenceGroups", info: "" },
      { name: "Users", info: "" },
      { name: "UserRoles", info: "" },
      { name: "UserContacts", info: "" },
      { name: "UserContactGroups", info: "" },
      { name: "Resellers", info: "" },
      { name: "Trailers", info: "" },
      { name: "Projects", info: "" },
      { name: "Triggers", info: "" },
      { name: "FuelEvents", info: "" },
      { name: "Maintenances", info: "" },
      { name: "MaintenanceTypes", info: "" },
      { name: "Warehouses", info: "" },
      { name: "WarehouseZones", info: "" },
      { name: "WarehouseEvents", info: "" },
      { name: "Wasl", info: "" },
      { name: "Dashcams", info: "" },
    ],
    info: "Set which entities the user has access to",
  },
  {
    name: "FleetManagement",
    parent: "Modules_HasFleetManagement",
    subPermissions: [
      { name: "Home", info: "" },
      { name: "Dashboard", info: "" },
      { name: "Actions", info: "" },
      { name: "Issues", info: "" },
      { name: "External", info: "" },
      { name: "Map", info: "" },
      { name: "DeviceState", info: "" },
      { name: "DeviceCards", info: "" },
      { name: "DeviceUtilization", info: "" },
      { name: "Costs", info: "" },
      { name: "Sensors", info: "" },
      { name: "RawData", info: "" },
      { name: "CargoAndRoutes", info: "" },
      { name: "DriverCards", info: "" },
      { name: "DriverScore", info: "" },
      { name: "Equipment", info: "" },
      { name: "DeviceSettingsView", info: "" },
      { name: "DeviceCommandQueueView", info: "" },
      { name: "DeviceSettingsWakeUp", info: "" },
      { name: "OdoView", info: "" },
      { name: "OdoEdit", info: "" },
      { name: "EngineHourView", info: "" },
      { name: "EngineHourEdit", info: "" },
      { name: "WorkingHours", info: "" },
      { name: "Warehouses", info: "" },
      { name: "Audit", info: "" },
      { name: "Jobs", info: "" },
      { name: "Tags", info: "" },
      { name: "Hierarchy", info: "" },
      { name: "Trips", info: "" },
      { name: "Messages", info: "" },
      { name: "Notifications", info: "" },
      { name: "NotificationSettings", info: "" },
      { name: "Episodes", info: "" },
      { name: "GeofenceStates", info: "" },
      { name: "ImmobilizeVehicle", info: "" },
      { name: "Reporting", info: "" },
      { name: "ExportImport", info: "" },
      { name: "Log", info: "" },
      { name: "Overview", info: "" },
      { name: "Cockpit", info: "" },
      { name: "Sharing", info: "" },
      { name: "DeviceMaintenance", info: "" },
      { name: "DeviceFuel", info: "" },
    ],
    info: "Set user permissions for FleetManagement module.",
  },
  {
    name: "Actions",
    parent: "FleetManagement_Actions",
    subPermissions: [
      { name: "Activate", info: "" },
      { name: "Archive", info: "" },
      { name: "MoveToAccount", info: "" },
      { name: "ReplaceDevice", info: "" },
      { name: "ChangeReseller", info: "" },
      { name: "RemoveFromAccount", info: "" },
      { name: "RequestLocation", info: "" },
      { name: "CreateInstantReport", info: "" },
      { name: "CalibrateOdo", info: "" },
      { name: "RerunTrips", info: "" },
      { name: "RefreshDeviceStateFromDatabase", info: "" },
      { name: "CreateBasicTrigger", info: "" },
      { name: "ResetState", info: "" },
      { name: "SendCustomCommand", info: "" },
    ],
    info: "Set user permissions for actions on the FleetManagement module.",
  },
  {
    name: "FleetOverview",
    parent: "Modules_HasFleetOverview",
    subPermissions: [
      { name: "History", info: "" },
      { name: "Realtime", info: "" },
      { name: "Events", info: "" },
      { name: "Reports", info: "" },
      { name: "Dashboard", info: "" },
      { name: "Settings", info: "" },
      { name: "TargetVsActual", info: "" },
      { name: "AssetScore", info: "" },
      { name: "DriverScoreNew", info: "" },
      { name: "Infringements", info: "" },
    ],
    info: "Set user permissions for FleetOverview module.",
  },
  {
    name: "Setup",
    parent: "Modules_HasSetup",
    subPermissions: [
      { name: "SetupSiteOperatingSchedule", info: "" },
      { name: "SetupSiteMaintenanceTypes", info: "" },
      { name: "SetupSiteAssetTypes", info: "" },
      { name: "SetupSiteAssetImmobilization", info: "" },
      { name: "SetupSiteCargoAndRoutes", info: "" },
      { name: "SetupSiteManualFuel", info: "" },
      { name: "SetupSitesFOTA", info: "" },
      { name: "SetupSiteRfidSettings", info: "" },
      { name: "CanGetLocation", info: "" },
      { name: "ViewEventsNotifications", info: "" },
      { name: "ControlEventsNotifications", info: "" },
      { name: "ViewGeofences", info: "" },
      { name: "ControlGeofences", info: "" },
      { name: "ViewDeviceGroups", info: "" },
      { name: "ControlDeviceGroups", info: "" },
      { name: "CanEditAndDeleteAssets", info: "" },
      { name: "Projects", info: "" },
    ],
    info: "Set user permissions for Setup module.",
  },
  {
    name: "MileageRegistrationModule",
    parent: "Modules_HasMileageRegistration",
    subPermissions: [],
    info: "Set user permissions for MileageRegistration module.",
  },
  {
    name: "FuelModule",
    parent: "Modules_Fuel",
    subPermissions: [],
    info: "Set user permissions for Fuel module.",
  },
  {
    name: "MaintenanceModule",
    parent: "Modules_HasMaintenance",
    subPermissions: [{ name: "MaintenanceAlerts", info: "" }],
    info: "Set user permissions for Maintenance module.",
  },
  {
    name: "Api360",
    parent: "Modules_HasApi360",
    subPermissions: [{ name: "PushNotifications", info: "" }],
    info: "Set user permissions for Api360.",
  },
  {
    name: "ReportingModule",
    parent: "Modules_HasReporting",
    subPermissions: [
      { name: "HasReportingAttachementOption", info: "" },
      { name: "HasReportingInteractiveMode", info: "" },
      { name: "HasReportSubscriptions", info: "" },
    ],
    info: "Set user permissions for Reporting module.",
  },
  {
    name: "DeclarationModule",
    parent: "Modules_HasDeclaration",
    subPermissions: [],
    info: "Set user permissions for Declaration module.",
  },
  {
    name: "PrivateFleetModule",
    parent: "Modules_HasPrivateFleet",
    subPermissions: [
      { name: "Admin", info: "" },
      { name: "Editor", info: "" },
      { name: "EditorLight", info: "" },
    ],
    info: "Set user permissions for PrivateFleet module.",
  },
  {
    name: "MobileApp",
    parent: "Modules_HasMobileApp",
    subPermissions: [
      { name: "Trips", info: "" },
      { name: "Messages", info: "" },
      { name: "Calibrations", info: "" },
      { name: "Statistics", info: "" },
      { name: "Settings", info: "" },
      { name: "CustomCommand", info: "" },
      { name: "Geofence", info: "" },
      { name: "ShareLocation", info: "" },
    ],
    info: "Set user permissions for Mobile Apps.",
  },
  {
    name: "Accounts",
    parent: "Entities_Accounts",
    subPermissions: [
      { name: "View", info: "" },
      { name: "Add", info: "" },
      { name: "Edit", info: "" },
      { name: "Delete", info: "" },
    ],
    info: "Set user permissions for Account entities.",
  },
  {
    name: "Assets",
    parent: "Entities_Assets",
    subPermissions: [
      { name: "View", info: "" },
      { name: "Add", info: "" },
      { name: "Edit", info: "" },
      { name: "Delete", info: "" },
    ],
    info: "Set user permissions for Asset entities.",
  },
  {
    name: "Dashcams",
    parent: "Entities_Dashcams",
    subPermissions: [
      { name: "View", info: "" },
      { name: "Add", info: "" },
      { name: "Edit", info: "" },
      { name: "Delete", info: "" },
    ],
    info: "Set user permissions for Dashcams entities.",
  },
  {
    name: "AssetTypes",
    parent: "Entities_AssetTypes",
    subPermissions: [
      { name: "View", info: "" },
      { name: "Add", info: "" },
      { name: "Edit", info: "" },
      { name: "Delete", info: "" },
    ],
    info: "Set user permissions for AssetType entities.",
  },
  {
    name: "Devices",
    parent: "Entities_Devices",
    subPermissions: [
      { name: "View", info: "" },
      { name: "Add", info: "" },
      { name: "Edit", info: "" },
      { name: "Delete", info: "" },
    ],
    info: "Set user permissions for Device entities.",
  },
  {
    name: "AssetGroups",
    parent: "Entities_AssetGroups",
    subPermissions: [
      { name: "View", info: "" },
      { name: "Add", info: "" },
      { name: "Edit", info: "" },
      { name: "Delete", info: "" },
    ],
    info: "Set user permissions for AssetGroups entities.",
  },
  {
    name: "Drivers",
    parent: "Entities_Drivers",
    subPermissions: [
      { name: "View", info: "" },
      { name: "Add", info: "" },
      { name: "Assign", info: "" },
      { name: "Edit", info: "" },
      { name: "Delete", info: "" },
    ],
    info: "Set user permissions for Driver entities.",
  },
  {
    name: "DriverGroups",
    parent: "Entities_DriverGroups",
    subPermissions: [
      { name: "View", info: "" },
      { name: "Add", info: "" },
      { name: "Edit", info: "" },
      { name: "Delete", info: "" },
    ],
    info: "Set user permissions for DriverGroup entities.",
  },
  {
    name: "Geofences",
    parent: "Entities_Geofences",
    subPermissions: [
      { name: "View", info: "" },
      { name: "Add", info: "" },
      { name: "Edit", info: "" },
      { name: "Delete", info: "" },
    ],
    info: "Set user permissions for Geofence entities.",
  },
  {
    name: "GeofenceGroups",
    parent: "Entities_GeofenceGroups",
    subPermissions: [
      { name: "View", info: "" },
      { name: "Add", info: "" },
      { name: "Edit", info: "" },
      { name: "Delete", info: "" },
    ],
    info: "Set user permissions for GeofenceGroup entities.",
  },
  {
    name: "Resellers",
    parent: "Entities_Resellers",
    subPermissions: [
      { name: "View", info: "" },
      { name: "Add", info: "" },
      { name: "Edit", info: "" },
      { name: "Delete", info: "" },
    ],
    info: "Set user permissions for Reseller entities.",
  },
  {
    name: "Users",
    parent: "Entities_Users",
    subPermissions: [
      { name: "View", info: "" },
      { name: "Add", info: "" },
      { name: "Edit", info: "" },
      { name: "Delete", info: "" },
    ],
    info: "Set user permissions for User entities.",
  },
  {
    name: "UserContacts",
    parent: "Entities_UserContacts",
    subPermissions: [
      { name: "View", info: "" },
      { name: "Add", info: "" },
      { name: "Edit", info: "" },
      { name: "Delete", info: "" },
    ],
    info: "Set user permissions for UserContact entities.",
  },
  {
    name: "UserContactGroups",
    parent: "Entities_UserContactGroups",
    subPermissions: [
      { name: "View", info: "" },
      { name: "Add", info: "" },
      { name: "Edit", info: "" },
      { name: "Delete", info: "" },
    ],
    info: "Set user permissions for UserContactGroup entities.",
  },
  {
    name: "UserRoles",
    parent: "Entities_UserRoles",
    subPermissions: [
      { name: "View", info: "" },
      { name: "Add", info: "" },
      { name: "Edit", info: "" },
      { name: "Delete", info: "" },
    ],
    info: "Set user permissions for UserRole entities.",
  },
  {
    name: "FuelEvents",
    parent: "Entities_FuelEvents",
    subPermissions: [
      { name: "View", info: "" },
      { name: "Add", info: "" },
      { name: "Edit", info: "" },
      { name: "Delete", info: "" },
    ],
    info: "Set user permissions for Fuel entities.",
  },
  {
    name: "Maintenances",
    parent: "Entities_Maintenances",
    subPermissions: [
      { name: "View", info: "" },
      { name: "Add", info: "" },
      { name: "Edit", info: "" },
      { name: "Delete", info: "" },
    ],
    info: "Set user permissions for Maintenance entities.",
  },
  {
    name: "Warehouses",
    parent: "Entities_Warehouses",
    subPermissions: [
      { name: "View", info: "" },
      { name: "Add", info: "" },
      { name: "Edit", info: "" },
      { name: "Delete", info: "" },
    ],
    info: "Set user permissions for Warehouse entities.",
  },
  {
    name: "WarehouseEvents",
    parent: "Entities_Warehouses",
    subPermissions: [
      { name: "View", info: "" },
      { name: "Add", info: "" },
      { name: "Edit", info: "" },
      { name: "Delete", info: "" },
    ],
    info: "Set user permissions for Warehouse events.",
  },
  {
    name: "WarehouseZones",
    parent: "Entities_Warehouses",
    subPermissions: [
      { name: "View", info: "" },
      { name: "Add", info: "" },
      { name: "Edit", info: "" },
      { name: "Delete", info: "" },
    ],
    info: "Set user permissions for Warehouse zones.",
  },
  {
    name: "Wasl",
    parent: "Entities_Wasl",
    subPermissions: [
      { name: "View", info: "" },
      { name: "Add", info: "" },
      { name: "Edit", info: "" },
      { name: "Inquire", info: "" },
      { name: "UpdateStoringCategory", info: "" },
      { name: "Delete", info: "" },
    ],
    info: "Set user permissions for Wasl entities.",
  },
  {
    name: "MaintenanceTypes",
    parent: "Entities_MaintenanceTypes",
    subPermissions: [
      { name: "View", info: "" },
      { name: "Add", info: "" },
      { name: "Edit", info: "" },
      { name: "Delete", info: "" },
    ],
    info: "Set user permissions for MaintenanceType entities.",
  },
  {
    name: "Trailers",
    parent: "Entities_Trailers",
    subPermissions: [
      { name: "View", info: "" },
      { name: "Add", info: "" },
      { name: "Assign", info: "" },
      { name: "Edit", info: "" },
      { name: "Delete", info: "" },
    ],
    info: "Set user permissions for Trailer entities.",
  },
  {
    name: "Projects",
    parent: "Entities_Projects",
    subPermissions: [
      { name: "View", info: "" },
      { name: "Add", info: "" },
      { name: "Assign", info: "" },
      { name: "Edit", info: "" },
      { name: "Delete", info: "" },
    ],
    info: "Set user permissions for Project entities.",
  },
  {
    name: "Triggers",
    parent: "Entities_Triggers",
    subPermissions: [
      { name: "View", info: "" },
      { name: "Add", info: "" },
      { name: "Edit", info: "" },
      { name: "Delete", info: "" },
    ],
    info: "Set user permissions for Trigger entities.",
  },
  {
    name: "FleetManagementReporting",
    parent: "FleetManagement_Reporting",
    subPermissions: [
      { name: "HasReportColumnSelection", info: "" },
      { name: "View", info: "" },
      { name: "Add", info: "" },
      { name: "Edit", info: "" },
      { name: "Delete", info: "" },
      { name: "HasDefaultTemplates", info: "" },
    ],
    info: "Set user permissions for FM Reporting module.",
  },
  {
    name: "FleetManagementReports",
    parent: "FleetManagement_Reporting",
    subPermissions: [
      { name: "Trips", info: "Enables trips report in FM" },
      { name: "TripsWithDetails", info: "Enables trip with details report in FM" },
      { name: "AssetScore", info: "Enables asset score report in FM" },
      { name: "DriverUtilization", info: "Enables driver report in FM" },
      { name: "GeofenceActivity", info: "Enables geofence activity report in FM" },
      { name: "FleetSensors", info: "Enables fleet sensors report in FM" },
      { name: "TrackerStatus", info: "Enables tracker status report in FM" },
      { name: "FleetUtilization", info: "Enables fleet utilization report in FM" },
      { name: "FleetUtilizationInsideOutside", info: "Enables fleet utilization inside/outside report in FM" },
      { name: "TripsByDriver", info: "Enables trips by driver report in FM" },
      { name: "DataUsage", info: "Enables DataUsage report in FM" },
      { name: "GeofenceEpisodes", info: "Enables geofence episodes report in FM" },
      { name: "DriverAssignment", info: "Enables driver assignment report in FM" },
      { name: "FuelSummary", info: "Enables fuel summary report in FM" },
      { name: "FuelDetails", info: "Enables fuel details report in FM" },
      { name: "DriverScore", info: "Enables driver score report in FM" },
      { name: "CarSharing", info: "Enables carsharing report in FM" },
      { name: "DriverAbuse", info: "Enables driver abuse report in FM" },
      { name: "AssetAbuse", info: "Enables asset abuse report in FM" },
      { name: "FleetOveruse", info: "Enables fleet overuse report in FM" },
      { name: "TripsExtended", info: "Enables trips extended report in FM" },
      { name: "Reseller", info: "Enables reseller report in FM" },
      { name: "Speeding", info: "Enables speeding report in FM" },
      { name: "Idling", info: "Enables idling report in FM" },
      { name: "LocationsTemperature", info: "Enables LocationsTemperature report in FM" },
      { name: "MaintenanceDue", info: "Enables Maintenance Due report in FM" },
      { name: "MaintenanceDone", info: "Enables Maintenance Done report in FM" },
      { name: "PrivateFleetUtilization", info: "Enables Private fleet utilization report in FM" },
      { name: "FleetUtilizationWithDriver", info: "Enables Fleet utilization with driver in FM" },
      { name: "FleetUtilizationWithSchedule", info: "Enables Fleet utilization with schedule in FM" },
      { name: "GeofenceToGeofence", info: "Enables geofence to geofence report in FM" },
      { name: "FleetCosts", info: "Enables fleet costs in FM" },
      { name: "TripsWithDetailsByDriver", info: "Enables trip with details report in FM" },
    ],
    info: "Set user permissions for FM Reports",
  },
  {
    name: "FleetManagementIssues",
    parent: "FleetManagement_Issues",
    subPermissions: [
      { name: "HighVolume", info: "Issue related to sending too much messages" },
      { name: "Behaviour", info: "Issue related to the behavior of the tracker" },
      { name: "Installation", info: "Issue related to the installation of the tracker" },
      { name: "Trips", info: "Issues related to trips" },
      { name: "Canbus", info: "Issues related by data reported over CAN" },
      { name: "Latency", info: "Issues related to latency" },
    ],
    info: "Set user permissions for FM Issues",
  },
  {
    name: "Reports",
    parent: "Modules_HasReporting",
    subPermissions: [
      { name: "Ignition", info: "" },
      { name: "Location", info: "" },
      { name: "CumulativePTO", info: "" },
      { name: "Speeding", info: "" },
      { name: "Trips", info: "" },
      { name: "Geofence", info: "" },
      { name: "CumulativeDistance", info: "" },
      { name: "CumulativeWorkingHours", info: "" },
      { name: "Stoppage", info: "" },
      { name: "RefrigeratedCargo", info: "" },
      { name: "WorkingHoursExceptions", info: "" },
      { name: "DistanceTraveled", info: "" },
      { name: "FuelLevel", info: "" },
      { name: "AverageFuelConsumption", info: "" },
      { name: "Temperature", info: "" },
      { name: "Abuse", info: "" },
      { name: "PlannedRoutes", info: "" },
      { name: "ActiveSchedules", info: "" },
      { name: "RouteActuals", info: "" },
      { name: "RouteActualsOutOfTolerance", info: "" },
      { name: "RouteOutOfDeviation", info: "" },
      { name: "RoadSpeeds", info: "" },
      { name: "BehaviorByDriver", info: "" },
      { name: "BehaviorByAsset", info: "" },
      { name: "DriverScoreCard", info: "" },
      { name: "BehaviorByDriverTrend", info: "" },
      { name: "BehaviorByAssetTrend", info: "" },
      { name: "UtilizationDetail", info: "" },
      { name: "UtilizationSummary", info: "" },
      { name: "UtilizationDaily", info: "" },
      { name: "TripsWithDetails", info: "" },
      { name: "Idling", info: "" },
      { name: "FuelLog", info: "" },
      { name: "FuelEvents", info: "" },
      { name: "MaintenanceDue", info: "" },
      { name: "TripsWithDetailsIdling", info: "" },
      { name: "AbuseByAsset", info: "" },
      { name: "AbuseByDriver", info: "" },
      { name: "LastLocation", info: "" },
      { name: "RouteCargo", info: "" },
      { name: "Route", info: "" },
      { name: "GeofenceByDriver", info: "" },
      { name: "CarBattery", info: "" },
      { name: "ReportGeofenceActivity", info: "" },
      { name: "ReportLocationTemperature", info: "" },
      { name: "ReportGeofenceDetailedActivityStiho", info: "" },
      { name: "ReportMaintenanceOccurences", info: "" },
      { name: "ReportManualFuel", info: "" },
      { name: "ReportFleetEfficiency", info: "" },
      { name: "MaintenanceAlerts", info: "" },
      { name: "ReportAssetsByProject", info: "" },
      { name: "ReportProjects", info: "" },
    ],
    info: "Set user permissions for Reports",
  },
];
