import { ActivatedRouteSnapshot, CanActivateChildFn, CanActivateFn, RouterStateSnapshot } from "@angular/router";
import { environment } from "environments/environment";

/**
 * Functional CanActivate guard uses AuthenticationService to determine if a page can or cannot be shown.
 */
export const canActivateGuardOnlySSO: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const onlySSO = environment.OnlySSO;
  return !onlySSO;
};

/**
 * Functional CanActivateChild guard uses AuthenticationService to determine if a page can or cannot be shown.
 */
export const canActivateChildGuardOnlySSO: CanActivateChildFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {
  const onlySSO = environment.OnlySSO;
  return !onlySSO;
};
