import {
  Component,
  AfterViewInit,
  Input,
  ElementRef,
  ViewChild,
  OnInit,
  Output,
  EventEmitter,
  OnChanges,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  SimpleChanges,
} from "@angular/core";

import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { AuthenticationService } from "app/services/authentication/authentication.service";
import { HierarchieService } from "app/services/hierarchy/hierarchie.service";
import { TreeviewConfig } from "../treeview/models/treeview-config";
import { TreeviewItem } from "../treeview/models/treeview-item";
import { DropdownTreeviewComponent } from "../treeview/components/dropdown-treeview/dropdown-treeview.component";

const noop = () => {};

@Component({
  selector: "fh-hierarchy-group-input",
  templateUrl: "./hierarchyGroupSelector.template.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: NG_VALUE_ACCESSOR, multi: true, useExisting: HierarchyGroupInputComponent }],
})
export class HierarchyGroupInputComponent implements OnChanges, OnInit, AfterViewInit, ControlValueAccessor {
  @ViewChild("DropdownTreeviewComponent", { static: false }) dropdownTreeviewComponent: DropdownTreeviewComponent;

  @Input() placeholder: string;
  @Input() clearable = true;
  @Input() selectedAccountId;
  @Input() autoSelect = true;
  @Input() readonly = false;
  @Input() multiple = true;
  @Input() tableInline;
  @Input() activeOnly = false;

  @Input() includeAssets = false;
  @Input() includeDrivers = false;
  @Input() includeGeofences = false;

  @Output() onChanged = new EventEmitter();

  loading = false;

  config: TreeviewConfig = {
    hasAllCheckBox: false,
    hasFilter: true,
    hasDivider: false,
    hasCollapseExpand: false,
    maxHeight: 500,
  };

  serverResponses = {};
  createdItems = [];

  private _value: any[];
  private _onChange: (_: any) => void = noop;

  hierarchyGroups: any = [];
  items: TreeviewItem[];

  get value(): any {
    return this._value;
  }

  set value(v: any) {
    if (v !== this._value) {
      this._value = v;
      this._onChange(v);
      this.cd.markForCheck();
    }
  }

  constructor(private cd: ChangeDetectorRef, private hierarchyService: HierarchieService) {}

  onSelectedChange(event) {
    this.value = event;
  }

  onFilterChange(event) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedAccountId && changes.selectedAccountId.currentValue != null) {
      this.loadHierarchy();
    }

    if (changes.includeAssets || changes.includeDrivers || changes.includeGeofences) {
      this.loadHierarchy();
    }
  }

  ngOnInit() {
    if (!this.selectedAccountId) {
      this.loadHierarchy();
    }
  }

  createNestedHierarchyWithChildren(data) {
    const groupMap = {};

    // Populate the groupMap
    data.forEach((group) => {
      groupMap[group.groupId] = { ...group, children: [] };
    });

    // Add assets, drivers, and geofences as children
    Object.values(groupMap).forEach((group: any) => {
      const assets = Object.entries(group.assets).map(([id, name]) => ({
        id,
        text: name,
        icon: "fa-car",
        value: "a_" + id,
        type: "asset" as const,
        checked: false,
      }));

      if (this.includeAssets) {
        group.children?.push(...assets);
      }

      const drivers = Object.entries(group.drivers).map(([id, name]) => ({
        id,
        text: name,
        icon: "fa-id-card-alt",
        value: "d_" + id,
        type: "driver" as const,
        checked: false,
      }));

      if (this.includeDrivers) {
        group.children?.push(...drivers);
      }

      const geofences = Object.entries(group.geofences).map(([id, name]) => ({
        id,
        text: name,
        icon: "fa-draw-polygon",
        value: "g_" + id,
        type: "geofence" as const,
        checked: false,
      }));

      if (this.includeGeofences) {
        group.children?.push(...geofences);
      }
    });

    const treeview = [];
    // Link groups to their parents and transform to TreeviewItem
    data.forEach((group, index) => {
      const treeviewItem = {
        text: group.groupName,
        icon: "fa-folder-tree",
        value: "hg_" + group.groupId,
        collapsed: true,
        checked: false,
        children: groupMap[group.groupId].children,
      };

      if (group.parentId && groupMap[group.parentId]) {
        groupMap[group.parentId].children.push(treeviewItem);
      } else {
        treeview.push(treeviewItem);
      }
    });

    return treeview;
  }

  loadHierarchy(values = []) {
    if (!this.selectedAccountId) {
      return;
    }

    this.loading = true;

    this.hierarchyService.getHierarchyGroupsByAccountId(this.selectedAccountId).subscribe({
      next: (result) => {
        this.hierarchyGroups = this.createNestedHierarchyWithChildren(result);

        this.items = [];
        this.hierarchyGroups.forEach((element) => {
          const tree = new TreeviewItem(element, false);
          this.checkChildrenValues(tree, values);
          this.items.push(tree);
        });

        this.loading = false;
        this.cd.markForCheck();
      },
      error: (error) => {
        this.loading = false;
        this.cd.markForCheck();
      },
    });
  }

  ngAfterViewInit() {
    const __this = this;
  }

  writeValue(values: any) {
    this._value = values;

    if (this.items && values && values.length > 0) {
      this.loadHierarchy(values);
    }

    this.cd.markForCheck();
  }

  registerOnChange(fn: (value: any) => void) {
    this._onChange = fn;
    this.cd.markForCheck();
  }

  registerOnTouched(fn: any) {
    this.cd.markForCheck();
  }

  checkChildrenValues(items, values) {
    if (values.includes(items.value)) {
      items.checked = true;
    }

    items.children?.forEach((child) => {
      this.checkChildrenValues(child, values);
    });
  }
}
