import { Component, Input, OnInit, ChangeDetectorRef } from "@angular/core";
import { ControlContainer, NgForm } from "@angular/forms";
import { FormMode } from "app/common/enums";
import { ClientAnnouncement } from "app/models/clientAnnouncement.model";
import { AccountService } from "app/services/account/account.service";
import { AuthenticationService } from "app/services/authentication/authentication.service";
import { UserService } from "app/services/users/user.service";
import { BsDatepickerConfig } from "ngx-bootstrap/datepicker";

@Component({
  selector: "fh-client-announcement-details",
  templateUrl: "clientAnnouncementDetails.component.html",
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class ClientAnnouncementDetailsComponent implements OnInit {
  @Input() clientAnnouncement: ClientAnnouncement;
  @Input() formMode: any = FormMode.read;

  permissions: {};

  // Datepicker
  public dpConfig: Partial<BsDatepickerConfig> = new BsDatepickerConfig();

  ngOnInit(): void {
    // Datepicker
    this.dpConfig.containerClass = "theme-default"; // or whatever color
    this.dpConfig.dateInputFormat = "ll";
    this.dpConfig.isAnimated = false;
    this.dpConfig.withTimepicker = false;
  }

  constructor(
    private cd: ChangeDetectorRef,
    private authenticationService: AuthenticationService,
    private accountService: AccountService,
    private userService: UserService
  ) {
    this.permissions = this.authenticationService.permissions;
  }
}
