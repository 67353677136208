import { ServiceInterval } from "./serviceItem.model";

export class WorkOrder {
  id: number;
  accountId: number;
  deviceId: number;
  assetId: number;
  assetName: string;
  resellerId: number;
  resellerDescription: string;
  companyName: string;
  serviceDate: Date;
  odometer: number;
  engineHours: number;
  completed: boolean;
  totalCost: number;
  workOrderItems: WorkOrderItem[];
  description: string;
}

export class WorkOrderItem {
  id: number;
  workOrderId: number;
  serviceItemId: number;
  serviceDate: Date;
  serviceInterval: ServiceInterval;
  serviceItemName: string;
  description: string;
  quantity: number = 1;
  itemPrice: number = 0;
  laborCost: number = 0;
  totalCost: number;
  recurrence: string;
  planned: boolean;
  exists: boolean;
  remarks: string;
  discount: number = 0;
  partNumber: string;
  materialReceiptInspectionVoucherNumber: string;
  localPurchaseOrderNumber: string;
  supplierName: string;
}
