import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { ControlContainer, FormGroup, NgForm } from "@angular/forms";
import { Router } from "@angular/router";
import { FormMode } from "app/common/enums";
import { ServiceItem } from "app/models/serviceItem.model";
import { WorkOrder, WorkOrderItem } from "app/models/workOrder.model";
import { AssetService } from "app/services/asset/asset.service";
import { AuthenticationService } from "app/services/authentication/authentication.service";
import { DeviceService } from "app/services/device/device.service";
import { MaintenanceV2Service } from "app/services/maintenancev2/maintenanceV2.service";
import { BsDatepickerConfig } from "ngx-bootstrap/datepicker";
import { ConfirmationModalComponent } from "./confirmationModal.component";
import { roundAsString } from "app/common/globals";

@Component({
  selector: "fh-maintenance-work-order-details",
  templateUrl: "workOrderDetails.component.html",
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class MaintenanceWorkOrderDetailsComponent implements AfterViewInit, OnChanges {
  @ViewChild("deleteModal") deleteModal: ConfirmationModalComponent;
  @Input() form;
  @Input() formMode = FormMode.read;
  @Input() workOrder: WorkOrder = new WorkOrder();
  @Output() insert = new EventEmitter();
  @Output() update = new EventEmitter();
  @Output() showDeleteModal = new EventEmitter();

  success;
  error;
  loading = false;

  workOrderId: number;

  formGroup: FormGroup;
  permissionName = "Maintenances_View";

  permissions: {};

  selectedResellerId;
  serviceItems: ServiceItem[] = [];
  asset;

  workOrderItem: WorkOrderItem;
  editItem = false;
  editServiceItem = false;

  public dpConfig: Partial<BsDatepickerConfig> = new BsDatepickerConfig();

  constructor(
    private cd: ChangeDetectorRef,
    private authenticationService: AuthenticationService,
    private maintenanceService: MaintenanceV2Service,
    private deviceService: DeviceService,
    private assetService: AssetService,
    private router: Router
  ) {
    // Datepicker
    this.dpConfig.containerClass = "theme-default"; // or whatever color
    this.dpConfig.dateInputFormat = "lll";
    this.dpConfig.isAnimated = false;
    this.dpConfig.withTimepicker = true;
    this.dpConfig.keepDatepickerOpened = true;

    this.permissions = this.authenticationService.permissions;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.workOrder?.currentValue?.accountId) {
      this.getServiceItems();
    }
  }

  ngAfterViewInit(): void {
    this.formGroup = this.form.form;
  }

  setFormMode(mode) {
    this.formMode = mode;

    this.workOrderItem = null;
    this.editItem = false;
    this.editServiceItem = false;
  }

  resellerChanged(resellerId) {
    this.workOrder.accountId = null;

    this.selectedResellerId = resellerId;

    this.accountChanged(null);

    setTimeout(() => {
      this.cd.markForCheck();
    }, 100);
  }

  accountChanged(accountId) {
    this.workOrderItem = null;
    this.workOrder.accountId = accountId;

    this.getServiceItems();
  }

  handleAssetChanged(asset) {
    this.workOrder.assetId = asset ? asset.id : undefined;
    this.asset = asset;

    if (!asset) {
      return;
    }

    this.assetService.getAssetById(asset.id).subscribe((asset) => {
      this.deviceService.getDeviceById(asset.deviceId).subscribe((result) => {
        this.workOrder.engineHours = Math.round(
          ((result?.asset?.engineHourCalibrationOdoOffset ?? 0) + (result?.engineHourRunningInSeconds ?? 0)) / 3600
        );

        this.workOrder.odometer = Math.round(
          ((result?.asset?.incrementalOdoDistanceOffset ?? 0) + (result?.incrementalEndOdo ?? 0)) / 1000
        );
      });
    });
  }

  roundAsString(value, decimals) {
    return roundAsString(value, decimals);
  }

  getServiceItems(): void {
    if (!this.workOrder?.accountId) {
      return;
    }

    this.maintenanceService.getServiceItemsByAccount(this.workOrder.accountId).subscribe((result) => {
      this.serviceItems = result;

      if (this.formMode == 2) {
        const serviceItemIds = new Set(this.serviceItems.map((serviceItem) => serviceItem.id));

        for (let i = this.workOrder.workOrderItems.length - 1; i >= 0; i--) {
          if (
            this.workOrder.workOrderItems[i].serviceItemId != null &&
            !serviceItemIds.has(this.workOrder.workOrderItems[i].serviceItemId)
          ) {
            this.workOrder.workOrderItems.splice(i, 1);
          }
        }
      }

      this.workOrderItem = null;
      this.editItem = false;
      this.editServiceItem = false;

      this.cd.markForCheck();
    });
  }

  showModal(itemId) {
    this.showDeleteModal.emit(itemId);
  }

  addWorkOrderItem() {
    this.editItem = false;
    this.workOrderItem = new WorkOrderItem();

    // Assigning temporary id for editing
    this.workOrderItem.id = Math.floor(Math.random() * (999999 - 100000)) + 100000;
  }

  editWorkOrderItem(item) {
    this.editItem = true;
    this.editServiceItem = item.exists;
    this.workOrderItem = { ...item };
  }

  addItemToTable() {
    if (this.editItem) {
      this.workOrder.workOrderItems.forEach((item) => {
        if (this.workOrderItem.id == item.id) {
          const index = this.workOrder.workOrderItems.findIndex((x) => x.id == item.id);
          this.workOrder.workOrderItems[index] = this.workOrderItem;
        }
      });
    } else {
      this.serviceItems.forEach((item) => {
        if (item.id == this.workOrderItem.serviceItemId) {
          this.workOrderItem.serviceItemName = item.name;
        }
      });

      this.workOrder?.workOrderItems.push(this.workOrderItem);
    }

    this.workOrderItem = null;
    this.editItem = false;
    this.editServiceItem = false;
  }

  onInsert() {
    this.insert.emit(this.workOrder);

    this.workOrderItem = null;
    this.editItem = false;
    this.editServiceItem = false;
  }

  onSave() {
    //Removing temporary id
    this.workOrder.workOrderItems.forEach((item) => {
      if (item.exists == null) {
        item.id = null;
      }
    });

    this.update.emit(this.workOrder);

    this.workOrderItem = null;
    this.editItem = false;
    this.editServiceItem = false;
  }

  onDelete() {
    this.loading = true;

    this.maintenanceService.deleteWorkOrder(this.workOrderId, this.workOrder.assetId).subscribe(
      (result) => {
        this.error = null;
        this.success = {
          statusText: "Success",
          success: "work order is successfully deleted.",
        };
        this.cd.markForCheck();

        setTimeout(() => {
          this.router.navigate(["/MaintenancesV2/WorkOrders/Overview"]);
        }, 3000);
      },
      (error) => {
        this.success = null;
        this.error = error;
        this.loading = false;
      }
    );
  }
}
