import { Component, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { GridBase360Directive } from "app/common/360Grid.base";
import { StorageHelper } from "app/common/storagehelper";
import { createdCellCheckbox, getGridButtons, getGridLanguages } from "app/common/gridhelper";
import { AuthenticationService } from "app/services/authentication/authentication.service";
import { ColorService } from "app/services/common/color.service";
import * as Moment from "moment";
import { roundAsNumber } from "app/common/globals";
import { MaintenanceV2Service } from "app/services/maintenancev2/maintenanceV2.service";

@Component({
  selector: "fh-maintenance-service-item",
  templateUrl: "serviceItems.template.html",
})
export class MaintenanceServiceItemViewComponent extends GridBase360Directive implements OnInit {
  loading = false;
  languageLoaded: boolean;
  permissions: {};

  permissionName = "MaintenanceTypes_View";
  constructorName = "MaintenanceServiceItemViewComponent";

  success;
  error;
  warning;

  timezoneIana: string;

  constructor(
    private translateService: TranslateService,
    protected storageHelper: StorageHelper,
    private maintenanceService: MaintenanceV2Service,
    private authenticationService: AuthenticationService,
    private colorService: ColorService
  ) {
    super(storageHelper);

    this.timezoneIana = this.authenticationService.getTimeZoneIana();
    this.permissions = this.authenticationService.permissions;
  }

  ngOnInit() {
    this.translateService.get("general.date").subscribe((value) => {
      this.languageLoaded = true;
      this.initGrid();
    });
  }

  initGrid(): void {
    console.log("Init grid");

    const that = this;

    $.fn["dataTable"].ext.search.pop();

    this.loading = true;

    this.columns = [
      {
        name: "id",
        data: "id",
        orderable: false,
        title: '<div class="hideDropdown"></div>',
        width: "20",
        render: function (data, type, row) {
          return (
            "<a class='btn btn-primary btn-grid' title='" +
            that.translateService.instant("general.details") +
            "' href='/#/MaintenancesV2/ServiceItems/Details/Index/" +
            data +
            '\'><span class="d-none d-md-inline-flex" style="padding-left: 7px">' +
            that.translateService.instant("general.details") +
            " </span><i class='fas fa-fw fa-angle-right'></i></a>"
          );
        },
      },
      {
        name: "id_export",
        data: "id",
        className: "noVis",
        title: this.translateService.instant("general.id"),
        visible: false,
      },
      {
        name: "name",
        data: "name",
        title: this.translateService.instant("general.name"),
      },
      {
        name: "occurrenceCount",
        data: "occurrenceCount",
        defaultContent: "-",
        title: this.translateService.instant("general.occurrenceCount"),
        render: (data, type, row) => {
          return data ? roundAsNumber(data, 0).toLocaleString() : "-";
        },
      },
      {
        name: "assetCount",
        data: "assetCount",
        defaultContent: "-",
        title: this.translateService.instant("general.assetCount"),
        render: (data, type, row) => {
          return data ? roundAsNumber(data, 0).toLocaleString() : "-";
        },
      },
      {
        name: "threshold",
        defaultContent: "-",
        title: this.translateService.instant("general.threshold"),
        render: function (data, type, row) {
          let result = "";
          if (row.serviceInterval?.dateThreshold) {
            const date = Moment.utc(row.serviceInterval.dateThreshold)["tz"](that.timezoneIana);
            result += date.format("ll");
          }
          if (row.serviceInterval?.daysThreshold) {
            if (result !== "") {
              result += " - ";
            }
            result += row.serviceInterval?.daysThreshold + " days";
          }
          if (row.serviceInterval?.kmThreshold) {
            if (result !== "") {
              result += " - ";
            }
            result += row.serviceInterval?.kmThreshold + " km";
          }
          if (row.serviceInterval?.hourThreshold) {
            if (result !== "") {
              result += " - ";
            }
            result += row.serviceInterval?.hourThreshold + " hour";
          }
          return result;
        },
      },
      {
        name: "daysThreshold",
        data: "serviceInterval.daysThreshold",
        defaultContent: "-",
        title: this.translateService.instant("general.daysThreshold"),
        visible: false,
      },
      {
        name: "kmThreshold",
        data: "serviceInterval.kmThreshold",
        defaultContent: "-",
        title: this.translateService.instant("general.kilometersThreshold"),
        visible: false,
      },
      {
        name: "dateThreshold",
        data: "serviceInterval.dateThreshold",
        defaultContent: "-",
        title: this.translateService.instant("general.dateThreshold"),
        render: function (data, type, row) {
          if (data) {
            if (type && type === "display") {
              const date = Moment.utc(data)["tz"](that.timezoneIana);
              return data ? '<span title=" ' + date.toLocaleString() + '">' + date.format("ll") + "</span>" : "";
            } else {
              return data;
            }
          }
          return "-";
        },
        visible: false,
      },
      {
        name: "hourThreshold",
        data: "serviceInterval.hourThreshold",
        defaultContent: "-",
        title: this.translateService.instant("general.hoursThreshold"),
        visible: false,
      },
      {
        name: "isUnique",
        data: "isUnique",
        title: this.translateService.instant("general.unique"),
        type: "checkBox",
        defaultContent: "",
        visible: false,
        createdCell: createdCellCheckbox,
      },
      {
        name: "createdTimestamp",
        data: "timeStamp",
        defaultContent: "-",
        title: this.translateService.instant("general.createdDate"),
        render: function (data, type, row) {
          if (type && type === "display") {
            const date = Moment.utc(data)["tz"](that.timezoneIana);
            return data ? '<span title=" ' + date.toLocaleString() + '">' + date.format("lll") + "</span>" : "";
          } else {
            return data;
          }
        },
        visible: false,
      },
      {
        name: "companyName",
        data: "companyName",
        title: this.translateService.instant("general.companyName"),
      },
      {
        name: "resellerDescription",
        data: "resellerDescription",
        visible: false,
        title: this.translateService.instant("general.reseller"),
      },
    ];

    this.dtOptions = {
      buttons: getGridButtons(
        this.commonExportOptions,
        "serviceitem_overview",
        this.translateService.instant("menu.messagesoverview"),
        this.colorService
      ),
      pagingType: "simple_numbers",
      serverSide: false,
      processing: false,
      scrollX: true,
      colReorder: { fixedColumnsLeft: 1 },
      order: [[2, "asc"]],
      stateSave: false,
      ajax: (dataTablesParameters: any, callback) => {
        $(".dataTables_info").html(this.translateService.instant("grid.loadingData"));
        this.loading = true;

        this.maintenanceService.getServiceItems().subscribe({
          next: (resp) => {
            callback({
              recordsTotal: resp.length,
              recordsFiltered: resp.length,
              data: resp,
            });
          },
          error: (error) => {
            this.success = null;
            this.error = error;
            this.loading = false;
          },
        });
      },
      initComplete: function (settings, json) {
        that.loading = false;

        console.log("init complete");
        that.checkFilters();
        that.drawFilterRow();
        that.loading = false;
      },
      colVis: {
        restore: this.translateService.instant("general.restore"),
        showAll: this.translateService.instant("general.showAll"),
        showNone: this.translateService.instant("general.hideAll"),
      },
      columns: this.columns,
      pageLength: 17,
      lengthMenu: [
        [10, 17, 25, 50, 200, -1],
        [10, 17, 25, 50, 200, this.translateService.instant("general.all")],
      ],
      language: getGridLanguages(this.translateService),
      rowCallback: (row, data) => {},
    };
  }
}
