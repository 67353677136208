import { inject } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivateChildFn, CanActivateFn, RouterStateSnapshot } from "@angular/router";
import { AuthenticationService } from "./services/authentication/authentication.service";

/**
 * Functional CanActivate guard uses AuthenticationService to determine if a page can or cannot be shown.
 */
export const canActivateGuardSharing: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const authService = inject(AuthenticationService);
  return authService.IsAuthenticatedShareToken(state.url);
};

/**
 * Functional CanActivateChild guard uses AuthenticationService to determine if a page can or cannot be shown.
 */
export const canActivateChildGuardSharing: CanActivateChildFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {
  const authService = inject(AuthenticationService);
  return authService.IsAuthenticatedShareToken(state.url);
};
