import { NgModule } from "@angular/core";
// Forms
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouterModule } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { DataTablesModule } from "angular-datatables";
import { MomentModule } from "ngx-moment";
import { NumberOnlyDirective } from "app/common/numbersOnly.directive";
// Pipes
import { DataTables360Module } from "app/modules/360grid";
import { PrettyJsonModule } from "app/modules/prettyjson/prettyjson.module";
// NGX-Bootstrap
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { ModalModule } from "ngx-bootstrap/modal";
import { TooltipModule } from "ngx-bootstrap/tooltip";
import { TabsModule } from "ngx-bootstrap/tabs";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { TimepickerModule } from "ngx-bootstrap/timepicker";
// Tags (chips)
import { TagInputModule } from "ngx-chips";

import { TimezoneSelectorModule } from "../../modules/timezoneselector/timezoneselector.module";
import { WizardModule } from "../../modules/wizard/wizard.module";
import { AddressDetailsComponent } from "./usercontrols/addressDetails.component";
import { AssetDetailsComponent } from "./usercontrols/assetDetails.component";
import { FuelThresholdEditComponent } from "./usercontrols/fuelThreshold.component";
import { CalibrationComponent } from "./usercontrols/calibrationDetails.component";
import { ConfirmationModalComponent } from "./usercontrols/confirmationModal.component";
import { DeviceDetailsComponent } from "./usercontrols/deviceDetails.component";
// User Controlls
import { DevicesNearbyComponent } from "./usercontrols/devicesNearby.component";
import { DriverDetailsComponent } from "./usercontrols/driverDetails.component";
import { ErrorComponent } from "./usercontrols/error.component";
import { FilterTogglerComponent } from "./usercontrols/filterToggler.component";
import { LangSelectorComponent } from "./usercontrols/lang-selector.component";
import { LoggerComponent } from "./usercontrols/logger.component";
import { SaveFooterComponent } from "./usercontrols/saveFooter.component";
import { SensorTypeDetailsComponent } from "./usercontrols/sensorTypeDetails.component";
import { TaggingComponent } from "./usercontrols/tagging.component";
import { UserDetailsComponent } from "./usercontrols/userDetails.component";
import { WaslAssetDetailsComponent } from "./usercontrols/waslAssetDetails.component";
import { WaslDriverDetailsComponent } from "./usercontrols/waslDriverDetails.component";
import { DeviceDetailsDriverComponent } from "./usercontrols/devicesDetailsDriver.component";
import { GeofenceDetailsComponent } from "./usercontrols/geofenceDetails.component";
import { AccountDetailsComponent } from "./usercontrols/accountDetails.component";
import { LeafletMapComponent } from "./usercontrols/leafletMap.component";
import { ReportWizardComponent } from "../reporting/reportWizard.component";

// Color picker
import { ColorPickerModule } from "ngx-color-picker";

// Leaflet
import { LeafletModule } from "@asymmetrik/ngx-leaflet";
import { GroupDetailsComponent } from "./usercontrols/groupDetails.component";
import { GroupOverviewComponent } from "./usercontrols/groupOverview.component";
import { DriverDevicesOverviewComponent } from "./usercontrols/driverDevices.component";
import { BatchProcessingComponent } from "./usercontrols/batch/batchProcessing.component";
import { BatchProcessingUsercontrolsModule } from "./usercontrols/batch/controls/usercontrols.module";
import { WaslAccountDetailsComponent } from "./usercontrols/waslAccountDetails.component";

import { NgxPaginationModule } from "ngx-pagination";
import { StringFilterPipe } from "app/common/stringFilter.pipe";
import { DevicesConnectedComponent } from "./usercontrols/devicesConnected.component";
import { NotificationsListViewComponent } from "./usercontrols/notifications.component";
import { ResellerDetailsComponent } from "./usercontrols/resellerDetails.component";
import { FuelDetailsComponent } from "./usercontrols/fuelDetails.component";
import { TriggerDetailsComponent } from "./usercontrols/triggerDetails.component";
import { MaintenanceDetailsComponent } from "./usercontrols/maintenanceDetails.component";
import { MaintenanceTypeDetailsComponent } from "./usercontrols/maintenanceTypeDetails.component";
import { MaintenanceServiceItemDetailsComponent } from "./usercontrols/serviceItemDetails.component";
import { MaintenanceWorkOrderDetailsComponent } from "./usercontrols/workOrderDetails.component";
import { CallbackPipe } from "app/common/callback.pipe";
import { FilterPipe } from "app/common/filter.pipe";
import { GroupByPipe } from "app/common/groupBy.pipe";
import { AssetDetailsExtendedComponent } from "./usercontrols/assetDetailsExtended.component";
import { DeviceSettingsDetailsComponent } from "./usercontrols/deviceSettingsDetails.component";
import { BatchDeviceSettingsComponent } from "./usercontrols/batch/controls/updateDeviceSettings.component";
import { TypeaheadModule } from "ngx-bootstrap/typeahead";
import { AssetChooserDetailsComponent } from "./usercontrols/assetChooser.component";
import { DriverChooserComponent } from "./usercontrols/driverChooser.component";
import { AssetTypeSelectorComponent } from "./usercontrols/assetTypeSelector.component";
import { AssetTypeDetailsComponent } from "./usercontrols/assetTypeDetails.component";
import { PermissionHandlerComponent } from "./usercontrols/permissionHandler.component";
import { AccountUserDetailsComponent } from "./usercontrols/accountUserDetails.component";
import { KeysPipe } from "app/common/keys.pipe";
import { UserContactDetailsComponent } from "./usercontrols/userContactDetails.component";
import { UserContactGroupDetailsComponent } from "./usercontrols/userContactGroupDetails.component";
import { UserContactSelectorComponent } from "./usercontrols/userContactSelector.component";
import { GeofenceGroupDetailsComponent } from "./usercontrols/geofenceGroupDetails.component";
import { DeviceSettingChangesComponent } from "./usercontrols/deviceSettingChanges.component";
import { BatchDeviceSettingChangesComponent } from "./usercontrols/batch/controls/applyDeviceSettingChanges.component";
import { BatchDeviceCustomCommandsComponent } from "./usercontrols/batch/controls/customCommands.component";
import { SingleTaskProcessingDropdownComponent } from "./usercontrols/batch/singleTaskProcessing.component";
import { FleetOverviewActionProcessingComponent } from "./usercontrols/batch/overviewTaskProcessing.component";
import { HandleTaskProcessingComponent } from "./usercontrols/batch/handleTaskProcessing.component";
import { NgSelectModule } from "@ng-select/ng-select";
import { NgOptionHighlightModule } from "@ng-select/ng-option-highlight";
import { UserChooserDetailsComponent } from "./usercontrols/userChooser.component";
import { ArchivingModalComponent } from "./usercontrols/archivingModal.component";
import { UserTableOverviewComponent } from "./usercontrols/userTableOverview.component";
import { TriggerOutputDetailsComponent } from "./usercontrols/triggerOutputDetails.component";
import { TriggerRuleDetailsComponent } from "./usercontrols/triggerRuleDetails.component";
import { AssignmentModalComponent } from "./usercontrols/assignmentModal.component";
import { ViewerOverviewComponent } from "./usercontrols/viewerOverview.component";
import { TrailerDetailsComponent } from "./usercontrols/trailerDetails.component";
import { TrailerChooserComponent } from "./usercontrols/trailerChooser.component";
import { TrailerDevicesOverviewComponent } from "./usercontrols/trailerDevices.component";
import { UserRoleDetailsComponent } from "./usercontrols/userRoleDetails.component";
import { UserRolePermissionContentComponent } from "./usercontrols/userRolePermissionContent.component";
import { SharingCreateViewerComponent } from "./usercontrols/sharingCreate.component";
import { WorkingHoursSelectorComponent } from "./usercontrols/workingHours.component";
import { NgxSliderModule } from "@angular-slider/ngx-slider";
import { SharingComponent } from "./usercontrols/sharing.component";
import { DriverGroupChooserComponent } from "./usercontrols/drivergroupChooser.component";
import { WarehouseDetailsComponent } from "./usercontrols/warehouseDetails.component";
import { WarehouseZoneDetailsComponent } from "./usercontrols/warehouseZoneDetails.component";
import { WarehouseSensorDetailsComponent } from "./usercontrols/warehouseSensorDetails.component";
import { ngxLoadingAnimationTypes } from "app/modules/loading/ngx-loading-config";
import { NgxLoadingModule } from "app/modules/loading/public_api";
import { CopyInputBoxModule } from "app/modules/copyInputBox/copyInputBox.module";
import { CustomInputModule } from "app/modules/customInputs/customInput.module";
import { ProjectChooserComponent } from "./usercontrols/projectChooser.component";
import { ProjectDetailsComponent } from "./usercontrols/projectDetails.component";
import { ProjectDevicesOverviewComponent } from "./usercontrols/projectDevices.component";
import { TriggerScheduleDetailsComponent } from "./usercontrols/triggerScheduleDetails.component";
import { ServiceAnnouncementDetailsComponent } from "./usercontrols/serviceAnnouncementDetails.component";
import { ClientAnnouncementDetailsComponent } from "./usercontrols/clientAnnouncementDetails.component";
import { SensorTemplateDetailsComponent } from "./usercontrols/sensorTemplateDetails.component";
import { CustomCommandDetailsComponent } from "./usercontrols/customCommandDetails.component";
import { BatchDeviceSensorsComponent } from "./usercontrols/batch/controls/updateDeviceSensors.component";
import { EpisodeDetailsComponent } from "./usercontrols/episodeDetails.component";
import { TriggerRulesViewComponent } from "./usercontrols/triggerRules.component";
import { WebhookEndpointDetailsComponent } from "./usercontrols/webhookEndpointDetails.component";
import { ColumnSelectorComponent } from "./usercontrols/columnSelector.component";
import { TriggerSourceViewComponent } from "./usercontrols/triggerSource.component";
import { RerunReportModalComponent } from "./usercontrols/rerunReportModal.component";
import { HighchartsChartModule } from "highcharts-angular";
import { ReportCategoryFilterPipe } from "app/common/reportCategoryFilter.pipe";
import { CarSharingGarageDetailsComponent } from "./usercontrols/carsharingGarageDetails.component";
import { IboxToolsComponent } from "./usercontrols/iboxtools/iboxtools.component";
import { IboxSearchComponent } from "./usercontrols/iboxtools/iboxsearch.component";
import { IboxChartExportComponent } from "./usercontrols/iboxtools/iboxexport.component";
import { BatchGroupAddComponent } from "./usercontrols/batch/controls/groupAdd.component";
import { ScheduleAssignmentsOverviewComponent } from "./usercontrols/scheduleAssignmentsOverview.component";
import { ScheduleAssignmentModalComponent } from "./usercontrols/scheduleAssignmentModal.component";
import { AssetGroupChooserComponent } from "./usercontrols/assetgroupChooser.component";
import { CustomFieldsComponent } from "./usercontrols/customFields.component";
import { HiddenFilterPipe } from "app/common/hiddenFilter.pipe";
import { NotificationSettingDetailsComponent } from "./usercontrols/notificationSettingDetails.component";
import { DashcamsAdminComponent } from "./usercontrols/dashcamsAdmin.component";
import { MaintenanceDetailsItemComponent } from "./usercontrols/maintenanceDetailsItem.component";
import { ShareLinkComponent } from "./usercontrols/shareLink.component";
import { ShareTokensComponent } from "../appviews/shareTokens.component";
import { TrimWhiteSpacePipe } from "app/common/trimWhiteSpace.pipe";
import { CronEditorModule } from "app/modules/cron-editor/cron-editor.module";
import { HierarchyGroupDetailsComponent } from "./usercontrols/hierarchyGroupDetails.component";
import { SafeHtmlPipe } from "app/common/sanitizeHtml.pipe";
import { StripHtmlPipe } from "app/common/stripHtml.pipe";

@NgModule({
  declarations: [
    FilterTogglerComponent,
    TaggingComponent,
    DevicesNearbyComponent,
    DeviceDetailsDriverComponent,
    AddressDetailsComponent,
    GroupOverviewComponent,
    ViewerOverviewComponent,
    UserTableOverviewComponent,
    AccountDetailsComponent,
    ResellerDetailsComponent,
    FuelDetailsComponent,
    CalibrationComponent,
    MaintenanceDetailsComponent,
    MaintenanceDetailsItemComponent,
    NotificationSettingDetailsComponent,
    MaintenanceTypeDetailsComponent,
    MaintenanceServiceItemDetailsComponent,
    MaintenanceWorkOrderDetailsComponent,
    AssetTypeDetailsComponent,
    AssetTypeSelectorComponent,
    UserContactSelectorComponent,
    AssetDetailsComponent,
    AssetDetailsExtendedComponent,
    AssetChooserDetailsComponent,
    UserChooserDetailsComponent,
    DriverChooserComponent,
    AssetGroupChooserComponent,
    SharingComponent,
    ShareTokensComponent,
    ShareLinkComponent,
    DriverGroupChooserComponent,
    SharingCreateViewerComponent,
    BatchProcessingComponent,
    WaslAssetDetailsComponent,
    WaslAccountDetailsComponent,
    WaslDriverDetailsComponent,
    GroupDetailsComponent,
    HierarchyGroupDetailsComponent,
    GeofenceGroupDetailsComponent,
    DriverDevicesOverviewComponent,
    TrailerDevicesOverviewComponent,
    ProjectDevicesOverviewComponent,
    ReportWizardComponent,
    TrimWhiteSpacePipe,
    TrailerChooserComponent,
    ProjectChooserComponent,
    ArchivingModalComponent,
    AssignmentModalComponent,
    ConfirmationModalComponent,
    DriverDetailsComponent,
    TrailerDetailsComponent,
    ProjectDetailsComponent,
    WarehouseDetailsComponent,
    WarehouseZoneDetailsComponent,
    WarehouseSensorDetailsComponent,
    GeofenceDetailsComponent,
    UserDetailsComponent,
    AccountUserDetailsComponent,
    DeviceDetailsComponent,
    CustomFieldsComponent,
    DashcamsAdminComponent,
    SaveFooterComponent,
    ErrorComponent,
    SingleTaskProcessingDropdownComponent,
    FleetOverviewActionProcessingComponent,
    HandleTaskProcessingComponent,
    LoggerComponent,
    NotificationsListViewComponent,
    LangSelectorComponent,
    SensorTypeDetailsComponent,
    CalibrationComponent,
    LeafletMapComponent,
    NumberOnlyDirective,
    FuelThresholdEditComponent,
    DevicesConnectedComponent,
    StringFilterPipe,
    HiddenFilterPipe,
    ReportCategoryFilterPipe,
    CallbackPipe,
    GroupByPipe,
    SafeHtmlPipe,
    StripHtmlPipe,
    FilterPipe,
    KeysPipe,
    DeviceSettingsDetailsComponent,
    DeviceSettingChangesComponent,
    BatchDeviceSettingsComponent,
    BatchDeviceSensorsComponent,
    BatchDeviceSettingChangesComponent,
    BatchDeviceCustomCommandsComponent,
    PermissionHandlerComponent,
    TriggerDetailsComponent,
    TriggerOutputDetailsComponent,
    TriggerRulesViewComponent,
    TriggerRuleDetailsComponent,
    TriggerSourceViewComponent,
    TriggerScheduleDetailsComponent,
    SensorTemplateDetailsComponent,
    CustomCommandDetailsComponent,
    UserContactDetailsComponent,
    UserContactGroupDetailsComponent,
    UserRoleDetailsComponent,
    UserRolePermissionContentComponent,
    WorkingHoursSelectorComponent,
    ServiceAnnouncementDetailsComponent,
    ClientAnnouncementDetailsComponent,
    EpisodeDetailsComponent,
    WebhookEndpointDetailsComponent,
    ColumnSelectorComponent,
    RerunReportModalComponent,
    CarSharingGarageDetailsComponent,
    IboxToolsComponent,
    IboxSearchComponent,
    IboxChartExportComponent,
    BatchGroupAddComponent,
    ScheduleAssignmentsOverviewComponent,
    ScheduleAssignmentModalComponent,
  ],
  imports: [
    CustomInputModule,
    NgSelectModule,
    NgOptionHighlightModule,
    TagInputModule,
    BrowserAnimationsModule,
    FormsModule,
    MomentModule,
    NgxSliderModule,
    HighchartsChartModule,
    BatchProcessingUsercontrolsModule,
    ReactiveFormsModule,
    BrowserModule,
    RouterModule,
    TabsModule.forRoot(),
    DataTablesModule,
    DataTables360Module,
    PrettyJsonModule,
    TimezoneSelectorModule,
    TooltipModule.forRoot(),
    BsDropdownModule.forRoot(),
    BsDatepickerModule.forRoot(),
    TimepickerModule.forRoot(),
    NgxPaginationModule,
    ModalModule.forRoot(),
    CopyInputBoxModule,
    WizardModule,
    CronEditorModule,
    NgxLoadingModule.forRoot({
      animationType: ngxLoadingAnimationTypes.rectangleBounce,
      backdropBackgroundColour: "rgba(255,255,255,0.7)",
      backdropBorderRadius: "4px",
      primaryColour: "#DC8139",
      secondaryColour: "#DC8139",
      tertiaryColour: "#DC8139",
    }),
    ColorPickerModule,
    TranslateModule,
    LeafletModule,
    TypeaheadModule.forRoot(),
  ],
  exports: [
    FilterTogglerComponent,
    TaggingComponent,
    AddressDetailsComponent,
    GroupOverviewComponent,
    ViewerOverviewComponent,
    UserTableOverviewComponent,
    AssetDetailsComponent,
    AssetDetailsExtendedComponent,
    AssetChooserDetailsComponent,
    DriverChooserComponent,
    AssetGroupChooserComponent,
    SharingComponent,
    ShareTokensComponent,
    ShareLinkComponent,
    DriverGroupChooserComponent,
    SharingCreateViewerComponent,
    UserChooserDetailsComponent,
    UserDetailsComponent,
    AccountUserDetailsComponent,
    AccountDetailsComponent,
    ResellerDetailsComponent,
    FuelDetailsComponent,
    CalibrationComponent,
    MaintenanceDetailsComponent,
    MaintenanceDetailsItemComponent,
    NotificationSettingDetailsComponent,
    MaintenanceTypeDetailsComponent,
    MaintenanceServiceItemDetailsComponent,
    MaintenanceWorkOrderDetailsComponent,
    AssetTypeDetailsComponent,
    AssetTypeSelectorComponent,
    UserContactSelectorComponent,
    BatchProcessingComponent,
    DriverDevicesOverviewComponent,
    TrailerDevicesOverviewComponent,
    ProjectDevicesOverviewComponent,
    GroupDetailsComponent,
    HierarchyGroupDetailsComponent,
    GeofenceGroupDetailsComponent,
    ArchivingModalComponent,
    AssignmentModalComponent,
    ConfirmationModalComponent,
    ErrorComponent,
    SingleTaskProcessingDropdownComponent,
    FleetOverviewActionProcessingComponent,
    HandleTaskProcessingComponent,
    LoggerComponent,
    NotificationsListViewComponent,
    WaslAssetDetailsComponent,
    WaslDriverDetailsComponent,
    DriverDetailsComponent,
    TrailerDetailsComponent,
    ProjectDetailsComponent,
    ReportWizardComponent,
    WarehouseDetailsComponent,
    WarehouseZoneDetailsComponent,
    WarehouseSensorDetailsComponent,
    TrailerChooserComponent,
    ProjectChooserComponent,
    GeofenceDetailsComponent,
    DeviceDetailsComponent,
    CustomFieldsComponent,
    DashcamsAdminComponent,
    DeviceDetailsDriverComponent,
    SaveFooterComponent,
    DevicesNearbyComponent,
    LangSelectorComponent,
    CalibrationComponent,
    SensorTypeDetailsComponent,
    LeafletMapComponent,
    NumberOnlyDirective,
    WaslAccountDetailsComponent,
    DevicesConnectedComponent,
    FuelThresholdEditComponent,
    StringFilterPipe,
    HiddenFilterPipe,
    ReportCategoryFilterPipe,
    CallbackPipe,
    FilterPipe,
    GroupByPipe,
    SafeHtmlPipe,
    StripHtmlPipe,
    KeysPipe,
    DeviceSettingsDetailsComponent,
    DeviceSettingChangesComponent,
    BatchDeviceSettingsComponent,
    BatchDeviceSensorsComponent,
    BatchDeviceSettingChangesComponent,
    BatchDeviceCustomCommandsComponent,
    PermissionHandlerComponent,
    TriggerDetailsComponent,
    TriggerOutputDetailsComponent,
    TriggerRuleDetailsComponent,
    TriggerScheduleDetailsComponent,
    SensorTemplateDetailsComponent,
    CustomCommandDetailsComponent,
    UserContactDetailsComponent,
    UserContactGroupDetailsComponent,
    UserRoleDetailsComponent,
    UserRolePermissionContentComponent,
    WorkingHoursSelectorComponent,
    ServiceAnnouncementDetailsComponent,
    ClientAnnouncementDetailsComponent,
    EpisodeDetailsComponent,
    TriggerRulesViewComponent,
    TriggerSourceViewComponent,
    WebhookEndpointDetailsComponent,
    ColumnSelectorComponent,
    RerunReportModalComponent,
    CarSharingGarageDetailsComponent,
    IboxToolsComponent,
    IboxSearchComponent,
    IboxChartExportComponent,
    BatchGroupAddComponent,
    ScheduleAssignmentsOverviewComponent,
    ScheduleAssignmentModalComponent,
  ],
})
export class UsercontrolsModule {}
