<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12 flex header-content">
        <nav class="breadcrumb-container" aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a href="/#/"> <i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
                </li>
                <li class="breadcrumb-item">
                    <a href="/#/Dashboard">{{'general.dashboard' | translate }}</a>
                </li>
                <li class="breadcrumb-item active">{{'general.fuel' | translate }}</li>
            </ol>
        </nav>
    </div>
    <fh-dashboard-navigation> </fh-dashboard-navigation>
</div>

<div class="wrapper wrapper-content">
    <div class="row" data-equalizer="">
        <div class="col-lg-12">
            <fh-error [error]="error" [success]="success" [warning]="warning"></fh-error>

            <!-- Custom filters -->
            <div class="ibox" [hidden]="!showFilter">
                <div class="ibox-title">
                    <h5><i class="fas fa-fw fa-filter"></i> {{'general.filter' | translate }}</h5>
                </div>
                <div class="ibox-content slick_toppadding flex-container" style="position: relative">
                    <ngx-loading [show]="loading"></ngx-loading>
                    <div class="col-lg-4">
                        <div class="form-group input-group" style="margin-bottom: 6px; display: block">
                            <span style="width: 42px; padding: 10px; float: left" class="input-group-prepend">
                                <i class="fa fa-fw fa-calendar"></i>
                            </span>
                            <div style="width: calc(100% - 42px); float: left">
                                <fh-reseller-input
                                    name="reseller"
                                    #resellerVar="ngModel"
                                    (onChanged)="resellerChanged($event)"
                                    required
                                    placeholder="{{ 'general.chooseReseller' | translate}}"
                                    [(ngModel)]="selectedResellerId"
                                >
                                </fh-reseller-input>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="form-group" style="margin-bottom: 6px">
                            <fh-account-input
                                name="account"
                                #accountVar="ngModel"
                                (onChanged)="accountChanged($event)"
                                required
                                [selectedResellerId]="selectedResellerId"
                                placeholder="{{ (selectedResellerId ? 'general.chooseAccount' : 'general.firstChooseReseller') | translate}}"
                                [(ngModel)]="selectedAccountId"
                            >
                            </fh-account-input>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="form-group" style="margin-bottom: 6px">
                            <fh-asset-group-input
                                name="assetGroups"
                                #assetGroupVar="ngModel"
                                required
                                [selectedAccountId]="selectedAccountId"
                                [tableInline]="false"
                                (onChanged)="dateChanged(true)"
                                [multiple]="false"
                                [autoSelect]="false"
                                placeholder="{{ (selectedAccountId? 'general.chooseAssetGroups' : 'general.firstChooseAccount') | translate}}"
                                [(ngModel)]="selectedAssetGroup"
                            >
                            </fh-asset-group-input>
                        </div>
                    </div>
                    <div style="clear: both"></div>
                    <div class="col-lg-3">
                        <div class="form-group" style="margin-bottom: 6px">
                            <input
                                placeholder="Daterangepicker"
                                style="width: 100%"
                                placeholder="Datepicker"
                                type="text"
                                [bsConfig]="dpConfig"
                                class="form-control"
                                bsDaterangepicker
                                (onHidden)="dateChanged('hidden')"
                                [(ngModel)]="daterangepickerModel"
                            />
                            <span class="input-group-calendar-datatables">
                                <i class="fa fa-calendar-alt"></i>
                            </span>
                            <div style="clear: both"></div>
                        </div>
                    </div>
                    <div class="col-lg-3">
                        <div class="form-group input-group" style="margin-bottom: 6px">
                            <span class="input-group-prepend">
                                <i class="fa fa-fw fa-calculator"></i>
                            </span>
                            <ng-select
                                class="form-control"
                                name="utilizationCalculationFactor"
                                (change)="dateChanged('hidden')"
                                [clearable]="false"
                                placeholder="{{ 'general.utilizationCalculationFactor' | translate }}"
                                class="form-control"
                                [(ngModel)]="utilizationCalculationFactor"
                                style="width: 100%"
                            >
                                <ng-option [value]="1">
                                    {{ 'enums.utilizationCalculationFactor.1' | translate}}
                                </ng-option>
                                <ng-option [value]="2">
                                    {{ 'enums.utilizationCalculationFactor.2' | translate}}
                                </ng-option>
                                <ng-option [value]="3">
                                    {{ 'enums.utilizationCalculationFactor.3' | translate}}
                                </ng-option>
                            </ng-select>
                        </div>
                    </div>
                    <div class="col-lg-3">
                        <div class="form-group input-group" style="margin-bottom: 6px">
                            <span class="input-group-prepend">
                                <i class="fa fa-fw fa-router"></i>
                            </span>
                            <ng-select
                                class="form-control"
                                name="deviceTypes"
                                (change)="dateChanged(true)"
                                class="form-control"
                                placeholder="{{ 'general.chooseDeviceType' | translate }}  ({{ deviceTypeOptions.length }})"
                                [(ngModel)]="selectedDeviceType"
                                style="width: 100%"
                            >
                                <ng-option *ngFor="let deviceType of deviceTypeOptions" [value]="deviceType.id">
                                    {{ deviceType.value }}</ng-option
                                >
                            </ng-select>
                        </div>
                    </div>
                    <div class="col-lg-3">
                        <div class="form-group input-group" style="margin-bottom: 6px" title="Filter on 0 values">
                            <span id="checkBox11" class="input-group-prepend hand" title="Filter on 0 values">
                                <i
                                    id="checkBox_11"
                                    class="auto_checkbox fa fa-fw"
                                    (click)="filterZeroValues = !filterZeroValues; dateChanged('hidden')"
                                    [ngClass]="filterZeroValues ? 'fa-check' : 'fa-times'"
                                    title="Filter on 0 values"
                                    [style.color]="filterZeroValues ? 'rgba(0, 142, 67, 0.8)' : 'rgba(151, 28, 36, 0.8)'"
                                ></i>
                            </span>
                            <input
                                name="checkboxName_11"
                                id="checkboxName_11"
                                style="width: 100%; cursor: pointer !important"
                                placeholder="{{'general.filterZeroValues' | translate }}"
                                disabled="disabled"
                                class="form-control auto_search"
                            />
                            <div style="clear: both"></div>
                        </div>
                    </div>
                    <div style="clear: both"></div>
                </div>
                <div class="ibox-content" style="position: relative; display: none">
                    <ngx-loading [show]="loading"></ngx-loading>
                    <div id="dynamicFilters" class="flex-container"></div>
                    <div style="clear: both"></div>
                </div>
            </div>
            <!-- End default dynamic filters -->
        </div>
    </div>

    <div class="row" style="position: relative" *ngIf="devices?.length > 0">
        <ngx-loading [show]="loading"></ngx-loading>
        <div class="col-6 col-md-4 col-lg-2">
            <div class="ibox hand">
                <div class="kpi-item" style="position: relative" [routerLink]="['/Devices/Overview/']">
                    <h3 class="margin-bottom font-bold">
                        <i class="fas fa-fw fa-car"></i>
                        {{kpis?.assetCountWithTrips?.toLocaleString()}} / {{kpis?.assetCount?.toLocaleString()}}
                    </h3>
                    <div>{{'general.activeAssets' | translate }}</div>
                </div>
            </div>
        </div>

        <div class="col-6 col-md-4 col-lg-2">
            <div class="ibox hand">
                <div class="kpi-item" style="position: relative">
                    <h3 class="margin-bottom font-bold">
                        <i class="fa fa-fw fa-route"></i>
                        {{actualRound(kpis?.totalDistance,0)?.toLocaleString()}} km
                    </h3>
                    <div>{{'general.distance' | translate }}</div>
                </div>
            </div>
        </div>
        <div class="col-6 col-md-4 col-lg-2">
            <div class="ibox hand">
                <div class="kpi-item" style="position: relative">
                    <h3 class="margin-bottom font-bold">
                        <i class="fas fa-fw fa-route"></i> {{actualRound(kpis?.totalDistance /
                        kpis?.assetCount,0)?.toLocaleString()}} km
                    </h3>
                    <div>{{'general.avgDistance' | translate }}</div>
                </div>
            </div>
        </div>
        <div class="col-6 col-md-4 col-lg-2">
            <div class="ibox hand">
                <div class="kpi-item" style="position: relative">
                    <h3 class="margin-bottom font-bold">
                        <i class="fas fa-fw fa-clock"></i>
                        {{actualRoundMinutes(kpis?.totalDuration / 60)}}
                    </h3>
                    <div>{{'general.duration' | translate }}</div>
                </div>
            </div>
        </div>
        <div class="col-6 col-md-4 col-lg-2">
            <div class="ibox hand">
                <div class="kpi-item" style="position: relative">
                    <h3 class="margin-bottom font-bold">
                        <i class="fa fa-fw fa-snooze"></i>
                        {{actualRoundMinutes(kpis?.totalIdling / 60)}}
                    </h3>
                    <div>{{'general.idling' | translate }}</div>
                </div>
            </div>
        </div>
        <div class="col-6 col-md-4 col-lg-2">
            <div class="ibox hand">
                <div class="kpi-item" style="position: relative">
                    <h3 class="margin-bottom font-bold">
                        <i class="fas fa-fw fa-gas-pump"></i>
                        {{actualRound(kpis?.fuelUsedWhileDriving,0)?.toLocaleString()}} L
                    </h3>
                    <div>{{'general.fuelUsedWhileDriving' | translate }}</div>
                </div>
            </div>
        </div>
        <div class="col-lg-3 col-md-4 col-sm-6">
            <div class="ibox hand">
                <div class="kpi-item" style="position: relative">
                    <h3 class="margin-bottom font-bold">
                        <i class="fas fa-fw fa-gas-pump"></i>
                        {{actualRound(kpis?.fuelLostWhileIdling,0)?.toLocaleString()}} L
                    </h3>
                    <div>{{'general.fuelLostWhileIdling' | translate }}</div>
                </div>
            </div>
        </div>
        <div class="col-lg-3 col-md-4 col-sm-6">
            <div class="ibox hand">
                <div class="kpi-item" style="position: relative">
                    <h3 class="margin-bottom font-bold">
                        <i class="fas fa-fw fa-gas-pump"></i> {{actualRound(kpis?.fuelUsedTotal,0)?.toLocaleString()}} L
                    </h3>
                    <div>{{'general.fuelUsedTotal' | translate }}</div>
                </div>
            </div>
        </div>
        <div class="col-lg-3 col-md-4 col-sm-6">
            <div class="ibox hand">
                <div class="kpi-item" style="position: relative">
                    <h3 class="margin-bottom font-bold">
                        <i class="fas fa-fw fa-gas-pump"></i> {{actualRound((kpis?.fuelLostWhileIdling /
                        kpis?.fuelUsedTotal) * 100,1)?.toLocaleString()}} %
                    </h3>
                    <div>{{'general.fuelLostPercentage' | translate }}</div>
                </div>
            </div>
        </div>
        <div class="col-lg-3 col-md-4 col-sm-6">
            <div class="ibox hand">
                <div class="kpi-item" style="position: relative">
                    <h3 class="margin-bottom font-bold">
                        <i class="fas fa-fw fa-gas-pump"></i> {{actualRound((kpis?.totalDistance /
                        kpis?.fuelUsedTotal),1)?.toLocaleString()}} km/L
                    </h3>
                    <div>{{'general.fuelKmPerLiter' | translate }}</div>
                </div>
            </div>
        </div>
    </div>

    <div class="row" *ngIf="devices?.length > 0">
        <div class="col-lg-6">
            <div class="ibox">
                <div class="ibox-title">
                    <h5><i class="fas fa-fw fa-engine"></i> {{'general.utilizationPerAsset' | translate }} %</h5>
                    <span class="float-right">
                        <ibox-chart-export [chartObject]="chartUtilization"> </ibox-chart-export>
                    </span>
                </div>
                <div class="ibox-content slicker" style="position: relative">
                    <ngx-loading [show]="loading"></ngx-loading>

                    <highcharts-chart
                        *ngIf="chartUtilization"
                        [runOutsideAngular]="true"
                        style="display: block; width: 100%; height: 150px"
                        [Highcharts]="Highcharts"
                        [options]="chartUtilization"
                    ></highcharts-chart>
                </div>
            </div>
        </div>
        <div class="col-lg-6">
            <div class="ibox">
                <div class="ibox-title">
                    <h5>
                        <i class="fas fa-fw fa-route"></i> {{'general.totalDistance' | translate }} ({{'general.km' |
                        translate }})
                    </h5>
                    <span class="float-right">
                        <ibox-chart-export [chartObject]="chartDistance"> </ibox-chart-export>
                    </span>
                </div>
                <div class="ibox-content slicker" style="position: relative">
                    <ngx-loading [show]="loadingCount"></ngx-loading>

                    <highcharts-chart
                        *ngIf="chartDistance"
                        [runOutsideAngular]="true"
                        style="display: block; width: 100%; height: 150px"
                        [Highcharts]="Highcharts"
                        [options]="chartDistance"
                    >
                    </highcharts-chart>
                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-lg-12">
            <div class="ibox">
                <div class="ibox-title">
                    <h5><i class="fas fa-fw fa-gas-pump"></i> {{ 'general.fuel' | translate }}</h5>
                    <iboxsearch
                        [searchTerm]="searchTerm"
                        (searchChanged)="searchChanged($event)"
                        [constructorName]="constructorName"
                        (filterChanged)="showFilter = $event"
                        (presetLoaded)="onPresetLoaded($event)"
                        (columnsChanged)="drawFilterRow()"
                    ></iboxsearch>
                </div>
                <div class="ibox-content slick" style="position: relative" *ngIf="languageLoaded">
                    <table
                        style="width: 100%; min-height: 50px"
                        fh-datatable
                        [dtOptions]="dtOptions"
                        [dtTrigger]="dtTrigger"
                        class="table nowrap margin-bottom-0 dataTable dtr-inline"
                    ></table>
                </div>
            </div>
        </div>
    </div>
</div>
