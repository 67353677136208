import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { FormMode } from "app/common/enums";
import { AccountInventory } from "app/models/account.model";
import { HierarchyGroup } from "app/models/hierarchyGroup.model";
import { HierarchieService } from "app/services/hierarchy/hierarchie.service";

@Component({
  selector: "fh-hierarchy-groups-add",
  templateUrl: "addHierarchyGroup.template.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HierarchyGroupsAddViewComponent implements OnInit, AfterViewInit {
  sub;

  loading = false;
  saving = false;

  error: any;
  success: any;

  formMode = FormMode.add;

  group: HierarchyGroup;
  account: AccountInventory;

  constructor(private cd: ChangeDetectorRef, private hierarchyService: HierarchieService, private router: Router) {
    this.account = null;

    this.group = new HierarchyGroup();
    this.cd.markForCheck();
  }

  ngOnInit() {}

  ngAfterViewInit() {
    this.cd.markForCheck();
  }

  onInsert() {
    this.saving = true;
    console.log("insert");
    this.hierarchyService.saveHierarchyGroupBy(this.group).subscribe({
      next: (result) => {
        this.error = null;
        this.success = {
          statusText: "Success",
          success: "Hierarchy group is successfully added.",
        };

        this.cd.markForCheck();
        setTimeout(() => {
          this.router.navigate(["/System/HierarchyGroupDetails/Index/" + result.id]);
        }, 2000);
      },
      error: (error) => {
        this.saving = false;
        this.success = null;
        this.error = error;
        this.cd.markForCheck();
      },
    });
  }
}
