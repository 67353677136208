import { ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import {
  getGridButtons,
  getGridLanguages,
  getDefaultDpConfig,
  setSelection,
  setSelectionRows,
  setTableStars,
} from "app/common/gridhelper";

import { AuthenticationService } from "../../services/authentication/authentication.service";

import { forkJoin } from "rxjs";
import { MaintenanceService } from "app/services/maintenance/maintenance.service";

import { BsDaterangepickerConfig } from "ngx-bootstrap/datepicker";

import { BatchStatus, EntityType } from "app/common/enums";
import { GridBase360Directive } from "app/common/360Grid.base";

// Moment
import Moment from "moment-timezone";
import { StorageHelper } from "app/common/storagehelper";
import { roundAsNumber } from "app/common/globals";
import { DistanceUnitService } from "app/common/distanceunit.service";
import { ColorService } from "app/services/common/color.service";

window["moment"] = Moment;

@Component({
  selector: "fh-maintenance",
  templateUrl: "maintenance.template.html",
})
export class MaintenanceViewComponent extends GridBase360Directive implements OnInit, OnDestroy {
  // Batch processing
  enableSelecting = true;

  entityTypes = EntityType.Maintenance;

  // Grid
  showActiveOnly = true;

  loading = false;
  languageLoaded: boolean;
  permissions: {};

  permissionName = "Maintenances_View";
  constructorName = "MaintenanceViewComponent";
  timezoneIana: string;

  error;
  success;

  // Daterange
  public dpConfig: Partial<BsDaterangepickerConfig> = new BsDaterangepickerConfig();
  daterangepickerModel: Date[];

  constructor(
    private translateService: TranslateService,
    private cd: ChangeDetectorRef,
    private distance: DistanceUnitService,
    private authenticationService: AuthenticationService,
    private maintenanceService: MaintenanceService,
    protected storageHelper: StorageHelper,
    private colorService: ColorService
  ) {
    super(storageHelper);

    this.timezoneIana = this.authenticationService.getTimeZoneIana();

    this.permissions = this.authenticationService.permissions;

    this.daterangepickerModel = [
      Moment().tz(this.timezoneIana).subtract(1, "months").startOf("day").toDate(),
      Moment().add(0, "days").endOf("day").toDate(),
    ];

    this.dpConfig = getDefaultDpConfig(authenticationService);

    // Get all the date for dropdown boxes
    forkJoin({ dateTranslation: this.translateService.get("general.date") }).subscribe({
      next: ({ dateTranslation }) => {
        this.languageLoaded = true;
        this.loading = false;

        this.initGrid();
      },
      error: (err) => console.error(err),
    });
  }

  // Fire custom filters to update grid and call server again
  fireFilter(event): void {
    if (event != null) {
      console.log("Fire update");
      this.datatableElement?.dtInstance.then((dtInstance: DataTables.Api) => {
        if (this.showActiveOnly === true) {
          dtInstance.column("isActive:name").search("true");
        } else {
          dtInstance.column("isActive:name").search("@ignore");
        }

        // Fire the request
        dtInstance.draw();
      });
    }
  }

  ngOnInit() {
    this.permissions = this.authenticationService.permissions;

    this.translateService.get("general.date").subscribe((value) => {
      this.languageLoaded = true;
      this.initGrid();
    });
  }

  // Check custom filters from grid save
  checkFilters() {
    this.datatableElement?.dtInstance.then((dtInstance: DataTables.Api) => {
      this.showActiveOnly = dtInstance.column("isActive:name").search() !== "@ignore";
    });

    this.fireFilter(1);
  }

  dateChanged(event) {
    if (event !== null) {
      this.datatableElement?.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.ajax
          .url(
            this.maintenanceService.getPagingUrl(
              Moment.utc(this.daterangepickerModel[0]).tz(this.timezoneIana).startOf("day"),
              Moment.utc(this.daterangepickerModel[1]).tz(this.timezoneIana).endOf("day")
            )
          )
          .load();
      });
    }
  }

  initGrid(): void {
    $.fn["dataTable"].ext.search.pop();

    this.loading = true;

    const excludedColumns = ["id", "id_export", "isSelected", "form"];
    let selectorVisible = true;

    if (!this.permissions["FleetManagement_Actions"]) {
      selectorVisible = false;
    }

    this.columns = [
      {
        name: "isSelected",
        data: "id",
        className: "noVis",
        title: '<div class="hideDropdown" id="selectorHeader"></div>',
        orderable: false,
        visible: selectorVisible,
        width: "20",
        render: (data, type, row) => {
          if (!this.processing.tableIdsSelection) {
            this.processing.tableIdsSelection = [];
          }
          if (this.processing.tableIdsSelection.indexOf(row.id.toString()) === -1) {
            this.processing.tableIdsSelection.push(row.id.toString());
          }

          if (!this.processing.tableIds) {
            this.processing.tableIds = [];
          }
          if (this.processing.tableIds.indexOf(row.id.toString()) === -1) {
            this.processing.tableIds.push(row.id.toString());
          }

          const isSelected = this.processing.gridSelection.indexOf(data.toString()) !== -1;
          return `
            <i id="selection_${row.id}" class="far fa-fw fa-lg ${isSelected ? "fa-check-square" : "fa-square"}" 
              style="cursor: pointer;">
            </i>`;
        },
      },
      {
        name: "id",
        data: "id",
        className: "noVis",
        orderable: false,
        title: '<div class="hideDropdown"></div>',
        width: "20",
        render: (data, type, row) => {
          return `
            <a class='btn btn-primary btn-grid' title='${this.translateService.instant(
              "general.details"
            )}' href='/#/MaintenanceDetails/Index/${data}'>
              <span class="d-none d-md-inline-flex" style="padding-left: 7px">${this.translateService.instant(
                "general.details"
              )}</span>
              <i class='fas fa-fw fa-angle-right'></i>
            </a>`;
        },
      },
      {
        name: "id_export",
        data: "id",
        className: "noVis",
        title: this.translateService.instant("general.id"),
        visible: false,
      },
      {
        name: "typeName",
        data: "typeName",
        defaultContent: "-",
        title: this.translateService.instant("general.typeName"),
      },
      {
        name: "occurrenceDate",
        data: "occurrenceDate",
        type: "date",
        render: (data, type, row) => {
          const date = Moment.utc(data)["tz"](this.timezoneIana);
          return data ? '<span title=" ' + date.toLocaleString() + '">' + date.format("lll") + "</span>" : "";
        },
        title: this.translateService.instant("general.occurrenceDate"),
      },
      {
        name: "assetName",
        data: "assetName",
        defaultContent: "-",
        title: this.translateService.instant("general.assetName"),
      },
      {
        name: "supplierName",
        data: "supplierName",
        defaultContent: "-",
        title: this.translateService.instant("general.supplierName"),
      },
      {
        name: "description",
        data: "description",
        defaultContent: "-",
        title: this.translateService.instant("general.description"),
        render: (data, type, row) => {
          const result = data ?? "-";
          return '<div class="truncate">' + result + "</div>";
        },
      },
      {
        name: "cost",
        data: "cost",
        defaultContent: "0",
        title: this.translateService.instant("general.cost"),
        render: (data, type, row) => {
          if (type && type === "display") {
            if (data) {
              const value = roundAsNumber(data as number, 2).toLocaleString();
              return value;
            }
            return "-";
          }
          return data;
        },
      },
      {
        name: "companyName",
        data: "companyName",
        defaultContent: "-",
        title: this.translateService.instant("general.companyName"),
      },
      {
        name: "accountId",
        data: "accountId",
        defaultContent: "-",
        title: this.translateService.instant("general.accountId"),
        visible: false,
      },
      {
        name: "localPurchaseOrderNumber",
        data: "localPurchaseOrderNumber",
        visible: false,
        defaultContent: "-",
        title: this.translateService.instant("general.localPurchaseOrderNumber"),
      },
      {
        name: "materialReceiptInspectionVoucherNumber",
        data: "materialReceiptInspectionVoucherNumber",
        visible: false,
        defaultContent: "-",
        title: this.translateService.instant("general.materialReceiptInspectionVoucherNumber"),
      },
      {
        name: "controlNumber",
        data: "controlNumber",
        visible: false,
        defaultContent: "-",
        title: this.translateService.instant("general.controlNumber"),
      },
      {
        name: "partNumber",
        data: "partNumber",
        visible: false,
        defaultContent: "-",
        title: this.translateService.instant("general.partNumber"),
      },
      {
        name: "quantity",
        data: "quantity",
        visible: false,
        defaultContent: "-",
        title: this.translateService.instant("general.quantity"),
      },
      {
        name: "unitPrice",
        data: "unitPrice",
        visible: false,
        defaultContent: "-",
        title: this.translateService.instant("general.unitPrice"),
      },
      {
        name: "discount",
        data: "discount",
        visible: false,
        defaultContent: "-",
        title: this.translateService.instant("general.discount"),
      },
      {
        name: "remarks",
        data: "remarks",
        visible: false,
        defaultContent: "-",
        title: this.translateService.instant("general.remarks"),
      },
      {
        name: "reference",
        data: "reference",
        visible: false,
        defaultContent: "-",
        title: this.translateService.instant("general.reference"),
      },
      {
        name: "calibratedOdoValueEnd",
        data: "calibratedOdoValueEnd",
        visible: false,
        defaultContent: "-",
        title: this.translateService.instant("general.odoValue"),
        render: (data, type, row) => {
          return data && data > 0.1
            ? roundAsNumber(data / 1000, 1).toLocaleString() +
                " " +
                this.translateService.instant(this.distance.getDistanceUnit())
            : "-";
        },
      },
      {
        name: "dateCalibration",
        data: "dateCalibration",
        visible: false,
        defaultContent: "-",
        title: this.translateService.instant("general.dateCalibration"),
        render: (data, type, row) => {
          const date = Moment.utc(data)["tz"](this.timezoneIana);
          return data ? '<span title=" ' + date.toLocaleString() + '">' + date.format("lll") + "</span>" : "-";
        },
      },
    ];

    this.dtOptions = {
      buttons: getGridButtons(
        this.commonExportOptions,
        "maintenance_overview",
        this.translateService.instant("menu.deviceoverview"),
        this.colorService
      ),
      pagingType: "simple_numbers",
      serverSide: true,
      processing: true,
      searchDelay: 500,
      deferRender: true,
      scrollX: true,
      colReorder: { fixedColumnsLeft: 1 },
      deferLoading: 0,
      stateSave: true,
      stateSaveCallback: (settings, data) => {
        this.saveState(this.constructorName, data);
      },
      stateLoadCallback: (_, callback) => {
        (async () => {
          try {
            const columnSettings = await this.loadState(this.constructorName);
            this.searchTerm = columnSettings && columnSettings.search && columnSettings.search.search;
            return columnSettings;
          } catch (e) {
            this.error = {};
            this.error.error = e;
            this.error.statusText = "Error fetching column settings";

            return null;
          }
        })().then((result) => {
          callback(result);
        });
      },
      order: [[2, "asc"]],
      ajax: {
        beforeSend: () => {
          this.drawFilterRow();

          $(".dataTables_info").html(this.translateService.instant("grid.loadingData"));
        },
        url: this.maintenanceService.getPagingUrl(
          Moment.utc(this.daterangepickerModel[0]).tz(this.timezoneIana).startOf("day"),
          Moment.utc(this.daterangepickerModel[1]).tz(this.timezoneIana).endOf("day")
        ),
        data: (d) => {
          return d;
        },
        dataSrc: (json) => {
          // if selection and returning keys
          if (this.processing.tableIdsSelection && this.processing.tableIdsSelection.length === 0) {
            this.processing.tableIdsSelection = json.keys;
          }
          return json.data;
        },
        type: "POST",
      },
      initComplete: (settings, json) => {
        // this.checkRoute();
        this.checkFilters();
        this.setEvents();
        this.drawFilterRow();
        $("#grid_select_or").appendTo($("th > #selectorHeader"));
        this.loading = false;
      },
      colVis: {
        restore: this.translateService.instant("general.restore"),
        showAll: this.translateService.instant("general.showAll"),
        showNone: this.translateService.instant("general.hideAll"),
        exclude: excludedColumns,
      },
      columns: this.columns,
      pageLength: 17,
      lengthMenu: [
        [10, 17, 25, 50, -1],
        [10, 17, 25, 50, this.translateService.instant("general.all")],
      ],
      language: getGridLanguages(this.translateService),
      drawCallback: (data) => {
        setTableStars(this.processing.tableIds);
        setSelection(this.processing.tableIds, this.processing.gridSelection);
      },
      rowCallback: (row, data) => {
        setSelectionRows(this, row, data, this.processing.gridSelection);
      },
    };
  }

  // batch processing
  onClose(event) {
    console.log("Closing batch popup");

    this.success = null;
    this.error = null;

    if (event && event.status === BatchStatus.Success) {
      console.log("success");

      this.success = {};
      this.success.statusText = "Success";
      this.success.success = "Your batch was accepted and scheduled to be proccesed";
      this.success.unique = Math.floor(Math.random() * (999999 - 100000)) + 100000;
      this.cd.markForCheck();
    }
    if (event && event.status === BatchStatus.Error) {
      this.error = {};
      this.error.statusText = "Error";
      this.error.error = "Error in processing the batch";
      this.error.unique = Math.floor(Math.random() * (999999 - 100000)) + 100000;
      this.cd.markForCheck();
    }
  }
}
