<div *ngIf="device" class="flex-container">
    <div
        class="col-6 col-lg-4"
        style="padding: 0"
        *ngIf="(device.settings.inputPorts[0] && device.settings.inputPorts[0].byte != null && device.settings.inputPorts[0].byte != 0) || showUnMapped"
    >
        <div
            title="Input 1: {{ deviceState?.input1?.state ?? 'Undefined' }}, Updated: {{ deviceState?.input1?.updateTimestamp ?? 'Undefined'}}"
            class="kpi-item-small"
        >
            <h3 class="margin-bottom font-bold">
                <i
                    class="full-icon fas fa-fw fa-arrow-alt-circle-up"
                    [ngStyle]="{'color': (deviceState?.input1 == null) ? '#ccc' : (deviceState?.input1?.state ? 'rgba(0,142,67,0.9)' : 'rgba(151,28,36,0.9)') }"
                ></i>
                {{ (deviceState?.input1 == null) ? 'Unknown' : (deviceState?.input1?.state ? 'On' : 'Off') }}
            </h3>
            <div>
                {{(device.settings.inputPorts[0]?.byte != null && device.settings.inputPorts[0]?.byte != "0" &&
                device.settings.inputPorts) ? ('enums.locationEventType.' + device.settings.inputPorts[0]?.byte |
                translate) : 'general.inputFormatted' | translate:{ value: '1' } }}
            </div>
        </div>
    </div>
    <div
        class="col-6 col-lg-4"
        style="padding: 0"
        *ngIf="(device.settings.inputPorts[1] && device.settings.inputPorts[1].byte != null && device.settings.inputPorts[1].byte != 0) || showUnMapped"
    >
        <div
            title="Input 2: {{ deviceState?.input2?.state ?? 'Undefined' }}, Updated: {{ deviceState?.input2?.updateTimestamp ?? 'Undefined'}}"
            class="kpi-item-small"
        >
            <h3 class="margin-bottom font-bold">
                <i
                    class="full-icon fas fa-fw fa-arrow-alt-circle-up"
                    [ngStyle]="{'color': (deviceState?.input2 == null) ? '#ccc' : (deviceState?.input2?.state ? 'rgba(0,142,67,0.9)' : 'rgba(151,28,36,0.9)') }"
                ></i>
                {{ (deviceState?.input2 == null) ? 'Unknown' : (deviceState?.input2?.state ? 'On' : 'Off') }}
            </h3>
            <div>
                {{(device.settings.inputPorts[1]?.byte != null && device.settings.inputPorts[1]?.byte != "0" &&
                device.settings.inputPorts) ? ('enums.locationEventType.' + device.settings.inputPorts[1]?.byte |
                translate) : 'general.inputFormatted' | translate:{ value: '2' } }}
            </div>
        </div>
    </div>
    <div
        class="col-6 col-lg-4"
        style="padding: 0"
        *ngIf="(device.settings.inputPorts[2] && device.settings.inputPorts[2].byte != null && device.settings.inputPorts[2].byte != 0) || showUnMapped"
    >
        <div
            title="Input 3: {{ deviceState?.input3?.state ?? 'Undefined' }}, Updated: {{ deviceState?.input3?.updateTimestamp ?? 'Undefined'}}"
            class="kpi-item-small"
        >
            <h3 class="margin-bottom font-bold">
                <i
                    class="full-icon fas fa-fw fa-arrow-alt-circle-up"
                    [ngStyle]="{'color': (deviceState?.input3 == null) ? '#ccc' : (deviceState?.input3?.state ? 'rgba(0,142,67,0.9)' : 'rgba(151,28,36,0.9)') }"
                ></i>
                {{ (deviceState?.input3 == null) ? 'Unknown' : (deviceState?.input3?.state ? 'On' : 'Off') }}
            </h3>
            <div>
                {{(device.settings.inputPorts[2]?.byte != null && device.settings.inputPorts[2]?.byte != "0" &&
                device.settings.inputPorts) ? ('enums.locationEventType.' + device.settings.inputPorts[2]?.byte |
                translate) : 'general.inputFormatted' | translate:{ value: '3' } }}
            </div>
        </div>
    </div>
    <div
        class="col-6 col-lg-4"
        style="padding: 0"
        *ngIf="(device.settings.inputPorts[3] && device.settings.inputPorts[3].byte != null && device.settings.inputPorts[3].byte != 0) || showUnMapped"
    >
        <div
            title="Input 4: {{ deviceState?.input4?.state ?? 'Undefined' }}, Updated: {{ deviceState?.input4?.updateTimestamp ?? 'Undefined'}}"
            class="kpi-item-small"
        >
            <h3 class="margin-bottom font-bold">
                <i
                    class="full-icon fas fa-fw fa-arrow-alt-circle-up"
                    [ngStyle]="{'color': (deviceState?.input4 == null) ? '#ccc' : (deviceState?.input4?.state ? 'rgba(0,142,67,0.9)' : 'rgba(151,28,36,0.9)') }"
                ></i>
                {{ (deviceState?.input4 == null) ? 'Unknown' : (deviceState?.input4?.state ? 'On' : 'Off') }}
            </h3>
            <div>
                {{(device.settings.inputPorts[3]?.byte != null && device.settings.inputPorts[3]?.byte != "0" &&
                device.settings.inputPorts) ? ('enums.locationEventType.' + device.settings.inputPorts[3]?.byte |
                translate) : 'general.inputFormatted' | translate:{ value: '4' } }}
            </div>
        </div>
    </div>
    <div
        class="col-6 col-lg-4"
        style="padding: 0"
        *ngIf="(device.settings.inputPorts[4] && device.settings.inputPorts[4].byte != null && device.settings.inputPorts[4].byte != 0) || showUnMapped"
    >
        <div
            title="Input 5: {{ deviceState?.input5?.state ?? 'Undefined' }}, Updated: {{ deviceState?.input5?.updateTimestamp ?? 'Undefined'}}"
            class="kpi-item-small"
        >
            <h3 class="margin-bottom font-bold">
                <i
                    class="full-icon fas fa-fw fa-arrow-alt-circle-up"
                    [ngStyle]="{'color': (deviceState?.input5 == null) ? '#ccc' : (deviceState?.input5?.state ? 'rgba(0,142,67,0.9)' : 'rgba(151,28,36,0.9)') }"
                ></i>
                {{ (deviceState?.input5 == null) ? 'Unknown' : (deviceState?.input5?.state ? 'On' : 'Off') }}
            </h3>
            <div>
                {{(device.settings.inputPorts[4]?.byte != null && device.settings.inputPorts[4]?.byte != "0" &&
                device.settings.inputPorts) ? ('enums.locationEventType.' + device.settings.inputPorts[4]?.byte |
                translate) : 'general.inputFormatted' | translate:{ value: '5' } }}
            </div>
        </div>
    </div>
    <div
        class="col-6 col-lg-4"
        style="padding: 0"
        *ngIf="(device.settings.inputPorts[5] && device.settings.inputPorts[5].byte != null && device.settings.inputPorts[5].byte != 0) || showUnMapped"
    >
        <div
            title="Input 6: {{ deviceState?.input6?.state ?? 'Undefined' }}, Updated: {{ deviceState?.input6?.updateTimestamp ?? 'Undefined'}}"
            class="kpi-item-small"
        >
            <h3 class="margin-bottom font-bold">
                <i
                    class="full-icon fas fa-fw fa-arrow-alt-circle-up"
                    [ngStyle]="{'color': (deviceState?.input6 == null) ? '#ccc' : (deviceState?.input6?.state ? 'rgba(0,142,67,0.9)' : 'rgba(151,28,36,0.9)') }"
                ></i>
                {{ (deviceState?.input6 == null) ? 'Unknown' : (deviceState?.input6?.state ? 'On' : 'Off') }}
            </h3>
            <div>
                {{(device.settings.inputPorts[5]?.byte != null && device.settings.inputPorts[5]?.byte != "0" &&
                device.settings.inputPorts) ? ('enums.locationEventType.' + device.settings.inputPorts[5]?.byte |
                translate) : 'general.inputFormatted' | translate:{ value: '6' } }}
            </div>
        </div>
    </div>
    <div
        class="col-6 col-lg-4"
        style="padding: 0"
        *ngIf="(device.settings.ignition && device.settings.ignition != null && device.settings.ignition != 0) && showUnMapped && !device.asset?.isAssetTracker"
    >
        <div
            title="Ignition: {{ deviceState?.ignition?.state ?? 'Undefined' }}, Updated: {{ deviceState?.ignition?.updateTimestamp ?? 'Undefined'}}"
            class="kpi-item-small"
        >
            <h3 class="margin-bottom font-bold">
                <i
                    class="full-icon fas fa-fw fa-arrow-alt-circle-up"
                    [ngStyle]="{'color': (deviceState == null || deviceState?.ignition == null) ? '#ccc' : (deviceState?.ignition?.state ? 'rgba(0,142,67,0.9)' : 'rgba(151,28,36,0.9)') }"
                ></i>
                {{ (deviceState?.ignition == null) ? 'Unknown' : (deviceState?.ignition?.state ? 'On' : 'Off') }}
            </h3>
            <div>
                {{device.settings.ignition && 'enums.locationEventType.' + device.settings.ignition | translate }}
            </div>
        </div>
    </div>
    <div
        class="col-6 col-lg-4"
        style="padding: 0"
        *ngIf="(device.settings.externalPower && device.settings.externalPower != null && device.settings.externalPower != 0) && showUnMapped && !device.asset?.isAssetTracker"
    >
        <div
            title="External Power: {{ deviceState?.externalPower?.state ?? 'Undefined' }}, Updated: {{ deviceState?.externalPower?.updateTimestamp ?? 'Undefined'}}"
            class="kpi-item-small"
        >
            <h3 class="margin-bottom font-bold">
                <i
                    class="full-icon fas fa-fw fa-arrow-alt-circle-up"
                    [ngStyle]="{'color': (deviceState?.externalPower == null) ? '#ccc' : (deviceState?.externalPower?.state ? 'rgba(0,142,67,0.9)' : 'rgba(151,28,36,0.9)') }"
                ></i>

                {{ (deviceState?.externalPower == null) ? 'Unknown' : (deviceState?.externalPower?.state ? 'On' : 'Off')
                }}
            </h3>
            <div>
                {{device.settings.externalPower && 'enums.locationEventType.' + device.settings.externalPower |
                translate }}
            </div>
        </div>
    </div>
    <div
        class="col-6 col-lg-4"
        style="padding: 0"
        *ngIf="(device.settings.outputPorts[0] && device.settings.outputPorts[0].byte != null && device.settings.outputPorts[0].byte != 0) || showUnMapped"
    >
        <div
            title="Output 1: {{ deviceState?.output1?.state ?? 'Undefined' }}, Updated: {{ deviceState?.output1?.updateTimestamp ?? 'Undefined'}}"
            class="kpi-item-small"
        >
            <h3 class="margin-bottom font-bold">
                <i
                    class="full-icon fas fa-fw fa-arrow-alt-circle-down"
                    [ngStyle]="{'color': (deviceState?.output1 == null) ? '#ccc' : (deviceState?.output1?.state ? 'rgba(0,142,67,0.9)' : 'rgba(151,28,36,0.9)') }"
                ></i>

                {{ (deviceState?.output1 == null) ? 'Unknown' : (deviceState?.output1?.state ? 'On' : 'Off') }}
            </h3>
            <div>
                {{(device.settings.outputPorts[0]?.byte != null && device.settings.outputPorts[0]?.byte != "0" &&
                device.settings.outputPorts) ? ('enums.deviceOutput.' + device.settings.outputPorts[0]?.byte |
                translate) : 'general.outputFormatted' | translate:{ value: '1' } }}
            </div>
        </div>
    </div>
    <div
        class="col-6 col-lg-4"
        style="padding: 0"
        *ngIf="(device.settings.outputPorts[1] && device.settings.outputPorts[1].byte != null && device.settings.outputPorts[1].byte != 0) || showUnMapped"
    >
        <div
            title="Output 2: {{ deviceState?.input2?.state ?? 'Undefined' }}, Updated: {{ deviceState?.output2?.updateTimestamp ?? 'Undefined'}}"
            class="kpi-item-small"
        >
            <h3 class="margin-bottom font-bold">
                <i
                    class="full-icon fas fa-fw fa-arrow-alt-circle-down"
                    [ngStyle]="{'color': (deviceState?.output2 == null) ? '#ccc' : (deviceState?.output2?.state ? 'rgba(0,142,67,0.9)' : 'rgba(151,28,36,0.9)') }"
                ></i>
                {{ (deviceState?.output2 == null) ? 'Unknown' : (deviceState?.output2?.state ? 'On' : 'Off') }}
            </h3>
            <div>
                {{(device.settings.outputPorts[1]?.byte != null && device.settings.outputPorts[1]?.byte != "0" &&
                device.settings.outputPorts) ? ('enums.deviceOutput.' + device.settings.outputPorts[1]?.byte |
                translate) : 'general.outputFormatted' | translate:{ value: '2' } }}
            </div>
        </div>
    </div>
    <div
        class="col-6 col-lg-4"
        style="padding: 0"
        *ngIf="(device.settings.outputPorts[2] && device.settings.outputPorts[2].byte != null && device.settings.outputPorts[2].byte != 0)  || showUnMapped"
    >
        <div
            title="Output 3: {{ deviceState?.input3?.state ?? 'Undefined' }}, Updated: {{ deviceState?.output3?.updateTimestamp ?? 'Undefined'}}"
            class="kpi-item-small"
        >
            <h3 class="margin-bottom font-bold">
                <i
                    class="full-icon fas fa-fw fa-arrow-alt-circle-down"
                    [ngStyle]="{'color': (deviceState?.output3 == null) ? '#ccc' : (deviceState?.output3?.state ? 'rgba(0,142,67,0.9)' : 'rgba(151,28,36,0.9)') }"
                ></i>
                {{ (deviceState?.output3 == null) ? 'Unknown' : (deviceState?.output3?.state ? 'On' : 'Off') }}
            </h3>
            <div>
                {{(device.settings.outputPorts[2]?.byte != null && device.settings.outputPorts[2]?.byte != "0" &&
                device.settings.outputPorts) ? ('enums.deviceOutput.' + device.settings.outputPorts[2]?.byte |
                translate) : 'general.outputFormatted' | translate:{ value: '3' } }}
            </div>
        </div>
    </div>
    <div
        class="col-6 col-lg-4"
        style="padding: 0"
        *ngIf="(device.settings.outputPorts[3] && device.settings.outputPorts[3].byte != null && device.settings.outputPorts[3].byte != 0)  || showUnMapped"
    >
        <div
            title="Output 4: {{ deviceState?.input4?.state ?? 'Undefined' }}, Updated: {{ deviceState?.output4?.updateTimestamp ?? 'Undefined'}}"
            class="kpi-item-small"
        >
            <h3 class="margin-bottom font-bold">
                <i
                    class="full-icon fas fa-fw fa-arrow-alt-circle-down"
                    [ngStyle]="{'color': (deviceState?.output4 == null) ? '#ccc' : (deviceState?.output4?.state ? 'rgba(0,142,67,0.9)' : 'rgba(151,28,36,0.9)') }"
                ></i>
                {{ (deviceState?.output4 == null) ? 'Unknown' : (deviceState?.output4?.state ? 'On' : 'Off') }}
            </h3>
            <div>
                {{(device.settings.outputPorts[3]?.byte != null && device.settings.outputPorts[3]?.byte != "0" &&
                device.settings.outputPorts) ? ('enums.deviceOutput.' + device.settings.outputPorts[3]?.byte |
                translate) : 'general.outputFormatted' | translate:{ value: '4' } }}
            </div>
        </div>
    </div>
    <div
        class="col-6 col-lg-4"
        style="padding: 0"
        *ngIf="(device.settings.analogPorts[0] && device.settings.analogPorts[0].byte != null && device.settings.analogPorts[0].byte != 0)  || showUnMapped"
    >
        <div
            title="Analog 1: {{ deviceState?.analog1?.value?.toLocaleString() ?? 'Undefined' }}, Updated: {{ deviceState?.analog1?.updateTimestamp ?? 'Undefined'}}"
            class="kpi-item-small"
        >
            <h3 class="margin-bottom font-bold">
                <i class="full-icon fas fa-fw fa-bolt"></i>
                {{deviceState?.analog1 ? deviceState?.analog1?.value?.toLocaleString() : '-' }} V
            </h3>
            <div>
                {{device.settings.analogPorts && 'enums.analogFunctions.' + device.settings.analogPorts[0]?.byte |
                translate }} (Analog 1)
            </div>
        </div>
    </div>
    <div
        class="col-6 col-lg-4"
        style="padding: 0"
        *ngIf="(device.settings.analogPorts[1] && device.settings.analogPorts[1].byte != null && device.settings.analogPorts[1].byte != 0)  || showUnMapped"
    >
        <div
            title="Analog 2: {{ deviceState?.analog2?.value?.toLocaleString() ?? 'Undefined' }}, Updated: {{ deviceState?.analog2?.updateTimestamp ?? 'Undefined'}}"
            class="kpi-item-small"
        >
            <h3 class="margin-bottom font-bold">
                <i class="full-icon fas fa-fw fa-bolt"></i>
                {{deviceState?.analog2 ? deviceState?.analog2?.value?.toLocaleString() : '-' }} V
            </h3>
            <div>
                {{device.settings.analogPorts && 'enums.analogFunctions.' + device.settings.analogPorts[1]?.byte |
                translate }} (Analog 2)
            </div>
        </div>
    </div>
    <div
        class="col-6 col-lg-4"
        style="padding: 0"
        *ngIf="(device.settings.analogPorts[2] && device.settings.analogPorts[2].byte != null && device.settings.analogPorts[2].byte != 0)  || showUnMapped"
    >
        <div
            title="Analog 3: {{ deviceState?.analog3?.value?.toLocaleString() ?? 'Undefined' }}, Updated: {{ deviceState?.analog3?.updateTimestamp ?? 'Undefined'}}"
            class="kpi-item-small"
        >
            <h3 class="margin-bottom font-bold">
                <i class="full-icon fas fa-fw fa-bolt"></i>
                {{ deviceState?.analog3 ? deviceState?.analog3?.value?.toLocaleString() : '-' }} V
            </h3>
            <div>
                {{device.settings.analogPorts && 'enums.analogFunctions.' + device.settings.analogPorts[2]?.byte |
                translate }} (Analog 3)
            </div>
        </div>
    </div>
    <div
        class="col-6 col-lg-4"
        style="padding: 0"
        *ngIf="(device.settings.analogPorts[3] && device.settings.analogPorts[3].byte != null && device.settings.analogPorts[3].byte != 0)  || showUnMapped"
    >
        <div
            title="Analog 4: {{ deviceState?.analog4?.value?.toLocaleString() ?? 'Undefined' }}, Updated: {{ deviceState?.analog4?.updateTimestamp ?? 'Undefined'}}"
            class="kpi-item-small"
        >
            <h3 class="margin-bottom font-bold">
                <i class="full-icon fas fa-fw fa-bolt"></i>
                {{deviceState?.analog4 ? deviceState?.analog4?.value?.toLocaleString() : '-' }} V
            </h3>
            <div>
                {{device.settings.analogPorts && 'enums.analogFunctions.' + device.settings.analogPorts[3]?.byte |
                translate }} (Analog 4)
            </div>
        </div>
    </div>
</div>
<div style="clear: both"></div>
