import { ChangeDetectorRef, Component, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { FormMode } from "app/common/enums";
import { WorkOrder } from "app/models/workOrder.model";
import { AuthenticationService } from "app/services/authentication/authentication.service";
import { MaintenanceV2Service } from "app/services/maintenancev2/maintenanceV2.service";
import { ConfirmationModalComponent } from "app/views/shared/usercontrols/confirmationModal.component";
import { MaintenanceWorkOrderDetailsComponent } from "app/views/shared/usercontrols/workOrderDetails.component";

@Component({
  selector: "fh-maintenance-add-work-order",
  templateUrl: "addWorkOrder.template.html",
})
export class MaintenanceWorkOrderAddViewComponent {
  @ViewChild(MaintenanceWorkOrderDetailsComponent, { static: true })
  workOrderDetails: MaintenanceWorkOrderDetailsComponent;
  @ViewChild("deleteModal")
  deleteModal: ConfirmationModalComponent;

  formMode = FormMode.add;

  permissionName = "Maintenances_Add";
  permissions: {};

  workOrder: WorkOrder = new WorkOrder();

  constructor(
    private cd: ChangeDetectorRef,
    private authenticationService: AuthenticationService,
    private maintenanceService: MaintenanceV2Service,
    private router: Router
  ) {
    this.workOrder.workOrderItems = [];
    this.permissions = this.authenticationService.permissions;
  }

  showDeleteModal(itemId: number) {
    this.deleteModal.showModal(itemId);
  }

  deleteWorkOrderItem() {
    const item = this.workOrder.workOrderItems.find((x) => x.id === this.deleteModal.activeId);
    const index: number = this.workOrder.workOrderItems.indexOf(item);
    if (index !== -1) {
      this.workOrder.workOrderItems.splice(index, 1);
    }
    this.deleteModal.hideModal();
  }

  onInsert(workOrder: WorkOrder) {
    this.workOrderDetails.loading = true;

    this.maintenanceService.createWorkOrder(workOrder).subscribe({
      next: (result) => {
        this.workOrderDetails.success = {
          statusText: "Success",
          success: "Work order is successfully added",
        };

        this.workOrderDetails.loading = false;
        this.cd.markForCheck();

        setTimeout(() => {
          this.router.navigate(["/MaintenancesV2/WorkOrders/Overview/"]);
        }, 3000);
      },
      error: (error) => {
        this.workOrderDetails, (error = error);
        this.workOrderDetails.loading = false;
        this.cd.markForCheck();
      },
    });
  }
}
