import { ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import {
  getGridButtons,
  getGridLanguages,
  setTableStars,
  setSelection,
  setSelectionRows,
  createdCellCheckbox,
} from "app/common/gridhelper";

import { AuthenticationService } from "../../services/authentication/authentication.service";

import { forkJoin } from "rxjs";
import { GeofenceService } from "app/services/geofence/geofence.service";
import { EntityType, Form, BatchStatus } from "app/common/enums";

import { ActivatedRoute } from "@angular/router";
import { GridBase360Directive } from "app/common/360Grid.base";

// Moment timezone
import Moment from "moment-timezone";
import { StorageHelper } from "app/common/storagehelper";
import { roundAsNumber } from "app/common/globals";
import { ColorService } from "app/services/common/color.service";

window["moment"] = Moment;

@Component({
  selector: "fh-geofences",
  templateUrl: "geofences.template.html",
})
export class GeofencesViewComponent extends GridBase360Directive implements OnInit, OnDestroy {
  excludingColumns = ["id", "id_export", "isActive"];

  timezoneIana: string;

  loading = false;
  languageLoaded: boolean;
  permissions: {};

  permissionName = "Geofences_View";
  constructorName = "GeofencesViewComponent";

  error: any;
  success: any;

  enableSelecting = true;

  entityTypes = EntityType.Geofence;

  constructor(
    private route: ActivatedRoute,
    private translateService: TranslateService,
    private cd: ChangeDetectorRef,
    private authenticationService: AuthenticationService,
    private geofenceService: GeofenceService,
    protected storageHelper: StorageHelper,
    private colorService: ColorService
  ) {
    super(storageHelper);

    this.permissions = this.authenticationService.permissions;

    this.timezoneIana = this.authenticationService.getTimeZoneIana();

    // Get all the date for dropdown boxes
    forkJoin({ dateTranslation: this.translateService.get("general.date") }).subscribe({
      next: (data) => {
        this.languageLoaded = true;
        this.loading = false;

        this.initGrid();
      },
      error: (err) => console.error(err),
    });
  }

  ngOnInit() {
    this.permissions = this.authenticationService.permissions;

    this.translateService.get("general.date").subscribe((value) => {
      this.languageLoaded = true;
      this.initGrid();
    });
  }

  // Check route to apply filters
  checkRoute() {
    this.route.queryParams.subscribe((params) => {
      const companyName = params["companyName"];
      const resellerDescription = params["resellerDescription"];
      let requireDraw = false;

      this.datatableElement?.dtInstance.then((dtInstance: DataTables.Api) => {
        if (companyName) {
          dtInstance.column("resellerDescription:name").search("");
          dtInstance.column("companyName:name").search(companyName);
          dtInstance.column("name:name").search("");
          requireDraw = true;
        }
        if (resellerDescription) {
          dtInstance.column("resellerDescription:name").search(resellerDescription);
          dtInstance.column("companyName:name").search("");
          dtInstance.column("name:name").search("");
          requireDraw = true;
        }
        // Fire the request
        if (requireDraw) {
          dtInstance.draw();
        }
      });
    });
  }

  initGrid(): void {
    $.fn["dataTable"].ext.search.pop();

    this.loading = true;

    const excludedColumns = ["id", "id_export", "isSelected", "form"];
    let selectorVisible = true;

    if (!this.permissions["FleetManagement_Actions"]) {
      selectorVisible = false;
    }

    this.columns = [
      {
        name: "isSelected",
        data: "id",
        className: "noVis",
        title: '<div class="hideDropdown" id="selectorHeader"></div>',
        orderable: false,
        visible: selectorVisible,
        width: "20",
        render: (data, type, row) => {
          if (!this.processing.tableIdsSelection) {
            this.processing.tableIdsSelection = [];
          }
          if (this.processing.tableIdsSelection.indexOf(row.id.toString()) === -1) {
            this.processing.tableIdsSelection.push(row.id.toString());
          }

          if (!this.processing.tableIds) {
            this.processing.tableIds = [];
          }
          if (this.processing.tableIds.indexOf(row.id.toString()) === -1) {
            this.processing.tableIds.push(row.id.toString());
          }

          const isSelected = this.processing.gridSelection.indexOf(data.toString()) !== -1;
          return `
            <i id="selection_${row.id}" class="far fa-fw fa-lg ${isSelected ? "fa-check-square" : "fa-square"}" 
              style="cursor: pointer;">
            </i>`;
        },
      },
      {
        name: "id",
        data: "id",
        className: "noVis",
        orderable: false,
        title: '<div class="hideDropdown"></div>',
        width: "20",
        render: (data, type, row) => {
          return `
            <a class='btn btn-primary btn-grid' title='${this.translateService.instant(
              "general.details"
            )}' href='/#/GeofenceDetails/Index/${data}'>
              <span class="d-none d-md-inline-flex" style="padding-left: 7px">${this.translateService.instant(
                "general.details"
              )}</span>
              <i class='fas fa-fw fa-angle-right'></i>
            </a>`;
        },
      },
      {
        name: "id_export",
        data: "id",
        className: "noVis",
        title: this.translateService.instant("general.id"),
        visible: false,
      },
      {
        name: "name",
        data: "name",
        title: this.translateService.instant("general.name"),
      },
      {
        name: "form",
        data: "form",
        title: this.translateService.instant("general.form"),
        iconName: "fa fa-fw fa-draw-polygon",
        type: "select",
        options: [
          { id: "Point", value: "Point" },
          { id: "MultiPoint", value: "MultiPoint" },
          { id: "Polygon", value: "Polygon" },
          { id: "LineString", value: "LineString" },
          { id: "MultiLineString", value: "MultiLineString" },
        ],
        render: (data, type, row) => {
          if (type && type === "display") {
            let icon = "";

            switch (data) {
              case Form.Point:
              case Form.MultiPoint:
                icon = "fa-circle";
                break;
              case Form.LineString:
              case Form.MultiLineString:
                icon = "fa-slash";
                break;
              case Form.Polygon:
              default:
                icon = "fa-draw-polygon";
                break;
            }

            return "<i class='fa fa-fw " + icon + "' style=\"color: " + row.color + '"></i>';
          } else {
            return data;
          }
        },
      },
      {
        name: "timeStamp",
        data: "timestamp",
        type: "date",
        title: this.translateService.instant("general.timeStamp"),
        render: (data, type, row) => {
          if (type && type === "display") {
            const date = Moment.utc(data)["tz"](this.timezoneIana);
            return data ? '<span title=" ' + date.toLocaleString() + '">' + date.format("lll") + "</span>" : "";
          } else {
            return data;
          }
        },
        visible: true,
      },
      {
        name: "area",
        data: "area",
        defaultContent: "-",
        type: "num",
        title: this.translateService.instant("general.area"),
        render: (data, type, row) => {
          if (type && type === "display") {
            return data
              ? `<span title="${data.toLocaleString()}">${roundAsNumber(
                  data / (1000 * 1000),
                  3
                ).toLocaleString()} km²</span>`
              : "";
          } else {
            return data;
          }
        },
      },
      {
        name: "referenceId",
        data: "referenceId",
        defaultContent: "-",
        title: this.translateService.instant("general.referenceId"),
      },
      {
        name: "companyName",
        data: "companyName",
        defaultContent: "-",
        title: this.translateService.instant("general.companyName"),
      },
      {
        name: "accountId",
        data: "accountId",
        defaultContent: "-",
        title: this.translateService.instant("general.accountId"),
        visible: false,
      },
      {
        name: "resellerDescription",
        data: "resellerDescription",
        defaultContent: "-",
        title: this.translateService.instant("general.resellerDescription"),
        visible: false,
      },
      {
        name: "isPersonal",
        data: "isPersonal",
        type: "checkBox",
        title: this.translateService.instant("general.isPersonal"),
        visible: false,
        createdCell: createdCellCheckbox,
        width: "40",
      },
    ];

    this.commonExportOptions = {
      // orthogonal: 'export',
      // format: {
      //   body: function (data, row, column, node) {
      //     return data;
      //   }
      // },
      modifier: {
        page: "all",
        search: "none",
      },
      columns: ["id_export:name", "geoJson:name", "accountId:name", ":visible[tabindex]"],
    };

    this.dtOptions = {
      buttons: getGridButtons(
        this.commonExportOptions,
        "geofence_overview",
        this.translateService.instant("menu.geofenceoverview"),
        this.colorService
      ),
      pagingType: "simple_numbers",
      serverSide: true,
      processing: true,
      searchDelay: 500,
      deferRender: true,
      scrollX: true,
      colReorder: { fixedColumnsLeft: 2 },
      deferLoading: 0,
      stateSave: true,
      stateSaveCallback: (settings, data) => {
        this.saveState(this.constructorName, data);
      },
      stateLoadCallback: (_, callback) => {
        (async () => {
          try {
            const columnSettings = await this.loadState(this.constructorName);
            this.searchTerm = columnSettings && columnSettings.search && columnSettings.search.search;
            return columnSettings;
          } catch (e) {
            this.error = {};
            this.error.error = e;
            this.error.statusText = "Error fetching column settings";

            return null;
          }
        })().then((result) => {
          callback(result);
        });
      },
      order: [[5, "desc"]],
      ajax: {
        beforeSend: () => {
          this.drawFilterRow();

          $(".dataTables_info").html(this.translateService.instant("grid.loadingData"));
        },
        url: this.geofenceService.getPagingUrl(),
        data: (d) => {
          return d;
        },
        dataSrc: (json) => {
          // if selection and returning keys
          if (this.processing.tableIdsSelection && this.processing.tableIdsSelection.length === 0) {
            this.processing.tableIdsSelection = json.keys;
          }
          return json.data;
        },
        type: "POST",
      },
      initComplete: (settings, json) => {
        this.checkRoute();
        this.checkFilters();
        this.setEvents();
        this.drawFilterRow();
        $("#grid_select_or").appendTo($("th > #selectorHeader"));
        this.loading = false;
      },
      colVis: {
        restore: this.translateService.instant("general.restore"),
        showAll: this.translateService.instant("general.showAll"),
        showNone: this.translateService.instant("general.hideAll"),
        exclude: excludedColumns,
      },
      columns: this.columns,
      pageLength: 17,
      lengthMenu: [
        [10, 17, 25, 50, -1],
        [10, 17, 25, 50, this.translateService.instant("general.all")],
      ],
      language: getGridLanguages(this.translateService),
      drawCallback: (data) => {
        setTableStars(this.processing.tableIds);
        setSelection(this.processing.tableIds, this.processing.gridSelection);
      },
      rowCallback: (row, data) => {
        setSelectionRows(this, row, data, this.processing.gridSelection);
      },
    };
  }

  // batch processing
  onClose(event) {
    console.log("Closing batch popup");

    this.success = null;
    this.error = null;

    if (event && event.status === BatchStatus.Success) {
      console.log("success");

      this.success = {};
      this.success.statusText = "Success";
      this.success.success = "Your batch was accepted and scheduled to be proccesed";
      this.success.unique = Math.floor(Math.random() * (999999 - 100000)) + 100000;
      this.cd.markForCheck();
    }
    if (event && event.status === BatchStatus.Error) {
      this.error = {};
      this.error.statusText = "Error";
      this.error.error = "Error in processing the batch";
      this.error.unique = Math.floor(Math.random() * (999999 - 100000)) + 100000;
      this.cd.markForCheck();
    }
  }
}
