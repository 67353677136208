import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { FormMode } from "app/common/enums";
import { DriverService } from "app/services/driver/driver.service";
import { AuthenticationService } from "../../services/authentication/authentication.service";
import { DriverGroupsService } from "app/services/driver/driverGroups.service";
import { DriverGroup } from "app/models/group.model";

@Component({
  selector: "fh-drivergroup-add",
  templateUrl: "groupAdd.template.html",
})
export class DriverGroupAddViewComponent implements OnInit {
  loading = false;
  loadingGroups = false;
  driverGroup = new DriverGroup();

  formMode = FormMode.add;
  error: any;
  permissions: {};
  success: { statusText: string; success: string };

  // Multiselect
  format = {
    add: "Add",
    remove: "Remove",
    all: "All",
    none: "None",
    draggable: true,
  };

  display = "name";

  source = [];
  confirmed = [];
  result = [];

  loadingDrivers = false;

  permissionName = "DriverGroups_Add";

  constructor(
    private driverService: DriverService,
    private driverGroupService: DriverGroupsService,
    private authenticationService: AuthenticationService,
    private router: Router
  ) {
    this.permissions = this.authenticationService.permissions;
  }

  ngOnInit() {
    this.loadSelector();
  }

  accountChanged(accountId) {
    this.confirmed = [];
    if (accountId !== null) {
      this.loadSelector();
    } else {
      this.source = [];
    }
  }

  loadSelector() {
    this.loadingDrivers = true;

    if (this.driverGroup.accountId !== undefined) {
      this.driverService.getDriversByAccount(this.driverGroup.accountId).subscribe((result) => {
        this.source = result;
        this.loadingDrivers = false;
      });
    } else {
      this.loadingDrivers = false;
    }
  }

  onInsert() {
    this.loading = true;

    this.driverGroup.items = this.confirmed.map((driver) => +driver.id);
    this.driverGroupService.saveDriverGroup(this.driverGroup).subscribe({
      next: (result) => {
        this.success = {
          statusText: "Success",
          success: "You will be redirected to the drivergroup",
        };

        setTimeout(() => {
          this.router.navigate(["/DriverGroupDetails/Index/" + result.id]);
        }, 2000);

        this.loading = false;
      },
      error: (error) => {
        this.error = error;
        this.loading = false;
      },
    });
  }
}
