<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12 flex header-content">
        <nav class="breadcrumb-container" aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a href="/#/"> <i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
                </li>
                <li class="breadcrumb-item">
                    <a href="/#/System/">{{'general.system' | translate }}</a>
                </li>
                <li class="breadcrumb-item">
                    <a href="/#/System/HierarchyGroups/Dashboard">{{'general.hierarchyGroups' | translate }}</a>
                </li>
            </ol>
        </nav>
    </div>
    <fh-system-navigation></fh-system-navigation>
</div>

<div class="wrapper wrapper-content">
    <div class="row" data-equalizer="">
        <div class="col-lg-12">
            <fh-error [error]="error" [warning]="warning"></fh-error>

            <div class="ibox">
                <div class="ibox-title">
                    <h5><i class="fas fa-fw fa-filter"></i> {{'general.filter' | translate }}</h5>
                </div>
                <div class="ibox-content slick_toppadding flex-container" style="position: relative">
                    <ngx-loading [show]="loading"></ngx-loading>
                    <div class="col-lg-6">
                        <div class="form-group input-group" style="margin-bottom: 6px; display: block">
                            <fh-reseller-input
                                name="reseller"
                                #resellerVar="ngModel"
                                (onChanged)="resellerChanged($event)"
                                required
                                placeholder="{{ 'general.chooseReseller' | translate}}"
                                [(ngModel)]="selectedResellerId"
                            >
                            </fh-reseller-input>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="form-group input-group" style="margin-bottom: 6px; display: block">
                            <fh-account-input
                                name="account"
                                #accountVar="ngModel"
                                (onChanged)="accountChanged($event)"
                                required
                                [selectedResellerId]="selectedResellerId"
                                [showEmptyAccountsButton]="false"
                                placeholder="{{ (selectedResellerId ? 'general.chooseAccount' : 'general.firstChooseReseller') | translate}}"
                                [(ngModel)]="selectedAccountId"
                            >
                            </fh-account-input>
                        </div>
                    </div>
                </div>
            </div>

            <div class="ibox">
                <div class="ibox-title">
                    <h5><i class="fas fa-fw fa-folder-tree"></i> {{'general.hierarchyGroups' | translate }}</h5>
                </div>
                <div class="ibox-content" *ngIf="hierarchyGroups.length == 0">
                    <span>{{ "general.noDataFound" | translate }}</span>
                </div>
                <div class="ibox-content" *ngIf="hierarchyGroups.length > 0" style="margin-left: -15px">
                    <fh-hierarchy-display [groups]="hierarchyGroups" [parentId]=""></fh-hierarchy-display>
                </div>
            </div>
        </div>
    </div>
</div>
