<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12 flex header-content">
        <nav class="breadcrumb-container" aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a href="/#/"> <i class="fas fa-fw fa-home"></i> {{ "general.home" | translate }}</a>
                </li>
                <li class="breadcrumb-item">
                    <a href="/#/Dashboard">{{ "general.dashboard" | translate }}</a>
                </li>
                <li class="breadcrumb-item active">
                    {{ "general.issues" | translate }}
                </li>
            </ol>
        </nav>

        <fh-filter-toggler
            style="margin-left: auto"
            [constructorName]="constructorName"
            (filterChanged)="showFilter = $event"
            (presetsChanged)="showPresets = $event"
        >
            <a
                style="margin-left: 2px"
                class="btn btn-info margin-fix d-none d-md-inline-flex"
                title="Help"
                href="https://help.360locate.com/knowledge-base/fleet-management/issues/"
                target="_blank"
                ><i class="fa fa-fa fa-book" style="margin-right: 6px"></i>
                <span class="d-none d-md-inline-flex">{{ "general.needHelp" | translate }}</span></a
            >
        </fh-filter-toggler>
    </div>
    <fh-dashboard-navigation> </fh-dashboard-navigation>
</div>

<fh-permission-handler [permissionName]="permissionName">
    <div class="wrapper wrapper-content">
        <div class="row" *ngIf="false">
            <div class="col-lg-12">
                <div class="ibox float-e-margins">
                    <div class="ibox-title">
                        <h5>
                            <i class="fas fa-fw fa-sort-numeric-down"></i> {{ "general.violationsPerType" | translate }}
                        </h5>
                        <span class="float-right">
                            <ibox-chart-export [chartObject]="chartViolationsPerType"> </ibox-chart-export>
                        </span>
                    </div>
                    <div class="ibox-content slicker" style="position: relative">
                        <ngx-loading [show]="loading"></ngx-loading>

                        <highcharts-chart
                            [runOutsideAngular]="true"
                            style="display: block; width: 100%; height: 369px"
                            [Highcharts]="Highcharts"
                            [options]="chartViolationsPerType"
                        >
                        </highcharts-chart>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-12">
                <!-- Custom filters -->
                <div class="ibox" [hidden]="!showFilter">
                    <div class="ibox-title">
                        <h5><i class="fas fa-fw fa-filter"></i> {{ "general.filter" | translate }}</h5>
                        <span class="float-right hand btn btn-info filter-margin-fix" (click)="clearAllFilters()">
                            <i class="fa fa-fw fa-times"></i>
                            <span class="d-none d-md-inline-flex"> {{ "general.clearFilters" | translate }}</span>
                        </span>
                    </div>
                    <div class="ibox-content slick_toppadding" style="position: relative">
                        <div class="col-lg-12">
                            <div class="form-group input-group" style="margin-bottom: 5px">
                                <span class="input-group-prepend">
                                    <i class="fa fa-fw fa-calendar"></i>
                                </span>
                                <input
                                    placeholder="Daterangepicker"
                                    style="width: 100%"
                                    placeholder="Datepicker"
                                    type="text"
                                    [bsConfig]="dpConfig"
                                    class="form-control"
                                    bsDaterangepicker
                                    (onHidden)="dateChanged('hidden')"
                                    [(ngModel)]="daterangepickerModel"
                                />
                                <span class="input-group-calendar-datatables">
                                    <i class="fa fa-calendar-alt"></i>
                                </span>
                                <div style="clear: both"></div>
                            </div>
                        </div>
                    </div>
                    <div class="ibox-content slick_toppadding" style="position: relative">
                        <ngx-loading [show]="episodesLoaded"></ngx-loading>
                        <div id="dynamicFilters" class="flex-container"></div>
                        <div style="clear: both"></div>
                    </div>
                </div>
                <!-- End default dynamic filters -->

                <div class="ibox">
                    <div class="ibox-title">
                        <h5><i class="fas fa-fw fa-car-crash"></i> {{ "general.issues" | translate }}</h5>
                        <iboxsearch
                            [searchTerm]="searchTerm"
                            (searchChanged)="searchChanged($event)"
                            [constructorName]="constructorName"
                            (filterChanged)="showFilter = $event"
                            (presetLoaded)="onPresetLoaded($event)"
                            (columnsChanged)="drawFilterRow()"
                        ></iboxsearch>
                    </div>
                    <div class="ibox-content slick" style="position: relative" *ngIf="languageLoaded">
                        <table
                            style="width: 100%; min-height: 50px; padding-bottom: 0px"
                            fh-datatable
                            [dtTrigger]="dtTrigger"
                            [dtOptions]="dtOptions"
                            class="table nowrap dataTable dtr-inline"
                        ></table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</fh-permission-handler>
