import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  ViewChild,
  Input,
  OnChanges,
  ChangeDetectorRef,
} from "@angular/core";
import { WizardComponent } from "app/modules/wizard/wizard";
import { ReportService } from "app/services/reports/report.service";
import { TranslateService } from "@ngx-translate/core";
import { ReportSubscription } from "app/models/reporting.model";
import { ActivatedRoute } from "@angular/router";
import { FormMode } from "app/common/enums";

// Moment
import Moment from "moment-timezone";
import { ReportWizardComponent } from "./reportWizard.component";

window["moment"] = Moment;

@Component({
  selector: "fh-subscription-edit",
  templateUrl: "subscriptionEdit.template.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubscriptionEditViewComponent implements OnInit, OnChanges {
  @ViewChild(WizardComponent, { static: true }) wizard: WizardComponent;
  @ViewChild(ReportWizardComponent, { static: true }) reportWizard: ReportWizardComponent;
  @Input() Id;
  sub: any;

  formMode = FormMode.edit;

  sendOutputMessage = false;

  processing = false;

  success;
  error;

  // Report
  reportShown = false;
  reportData = {
    request: null,
    kpiList: [],
    charts: [],
    data: [],
  };

  reportSubscription;

  constructor(
    private cd: ChangeDetectorRef,
    private reportService: ReportService,
    private translate: TranslateService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.reportSubscription = new ReportSubscription();

    this.sub = this.route.params.subscribe((params) => {
      this.Id = params["id"];

      this.reportService.getReportSubscriptionById(this.Id).subscribe((res) => {
        this.reportSubscription = res;
      });
    });
  }

  prefillWizard = (reportSubscription) => {
    this.reportWizard.selectedHierarchy = [];
    this.reportWizard.selectedAssetGroups = [];
    this.reportWizard.selectedDriverGroups = [];
    this.reportWizard.selectedProjects = [];
    this.reportWizard.selectedGeofenceGroups = [];
    this.reportWizard.selectedUserContactGroups = [];

    //Set current hierarchhy
    if (reportSubscription?.selectedHierarchy?.length > 0) {
      reportSubscription.selectedHierarchy.forEach((id) => {
        this.reportWizard.selectedHierarchy?.push(id);
      });
    }

    //Set current assets
    if (reportSubscription?.assetSelection?.selectedAssetGroups?.length > 0) {
      reportSubscription.assetSelection.selectedAssetGroups.forEach((id) => {
        this.reportWizard.selectedAssetGroups?.push({ id });
      });
    }

    if (reportSubscription?.assetSelection?.selectedAssets?.length > 0) {
      reportSubscription?.assetSelection?.selectedAssets.forEach((id) => {
        this.reportWizard.selectedAssetGroups.push(id);
      });
    }

    //Set current projects
    this.reportWizard.selectedProjects = reportSubscription?.projectSelection?.selectedProjects;

    //Set current geofences
    if (reportSubscription?.geofenceSelection?.selectedGeofenceGroups?.length > 0) {
      reportSubscription?.geofenceSelection?.selectedGeofenceGroups?.forEach((id) => {
        this.reportWizard.selectedGeofenceGroups.push({ id });
      });
    }

    if (reportSubscription?.geofenceSelection.selectedGeofences.length > 0) {
      reportSubscription.geofenceSelection?.selectedGeofences.forEach((item) => {
        this.reportWizard.selectedGeofenceGroups.push(item);
      });
    }

    //Set current drivers
    if (reportSubscription?.driverSelection?.selectedDriverGroups.length > 0) {
      reportSubscription.driverSelection?.selectedDriverGroups.forEach((id) => {
        this.reportWizard.selectedDriverGroups.push({ id });
      });
    }

    if (reportSubscription?.driverSelection?.selectedDrivers.length > 0) {
      reportSubscription.driverSelection?.selectedDrivers.forEach((id) => {
        this.reportWizard.selectedDriverGroups.push(id);
      });
    }

    // Set current contacts
    if (reportSubscription?.notificationConfiguration?.userContactGroupRecipients.length > 0) {
      this.sendOutputMessage = true;
      reportSubscription.notificationConfiguration.userContactGroupRecipients.forEach((id) => {
        this.reportWizard.selectedUserContactGroups.push({ id });
      });
    }

    if (reportSubscription?.notificationConfiguration?.userContactRecipients?.length > 0) {
      this.sendOutputMessage = true;
      reportSubscription.notificationConfiguration.userContactRecipients.forEach((contact) => {
        this.reportWizard.selectedUserContactGroups.push(contact);
      });
    }

    this.cd.markForCheck();
  };

  ngOnChanges(): void {}

  onCheckOutFinish() {
    this.processing = true;

    this.reportService.updateReportSubscription(this.reportSubscription.id, this.reportSubscription).subscribe({
      next: (result) => {
        this.processing = false;

        this.reportShown = true;
        this.reportData = result;

        this.success = {
          statusText: "Success",
          success: this.translate.instant(result.message),
        };

        this.resetWizard();
      },
      error: (error) => {
        this.error = error;
        this.processing = false;
      },
    });
  }

  resetWizard() {
    const oldReport = { ...this.reportSubscription };

    this.reportShown = false;
    this.reportSubscription = new ReportSubscription();

    this.reportSubscription.selectedReportCategory = oldReport.selectedReportCategory;
    this.reportSubscription.selectedReportType = oldReport.selectedReportType;
    this.reportSubscription.accountId = oldReport.accountId;
    this.reportSubscription.name = oldReport.name;

    this.reportSubscription.parameterConfiguration = oldReport.parameterConfiguration;
    this.reportSubscription.template = oldReport.template;

    this.reportSubscription.selectedAssetGroups = oldReport.selectedAssetGroups;
    this.reportSubscription.selectedAssets = oldReport.selectedAssets;
    this.reportSubscription.selectedDriverGroups = oldReport.selectedDriverGroups;
    this.reportSubscription.selectedDrivers = oldReport.selectedDrivers;
    this.reportSubscription.selectedGeofenceGroups = oldReport.selectedGeofenceGroups;
    this.reportSubscription.selectedGeofences = oldReport.selectedGeofences;
    this.reportSubscription.selectedProjects = oldReport.selectedProjects;

    this.wizard.reset();
  }
}
