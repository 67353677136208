<div class="row" data-equalizer="">
    <div class="col-lg-12">
        <fh-error [error]="error" [success]="success"></fh-error>
    </div>
</div>

<div class="row" data-equalizer="">
    <div class="col-lg-6">
        <div class="ibox">
            <div class="ibox-title">
                <h5>
                    <i class="fas fa-fw fa-tower-broadcast"></i>
                    {{ "general.customCommand" | translate }}
                </h5>
            </div>
            <div class="ibox-content slick" style="position: relative">
                <ngx-loading [show]="loading"></ngx-loading>
                <table style="width: 100%" class="table nowrap dtr-inline no-footer">
                    <tbody *ngIf="customCommand">
                        <ng-container *ngIf="formMode == 1">
                            <tr [hidden]="!permissions['Resellers_View'] && customCommand.resellerId">
                                <td class="row_opening">
                                    {{ "general.resellerDescription" | translate }}
                                </td>
                                <td class="padding-readonly-correct row_content">
                                    <a
                                        class="secondary link_bolder"
                                        href="/#/AccountDetails/Index/{{ customCommand?.resellerId }}"
                                        >{{ customCommand?.resellerDescription }}</a
                                    >
                                </td>
                            </tr>
                            <tr [hidden]="!permissions['Accounts_View']">
                                <td class="row_opening">
                                    {{ "general.account" | translate }}
                                </td>
                                <td class="row_content padding-readonly-correct">
                                    <a
                                        class="secondary link_bolder"
                                        href="/#/AccountDetails/Index/{{ customCommand?.accountId }}"
                                        >{{ customCommand?.companyName }}</a
                                    >
                                </td>
                            </tr>
                        </ng-container>
                        <ng-container *ngIf="formMode != 1">
                            <tr [hidden]="!permissions['Resellers_View'] && customCommand.resellerId">
                                <td class="row_opening">
                                    {{ "general.resellerDescription" | translate }}
                                    <span class="error" *ngIf="resellerVar.invalid">*</span>
                                </td>
                                <td colspan="2" class="row_content" style="position: relative; overflow: visible">
                                    <fh-reseller-input
                                        name="reseller"
                                        #resellerVar="ngModel"
                                        (onChanged)="resellerChanged($event)"
                                        [tableInline]="true"
                                        [disable]="!permissions['Platform_IsReseller']"
                                        [autoSelect]="formMode == 2"
                                        [required]="!permissions['Platform_IsReseller']"
                                        placeholder="{{ 'general.chooseReseller' | translate }}"
                                        [(ngModel)]="customCommand.resellerId"
                                    >
                                    </fh-reseller-input>
                                </td>
                            </tr>
                            <tr [hidden]="!permissions['Accounts_View'] && customCommand.accountId">
                                <td class="row_opening">
                                    {{ "general.account" | translate }}
                                    <span class="error" *ngIf="accountVar.invalid">*</span>
                                </td>
                                <td colspan="2" class="row_content" style="position: relative; overflow: visible">
                                    <fh-account-input
                                        name="account"
                                        #accountVar="ngModel"
                                        (onChanged)="accountChanged($event)"
                                        [autoSelect]="formMode == 2"
                                        [required]="!permissions['Platform_IsReseller']"
                                        [disable]="!permissions['Platform_IsReseller']"
                                        [selectedResellerId]="customCommand.resellerId"
                                        [tableInline]="true"
                                        placeholder="{{
                                            (customCommand.resellerId
                                                ? 'general.chooseAccount'
                                                : 'general.firstChooseReseller'
                                            ) | translate
                                        }}"
                                        [(ngModel)]="customCommand.accountId"
                                    >
                                    </fh-account-input>
                                </td>
                            </tr>
                        </ng-container>
                        <!-- name -->
                        <tr>
                            <td class="row_opening">
                                {{ "general.name" | translate }}
                                <span class="error" *ngIf="name.invalid && formMode != 1">*</span>
                            </td>
                            <td colspan="2" class="row_content">
                                <input
                                    type="text"
                                    [readonly]="formMode == 1"
                                    name="name"
                                    class="form-control table-inline"
                                    placeholder="{{ 'placeholder.triggerName' | translate }}"
                                    [(ngModel)]="customCommand.name"
                                    required
                                    minlength="3"
                                    #name="ngModel"
                                />
                            </td>
                        </tr>
                        <tr *ngIf="permissions['Platform_IsReseller']">
                            <td class="row_opening">
                                {{ "general.resellerOnly" | translate }}
                                <span class="error" *ngIf="resellerOnly.invalid && formMode != 1">*</span>
                            </td>
                            <td colspan="2" class="row_content">
                                <div class="fa_checkbox">
                                    <input
                                        id="resellerOnly"
                                        name="resellerOnly"
                                        type="checkbox"
                                        [disabled]="formMode == 1"
                                        [(ngModel)]="customCommand.isResellerOnly"
                                        #resellerOnly="ngModel"
                                    />
                                    <label for="resellerOnly"> </label>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <fh-save-footer
                [valid]="!form.invalid && !invalidSettings && commands.length > 0"
                [allowCancelOnInsert]="false"
                [allowEdit]="permissions['Platform_IsReseller'] || customCommand?.userId == userId"
                [allowDelete]="permissions['Platform_IsReseller'] || customCommand?.userId == userId"
                (setFormMode)="setFormMode($event)"
                (onInsert)="onInsert()"
                (onSave)="onSave()"
                (onDelete)="onDelete()"
                [formMode]="formMode"
            >
                <span class="float-right">
                    <button
                        type="button"
                        class="btn btn-primary float-right"
                        [disabled]="formMode == 1"
                        (click)="addCustomCommandImplementation()"
                    >
                        <i class="fas fa-fw fa-plus"></i>
                    </button>
                </span>
            </fh-save-footer>
        </div>
    </div>
    <div class="col-lg-6">
        <div class="ibox" style="position: relative" *ngFor="let command of commands; let i = index">
            <div class="ibox-title">
                <h5>
                    <i class="fa fa-fw fa-wrench-simple"></i>
                    {{ "general.customCommandImplementation" | translate }}
                </h5>
                <button
                    type="button"
                    class="btn btn-danger float-right"
                    [disabled]="formMode == 1"
                    (click)="deleteCommand(command)"
                >
                    <i class="fa fa-fw fa-trash-alt"></i>
                </button>
            </div>
            <div class="ibox-content slick" style="position: relative">
                <ngx-loading [show]="loading"></ngx-loading>
                <table style="width: 100%" class="table table-fixed nowrap dtr-inline no-footer">
                    <tbody>
                        <tr>
                            <td class="row_opening">
                                {{ "general.trackerType" | translate }}
                                <span class="error" *ngIf="deviceType.invalid && formMode != 1">*</span>
                                <i
                                    *ngIf="!!duplicateInputs[formGroup?.get('deviceType_' + i)?.value]"
                                    [tooltip]="'general.duplicateDevice' | translate"
                                    style="line-height: 22px"
                                    class="fas fa-fw fa-exclamation-triangle float-right"
                                ></i>
                            </td>
                            <td colspan="2" class="row_content" style="position: relative; overflow: visible">
                                <ngx-loading [show]="loadingDeviceTypes"></ngx-loading>
                                <ng-select
                                    [name]="'deviceType_' + i"
                                    [clearable]="false"
                                    required
                                    (change)="checkDuplicate()"
                                    [disabled]="formMode == 1"
                                    placeholder="{{ 'general.chooseDeviceType' | translate }}"
                                    [(ngModel)]="command.deviceTypeId"
                                    #deviceType="ngModel"
                                    class="form-control table-inline"
                                    [ngStyle]="{
                                        border: !!duplicateInputs[formGroup?.get('deviceType_' + i)?.value]
                                            ? '1px solid red'
                                            : '0'
                                    }"
                                >
                                    <ng-option
                                        *ngFor="let deviceType of deviceTypes | orderBy : 'modelName' : false"
                                        [value]="deviceType.id"
                                        >{{ deviceType.modelName }} | {{ deviceType.description }}</ng-option
                                    >
                                </ng-select>
                            </td>
                        </tr>
                        <tr>
                            <td class="row_opening">
                                {{ "general.command" | translate }}
                                <span class="error" *ngIf="description.invalid && formMode != 1">*</span>
                            </td>
                            <td colspan="2" style="background-color: unset; border-bottom: none !important">
                                <textarea
                                    rows="4"
                                    [readonly]="formMode == 1"
                                    [name]="'description_' + i"
                                    #description="ngModel"
                                    required
                                    class="form-control noresize"
                                    placeholder="{{ 'placeholder.description' | translate }}"
                                    style="height: 100px; width: 100%; resize: none"
                                    [(ngModel)]="command.value"
                                >
                                </textarea>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
