import { ChangeDetectorRef, Component } from "@angular/core";
import { Router } from "@angular/router";
import { FormMode } from "app/common/enums";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { UserContactGroup, UserContactGroupItem } from "app/models/userContactGroup.model";
import { UserContactGroupService } from "app/services/users/userContactGroups.service";
import { UserContactService } from "app/services/users/userContacts.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "fh-user-contact-add",
  templateUrl: "add.template.html",
})
export class UserContactGroupAddViewComponent {
  loading = false;
  loadingUserContacts = false;

  userContactGroup: UserContactGroup = new UserContactGroup();

  formMode = FormMode.add;
  formModeAssetTypes: FormMode = FormMode.read;

  error: any;
  permissions: {};
  success: { statusText: string; success: string };

  userContacts = [];

  permissionName = "UserContactGroups_Add";

  constructor(
    private translateServe: TranslateService,
    private cd: ChangeDetectorRef,
    private authenticationService: AuthenticationService,
    private router: Router,
    private userContactGroupService: UserContactGroupService,
    private userContactService: UserContactService
  ) {
    this.permissions = this.authenticationService.permissions;
  }

  onInsert() {
    this.loading = true;
    this.addUserContactGroupItems();

    this.userContactGroupService.saveUserContactGroup(this.userContactGroup).subscribe({
      next: (result) => {
        this.success = {
          statusText: "Success",
          success: this.translateServe.instant("general.saveSuccessRedirect", { entity: "userContactGroup" }),
        };

        setTimeout(() => {
          this.router.navigate(["/Notifications/UserContactGroupDetails/Index/" + result.id]);
        }, 2000);

        this.loading = false;
      },
      error: (error) => {
        this.error = error;
        this.loading = false;
      },
    });
  }

  accountChanged(accountId) {
    console.log("Changed accountId");
    this.userContactGroup.accountId = accountId;
    this.getUserContacts(accountId);

    this.cd.markForCheck();
  }

  getUserContacts(accountId) {
    if (accountId > 0) {
      this.loadingUserContacts = true;
      this.cd.markForCheck();
      this.userContactService.getUserContacts(this.userContactGroup.accountId).subscribe((result) => {
        this.userContacts = result;
        this.loadingUserContacts = false;
        this.cd.markForCheck();
      });
    } else {
      this.userContacts = [];
      this.loadingUserContacts = false;
      this.cd.markForCheck();
    }
  }

  addUserContactGroupItems() {
    this.userContacts.forEach((userContact) => {
      let notificationMethod = 0;
      if (userContact.isEmailEnabled) {
        notificationMethod += 1;
      }
      if (userContact.isSmsEnabled) {
        notificationMethod += 2;
      }

      if (notificationMethod !== 0) {
        const userContactGroupItem = new UserContactGroupItem();
        userContactGroupItem.userContactId = userContact.id;
        userContactGroupItem.notificationMethod = notificationMethod;
        this.userContactGroup.items.push(userContactGroupItem);
      }
    });
  }
}
