import { Component, OnDestroy, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { getGridButtons, getGridLanguages, getDefaultDpConfig } from "app/common/gridhelper";

import { AuthenticationService } from "../../services/authentication/authentication.service";

import { forkJoin } from "rxjs";
import { GridBase360Directive } from "app/common/360Grid.base";

import { DeviceSettingChangeService } from "app/services/device/deviceSettingChange.service";
import { BsDaterangepickerConfig } from "ngx-bootstrap/datepicker";
import { StorageHelper } from "app/common/storagehelper";

// Moment timezone
import Moment from "moment-timezone";
import { ColorService } from "app/services/common/color.service";

window["moment"] = Moment;

@Component({
  selector: "fh-device-settings-changes-overview",
  templateUrl: "changesOverview.template.html",
})
export class DeviceSettingChangesViewComponent extends GridBase360Directive implements OnInit, OnDestroy {
  showActiveOnly = true;

  loading = false;
  languageLoaded: boolean;
  permissions: {};

  constructorName = "DeviceSettingsOverviewViewComponent";
  timezoneIana: string;

  permissionName = "Devices_Edit";

  error;

  // Daterange
  public dpConfig: Partial<BsDaterangepickerConfig> = new BsDaterangepickerConfig();
  daterangepickerModel: Date[];
  selectedAccountId: any;
  selectedResellerId: any;

  constructor(
    private translateService: TranslateService,
    private deviceSettingChangeService: DeviceSettingChangeService,
    private authenticationService: AuthenticationService,
    protected storageHelper: StorageHelper,
    private colorService: ColorService
  ) {
    super(storageHelper);

    this.timezoneIana = this.authenticationService.getTimeZoneIana();

    this.daterangepickerModel = [
      Moment().tz(this.timezoneIana).subtract(8, "days").startOf("day").toDate(),
      Moment().tz(this.timezoneIana).subtract(1, "days").startOf("day").toDate(),
    ];

    this.dpConfig = getDefaultDpConfig(authenticationService);

    // Get all the date for dropdown boxes
    forkJoin({ dateTranslation: this.translateService.get("general.date") }).subscribe({
      next: ({ dateTranslation }) => {
        this.languageLoaded = true;
        this.loading = false;

        this.initGrid();
      },
      error: (err) => console.error(err),
    });
  }

  dateChanged(event) {
    console.log("Changed date");
    if (event !== null) {
      this.error = null;

      this.datatableElement?.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.ajax.reload();
      });
    }
  }

  resellerChanged(resellerId) {
    this.selectedAccountId = null;

    this.selectedResellerId = resellerId;
  }

  accountChanged(accountId) {
    this.selectedAccountId = accountId;

    this.error = null;
    this.dateChanged(true);
  }

  // Fire custom filters to update grid and call server again
  fireFilter(event): void {
    if (event != null) {
      console.log("Fire update");
      this.datatableElement?.dtInstance.then((dtInstance: DataTables.Api) => {
        if (this.showActiveOnly === true) {
          dtInstance.column("isActive:name").search("true");
        } else {
          dtInstance.column("isActive:name").search("@ignore");
        }

        // Fire the request
        dtInstance.draw();
      });
    }
  }

  ngOnInit() {
    this.permissions = this.authenticationService.permissions;

    this.translateService.get("general.date").subscribe((value) => {
      this.languageLoaded = true;
      this.initGrid();
    });
  }

  // Check custom filters from grid save
  checkFilters() {
    this.datatableElement?.dtInstance.then((dtInstance: DataTables.Api) => {
      this.showActiveOnly = dtInstance.column("isActive:name").search() !== "@ignore";
    });

    this.fireFilter(1);
  }

  initGrid(): void {
    $.fn["dataTable"].ext.search.pop();

    this.loading = true;

    const deviceSettingChangeStatusOptions = [];
    for (let i = 0; i < 8; i++) {
      deviceSettingChangeStatusOptions.push({
        id: this.translateService.instant("enums.deviceSettingChangeStatus." + i),
        value: this.translateService.instant("enums.deviceSettingChangeStatus." + i),
      });
    }

    this.columns = [
      {
        name: "deviceId",
        data: "deviceId",
        orderable: false,
        title: '<div class="hideDropdown"></div>',
        className: "noVis",
        width: "20",
        render: (data, type, row) => {
          const generalDetails = this.translateService.instant("general.details");

          return `
          <a class='btn btn-primary btn-grid' title='${generalDetails}' href='/#/DeviceDetails/Index/${data}'>
            <span class="d-none d-md-inline-flex" style="padding-left: 7px">${generalDetails}</span>
            <i class='fas fa-fw fa-angle-right'></i>
          </a>`;
        },
      },
      {
        name: "id_export",
        data: "id",
        className: "noVis",
        title: this.translateService.instant("general.id"),
        visible: false,
      },
      {
        name: "timestamp",
        data: "timestamp",
        defaultContent: "-",
        title: this.translateService.instant("general.timestamp"),
        render: (data, type, row) => {
          if (data == null) {
            return "-";
          } else {
            const date = Moment.utc(data)["tz"](this.timezoneIana);
            return data ? '<span title=" ' + date.toLocaleString() + '">' + date.format("lll") + "</span>" : "";
          }
        },
      },
      {
        name: "assetName",
        data: "assetName",
        defaultContent: "-",
        title: this.translateService.instant("general.assetName"),
      },
      {
        name: "assetCode",
        data: "assetCode",
        defaultContent: "-",
        visible: false,
        title: this.translateService.instant("general.assetCode"),
      },
      {
        name: "plateNumber",
        data: "plateNumber",
        defaultContent: "-",
        visible: false,
        title: this.translateService.instant("general.plateNumber"),
      },
      {
        name: "settingId",
        data: "settingId",
        defaultContent: "-",
        title: this.translateService.instant("general.settingId"),
        render: (data, type, row) => {
          return this.translateService.instant("enums.deviceSettingChange." + data);
        },
      },
      {
        name: "status",
        data: "status",
        type: "select",
        iconName: "fas fa-fw fa-router",
        options: deviceSettingChangeStatusOptions.sort((a, b) => a.value.localeCompare(b.value)),
        title: this.translateService.instant("general.status"),
        render: (data, type, row) => {
          return this.translateService.instant("enums.deviceSettingChangeStatus." + data);
        },
      },
      {
        name: "changeSentDate",
        data: "changeSentDate",
        defaultContent: "-",
        title: this.translateService.instant("general.sent"),
        render: (data, type, row) => {
          if (data == null) {
            return '<i class="fa fa-times fa-fw " style="color: rgb(151, 28, 36);"></i>';
          } else {
            const date = Moment.utc(data)["tz"](this.timezoneIana);
            return data
              ? `<i class="fa fa-check fa-fw" style="color: rgb(0, 142, 67);" title="${date.toLocaleString()}"></i>`
              : "";
          }
        },
      },
      {
        name: "changeAppliedDate",
        data: "changeAppliedDate",
        defaultContent: "-",
        title: this.translateService.instant("general.applied"),
        render: (data, type, row) => {
          if (data == null) {
            return '<i class="fa fa-times fa-fw " style="color: rgb(151, 28, 36);"></i>';
          } else {
            const date = Moment.utc(data)["tz"](this.timezoneIana);
            return data
              ? `<i class="fa fa-check fa-fw" style="color: rgb(0, 142, 67);" title="${date.toLocaleString()}"></i>`
              : "";
          }
        },
      },
      {
        name: "retryCount",
        data: "retryCount",
        defaultContent: "-",
        title: this.translateService.instant("general.retryCount"),
      },
      {
        name: "value",
        data: "value",
        defaultContent: "-",
        title: this.translateService.instant("general.value"),
      },
      {
        name: "response",
        data: "response",
        defaultContent: "-",
        title: this.translateService.instant("general.response"),
      },
    ];

    this.dtOptions = {
      buttons: getGridButtons(
        this.commonExportOptions,
        "changes_overview",
        this.translateService.instant("menu.messagesoverview"),
        this.colorService
      ),
      pagingType: "simple_numbers",
      serverSide: false,
      processing: false,
      scrollX: true,
      colReorder: { fixedColumnsLeft: 1 },
      order: [[0, "desc"]],
      stateSave: false,
      ajax: (dataTablesParameters: any, callback) => {
        $(".dataTables_info").html(this.translateService.instant("grid.loadingData"));

        this.loading = true;
        this.deviceSettingChangeService
          .getDeviceSettingChanges(
            this.selectedAccountId,
            Moment.utc(this.daterangepickerModel[0]).tz(this.timezoneIana).startOf("day"),
            Moment.utc(this.daterangepickerModel[1]).tz(this.timezoneIana).endOf("day")
          )
          .subscribe({
            next: (resp) => {
              this.loading = false;
              callback({
                recordsTotal: resp.length,
                recordsFiltered: resp.length,
                data: resp,
              });
            },
            error: (error) => {
              this.error = error;
              this.error.statusText = "Error fetching data";
              this.loading = false;
            },
          });
      },
      initComplete: (settings, json) => {
        this.loading = false;

        console.log("init complete");
        this.checkFilters();
        this.drawFilterRow();
        this.loading = false;
      },
      colVis: {
        restore: this.translateService.instant("general.restore"),
        showAll: this.translateService.instant("general.showAll"),
        showNone: this.translateService.instant("general.hideAll"),
        exclude: ["id", "id_export", "deviceId"],
      },
      columns: this.columns,
      pageLength: 25,
      lengthMenu: [
        [10, 17, 25, 50, 200, -1],
        [10, 17, 25, 50, 200, this.translateService.instant("general.all")],
      ],
      language: getGridLanguages(this.translateService),
      rowCallback: (row, data) => {},
    };
  }
}
