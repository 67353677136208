<div class="row">
    <div class="col-lg-12">
        <fh-error [success]="success" [error]="error"></fh-error>
    </div>
</div>

<div class="row">
    <div class="col-md-6">
        <ngx-loading [show]="loading"></ngx-loading>
        <div class="ibox" style="position: relative">
            <div class="ibox-title">
                <h5><i class="fas fa-fw fa-screwdriver-wrench"></i> {{'general.maintenanceType' | translate}}</h5>
            </div>
            <div class="ibox-content slick" style="position: relative">
                <table style="width: 100%" class="table table-fixed nowrap dtr-inline no-footer">
                    <tbody *ngIf="!serviceItem">
                        <tr>
                            <td colspan="2">{{ 'general.noMaintenance' | translate}}</td>
                        </tr>
                    </tbody>
                    <tbody *ngIf="serviceItem">
                        <ng-container *ngIf="formMode != 2">
                            <tr [hidden]="!permissions['Resellers_View']">
                                <td class="row_opening">{{ 'general.resellerDescription' | translate}}</td>
                                <td class="padding-readonly-correct row_content">
                                    <a
                                        class="secondary link_bolder"
                                        href="/#/ResellerDetails/Index/{{serviceItem?.resellerId}}"
                                        >{{serviceItem?.resellerDescription }}</a
                                    >
                                </td>
                            </tr>
                            <tr [hidden]="!permissions['Accounts_View']">
                                <td class="row_opening">{{ 'general.account' | translate}}</td>
                                <td class="row_content padding-readonly-correct">
                                    <a
                                        class="secondary link_bolder"
                                        href="/#/AccountDetails/Index/{{serviceItem?.accountId}}"
                                        >{{serviceItem?.companyName }}</a
                                    >
                                </td>
                            </tr>
                        </ng-container>
                        <ng-container *ngIf="formMode == 2">
                            <tr [hidden]="!permissions['Resellers_View'] && selectedResellerId">
                                <td class="row_opening">
                                    {{ 'general.resellerDescription' | translate}}
                                    <span class="error" *ngIf="resellerVar.invalid">*</span>
                                </td>
                                <td class="row_content" style="position: relative; overflow: visible">
                                    <fh-reseller-input
                                        name="reseller"
                                        #resellerVar="ngModel"
                                        (onChanged)="resellerChanged($event)"
                                        required
                                        placeholder="{{ 'general.chooseReseller' | translate}}"
                                        [tableInline]="true"
                                        [(ngModel)]="selectedResellerId"
                                    >
                                    </fh-reseller-input>
                                </td>
                            </tr>
                            <tr [hidden]="!permissions['Accounts_View'] && serviceItem.accountId">
                                <td class="row_opening">
                                    {{ 'general.account' | translate}}
                                    <span class="error" *ngIf="accountVar.invalid">*</span>
                                </td>
                                <td class="row_content" style="position: relative; overflow: visible">
                                    <fh-account-input
                                        name="account"
                                        #accountVar="ngModel"
                                        (onChanged)="accountChanged($event)"
                                        required
                                        [selectedResellerId]="selectedResellerId"
                                        [tableInline]="true"
                                        placeholder="{{ (selectedResellerId ? 'general.chooseAccount' : 'general.firstChooseReseller') | translate}}"
                                        [(ngModel)]="serviceItem.accountId"
                                    >
                                    </fh-account-input>
                                </td>
                            </tr>
                        </ng-container>
                        <tr>
                            <td class="row_opening">
                                {{ 'general.name' | translate}}
                                <span class="error" *ngIf="name.invalid && formMode != 1">*</span>
                            </td>
                            <td class="row_content">
                                <input
                                    name="name"
                                    type="text"
                                    [readonly]="formMode == 1"
                                    class="form-control table-inline"
                                    placeholder="{{ 'placeholder.nameType' | translate}}"
                                    [(ngModel)]="serviceItem.name"
                                    required
                                    minlength="4"
                                    #name="ngModel"
                                />
                            </td>
                        </tr>
                        <tr>
                            <td class="row_opening">
                                {{ 'general.description' | translate}}
                                <span class="error" *ngIf="description.invalid && formMode != 1">*</span>
                            </td>
                            <td class="row_content">
                                <textarea
                                    [readonly]="formMode == 1"
                                    name="description"
                                    #description="ngModel"
                                    required
                                    class="form-control noresize"
                                    style="height: 100px; width: 100%; resize: none"
                                    [(ngModel)]="serviceItem.description"
                                >
                                </textarea>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <fh-save-footer
                [valid]="!form.invalid && hasSelectedTypes() && hasSelectedInterval() && hasValidThreshold()"
                (onInsert)="onInsert()"
                (onSave)="onSave()"
                [allowEdit]="permissions['MaintenanceTypes_Edit']"
                [allowDelete]="permissions['MaintenanceTypes_Delete']"
                [formMode]="formMode"
                (setFormMode)="setFormMode($event)"
                (onDelete)="onDelete()"
            >
            </fh-save-footer>
        </div>
    </div>
    <div class="col-md-6">
        <div class="ibox" style="position: relative">
            <div class="ibox-title">
                <h5><i class="fas fa-fw fa-clock"></i> {{'general.serviceInterval' | translate}}</h5>
            </div>
            <div class="ibox-content slick" style="position: relative">
                <table style="width: 100%" class="table table-fixed nowrap dtr-inline no-footer">
                    <tbody *ngIf="!serviceItem">
                        <tr>
                            <td colspan="2">{{ 'general.noMaintenance' | translate}}</td>
                        </tr>
                    </tbody>
                    <tbody *ngIf="serviceItem.serviceInterval">
                        <tr>
                            <td class="row_opening">
                                {{ 'general.dayBased' | translate }}
                                <span
                                    class="error"
                                    *ngIf="serviceItem.serviceInterval.dayBased && checkValidServiceInterval('day') && formMode != 1"
                                    >*</span
                                >
                                <i
                                    *ngIf="serviceItem.serviceInterval.daysDueSoon > serviceItem.serviceInterval.daysThreshold"
                                    [tooltip]="'general.serviceIntervalWarning' | translate"
                                    style="line-height: 22px"
                                    class="fas fa-fw fa-exclamation-triangle float-right"
                                ></i>
                            </td>
                            <td class="row_content">
                                <div class="fa_checkbox">
                                    <input
                                        id="dayBased"
                                        name="dayBased"
                                        type="checkbox"
                                        (click)="clearThresholds('day')"
                                        [disabled]="formMode == 1"
                                        [(ngModel)]="serviceItem.serviceInterval.dayBased"
                                        #dayBased="ngModel"
                                    />
                                    <label for="dayBased"> </label>
                                </div>
                            </td>
                            <td *ngIf="serviceItem.serviceInterval.dayBased" class="row_content">
                                <input
                                    name="daysThreshold"
                                    id="daysThreshold"
                                    type="number"
                                    [readonly]="formMode == 1"
                                    class="form-control table-inline"
                                    placeholder="{{ 'placeholder.maintenanceThreshold' | translate}}"
                                    [(ngModel)]="serviceItem.serviceInterval.daysThreshold"
                                    #daysThreshold="ngModel"
                                />
                            </td>
                            <td *ngIf="serviceItem.serviceInterval.dayBased" class="row_content">
                                <input
                                    name="daysDueSoon"
                                    id="daysDueSoon"
                                    type="number"
                                    [readonly]="formMode == 1"
                                    placeholder="{{ 'placeholder.dueSoonThreshold' | translate}}"
                                    class="form-control table-inline"
                                    [(ngModel)]="serviceItem.serviceInterval.daysDueSoon"
                                    #daysDueSoon="ngModel"
                                />
                            </td>
                        </tr>
                        <tr
                            *ngIf="formMode == 2 && serviceItem.serviceInterval.dayBased && serviceItem.serviceInterval.daysThreshold && serviceItem.serviceInterval.daysDueSoon && !(serviceItem.serviceInterval.daysDueSoon > serviceItem.serviceInterval.daysThreshold)"
                        >
                            <td style="opacity: 0.7">
                                The maintenance threshold will be
                                <i>{{ serviceItem.serviceInterval.daysThreshold }} days </i>and a warning will be
                                visible after <i>{{ serviceItem.serviceInterval.daysDueSoon }} days </i>have passed
                            </td>
                        </tr>
                        <tr>
                            <td class="row_opening">
                                {{ 'general.engineHourBased' | translate }}
                                <span
                                    class="error"
                                    *ngIf="serviceItem.serviceInterval.engineHourBased && checkValidServiceInterval('hour') && formMode != 1"
                                    >*</span
                                >
                                <i
                                    *ngIf="serviceItem.serviceInterval.hourDueSoon > serviceItem.serviceInterval.hourThreshold"
                                    [tooltip]="'general.serviceIntervalWarning' | translate"
                                    style="line-height: 22px"
                                    class="fas fa-fw fa-exclamation-triangle float-right"
                                ></i>
                            </td>
                            <td class="row_content">
                                <div class="fa_checkbox">
                                    <input
                                        id="engineHourBased"
                                        name="engineHourBased"
                                        type="checkbox"
                                        (click)="clearThresholds('hour')"
                                        [disabled]="formMode == 1"
                                        [(ngModel)]="serviceItem.serviceInterval.engineHourBased"
                                        #engineHourBased="ngModel"
                                    />
                                    <label for="engineHourBased"> </label>
                                </div>
                            </td>

                            <td *ngIf="serviceItem.serviceInterval.engineHourBased" class="row_content">
                                <input
                                    id="hoursThreshold"
                                    name="hoursThreshold"
                                    type="number"
                                    [readonly]="formMode == 1"
                                    placeholder="{{ 'placeholder.maintenanceThreshold' | translate}}"
                                    class="form-control table-inline"
                                    [(ngModel)]="serviceItem.serviceInterval.hourThreshold"
                                    #hoursThreshold="ngModel"
                                />
                            </td>
                            <td *ngIf="serviceItem.serviceInterval.engineHourBased" class="row_content">
                                <input
                                    id="hourDueSoon"
                                    name="hourDueSoon"
                                    type="number"
                                    [readonly]="formMode == 1"
                                    placeholder="{{ 'placeholder.dueSoonThreshold' | translate}}"
                                    class="form-control table-inline"
                                    [(ngModel)]="serviceItem.serviceInterval.hourDueSoon"
                                    #hourDueSoon="ngModel"
                                />
                            </td>
                        </tr>
                        <tr
                            *ngIf="formMode == 2 && serviceItem.serviceInterval.engineHourBased && !(serviceItem.serviceInterval.hourDueSoon > serviceItem.serviceInterval.hourThreshold) && serviceItem.serviceInterval.hourThreshold && serviceItem.serviceInterval.hourDueSoon"
                        >
                            <td style="opacity: 0.7">
                                The maintenance threshold will be
                                <i>{{ serviceItem.serviceInterval.hourThreshold }}hours </i>and a warning will be
                                visible after <i>{{ serviceItem.serviceInterval.hourDueSoon }} hours </i>have passed
                            </td>
                        </tr>
                        <tr>
                            <td class="row_opening">
                                {{ 'general.dateBased' | translate }}
                                <span
                                    class="error"
                                    *ngIf="serviceItem.serviceInterval.dateBased && checkValidServiceInterval('date') && formMode != 1"
                                    >*</span
                                >
                                <i
                                    *ngIf="serviceItem.serviceInterval.dateDueSoon > serviceItem.serviceInterval.dateThreshold"
                                    [tooltip]="'general.serviceIntervalWarning' | translate"
                                    style="line-height: 22px"
                                    class="fas fa-fw fa-exclamation-triangle float-right"
                                ></i>
                            </td>
                            <td class="row_content">
                                <div class="fa_checkbox">
                                    <input
                                        id="dateBased"
                                        name="dateBased"
                                        type="checkbox"
                                        (click)="clearThresholds('date')"
                                        [disabled]="formMode == 1"
                                        [(ngModel)]="serviceItem.serviceInterval.dateBased"
                                        #dateBased="ngModel"
                                    />
                                    <label for="dateBased"> </label>
                                </div>
                            </td>
                            <td *ngIf="serviceItem.serviceInterval.dateBased" class="row_content">
                                <input
                                    *ngIf="formMode == 1"
                                    type="text"
                                    readonly
                                    name="dateThresholdReadonly"
                                    class="form-control table-inline"
                                    [value]="serviceItem.serviceInterval.dateThreshold | amDateFormat: 'll'"
                                />

                                <input
                                    *ngIf="formMode != 1"
                                    type="text"
                                    name="dateThreshold"
                                    id="dateThreshold"
                                    [bsConfig]="dpConfig"
                                    placeholder="{{ 'placeholder.maintenanceThreshold' | translate}}"
                                    bsDatepicker
                                    #dateThreshold="ngModel"
                                    class="form-control table-inline"
                                    [ngModel]="serviceItem.serviceInterval.dateThreshold | amDateFormat: 'll'"
                                    placement="left"
                                    (ngModelChange)="serviceItem.serviceInterval.dateThreshold=$event"
                                    required
                                />
                            </td>
                            <td *ngIf="serviceItem.serviceInterval.dateBased" class="row_content">
                                <input
                                    *ngIf="formMode == 1"
                                    type="text"
                                    readonly
                                    name="dateDueSoonReadonly"
                                    class="form-control table-inline"
                                    [value]="serviceItem.serviceInterval.dateDueSoon | amDateFormat: 'll'"
                                />

                                <input
                                    *ngIf="formMode != 1"
                                    type="text"
                                    name="dateDueSoon"
                                    id="dateDueSoon"
                                    [bsConfig]="dpConfig"
                                    placeholder="{{ 'placeholder.dueSoonThreshold' | translate}}"
                                    bsDatepicker
                                    #dateDueSoon="ngModel"
                                    class="form-control table-inline"
                                    [ngModel]="serviceItem.serviceInterval.dateDueSoon | amDateFormat: 'll'"
                                    placement="left"
                                    (ngModelChange)="serviceItem.serviceInterval.dateDueSoon=$event"
                                    required
                                />
                            </td>
                        </tr>
                        <tr
                            *ngIf="formMode == 2 && serviceItem.serviceInterval.dateBased && !(serviceItem.serviceInterval.dateDueSoon > serviceItem.serviceInterval.dateThreshold) && serviceItem.serviceInterval.dateThreshold && serviceItem.serviceInterval.dateDueSoon"
                        >
                            <td style="opacity: 0.7">
                                The maintenance threshold will be on
                                <i>{{ serviceItem.serviceInterval.dateThreshold | amDateFormat: 'll' }}</i>
                                and a warning will be visible on
                                <i>{{ serviceItem.serviceInterval.dateDueSoon | amDateFormat: 'll' }}</i>
                            </td>
                        </tr>
                        <tr>
                            <td class="row_opening">
                                {{ 'general.distanceBased' | translate }}
                                <span
                                    class="error"
                                    *ngIf="serviceItem.serviceInterval.distanceBased && checkValidServiceInterval('distance') && formMode != 1"
                                    >*</span
                                >
                                <i
                                    *ngIf="serviceItem.serviceInterval.kmDueSoon > serviceItem.serviceInterval.kmThreshold"
                                    [tooltip]="'general.serviceIntervalWarning' | translate"
                                    style="line-height: 22px"
                                    class="fas fa-fw fa-exclamation-triangle float-right"
                                ></i>
                            </td>
                            <td class="row_content">
                                <div class="fa_checkbox">
                                    <input
                                        id="distanceBased"
                                        name="distanceBased"
                                        type="checkbox"
                                        (click)="clearThresholds('distance')"
                                        [disabled]="formMode == 1"
                                        [(ngModel)]="serviceItem.serviceInterval.distanceBased"
                                        #distanceBased="ngModel"
                                    />
                                    <label for="distanceBased"> </label>
                                </div>
                            </td>
                            <td *ngIf="serviceItem.serviceInterval.distanceBased" class="row_content">
                                <input
                                    name="kmThreshold"
                                    id="kmThreshold"
                                    type="number"
                                    [readonly]="formMode == 1"
                                    placeholder="{{ 'placeholder.maintenanceThreshold' | translate}}"
                                    class="form-control table-inline"
                                    [(ngModel)]="serviceItem.serviceInterval.kmThreshold"
                                    required
                                    #kilometersThreshold="ngModel"
                                />
                            </td>
                            <td *ngIf="serviceItem.serviceInterval.distanceBased" class="row_content">
                                <input
                                    name="kmDueSoon"
                                    id="kmDueSoon"
                                    type="number"
                                    [readonly]="formMode == 1"
                                    placeholder="{{ 'placeholder.dueSoonThreshold' | translate}}"
                                    class="form-control table-inline"
                                    [(ngModel)]="serviceItem.serviceInterval.kmDueSoon"
                                    required
                                    #kmDueSoon="ngModel"
                                />
                            </td>
                        </tr>
                        <tr
                            *ngIf="formMode == 2 && serviceItem.serviceInterval.distanceBased && !(serviceItem.serviceInterval.kmDueSoon > serviceItem.serviceInterval.kmThreshold) && serviceItem.serviceInterval.kmThreshold && serviceItem.serviceInterval.kmDueSoon"
                        >
                            <td style="opacity: 0.7">
                                The maintenance threshold is <i>{{ serviceItem.serviceInterval.kmThreshold }} km </i>and
                                a warning will be visible after
                                <i>{{ serviceItem.serviceInterval.kmDueSoon }} km </i>has been driven
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <form>
            <fh-asset-type-selector [assetTypes]="assetTypes" [formMode]="formMode" [loading]="loading">
            </fh-asset-type-selector>
        </form>
    </div>
</div>
