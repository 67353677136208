import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { getDefaultDpConfig, getGridLanguages, getGridButtons, clientSideExportAction } from "app/common/gridhelper";
import { AuthenticationService } from "app/services/authentication/authentication.service";
import { BsDaterangepickerConfig } from "ngx-bootstrap/datepicker";
import { GridBase360Directive } from "app/common/360Grid.base";
import { WarehouseService } from "app/services/warehouse/warehouse.service";
import { Warehouse } from "app/models/warehouse.model";

// Moment timezone
import Moment from "moment-timezone";
import { roundAsString } from "app/common/globals";
import { StorageHelper } from "app/common/storagehelper";
import { ColorService } from "app/services/common/color.service";

window["moment"] = Moment;

@Component({
  selector: "fh-warehouse-messages",
  templateUrl: "messages.template.html",
})
export class WarehouseMessageViewComponent extends GridBase360Directive implements OnInit, OnDestroy {
  loading: boolean;
  asset: any;
  sub: any;
  messages: any;

  theMarker;

  excludingColumns = ["timestamp"];

  // Datepicker
  public dpConfig: Partial<BsDaterangepickerConfig> = new BsDaterangepickerConfig();
  to: any;
  from: any;
  daterangepickerModel: any[];
  permissions: {};
  languageLoaded: boolean;

  maxDate = new Date();
  sensors = [];
  constructorName = "WarehouseMessageViewComponent";

  timezoneIana: string;

  error: any;
  success: any;
  limit = 1000;

  loadingLocations = false;
  previousLookupTimestamp;
  locationSubscription: any;
  loadingLocation: boolean;

  updatesActive = true;
  isLoaded = false;
  randomKey: number;
  warehouse: Warehouse;
  warehouseId: any;

  constructor(
    private warehouseService: WarehouseService,
    private translateService: TranslateService,
    private authenticationService: AuthenticationService,
    private route: ActivatedRoute,
    private router: Router,
    protected storageHelper: StorageHelper,
    private colorService: ColorService
  ) {
    super(storageHelper);

    this.warehouse = null;
    this.sensors = [];

    this.timezoneIana = this.authenticationService.getTimeZoneIana();

    this.daterangepickerModel = [
      Moment().tz(this.timezoneIana).subtract(2, "days").startOf("day").toDate(),
      Moment().tz(this.timezoneIana).endOf("day").toDate(),
    ];

    this.dpConfig = getDefaultDpConfig(authenticationService);
  }

  ngOnInit() {
    this.warehouse = new Warehouse();
    this.loading = true;
    this.warehouse.id = "";

    this.sub = this.route.params.subscribe((params) => {
      const id = params["id"];

      this.warehouseId = id;
      this.warehouseService.getWarehouseById(id).subscribe({
        next: (warehouse) => {
          this.warehouse = warehouse;

          this.dateChanged(null);

          this.translateService.get("general.date").subscribe((value) => {
            this.initGrid();
            this.languageLoaded = true;
          });

          this.loading = false;
        },
        error: (error) => {
          this.error = error;
          this.error.statusText = "Error fetching warehouse";

          setTimeout(() => {
            this.router.navigate(["/Warehouses/Overview"]);
          }, 3000);
        },
      });
    });
  }

  dateChanged(event) {
    console.log("Changed date");
    if (event !== null) {
      this.loadingLocations = true;

      this.datatableElement?.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.ajax.reload();
      });
    }
  }

  initGrid(): void {
    console.log("Init grid");

    const commonExportOptions: any = {
      columns: ["id_export:name", "accountId:name", ":visible[tabindex]"],
    };

    this.columns = [
      {
        name: "timestamp",
        data: "timestamp",
        width: 130,
        defaultContent: "-",
        title: this.translateService.instant("general.timestamp"),
        render: (data, type, row) => {
          const date = Moment.utc(data)["tz"](this.timezoneIana);
          return data
            ? '<span title=" ' + date.toLocaleString() + '">' + date.format("YYYY-MM-DD HH:mm:ss") + "</span>"
            : "";
        },
      },
      {
        name: "zoneName",
        data: "zoneName",
        defaultContent: "-",
        title: this.translateService.instant("general.zoneName"),
      },
      {
        name: "sensorName",
        data: "sensorName",
        defaultContent: "-",
        title: this.translateService.instant("general.sensorName"),
      },
      {
        name: "sensorId",
        data: "sensorId",
        defaultContent: "-",
        title: this.translateService.instant("general.sensorId"),
      },
      {
        name: "temperature",
        data: "temperature",
        defaultContent: "-",
        title: this.translateService.instant("general.temperature"),
        iconName: "fas fa-fw fa-thermometer-three-quarters",
        render: (data, type, row) => {
          return data ? roundAsString(data, 1) + " °C" : "-";
        },
      },
      {
        name: "humidity",
        data: "humidity",
        defaultContent: "-",
        title: this.translateService.instant("general.humidity"),
        iconName: "fas fa-fw fa-humidity",
        render: (data, type, row) => {
          return data ? roundAsString(data, 1) + "%" : "-";
        },
      },
      {
        name: "battery",
        data: "power",
        defaultContent: "-",
        title: this.translateService.instant("general.battery"),
        iconName: "fas fa-fw fa-battery-three-quarters",
        render: (data, type, row) => {
          return data ? roundAsString(data, 1) + "%" : "-";
        },
      },
      {
        name: "rssi",
        data: "rssi",
        defaultContent: "-",
        title: this.translateService.instant("general.rssi"),
        iconName: "fas fa-fw fa-signal",
        render: (data, type, row) => {
          return data ? roundAsString(data, 1) + " dBm" : "-";
        },
      },
    ];

    this.dtOptions = {
      buttons: getGridButtons(
        commonExportOptions,
        "warehousemessages_overview",
        this.translateService.instant("menu.messagesoverview"),
        this.colorService,
        clientSideExportAction
      ),
      pagingType: "simple_numbers",
      serverSide: false,
      processing: false,
      scrollX: true,
      colReorder: { fixedColumnsLeft: 1 },
      order: [[0, "desc"]],
      stateSave: true,
      stateSaveCallback: (settings, data) => {
        this.saveState(this.constructorName, data);
      },
      stateLoadCallback: (_, callback) => {
        (async () => {
          try {
            const columnSettings = await this.loadState(this.constructorName);
            this.searchTerm = columnSettings && columnSettings.search && columnSettings.search.search;
            return columnSettings;
          } catch (e) {
            this.error = {};
            this.error.error = e;
            this.error.statusText = "Error fetching column settings";

            return null;
          }
        })().then((result) => {
          callback(result);
        });
      },
      ajax: (dataTablesParameters: any, callback) => {
        $(".dataTables_info").html(this.translateService.instant("grid.loadingData"));

        this.warehouseService
          .getWarehouseDataByReference(
            this.warehouse.referenceId,
            Moment.utc(this.daterangepickerModel[0]).tz(this.timezoneIana).startOf("day"),
            Moment.utc(this.daterangepickerModel[1]).tz(this.timezoneIana).endOf("day")
          )
          .subscribe({
            next: (resp) => {
              callback({
                recordsTotal: resp.length,
                recordsFiltered: resp.length,
                data: resp,
              });
            },
            error: (error) => {
              this.success = null;
              this.error = error;
              this.error.statusText = "Error fetching data";
              this.loading = false;
            },
          });
      },
      initComplete: (settings, json) => {
        this.loading = false;
        this.loadingLocations = false;

        console.log("init complete");
        this.checkFilters();
        this.drawFilterRow();
        this.loading = false;
      },
      colVis: {
        restore: this.translateService.instant("general.restore"),
        showAll: this.translateService.instant("general.showAll"),
        showNone: this.translateService.instant("general.hideAll"),
      },
      columns: this.columns,
      pageLength: 25,
      lengthMenu: [
        [10, 17, 25, 50, 200, -1],
        [10, 17, 25, 50, 200, this.translateService.instant("general.all")],
      ],
      language: getGridLanguages(this.translateService),
      rowCallback: (row, data) => {},
    };
  }
}
