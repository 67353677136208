import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { throwError } from "rxjs/internal/observable/throwError";
import { Observable, catchError, map } from "rxjs";
import { AuthenticationService } from "../authentication/authentication.service";
import { LoggingService } from "../logging/logging.service";
import { NotificationLog } from "app/models/notificationlog.model";

// Moment timezone
import Moment from "moment-timezone";

window["moment"] = Moment;

@Injectable()
export class NotificationLogService {
  timestamp: number;
  url = "";
  urlBase = "";
  timezoneIana: string;

  constructor(
    private http: HttpClient,
    private loggingService: LoggingService,
    private authenticationService: AuthenticationService
  ) {
    this.url = this.authenticationService.getWebserviceURL("notificationlog");
    this.urlBase = this.authenticationService.getWebserviceURL("");
    this.timezoneIana = this.authenticationService.getTimeZoneIana();
  }

  getPagingUrl(start, end, accountId) {
    return this.url + "Paging?startRange=" + start.unix() + "&endRange=" + end.unix() + "&accountId=" + accountId;
  }

  getNoticationLogById(id: string): Observable<NotificationLog> {
    console.log("getting notificationLog from service");
    return this.http.get(this.url + id, { headers: this.authenticationService.headers }).pipe(
      map((data) => this.parseResponseDetails(data)),
      catchError(this.handleError)
    );
  }

  getNoticationLogByUserId(id: string, start, end): Observable<any> {
    return this.http
      .get(this.urlBase + "User/" + id + "/Notifications?start=" + start.unix() + "&end=" + end.unix(), {
        headers: this.authenticationService.headers,
      })
      .pipe(
        map((data) => this.parseResponse(data)),
        catchError(this.handleError)
      );
  }

  getNoticationLogByDeviceId(id: string, start, end): Observable<any> {
    return this.http
      .get(this.urlBase + "Device/" + id + "/Notifications?start=" + start.unix() + "&end=" + end.unix(), {
        headers: this.authenticationService.headers,
      })
      .pipe(
        map((data) => this.parseResponse(data)),
        catchError(this.handleError)
      );
  }

  getNotificationLogByTriggerId(id: string, start, end): Observable<any> {
    return this.http
      .get(this.urlBase + "Trigger/" + id + "/Notifications?start=" + start.unix() + "&end=" + end.unix(), {
        headers: this.authenticationService.headers,
      })
      .pipe(
        map((data) => this.parseResponse(data)),
        catchError(this.handleError)
      );
  }

  getNoticationLogByAccountId(id: string, start, end): Observable<any> {
    return this.http
      .get(this.urlBase + "Account/" + id + "/Notifications?start=" + start.unix() + "&end=" + end.unix(), {
        headers: this.authenticationService.headers,
      })
      .pipe(
        map((data) => this.parseResponse(data)),
        catchError(this.handleError)
      );
  }

  getNoticationLogChart(start, end, accountId): Observable<any> {
    console.log("getting notificationLog from service");
    return this.http
      .get(this.url + "Chart?startRange=" + start.unix() + "&endRange=" + end.unix() + "&accountId=" + accountId, {
        headers: this.authenticationService.headers,
      })
      .pipe(catchError(this.handleError));
  }

  saveNotificationThresholdsByUserId(id: string, settings: any): Observable<any> {
    console.log("save notification settings", settings);

    return this.http
      .post(this.urlBase + "User/" + id + "/NotificationThresholds", settings, {
        headers: this.authenticationService.headers,
      })
      .pipe(catchError(this.handleError));
  }

  getNotificationThresholdsByUserId(id: string): Observable<any> {
    return this.http
      .get(this.urlBase + "User/" + id + "/NotificationThresholds", { headers: this.authenticationService.headers })
      .pipe(catchError(this.handleError));
  }

  private handleError(error: Response) {
    return throwError(() => error);
  }

  parseResponse(json: any): NotificationLog[] {
    this.loggingService.log(this.constructor.name, "Retrieved " + json.length + " Logitems.");
    const auditlogs: NotificationLog[] = [];

    json.forEach((item) => {
      const log = this.parseResponseDetails(item);
      auditlogs.push(log);
    });

    this.timestamp = new Date().getTime();
    return auditlogs;
  }

  parseResponseDetails(item) {
    const log = new NotificationLog();
    log.id = item.id;
    log.deviceId = item.deviceId;
    log.dataTriggerId = item.dataTriggerId;
    log.recipient = item.recipient;
    log.notificationType = item.notificationType;
    log.responseCode = item.responseCode;
    log.notificationMethod = item.notificationMethod;
    log.timeStampSending = Moment.utc(item.timeStampSending)["tz"](this.timezoneIana);
    log.emailLoginName = item.emailLoginName;
    log.timeStampAcknowledged = item.timeStampAcknowledged;
    log.transmissionStatus = item.transmissionStatus;
    log.fkUserId = item.fkUserId;
    log.subject = item.subject;
    log.triggerId = item.triggerId;
    log.triggerName = item.triggerName;
    log.smsExternalMessageReference = item.smsExternalMessageReference;
    log.assetName = item.assetName;
    log.dataTriggerName = item.dataTriggerName;

    try {
      log.message =
        item.message && item.message.charAt(0) === "{"
          ? JSON.parse(item.message.replace(/\r?\n|\r/g, ""))
          : item.message;
      log.responseMessage =
        item.responseMessage && item.responseMessage.charAt(0) === "{"
          ? JSON.parse(item.responseMessage.replace(/\r?\n|\r/g, ""))
          : item.responseMessage;
    } catch (error) {
      log.message = item.message;
      log.responseMessage = item.responseMessage;
      console.log(error);
    }

    return log;
  }
}
