import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewEncapsulation,
  OnDestroy,
  Input,
} from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { DistanceUnitService } from "app/common/distanceunit.service";
import { roundAsNumber, roundMinutes, roundSeconds } from "app/common/globals";
import { colorMapper, getEngineColor, getFuelColor } from "app/common/leafletGlobals";
import { AuthenticationService } from "app/services/authentication/authentication.service";

// Moment timezone
import Moment from "moment-timezone";
window["moment"] = Moment;

@Component({
  selector: "fh-device-state-display",
  templateUrl: "deviceStateDisplay.template.html",
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeviceDetailsStateDisplayComponent implements OnInit, OnDestroy {
  @Input() loading = false;
  @Input() deviceState;
  @Input() device;
  @Input() sensorTemplates;
  @Input() hasCanbus;
  @Input() showPosition;
  @Input() includeDriver = true;

  colorMapper = colorMapper;
  getFuelColor = getFuelColor;
  getEngineColor = getEngineColor;

  timezoneIana: string;
  translatedKmh: any = "km/h";
  translatedKm: any = "km";

  compareDate = new Date(new Date().setDate(new Date().getDate() - 3));
  permissions: {};

  constructor(
    private authenticationService: AuthenticationService,
    private cd: ChangeDetectorRef,
    private distance: DistanceUnitService,
    private translateService: TranslateService
  ) {
    this.timezoneIana = this.authenticationService.getTimeZoneIana();
    this.permissions = this.authenticationService.permissions;
  }

  ngOnChanges(): void {
    this.cd.markForCheck();
  }

  public redraw() {
    this.cd.markForCheck();
  }

  ngOnDestroy(): void {}

  ngOnInit(): void {
    this.translateService.get("general.date").subscribe((data) => {
      this.translatedKm = this.translateService.instant(this.distance.getDistanceUnit());
      this.translatedKmh = this.translateService.instant(this.distance.getDistanceUnitPerHour());
      this.cd.markForCheck();
    });
  }

  actualRound(value, decimals) {
    return roundAsNumber(value, decimals);
  }

  actualRoundMinutes(value) {
    return roundMinutes(value);
  }

  actualRoundSeconds(value) {
    return roundSeconds(value);
  }

  checkSensorOnTempate(value, templateId) {
    if (!this.sensorTemplates || this.sensorTemplates?.length == 0) {
      return;
    }

    const template = this.sensorTemplates?.find((x) => x.id === templateId);

    if (value === undefined && templateId !== undefined) {
      return false;
    }

    if (template === undefined) {
      return true;
    }

    if (template.maxValue !== undefined && value > template.maxValue) {
      return false;
    }
    if (template.minValue !== undefined && value < template.minValue) {
      return false;
    }

    return true;
  }
}
