import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { FormMode } from "app/common/enums";
import { AuthenticationService } from "../../services/authentication/authentication.service";
import { FuelService } from "app/services/fuel/fuel.service";
import { Fuel } from "app/models/fuel.model";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "fh-fuel-add",
  templateUrl: "add.template.html",
})
export class FuelAddViewComponent implements OnInit {
  loading = false;
  fuel: Fuel = new Fuel();

  formMode = FormMode.add;
  error: any;
  permissions: {};
  success: { statusText: string; success: string };

  permissionName = "FuelEvents_Add";

  constructor(
    private translateServe: TranslateService,
    private fuelService: FuelService,
    private authenticationService: AuthenticationService,
    private router: Router
  ) {
    this.permissions = this.authenticationService.permissions;
  }

  ngOnInit(): void {
    this.fuel.timestamp = new Date();
  }

  onInsert() {
    this.loading = true;

    this.fuelService.saveFuelEvent(this.fuel).subscribe({
      next: (result) => {
        this.success = {
          statusText: "Success",
          success: this.translateServe.instant("general.saveSuccessRedirect", { entity: "fuel" }),
        };

        setTimeout(() => {
          this.router.navigate(["/FuelDetails/Index/" + result.id]);
        }, 2000);

        this.loading = false;
      },
      error: (error) => {
        this.success = null;
        this.error = error;
        this.loading = false;
      },
    });
  }
}
