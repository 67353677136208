import { ChangeDetectorRef, Component, OnDestroy } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { getGridButtons, getGridLanguages, getDefaultDpConfig } from "app/common/gridhelper";
import { AuthenticationService } from "app/services/authentication/authentication.service";
import { catchError, forkJoin } from "rxjs";
import { BatchService } from "app/services/batch/batch.service";
import { GridBase360Directive } from "app/common/360Grid.base";
import { SignalrService } from "app/services/signalr/signalr.service";
import { BatchItemStatus } from "app/common/enums";
import { BsDaterangepickerConfig } from "ngx-bootstrap/datepicker";
import { StorageHelper } from "app/common/storagehelper";

// Moment
import Moment from "moment-timezone";
import { ColorService } from "app/services/common/color.service";

window["moment"] = Moment;

@Component({
  selector: "fh-batch",
  templateUrl: "batch.template.html",
})
export class BatchViewComponent extends GridBase360Directive implements OnDestroy {
  loading = false;
  languageLoaded: boolean;
  permissions: {};

  excludingColumns = ["jobRequested", "batchItemsLength", "batchItemsFailed"];

  success;
  error;

  randomKey: number;

  constructorName = "BatchViewComponent";
  permissionName = "FleetManagement_Jobs";
  timezoneIana: string;

  // Daterange
  public dpConfig: Partial<BsDaterangepickerConfig> = new BsDaterangepickerConfig();
  daterangepickerModel: Date[];

  constructor(
    public signalrService: SignalrService,
    private translateService: TranslateService,
    private cd: ChangeDetectorRef,
    private authenticationService: AuthenticationService,
    private batchService: BatchService,
    protected storageHelper: StorageHelper,
    private colorService: ColorService
  ) {
    super(storageHelper);

    this.loading = true;

    this.randomKey = Math.floor(Math.random() * (999999 - 100000)) + 100000;

    this.permissions = this.authenticationService.permissions;
    this.timezoneIana = this.authenticationService.getTimeZoneIana();

    this.daterangepickerModel = [
      Moment().tz(this.timezoneIana).subtract(1, "months").startOf("day").toDate(),
      Moment().tz(this.timezoneIana).endOf("day").toDate(),
    ];

    this.dpConfig = getDefaultDpConfig(authenticationService);

    // Get all the date for dropdown boxes
    forkJoin({ dateTranslation: this.translateService.get("general.date") }).subscribe({
      next: ({ dateTranslation }) => {
        this.languageLoaded = true;
        this.loading = false;
        this.cd.markForCheck();

        this.initGrid();
      },
      error: (err) => {
        this.error = err;
        this.languageLoaded = true;
        this.loading = false;
        this.cd.markForCheck();
      },
    });
  }

  guidGenerator() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
      const r = (Math.random() * 16) | 0,
        v = c === "x" ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }

  dateChanged(event) {
    if (event !== null) {
      this.datatableElement?.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.ajax
          .url(
            this.batchService.getPagingUrl(
              this.randomKey,
              Moment.utc(this.daterangepickerModel[0]).tz(this.timezoneIana).startOf("day"),
              Moment.utc(this.daterangepickerModel[1]).tz(this.timezoneIana).endOf("day")
            )
          )
          .load();
      });
    }
  }

  public processData(): void {
    const random = this.guidGenerator();

    console.log("Batch " + random + " created");
    this.success = "The batch request " + random + " has been send to SignalR hub.We well be informed on updates.";
    const userId = +this.authenticationService.getUserId();

    this.signalrService.connection.invoke("StartDataProcessing", userId, random).then(() => {
      this.success = "Successfully finished batch SignalR"!;
      this.cd.markForCheck();
    }),
      catchError((error) => {
        this.error = error;
        this.cd.markForCheck();
        return null;
      });
  }

  refresh() {
    super.searchChanged("");
  }

  initGrid(): void {
    $.fn["dataTable"].ext.search.pop();

    this.loading = true;

    this.columns = [
      {
        name: "id",
        data: "id",
        className: "noVis",
        orderable: false,
        title: '<div class="hideDropdown"></div>',
        width: "20",
        render: (data, type, row) => {
          return `
            <a class='btn btn-primary btn-grid' title='${this.translateService.instant(
              "general.details"
            )}' href='/#/System/BatchDetails/Index/${data}'>
              <span class="d-none d-md-inline-flex" style="padding-left: 7px">${this.translateService.instant(
                "general.details"
              )}</span>
              <i class='fas fa-fw fa-angle-right'></i>
            </a>`;
        },
      },
      {
        name: "jobRequested",
        data: "jobRequested",
        type: "date",
        title: this.translateService.instant("general.jobRequested"),
        render: (data, type, row) => {
          const date = Moment.utc(data)["tz"](this.timezoneIana);
          return data ? '<span title=" ' + date.toLocaleString() + '">' + date.format("lll") + "</span>" : "";
        },
      },
      {
        name: "account",
        data: "account",
        title: this.translateService.instant("general.companyName"),
      },
      {
        name: "user",
        data: "user",
        title: this.translateService.instant("general.userName"),
      },
      {
        name: "entityType",
        data: "entityTypes",
        title: this.translateService.instant("general.batchEntity"),
        render: (data, type, row) => {
          return this.translateService.instant("enums.entityTypes." + data);
        },
      },
      {
        name: "batchActionType",
        data: "batchActionType",
        title: this.translateService.instant("general.batchActionType"),
        render: (data, type, row) => {
          return this.translateService.instant("enums.batchAction." + data);
        },
      },
      {
        name: "status",
        data: "status",
        title: this.translateService.instant("general.status"),
        render: (data, type, row) => {
          return this.translateService.instant("enums.batchStatus." + data);
        },
      },
      {
        name: "batchItemsLength",
        data: "batchItems",
        searchable: false,
        orderable: false,
        title: this.translateService.instant("general.itemCount"),
        render: (data, type, row) => {
          if (type === "display") {
            return "<b>" + data.length + "</b>";
          }

          return data.length;
        },
      },
      {
        name: "batchItemsFailed",
        data: "batchItems",
        searchable: false,
        orderable: false,
        title: this.translateService.instant("general.failedItemCount"),
        render: (data, type, row) => {
          return data.filter((item) => item.status === BatchItemStatus.Error).length;
        },
      },
    ];

    this.dtOptions = {
      buttons: getGridButtons(
        this.commonExportOptions,
        "jobs_overview",
        this.translateService.instant("general.job"),
        this.colorService
      ),
      pagingType: "simple_numbers",
      serverSide: true,
      processing: true,
      searchDelay: 2000,
      deferRender: true,
      scrollX: true,
      colReorder: { fixedColumnsLeft: 1 },
      deferLoading: 0,
      stateSave: true,
      stateSaveCallback: (settings, data) => {
        this.saveState(this.constructorName, data);
      },
      stateLoadCallback: (_, callback) => {
        (async () => {
          try {
            const columnSettings = await this.loadState(this.constructorName);
            this.searchTerm = columnSettings && columnSettings.search && columnSettings.search.search;
            return columnSettings;
          } catch (e) {
            this.error = {};
            this.error.error = e;
            this.error.statusText = "Error fetching column settings";

            return null;
          }
        })().then((result) => {
          callback(result);
        });
      },
      order: [[1, "desc"]],
      ajax: {
        beforeSend: () => {
          this.drawFilterRow();

          $(".dataTables_info").html(this.translateService.instant("grid.loadingData"));
        },
        url: this.batchService.getPagingUrl(
          this.randomKey,
          Moment.utc(this.daterangepickerModel[0]).tz(this.timezoneIana).startOf("day"),
          Moment.utc(this.daterangepickerModel[1]).tz(this.timezoneIana).endOf("day")
        ),
        data: (d) => {
          return d;
        },
        type: "POST",
      },
      initComplete: (settings, json) => {
        this.checkFilters();
        this.setEvents();
        this.drawFilterRow();
        this.loading = false;
        this.cd.markForCheck();
      },
      colVis: {
        restore: this.translateService.instant("general.restore"),
        showAll: this.translateService.instant("general.showAll"),
        showNone: this.translateService.instant("general.hideAll"),
        exclude: ["id", "id_export"],
      },
      columns: this.columns,
      pageLength: 17,
      lengthMenu: [
        [10, 17, 25, 50, -1],
        [10, 17, 25, 50, this.translateService.instant("general.all")],
      ],
      language: getGridLanguages(this.translateService),
    };
  }
}
