<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12 flex header-content">
        <nav class="breadcrumb-container" aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a href="/#/"> <i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
                </li>
                <li class="breadcrumb-item" *ngIf="device?.asset && device?.asset.accountId">
                    <a href="/#/AccountDetails/Index/{{device?.asset.accountId}}">{{device?.asset?.companyName}}</a>
                </li>
                <li class="breadcrumb-item">
                    <a href="/#/Devices/Overview?companyName={{device?.companyName}}"
                        >{{'general.devices' | translate }}</a
                    >
                </li>
                <li class="breadcrumb-item">
                    <a href="/#/DeviceDetails/Index/{{device?.id}}"
                        >{{device?.asset?.name ? device?.asset?.name : device?.name}}</a
                    >
                </li>
                <li class="breadcrumb-item active">{{'menu.trips' | translate }}</li>
            </ol>
        </nav>
    </div>
    <fh-device-details-navigation [deviceId]="deviceId" [device]="device"></fh-device-details-navigation>
    <div *ngIf="!showMapOnSide" class="col-lg-12" style="padding: 1px 0 0 0">
        <ngx-loading [show]="loadingGeofences"></ngx-loading>
        <fh-leaflet-map
            (mapReady)="onMapReady($event)"
            [geofences]="geofences"
            [height]="mapHeight"
            [isSpinning]="loadingDetails"
            [geofenceEnabled]="false"
            [allowBookmarks]="false"
            (mapResized)="onMapResized()"
            [geofenceFromTrip]="true"
        >
        </fh-leaflet-map>
    </div>
</div>

<fh-permission-handler [permissionName]="permissionName">
    <div class="wrapper wrapper-content">
        <div class="row" data-equalizer="">
            <div class="col-lg-7">
                <fh-error [error]="error" [success]="success"></fh-error>

                <div class="ibox">
                    <div class="ibox-title">
                        <h5><i class="fas fa-fw fa-filter"></i> {{'general.filter' | translate }}</h5>
                    </div>
                    <div class="ibox-content slick_toppadding flex-container" style="position: relative">
                        <div class="col-lg-9">
                            <div class="form-group input-group" style="margin-bottom: 5px">
                                <span class="input-group-prepend">
                                    <i class="fa fa-fw fa-calendar"></i>
                                </span>
                                <input
                                    placeholder="Daterangepicker"
                                    style="width: 100%"
                                    placeholder="Datepicker"
                                    type="text"
                                    [bsConfig]="dpConfig"
                                    class="form-control"
                                    bsDaterangepicker
                                    [maxDate]="maxDate"
                                    (onHidden)="dateChanged('hidden')"
                                    [(ngModel)]="daterangepickerModel"
                                />
                                <span class="input-group-calendar-datatables">
                                    <i class="fa fa-calendar-alt"></i>
                                </span>
                            </div>
                        </div>
                        <div class="col-lg-3">
                            <div class="form-group input-group" style="margin-bottom: 5px">
                                <span class="input-group-prepend" title="Items per page">
                                    <i class="fa fa-fw fa-stream"></i>
                                </span>
                                <ng-select
                                    [(ngModel)]="itemsPerPage"
                                    class="form-control"
                                    name="itemsPerPage"
                                    required
                                    [clearable]="false"
                                    style="width: 100%"
                                >
                                    <ng-option [value]="10">10</ng-option>
                                    <ng-option [value]="17">17</ng-option>
                                    <ng-option [value]="25">25</ng-option>
                                    <ng-option [value]="50">50</ng-option>
                                    <ng-option [value]="99999">{{'general.all' | translate }}</ng-option>
                                </ng-select>
                            </div>
                        </div>
                        <div style="clear: both"></div>
                    </div>
                </div>

                <div class="ibox">
                    <div class="ibox-title">
                        <span class="pull-left">
                            <h5><i class="fas fa-fw fa-route"></i> {{'menu.trips' | translate }}</h5>
                        </span>
                        <span class="float-right" style="margin-top: -5px">
                            <fh-share-link type="3" [entityId]="device?.id"> </fh-share-link>
                        </span>
                        <span class="float-right">
                            <div class="filter-form d-none d-md-block">
                                <input
                                    type="text"
                                    class="form-control table-inline top-filter d-none d-md-block"
                                    (ngModelChange)="p.setCurrent(0)"
                                    [(ngModel)]="filter"
                                />
                            </div>
                        </span>
                    </div>
                    <div class="ibox-content slick" style="position: relative; overflow-y: hidden">
                        <ngx-loading [show]="loading || loadingTrips"></ngx-loading>
                        <table class="table nowrap dtr-inline no-footer" style="width: 100%; margin-bottom: 0px">
                            <thead>
                                <tr>
                                    <th style="width: 42px"></th>
                                    <th style="width: 50px"></th>
                                    <th style="width: 60px; text-align: right">{{'general.start' | translate }}</th>
                                    <th style="width: 60px; text-align: right">{{'general.duration' | translate }}</th>
                                    <th style="width: 65px; text-align: right">{{ this.translatedKm }}</th>
                                    <th style="width: auto">{{'general.start' | translate }}</th>
                                    <th style="width: auto">{{'general.end' | translate }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <ng-container
                                    *ngFor="let trip of trips | stringFilter: filter | paginate: { itemsPerPage: itemsPerPage, currentPage: currentPage, id: 'first'}; let i = index;"
                                >
                                    <tr *ngIf="isNewDate(trip)">
                                        <td colspan="7" style="font-weight: bold; text-transform: capitalize">
                                            {{ trip.beginDateTime | amDateFormat:'dddd LL' }}
                                        </td>
                                    </tr>
                                    <tr
                                        *ngIf="(currentPage + '.' + i) as id"
                                        class="hand"
                                        [ngClass]="{ 'rowSelected': trip.ident == selectedTrip?.ident, 'messages_orange' : trip.tripMethod == 5 , 'messages_green' : trip.isPrivate, 'messages_red' : trip.isHidden}"
                                        (click)="clearSelectionAndGetTripData($event, trip)"
                                    >
                                        <td
                                            [ngStyle]="{'color': colorArray[trip.ident % colorArray.length]}"
                                            style="text-align: right; font-weight: bold"
                                            class="elipses_overflow"
                                        >
                                            {{ trip.ident }}
                                        </td>
                                        <td
                                            [ngStyle]="{'border-left': '7px solid ' + colorArray[trip.ident % colorArray.length]}"
                                        >
                                            <span
                                                (click)="getTripData($event, trip)"
                                                *ngIf="trip.tripMethod !== 5 && !trip.isHidden"
                                            >
                                                <i
                                                    class="far fa-fw fa-lg"
                                                    [ngClass]="tripPolyLineDict[trip.id] ? 'fa-check-square' : 'fa-square'"
                                                ></i>
                                            </span>
                                            <span *ngIf="trip.tripMethod == 5 || trip.isHidden">
                                                <i *ngIf="trip.isHidden" class="fa fa-fw fa-lg fa-user-secret"></i>

                                                <i
                                                    *ngIf="trip.tripMethod == 5"
                                                    class="fa fa-fw fa-lg fa-tachometer-alt"
                                                ></i>
                                            </span>
                                        </td>
                                        <td
                                            style="text-align: right"
                                            class="elipses_overflow"
                                            title="{{ trip.beginDateTime.toLocaleString() }} - {{ trip.endDateTime.toLocaleString()}}"
                                        >
                                            {{ trip.beginDateTime | amDateFormat:'HH:mm' }}
                                        </td>
                                        <td
                                            style="text-align: right"
                                            class="elipses_overflow"
                                            title="{{ trip.beginDateTime.toLocaleString() }} - {{ trip.endDateTime.toLocaleString()}}"
                                        >
                                            {{ trip.tripType != 3 ? (trip.duration) : '-' }}
                                        </td>
                                        <td class="elipses_overflow" style="text-align: right">
                                            {{ actualRound(trip.segmentDistanceInKilometers,2)?.toLocaleString() }}
                                        </td>
                                        <td style="width: auto" class="elipses_overflow">
                                            <span *ngIf="trip.tripMethod == 5"
                                                >{{ ('general.calibration' | translate ) }}</span
                                            >
                                            <span *ngIf="trip.source == 2" title="Generated by TripCalculator">★</span>
                                            <span *ngIf="trip.source == 3" title="Generated by TripRerunner">☆</span>
                                            {{ trip.beginAddressFull }}
                                        </td>
                                        <td style="width: auto" class="elipses_overflow">
                                            <container-element [ngSwitch]="trip.tripType">
                                                <span *ngSwitchCase="3">{{ ('general.ongoing' | translate ) }}</span>
                                                <span *ngSwitchCase="5">{{ ('general.calibration' | translate )}}</span>
                                                <span *ngSwitchDefault>{{ trip.endAddressFull }}</span>
                                            </container-element>
                                        </td>
                                    </tr>
                                </ng-container>
                                <tr *ngIf="trips.length === 0">
                                    <td colspan="7">{{'general.nodatafound' | translate }}</td>
                                </tr>
                                <tr *ngIf="resetDate()"></tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="ibox-footer noselect dataTables_wrapper">
                        <pagination-template #p="paginationApi" (pageChange)="currentPage = $event" id="first">
                            <div class="dataTables_paginate paging_simple_numbers" id="dataTable_paginate">
                                <ul class="pagination">
                                    <li class="paginate_button page-item previous" [class.disabled]="p.isFirstPage()">
                                        <a class="page-link" (click)="p.previous()" *ngIf="!p.isFirstPage()"
                                            >{{ 'grid.previous' | translate }}</a
                                        >
                                        <a *ngIf="p.isFirstPage()">{{ 'grid.previous' | translate }}</a>
                                    </li>

                                    <li
                                        class="paginate_button page-item"
                                        *ngFor="let page of p.pages"
                                        [class.current]="p.getCurrent() === page.value"
                                    >
                                        <a
                                            class="page-link"
                                            (click)="p.setCurrent(page.value)"
                                            *ngIf="p.getCurrent() !== page.value"
                                        >
                                            <span>{{ page.label }}</span>
                                        </a>
                                        <a
                                            class="page-link"
                                            (click)="p.setCurrent(page.value)"
                                            *ngIf="p.getCurrent() === page.value"
                                        >
                                            <span style="font-weight: bold">{{ page.label }}</span>
                                        </a>
                                    </li>

                                    <li class="paginate_button page-item next" [class.disabled]="p.isLastPage()">
                                        <a class="page-link" (click)="p.next()" *ngIf="!p.isLastPage()">
                                            {{ 'grid.next' | translate }}
                                        </a>
                                        <a class="page-link" *ngIf="p.isLastPage()"> {{ 'grid.next' | translate }} </a>
                                    </li>
                                </ul>
                            </div>
                        </pagination-template>
                        <div style="clear: both"></div>
                    </div>
                </div>
            </div>
            <div class="col-lg-5">
                <div class="ibox" style="position: relative">
                    <ngx-loading [show]="loadingGeofences"></ngx-loading>
                    <fh-leaflet-map
                        *ngIf="showMapOnSide"
                        (mapReady)="onMapReady($event)"
                        [geofences]="geofences"
                        [height]="mapHeight"
                        [borderRadius]="8"
                        [geofenceEnabled]="false"
                        [allowBookmarks]="false"
                        [isSpinning]="loadingDetails"
                        (mapResized)="onMapResized()"
                        [geofenceFromTrip]="true"
                    >
                    </fh-leaflet-map>

                    <div class="ibox-content" style="padding: 5px; position: relative; min-height: 50px">
                        <highcharts-chart
                            [runOutsideAngular]="true"
                            *ngIf="speedChart && selectedTrip"
                            style="display: block; width: 100%; height: 220px"
                            [Highcharts]="Highcharts"
                            [options]="speedChart"
                        >
                        </highcharts-chart>
                    </div>
                </div>

                <div class="ibox" style="position: relative">
                    <ngx-loading [show]="loadingDetails || loadingGeofences || mapBusy"> </ngx-loading>
                    <div header class="ibox-title">
                        <span class="pull-left">
                            <h5><i class="fa fa-fw fa-map-marked-alt"></i> {{'general.animation' | translate }}</h5>
                        </span>
                        <span class="float-right">
                            <div *ngIf="selectedTrip">
                                <ng-container
                                    *ngIf="leafletMapComponent?.locationLayer?.getLayers().length; else showLocations"
                                >
                                    <button
                                        class="btn btn-info"
                                        title="{{ 'general.removeLocations' | translate }}"
                                        (click)="leafletMapComponent.locationLayer.clearLayers()"
                                    >
                                        <i class="fas fa-fw fa-location-pin-slash"></i>
                                    </button>
                                </ng-container>

                                <ng-template #showLocations>
                                    <button
                                        class="btn btn-info"
                                        title="{{ 'general.showLocations' | translate }}"
                                        (click)="showLocationDetails()"
                                    >
                                        <i class="fa fa-fw fa-location-pin"></i>
                                    </button>
                                </ng-template>

                                <fh-share-link
                                    type="4"
                                    [entityId]="selectedTrip?.id"
                                    *ngIf="selectedTrip || leafletMapComponent?.tripLayer.getLayers().length == 1"
                                ></fh-share-link>

                                <div style="clear: both"></div>
                            </div>
                        </span>
                    </div>
                    <div *ngIf="!selectedTrip" class="ibox-content" style="position: relative">
                        {{'general.noTripSelected' | translate }}
                    </div>
                    <div class="ibox-content slick">
                        <table class="table nowrap dtr-inline no-footer" style="width: 100%; margin-bottom: 0px">
                            <tbody>
                                <tr>
                                    <td class="row_opening">{{'general.showAbuse' | translate }}</td>
                                    <td class="row_content">
                                        <i
                                            id="checkBox_11"
                                            class="auto_checkbox fa fa-fw hand"
                                            (click)="flipShowAbuseIcons();"
                                            [ngClass]="showAbuseIcons ? 'fa-check' : 'fa-times'"
                                            title="Filter on 0 values"
                                            [style.color]="showAbuseIcons ? 'rgba(0, 142, 67, 0.8)' : 'rgba(151, 28, 36, 0.8)'"
                                        ></i>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="ibox-footer" *ngIf="selectedTrip">
                        <ng-container>
                            <button
                                *ngIf="!myMovingMarker"
                                class="btn btn-info"
                                title="{{ 'general.playTrip' | translate }}"
                                (click)="playTrips()"
                            >
                                <i class="fa fa-fw fa-play"></i>
                                <span class="d-none d-md-inline-flex"> {{'general.play' | translate }}</span>
                            </button>

                            <button
                                *ngIf="myMovingMarker"
                                class="btn btn-info"
                                title="{{ 'general.resumeTrip' | translate }}"
                                (click)="resumeTrip()"
                            >
                                <i class="fa fa-fw fa-play"></i>
                                <span class="d-none d-md-inline-flex"> {{'general.resume' | translate }}</span>
                            </button>

                            <button
                                *ngIf="myMovingMarker"
                                class="btn btn-info"
                                title="{{ 'general.pauseTrip' | translate }}"
                                (click)="pauseTrip()"
                            >
                                <i class="fa fa-fw fa-pause"></i>
                                <span class="d-none d-md-inline-flex"> {{'general.pause' | translate }}</span>
                            </button>

                            <button
                                *ngIf="myMovingMarker"
                                class="btn btn-info"
                                title="{{ 'general.stopTrip' | translate }}"
                                (click)="stopTrip()"
                            >
                                <i class="fa fa-fw fa-stop"></i>
                                <span class="d-none d-md-inline-flex"> {{'general.stop' | translate }}</span>
                            </button>
                        </ng-container>

                        <span class="float-right">
                            <ng-select
                                class="form-control"
                                name="speedCalculator"
                                [(ngModel)]="speedCalculator"
                                (ngModelChange)="speedChanged()"
                                [searchable]="false"
                                [clearable]="false"
                                style="width: 100px"
                            >
                                <ng-option [value]="2">Fast</ng-option>
                                <ng-option [value]="8">Normal</ng-option>
                                <ng-option [value]="16">Slow</ng-option>
                            </ng-select>
                        </span>
                        <div style="clear: both"></div>
                    </div>
                </div>

                <div class="ibox" style="position: relative" *ngIf="hasPrivateBusinessButtons() == true">
                    <div class="ibox-title">
                        <h5><i class="fas fa-fw fa-briefcase"></i> {{'general.privateBusiness' | translate }}</h5>
                    </div>
                    <div class="ibox-content slick" style="position: relative">
                        <table class="table nowrap dtr-inline no-footer" style="width: 100%; margin-bottom: 0px">
                            <tbody>
                                <tr>
                                    <td class="row_opening">{{'general.private' | translate }}</td>
                                    <td class="row_content">
                                        <i
                                            (click)="setPrivateBusiness()"
                                            title="{{ 'general.private' | translate }}"
                                            style="vertical-align: sub"
                                            class="fa-lg fa-duotone"
                                            [ngClass]=" selectedTrip.isPrivate ? 'fa-toggle-on toggle-green' : 'fa-toggle-off toggle-red'"
                                        ></i>
                                    </td>
                                </tr>
                                <tr *ngIf="!selectedTrip.isPrivate">
                                    <td class="row_opening">{{'general.deTour' | translate }}</td>
                                    <td class="row_content">
                                        <ngx-slider [(value)]="selectedTrip.kmPrivate" [options]="sliderOptions">
                                        </ngx-slider>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="2" style="background-color: unset; padding: 2px">
                                        <textarea
                                            name="note"
                                            name="note"
                                            class="noresize stickypad"
                                            style="height: 100px; width: 100%; resize: none"
                                            id="encCss"
                                            placeholder="{{ 'placeholder.comments' | translate}}"
                                            [(ngModel)]="selectedTrip.comment"
                                        >
                                        </textarea>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="ibox-footer" *ngIf="selectedTrip">
                        <button
                            class="btn btn-primary"
                            title="{{ 'general.save' | translate }}"
                            (click)=" savePrivateDistance()"
                        >
                            <i class="fas fa-fw fa-save"></i>
                            <span class="d-none d-md-inline-flex"> {{'general.save' | translate }}</span>
                        </button>
                    </div>
                </div>

                <div class="ibox" style="position: relative">
                    <div class="ibox-title">
                        <h5><i class="fas fa-fw fa-location-arrow"></i> {{'general.selectedTrip' | translate }}</h5>
                    </div>
                    <div
                        class="ibox-content"
                        style="position: relative"
                        *ngIf="!selectedTrip || leafletMapComponent?.tripLayer.getLayers().length > 1"
                    >
                        <ngx-loading [show]="loadingDetails || loadingGeofences || mapBusy"> </ngx-loading>
                        <span style="width: 100%" *ngIf="leafletMapComponent?.tripLayer.getLayers().length > 1">
                            {{'general.selectOnlyOneTrip' | translate }}
                        </span>
                        <span *ngIf="!selectedTrip"> {{'general.noTripSelected' | translate }} </span>
                    </div>
                    <div
                        class="ibox-content slick"
                        style="position: relative"
                        *ngIf="selectedTrip && !(leafletMapComponent?.tripLayer.getLayers().length > 1)"
                    >
                        <ngx-loading [show]="loadingDetails || loadingGeofences || mapBusy"> </ngx-loading>
                        <div class="flex-container">
                            <div class="col-lg-4" style="padding: 0">
                                <div class="kpi-item-small">
                                    <h3
                                        class="margin-bottom font-bold"
                                        title="{{selectedTrip.beginDateTime.toLocaleString()}}"
                                    >
                                        {{ selectedTrip.beginDateTime | amDateFormat:'ll HH:mm' }}
                                    </h3>
                                    <div>{{'general.start' | translate }}</div>
                                </div>
                            </div>
                            <div class="col-lg-4" style="padding: 0">
                                <div class="kpi-item-small">
                                    <h3
                                        class="margin-bottom font-bold"
                                        title="{{selectedTrip.endDateTime.toLocaleString()}}"
                                    >
                                        {{ selectedTrip.endDateTime | amDateFormat:'ll HH:mm' }}
                                    </h3>
                                    <div>{{'general.end' | translate }}</div>
                                </div>
                            </div>
                            <div class="col-lg-4" style="padding: 0">
                                <div class="kpi-item-small">
                                    <h3 class="margin-bottom font-bold">{{ selectedTrip.duration }}</h3>
                                    <div>{{'general.duration' | translate }}</div>
                                </div>
                            </div>
                            <div class="col-lg-4" style="padding: 0">
                                <div class="kpi-item-small">
                                    <h3 class="margin-bottom font-bold">
                                        {{ actualRound(selectedTrip.segmentDistanceInKilometers,2)?.toLocaleString() }}
                                        {{ translatedKm }}
                                    </h3>
                                    <div>{{'general.distanceGpsBased' | translate }}</div>
                                </div>
                            </div>
                            <div class="col-lg-4" style="padding: 0">
                                <div class="kpi-item-small">
                                    <h3 class="margin-bottom font-bold">
                                        {{ actualRound(selectedTrip.calibratedOdoValueEnd, 0)?.toLocaleString() }} {{
                                        translatedKm }}
                                    </h3>
                                    <div>{{'general.odo' | translate }}</div>
                                </div>
                            </div>
                            <div class="col-lg-4" style="padding: 0" *ngIf="permissions['Drivers_View']">
                                <div class="kpi-item-small">
                                    <h3 class="margin-bottom font-bold">
                                        <a
                                            *ngIf="scannedDriver?.id"
                                            class="secondary link_bolder"
                                            href="/#/DriverDetails/Index/{{scannedDriver.id}}"
                                        >
                                            {{ scannedDriver.name }}</a
                                        >

                                        <a
                                            *ngIf="selectedTrip?.driverId"
                                            class="secondary link_bolder"
                                            href="/#/DriverDetails/Index/{{selectedTrip.driverId}}"
                                        >
                                            {{ selectedTrip.driverName }}</a
                                        >

                                        <span *ngIf="!selectedTrip.driverId && !scannedDriver"
                                            >{{'general.unknown' | translate }}</span
                                        >
                                    </h3>
                                    <div>{{'general.driver' | translate }}</div>
                                </div>
                            </div>
                            <div class="col-lg-4" style="padding: 0">
                                <div class="kpi-item-small">
                                    <h3 class="margin-bottom font-bold">
                                        {{ selectedTrip.maxSpeed }} {{ translatedKmh }}
                                    </h3>
                                    <div>{{'general.maxSpeed' | translate }}</div>
                                </div>
                            </div>
                            <div class="col-lg-4" style="padding: 0">
                                <div class="kpi-item-small">
                                    <h3 class="margin-bottom font-bold">
                                        {{ actualRound(selectedTrip.totalConsumptionMixed,2) }} {{'enums.uom.liter' |
                                        translate }}
                                    </h3>
                                    <div>{{'general.totalConsumptionMixed' | translate }}</div>
                                </div>
                            </div>
                            <div class="col-lg-4" style="padding: 0">
                                <div class="kpi-item-small">
                                    <h3 class="margin-bottom font-bold">
                                        {{ actualRound(selectedTrip.totalEmissionCO2,2) }} {{'enums.uom.kg' | translate
                                        }}
                                    </h3>
                                    <div>{{'general.totalEmissionCO2' | translate }}</div>
                                </div>
                            </div>
                            <!-- <div class="col-lg-4" style="padding:0">
                                <div class="kpi-item-small">
                                    <h3 class="margin-bottom font-bold">
                                        {{ actualRound(selectedTrip.totalEmissionParticlesLight,2) }}
                                    </h3>
                                    <div> {{'general.totalEmissionParticlesLight' | translate }}</div>
                                </div>
                            </div> -->
                            <div style="clear: both"></div>
                        </div>
                    </div>
                </div>

                <div class="ibox" style="position: relative">
                    <div class="ibox-title">
                        <h5><i class="fas fa-fw fa-location-arrow"></i> {{'general.episodes' | translate }}</h5>
                    </div>
                    <div
                        class="ibox-content"
                        style="position: relative"
                        *ngIf="!selectedTrip || leafletMapComponent?.tripLayer.getLayers().length > 1"
                    >
                        <ngx-loading [show]="loadingDetails || loadingGeofences || mapBusy"> </ngx-loading>
                        <span style="width: 100%" *ngIf="leafletMapComponent?.tripLayer.getLayers().length > 1">
                            {{'general.selectOnlyOneTrip' | translate }}
                        </span>
                        <span *ngIf="!selectedTrip"> {{'general.noTripSelected' | translate }} </span>
                    </div>
                    <div
                        class="ibox-content slick"
                        style="position: relative"
                        *ngIf="selectedTrip && !(leafletMapComponent?.tripLayer.getLayers().length > 1)"
                    >
                        <div
                            class="ibox-content slick"
                            style="position: relative"
                            *ngIf="selectedTrip && !(leafletMapComponent?.tripLayer.getLayers().length > 1)"
                        >
                            <table
                                class="table nowrap dtr-inline no-footer low-opacity"
                                style="width: 100%; margin-bottom: 0px"
                            >
                                <tbody>
                                    <tr
                                        class="hand"
                                        *ngIf="selectedTrip.tripType != 3"
                                        (click)="displayLocation(selectedTrip.endLocation)"
                                    >
                                        <td style="width: 50px; padding-left: 18px">
                                            <i class="fa fa-fw fa-flag-checkered"></i>
                                        </td>
                                        <td
                                            style="width: 60px; text-align: right"
                                            title="{{selectedTrip.endDateTime.toLocaleString()}}"
                                        >
                                            {{ selectedTrip.endDateTime | amDateFormat:'HH:mm' }}
                                        </td>
                                        <td>{{'general.end' | translate }} in {{ selectedTrip.endAddressFull }}</td>
                                    </tr>
                                    <tr
                                        class="hand"
                                        (click)="displayLocation(event.location, event.geofence, event)"
                                        *ngFor="let event of selectedTrip.concatenatedEvents; let i = index;"
                                    >
                                        <td style="width: 50px; padding-left: 18px">
                                            <i
                                                class="fa fa-fw"
                                                [ngClass]="event?.icon"
                                                [style.color]="event?.iconFontColor"
                                            ></i>
                                        </td>
                                        <td
                                            style="width: 60px; text-align: right"
                                            title="{{event.startUtc?.toLocaleString()}}"
                                        >
                                            {{event.startUtc | amDateFormat: 'HH:mm' }}
                                        </td>
                                        <td><span [innerHTML]="event.description"></span></td>
                                    </tr>
                                    <tr class="hand" (click)="displayLocation(selectedTrip.beginLocation)">
                                        <td style="width: 50px; padding-left: 18px">
                                            <i class="fa fa-fw fa-car-bus"></i>
                                        </td>
                                        <td
                                            style="width: 60px; text-align: right"
                                            title="{{selectedTrip.beginDateTime?.toLocaleString()}}"
                                        >
                                            {{ selectedTrip.beginDateTime | amDateFormat:'HH:mm' }}
                                        </td>
                                        <td>{{'general.start' | translate }} in {{ selectedTrip.beginAddressFull }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</fh-permission-handler>
