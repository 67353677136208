import { Episode } from "app/models/episode.model";
import * as L from "leaflet";

export function parseEpisode(item, translateService, Moment, timezoneIana, distance) {
  const episode = new Episode();

  const deviceepisodetype = item.fkDeviceEpisodeTypeId;
  episode.assetName = item.assetName;

  episode.startUtc = Moment.utc(item.episodeStart)["tz"](timezoneIana);
  episode.endUtc = Moment.utc(item.episodeEnd)["tz"](timezoneIana);
  episode.date = Moment.utc(item.episodeStart)["tz"](timezoneIana).unix();
  episode.durationInSeconds = item.durationInSeconds;
  episode.fkDeviceEpisodeTypeId = item.fkDeviceEpisodeTypeId;

  episode.conditions = item.conditions;
  episode.deviation = item.deviation;

  episode.driverId = item.driverId;

  episode.roadPolygon = item.roadPolygon;
  episode.geoJson = item.geoJson;

  episode.id = item.id;
  episode.episodeType = item.deviceEpisodeType;
  episode.locationEventType = item.locationEventType;

  episode.count = item.count;
  episode.maxValue = item.maxValue;
  episode.limitValue = item.limitValue;
  episode.description = item.description;

  episode.beginLocationType = item.beginLocationType;
  episode.endLocationType = item.endLocationType;

  episode.tripIdsList = [];
  episode.tripIdsList = item.tripIdsList;

  episode.locationId = item.locationId;

  // drawing interpetaion

  const translatedSpeed = translateService.instant("general.speed");
  const translatedRoadSpeed = translateService.instant("general.roadSpeed");
  const translatedKmh = translateService.instant(distance.getDistanceUnitPerHour());
  const translatedRpm = translateService.instant("general.rpm");
  const translatedLimit = translateService.instant("general.limit");
  const translatedIdling = translateService.instant("general.deviceState_3");
  const translatedStart = translateService.instant("general.started");
  const translatedEnd = translateService.instant("general.ended");

  const displaySpeeds = false;
  const displayAccel = false;

  // If the episode is older then a day... hide the episode and leave it to the summery
  const startDate = Moment.utc(item.episodeStart);
  const now = Moment(new Date());
  const diffInHours = now.diff(startDate, "hours");

  // Convert deviation to readable format
  if (episode.deviation) {
    const deviationArray = episode.deviation.split(";");
    if (deviationArray[1]) {
      if (deviationArray[1] === "km") {
        // value in %.. convert to miles when needed
        episode.deviation =
          "+ " + Math.ceil(distance.calculateDistanceUnitFromKm(deviationArray[0], 0)) + " " + translatedKmh;
      } else {
        // %
        episode.deviation = "+ " + deviationArray[0] + "%";
      }
    } else {
      // do nothing to the deviation string because it is in old format
    }
  }

  episode.duration = item.durationInSeconds >= 0 && Moment.duration(item.durationInSeconds, "seconds").humanize();

  episode.beginLongitude = item.beginLongitude;
  episode.beginLatitude = item.beginLatitude;

  episode.location = L.latLng(item.beginLatitude, item.beginLongitude);

  episode.endLongitude = item.endLongitude;
  episode.endLatitude = item.endLatitude;

  episode.iconFontColor = "#666";
  episode.iconUrl = "";
  episode.iconBackgroundClass = "white";
  episode.markerColor = "orange";
  episode.assetId = item.assetId;
  episode.deviceId = item.deviceId;
  episode.geofenceId = item.geoFenceId;

  // defaulding
  episode.icon = "fas fa-question";
  episode.description = translateService.instant("general.unknown");
  episode.header = episode.startUtc.format("HH:mm");

  if (item.triggerId) {
    episode.icon = "fas fa-bell-on";
    episode.header = episode.startUtc.format("HH:mm") + " -> " + episode.endUtc.format("HH:mm");
    episode.description = item.description ?? item.triggerName;
  }

  if (deviceepisodetype === 1) {
    episode.displayAsLabel = false;
    episode.icon = "fas fa-chart-area";
    episode.description =
      translateService.instant("general.rpmHigh") +
      (episode.maxValue ? " (" + Math.ceil(episode.maxValue) + " " + translatedRpm + ")" : "");
  }

  if (deviceepisodetype === 2) {
    episode.displayAsLabel = false;
    episode.icon = "fas fa-chart-area";
    episode.description = translateService.instant("general.rpmMedium");
  }

  if (deviceepisodetype === 3) {
    episode.displayAsLabel = false;
    episode.icon = "fas fa-chart-area";
    episode.description = translateService.instant("general.rpmLow");
  }

  if (deviceepisodetype === 4) {
    episode.displayAsLabel = false;
    episode.icon = "fas fa-space-shuttle";
    episode.description =
      translateService.instant("general.speedHigh") +
      (episode.maxValue
        ? " (" + Math.ceil(distance.calculateDistanceUnitFromKm(episode.maxValue, 0)) +
          translatedKmh + " " + (episode.limitValue ? " " + translatedLimit + ": " : "") +
          Math.ceil(distance.calculateDistanceUnitFromKm(episode.limitValue, 0)) +
          translatedKmh + ")"
        : "");
  }

  if (deviceepisodetype === 5) {
    episode.displayAsLabel = false;
    episode.icon = "fas fa-space-shuttle";
    episode.description = translateService.instant("general.speedMedium");
  }

  if (deviceepisodetype === 6) {
    episode.displayAsLabel = false;
    episode.icon = "fas fa-space-shuttle";
    episode.description = translateService.instant("general.speedLow");
  }

  if (deviceepisodetype === 7) {
    episode.displayAsLabel = false;
    episode.icon = "fas fa-exclamation-triangle";
    episode.description = translateService.instant("general.torqueHigh");
  }

  if (deviceepisodetype === 8) {
    episode.displayAsLabel = false;
    episode.icon = "fas fa-exclamation-triangle";
    episode.description = translateService.instant("general.torqueMedium");
  }

  if (deviceepisodetype === 9) {
    episode.displayAsLabel = false;
    episode.icon = "fas fa-exclamation-triangle";
    episode.description = translateService.instant("general.torqueLow");
  }

  if (deviceepisodetype === 10) {
    episode.displayAsLabel = false;
    episode.icon = "fas fa-exclamation-triangle";
    episode.description = translateService.instant("general.torqueHigh");
  }

  if (deviceepisodetype === 11) {
    episode.displayAsLabel = false;
    episode.icon = "fas fa-exclamation-triangle";
    episode.description = translateService.instant("general.torqueMedium");
  }

  if (deviceepisodetype === 12) {
    episode.displayAsLabel = false;
    episode.icon = "fas fa-exclamation-triangle";
    episode.description = translateService.instant("general.torqueLow");
  }

  if (deviceepisodetype === 13) {
    episode.displayAsLabel = false;
    episode.icon = "fas fa-exclamation-triangle";
    episode.description = translateService.instant("general.torqueHigh");
  }

  if (deviceepisodetype === 14) {
    episode.displayAsLabel = false;
    episode.icon = "fas fa-exclamation-triangle";
    episode.description = translateService.instant("general.torqueMedium");
  }

  if (deviceepisodetype === 15) {
    episode.displayAsLabel = false;
    episode.icon = "fas fa-exclamation-triangle";
    episode.description = translateService.instant("general.torqueLow");
  }

  if (deviceepisodetype === 16) {
    episode.displayAsLabel = false;
    episode.icon = "fas fa-exclamation-triangle";
    episode.description = translateService.instant("general.coasting");
  }

  if (deviceepisodetype === 17) {
    episode.displayAsLabel = false;
    episode.icon = "fas fa-exclamation-triangle";
    episode.description = translateService.instant("general.cruising");
  }
  if (deviceepisodetype === 18) {
    episode.icon = "fas fa-exclamation-triangle";
    episode.description = translateService.instant("general.driving");
  }

  if (deviceepisodetype === 19) {
    episode.icon = "fas fa-pause-circle";
    episode.header = episode.startUtc.format("HH:mm") + " -> " + episode.endUtc.format("HH:mm");
    episode.description = translatedIdling;
  }

  if (deviceepisodetype === 20) {
    episode.displayAsLabel = false;
    episode.icon = "fas fa-exclamation-triangle";
    episode.description = "Harsh braking";
  }

  if (deviceepisodetype === 21) {
    episode.icon = "fas fa-exclamation-triangle";
    episode.description = "Number Of Stops";
  }

  if (deviceepisodetype === 22) {
    episode.icon = "fas fa-exclamation-triangle";
    episode.description = "Retarder";
  }

  if (deviceepisodetype === 23) {
    episode.icon = "fas fa-exclamation-triangle";
    episode.description = "Engine seconds";
  }
  if (deviceepisodetype === 24) {
    episode.displayAsLabel = false;
    episode.icon = "fas fa-jet-fighter";
    episode.description = "Accel";
    episode.display = displayAccel;
  }
  if (deviceepisodetype === 25) {
    episode.displayAsLabel = false;
    episode.icon = "fas fa-brake-warning";
    episode.description = "Decel";
    episode.display = displayAccel;
  }
  if (deviceepisodetype === 26) {
    episode.displayAsLabel = false;
    episode.icon = "fas fa-car-tilt";
    episode.description = "Cornering";
  }

  if (deviceepisodetype === 27) {
    episode.icon = "fas fa-plug";
    episode.description = "Fuel";
  }
  if (deviceepisodetype === 30) {
    episode.icon = "fas fa-taxi";
    episode.limitValue = 80;
    episode.description =
      translatedSpeed +
      " > " +
      Math.ceil(distance.calculateDistanceUnitFromKm(episode.limitValue, 0)) +
      " " +
      translatedKmh;
    episode.actualValueDescription = episode.maxValue
      ? Math.ceil(distance.calculateDistanceUnitFromKm(episode.maxValue, 0)) + " " + translatedKmh
      : "";
    episode.limitValueDescription = episode.limitValue
      ? Math.ceil(distance.calculateDistanceUnitFromKm(episode.limitValue, 0)) + " " + translatedKmh
      : "";
    episode.display = displaySpeeds;
    episode.markerColor = "#CF0136";
  }

  if (deviceepisodetype === 31) {
    episode.icon = "fas fa-taxi";
    episode.limitValue = 90;
    episode.description =
      translatedSpeed +
      " > " +
      Math.ceil(distance.calculateDistanceUnitFromKm(episode.limitValue, 0)) +
      " " +
      translatedKmh;
    episode.actualValueDescription = episode.maxValue
      ? Math.ceil(distance.calculateDistanceUnitFromKm(episode.maxValue, 0)) + " " + translatedKmh
      : "";
    episode.limitValueDescription = episode.limitValue
      ? Math.ceil(distance.calculateDistanceUnitFromKm(episode.limitValue, 0)) + " " + translatedKmh
      : "";
    episode.display = displaySpeeds;
    episode.markerColor = "#CF0136";
  }

  if (deviceepisodetype === 32) {
    episode.icon = "fas fa-taxi";
    episode.limitValue = 100;
    episode.description =
      translatedSpeed +
      " > " +
      Math.ceil(distance.calculateDistanceUnitFromKm(episode.limitValue, 0)) +
      " " +
      translatedKmh;
    episode.actualValueDescription = episode.maxValue
      ? Math.ceil(distance.calculateDistanceUnitFromKm(episode.maxValue, 0)) + " " + translatedKmh
      : "";
    episode.limitValueDescription = episode.limitValue
      ? Math.ceil(distance.calculateDistanceUnitFromKm(episode.limitValue, 0)) + " " + translatedKmh
      : "";
    episode.display = displaySpeeds;
    episode.markerColor = "#CF0136";
  }

  if (deviceepisodetype === 33) {
    episode.icon = "fas fa-taxi";
    episode.limitValue = 110;
    episode.description =
      translatedSpeed +
      " > " +
      Math.ceil(distance.calculateDistanceUnitFromKm(episode.limitValue, 0)) +
      " " +
      translatedKmh;
    episode.actualValueDescription = episode.maxValue
      ? Math.ceil(distance.calculateDistanceUnitFromKm(episode.maxValue, 0)) + " " + translatedKmh
      : "";
    episode.limitValueDescription = episode.limitValue
      ? Math.ceil(distance.calculateDistanceUnitFromKm(episode.limitValue, 0)) + " " + translatedKmh
      : "";
    episode.display = displaySpeeds;
    episode.markerColor = "#CF0136";
  }

  if (deviceepisodetype === 34) {
    episode.event = translatedSpeed;
    episode.icon = "fas fa-taxi";
    episode.limitValue = 120;
    episode.description =
      translatedSpeed +
      " > " +
      Math.ceil(distance.calculateDistanceUnitFromKm(episode.limitValue, 0)) +
      " " +
      translatedKmh;
    episode.actualValueDescription = episode.maxValue
      ? Math.ceil(distance.calculateDistanceUnitFromKm(episode.maxValue, 0)) + " " + translatedKmh
      : "";
    episode.limitValueDescription = episode.limitValue
      ? Math.ceil(distance.calculateDistanceUnitFromKm(episode.limitValue, 0)) + " " + translatedKmh
      : "";
    episode.display = displaySpeeds;
    episode.markerColor = "#CF0136";
  }

  if (deviceepisodetype === 35) {
    episode.event = translatedSpeed;
    episode.icon = "fas fa-taxi";
    episode.limitValue = 130;
    episode.description =
      translatedSpeed +
      " > " +
      Math.ceil(distance.calculateDistanceUnitFromKm(episode.limitValue, 0)) +
      " " +
      translatedKmh;
    episode.actualValueDescription = episode.maxValue
      ? Math.ceil(distance.calculateDistanceUnitFromKm(episode.maxValue, 0)) + " " + translatedKmh
      : "";
    episode.limitValueDescription = episode.limitValue
      ? Math.ceil(distance.calculateDistanceUnitFromKm(episode.limitValue, 0)) + " " + translatedKmh
      : "";
    episode.display = displaySpeeds;
    episode.markerColor = "#CF0136";
  }

  if (deviceepisodetype === 36) {
    episode.event = translatedSpeed;
    episode.icon = "fas fa-taxi";
    episode.limitValue = 105;
    episode.description =
      translatedSpeed +
      " > " +
      Math.ceil(distance.calculateDistanceUnitFromKm(episode.limitValue, 0)) +
      " " +
      translatedKmh;
    episode.actualValueDescription = episode.maxValue
      ? Math.ceil(distance.calculateDistanceUnitFromKm(episode.maxValue, 0)) + " " + translatedKmh
      : "";
    episode.limitValueDescription = episode.limitValue
      ? Math.ceil(distance.calculateDistanceUnitFromKm(episode.limitValue, 0)) + " " + translatedKmh
      : "";
    episode.display = displaySpeeds;
    episode.markerColor = "#CF0136";
  }

  if (deviceepisodetype === 40) {
    episode.event = translatedRoadSpeed;
    episode.icon = "fas fa-thumbs-down";
    episode.description =
      translatedRoadSpeed +
      " : " +
      Math.ceil(distance.calculateDistanceUnitFromKm(episode.maxValue, 0)) +
      " " +
      translatedKmh;
    episode.description = episode.description;
    episode.actualValueDescription = episode.maxValue
      ? Math.ceil(distance.calculateDistanceUnitFromKm(episode.maxValue, 0)) + " " + translatedKmh
      : "";
    episode.limitValueDescription = episode.limitValue
      ? Math.ceil(distance.calculateDistanceUnitFromKm(episode.limitValue, 0)) + " " + translatedKmh
      : "";
    episode.display = displaySpeeds;
    episode.markerColor = "#CF0136";
  }

  if (deviceepisodetype === 110) {
    episode.icon = "fab fa-codepen";
    episode.description = translateService.instant("general.insideGeofenceTimeThresholdViolation");
  }

  if (deviceepisodetype === 111) {
    episode.icon = "fab fa-codepen";
    episode.description = translateService.instant("general.outsideGeofenceTimeThresholdViolation");
  } else if (deviceepisodetype === 112) {
    episode.icon = "fab fa-codepen";
    episode.description = translateService.instant("general.outsideAllGeofencesTimeThresholdViolation");
  } else if (deviceepisodetype === 120) {
    episode.icon = "fab fa-codepen";
    episode.description = translateService.instant("general.outsideAllGeofencesEventIgnitionOffViolation");
  } else if (deviceepisodetype === 121) {
    episode.icon = "fab fa-codepen";
    episode.description = translateService.instant("general.outsideAllGeofencesEventIgnitionOnViolation");
  } else if (deviceepisodetype === 122) {
    episode.icon = "fab fa-codepen";
    episode.description = translateService.instant("general.outsideAllGeofencesEventIdlingStartViolation");
  } else if (deviceepisodetype === 123) {
    episode.icon = "fab fa-codepen";
    episode.description = translateService.instant("general.outsideAllGeofencesEventIdlingEndViolation");
  } else if (deviceepisodetype === 124) {
    episode.icon = "fab fa-codepen";
    episode.description = translateService.instant("general.outsideAllGeofencesEventPowerOnViolation");
  } else if (deviceepisodetype === 125) {
    episode.icon = "fab fa-codepen";
    episode.description = translateService.instant("general.outsideAllGeofencesEventPowerOffViolation");
  } else if (deviceepisodetype === 130) {
    episode.icon = "fas fa-pause-circle";
    episode.description = translatedIdling + " " + translatedStart;

    // Set date to end
    episode.episodeEnd = episode.startUtc["tz"](timezoneIana).format("HH:mm");
    episode.date = episode.startUtc["tz"](timezoneIana);
    episode.footer = "";
    episode.duration = "-";
  } else if (deviceepisodetype === 131) {
    episode.icon = "fas fa-pause-circle";
    episode.header = episode.startUtc.format("HH:mm") + " -> " + episode.endUtc.format("HH:mm");
    episode.description = translatedIdling;

    // Set date to end
    // episode.episodeStart = episode.endUtc['tz'](timezoneIana).format('HH:mm');
    // episode.date = episode.endUtc['tz'](timezoneIana);
  } else if (deviceepisodetype === 150) {
    // ExternalPowerOff
    episode.icon = "fas fa-power-off";
    episode.description = translateService.instant("general.externalPowerOff");
  } else if (deviceepisodetype === 151) {
    // ExternalPowerOn
    episode.icon = "fas fa-power-off";
    episode.description = translateService.instant("general.externalPowerOn");
  } else if (deviceepisodetype === 152) {
    // Alert
    episode.icon = "fas fa-exclamation-circle";
    episode.description = translateService.instant("general.alert");
  } else if (deviceepisodetype === 153) {
    // Battery
    episode.icon = "fas fa-battery-empty";
    episode.description = translateService.instant("general.battery");
  } else if (deviceepisodetype === 154) {
    // PTO
    episode.icon = "fas fa-power-off";
    episode.description = translateService.instant("general.pto");

    if (diffInHours > 24) {
      episode.display = false;
    }

    if (episode.maxValue === 1) {
      episode.description = episode.description + " " + translateService.instant("general.on");
    }
    if (episode.maxValue === 0) {
      episode.description = episode.description + " " + translateService.instant("general.off");
    }
  } else if (deviceepisodetype === 155) {
    // Rfid
    episode.icon = "fas fa-barcode";
    episode.description = translateService.instant("general.rfid");
  } else if (deviceepisodetype === 156) {
    // UnknownRfid
    episode.icon = "fas fa-question-circle";
    episode.description = translateService.instant("general.unknownRfid");
  } else if (deviceepisodetype === 157) {
    // DoorSensor
    episode.icon = "fas fa-unlock";
    episode.description = translateService.instant("general.doorSensor");

    if (episode.maxValue === 1) {
      episode.description = episode.description + " " + translateService.instant("general.open");
      episode.icon = "fas fa-unlock";
    }
    if (episode.maxValue === 0) {
      episode.description = episode.description + " " + translateService.instant("general.closed");
      episode.icon = "fas fa-lock";
    }
  } else if (deviceepisodetype === 158) {
    // SecondaryDoorSensor
    episode.icon = "fas fa-unlock";
    episode.description = translateService.instant("general.secondaryDoorSensor");

    if (episode.maxValue === 1) {
      episode.description = episode.description + " " + translateService.instant("general.open");
      episode.icon = "fas fa-unlock";
    }
    if (episode.maxValue === 0) {
      episode.description = episode.description + " " + translateService.instant("general.closed");
      episode.icon = "fas fa-lock";
    }
  }

  // IO
  if (deviceepisodetype === 200) {
    episode.icon = "fas fa-power-off";
    episode.description = translateService.instant("general.ignitionActive");
    episode.markerColor = "#3BA850";
    episode.iconFontColor = "#3BA850";
    episode.display = false;
  }

  if (deviceepisodetype === 201) {
    episode.icon = "fas fa-power-off";
    episode.description = translateService.instant("general.ignitionInActive");
    episode.markerColor = "#a90000";
    episode.iconFontColor = "#a90000";
    episode.display = false;
  }

  if (deviceepisodetype === 202) {
    episode.icon = "fas fa-bolt";
    episode.description = translateService.instant("general.externalPowerActive");
    episode.markerColor = "#3BA850";
    episode.iconFontColor = "#3BA850";
  }

  if (deviceepisodetype === 203) {
    episode.icon = "fas fa-bolt";
    episode.description = translateService.instant("general.externalPowerInActive");
    episode.markerColor = "#a90000";
    episode.iconFontColor = "#a90000";
  }

  if (deviceepisodetype > 203 && deviceepisodetype < 220) {
    episode.icon = "fas fa-random";
    episode.description = translateService.instant("enums.deviceEpisode." + deviceepisodetype);
  }

  if (deviceepisodetype >= 220 && deviceepisodetype < 230) {
    episode.icon = "fas fa-random";
    episode.description = translateService.instant("enums.deviceEpisode." + deviceepisodetype);
  }

  if (deviceepisodetype >= 230 && deviceepisodetype < 300) {
    episode.icon = "fas fa-random";
    episode.description = translateService.instant("enums.deviceEpisode." + deviceepisodetype);
  }

  if (episode.event === undefined) {
    episode.event = episode.header;
  }

  return episode;
}
