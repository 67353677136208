import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AuditLog } from "../../models/auditlog.model";
import { AuditLogService } from "../../services/auditlog/auditlog.service";
import { AssetGroup } from "app/models/group.model";
import { AssetGroupsService } from "app/services/asset/assetGroups.service";

@Component({
  selector: "fh-asset-group-log",
  templateUrl: "assetGroupLog.template.html",
})
export class AssetGroupLogViewComponent implements OnInit {
  assetGroupLog: AuditLog[];
  assetGroup: AssetGroup;
  loading = false;

  permissionName = "FleetManagement_Log";
  constructorName = "AssetLogViewComponent";

  error: any;
  success: any;
  assetId: any;

  constructor(
    private auditLogService: AuditLogService,
    private assetGroupService: AssetGroupsService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.assetGroup = null;
    this.assetGroupLog = [];
  }

  ngOnInit() {
    this.loading = true;
    this.assetGroup = new AssetGroup();

    this.route.params.subscribe((params) => {
      const id = params["id"];

      this.assetId = id;
      this.assetGroupService.getAssetGroupById(id).subscribe((assetGroup) => {
        this.assetGroup = assetGroup;

        if (this.assetGroup == null) {
          this.router.navigate(["/AssetGroups/Overview"]);
        }

        this.auditLogService.getAuditLogByAssetGroup(id).subscribe({
          next: (deviceLog) => {
            console.log("loading done");
            this.assetGroupLog = deviceLog;
            this.loading = false;
          },
          error: (error) => {
            this.error = error;
            this.loading = false;
          },
        });
      });
    });
  }
}
