import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import "jquery-slimscroll";

import { colorArray2, getIconPath, roundAsString } from "app/common/globals";

import { Subscription } from "rxjs/internal/Subscription";
import { AuthenticationService } from "../../services/authentication/authentication.service";
import { AssetGroupsService } from "app/services/asset/assetGroups.service";
import { KeyValue } from "@angular/common";
import { TranslateService } from "@ngx-translate/core";
import { DriverService } from "app/services/driver/driver.service";
import { DriverGroupsService } from "app/services/driver/driverGroups.service";
import {
  type FleetOverviewModeCase,
  type FleetOverviewStateCase,
  FleetOverviewStoreService,
} from "app/services/fleetoverview/fleetoverview-store.service";
import { colorMapper } from "app/common/leafletGlobals";
import { AssetDisplayName, SortingOptionFleetOverview, StorageType } from "app/common/enums";
import { AppUser } from "app/models/user.model";

import { marker } from "leaflet";

import * as Moment from "moment";

import { Subject } from "rxjs";
import { debounceTime } from "rxjs/operators";
import { StorageHelper } from "app/common/storagehelper";
import { MapService } from "app/services/common/map.service";
import { UserService } from "app/services/users/user.service";

declare var L;
declare var $;

@Component({
  selector: "fh-cockpit-navigation",
  templateUrl: "cockpitNavigation.template.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CockpitNavigationComponent implements OnInit, AfterViewInit, OnDestroy {
  hasFuel: any;
  // Permissions
  hasReporting: any;
  hasCalculationSettings: any;
  hasLocations: any;
  hasTrips: any;
  hasAdvice: any;
  hasSchedule: any;
  hasSettings: any;
  hasAssets: any;
  hasDevices: any;
  hasCustomers: any;

  showId: any;
  id: number;
  private sub: Subscription;
  children = false;
  permissions: {};
  isImpersonated: boolean;

  colorArray: string[] = colorArray2;

  selectedDevice;
  filter = "";
  assetGroups: Map<any, any> = new Map();
  driverGroups: Map<any, any> = new Map();

  assetGroupSelection: Map<any, any> = new Map();
  driverGroupSelection: Map<any, any> = new Map();
  geofenceGroupSelection: Map<any, any> = new Map();
  deviceSelection: Map<any, any> = new Map();
  driverSelection: Map<any, any> = new Map();
  geofenceSelection: Map<any, any> = new Map();

  filterToggle = false;

  calculatedDeviceState: Map<number, any> = new Map<number, any>();
  lastCommunication: Map<number, string> = new Map<number, string>();
  lastStateUpdated: Map<number, string> = new Map<number, string>();
  address: Map<number, any> = new Map<number, any>();

  calculatedStateSum: Map<number, number> = new Map<number, number>();

  cachedTrips: Map<number, L.FeatureGroup<any>> = new Map<number, L.FeatureGroup<any>>();

  hiddenAssets: Map<number, boolean> = new Map<number, boolean>();
  hiddenGeofences: Map<number, boolean> = new Map<number, boolean>();
  liveHidden: Map<number, boolean> = new Map<number, boolean>();

  delay = 500;
  filterDebounce: Subscription;

  maxListItems = 60;

  maxGroups = 100;

  selectedHistoryDevice;
  selectedLiveGroup;
  mode: FleetOverviewModeCase = "Overview";
  tab = "Assets";
  history: Map<any, any> = new Map();

  live: Map<any, any> = new Map();

  currentDate: number;

  timezoneIana: string;

  fetchingStates = true;

  sidebarHidden = true;

  currentMarker = 0;

  user: AppUser;

  sortingOrder: SortingOptionFleetOverview = SortingOptionFleetOverview["Device Name"];

  accountId;

  debouncer = new Subject();

  driversMap;

  dropdownStates = [
    {
      state: 1,
      image: "fa-car",
      color: "green",
      count() {
        if (this.getDeviceCount(1) != this.calculatedStateSum.get(1)) {
          return `${this.getDeviceCount(1)}/${this.calculatedStateSum.get(1)}`;
        }

        return this.getDeviceCount(1);
      },
    },
    {
      state: 2,
      image: "fa-car",
      color: "red",
      count() {
        if (this.getDeviceCount(2) != this.calculatedStateSum.get(2)) {
          return `${this.getDeviceCount(2)}/${this.calculatedStateSum.get(2)}`;
        }

        return this.getDeviceCount(2);
      },
    },
    {
      state: 3,
      image: "fa-car",
      color: "orange",
      count() {
        if (this.getDeviceCount(3) != this.calculatedStateSum.get(3)) {
          return `${this.getDeviceCount(3)}/${this.calculatedStateSum.get(3)}`;
        }

        return this.getDeviceCount(3);
      },
    },
    {
      state: 4,
      image: "fa-car",
      color: "blue",
      count() {
        if (this.getDeviceCount(4) != this.calculatedStateSum.get(4)) {
          return `${this.getDeviceCount(4)}/${this.calculatedStateSum.get(4)}`;
        }

        return this.getDeviceCount(4);
      },
    },
    {
      state: 5,
      image: "fa-car",
      color: "black",
      count() {
        if (this.getDeviceCount(5) != this.calculatedStateSum.get(5)) {
          return `${this.getDeviceCount(5)}/${this.calculatedStateSum.get(5)}`;
        }

        return this.getDeviceCount(5);
      },
    },
    {
      state: 0,
      image: "fa-car",
      color: "grey",
      count() {
        return this.calculatedStateSum.get(0);
      },
    },
  ];

  // Temp map properties
  theMarker: any;
  myMovingMarker: any;
  stopMarker: any;
  theGeofence: any;

  storageType = StorageType.LocalStorage;
  settings = [];

  foSettings = [
    { name: "showSidebarLocationUpdated", value: null },
    { name: "showSidebarStateUpdated", value: null },
    { name: "showSidebarDriverName", value: null },
    { name: "showSidebarCity", value: null },
    { name: "showSidebarGeofence", value: null },
    { name: "includeArchivedGroups", value: null },
  ];

  get state(): FleetOverviewStateCase {
    return this.fleetOverviewStoreService.fleetOverviewState;
  }

  constructor(
    private cd: ChangeDetectorRef,
    private router: Router,
    private route: ActivatedRoute,
    private userService: UserService,
    private mapService: MapService,
    private assetGroupService: AssetGroupsService,
    private driverService: DriverService,
    private driverGroupService: DriverGroupsService,
    private fleetOverviewStoreService: FleetOverviewStoreService,
    private translateService: TranslateService,
    private authenticationService: AuthenticationService,
    private storageHelper: StorageHelper
  ) {
    this.id = 0;
    this.showId = "";

    this.timezoneIana = this.authenticationService.getTimeZoneIana();
  }

  ngAfterViewInit() {
    if ($("body").hasClass("fixed-sidebar")) {
      $(".sidebar-collapse")["slimscroll"]({
        height: "100%",
      });
    }
  }

  onSearchChanged(value) {
    this.debouncer.next(value);
  }

  ngOnInit() {
    const that = this;

    this.debouncer.pipe(debounceTime(this.delay)).subscribe((val) => {
      console.log("Filtering markers for " + this.filter);
      this.filterMarkers();
    });

    this.userService.getUserById(this.authenticationService.getUserId()).subscribe((user) => {
      this.user = user;
    });

    this.storageHelper
      .loadStoreState(StorageType.LocalStorage, "settings_", "sortingOrderFleetOverview")
      .subscribe((value) => {
        this.sortingOrder = SortingOptionFleetOverview["Device Name"];

        if (value !== null) {
          this.sortingOrder = +value;
        }
      });

    this.foSettings.forEach((setting) => {
      this.storageHelper.loadStoreState(this.storageType, "settings_", setting.name).subscribe((result) => {
        this.settings[setting.name] = JSON.parse(result) === true;
      });
    });

    // jQuery('body').addClass('mini-navbar');
    this.isImpersonated = this.authenticationService.getIsImpersonated();

    this.accountId = this.authenticationService.getAccountId();

    this.permissions = this.authenticationService.permissions;

    this.translateService.get("general.date").subscribe({
      next: (_) => {
        this.cd.detach();

        this.fleetOverviewStoreService.deviceState$.subscribe((states) => {
          const newStates = new Map<number, any>();
          const deviceStateValues = [0, 0, 0, 0, 0, 0];

          for (const [id, state] of states) {
            newStates.set(id, {
              id: state,
              get color() {
                return colorMapper(this.id);
              },
            });

            deviceStateValues[state]++;
          }

          this.calculatedStateSum.set(0, deviceStateValues[0]);
          this.calculatedStateSum.set(1, deviceStateValues[1]);
          this.calculatedStateSum.set(2, deviceStateValues[2]);
          this.calculatedStateSum.set(3, deviceStateValues[3]);
          this.calculatedStateSum.set(4, deviceStateValues[4]);
          this.calculatedStateSum.set(5, deviceStateValues[5]);
          this.calculatedStateSum.set(6, deviceStateValues[6]);

          this.calculatedDeviceState = newStates;
          this.cd.detectChanges();
        });

        this.fleetOverviewStoreService.lastDeviceStates$.subscribe((states) => {
          console.log("FO: Handle device subscription.");

          for (const state of states) {
            if (state.currentAddress) {
              this.address.set(+state.id, {
                address: state.currentAddress.address,
                city: state.currentAddress.city,
                country: state.currentAddress.country,
              });
            }
          }

          this.driversMap = this.fleetOverviewStoreService.driversMap;

          this.cd.detectChanges();
        });

        this.fleetOverviewStoreService.lastCommunication$.subscribe((states) => {
          this.lastCommunication = states;
          this.cd.detectChanges();
        });

        this.fleetOverviewStoreService.lastStateUpdated$.subscribe((states) => {
          this.lastStateUpdated = states;
          this.cd.detectChanges();
        });

        this.fleetOverviewStoreService.fetchingStates$.subscribe((fetchingStates) => {
          this.fetchingStates = fetchingStates;
          this.cd.detectChanges();
        });

        this.driverGroupService.getDriverGroups(this.accountId, false).subscribe((driverGroups) => {
          for (const driverGroup of driverGroups) {
            const name = driverGroup.displayName + " - " + driverGroup.companyName;
            this.driverGroups.set(driverGroup.id, {
              _order: name,
              filterClose: false,
              name,
              items: [],
              open: false,
            });
          }

          this.driverService.getDriversByAccount(this.accountId, true, true).subscribe((drivers) => {
            for (const driver of drivers) {
              this.fleetOverviewStoreService.driversMap.set(driver.id, {
                name: driver.name,
                phone: driver.mobilePhone,
                tag: driver.tag,
              });

              for (const { id } of driver.driverGroups) {
                const driverGroup = this.driverGroups.get(+id);
                if (driverGroup !== undefined) {
                  driverGroup.items.push({
                    _search: driverGroup.name,
                    id: driver.id,
                    name: driver.name,
                    get assetName() {
                      return that.fleetOverviewStoreService.driverAssetsMap.get(driver.id);
                    },
                  });
                }
              }
            }
          });
        });

        this.fleetOverviewStoreService.fleetOverviewState$.subscribe((__) => {
          this.cd.detectChanges();
        });

        this.fleetOverviewStoreService.clearTrips$.subscribe(() => {
          for (const [__, item] of this.history) {
            try {
              for (const trip of item.trips) {
                delete trip.plotted;
                delete trip.locations;
              }
            } finally {
              continue;
            }
          }

          this.cachedTrips.clear();
          this.cd.detectChanges();
        });

        this.assetGroupService.getAssetGroups(this.accountId, true).subscribe((assetGroups) => {
          for (const assetGroup of assetGroups) {
            if (assetGroup.assetGroupItems.length === 0) {
              continue;
            }

            if (!this.settings["includeArchivedGroups"] && assetGroup?.groupType == "15") {
              continue;
            }

            let assetGroupName = assetGroup.displayName;

            if (assetGroup.accountId !== +this.accountId) {
              assetGroupName = assetGroup.displayName + " - " + assetGroup.companyName;
            }

            const that = this;

            const groupItems = [];
            for (const groupItem of assetGroup.assetGroupItems) {
              let assetDisplayLabel: string;

              if (this.user?.assetDisplayName === undefined) {
                console.error("User was empty, setting default");
                this.user = new AppUser();
              }

              switch (this.user.assetDisplayName) {
                case AssetDisplayName["Asset Code"]:
                  assetDisplayLabel = `${groupItem.assetCode || groupItem.assetName}`;
                  break;
                case AssetDisplayName["Plate Number"]:
                  assetDisplayLabel = `${groupItem.plateNumber || groupItem.assetName}`;
                  break;
                case AssetDisplayName["Device Name"]:
                  assetDisplayLabel = `${groupItem.assetName ?? (groupItem.assetCode || groupItem.assetName)}`;
                  break;
                case AssetDisplayName["Client Handle: Asset Code"]:
                  assetDisplayLabel = `${groupItem.assetCompanyName}: ${groupItem.assetCode || groupItem.assetName}`;
                  break;
                case AssetDisplayName["Client Handle: Plate Number"]:
                  assetDisplayLabel = `${groupItem.assetCompanyName}: ${groupItem.plateNumber || groupItem.assetName}`;
                  break;
                case AssetDisplayName["Client Handle: Device Name"]:
                  assetDisplayLabel = `${groupItem.assetCompanyName}: ${groupItem.assetName}`;
                  break;
                default:
                  assetDisplayLabel = `${groupItem.assetName ?? (groupItem.assetCode || groupItem.assetName)}`;
                  break;
              }

              var isArchived = assetGroupName.indexOf("Archived") > -1;

              if (!isArchived) {
                this.fleetOverviewStoreService.driverBindings.set(groupItem.deviceId, () =>
                  this.fleetOverviewStoreService.driversMap.get(groupItem.driverId)
                );
                this.fleetOverviewStoreService.assetDriverBindings.set(groupItem.deviceId, groupItem.driverId);
              }

              groupItems.push({
                get _search() {
                  var driverId = that.fleetOverviewStoreService.assetDriverBindings.get(this.id);
                  var driver = that.fleetOverviewStoreService.driversMap.get(driverId);
                  return assetGroup.displayName + (driver ? driver["name"] : "");
                },
                get status() {
                  return that.calculatedDeviceState.get(this.id)?.id;
                },
                get time() {
                  return Moment.utc(+that.lastCommunication.get(this.id)).tz(that.timezoneIana);
                },
                get address() {
                  var theAddress = that.address.get(this.id);
                  var returnAddress = [];

                  if (theAddress && theAddress.address) {
                    returnAddress.push(theAddress.address);
                  }

                  if (theAddress && theAddress.city) {
                    returnAddress.push(theAddress.city);
                  }

                  if (theAddress && theAddress.country) {
                    returnAddress.push(theAddress.country);
                  }
                  return returnAddress.join(", ");
                },
                get _order() {
                  switch (that.sortingOrder) {
                    case SortingOptionFleetOverview["Device Name"]:
                      return groupItem.assetName;
                    case SortingOptionFleetOverview["Asset Code"]:
                      return groupItem.assetCode;
                    case SortingOptionFleetOverview["Plate Number"]:
                      if (groupItem.plateNumber === "") {
                        return null;
                      }

                      return groupItem.plateNumber;
                    case SortingOptionFleetOverview["Location Updated"]:
                      const difference =
                        +new Date() - +Moment.utc(+that.lastCommunication.get(this.id)).tz(that.timezoneIana);

                      if (Number.isNaN(difference)) {
                        return +new Date();
                      }

                      return difference;
                    case SortingOptionFleetOverview["State Updated"]:
                      const stateDifference =
                        +new Date() - +Moment.utc(+that.lastStateUpdated.get(this.id)).tz(that.timezoneIana);

                      if (Number.isNaN(stateDifference)) {
                        return +new Date();
                      }

                      return stateDifference;
                    case SortingOptionFleetOverview["Driver Name"]:
                      var driverId = that.fleetOverviewStoreService.assetDriverBindings.get(this.id);
                      var driver = that.fleetOverviewStoreService.driversMap.get(driverId);
                      return driver ? driver["name"] : "zzz";
                    case SortingOptionFleetOverview["Display Name"]:
                    default:
                      return assetDisplayLabel;
                  }
                },
                id: groupItem.deviceId,
                name: assetDisplayLabel,
                get displayLabel() {
                  let infoDisplayLabel = [];

                  if (that.settings["showSidebarLocationUpdated"]) {
                    var time = Moment.utc(+that.lastCommunication.get(this.id)).tz(that.timezoneIana).fromNow();
                    if (time) {
                      infoDisplayLabel.push(time);
                    }
                  }

                  if (that.settings["showSidebarStateUpdated"]) {
                    var time2 = Moment.utc(+that.lastStateUpdated.get(this.id)).tz(that.timezoneIana).fromNow();
                    if (time2) {
                      infoDisplayLabel.push(time2);
                    }
                  }

                  if (that.settings["showSidebarDriverName"]) {
                    var driverId = that.fleetOverviewStoreService.assetDriverBindings.get(this.id);
                    var driver = that.fleetOverviewStoreService.driversMap.get(driverId);
                    if (driver) {
                      infoDisplayLabel.push(driver["name"]);
                    }
                  }

                  if (that.settings["showSidebarGeofence"]) {
                    var geofences =
                      that.fleetOverviewStoreService.insideGeofencesByDeviceMap.get(this.id.toString()) ?? [];
                    if (geofences) {
                      geofences.forEach((geofenceId) => {
                        const geofence = that.fleetOverviewStoreService.geofencesMap.get(+geofenceId);
                        if (geofence) {
                          infoDisplayLabel.push(geofence.name);
                        }
                      });
                    }
                  }

                  if (that.settings["showSidebarCity"]) {
                    var theAddress = that.address.get(this.id);
                    var returnAddress = [];

                    if (theAddress && theAddress.address) {
                      returnAddress.push(theAddress.address);
                    }

                    if (theAddress && theAddress.city) {
                      returnAddress.push(theAddress.city);
                    }

                    if (theAddress && theAddress.country) {
                      returnAddress.push(theAddress.country);
                    }
                    var address = returnAddress.join(", ");
                    if (address) {
                      infoDisplayLabel.push(address);
                    }
                  }

                  // Set default sidebar info display
                  if (infoDisplayLabel.length == 0) {
                    var time2 = Moment.utc(+that.lastStateUpdated.get(this.id)).tz(that.timezoneIana).fromNow();
                    if (time2) {
                      infoDisplayLabel.push(time2);
                    }
                  }

                  return infoDisplayLabel.join(", ");
                },
                driverId: groupItem.driverId,
                iconId: groupItem.iconId,
                // get geofenceList() {  },
                get driverName() {
                  return that.fleetOverviewStoreService.driversMap.get(groupItem.driverId);
                },
                get hidden() {
                  return that.filterSelection.get(that.calculatedDeviceState.get(this.id)?.id ?? 0) === true;
                },
              });

              if (groupItem.driverId) {
                let previousValue = that.fleetOverviewStoreService.driverAssetsMap.get(groupItem.driverId);

                // If not exists in list
                if (!(previousValue?.indexOf(groupItem.assetName) > -1)) {
                  previousValue = (previousValue ? previousValue + ", " : "") + groupItem.assetName;
                  this.fleetOverviewStoreService.driverAssetsMap.set(groupItem.driverId, previousValue);
                }
              }
            }

            this.assetGroups.set(assetGroup.id, {
              _order: assetGroupName?.trim(),
              filterClose: false,
              name: assetGroupName,
              items: groupItems,
              open: false,
            });
          }

          this.cd.detectChanges();
        });
      },
      error: (error) => {
        console.log(error);
        this.cd.detach();
      },
    });

    if (this.route.children.length > 0) {
      this.children = true;

      this.sub = this.route.children[0].params.subscribe((params) => {
        this.id = params["id"];
        this.showId = this.id ? this.id.toString().substring(0, 4) : "";
        this.cd.detectChanges();
      });
    }
  }

  changeTab(updatedTab: string) {
    this.tab = updatedTab;

    switch (this.tab) {
      case "Assets":
        // this.getHistory();
        break;
      default:
        break;
    }

    this.cd.detectChanges();
  }

  valueAscOrder = (
    a: KeyValue<number, { [id: string]: string }>,
    b: KeyValue<number, { [id: string]: string }>
  ): number => {
    return a.value._order.localeCompare(b.value._order);
  };

  selectAsset(device) {
    this.selectedDevice = device.name;

    this.cd.detectChanges();

    window.dispatchEvent(new CustomEvent("selectDeviceFromSidebar", { detail: device.id, bubbles: true }));
  }

  openGroup(group) {
    if (!(group.value.items?.length > 0)) {
      return;
    }

    if (this.filter.length > 0) {
      group.value.filterClose = group.value.filterClose !== true;
      group.value.open = false;

      this.cd.detectChanges();
      return;
    }

    group.value.open = group.value.open !== true;
    this.cd.detectChanges();
  }

  activeRoute(routename: string): boolean {
    return this.router.url.indexOf(routename) === 1;
  }

  checkForUpdates() {
    this.cd.detectChanges();
  }

  watchGroupSelected(collection: Map<number, any>, items: any[]) {
    for (const item of items) {
      if (collection.get(item.id) === undefined || collection.get(item.id).checked === false) {
        return false;
      }
    }

    return true;
  }

  watchGroupVisibility(collection: Map<number, boolean>, items: any[]) {
    for (const item of items) {
      if (collection.get(item.id) === true) {
        return false;
      }
    }

    return true;
  }

  changeGroupVisibility(collection: Map<number, boolean>, state: boolean, items: any[]) {
    for (const item of items) {
      collection.set(item.id, !state);
    }

    this.fleetOverviewStoreService.hiddenAssets.next(collection);
    this.cd.detectChanges();
  }

  changeGeofenceGroupVisibility(collection: Map<number, boolean>, state: boolean, items: any[]) {
    for (const item of items) {
      collection.set(item.id, !state);
    }

    this.fleetOverviewStoreService.hiddenGeofences.next(collection);
    this.cd.detectChanges();
  }

  changeVisibility(collection: Map<number, boolean>, id: number) {
    collection.set(id, !collection.get(id));

    this.fleetOverviewStoreService.hiddenAssets.next(collection);
    this.cd.detectChanges();
  }

  changeGeofenceVisibility(collection: Map<number, boolean>, id: number) {
    collection.set(id, !collection.get(id));

    this.fleetOverviewStoreService.hiddenGeofences.next(collection);
    this.cd.detectChanges();
  }

  hasAnySelected(selected: Map<any, any>[]) {
    for (const map of selected) {
      for (const [_, predicate] of map) {
        if (predicate !== true && predicate?.checked !== true) {
          continue;
        }

        return true;
      }
    }

    return false;
  }

  updateCheckbox($event, driverSelection, driver) {
    driverSelection.set(driver.id, { checked: $event, name: driver.name });
    this.cd.detectChanges();
  }

  filterMarkers() {
    if (this.tab !== "Assets") {
      this.cd.detectChanges();
      return;
    }

    this.fleetOverviewStoreService.searchFilter.next(this.filter);
    this.cd.detectChanges();
  }

  openLiveTab(liveGroup) {
    if (this.filter.length > 0) {
      liveGroup.value.filterClose = liveGroup.value.filterClose !== true;
      // liveGroup.value.open = false;
      this.selectedLiveGroup = undefined;
      this.cd.detectChanges();
      return;
    }

    this.selectedLiveGroup = this.selectedLiveGroup !== liveGroup.key && liveGroup.key;
    this.cd.detectChanges();
  }

  actualRound(value, decimals) {
    return roundAsString(value, decimals);
  }

  getFleetOverviewMode() {
    return this.fleetOverviewStoreService.fleetOverviewMode$;
  }

  extendFilterMap<T>(map: T): T {
    this.currentMarker = 0;
    return map;
  }

  increaseViewCount() {
    this.currentMarker += 1;
    return true;
  }

  toggleAssetStatus() {
    this.filterToggle = this.filterToggle !== true;
    this.cd.detectChanges();
  }

  getDeviceCount(index: number) {
    return window["deviceCounts"][index % 6];
  }

  get filterSelection() {
    return this.fleetOverviewStoreService.stateFilter.getValue();
  }

  get geofenceGroups() {
    return this.fleetOverviewStoreService.geofencesGroupsMap;
  }

  get insideGeofences() {
    return this.fleetOverviewStoreService.insideGeofencesMap;
  }

  emptyGroups(groups: Map<any, any>) {
    return !Array.from(groups.values()).find((x) => x.items?.length > 0);
  }

  visibilityAssetStatus(event, key) {
    this.filterSelection.set(key, event.target.checked !== true);
    this.fleetOverviewStoreService.stateFilter.next(this.filterSelection);

    let reset = "NULL";
    [reset, this.filter] = [this.filter, reset];
    this.cd.detectChanges();

    [reset, this.filter] = [this.filter, reset];
    this.cd.detectChanges();
  }

  toggleSidebar() {
    jQuery("body").removeClass("mini-navbar");

    this.sidebarHidden = this.sidebarHidden === false;
    this.cd.detectChanges();
  }

  log(e) {
    console.log(e);
  }

  // Play trips
  playTrip(trip) {
    this.fleetOverviewStoreService.playTrip.next([trip.assetId, trip.locations]);
  }

  // display episodes

  clearLocation() {
    const mapReferece = this.mapService.leafletMapComponent;

    if (this.theMarker) {
      mapReferece.removeLayer(this.theMarker);
    }

    if (this.myMovingMarker) {
      mapReferece.removeLayer(this.myMovingMarker);
    }

    if (this.stopMarker) {
      mapReferece.removeLayer(this.stopMarker);
    }
  }

  displayLocation(asset, location, geofence = null, episode = null) {
    const mapReference = this.mapService.leafletMapComponent;

    const iconPath = getIconPath(asset?.value?.iconId)[1];

    const movingMarkerIcon = L.icon({
      iconUrl: iconPath,
      // className: 'markerPlayTrip',
      iconAnchor: [16, 16],
    });

    // beginLongitude
    this.clearLocation();

    if (location != null && geofence == null) {
      let theEpisodeIcon = null;

      if (episode) {
        theEpisodeIcon = L["StatusMarker"].icon({
          // iconUrl: iconPath,
          icon: episode.icon,
          markerColor: episode.markerColor,
          rotate: 0,
          shape: "circle",
          prefix: "fas",
        });
      }

      this.theMarker = marker(location, {
        icon: episode ? theEpisodeIcon : movingMarkerIcon,
      }).addTo(mapReference);
      mapReference.setView(location, 15);
    }
  }

  // Destroy component
  ngOnDestroy() {
    this.fleetOverviewStoreService.fleetOverviewState = "Initialize";

    if (this.children) {
      this.sub.unsubscribe();
    }

    if (this.filterDebounce) {
      this.filterDebounce.unsubscribe();
    }
  }
}
