import {
  Component,
  Input,
  OnInit,
  ChangeDetectorRef,
  OnChanges,
  Output,
  EventEmitter,
  SimpleChanges,
} from "@angular/core";
import { ControlContainer, NgForm } from "@angular/forms";
import { FormMode, VehicleType } from "app/common/enums";
import { Asset } from "app/models/asset.model";
import { BsDatepickerConfig } from "ngx-bootstrap/datepicker";
import { AuthenticationService } from "app/services/authentication/authentication.service";
import { AssetService } from "app/services/asset/asset.service";

@Component({
  selector: "fh-asset-details",
  templateUrl: "assetDetails.component.html",
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class AssetDetailsComponent implements OnInit, OnChanges {
  @Input() asset: Asset;
  @Input() loading = false;
  @Input() formMode: any = FormMode.read;
  @Output() onAccountChanged = new EventEmitter();

  vehicleTypes: { id: string; name: any }[] = [];

  dmvMessage: any;
  loadingDmv = false;

  // Datepicker
  public dpConfig: Partial<BsDatepickerConfig> = new BsDatepickerConfig();
  permissions: {};

  constructor(
    private cd: ChangeDetectorRef,
    private authenticationService: AuthenticationService,
    private assetService: AssetService
  ) {
    this.permissions = this.authenticationService.permissions;

    // Datepicker
    this.dpConfig.containerClass = "theme-default"; // or whatever color
    this.dpConfig.dateInputFormat = "lll";
    this.dpConfig.isAnimated = false;
    this.dpConfig.withTimepicker = true;
    this.dpConfig.keepDatepickerOpened = true;
  }

  ngOnInit(): void {
    this.vehicleTypes = Object.keys(VehicleType)
      .filter((k) => typeof VehicleType[k] === "string")
      .map((n) => ({ id: n.toString(), name: VehicleType[n] }));
  }

  accountChanged(accountId) {
    this.onAccountChanged.emit(this.asset.accountId);
    this.asset.assetTypeId = null;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.asset !== undefined && this.asset.accountId !== undefined && this.asset.accountId === 0) {
      console.log("Setting to undefined");
      this.asset.accountId = undefined;
    }
  }

  getInformationDMV(plateOrVin: string) {
    this.loadingDmv = true;

    this.assetService.getInformationDMV(plateOrVin, this.asset.resellerId).subscribe({
      next: (dmvAsset) => {
        console.log(dmvAsset);

        if (dmvAsset.brand != null) {
          this.asset.brand = dmvAsset.brand;
          this.asset.model = dmvAsset.model;
          this.asset.color = dmvAsset.color;
          this.asset.year = dmvAsset.year;
          this.asset.vehicleType = dmvAsset.vehicleType.toString();
          this.asset.safetyCertificateExpirationDate = dmvAsset.safetyCertificateExpirationDate;
          this.asset.dateAcquired = dmvAsset.dateAcquired;
          this.asset.clientAssetCategory = dmvAsset.clientAssetCategory;
          this.asset.purchasePrice = dmvAsset.purchasePrice;
          this.asset.fuelType = dmvAsset.fuelType;

          this.asset.emissionCO2 = dmvAsset.emissionCO2;
          this.asset.emissionParticlesLight = dmvAsset.emissionParticlesLight;
          this.asset.emissionParticlesHeavy = dmvAsset.emissionParticlesHeavy;
          this.asset.urbanConsumption = dmvAsset.urbanConsumption;
          this.asset.extraUrbanConsumption = dmvAsset.extraUrbanConsumption;
          this.asset.mixedConsumption = dmvAsset.mixedConsumption;
        }

        this.loadingDmv = false;
        // this.dmvMessage = dmvAsset.message;
        this.cd.markForCheck();
      },
      error: (error) => {
        this.loadingDmv = false;
        this.dmvMessage = error.message;
        this.cd.markForCheck();
      },
    });
  }
}
