import { ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { forkJoin } from "rxjs";
import { AuthenticationService } from "app/services/authentication/authentication.service";
import { DeviceType } from "app/models/devicetype.model";
import { DeviceTypeService } from "app/services/devicetypes/devicetypes.service";

import { GridBase360Directive } from "app/common/360Grid.base";
import { getDefaultDpConfig, getGridButtons, getGridLanguages } from "app/common/gridhelper";
import { BsDaterangepickerConfig } from "ngx-bootstrap/datepicker";

// Moment
import Moment from "moment-timezone";
import { roundAsNumber } from "app/common/globals";
import { DashboardService } from "app/services/dashboard/dashboard.service";
import { StorageHelper } from "app/common/storagehelper";
import { DistanceUnitService } from "app/common/distanceunit.service";
import { ColorService } from "app/services/common/color.service";

window["moment"] = Moment;

@Component({
  selector: "fh-issues-latency",
  templateUrl: "latency.component.html",
})
export class IssuesLatencyViewComponent extends GridBase360Directive implements OnDestroy, OnInit {
  loading = false;

  timezoneIana: string;

  languageLoaded = false;
  episodesLoaded = false;

  deviceTypes: DeviceType[];

  randomKey: number;

  permissionName = "FleetManagement_Issues";
  constructorName = "IssuesLatencyViewComponent";

  error;
  success;

  // Daterange
  public dpConfig: Partial<BsDaterangepickerConfig> = new BsDaterangepickerConfig();
  daterangepickerModel: Date[];

  constructor(
    private dashboardService: DashboardService,
    private distance: DistanceUnitService,
    private translateService: TranslateService,
    private authenticationService: AuthenticationService,
    private deviceTypeService: DeviceTypeService,
    private cd: ChangeDetectorRef,
    protected storageHelper: StorageHelper,
    private colorService: ColorService
  ) {
    super(storageHelper);

    this.timezoneIana = this.authenticationService.getTimeZoneIana();

    this.daterangepickerModel = [
      Moment().tz(this.timezoneIana).subtract(7, "days").startOf("day").toDate(),
      Moment().tz(this.timezoneIana).endOf("day").toDate(),
    ];

    this.dpConfig = getDefaultDpConfig(this.authenticationService);

    this.randomKey = Math.floor(Math.random() * (999999 - 100000)) + 100000;

    // Get all the date for dropdown boxes
    forkJoin([this.translateService.get("general.date"), this.deviceTypeService.getDeviceTypes()]).subscribe({
      next: (data) => {
        this.languageLoaded = true;
        this.loading = false;
        this.cd.markForCheck();

        this.deviceTypes = data[1].filter((x) => x.deviceCount > 0);
        this.deviceTypes = this.deviceTypes.sort((a, b) => (a.modelName > b.modelName ? 1 : -1));

        this.initGrid();
      },
      error: (err) => {
        this.error = err;
        this.languageLoaded = true;
        this.loading = false;
        this.cd.markForCheck();
      },
    });
  }

  ngOnInit(): void {}

  dateChanged(event) {
    if (event !== null) {
      this.episodesLoaded = true;

      this.datatableElement?.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.ajax
          .url(
            this.dashboardService.getIssuesLatencyUrl(
              this.randomKey,
              Moment.utc(this.daterangepickerModel[0]).tz(this.timezoneIana).startOf("day"),
              Moment.utc(this.daterangepickerModel[1]).tz(this.timezoneIana).endOf("day")
            )
          )
          .load(() => (this.episodesLoaded = false));
      });
    }
  }

  initGrid(): void {
    const commonExportOptions = {
      modifier: {
        page: "all",
        search: "none",
      },
      columns: ["id_export:name", ":visible[tabindex]"],
    };

    const deviceTypeOptions = [];
    this.deviceTypes.forEach((item, index) => {
      if (item.modelName !== "") {
        deviceTypeOptions.push({ id: item.modelName, value: item.modelName + " (" + item.deviceCount + ")" });
      }
    });

    this.columns = [
      {
        name: "deviceId",
        data: "deviceId",
        orderable: false,
        title: '<div class="hideDropdown"></div>',
        width: "20",
        render: (data, type, row) => {
          const generalDetails = this.translateService.instant("general.details");

          return `
            <a class='btn btn-primary btn-grid' title='${generalDetails}' href='/#/DeviceDetails/Index/${data}'>
              <span class="d-none d-md-inline-flex" style="padding-left: 7px">${generalDetails}</span>
              <i class='fas fa-fw fa-angle-right'></i>
            </a>`;
        },
      },
      {
        name: "name",
        data: "name",
        title: this.translateService.instant("general.name"),
        render: (data, type, row) => {
          return data ?? "";
        },
      },
      {
        name: "unitId",
        data: "unitId",
        title: this.translateService.instant("general.unitId"),
        visible: true,
      },
      {
        name: "deviceTypeName",
        data: "deviceTypeName",
        defaultContent: "-",
        iconName: "fas fa-fw fa-router",
        type: "select",
        options: deviceTypeOptions.sort((a, b) => a.value.localeCompare(b.value)),
        title: this.translateService.instant("general.devicetype"),
      },
      {
        name: "manufacturer",
        data: "manufacturer",
        defaultContent: "-",
        title: this.translateService.instant("general.manufacturer"),
        visible: false,
        render: (data, type, row) => {
          return data ? data : "-";
        },
      },
      {
        name: "issueType",
        data: "issueType",
        title: this.translateService.instant("general.issueType"),
        iconName: "fas fa-fw fa-exclamation-triangle",
        type: "select",
        options: [
          {
            group: this.translateService.instant("general.issueGroup_Delay"),
            items: [
              { id: "DelayMoreThan10", value: this.translateService.instant("enums.issues.14") },
              { id: "DelayMoreThan60", value: this.translateService.instant("enums.issues.15") },
              { id: "DelayMoreThan240", value: this.translateService.instant("enums.issues.16") },
              { id: "DelayMoreThan960", value: this.translateService.instant("enums.issues.17") },
            ],
          },
        ],
        render: (data, type, row) => {
          return this.translateService.instant("enums.issues." + data);
        },
      },
      {
        name: "code",
        data: "code",
        visible: false,
        title: this.translateService.instant("general.code"),
        render: (data, type, row) => {
          return data ?? "";
        },
      },
      {
        name: "brand",
        data: "brand",
        visible: false,
        title: this.translateService.instant("general.brand"),
        render: (data, type, row) => {
          return data ?? "";
        },
      },
      {
        name: "model",
        data: "model",
        visible: false,
        title: this.translateService.instant("general.model"),
        render: (data, type, row) => {
          return data ?? "";
        },
      },
      {
        name: "beginDate",
        data: "beginDate",
        title: this.translateService.instant("general.startDate"),
        render: (data, type, row) => {
          const date = Moment.utc(data)["tz"](this.timezoneIana);
          return data ? "<span>" + date.format("ll") + "</span>" : "";
        },
        visible: false,
      },
      {
        name: "endDate",
        data: "endDate",
        title: this.translateService.instant("general.endDate"),
        render: (data, type, row) => {
          const date = Moment.utc(data)["tz"](this.timezoneIana);
          return data ? "<span>" + date.format("ll") + "</span>" : "";
        },
        visible: false,
      },
      {
        name: "tripCount",
        data: "tripCount",
        type: "num",
        title: this.translateService.instant("general.tripCount"),
        render: (data, type, row) => {
          return data ?? 0;
        },
      },
      {
        name: "messageCount",
        data: "messageCount",
        type: "num",
        title: this.translateService.instant("general.messageCount"),
        render: (data, type, row) => {
          return data ?? 0;
        },
      },
      {
        name: "tripMessageCount",
        data: "tripMessageCount",
        type: "num",
        title: this.translateService.instant("general.tripMessageCount"),
        render: (data, type, row) => {
          return data ?? 0;
        },
      },
      {
        name: "stopMessageCount",
        data: "stopMessageCount",
        type: "num",
        title: this.translateService.instant("general.stopMessageCount"),
        render: (data, type, row) => {
          return data ?? 0;
        },
      },
      {
        name: "ignitionCount",
        data: "ignitionCount",
        type: "num",
        title: this.translateService.instant("general.ignitionCount"),
        render: (data, type, row) => {
          return data ?? 0;
        },
      },
      {
        name: "speedCount",
        data: "speedCount",
        type: "num",
        title: this.translateService.instant("general.speedCount"),
        render: (data, type, row) => {
          return data ?? 0;
        },
      },
      {
        name: "uniqueCoordPercentage",
        data: "uniqueCoordPercentage",
        type: "num",
        title: this.translateService.instant("general.uniqueCoordPercentage"),
        render: (data, type, row) => {
          return data ? roundAsNumber(data, 0).toLocaleString() + " %" : 0;
        },
      },
      {
        name: "badTrips",
        data: "badTrips",
        type: "num",
        title: this.translateService.instant("general.badTrips"),
        render: (data, type, row) => {
          return data ?? 0;
        },
      },
      {
        name: "eventCount",
        data: "eventCount",
        type: "num",
        title: this.translateService.instant("general.eventCount"),
        render: (data, type, row) => {
          return data ?? 0;
        },
      },
      {
        name: "distanceInMeters",
        data: "distanceInMeters",
        type: "num",
        title: this.translateService.instant("general.distance"),
        render: (data, type, row) => {
          return data && data > 0.1
            ? `${roundAsNumber(data / 1000, 1).toLocaleString()} ${this.translateService.instant(
                this.distance.getDistanceUnit()
              )}`
            : 0;
        },
      },
      {
        name: "companyName",
        data: "companyName",
        defaultContent: "-",
        title: this.translateService.instant("general.companyName"),
      },
      {
        name: "resellerDescription",
        data: "resellerDescription",
        defaultContent: "-",
        title: this.translateService.instant("general.resellerDescription"),
        visible: false,
      },
      {
        name: "delayMoreThan10",
        data: "delayMoreThan10",
        type: "num",
        title: this.translateService.instant("general.delayMoreThan10"),
        render: (data, type, row) => {
          const totalDelay = row.delayMoreThan10 + row.delayMoreThan60 + row.delayMoreThan240 + row.delayMoreThan960;

          return `
            <span title="${totalDelay} / ${row.messageCount}">
              ${data ? roundAsNumber((totalDelay / row.messageCount) * 100, 0).toLocaleString() : 0} %
            </span>`;
        },
      },
      {
        name: "delayMoreThan60",
        data: "delayMoreThan60",
        type: "num",
        title: this.translateService.instant("general.delayMoreThan60"),
        render: (data, type, row) => {
          const totalDelay = row.delayMoreThan60 + row.delayMoreThan240 + row.delayMoreThan960;

          return `
            <span title="${totalDelay} / ${row.messageCount}">
              ${data ? roundAsNumber((totalDelay / row.messageCount) * 100, 0).toLocaleString() : 0} %
            </span>`;
        },
      },
      {
        name: "delayMoreThan240",
        data: "delayMoreThan240",
        type: "num",
        title: this.translateService.instant("general.delayMoreThan240"),
        render: (data, type, row) => {
          const totalDelay = row.delayMoreThan240 + row.delayMoreThan960;

          return `
            <span title="${totalDelay} / ${row.messageCount}">
              ${data ? roundAsNumber((totalDelay / row.messageCount) * 100, 0).toLocaleString() : 0} %
            </span>`;
        },
      },
      {
        name: "delayMoreThan960",
        data: "delayMoreThan960",
        type: "num",
        title: this.translateService.instant("general.delayMoreThan960"),
        render: (data, type, row) => {
          const totalDelay = row.delayMoreThan960;

          return `
            <span title="${totalDelay} / ${row.messageCount}">
              ${data ? roundAsNumber((totalDelay / row.messageCount) * 100, 0).toLocaleString() : 0} %
            </span>`;
        },
      },
    ];

    this.dtOptions = {
      buttons: getGridButtons(
        this.commonExportOptions,
        "issues_overview",
        this.translateService.instant("menu.issueoverview"),
        this.colorService
      ),
      pagingType: "simple_numbers",
      serverSide: true,
      processing: true,
      // scrollY: 500,
      // scroller: {
      //     loadingIndicator: true
      // },
      searchDelay: 2000,
      deferRender: true,
      scrollX: true,
      colReorder: { fixedColumnsLeft: 2 },
      deferLoading: 0,
      stateSave: true,
      stateSaveCallback: (settings, data) => {
        this.saveState(this.constructorName, data);
      },
      stateLoadCallback: (_, callback) => {
        (async () => {
          try {
            const columnSettings = await this.loadState(this.constructorName);
            this.searchTerm = columnSettings && columnSettings.search && columnSettings.search.search;
            return columnSettings;
          } catch (e) {
            this.error = {};
            this.error.error = e;
            this.error.statusText = "Error fetching column settings";

            return null;
          }
        })().then((result) => {
          callback(result);
        });
      },
      order: [[0, "desc"]],
      ajax: {
        beforeSend: () => {
          this.drawFilterRow();

          $(".dataTables_info").html(this.translateService.instant("grid.loadingData"));
        },
        url: this.dashboardService.getIssuesLatencyUrl(
          this.randomKey,
          Moment.utc(this.daterangepickerModel[0]).tz(this.timezoneIana).startOf("day"),
          Moment.utc(this.daterangepickerModel[1]).tz(this.timezoneIana).endOf("day")
        ),
        data: (d) => {
          return d;
        },
        dataSrc: (json) => {
          return json.data;
        },
        type: "POST",
      },
      initComplete: (settings, json) => {
        this.loading = false;
        this.episodesLoaded = false;

        console.log("init complete");
        this.checkFilters();
        this.drawFilterRow();
        this.loading = false;
      },
      colVis: {
        restore: this.translateService.instant("general.restore"),
        showAll: this.translateService.instant("general.showAll"),
        showNone: this.translateService.instant("general.hideAll"),
      },
      columns: this.columns,
      pageLength: 17,
      lengthMenu: [
        [10, 17, 25, 50, -1],
        [10, 17, 25, 50, this.translateService.instant("general.all")],
      ],
      language: getGridLanguages(this.translateService),
    };
  }
}
