<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12 flex header-content">
        <nav class="breadcrumb-container" aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a href="/#/"> <i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
                </li>
                <li>
                    <a href="/#/System/">{{'general.system' | translate }}</a>
                </li>
                <li class="breadcrumb-item active">{{'general.export' | translate }}</li>
            </ol>
        </nav>

        <span style="margin-left: auto" class="fa-filter-container noselect">
            <a
                style="margin-left: 2px"
                class="btn btn-info margin-fix d-none d-md-inline-flex"
                title="Help"
                href="https://help.360locate.com/knowledge-base/export-import/"
                target="_blank"
                ><i class="fa fa-fa fa-book" style="margin-right: 6px"></i>
                <span class="d-none d-md-inline-flex">{{'general.needHelp' | translate }}</span></a
            >
        </span>
    </div>
    <fh-system-navigation></fh-system-navigation>
</div>

<div class="wrapper wrapper-content">
    <div class="row">
        <div class="col-lg-12">
            <fh-error [success]="success" [error]="error" [autoRemove]="false"></fh-error>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-12">
            <fh-wizard [(step)]="step" [showHeader]="true" (finish)="onCheckOutFinish()">
                <fh-wizard-step
                    stepTitle="general.entity"
                    [validate]="entityChooserForm"
                    icon="fa-umbrella"
                    [padding]="false"
                >
                    <form #entityChooserForm="ngForm" style="width: 100%; border-top: 0px">
                        <table style="width: 100%" class="table nowrap dtr-inline no-footer">
                            <tbody>
                                <tr>
                                    <td class="row_opening">
                                        {{ 'general.entity' | translate}}
                                        <span class="error" *ngIf="entityVar.invalid">*</span>
                                    </td>
                                    <td class="row_content" style="position: relative; overflow: visible">
                                        <ng-select
                                            name="actionId"
                                            ngDefaultControl
                                            [searchable]="false"
                                            [clearable]="false"
                                            placeholder="{{ 'general.chooseAction' | translate}}"
                                            [(ngModel)]="selectedEntityId"
                                            #entityVar="ngModel"
                                            class="form-control table-inline"
                                            style="width: 100%"
                                            required
                                        >
                                            <ng-option [value]="entityType.Device">
                                                <i class="fa fa-fw fa-microchip"></i>
                                                {{ 'general.devices' | translate}}
                                            </ng-option>
                                            <ng-option [value]="entityType.Asset">
                                                <i class="fa fa-fw fa-car"></i>
                                                {{ 'general.assets' | translate}}
                                            </ng-option>
                                            <ng-option [value]="entityType.Driver">
                                                <i class="fa fa-fw fa-id-card-alt"></i>
                                                {{ 'general.drivers' | translate}}
                                            </ng-option>
                                            <ng-option [value]="entityType.Geofence">
                                                <i class="fa fa-fw fa-draw-polygon"></i>
                                                {{ 'general.geofences' | translate}}
                                            </ng-option>
                                            <ng-option [value]="entityType.Trailer">
                                                <i class="fa fa-fw fa-trailer"></i>
                                                {{ 'general.trailers' | translate}}
                                            </ng-option>
                                            <ng-option [value]="entityType.Maintenance">
                                                <i class="fa fa-fw fa-car-mechanic"></i>
                                                {{ 'general.maintenance' | translate}}
                                            </ng-option>
                                        </ng-select>
                                    </td>
                                </tr>

                                <tr *ngIf="selectedActionId == actionType.Import">
                                    <td class="row_opening">
                                        {{ 'general.clearItems' | translate}}

                                        <i
                                            tooltip="{{ 'general.clearItemsOnImport' |
                                                translate}}"
                                            class="fas fa-fw fa-question"
                                        ></i>
                                    </td>
                                    <td class="row_content" style="position: relative; overflow: visible">
                                        <div class="fa_checkbox" style="padding: 5px">
                                            <input
                                                name="clearItemsOnImport"
                                                id="clearItemsOnImport"
                                                type="checkbox"
                                                [(ngModel)]="clearItemsOnImport"
                                            />
                                            <label for="clearItemsOnImport"></label>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </form>
                </fh-wizard-step>
                <fh-wizard-step
                    stepTitle="general.selectAccount"
                    [validate]="accountChooserForm"
                    icon="fa-building"
                    [padding]="false"
                >
                    <form #accountChooserForm="ngForm" style="width: 100%; border-top: 0px">
                        <table style="width: 100%" class="table nowrap dtr-inline no-footer">
                            <tbody>
                                <tr *ngIf="selectedEntityId == entityType.Device">
                                    <td class="row_opening">{{ 'general.useAccount' | translate}}</td>
                                    <td class="row_content" style="position: relative; overflow: visible">
                                        <div class="fa_checkbox">
                                            <input
                                                [checked]="true"
                                                id="useAccount"
                                                type="checkbox"
                                                name="useAccount"
                                                [(ngModel)]="useAccount"
                                            />
                                            <label for="useAccount"></label>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="row_opening">
                                        {{ 'general.reseller' | translate}}
                                        <span class="error" *ngIf="resellerVar.invalid">*</span>
                                    </td>
                                    <td class="row_content" style="position: relative; overflow: visible">
                                        <fh-reseller-input
                                            name="reseller"
                                            #resellerVar="ngModel"
                                            (onChanged)="resellerChanged($event)"
                                            required
                                            [tableInline]="true"
                                            placeholder="{{ 'general.chooseReseller' | translate}}"
                                            [(ngModel)]="selectedResellerId"
                                        >
                                        </fh-reseller-input>
                                    </td>
                                </tr>
                                <tr *ngIf="useAccount">
                                    <td class="row_opening">
                                        {{ 'general.account' | translate}}
                                        <span class="error" *ngIf="accountVar.invalid">*</span>
                                    </td>
                                    <td class="row_content" style="position: relative; overflow: visible">
                                        <fh-account-input
                                            name="account"
                                            #accountVar="ngModel"
                                            (onChanged)="accountChanged($event)"
                                            required
                                            [selectedResellerId]="selectedResellerId"
                                            [tableInline]="true"
                                            placeholder="{{ (selectedResellerId ? 'general.chooseAccount' : 'general.firstChooseReseller') | translate}}"
                                            [(ngModel)]="selectedAccountId"
                                        >
                                        </fh-account-input>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </form>
                </fh-wizard-step>
                <fh-wizard-step
                    stepTitle="general.chooseFileType"
                    [validate]="fileTypeChooserForm"
                    icon="fa-file-pdf"
                    [padding]="false"
                >
                    <form #fileTypeChooserForm="ngForm" style="width: 100%; border-top: 0px">
                        <table style="width: 100%" class="table nowrap dtr-inline no-footer">
                            <tbody>
                                <tr>
                                    <td class="row_opening">
                                        {{ 'general.fileType' | translate}}
                                        <span class="error" *ngIf="entityVar.invalid">*</span>
                                    </td>
                                    <td class="row_content" style="position: relative; overflow: visible">
                                        <ng-select
                                            name="fileTypeId"
                                            ngDefaultControl
                                            [searchable]="false"
                                            [clearable]="false"
                                            [(ngModel)]="selectedFileTypeId"
                                            #entityVar="ngModel"
                                            class="form-control table-inline"
                                            style="width: 100%"
                                            required
                                        >
                                            <ng-option [value]="exportFileType.CSV">
                                                <i class="fa fa-fw fa-file-csv"></i>
                                                {{ 'general.csv' | translate}}
                                            </ng-option>
                                            <ng-option [value]="exportFileType.XLSX">
                                                <i class="fa fa-fw fa-file-excel"></i>
                                                {{ 'general.xlsx' | translate}}
                                            </ng-option>
                                        </ng-select>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </form>
                </fh-wizard-step>
                <fh-wizard-step stepTitle="general.confirm" icon="fa-check" [padding]="false">
                    <ngx-loading [show]="processing"></ngx-loading>
                    <table style="width: 100%" class="table nowrap dtr-inline no-footer">
                        <tbody>
                            <tr>
                                <td class="row_opening">{{ 'general.reseller' | translate}}</td>
                                <td class="row_content" style="position: relative; overflow: visible">
                                    {{ selectedReseller?.resellerDescription }}
                                </td>
                            </tr>
                            <tr>
                                <td class="row_opening">{{ 'general.account' | translate}}</td>
                                <td class="row_content" style="position: relative; overflow: visible">
                                    {{ selectedAccount?.name }}
                                </td>
                            </tr>
                            <tr>
                                <td class="row_opening">{{ 'general.action' | translate}}</td>
                                <td class="row_content" style="position: relative; overflow: visible">
                                    {{ 'general.export' | translate}}
                                </td>
                            </tr>
                            <tr>
                                <td class="row_opening">{{ 'general.entity' | translate}}</td>
                                <td class="row_content" style="position: relative; overflow: visible">
                                    {{ ('enums.entityTypes.' + selectedEntityId) | translate}}
                                </td>
                            </tr>
                            <tr>
                                <td class="row_opening">{{ 'general.fileType' | translate}}</td>
                                <td class="row_content" style="position: relative; overflow: visible">
                                    {{ ('enums.fileTypes.' + this.selectedFileTypeId) | translate}}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </fh-wizard-step>
            </fh-wizard>
        </div>
    </div>
</div>
