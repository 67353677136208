<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12 flex header-content">
        <nav class="breadcrumb-container" aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a href="/#/"> <i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
                </li>
                <li class="breadcrumb-item" *ngIf="driver?.accountId">
                    <a href="/#/AccountDetails/Index/{{driver?.accountId}}">{{driver?.companyName}}</a>
                </li>
                <li class="breadcrumb-item">
                    <a href="/#/Drivers/Overview">{{'general.driver' | translate }}</a>
                </li>
                <li class="breadcrumb-item">
                    <a href="/#/DriverDetails/Index/{{driver?.id}}">{{driver?.firstName}} {{driver?.lastName}}</a>
                </li>
                <li class="breadcrumb-item active">{{'menu.score' | translate }}</li>
            </ol>
        </nav>
    </div>
    <fh-driver-details-navigation [driverId]="driverId"></fh-driver-details-navigation>
    <div *ngIf="!showMapOnSide" class="col-lg-12" style="padding: 1px 0 0 0">
        <ngx-loading [show]="loading"></ngx-loading>
        <fh-leaflet-map
            [height]="height"
            [geofences]="geofences"
            [geofenceEnabled]="false"
            [allowBookmarks]="false"
            [heatmapEnabled]="true"
            [useClustering]="false"
            (mapReady)="onMapReady($event)"
        >
        </fh-leaflet-map>
    </div>
</div>

<div class="wrapper wrapper-content">
    <div class="row" data-equalizer="">
        <div class="col-lg-12">
            <fh-error [error]="error" [warning]="warning" [success]="success" [autoRemove]="false"></fh-error>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-6">
            <div class="ibox">
                <div class="ibox-title">
                    <h5><i class="fas fa-fw fa-filter"></i> {{'general.filter' | translate }}</h5>
                    <span class="float-right" [tooltip]="'Show debug'">
                        <i
                            (click)="showDebug = !showDebug"
                            class="fa-duotone fa-lg fa-fw"
                            [ngClass]="showDebug ? 'fa-toggle-on' : 'fa-toggle-off'"
                        >
                        </i>
                    </span>
                </div>
                <div class="ibox-content slick_toppadding" style="position: relative">
                    <div class="col-lg-12">
                        <div class="form-group input-group" style="margin-bottom: 5px">
                            <span class="input-group-prepend">
                                <i class="fa fa-fw fa-calendar"></i>
                            </span>
                            <input
                                placeholder="Daterangepicker"
                                style="width: 100%"
                                placeholder="Datepicker"
                                type="text"
                                [bsConfig]="dpConfig"
                                class="form-control"
                                bsDaterangepicker
                                [maxDate]="maxDate"
                                (onHidden)="dateChanged('hidden')"
                                [(ngModel)]="daterangepickerModel"
                            />
                            <span class="input-group-calendar-datatables">
                                <i class="fa fa-calendar-alt"></i>
                            </span>
                        </div>
                    </div>
                    <div style="clear: both"></div>
                </div>
            </div>

            <div class="ibox float-e-margins">
                <div class="ibox-title">
                    <h5><i class="fas fa-fw fa-sort-numeric-down"></i> {{'general.driverScore' | translate }}</h5>
                    <span class="float-right">
                        <span class="btn btn-info" style="display: inline; margin-top: -11px">
                            <b *ngIf="score?.score < 2"
                                >{{ score?.score ? actualRound(score.score, 1).toLocaleString() : 0 }} (Excellent)</b
                            >

                            <b *ngIf="score?.score >= 2 && score?.score < 5"
                                >{{ score?.score ? actualRound(score.score, 1).toLocaleString() : 0 }} (Good)</b
                            >

                            <b *ngIf="score?.score >= 5 && score?.score < 10"
                                >{{ score?.score ? actualRound(score.score, 1).toLocaleString() : 0 }} (Not Bad)</b
                            >

                            <b *ngIf="score?.score >= 10 && score?.score < 15"
                                >{{ score?.score ? actualRound(score.score, 1).toLocaleString() : 0 }} (Bad)</b
                            >

                            <b *ngIf="score?.score >= 15"
                                >{{ score?.score ? actualRound(score.score, 1).toLocaleString() : 0 }} (Very Bad)</b
                            >
                        </span>
                    </span>
                </div>
                <div class="ibox-content slick_toppadding" style="position: relative">
                    <ngx-loading [show]="loading || loadingScore"></ngx-loading>
                    <table style="width: 100%" class="table table-fixed nowrap dtr-inline no-footer">
                        <tbody>
                            <tr>
                                <td class="row_opening_small">{{ 'general.trips' | translate}}</td>
                                <td class="row_content">
                                    {{ score?.tripCount ? score.tripCount.toLocaleString() : 0 }}
                                </td>
                                <td class="row_opening_small">{{ 'general.score' | translate}}</td>
                                <td class="row_content">
                                    {{ score?.score ? actualRound(score.score, 1).toLocaleString() : 0 }}

                                    <fh-score-indicator [totalScore]="score?.score"></fh-score-indicator>
                                </td>
                            </tr>
                            <tr>
                                <td class="row_opening_small">{{ 'general.distance' | translate}}</td>
                                <td class="row_content">
                                    {{ score?.segmentsDistance ? score.segmentsDistance.toLocaleString() : 0 }} KM
                                </td>
                                <td class="row_opening_small">{{ 'general.speeding' | translate}}</td>
                                <td class="row_content">
                                    {{ score?.speedingDurationInSeconds ?
                                    actualRoundSeconds((score?.speedingDurationInSeconds)).toLocaleString() : 0 }}

                                    <fh-score-indicator [score]="score?.speedScoreWeighted"></fh-score-indicator>

                                    <span *ngIf="showDebug">({{actualRound(score?.speedScoreWeighted,2) }})</span>
                                </td>
                            </tr>
                            <tr>
                                <td class="row_opening_small">{{ 'general.workingHours' | translate}}</td>
                                <td class="row_content">
                                    {{ score?.workingHoursDurationInSeconds ?
                                    actualRoundSeconds((score?.workingHoursDurationInSeconds)).toLocaleString() : 0 }}
                                </td>
                                <td class="row_opening_small">{{ 'general.braking' | translate}}</td>
                                <td class="row_content">
                                    {{ score?.decelCount ? actualRound(score?.decelCount,0).toLocaleString() : 0 }}

                                    <fh-score-indicator [score]="score?.brakingEventScoreWeighted"></fh-score-indicator>

                                    <span *ngIf="showDebug"
                                        >({{actualRound(score?.brakingEventScoreWeighted,2) }})</span
                                    >
                                </td>
                            </tr>
                            <tr>
                                <td class="row_opening_small">{{ 'general.pureDriving' | translate}}</td>
                                <td class="row_content">
                                    {{ score?.pureDrivingDurationInSeconds ?
                                    actualRoundSeconds((score?.pureDrivingDurationInSeconds)).toLocaleString() : 0 }}
                                </td>
                                <td class="row_opening_small">{{ 'general.acceleration' | translate}}</td>
                                <td class="row_content">
                                    {{ score?.accelCount ? actualRound(score?.accelCount,0).toLocaleString():0 }}

                                    <fh-score-indicator [score]="score?.accellerationEventScoreWeighted">
                                    </fh-score-indicator>

                                    <span *ngIf="showDebug"
                                        >({{actualRound(score?.accellerationEventScoreWeighted,2) }})</span
                                    >
                                </td>
                            </tr>
                            <tr>
                                <td class="row_opening_small">{{ 'general.idling' | translate}}</td>
                                <td class="row_content">
                                    {{ score?.idlingDurationInSeconds ?
                                    actualRoundSeconds((score?.idlingDurationInSeconds)).toLocaleString() : 0 }}

                                    <fh-score-indicator [score]="score?.idlingScoreWeighted"></fh-score-indicator>

                                    <span *ngIf="showDebug">({{actualRound(score?.idlingScoreWeighted,2) }})</span>
                                </td>
                                <td class="row_opening_small">{{ 'general.cornering' | translate}}</td>
                                <td class="row_content">
                                    {{ score?.corneringCount ? actualRound(score?.corneringCount,0).toLocaleString() : 0
                                    }}

                                    <fh-score-indicator [score]="score?.corneringEventScoreWeighted">
                                    </fh-score-indicator>

                                    <span *ngIf="showDebug"
                                        >({{actualRound(score?.corneringEventScoreWeighted,2) }})</span
                                    >
                                </td>
                            </tr>
                            <tr>
                                <td class="row_opening_small">{{ 'general.seatBeltViolation' | translate}}</td>
                                <td class="row_content">
                                    {{ score?.durationSeatBeltViolationInSeconds ?
                                    actualRoundSeconds((score?.durationSeatBeltViolationInSeconds)).toLocaleString() : 0
                                    }}

                                    <fh-score-indicator [score]="score?.seatBeltScoreWeighted"></fh-score-indicator>

                                    <span *ngIf="showDebug">({{actualRound(score?.seatBeltScoreWeighted,2) }})</span>
                                </td>
                                <td class="row_opening_small">{{ 'general.rpm' | translate}}</td>
                                <td class="row_content">
                                    {{ score?.rpmCount ? actualRound(score?.rpmCount,0).toLocaleString() : 0 }}

                                    <fh-score-indicator [score]="score?.rpmEventScoreWeighted"></fh-score-indicator>

                                    <span *ngIf="showDebug">({{actualRound(score?.rpmEventScoreWeighted,2) }})</span>
                                </td>
                            </tr>
                            <tr>
                                <td class="row_content" colspan="4" style="white-space: normal">
                                    <span *ngFor="let group of score?.driverGroups">
                                        <a
                                            class="btn btn-info btn-grid"
                                            href="/#/DriverGroupDetails/Index/{{group?.id}}"
                                            style="text-wrap: nowrap; margin: 2px"
                                        >
                                            <i class="fas fa-fw fa-layer-group" [style.color]="group.color"></i>
                                            {{group?.name }}</a
                                        >
                                    </span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <!-- <div class="ibox float-e-margins">
                <div class="ibox-title">
                    <h5>
                        <i class="fas fa-fw fa-sort-numeric-down"></i> {{'general.violationsPerDay' | translate }}
                    </h5>
                </div>
                <div class="ibox-content slick_toppadding" style="position:relative">
                    <div style="height:300px" <highcharts-chart [runOutsideAngular]="true" style="display: block; width: 100%; height:214px" [Highcharts]="Highcharts" [options]="chartViolationsPerDay"></div>
                </div>
            </div> -->

            <div class="ibox float-e-margins">
                <div class="ibox-title">
                    <h5><i class="fas fa-fw fa-sort-numeric-down"></i> {{'general.violationsPerType' | translate }}</h5>
                    <span class="float-right">
                        <ibox-chart-export [chartObject]="chartViolationsPerType"> </ibox-chart-export>
                    </span>
                </div>
                <div class="ibox-content slick_toppadding" style="min-height: 300px; position: relative">
                    <ngx-loading [show]="loading || loadingScore"></ngx-loading>

                    <highcharts-chart
                        *ngIf="chartViolationsPerType"
                        [runOutsideAngular]="true"
                        style="display: block; width: 100%; height: 300px"
                        [Highcharts]="Highcharts"
                        [options]="chartViolationsPerType"
                    >
                    </highcharts-chart>
                </div>
            </div>
        </div>
        <div class="col-lg-6">
            <div class="ibox" style="position: relative" *ngIf="showMapOnSide">
                <ngx-loading [show]="loading"></ngx-loading>
                <fh-leaflet-map
                    (mapReady)="onMapReady($event)"
                    [height]="height"
                    [geofences]="geofences"
                    [geofenceEnabled]="false"
                    [allowBookmarks]="false"
                    [heatmapEnabled]="true"
                    [useClustering]="false"
                    (mapReady)="onMapReady($event)"
                >
                </fh-leaflet-map>
            </div>

            <div class="ibox">
                <div class="ibox-title">
                    <h5><i class="fa fa-traffic-light-stop"></i> {{ 'general.episodes' | translate }}</h5>
                    <iboxsearch
                        [searchTerm]="searchTerm"
                        (searchChanged)="searchChanged($event)"
                        [constructorName]="constructorName"
                        (filterChanged)="showFilter = $event"
                        (presetLoaded)="onPresetLoaded($event)"
                        (columnsChanged)="drawFilterRow()"
                    ></iboxsearch>
                </div>
                <div class="ibox-content slick" style="position: relative" *ngIf="languageLoaded">
                    <table
                        style="width: 100%; min-height: 50px"
                        fh-datatable
                        [dtOptions]="dtOptions"
                        [dtTrigger]="dtTrigger"
                        class="table nowrap margin-bottom-0 dataTable dtr-inline"
                    ></table>
                </div>
            </div>
        </div>
    </div>
</div>
