<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12 flex header-content">
        <nav class="breadcrumb-container" aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a href="/#/"> <i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
                </li>
                <li class="breadcrumb-item">
                    <a href="/#/Devices/">{{'general.devices' | translate }}</a>
                </li>
                <li class="breadcrumb-item">
                    <a href="/#/Devices/Changes">{{'general.settingsChanges' | translate }}</a>
                </li>
                <li class="breadcrumb-item active">{{'menu.overview' | translate }}</li>
            </ol>
        </nav>

        <fh-filter-toggler
            style="margin-left: auto"
            [constructorName]="constructorName"
            (filterChanged)="showFilter = $event"
            (presetsChanged)="showPresets = $event"
        ></fh-filter-toggler>
    </div>
    <fh-device-navigation></fh-device-navigation>
</div>

<fh-permission-handler [permissionName]="permissionName">
    <div class="wrapper wrapper-content">
        <div class="row">
            <div class="col-lg-12">
                <fh-error [error]="error"></fh-error>

                <!-- Custom filters -->
                <div class="ibox">
                    <div class="ibox-title">
                        <h5><i class="fas fa-fw fa-filter"></i> {{'general.filter' | translate }}</h5>
                    </div>
                    <div class="ibox-content slick_toppadding flex-container" style="position: relative">
                        <div class="col-lg-4">
                            <div class="form-group input-group" style="margin-bottom: 6px">
                                <span class="input-group-prepend">
                                    <i class="fa fa-fw fa-calendar"></i>
                                </span>
                                <fh-reseller-input
                                    name="reseller"
                                    #resellerVar="ngModel"
                                    (onChanged)="resellerChanged($event)"
                                    required
                                    class="form-control"
                                    placeholder="{{ 'general.chooseReseller' | translate}}"
                                    [(ngModel)]="selectedResellerId"
                                >
                                </fh-reseller-input>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="form-group input-group" style="margin-bottom: 6px">
                                <span class="input-group-prepend">
                                    <i class="fa fa-fw fa-calendar"></i>
                                </span>
                                <fh-account-input
                                    name="account"
                                    #accountVar="ngModel"
                                    (onChanged)="accountChanged($event)"
                                    required
                                    class="form-control"
                                    [selectedResellerId]="selectedResellerId"
                                    [showEmptyAccountsButton]="false"
                                    placeholder="{{ (selectedResellerId ? 'general.chooseAccount' : 'general.firstChooseReseller') | translate}}"
                                    [(ngModel)]="selectedAccountId"
                                >
                                </fh-account-input>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="form-group input-group" style="margin-bottom: 5px">
                                <span class="input-group-prepend">
                                    <i class="fa fa-fw fa-calendar"></i>
                                </span>
                                <input
                                    placeholder="Daterangepicker"
                                    style="width: 100%"
                                    placeholder="Datepicker"
                                    type="text"
                                    [bsConfig]="dpConfig"
                                    class="form-control"
                                    bsDaterangepicker
                                    class="form-control"
                                    (onHidden)="dateChanged('hidden')"
                                    [(ngModel)]="daterangepickerModel"
                                />
                                <span class="input-group-calendar-datatables">
                                    <i class="fa fa-calendar-alt"></i>
                                </span>
                                <div style="clear: both"></div>
                            </div>
                        </div>
                        <div style="clear: both"></div>
                    </div>
                    <div class="ibox-content slick_toppadding" style="position: relative">
                        <ngx-loading [show]="loading"></ngx-loading>
                        <div id="dynamicFilters" class="flex-container"></div>
                        <div style="clear: both"></div>
                    </div>
                </div>
                <!-- End default dynamic filters -->

                <div class="ibox">
                    <div class="ibox-title">
                        <h5><i class="fas fa-fw fa-cubes"></i> {{'general.settingsChanges' | translate }}</h5>
                        <iboxsearch
                            [searchTerm]="searchTerm"
                            (searchChanged)="searchChanged($event)"
                            [constructorName]="constructorName"
                            (filterChanged)="showFilter = $event"
                            (presetLoaded)="onPresetLoaded($event)"
                            (columnsChanged)="drawFilterRow()"
                        ></iboxsearch>
                    </div>
                    <div class="ibox-content slick" style="position: relative" *ngIf="languageLoaded">
                        <table
                            style="width: 100%; min-height: 50px"
                            fh-datatable
                            [dtOptions]="dtOptions"
                            [dtTrigger]="dtTrigger"
                            class="table nowrap margin-bottom-0 dataTable dtr-inline"
                        ></table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</fh-permission-handler>
