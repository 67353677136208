import { Component, OnInit, ChangeDetectorRef, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { FormMode } from "app/common/enums";
import { Project } from "app/models/project.model";
import { EntityType } from "app/common/enums";
import { AuthenticationService } from "app/services/authentication/authentication.service";

// Moment timezone
import { ProjectService } from "../../services/project/project.service";
import { ConfirmationModalComponent } from "../shared/usercontrols/confirmationModal.component";
import { AssetService } from "app/services/asset/asset.service";
import { BsDaterangepickerConfig } from "ngx-bootstrap/datepicker";
import { getDefaultDpConfig } from "app/common/gridhelper";
import { ScheduleAssignment } from "app/models/triggerSchedule.model";

@Component({
  selector: "fh-fh-project-details",
  templateUrl: "projectDetails.template.html",
})
export class ProjectDetailsViewComponent implements OnInit {
  @ViewChild("confirmModal", { static: false }) confirmModal: ConfirmationModalComponent;
  sub: any;
  project: Project = new Project();
  device: any;
  tagType = EntityType.Project.valueOf();
  formMode = FormMode.read;

  loading = false;
  saving = false;

  error: any;
  success: any;
  warning: any;

  selectedAction;

  permissions: {};

  loadingLocation = false;
  loadingAssignments = false;
  allowDelete = true;
  geofences = [];

  projectAssignments = [];

  permissionName = "Projects_View";
  projectId: any;

  errorItems = [];

  scheduleAssignment: ScheduleAssignment;
  scheduleAssignments: ScheduleAssignment[];

  // Multiselect
  format = {
    add: "Add",
    remove: "Remove",
    all: "All",
    none: "None",
    draggable: true,
  };

  display = "name";

  source = [];
  confirmed = [];

  step = 1;

  public dpConfig: Partial<BsDaterangepickerConfig> = new BsDaterangepickerConfig();

  constructor(
    private router: Router,
    private cd: ChangeDetectorRef,
    private assetService: AssetService,
    private authentication: AuthenticationService,
    private projectService: ProjectService,
    private route: ActivatedRoute
  ) {
    this.permissions = this.authentication.permissions;

    this.dpConfig = getDefaultDpConfig(authentication);

    this.dpConfig.containerClass = "theme-default";
    this.dpConfig.dateInputFormat = "lll";
    this.dpConfig.isAnimated = false;
    this.dpConfig.withTimepicker = true;
    this.dpConfig.keepDatepickerOpened = true;

    this.scheduleAssignment = new ScheduleAssignment();
    this.scheduleAssignments = [];
  }

  ngOnInit() {
    this.loading = true;
    this.loadingAssignments = true;

    this.sub = this.route.params.subscribe((params) => {
      const id = params["id"];

      this.projectId = id;
      this.scheduleAssignment.projectId = this.projectId;
      this.projectService.getProjectById(id).subscribe({
        next: (project) => {
          this.project = project;
          this.loading = false;

          this.assetService.getAssetsByAccount(project.accountId).subscribe((result) => {
            this.source = result.filter((x) => x.isArchived !== true);
          });
        },
        error: (error) => {
          this.error = error;
          this.error.statusText = "Error fetching project";

          this.cd.markForCheck();

          setTimeout(() => {
            this.router.navigate(["/Projects/Overview"]);
          }, 3000);
        },
      });

      this.getProjectAssignments();
    });
  }

  getProjectAssignments() {
    this.projectService.getProjectAssignmentsById(this.projectId).subscribe((res) => {
      this.projectAssignments = res.sort((a, b) => (a.dateStart.unix() > b.dateStart.unix() ? -1 : 1));
      const assignments = [];

      this.projectAssignments.forEach((assignment) => {
        if (!this[assignment.assetId]) {
          this[assignment.assetId] = assignment;
          assignments.push(this[assignment.assetId]);
          return;
        }

        this[assignment.assetId].dateEnd = Math.max(
          +this[assignment.assetId]?.dateEnd as any,
          +assignment?.dateEnd as any
        );
      }, Object.create(null));

      this.projectAssignments = assignments;
      this.confirmed = this.projectAssignments;

      this.loadingAssignments = false;
    });
  }

  showModal(selectedAction) {
    this.selectedAction = selectedAction;
    this.confirmModal.showModal("");
  }

  hideModal() {
    this.confirmModal.hideModal();
  }

  finishTask(task) {
    if (task?.completed) {
      this.error = null;

      if (task.result?.isSuccess) {
        this.warning = null;
        this.success = {
          statusText: "Success",
          success: task.result?.message,
        };
      } else {
        this.success = null;
        this.warning = {
          statusText: "Warning",
          warning: task.result?.message,
        };
      }

      this.getProjectInfo();
      this.cd.markForCheck();
    } else {
      this.error = task?.error;
    }
  }

  // Form
  onDelete() {
    this.loading = true;

    this.projectService.deleteProject(this.project).subscribe({
      next: (result) => {
        this.error = null;
        this.success = {
          statusText: "Success",
          success: "Project is successfully deleted.",
        };

        setTimeout(() => {
          this.router.navigate(["/Projects/Overview"]);
        }, 3000);
      },
      error: (error) => {
        this.success = null;
        this.error = error;
        this.loading = false;
        this.cd.markForCheck();
      },
    });
  }

  onSave() {
    this.saving = true;

    this.projectService.updateProject(this.project).subscribe({
      next: (result) => {
        this.error = null;
        this.success = {
          statusText: "Success",
          success: "Project is successfully updated.",
        };

        this.saving = false;
        this.cd.markForCheck();

        this.setFormMode(FormMode.read);
      },
      error: (error) => {
        this.saving = false;
        this.success = null;
        this.error = error;
        this.cd.markForCheck();
      },
    });
  }

  onCheckOutFinish() {
    this.formMode = FormMode.read;
    this.loadingAssignments = true;

    this.error = null;
    this.success = {
      statusText: "Success",
      success: "Project assignments are updated.",
    };

    this.loading = true;
    this.cd.markForCheck();

    this.getProjectInfo();
    this.getProjectAssignments();
  }

  setFormMode(mode) {
    this.formMode = mode;

    if (this.formMode === FormMode.read) {
      this.loading = true;
      this.getProjectInfo();
    }
  }

  getProjectInfo() {
    this.loading = true;

    this.projectService.getProjectById(this.project.id).subscribe((project) => {
      this.project = project;
      this.loading = false;
      this.step = 1;
      this.cd.markForCheck();
    });
  }

  // Assignments

  setStartDate(event) {
    this.confirmed.forEach((asset) => {
      asset.dateStart = event;
    });
  }

  setEndDate(event) {
    this.confirmed.forEach((asset) => {
      asset.dateEnd = event;
    });
  }

  sendRequest(form) {
    const assets = [];

    for (const key in form.value) {
      const item = form.value[key];
      const startDate = +(new Date(item.startDate) || item.dateStart) / 1000;
      const endDate = +(new Date(item.endDate) || item.dateEnd) / 1000;
      assets.push({ ...item, startDate, endDate });
    }

    this.project.assets = assets;

    this.loadingAssignments = true;
    this.loading = true;
    this.cd.markForCheck();

    this.projectService.updateProjectAssignments(this.project).subscribe({
      next: (result) => {
        this.errorItems = result?.errorItems;
        this.loading = false;
      },
      error: (error) => {
        this.success = null;
        this.error = error;

        this.errorItems = error?.errorItems;
        this.loading = false;
      },
    });
  }
}
