import { ChangeDetectorRef, Component, OnInit, ChangeDetectionStrategy } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { DeviceTypeService } from "app/services/devicetypes/devicetypes.service";
import { forkJoin } from "rxjs";
import { AuthenticationService } from "../../services/authentication/authentication.service";
import { DeviceService } from "../../services/device/device.service";

// Moment timezone
import Moment from "moment-timezone";
import { Device } from "app/models/device.model";
import { getIconPath } from "app/common/globals";

window["moment"] = Moment;

import * as XLSX from "xlsx-js-style";

@Component({
  selector: "fh-devices-cards",
  templateUrl: "cards.template.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DevicesCardsViewComponent implements OnInit {
  countrySelect = false;

  excludingColumns = [
    "timeStamp",
    "assetDateStart",
    "assetDateEnd",
    "lastCommunication",
    "input1",
    "input2",
    "input3",
    "input4",
    "input5",
    "input6",
    "output1",
    "output2",
  ];
  loading = false;

  permissionName = "Devices_View";
  constructorName = "DevicesSensorsViewComponent";

  selectedResellerId;
  selectedAccountId;
  selectedAssetGroups;

  error: any;
  success: any;
  timezoneIana: string;
  languageLoaded: boolean;

  deviceSource: Device[] = [];
  devices: Device[] = [];

  currentPage;
  filter;
  itemsPerPage = 10;
  orderBy = "lastCommunication";
  status;
  orderByReversed = true;

  constructor(
    private translateService: TranslateService,
    private cd: ChangeDetectorRef,
    private authenticationService: AuthenticationService,
    private deviceService: DeviceService,
    private deviceTypeService: DeviceTypeService
  ) {
    this.timezoneIana = this.authenticationService.getTimeZoneIana();

    // Get all the date for dropdown boxes
    forkJoin([this.translateService.get("general.date"), this.deviceTypeService.getDeviceTypes()]).subscribe({
      next: (data) => {
        this.languageLoaded = true;
        this.cd.markForCheck();
      },
      error: (err) => {
        this.error = err;
        this.languageLoaded = true;
        this.loading = false;
        this.cd.markForCheck();
      },
    });
  }

  resellerChanged(event) {
    this.deviceSource = [];
    this.devices = [];

    this.selectedAccountId = null;
    this.selectedAssetGroups = [];
  }

  accountChanged(event) {
    this.deviceSource = [];
    this.devices = [];
    this.selectedAssetGroups = [];

    this.getData(this.selectedAccountId);
  }

  ngOnInit() {}

  getIcon(iconId) {
    return getIconPath(iconId)[2];
  }

  filterDevices() {
    this.devices = this.deviceSource;
    if (this.status) {
      this.devices = this.devices.filter((x) => x.deviceState === this.status);
    }

    // Filter groups
    if (this.selectedAssetGroups && this.selectedAssetGroups.length > 0) {
      this.devices = this.devices.filter((x) =>
        x.asset?.assetGroups.some((ag) => this.selectedAssetGroups.includes(+ag.id))
      );
    }

    this.cd.markForCheck();
  }

  getData(accountId) {
    this.loading = true;

    this.deviceSource = [];
    this.devices = [];

    if (accountId == null) {
      this.loading = false;
      return;
    }

    this.deviceService.getDevicesWithLocation(accountId, true, true, true, false).subscribe({
      next: (result) => {
        this.deviceSource = result;
        this.filterDevices();
        this.loading = false;
        this.cd.markForCheck();
      },
      error: (err) => {
        this.error = err;
        this.languageLoaded = true;
        this.loading = false;
        this.cd.markForCheck();
      },
    });
  }

  export() {
    const exportList = [];

    this.devices.forEach((item) => {
      const newItem: any = item;
      newItem.assetGroupIds = item.asset?.assetGroups
        .map((k) => {
          return k.id;
        })
        .join(";");
      newItem.assetGroupNames = item.asset?.assetGroups
        .map((k) => {
          return k.name;
        })
        .join(";");
      exportList.push(newItem);
    });

    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.devices);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(wb, ws, "FleetManagement_Export");

    const filename = ("fm_export_" + this.selectedAccountId + "_cards")
      .replace(/\s/g, "")
      .replace(" ", "")
      .replace(".", "")
      .toLowerCase();
    XLSX.writeFile(wb, filename + ".csv", { bookType: "csv" });
  }
}
