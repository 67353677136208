<div class="slideMenuDetails white-bg fontColor" style="position: relative">
    <ngx-loading [show]="loadingSidebar"></ngx-loading>
    <img style="position: absolute; right: 20px; top: 0px" [src]="getIcon(selectedDevice?.asset?.icon)" />

    <table style="width: 100%" class="table table-fixed nowrap dtr-inline no-footer">
        <tbody>
            <tr>
                <td class="row_opening">
                    {{ "general.name" | translate }}
                </td>
                <td class="row_content" colspan="2">
                    <a class="secondary link_bolder" href="/#/DeviceDetails/Index/{{ selectedDevice?.id }}">
                        {{ selectedDevice?.asset?.name }}</a
                    >
                </td>
            </tr>
            <tr *ngIf="permissions['Entities_DeviceTypes']">
                <td class="row_opening">
                    {{ "general.modelId" | translate }}
                </td>
                <td class="row_content" colspan="2">
                    <a
                        class="secondary link_bolder"
                        href="/#/Devices/DeviceTypeDetails/Index/{{ selectedDeviceExtended?.deviceTypeId }}"
                    >
                        {{ selectedDeviceExtended?.deviceTypeNameFull }}
                    </a>
                </td>
            </tr>
            <tr *ngIf="permissions['Accounts_View']">
                <td class="row_opening">
                    {{ "general.customer" | translate }}
                </td>
                <td class="row_content" colspan="2">
                    <a class="secondary link_bolder" href="/#/AccountDetails/Index/{{ selectedDevice?.accountId }}">{{
                        selectedDevice?.companyName
                    }}</a>
                </td>
            </tr>
            <tr>
                <td class="row_opening">
                    {{ "general.lastCommunication" | translate }}
                </td>
                <td class="row_content" title="{{ deviceState?.communicationState?.updateTimestamp }}" colspan="2">
                    {{ deviceState?.communicationState?.updateTimestamp | amTimeAgo }}
                </td>
            </tr>
            <tr *ngIf="deviceState?.currentAddress">
                <td class="row_opening">
                    {{ "general.near" | translate }}
                </td>
                <td
                    class="row_content"
                    colspan="2"
                    title="{{ deviceState?.currentAddress?.address }}, {{ deviceState?.currentAddress?.city }}, {{
                        deviceState?.currentAddress?.country
                    }}"
                >
                    {{ deviceState?.currentAddress?.city }}
                    {{ deviceState?.currentAddress?.address ? ", " + deviceState?.currentAddress?.address : "" }}
                    {{ deviceState?.currentAddress?.country ? ", " + deviceState?.currentAddress?.country : "" }}
                </td>
            </tr>
            <tr *ngIf="selectedDevice?.asset?.currentAddress">
                <td class="row_opening">
                    {{ "general.near" | translate }}
                </td>
                <td
                    class="row_content"
                    colspan="2"
                    title="{{ selectedDevice?.asset?.currentAddress?.address }}, {{
                        selectedDevice?.asset?.currentAddress?.city
                    }}, {{ selectedDevice?.asset?.currentAddress?.country }}"
                >
                    {{ selectedDevice?.asset?.currentAddress?.city }}
                    {{
                        selectedDevice?.asset?.currentAddress?.address
                            ? ", " + selectedDevice?.asset?.currentAddress?.address
                            : ""
                    }}
                    {{
                        selectedDevice?.asset?.currentAddress?.country
                            ? ", " + selectedDevice?.asset?.currentAddress?.country
                            : ""
                    }}
                </td>
            </tr>
        </tbody>
    </table>

    <nav>
        <span
            class="fleet-overview-sidebar-action"
            style="float: right; display: block; position: relative; right: 0; top: 0"
            *ngIf="permissions['FleetManagement_Actions']"
        >
            <fh-overview-action [entity]="selectedDeviceExtended" [entityTypes]="tagType"></fh-overview-action>
        </span>
        <li class="fleet-overview-sidebar-tabs" style="margin-top: 15px; position: relative">
            <button
                class="fleet-overview-sidebar-tab"
                (click)="changeTab('DeviceState')"
                title="Asset details"
                [class.active]="tab === 'DeviceState'"
            >
                <i class="fa fa-fw fa-satellite"></i>
                <span *ngIf="tab === 'DeviceState'">{{ "general.state" | translate }}</span>
            </button>
            <button
                class="fleet-overview-sidebar-tab"
                (click)="changeTab('Details')"
                title="Asset details"
                [class.active]="tab === 'Details'"
            >
                <i class="fa fa-fw fa-microchip"></i>
                <span *ngIf="tab === 'Details'">{{ "general.details" | translate }}</span>
            </button>
            <button
                class="fleet-overview-sidebar-tab"
                (click)="changeTab('Utilization')"
                title="Utilization"
                [class.active]="tab === 'Utilization'"
            >
                <i class="fa fa-fw fa-engine"></i>
                <span *ngIf="tab === 'Utilization'">{{ "general.utilization" | translate }}</span>
            </button>
            <button
                class="fleet-overview-sidebar-tab"
                (click)="changeTab('Trips')"
                title="Trips"
                [class.active]="tab === 'Trips'"
            >
                <i class="fa fa-fw fa-route"></i>
                <span *ngIf="tab === 'Trips'">{{ "general.trips" | translate }}</span>
            </button>
        </li>
    </nav>

    <div class="fleet-overview-sidebar-details" *ngIf="tab === 'Details'">
        <table style="width: 100%; margin-bottom: 3px" class="table table-fixed nowrap dtr-inline no-footer">
            <tbody>
                <tr>
                    <td class="row_opening sidebar-header" colspan="3" style="border-top-left-radius: 0">
                        <i class="fa fa-fas fa-car"></i> {{ "general.asset" | translate }}
                    </td>
                </tr>
                <tr *ngIf="selectedDevice?.asset?.brand">
                    <td class="row_opening">
                        {{ "general.brand" | translate }}
                    </td>
                    <td class="row_content" colspan="2">
                        {{ selectedDevice?.asset?.brand }}
                    </td>
                </tr>
                <tr *ngIf="selectedDevice?.asset?.model">
                    <td class="row_opening">
                        {{ "general.model" | translate }}
                    </td>
                    <td class="row_content" colspan="2">
                        {{ selectedDevice?.asset?.model }}
                    </td>
                </tr>
                <tr *ngIf="selectedDevice?.asset?.plateNumber">
                    <td class="row_opening">
                        {{ "general.plate" | translate }}
                    </td>
                    <td class="row_content" colspan="2">
                        {{ selectedDevice?.asset?.plateNumber }}
                    </td>
                </tr>
                <tr *ngIf="selectedDevice?.asset?.year">
                    <td class="row_opening">
                        {{ "general.year" | translate }}
                    </td>
                    <td class="row_content" colspan="2">
                        {{ selectedDevice?.asset?.year }}
                    </td>
                </tr>
                <tr *ngIf="selectedDevice?.asset?.color">
                    <td class="row_opening">
                        {{ "general.color" | translate }}
                    </td>
                    <td class="row_content" colspan="2">
                        {{ selectedDevice?.asset?.color }}
                    </td>
                </tr>
                <tr *ngIf="permissions['Entities_DeviceTypes']">
                    <td class="row_opening">
                        {{ "general.unitId" | translate }}
                    </td>
                    <td class="row_content" colspan="2">
                        {{ selectedDevice?.unitId }}
                    </td>
                </tr>
                <tr *ngIf="selectedDeviceExtended?.asset?.vin">
                    <td class="row_opening">
                        {{ "general.vin" | translate }}
                    </td>
                    <td class="row_content" colspan="2">
                        {{ selectedDeviceExtended?.asset?.vin }}
                    </td>
                </tr>
                <tr *ngIf="selectedDeviceExtended?.asset?.comment">
                    <td class="row_opening">
                        {{ "general.comment" | translate }}
                    </td>
                    <td class="row_content" colspan="2">
                        {{ selectedDeviceExtended?.asset?.comment }}
                    </td>
                </tr>
                <tr *ngIf="selectedDeviceExtended?.asset?.properties?.custom.length > 0">
                    <td class="row_opening sidebar-header" colspan="3">
                        <i class="fa fa-fw fa-draw-polygon"></i> {{ "general.customFields" | translate }}
                    </td>
                </tr>
                <tr *ngFor="let custom of selectedDeviceExtended?.asset?.properties?.custom">
                    <td class="row_content">{{ custom.key }}</td>
                    <td class="row_content" colspan="2">{{ custom.value }}</td>
                </tr>
                <tr *ngIf="permissions['AssetGroups_View']">
                    <td class="row_opening sidebar-header" colspan="3">
                        <i class="fa fa-fw fa-layer-group"></i> {{ "general.assetGroups" | translate }}
                    </td>
                </tr>
                <tr *ngIf="permissions['AssetGroups_View']">
                    <td class="row_content" colspan="3" style="padding: 4px; white-space: normal">
                        <span *ngFor="let group of selectedDeviceExtended?.asset?.assetGroups">
                            <a
                                class="btn btn-info btn-grid"
                                href="/#/AssetGroupDetails/Index/{{ group?.id }}"
                                style="text-wrap: nowrap; margin: 2px"
                            >
                                {{ group?.name }}</a
                            >
                        </span>
                    </td>
                </tr>
                <tr *ngIf="selectedDeviceExtended?.tags">
                    <td class="row_opening sidebar-header" colspan="3">
                        <i class="fa fa-fw fa-tags"></i> {{ "general.tags" | translate }}
                    </td>
                </tr>
                <tr *ngIf="selectedDeviceExtended?.tags">
                    <td class="row_content" colspan="3" style="padding: 4px; white-space: normal">
                        <span *ngFor="let tag of selectedDeviceExtended?.tags">
                            <a
                                class="btn btn-info btn-grid"
                                href="/#/Tags/{{ tag }}"
                                style="text-wrap: nowrap; margin: 2px"
                            >
                                {{ tag }}</a
                            >
                        </span>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

    <!-- Utilization -->
    <div class="fleet-overview-sidebar-details flex-container" *ngIf="tab === 'Utilization'">
        <header class="sidebar-header">
            <i class="fa fa-fw fa-engine"></i> {{ "general.utilization" | translate }}
        </header>
        <div class="col-4" style="padding: 0">
            <div class="kpi-item-small">
                <h3 class="margin-bottom font-bold">
                    {{
                        selectedDeviceUtilization?.segmentsDistance
                            ? selectedDeviceUtilization?.segmentsDistance.toLocaleString()
                            : "-"
                    }}
                    {{ translatedKm }}
                </h3>
                <div>{{ "general.distance" | translate }}</div>
            </div>
        </div>
        <div class="col-4" style="padding: 0">
            <div class="kpi-item-small">
                <h3 class="margin-bottom font-bold">
                    {{
                        selectedDeviceUtilization?.tripDurationInSeconds
                            ? actualRoundSeconds(selectedDeviceUtilization?.tripDurationInSeconds)
                            : "-"
                    }}
                </h3>
                <div>{{ "general.duration" | translate }}</div>
            </div>
        </div>
        <div class="col-4" style="padding: 0">
            <div class="kpi-item-small">
                <h3 class="margin-bottom font-bold">
                    {{
                        selectedDeviceUtilization?.idlingDurationInSeconds
                            ? actualRoundSeconds(selectedDeviceUtilization?.idlingDurationInSeconds)
                            : "-"
                    }}
                </h3>
                <div>{{ "general.idling" | translate }}</div>
            </div>
        </div>
        <div class="col-4" style="padding: 0">
            <div class="kpi-item-small">
                <h3 class="margin-bottom font-bold">
                    <a
                        class="secondary link_bolder"
                        (click)="$event.stopPropagation()"
                        href="/#/DeviceDetails/Trips/{{ selectedDevice?.id }}"
                    >
                        {{ selectedDeviceUtilization?.tripCount ? selectedDeviceUtilization?.tripCount : "-" }}</a
                    >
                </h3>
                <div>{{ "general.trips" | translate }}</div>
            </div>
        </div>

        <div class="col-4" style="padding: 0">
            <div class="kpi-item-small">
                <h3 class="margin-bottom font-bold">
                    {{ selectedDeviceUtilization?.accelCount ? selectedDeviceUtilization?.accelCount : "-" }}
                </h3>
                <div>{{ "general.acceleration" | translate }}</div>
            </div>
        </div>
        <div class="col-4" style="padding: 0">
            <div class="kpi-item-small">
                <h3 class="margin-bottom font-bold">
                    {{ selectedDeviceUtilization?.decelCount ? selectedDeviceUtilization?.decelCount : "-" }}
                </h3>
                <div>{{ "general.braking" | translate }}</div>
            </div>
        </div>

        <div class="col-4" style="padding: 0">
            <div class="kpi-item-small">
                <h3 class="margin-bottom font-bold">
                    {{
                        selectedDeviceUtilization?.roadSpeedingCount
                            ? selectedDeviceUtilization?.roadSpeedingCount
                            : "-"
                    }}
                </h3>
                <div>{{ "general.speeding" | translate }}</div>
            </div>
        </div>
        <div class="col-4" style="padding: 0">
            <div class="kpi-item-small">
                <h3 class="margin-bottom font-bold">
                    {{
                        selectedDeviceUtilization?.maxSpeed ? actualRound(selectedDeviceUtilization?.maxSpeed, 0) : "-"
                    }}
                </h3>
                <div>{{ "general.maxSpeed" | translate }}</div>
            </div>
        </div>
    </div>

    <!-- Device State -->
    <div class="fleet-overview-sidebar-details" *ngIf="tab === 'DeviceState'">
        <table style="width: 100%; margin-bottom: 3px" class="table table-fixed nowrap dtr-inline no-footer">
            <tbody>
                <tr *ngIf="driver && permissions['Drivers_View']">
                    <td class="row_opening sidebar-header" colspan="3">
                        <i class="fa fa-fw fa-id-card-alt"></i> {{ "general.driver" | translate }}
                    </td>
                </tr>
                <tr *ngIf="driver && driver?.name && permissions['Drivers_View']">
                    <td class="row_opening">
                        {{ "general.name" | translate }}
                    </td>
                    <td class="row_content" colspan="2">
                        <a class="secondary link_bolder" href="/#/DriverDetails/Index/{{ driver?.id }}">
                            {{ driver?.name }}</a
                        >
                    </td>
                </tr>
                <tr *ngIf="driver && driver?.phone && permissions['Drivers_View']">
                    <td class="row_opening">
                        {{ "general.phone" | translate }}
                    </td>
                    <td class="row_content" colspan="2">
                        {{ driver?.phone }}
                    </td>
                </tr>
                <tr *ngIf="deviceState?.insideGeofences && selectedDevice?.geofences?.length > 0">
                    <td class="row_opening sidebar-header" colspan="3">
                        <i class="fa fa-fw fa-draw-polygon"></i> {{ "general.geofences" | translate }}
                    </td>
                </tr>
                <tr *ngIf="deviceState?.insideGeofences && selectedDevice?.geofences?.length > 0">
                    <td class="row_content" colspan="3" style="padding: 4px; white-space: normal">
                        <span *ngFor="let geofence of selectedDevice?.geofences">
                            <a
                                class="btn btn-info btn-grid"
                                title="{{ 'general.entered' | translate }} : {{ geofence.entered | amTimeAgo }}"
                                href="/#/GeofenceDetails/Index/{{ geofence?.id }}"
                                style="text-wrap: nowrap; margin: 2px"
                            >
                                <i
                                    class="fa fa-fw fa-draw-polygon"
                                    style="opacity: 0.5"
                                    [style.color]="geofence?.color"
                                ></i>
                                {{ geofence?.name }}</a
                            >
                        </span>
                    </td>
                </tr>
            </tbody>
        </table>
        <header class="sidebar-header">
            <i class="fa fa-fas fa-satellite"></i> {{ "general.deviceState" | translate }}

            <span class="float-right" [tooltip]="'Show GPS items'">
                <i
                    (click)="flipPosition()"
                    class="fa-duotone fa-lg fa-fw"
                    [ngClass]="showPosition ? 'fa-toggle-on' : 'fa-toggle-off'"
                >
                </i>
            </span>
        </header>

        <fh-device-state-display
            style="width: 100%"
            [deviceState]="deviceState"
            [device]="selectedDeviceExtended"
            [showPosition]="showPosition"
            [sensorTemplates]="sensorTemplates"
            [includeDriver]="false"
            [hasCanbus]="hasCanbus"
        ></fh-device-state-display>

        <!-- IO States -->
        <header class="sidebar-header">
            <i class="fa fa-fas fa-random"></i> {{ "general.ioStates" | translate }}

            <span class="float-right" [tooltip]="'Show unmapped items'">
                <i
                    (click)="flipUnMapped()"
                    class="fa-duotone fa-lg fa-fw"
                    [ngClass]="showUnMapped ? 'fa-toggle-on' : 'fa-toggle-off'"
                >
                </i>
            </span>
        </header>

        <fh-device-io-display
            style="width: 100%"
            [deviceState]="deviceState"
            [device]="selectedDeviceExtended"
            [showUnMapped]="showUnMapped"
            [sensorTemplates]="sensorTemplates"
            [hasCanbus]="hasCanbus"
        ></fh-device-io-display>
    </div>
    <div class="fleet-overview-sidebar-details fleet-overview-header" *ngIf="tab === 'Trips'">
        <ngx-loading [show]="loadingTrips"></ngx-loading>
        <fh-overview-trip-history
            [asset]="selectedAsset"
            [openTrip]="openTrip.bind(this)"
            [openStop]="openStop.bind(this)"
            [changeTripVisibility]="changeTripVisibility.bind(this)"
            [displayLocation]="displayLocation.bind(this)"
            [playTrip]="playTrip.bind(this)"
            [flipLocations]="flipLocations.bind(this)"
            [driversMap]="driversMap"
        >
        </fh-overview-trip-history>
    </div>
</div>
