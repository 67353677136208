import { ChangeDetectorRef, Component } from "@angular/core";
import { Router } from "@angular/router";
import { FormMode, UserContactType } from "app/common/enums";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { AssetType } from "app/models/assetType.model";
import { UserContact } from "app/models/userContact.model";
import { UserContactService } from "app/services/users/userContacts.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "fh-user-contact-add",
  templateUrl: "add.template.html",
})
export class UserContactAddViewComponent {
  loading = false;
  userContact: UserContact = new UserContact();

  formMode = FormMode.add;
  formModeAssetTypes: FormMode = FormMode.read;

  error: any;
  permissions: {};
  success: { statusText: string; success: string };
  assetTypes: AssetType[];

  permissionName = "UserContacts_Add";

  constructor(
    private translateServe: TranslateService,
    private cd: ChangeDetectorRef,
    private authenticationService: AuthenticationService,
    private router: Router,
    private userContactService: UserContactService
  ) {
    this.permissions = this.authenticationService.permissions;
  }

  onInsert() {
    this.loading = true;

    if (this.userContact.userContactType === undefined) {
      this.userContact.userContactType = UserContactType.NormalContact;
    }

    this.userContactService.saveUserContact(this.userContact).subscribe({
      next: (result) => {
        this.success = {
          statusText: "Success",
          success: this.translateServe.instant("general.saveSuccessRedirect", { entity: "userContact" }),
        };

        setTimeout(() => {
          this.router.navigate(["/Notifications/UserContactDetails/Index/" + result.id]);
        }, 2000);

        this.loading = false;
      },
      error: (error) => {
        this.error = error;
        this.loading = false;
      },
    });
  }

  accountChanged(accountId) {
    console.log("Changed accountId");
    this.userContact.accountId = accountId;

    this.cd.markForCheck();
  }
}
