import { Location } from "@angular/common";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { environment } from "app/../environments/environment";
import { User, UserManager, UserManagerSettings } from "oidc-client-ts";
import { catchError, finalize, from, map, Observable, of, Subject, switchMap, throwError } from "rxjs";
import { Config } from "../../models/config.model";
import { sha256 } from "js-sha256";
import { roundAsString } from "app/common/globals";
import { DistanceUnitService } from "app/common/distanceunit.service";
import { ColorService } from "../common/color.service";
import { UserInfoResult } from "app/models/userInfoResult.model";
import { AuthenticateTokenResult } from "app/models/authenticateTokenResult.model";
import { AuthenticateRequest } from "app/models/authenticateRequest.model";
import { AuthenticateShareTokenResult } from "app/models/authenticateShareTokenResult.model";

@Injectable()
export class AuthenticationService implements OnInit {
  public permissions: Record<string, boolean> = {};
  public resellers: number[];
  public user: User | null = null; // SSO user tokens
  public userInfo: UserInfoResult | null;

  public environment: Config = environment;

  private manager: UserManager = new UserManager(AuthenticationService.getSSOSettings());

  // Logged in event
  private loggedIn: Subject<boolean> = new Subject<boolean>();
  onLoggedIn: Observable<boolean> = this.loggedIn.asObservable();

  // Constants for our preferences.
  public static readonly TOKEN: string = "token";
  public static readonly SHARETOKEN: string = "sharetoken";

  public static readonly REFRESHTOKEN: string = "refreshtoken";

  public static readonly TOKEN_EXPIRED: string = "token_expired";
  public static readonly SHARETOKEN_EXPIRED: string = "sharetoken_expired";

  public static readonly TOKEN_IMPERSONATE: string = "token_impersonate";
  public static readonly TOKEN_IMPERSONATE_EXPIRED: string = "token_impersonate_expired";

  public static readonly REFRESHTOKEN_IMPERSONATE: string = "refreshtoken_impersonate";

  public static readonly CLUSTER_URL: string = "cluster_url";

  public static readonly USERID: string = "userid";
  public static readonly USERID_IMPERSONATE: string = "userid_impersonate";
  public static readonly PERMISSIONS: string = "permissions";
  public static readonly PERMISSIONS_IMPERSONATE: string = "permissions_impersonate";

  public static readonly RESELLERS: string = "resellers";
  public static readonly RESELLERS_IMPERSONATE: string = "resellers_impersonate";

  public static readonly ID: string = "id";
  public static readonly ID_IMPERSONATE: string = "id_impersonate";

  public static readonly ACCOUNTID: string = "accountid";
  public static readonly ACCOUNTIDENTIFIER: string = "accountidentifier";
  public static readonly ACCOUNTID_IMPERSONATE: string = "accountid_impersonate";
  public static readonly ACCOUNTIDENTIFIER_IMPERSONATE: string = "accountidentifier_impersonate";

  public static readonly RESELLERID: string = "resellerid";
  public static readonly RESELLERID_IMPERSONATE: string = "resellerid_impersonate";

  public static readonly DRIVERID: string = "driverid";
  public static readonly DRIVERID_IMPERSONATE: string = "driverid_impersonate";

  public static readonly TIMEZONEIANA: string = "timezoneiana";
  public static readonly TIMEZONEIANA_IMPERSONATE: string = "timezoneiana_impersonate";

  public static readonly CULTURE: string = "culture";

  public static readonly FEATUREFLAGS: string = "featureflags";
  public static readonly FEATUREFLAGS_IMPERSONATE: string = "featureflags_impersonate";

  public static readonly WHITELABEL: string = "whitelabel";
  public static readonly WHITELABEL_IMPERSONATE: string = "whitelabel_impersonate";

  public static readonly USERTYPE: string = "usertype";
  public static readonly USERTYPE_IMPERSONATE: string = "usertype_impersonate";

  public static readonly USERROLE: string = "userrole";
  public static readonly USERROLE_IMPERSONATE: string = "userrole_impersonate";

  public static readonly DISTANCEUNIT: string = "distanceunit";
  public static readonly DISTANCEUNIT_IMPERSONATE: string = "distanceunit_impersonate";

  public static getStaticToken(): string {
    return (
      localStorage.getItem(AuthenticationService.TOKEN_IMPERSONATE) || localStorage.getItem(AuthenticationService.TOKEN)
    );
  }

  public static getStaticExpiredToken(): string {
    return (
      localStorage.getItem(AuthenticationService.TOKEN_IMPERSONATE_EXPIRED) ||
      localStorage.getItem(AuthenticationService.TOKEN_EXPIRED)
    );
  }

  public static getStaticRefreshToken(): string {
    return (
      localStorage.getItem(AuthenticationService.REFRESHTOKEN_IMPERSONATE) ||
      localStorage.getItem(AuthenticationService.REFRESHTOKEN)
    );
  }

  public static getStaticClusterUrl(): string {
    return localStorage.getItem(AuthenticationService.CLUSTER_URL);
  }

  public static getSSOSettings(): UserManagerSettings {
    return {
      authority: window["server_variables"].SSOAuthenticationUrl,
      client_id: window["server_variables"].SSOClientId,
      redirect_uri: window["server_variables"].SSORedirectUrl,
      response_type: "code",
      post_logout_redirect_uri: window["server_variables"].SSOPostLogoutRedirectUrl,
      scope: window["server_variables"].SSOClientScope,
    };
  }

  constructor(
    private http: HttpClient,
    private distance: DistanceUnitService,
    private colorService: ColorService,
    private router: Router,
    private location: Location
  ) {
    environment.AuthenticationUrl = window["server_variables"].AuthenticationUrl;

    if (this.environment && this.environment.Debug) {
      console.log("Retrieved configuration, environment: " + environment.Environment);
    }
  }

  public getUserId(): string {
    return (
      localStorage.getItem(AuthenticationService.USERID_IMPERSONATE) ||
      localStorage.getItem(AuthenticationService.USERID)
    );
  }

  public getDistanceUnit(): string {
    return (
      localStorage.getItem(AuthenticationService.DISTANCEUNIT_IMPERSONATE) ||
      localStorage.getItem(AuthenticationService.DISTANCEUNIT)
    );
  }

  public getWhitelabel(): string {
    return (
      localStorage.getItem(AuthenticationService.WHITELABEL_IMPERSONATE) ||
      localStorage.getItem(AuthenticationService.WHITELABEL)
    );
  }

  public getUserType(): string {
    return (
      localStorage.getItem(AuthenticationService.USERTYPE_IMPERSONATE) ||
      localStorage.getItem(AuthenticationService.USERTYPE)
    );
  }

  public getUserRole(): string {
    return (
      localStorage.getItem(AuthenticationService.USERROLE_IMPERSONATE) ||
      localStorage.getItem(AuthenticationService.USERROLE)
    );
  }

  public getFeatureFlags(): string {
    return (
      localStorage.getItem(AuthenticationService.FEATUREFLAGS_IMPERSONATE) ||
      localStorage.getItem(AuthenticationService.FEATUREFLAGS)
    );
  }

  public getAuthToken(): string {
    return AuthenticationService.getStaticToken() ?? "";
  }

  public getShareToken(): string {
    return localStorage.getItem(AuthenticationService.SHARETOKEN) ?? "";
  }

  public getIsImpersonated(): boolean {
    return !!localStorage.getItem(AuthenticationService.TOKEN_IMPERSONATE);
  }

  public getCultureLang(): string {
    const serverCulture = this.getServerCulture();

    // Try to guess the culture from the language
    switch (serverCulture) {
      case "nl-NL":
        return "nl";
      case "fr-FR":
        return "fr";
      case "ar-EG":
        return "ar";
      case "ar-AE":
        return "ar";
      case "en-US":
        return "en";
      case "en-GB":
        return "en";
      case "de-DE":
        return "de";
      default:
        console.warn(`Could not translate language ${serverCulture} to culture. Falling back to server culture`);
        return serverCulture;
    }
  }

  public getServerCulture(): string {
    return localStorage.getItem(AuthenticationService.CULTURE);
  }

  public getTimeZoneIana(): string {
    return (
      localStorage.getItem(AuthenticationService.TIMEZONEIANA_IMPERSONATE) ||
      localStorage.getItem(AuthenticationService.TIMEZONEIANA)
    );
  }
  public getId(): string {
    return localStorage.getItem(AuthenticationService.ID_IMPERSONATE) || localStorage.getItem(AuthenticationService.ID);
  }

  public getAccountId(): string {
    return (
      localStorage.getItem(AuthenticationService.ACCOUNTID_IMPERSONATE) ||
      localStorage.getItem(AuthenticationService.ACCOUNTID)
    );
  }

  public getAccountIdentifier(): string {
    return (
      localStorage.getItem(AuthenticationService.ACCOUNTIDENTIFIER_IMPERSONATE) ||
      localStorage.getItem(AuthenticationService.ACCOUNTIDENTIFIER)
    );
  }

  public getResellerId(): string {
    return (
      localStorage.getItem(AuthenticationService.RESELLERID_IMPERSONATE) ||
      localStorage.getItem(AuthenticationService.RESELLERID)
    );
  }

  public getDriverId(): string {
    return (
      localStorage.getItem(AuthenticationService.DRIVERID_IMPERSONATE) ||
      localStorage.getItem(AuthenticationService.DRIVERID)
    );
  }

  public getWebserviceURL(resource: string): string {
    // Checks if the url is set, if not then get new one. Else return the url.
    const baseUrl = AuthenticationService.getStaticClusterUrl();
    return baseUrl !== "null" ? `${baseUrl}${resource !== "" ? resource + "/" : ""}` : null;
  }

  getPermissions() {
    // Try to decode
    const permissions =
      localStorage.getItem(AuthenticationService.PERMISSIONS_IMPERSONATE) ||
      localStorage.getItem(AuthenticationService.PERMISSIONS);
    try {
      const permissionJson = JSON.parse(window.atob(permissions));
      this.permissions = permissionJson;
    } catch (err) {
      console.error(err);
    }
  }

  setPermissions(setting: string, permissionList: string[] = []) {
    this.permissions = {};

    permissionList.forEach((permission) => {
      this.permissions[permission] = true;
    });

    if (this.permissions["HasExternal"] && !window["server_variables"]["HasExternal"]) {
      this.permissions["HasExternal"] = false;
    }

    const permissionJson = JSON.stringify(this.permissions);

    if (permissionJson.length > 0) {
      localStorage.setItem(setting, window.btoa(permissionJson));
    }
  }

  getResellers() {
    // Try to decode
    const resellers =
      localStorage.getItem(AuthenticationService.RESELLERS_IMPERSONATE) ||
      localStorage.getItem(AuthenticationService.RESELLERS);
    try {
      const resellerJson = JSON.parse(window.atob(resellers));
      this.resellers = resellerJson;
    } catch (err) {
      console.error(err);
    }
  }

  setResellers(setting: string, resellerList: number[] = []) {
    this.resellers = resellerList;

    const resellerJson = JSON.stringify(this.resellers);

    if (resellerJson.length > 0) {
      localStorage.setItem(setting, window.btoa(resellerJson));
    }
  }

  getUser(): Promise<User | null> {
    return this.manager.getUser();
  }

  SSOSignInCallback(url: string): Promise<User> {
    return this.manager.signinCallback(url);
  }

  SSOLogin(): Promise<void> {
    return this.manager.signinRedirect();
  }

  logout(): Promise<void> {
    return this.manager.signoutRedirect();
  }

  ngOnInit() {}

  public get headers(): HttpHeaders {
    let token = this.getAuthToken();
    if (this.user) {
      token = this.user.id_token;
    }

    return new HttpHeaders({
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    });
  }

  public get unAuthenticatedHeaders(): HttpHeaders {
    return new HttpHeaders({ "Content-Type": "application/json", Accept: "application/json" });
  }

  public get shareheaders(): HttpHeaders {
    const sharetoken = this.getShareToken();
    return new HttpHeaders({
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + sharetoken,
    });
  }

  // Function to handle navigation to the login page
  navigateToLogin = (path: string) => {
    this.clearToken();
    this.router.navigate(["/Login"], { queryParams: { redirect: encodeURI(path) } });
  };

  IsAuthenticated(path: string): Observable<boolean> {
    return from(this.clearStaleSSOState()).pipe(
      switchMap(() => {
        return this.checkAuth(path);
      })
    );
  }

  private checkAuth(path: string): Observable<boolean> {
    if (path == null) {
      path = this.location.path();
    }

    // Check if redirect / share token
    if (path?.toLowerCase().indexOf("sharing") > -1 && path?.toLowerCase().indexOf("carsharing") == -1) {
      console.log("Using share token or car sharing so returning true");
      return of(true);
    }

    if (environment.Debug) {
      console.log("Authentication: Testing if Token is expired...");
    }

    return from(this.getUser()).pipe(
      switchMap((user: User | null) => {
        this.user = user;
        const isSSO = this.user !== null;

        const isImpersonated = this.getIsImpersonated();
        if (isSSO == false && !this.isTokenValid()) {
          const refreshToken = AuthenticationService.getStaticRefreshToken();

          if (refreshToken) {
            if (environment.Debug) {
              console.log("Authentication: Refreshing token with refreshToken...");
            }
            return this.refreshTokenHandler(path, isImpersonated);
          } else {
            this.navigateToLogin(path);
            return of(false);
          }
        } else if (isSSO == true) {
          if (environment.Debug) {
            console.log("Authentication: User is SSO user");
          }
          if (!AuthenticationService.getStaticClusterUrl()) {
            localStorage.setItem(AuthenticationService.CLUSTER_URL, environment.AuthenticationUrl);
          }

          return this.getUserInfo(isImpersonated).pipe(
            switchMap(() => {
              return of(true);
            }),
            catchError((error) => {
              if (environment.Debug) {
                console.log("Authentication: Could not get user info!", error);
              }
              this.logout();
              return of(false);
            })
          );
        } else {
          if (environment.Debug) {
            console.log("Authentication: Token not expired yet. Checking user info");
          }
          return this.getUserInfo(isImpersonated).pipe(
            switchMap(() => {
              return of(true);
            }),
            catchError((error) => {
              if (environment.Debug) {
                console.log("Authentication: Could not check user info!", error);
              }
              this.logout();
              return of(false);
            })
          );
        }
      }),
      catchError((e) => {
        console.error(e);
        this.navigateToLogin(path);
        return of(false);
      }),
      finalize(() => {
        if (environment.Debug) {
          console.log("Authentication: Done with IsAuthenticated.");
        }
      })
    );
  }

  refreshTokenHandler(path: string, isImpersonated: boolean): Observable<boolean> {
    return this.refreshToken(isImpersonated).pipe(
      switchMap((result) => {
        if (result) {
          console.log("Authentication: Token is refreshed! Get user info");
          return this.getUserInfo(isImpersonated).pipe(
            map(() => {
              this.loggedIn.next(true);
              return true;
            }),
            catchError((error) => {
              if (environment.Debug) {
                console.log("Authentication: Could not get user info!", error);
              }
              this.logout();
              return of(false);
            })
          );
        }

        if (environment.Debug) {
          console.log("Authentication: Could not refresh token!");
        }
        this.navigateToLogin(path);
        return of(false);
      }),
      catchError((error) => {
        if (environment.Debug) {
          console.log("Authentication: Could not refresh token!", error);
        }
        this.navigateToLogin(path);
        return of(false);
      })
    );
  }

  getUserInfo(isImpersonated: boolean = false): Observable<UserInfoResult> {
    if (!this.userInfo) {
      console.log("Authentication: Get user info from api");

      return this.http
        .get<UserInfoResult>(environment.AuthenticationUrl + "Authentication/UserInfo", { headers: this.headers })
        .pipe(
          map((result) => {
            if (!isImpersonated) {
              console.log("Authentication: Saving user info");
              this.userInfo = this.saveUserInfo(result);

              this.getPermissions();
              this.getResellers();
              this.loggedIn.next(true);

              return this.userInfo;
            } else {
              console.log("Authentication: Saving impersonation user info");
              this.userInfo = this.saveImpersonationUserInfo(result);

              this.getPermissions();
              this.getResellers();
              this.loggedIn.next(true);

              return this.userInfo;
            }
          }),
          catchError(this.handleError)
        );
    }

    return of(this.userInfo);
  }

  refreshToken(isImpersonated: boolean = false): Observable<boolean> {
    const refreshToken = AuthenticationService.getStaticRefreshToken();
    return this.http
      .post<AuthenticateTokenResult>(
        environment.AuthenticationUrl + "Authentication/RefreshToken",
        { RefreshToken: refreshToken },
        { headers: this.unAuthenticatedHeaders }
      )
      .pipe(
        map((result) => {
          if (!isImpersonated) {
            console.log("Saving normal token");
            this.saveToken(result);
          } else {
            console.log("Saving impersonation token");
            this.setImpersonationToken(result);
          }
          return true;
        }),
        catchError(this.handleError)
      );
  }

  performReset(userName: string) {
    if (environment.Debug) {
      console.log("AuthenticateService: Performing reset for user: " + userName);
    }

    const body = '"' + encodeURIComponent(userName) + '"';

    return this.http.post(environment.AuthenticationUrl + "User/resetByUsername", body, {
      headers: this.unAuthenticatedHeaders,
    });
  }

  performLogin(userName: string, password: string): Observable<boolean> {
    if (environment.Debug) {
      console.log("AuthenticateService: Performing login for user: " + userName);
    }

    // Reset impersonation token
    this.stopImpersonation(false);

    const passwordHashed = sha256(password);
    const body = new AuthenticateRequest(userName, passwordHashed, true);

    return this.http
      .post<AuthenticateTokenResult>(environment.AuthenticationUrl + "Authentication/Login", body, {
        headers: this.unAuthenticatedHeaders,
      })
      .pipe(
        map((result) => {
          this.saveToken(result);
          this.loggedIn.next(true);
          return true;
        }),
        catchError(this.handleError)
      );
  }

  saveToken(authenticateResult: AuthenticateTokenResult) {
    if (environment.Debug) {
      console.log(
        `Authentication: Saving login token: ${authenticateResult.token} expires: ${authenticateResult.tokenExpires} url: ${environment.AuthenticationUrl}`
      );
    }

    localStorage.setItem(AuthenticationService.TOKEN, authenticateResult.token);
    localStorage.setItem(AuthenticationService.REFRESHTOKEN, authenticateResult.refreshToken);
    localStorage.setItem(AuthenticationService.TOKEN_EXPIRED, authenticateResult.tokenExpires);
    localStorage.setItem(AuthenticationService.CLUSTER_URL, environment.AuthenticationUrl);
  }

  saveUserInfo(userInfoResult: UserInfoResult): UserInfoResult {
    const url = window.location.origin;
    let overwriteWhitelabel = "";
    overwriteWhitelabel = this.colorService.getOverwriteTheme(url);
    if (overwriteWhitelabel !== "") {
      console.log(`Overwriting whitelabel with path ${url} setting theme ${overwriteWhitelabel}`);
      userInfoResult.whitelabel = overwriteWhitelabel;
    }

    localStorage.setItem(AuthenticationService.WHITELABEL, userInfoResult.whitelabel);
    localStorage.setItem(AuthenticationService.USERTYPE, userInfoResult.userType.toString());
    localStorage.setItem(AuthenticationService.USERROLE, userInfoResult.userRoleId.toString());
    localStorage.setItem(AuthenticationService.USERID, userInfoResult.id.toString());

    localStorage.setItem(AuthenticationService.ID, userInfoResult.userName);
    localStorage.setItem(AuthenticationService.ACCOUNTID, userInfoResult.accountId.toString());
    localStorage.setItem(AuthenticationService.ACCOUNTIDENTIFIER, userInfoResult.accountIdentifier);
    localStorage.setItem(AuthenticationService.RESELLERID, userInfoResult.resellerId.toString());
    localStorage.setItem(AuthenticationService.DRIVERID, userInfoResult.driverId?.toString());

    localStorage.setItem(AuthenticationService.CULTURE, userInfoResult.culture);

    localStorage.setItem(AuthenticationService.TIMEZONEIANA, userInfoResult.timezoneIana);
    localStorage.setItem(AuthenticationService.FEATUREFLAGS, userInfoResult.featureFlags);

    localStorage.setItem(AuthenticationService.DISTANCEUNIT, userInfoResult.distanceUnit.toString());
    this.distance.setUnit(userInfoResult.distanceUnit.toString());
    this.setPermissions(AuthenticationService.PERMISSIONS, userInfoResult.permissions);
    this.setResellers(AuthenticationService.RESELLERS, userInfoResult.resellers);

    return userInfoResult;
  }

  setImpersonationToken(result: AuthenticateTokenResult) {
    localStorage.setItem(AuthenticationService.TOKEN_IMPERSONATE, result.token);
    localStorage.setItem(AuthenticationService.REFRESHTOKEN_IMPERSONATE, result.refreshToken);
    localStorage.setItem(AuthenticationService.TOKEN_IMPERSONATE_EXPIRED, result.tokenExpires);
  }

  saveImpersonationUserInfo(userInfoResult: UserInfoResult): UserInfoResult {
    localStorage.setItem(AuthenticationService.WHITELABEL_IMPERSONATE, userInfoResult.whitelabel);
    localStorage.setItem(AuthenticationService.USERTYPE_IMPERSONATE, userInfoResult.userType.toString());
    localStorage.setItem(AuthenticationService.USERROLE_IMPERSONATE, userInfoResult.userRoleId.toString());
    localStorage.setItem(AuthenticationService.USERID_IMPERSONATE, userInfoResult.id.toString());

    localStorage.setItem(AuthenticationService.ID_IMPERSONATE, userInfoResult.userName);
    localStorage.setItem(AuthenticationService.ACCOUNTID_IMPERSONATE, userInfoResult.accountId.toString());
    localStorage.setItem(AuthenticationService.ACCOUNTIDENTIFIER_IMPERSONATE, userInfoResult.accountIdentifier);
    localStorage.setItem(AuthenticationService.RESELLERID_IMPERSONATE, userInfoResult.resellerId.toString());
    localStorage.setItem(AuthenticationService.DRIVERID_IMPERSONATE, userInfoResult.driverId?.toString());

    localStorage.setItem(AuthenticationService.TIMEZONEIANA_IMPERSONATE, userInfoResult.timezoneIana);
    localStorage.setItem(AuthenticationService.FEATUREFLAGS_IMPERSONATE, userInfoResult.featureFlags);

    localStorage.setItem(AuthenticationService.DISTANCEUNIT_IMPERSONATE, userInfoResult.distanceUnit.toString());
    this.distance.setUnit(userInfoResult.distanceUnit.toString());
    this.setPermissions(AuthenticationService.PERMISSIONS_IMPERSONATE, userInfoResult.permissions);
    this.setResellers(AuthenticationService.RESELLERS_IMPERSONATE, userInfoResult.resellers);

    return userInfoResult;
  }

  stopImpersonation(reloadPage = true) {
    localStorage.removeItem(AuthenticationService.TOKEN_IMPERSONATE);
    localStorage.removeItem(AuthenticationService.REFRESHTOKEN_IMPERSONATE);
    localStorage.removeItem(AuthenticationService.TOKEN_IMPERSONATE_EXPIRED);

    localStorage.removeItem(AuthenticationService.USERID_IMPERSONATE);
    localStorage.removeItem(AuthenticationService.PERMISSIONS_IMPERSONATE);
    localStorage.removeItem(AuthenticationService.RESELLERS_IMPERSONATE);
    localStorage.removeItem(AuthenticationService.ID_IMPERSONATE);
    localStorage.removeItem(AuthenticationService.ACCOUNTID_IMPERSONATE);
    localStorage.removeItem(AuthenticationService.ACCOUNTIDENTIFIER_IMPERSONATE);
    localStorage.removeItem(AuthenticationService.RESELLERID_IMPERSONATE);
    localStorage.removeItem(AuthenticationService.DRIVERID_IMPERSONATE);
    localStorage.removeItem(AuthenticationService.TIMEZONEIANA_IMPERSONATE);
    localStorage.removeItem(AuthenticationService.FEATUREFLAGS_IMPERSONATE);
    localStorage.removeItem(AuthenticationService.WHITELABEL_IMPERSONATE);
    localStorage.removeItem(AuthenticationService.USERTYPE_IMPERSONATE);
    localStorage.removeItem(AuthenticationService.USERROLE_IMPERSONATE);
    localStorage.removeItem(AuthenticationService.DISTANCEUNIT_IMPERSONATE);
    this.userInfo = null;
    this.distance.setUnit(this.getDistanceUnit());

    if (reloadPage) {
      location.reload();
    }
  }

  clearToken(redirect = true) {
    if (environment.Debug) {
      console.log("Authentication: Logging out and removing tokens");
    }

    localStorage.removeItem(AuthenticationService.TOKEN);
    localStorage.removeItem(AuthenticationService.SHARETOKEN);
    localStorage.removeItem(AuthenticationService.REFRESHTOKEN);
    localStorage.removeItem(AuthenticationService.TOKEN_EXPIRED);
    localStorage.removeItem(AuthenticationService.SHARETOKEN_EXPIRED);
    localStorage.removeItem(AuthenticationService.CLUSTER_URL);
    localStorage.removeItem(AuthenticationService.USERID);
    localStorage.removeItem(AuthenticationService.PERMISSIONS);
    localStorage.removeItem(AuthenticationService.RESELLERS);
    localStorage.removeItem(AuthenticationService.ID);
    localStorage.removeItem(AuthenticationService.ACCOUNTID);
    localStorage.removeItem(AuthenticationService.ACCOUNTIDENTIFIER);
    localStorage.removeItem(AuthenticationService.RESELLERID);
    localStorage.removeItem(AuthenticationService.DRIVERID);
    localStorage.removeItem(AuthenticationService.TIMEZONEIANA);
    localStorage.removeItem(AuthenticationService.CULTURE);
    localStorage.removeItem(AuthenticationService.FEATUREFLAGS);
    localStorage.removeItem(AuthenticationService.WHITELABEL);
    localStorage.removeItem(AuthenticationService.USERTYPE);
    localStorage.removeItem(AuthenticationService.USERROLE);
    localStorage.removeItem(AuthenticationService.DISTANCEUNIT);
    this.userInfo = null;

    // If SSO user, logout the SSO session as well
    if (this.user) {
      this.logout().then(() => {
        if (environment.Debug) {
          console.log("Authentication: Redirecting");
        }
      });
    } else {
      if (redirect) {
        this.router.navigate(["/Login"]);
      }
    }
  }

  public isTokenValid(): boolean {
    const token = AuthenticationService.getStaticToken();
    const tokenExpired = AuthenticationService.getStaticExpiredToken();

    return this.tokenExpiryChecker(token, tokenExpired);
  }

  public isShareTokenValid(): boolean {
    const token = localStorage.getItem(AuthenticationService.SHARETOKEN);
    const tokenExpired = localStorage.getItem(AuthenticationService.SHARETOKEN_EXPIRED);

    return this.tokenExpiryChecker(token, tokenExpired);
  }

  private tokenExpiryChecker(token: string, tokenExpired: string): boolean {
    if (token != null && tokenExpired != null) {
      var now = new Date();
      var utc_timestamp = Date.UTC(
        now.getUTCFullYear(),
        now.getUTCMonth(),
        now.getUTCDate(),
        now.getUTCHours(),
        now.getUTCMinutes(),
        now.getUTCSeconds(),
        now.getUTCMilliseconds()
      );
      var expireDate = Date.parse(tokenExpired);

      if (utc_timestamp < expireDate) {
        if (environment.Debug) {
          console.log(`Token still valid for (${roundAsString((expireDate - utc_timestamp) / 1000, 0)} seconds)!`);
        }
        return true;
      }
    }

    if (environment.Debug) {
      console.log("Authentication: Token is invalid!");
    }
    return false;
  }

  async clearStaleSSOState(): Promise<void> {
    await this.manager.clearStaleState();
    if (environment.Debug) {
      console.log("state cleared");
    }
  }

  // Sharetokens
  public IsAuthenticatedShareToken(path: string): Observable<boolean> {
    if (environment.Debug) {
      console.log("Authentication: Testing if Token is expired...");
    }

    if (this.isShareTokenValid()) {
      return of(true);
    } else {
      this.router.navigate(["/Login"]);
      return of(false);
    }
  }

  public LoginShareToken(shareToken: string): Observable<AuthenticateShareTokenResult> {
    return this.performLoginWithShareToken(shareToken, environment).pipe(
      map((result) => {
        this.saveShareToken(result);
        return result;
      }),
      catchError((error) => {
        console.log(error);
        if (environment.Debug) {
          console.log("Authentication: Could not login with shareToken!");
        }
        this.router.navigate(["/Login"], { queryParams: { redirect: encodeURI("") } });
        return this.handleError(error);
      })
    );
  }

  performLoginWithShareToken(token: string, config: Config): Observable<AuthenticateShareTokenResult> {
    return this.http.post<AuthenticateShareTokenResult>(
      config.AuthenticationUrl + "Authentication/AuthenticateFromShareToken",
      { shareToken: token },
      { headers: this.unAuthenticatedHeaders }
    );
  }

  saveShareToken(authenticateResult: AuthenticateShareTokenResult) {
    localStorage.setItem(AuthenticationService.SHARETOKEN, authenticateResult.token);
    localStorage.setItem(AuthenticationService.SHARETOKEN_EXPIRED, authenticateResult.tokenExpiryTimestamp.toString());
    localStorage.setItem(AuthenticationService.CLUSTER_URL, environment.AuthenticationUrl);

    localStorage.setItem(AuthenticationService.WHITELABEL, authenticateResult.whiteLabel);
    localStorage.setItem(AuthenticationService.DISTANCEUNIT, authenticateResult.distanceUnit.toString());
    localStorage.setItem(AuthenticationService.TIMEZONEIANA, authenticateResult.timeZoneIana);
  }

  getTokenForUser(id: string): Observable<AuthenticateTokenResult> {
    return this.http
      .get<AuthenticateTokenResult>(environment.AuthenticationUrl + "Authentication/impersonate/" + id, {
        headers: this.headers,
      })
      .pipe(catchError(this.handleError));
  }

  impersonate(userId: string) {
    return this.getTokenForUser(userId).subscribe({
      next: (result) => {
        this.userInfo = null; // Reset user info

        this.setImpersonationToken(result);
        console.log("Impersonation successful!");
        this.router.navigate(["/"]);
        return of(true);
      },
      error: (error) => {
        console.error("Error during impersonation:", error);
        return of(false);
      },
    });
  }

  stopImpersonationwithRedirect() {
    this.stopImpersonation();
    this.router.navigate(["/"]);
  }

  private handleError(error: Response) {
    return throwError(() => error);
  }
}
