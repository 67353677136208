import { Component, OnInit, ViewChild, OnDestroy, ChangeDetectorRef } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { BsDaterangepickerConfig } from "ngx-bootstrap/datepicker";
import { getDefaultDpConfig, getGridButtons, getGridLanguages } from "app/common/gridhelper";

import { AuthenticationService } from "app/services/authentication/authentication.service";
import { TripService } from "app/services/trip/trip.service";
import { DeviceEpisode } from "app/common/enums";
import { GridBase360Directive } from "app/common/360Grid.base";
import { TriggerService } from "app/services/triggers/triggers.service";
import { EpisodeDetailsComponent } from "app/views/shared/usercontrols/episodeDetails.component";

// Moment
import Moment from "moment-timezone";
import { StorageHelper } from "app/common/storagehelper";
import { roundSeconds } from "app/common/globals";
import { ColorService } from "app/services/common/color.service";

window["moment"] = Moment;

@Component({
  selector: "fh-trigger-episodes",
  templateUrl: "episodes.template.html",
})
export class TriggerEpisodesViewComponent extends GridBase360Directive implements OnInit, OnDestroy {
  @ViewChild(EpisodeDetailsComponent, { static: false }) episodeDetailsComponent: EpisodeDetailsComponent;

  sub;
  trigger;
  loading = false;

  error: any;
  success: any;

  timezoneIana: string;

  limit = 1000;
  excludingColumns = ["timestamp", "episodeStart", "episodeEnd"];

  languageLoaded = false;
  episodesLoaded = false;

  // Datepicker
  public dpConfig: Partial<BsDaterangepickerConfig> = new BsDaterangepickerConfig();
  to: any;
  from: any;
  daterangepickerModel: any[];
  permissions: {};

  maxDate = new Date();

  randomKey: number;

  permissionName = "FleetManagement_Episodes";
  constructorName = "TriggerEpisodesViewComponent";

  theMarker;
  showWiderMap: boolean;
  activeEpisode: any;

  constructor(
    private cd: ChangeDetectorRef,
    private triggerService: TriggerService,
    private route: ActivatedRoute,
    private router: Router,
    private translateService: TranslateService,
    private authenticationService: AuthenticationService,
    private tripService: TripService,
    protected storageHelper: StorageHelper,
    private colorService: ColorService
  ) {
    super(storageHelper);

    this.permissions = this.authenticationService.permissions;

    this.trigger = null;

    this.timezoneIana = this.authenticationService.getTimeZoneIana();

    this.randomKey = Math.floor(Math.random() * (999999 - 100000)) + 100000;

    this.daterangepickerModel = [
      Moment().tz(this.timezoneIana).subtract(1, "weeks").startOf("day").toDate(),
      Moment().tz(this.timezoneIana).endOf("day").toDate(),
    ];

    this.dpConfig = getDefaultDpConfig(authenticationService);

    this.showWiderMap = localStorage.getItem("ShowWiderMap_Episodes") === "true";
  }

  ngOnInit() {
    this.loading = true;
    this.sub = this.route.params.subscribe((params) => {
      const id = params["id"];

      this.getTriggerInfo(id);
    });
  }

  getTriggerInfo(id = null) {
    if (this.loading !== true) {
      this.loading = true;
    }

    // Get from current triggert
    if (id == null) {
      id = this.trigger.id;
    }

    this.triggerService.getTriggerById(id).subscribe({
      next: (trigger) => {
        this.trigger = trigger;

        if (this.trigger == null) {
          this.router.navigate(["/Notifications/Triggers/Overview"]);
        }

        this.loading = false;

        this.translateService.get("general.date").subscribe((value) => {
          this.initGrid();
          this.languageLoaded = true;
        });
      },
      error: (error) => {
        this.error = error;
        this.error.statusText = "Error fetching trigger";

        setTimeout(() => {
          this.router.navigate(["/Notifications/Triggers/Overview"]);
        }, 3000);
      },
    });
  }

  dateChanged(event) {
    console.log("Changed date");

    if (event !== null) {
      this.episodesLoaded = true;

      this.datatableElement?.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.ajax
          .url(
            this.tripService.getEpisodeUrl(
              null,
              this.trigger.id,
              Moment.utc(this.daterangepickerModel[0]).tz(this.timezoneIana).startOf("day"),
              Moment.utc(this.daterangepickerModel[1]).tz(this.timezoneIana).endOf("day"),
              this.randomKey
            )
          )
          .load(() => (this.episodesLoaded = false));
      });
    }
  }

  initGrid(): void {
    const deviceEpisodeTypes = [];
    Object.values(DeviceEpisode)
      .filter((key) => isNaN(Number(DeviceEpisode[key])))
      .forEach((item, index) => {
        deviceEpisodeTypes.push({ id: item.toString(), value: DeviceEpisode[item].toString() });
      });

    this.columns = [
      {
        name: "id_export",
        data: "id",
        className: "noVis",
        title: this.translateService.instant("general.id"),
        visible: false,
      },
      {
        name: "episodeStart",
        data: "episodeStart",
        width: 130,
        title: this.translateService.instant("general.episodeStart"),
        type: "date",
        render: (data, type, row) => {
          const date = Moment.utc(data)["tz"](this.timezoneIana);
          return data
            ? '<span title=" ' + date.toLocaleString() + '">' + date.format("YYYY-MM-DD HH:mm:ss") + "</span>"
            : "";
        },
      },
      {
        name: "episodeEnd",
        data: "episodeEnd",
        title: this.translateService.instant("general.episodeEnd"),
        width: 130,
        type: "date",
        visible: false,
        render: (data, type, row) => {
          const date = Moment.utc(data)["tz"](this.timezoneIana);
          return data
            ? '<span title=" ' + date.toLocaleString() + '">' + date.format("YYYY-MM-DD HH:mm:ss") + "</span>"
            : "";
        },
      },
      {
        name: "durationInSeconds",
        data: "durationInSeconds",
        title: this.translateService.instant("general.duration"),
        type: "num",
        render: (data, type, row) => {
          if (type === "display") {
            return roundSeconds(data);
          }
          return data ?? "0";
        },
      },
      {
        name: "timestamp",
        data: "timestamp",
        title: this.translateService.instant("general.timestamp"),
        type: "date",
        width: 130,
        visible: false,
        render: (data, type, row) => {
          const date = Moment.utc(data)["tz"](this.timezoneIana);
          return data
            ? '<span title=" ' + date.toLocaleString() + '">' + date.format("YYYY-MM-DD HH:mm:ss") + "</span>"
            : "";
        },
      },
      {
        name: "assetName",
        data: "assetName",
        title: this.translateService.instant("general.assetName"),
        render: (data, type, row) => {
          return data
            ? `<a class="secondary link_bolder" href="/#/DeviceDetails/Index/${row.fkDeviceId}">${data}</a>`
            : "";
        },
      },
      {
        name: "description",
        data: "description",
        title: this.translateService.instant("general.description"),
        render: (data, type, row) => {
          return data ?? "-";
        },
      },

      {
        name: "fkDeviceEpisodeTypeId",
        data: "fkDeviceEpisodeTypeId",
        type: "select",
        options: deviceEpisodeTypes.sort((a, b) => a.value.localeCompare(b.value)),
        title: this.translateService.instant("general.episodeType"),
        render: (data, type, row) => {
          return this.translateService.instant("enums.deviceEpisode." + data);
        },
      },
      {
        name: "maxValue",
        data: "maxValue",
        width: 70,
        type: "num",
        visible: false,
        title: this.translateService.instant("general.maxValue"),
        render: (data, type, row) => {
          return data ?? "0";
        },
      },
      {
        name: "count",
        data: "count",
        width: 70,
        type: "num",
        title: this.translateService.instant("general.messages"),
        render: (data, type, row) => {
          return data ?? "0";
        },
      },
      {
        name: "companyName",
        data: "companyName",
        defaultContent: "-",
        visible: false,
        title: this.translateService.instant("general.companyName"),
      },
      {
        name: "accountId",
        data: "accountId",
        defaultContent: "-",
        title: this.translateService.instant("general.accountId"),
        visible: false,
      },
      {
        name: "resellerDescription",
        data: "resellerDescription",
        defaultContent: "-",
        title: this.translateService.instant("general.resellerDescription"),
        visible: false,
      },
    ];

    this.dtOptions = {
      buttons: getGridButtons(
        this.commonExportOptions,
        "episodes_overview",
        this.translateService.instant("menu.episodesoverview"),
        this.colorService
      ),
      pagingType: "simple_numbers",
      serverSide: true,
      processing: true,
      // scrollY: 500,
      // scroller: {
      //     loadingIndicator: true
      // },
      searchDelay: 2000,
      deferRender: true,
      scrollX: true,
      colReorder: { fixedColumnsLeft: 2 },
      deferLoading: 0,
      stateSave: true,
      stateSaveCallback: (settings, data) => {
        this.saveState(this.constructorName, data);
      },
      stateLoadCallback: (_, callback) => {
        (async () => {
          try {
            const columnSettings = await this.loadState(this.constructorName);
            this.searchTerm = columnSettings && columnSettings.search && columnSettings.search.search;
            return columnSettings;
          } catch (e) {
            this.error = {};
            this.error.error = e;
            this.error.statusText = "Error fetching column settings";

            return null;
          }
        })().then((result) => {
          callback(result);
        });
      },
      order: [[1, "desc"]],
      ajax: {
        beforeSend: () => {
          this.drawFilterRow();

          $(".dataTables_info").html(this.translateService.instant("grid.loadingData"));
        },
        url: this.tripService.getEpisodeUrl(
          null,
          this.trigger.id,
          Moment.utc(this.daterangepickerModel[0]).tz(this.timezoneIana).startOf("day"),
          Moment.utc(this.daterangepickerModel[1]).tz(this.timezoneIana).endOf("day"),
          this.randomKey
        ),
        data: (d) => {
          return d;
        },
        dataSrc: (json) => {
          return json.data;
        },
        type: "POST",
      },
      initComplete: (settings, json) => {
        this.loading = false;
        this.episodesLoaded = false;

        console.log("init complete");
        this.checkFilters();
        this.drawFilterRow();
        this.loading = false;
      },
      colVis: {
        restore: this.translateService.instant("general.restore"),
        showAll: this.translateService.instant("general.showAll"),
        showNone: this.translateService.instant("general.hideAll"),
      },
      columns: this.columns,
      pageLength: 17,
      lengthMenu: [
        [10, 17, 25, 50, -1],
        [10, 17, 25, 50, this.translateService.instant("general.all")],
      ],
      language: getGridLanguages(this.translateService),
      rowCallback: (row, data) => {
        this.setMapInteraction(this, row, data);

        if (data.beginLatitude != null && data.beginLongitude != null) {
          $(row).addClass("hand");
        }
      },
    };
  }

  setMapInteraction(table, row, data) {
    const theLatLon = data;

    this.activeEpisode = data;

    $(row).click(() => {
      this.episodeDetailsComponent.showOnMap(data);
    });
  }

  onWideChanged() {
    this.showWiderMap = this.episodeDetailsComponent.showWiderMap;
  }

  episodeDeleted() {
    this.dateChanged(false);

    this.error = null;
    this.success = {
      statusText: "Success",
      success: "Episode is deleted.",
    };
    this.cd.markForCheck();
  }
}
