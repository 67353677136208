import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { InventorySensorType } from "app/models/sensorType.model";
import { throwError } from "rxjs/internal/observable/throwError";
import { Observable, catchError } from "rxjs";
import { AuthenticationService } from "../authentication/authentication.service";

@Injectable()
export class SensorsTypesService {
  url = "";

  constructor(private http: HttpClient, private authenticationService: AuthenticationService) {
    this.url = this.authenticationService.getWebserviceURL("sensorType");
  }

  getSensorTypes(): Observable<InventorySensorType[]> {
    console.log("getting sensorTypes from service");
    return this.http
      .get<InventorySensorType[]>(this.url, { headers: this.authenticationService.headers })
      .pipe(catchError(this.handleError));
  }

  getSensorTypeById(id: string): Observable<InventorySensorType> {
    console.log("Fetch sensorType by id " + id);
    return this.http
      .get<InventorySensorType>(this.url + id, { headers: this.authenticationService.headers })
      .pipe(catchError(this.handleError));
  }

  saveSensorType(assetType: InventorySensorType): Observable<any> {
    console.log("save assetType");
    return this.http
      .post(this.url, assetType, { headers: this.authenticationService.headers })
      .pipe(catchError(this.handleError));
  }

  updateSensorType(assetType: InventorySensorType): Observable<any> {
    console.log("save assetType");
    return this.http
      .put(this.url, assetType, { headers: this.authenticationService.headers })
      .pipe(catchError(this.handleError));
  }

  private handleError(error: Response) {
    return throwError(() => error);
  }
}
