<div *ngIf="device" class="flex-container">
    <div class="col-6 col-lg-4" style="padding: 0" *ngIf="!device.asset?.isAssetTracker">
        <div
            title="DeviceState: '{{
            'general.deviceState_' + deviceState?.calculatedDeviceState?.deviceState | translate
        }}' since {{ deviceState?.calculatedDeviceState?.stateChangedTimestamp }}"
            class="kpi-item-small border-active"
            [ngClass]="colorMapper(deviceState?.calculatedDeviceState?.deviceState)"
        >
            <h3 class="margin-bottom font-bold">
                <i
                    class="fas fa-fw fa-map-marker-alt eye-active"
                    [ngClass]="colorMapper(deviceState?.calculatedDeviceState?.deviceState)"
                ></i>
                {{ "general.deviceState_" + deviceState?.calculatedDeviceState?.deviceState | translate }}
            </h3>
            <div *ngIf="deviceState?.calculatedDeviceState?.updateTimestamp; else noTimeStamp">
                {{ "general.stateSince" | translate }} {{ deviceState?.calculatedDeviceState?.stateChangedTimestamp |
                amTimeAgo : true }}
            </div>
            <ng-template #noTimeStamp> {{ "general.undefined" | translate }} </ng-template>
        </div>
    </div>
    <div
        class="col-6 col-lg-4"
        style="padding: 0"
        *ngIf="
        !device.asset?.isAssetTracker &&
        deviceState?.currentPosition?.speed != undefined &&
        deviceState?.currentPosition?.speed > 0
    "
    >
        <div
            title="Speed: '{{ deviceState?.currentPosition?.speed }}' on {{
            deviceState?.currentPosition?.updateTimestamp
        }}"
            class="kpi-item-small"
        >
            <h3 class="margin-bottom font-bold">{{ deviceState?.currentPosition?.speed }} {{ this.translatedKmh }}</h3>
            <div>{{ "general.speed" | translate }}</div>
        </div>
    </div>
    <div
        class="col-6 col-lg-4"
        style="padding: 0"
        *ngIf="
        !device.asset?.isAssetTracker &&
        deviceState?.currentPosition?.speed != undefined &&
        deviceState?.currentPosition?.speed > 0
    "
    >
        <div
            title="Heading: '{{ deviceState?.currentPosition?.heading }}' on {{
            deviceState?.currentPosition?.updateTimestamp
        }}"
            class="kpi-item-small"
        >
            <h3 class="margin-bottom font-bold">
                <i
                    *ngIf="deviceState"
                    [ngClass]="deviceState?.markerColor"
                    [ngStyle]="{
                    transform: 'rotate(' + deviceState?.currentPosition?.heading + 'deg)',
                    '-webkit-transform': 'rotate(' + deviceState?.currentPosition?.heading + 'deg)',
                    '-ms-transform': 'rotate(' + deviceState?.currentPosition?.heading + 'deg)'
                }"
                    class="full-icon fas fa-arrow-up eye-active"
                ></i>
                {{ deviceState?.currentPosition?.heading != undefined ? deviceState?.currentPosition?.heading : "-" }}°
            </h3>
            <div>{{ "general.heading" | translate }}</div>
        </div>
    </div>
    <div
        class="col-6 col-lg-4"
        style="padding: 0"
        *ngIf="
        deviceState?.externalBattery?.batteryLevelInVoltage != undefined &&
        device?.asset &&
        !device.asset?.isAssetTracker &&
        showPosition
    "
    >
        <div
            title="ExternalBattery: '{{ deviceState?.externalBattery?.batteryLevelInVoltage }}' on {{
            deviceState?.externalBattery?.updateTimestamp
        }}"
            class="kpi-item-small"
        >
            <h3 class="margin-bottom font-bold">
                <i
                    class="full-icon fas fa-fw fa-car-battery"
                    [ngStyle]="{
                    color: !deviceState?.externalBattery?.batteryLevelInVoltage
                        ? '#ccc'
                        : deviceState?.externalBattery?.batteryLevelInVoltage > 9
                        ? 'rgba(0,142,67,0.9)'
                        : 'rgba(151,28,36,0.9)'
                }"
                ></i>
                {{ deviceState?.externalBattery?.batteryLevelInVoltage != undefined ?
                actualRound(deviceState?.externalBattery?.batteryLevelInVoltage, 2).toLocaleString() : "-" }} V
            </h3>
            <div>{{ "general.battery" | translate }}</div>
        </div>
    </div>
    <div
        class="col-6 col-lg-4"
        style="padding: 0"
        *ngIf="deviceState?.batteryLevel?.batteryLevelPercentage != undefined && device.asset?.isAssetTracker"
    >
        <div
            title="ExternalBattery: '{{ deviceState?.batteryLevel?.batteryLevelPercentage }}' on {{
            deviceState?.batteryLevel?.updateTimestamp
        }}"
            class="kpi-item-small"
        >
            <h3 class="margin-bottom font-bold">
                <i
                    class="full-icon fas fa-fw fa-car-battery"
                    [ngStyle]="{
                    color: !deviceState?.batteryLevel?.batteryLevelPercentage
                        ? '#ccc'
                        : deviceState?.batteryLevel?.batteryLevelPercentage > 9
                        ? 'rgba(0,142,67,0.9)'
                        : 'rgba(151,28,36,0.9)'
                }"
                ></i>
                {{ deviceState?.batteryLevel?.batteryLevelPercentage != undefined ?
                actualRound(deviceState?.batteryLevel?.batteryLevelPercentage, 0).toLocaleString() : "-" }} %
            </h3>
            <div>{{ "general.battery" | translate }}</div>
        </div>
    </div>
    <div
        class="col-6 col-lg-4"
        style="padding: 0"
        *ngIf="deviceState?.temperature1 && deviceState?.temperature1.updateTimestamp > compareDate"
    >
        <div [title]="deviceState?.temperature1?.updateTimestamp" class="kpi-item-small">
            <h3 class="margin-bottom font-bold">
                <i
                    class="full-icon fas fa-fw fa-thermometer-three-quarters"
                    [ngClass]="
                    deviceState?.temperature1?.temperatureInCelcius == undefined &&
                    device.properties.sensorTemplateSettings.temp1 == undefined
                        ? ''
                        : checkSensorOnTempate(
                              deviceState?.temperature1?.temperatureInCelcius,
                              device.properties.sensorTemplateSettings.temp1
                          ) == true
                        ? 'eye-active green'
                        : 'fa-exclamation-triangle eye-active red'
                "
                ></i>
                {{ deviceState?.temperature1?.temperatureInCelcius.toLocaleString() }} °C
            </h3>
            <div>
                {{ "general.temperature" | translate }} 1
                <i
                    *ngIf="deviceState?.temperature1?.updateTimestamp < deviceState?.communicationState?.updateTimestamp"
                    class="fa fa-fw history-icon hand fa-history"
                    title="{{ deviceState?.temperature1?.updateTimestamp }}"
                ></i>
            </div>
        </div>
    </div>
    <div
        class="col-6 col-lg-4"
        style="padding: 0"
        *ngIf="deviceState?.temperature2 && deviceState?.temperature2.updateTimestamp > compareDate"
    >
        <div class="kpi-item-small">
            <h3 [title]="deviceState?.temperature2?.updateTimestamp" class="margin-bottom font-bold">
                <i
                    class="full-icon fas fa-fw fa-thermometer-three-quarters"
                    [ngClass]="
                    deviceState?.temperature2?.temperatureInCelcius == undefined &&
                    device.properties.sensorTemplateSettings.temp2 == undefined
                        ? ''
                        : checkSensorOnTempate(
                              deviceState?.temperature2?.temperatureInCelcius,
                              device.properties.sensorTemplateSettings.temp2
                          ) == true
                        ? 'eye-active green'
                        : 'fa-exclamation-triangle eye-active red'
                "
                ></i>
                {{ deviceState?.temperature2?.temperatureInCelcius.toLocaleString() }} °C
            </h3>
            <div>
                {{ "general.temperature" | translate }} 2
                <i
                    *ngIf="deviceState?.temperature2?.updateTimestamp < deviceState?.communicationState?.updateTimestamp"
                    class="fa fa-fw history-icon hand fa-history"
                    title="{{ deviceState?.temperature2?.updateTimestamp }}"
                ></i>
            </div>
        </div>
    </div>
    <div
        class="col-6 col-lg-4"
        style="padding: 0"
        *ngIf="deviceState?.temperature3 && deviceState?.temperature3.updateTimestamp > compareDate"
    >
        <div [title]="deviceState?.temperature3?.updateTimestamp" class="kpi-item-small">
            <h3 class="margin-bottom font-bold">
                <i
                    class="full-icon fas fa-fw fa-thermometer-three-quarters"
                    [ngClass]="
                    deviceState?.temperature3?.temperatureInCelcius == undefined &&
                    device.properties.sensorTemplateSettings.temp3 == undefined
                        ? ''
                        : checkSensorOnTempate(
                              deviceState?.temperature3?.temperatureInCelcius,
                              device.properties.sensorTemplateSettings.temp3
                          ) == true
                        ? 'eye-active green'
                        : 'fa-exclamation-triangle eye-active red'
                "
                ></i>
                {{ deviceState?.temperature3?.temperatureInCelcius.toLocaleString() }} °C
            </h3>
            <div>
                {{ "general.temperature" | translate }} 3
                <i
                    *ngIf="deviceState?.temperature3?.updateTimestamp < deviceState?.communicationState?.updateTimestamp"
                    class="fa fa-fw history-icon hand fa-history"
                    title="{{ deviceState?.temperature3?.updateTimestamp }}"
                ></i>
            </div>
        </div>
    </div>
    <div
        class="col-6 col-lg-4"
        style="padding: 0"
        *ngIf="deviceState?.temperature4 && deviceState?.temperature4.updateTimestamp > compareDate"
    >
        <div [title]="deviceState?.temperature4?.updateTimestamp" class="kpi-item-small">
            <h3 class="margin-bottom font-bold">
                <i
                    class="full-icon fas fa-fw fa-thermometer-three-quarters"
                    [ngClass]="
                    deviceState?.temperature4?.temperatureInCelcius == undefined &&
                    device.properties.sensorTemplateSettings.temp4 == undefined
                        ? ''
                        : checkSensorOnTempate(
                              deviceState?.temperature4?.temperatureInCelcius,
                              device.properties.sensorTemplateSettings.temp4
                          ) == true
                        ? 'eye-active green'
                        : 'fa-exclamation-triangle eye-active red'
                "
                ></i>
                {{ deviceState?.temperature4?.temperatureInCelcius.toLocaleString() }} °C
            </h3>
            <div>
                {{ "general.temperature" | translate }} 4
                <i
                    *ngIf="deviceState?.temperature4?.updateTimestamp < deviceState?.communicationState?.updateTimestamp"
                    class="fa fa-fw history-icon hand fa-history"
                    title="{{ deviceState?.temperature4?.updateTimestamp }}"
                ></i>
            </div>
        </div>
    </div>
    <div
        class="col-6 col-lg-4"
        style="padding: 0"
        *ngIf="deviceState?.engineCoolantTemperature && deviceState?.engineCoolantTemperature.updateTimestamp > compareDate"
    >
        <div [title]="deviceState?.engineCoolantTemperature?.updateTimestamp" class="kpi-item-small">
            <h3 class="margin-bottom font-bold">
                <i
                    class="full-icon fas fa-fw fa-thermometer-three-quarters"
                    [ngStyle]="{ color: getEngineColor(deviceState?.engineCoolantTemperature?.temperatureInCelcius) }"
                ></i>
                {{ deviceState?.engineCoolantTemperature?.temperatureInCelcius.toLocaleString() }} °C
            </h3>
            <div>
                {{ "general.engineCoolantTemperature" | translate }}
                <i
                    *ngIf="
                    deviceState?.engineCoolantTemperature?.updateTimestamp <
                    deviceState?.communicationState?.updateTimestamp
                "
                    class="fa fa-fw history-icon hand fa-history"
                    title="{{ deviceState?.engineCoolantTemperature?.updateTimestamp }}"
                ></i>
            </div>
        </div>
    </div>
    <div
        class="col-6 col-lg-4"
        style="padding: 0"
        *ngIf="deviceState?.fuel && deviceState?.fuel.updateTimestamp > compareDate"
    >
        <div [title]="deviceState?.fuel?.updateTimestamp" class="kpi-item-small">
            <h3 class="margin-bottom font-bold">
                <i
                    class="full-icon fas fa-fw fa-gas-pump"
                    [ngStyle]="{ color: getFuelColor(deviceState?.fuel?.fuelLevelInPercentage) }"
                ></i>
                {{ actualRound(deviceState?.fuel?.fuelLevelInPercentage, 0).toLocaleString() }}%
            </h3>
            <div>
                {{ "general.fuel" | translate }}
                <i
                    *ngIf="deviceState?.fuel?.updateTimestamp < deviceState?.communicationState?.updateTimestamp"
                    class="fa fa-fw history-icon hand fa-history"
                    title="{{ deviceState?.fuel?.updateTimestamp }}"
                ></i>
            </div>
        </div>
    </div>
    <div
        class="col-6 col-lg-4"
        style="padding: 0"
        *ngIf="deviceState?.fuel1 && deviceState?.fuel1.updateTimestamp > compareDate"
    >
        <div [title]="deviceState?.fuel1?.updateTimestamp" class="kpi-item-small">
            <h3 class="margin-bottom font-bold">
                <i
                    class="full-icon fas fa-fw fa-gas-pump"
                    [ngClass]="
                    deviceState?.fuel1?.fuelLevelInPercentage == undefined &&
                    device.properties.sensorTemplateSettings.fuel1 == undefined
                        ? ''
                        : checkSensorOnTempate(
                              deviceState?.fuel1?.fuelLevelInPercentage,
                              device.properties.sensorTemplateSettings.fuel1
                          ) == true
                        ? 'eye-active green'
                        : 'fa-exclamation-triangle eye-active red'
                "
                ></i>

                {{ actualRound(deviceState?.fuel1?.fuelLevelInPercentage, 0).toLocaleString() }}%
            </h3>
            <div>
                {{ "general.fuel" | translate }} 2
                <i
                    *ngIf="deviceState?.fuel1?.updateTimestamp < deviceState?.communicationState?.updateTimestamp"
                    class="fa fa-fw history-icon hand fa-history"
                    title="{{ deviceState?.fuel1?.updateTimestamp }}"
                ></i>
            </div>
        </div>
    </div>
    <div
        class="col-6 col-lg-4"
        style="padding: 0"
        *ngIf="deviceState?.fuel2 && deviceState?.fuel2.updateTimestamp > compareDate"
    >
        <div [title]="deviceState?.fuel2?.updateTimestamp" class="kpi-item-small">
            <h3 class="margin-bottom font-bold">
                <i class="full-icon fas fa-fw fa-gas-pump"></i>
                {{ actualRound(deviceState?.fuel2?.fuelLevelInPercentage, 0).toLocaleString() }}%
            </h3>
            <div>
                {{ "general.fuel" | translate }} 2
                <i
                    *ngIf="deviceState?.fuel2?.updateTimestamp < deviceState?.communicationState?.updateTimestamp"
                    class="fa fa-fw history-icon hand fa-history"
                    title="{{ deviceState?.fuel2?.updateTimestamp }}"
                ></i>
            </div>
        </div>
    </div>
    <div
        class="col-6 col-lg-4"
        style="padding: 0"
        *ngIf="
        deviceState?.humidity1 &&
        deviceState?.humidity1.humidityInPercent > 0 &&
        deviceState?.humidity1.updateTimestamp > compareDate
    "
    >
        <div [title]="deviceState?.humidity1?.updateTimestamp" class="kpi-item-small">
            <h3 class="margin-bottom font-bold">
                <i
                    class="full-icon fas fa-fw fa-humidity"
                    [ngClass]="
                    deviceState?.humidity1?.humidityInPercent == undefined &&
                    device.properties.sensorTemplateSettings.humidity1 == undefined
                        ? ''
                        : checkSensorOnTempate(
                              deviceState?.humidity1?.humidityInPercent,
                              device.properties.sensorTemplateSettings.humidity1
                          ) == true
                        ? 'eye-active green'
                        : 'fa-exclamation-triangle eye-active red'
                "
                ></i>

                {{ deviceState?.humidity1?.humidityInPercent.toLocaleString() }} %
            </h3>
            <div>
                {{ "general.humidity" | translate }} 1
                <i
                    *ngIf="deviceState?.humidity1?.updateTimestamp < deviceState?.communicationState?.updateTimestamp"
                    class="fa fa-fw history-icon hand fa-history"
                    title="{{ deviceState?.humidity1?.updateTimestamp }}"
                ></i>
            </div>
        </div>
    </div>
    <div
        class="col-6 col-lg-4"
        style="padding: 0"
        *ngIf="deviceState?.humidity2 && deviceState?.humidity2.updateTimestamp > compareDate"
    >
        <div [title]="deviceState?.humidity2?.updateTimestamp" class="kpi-item-small">
            <h3 class="margin-bottom font-bold">
                <i
                    class="full-icon fas fa-fw fa-humidity"
                    [ngClass]="
                    deviceState?.humidity2?.humidityInPercent == undefined &&
                    device.properties.sensorTemplateSettings.humidity2 == undefined
                        ? ''
                        : checkSensorOnTempate(
                              deviceState?.humidity2?.humidityInPercent,
                              device.properties.sensorTemplateSettings.humidity2
                          ) == true
                        ? 'eye-active green'
                        : 'fa-exclamation-triangle eye-active red'
                "
                ></i>

                {{ deviceState?.humidity2?.humidityInPercent.toLocaleString() }} %
            </h3>
            <div>
                {{ "general.humidity" | translate }} 2
                <i
                    *ngIf="deviceState?.humidity2?.updateTimestamp < deviceState?.communicationState?.updateTimestamp"
                    class="fa fa-fw history-icon hand fa-history"
                    title="{{ deviceState?.humidity2?.updateTimestamp }}"
                ></i>
            </div>
        </div>
    </div>
    <div
        class="col-6 col-lg-4"
        style="padding: 0"
        *ngIf="deviceState?.totalAxleWeight && deviceState?.totalAxleWeight.updateTimestamp > compareDate"
    >
        <div [title]="deviceState?.totalAxleWeight?.updateTimestamp" class="kpi-item-small">
            <h3 class="margin-bottom font-bold">
                <i class="full-icon fas fa-fw fa-weight"></i>
                {{ deviceState?.totalAxleWeight?.totalWeightInKg.toLocaleString() }} kg
            </h3>
            <div>
                {{ "general.totalWeight" | translate }}
                <i
                    *ngIf="deviceState?.totalAxleWeight?.updateTimestamp < deviceState?.communicationState?.updateTimestamp"
                    class="fa fa-fw history-icon hand fa-history"
                    title="{{ deviceState?.totalAxleWeight?.updateTimestamp }}"
                ></i>
            </div>
        </div>
    </div>
    <div
        class="col-6 col-lg-4"
        style="padding: 0"
        *ngIf="deviceState?.rpmStatus?.rpm != undefined && deviceState?.rpmStatus?.updateTimestamp > compareDate"
    >
        <div [title]="deviceState?.rpmStatus?.updateTimestamp" class="kpi-item-small">
            <h3 class="margin-bottom font-bold">
                <i
                    class="full-icon fas fa-fw fa-engine"
                    [ngClass]="
                    deviceState?.rpmStatus?.rpm == undefined &&
                    device.properties.sensorTemplateSettings.rpm1 == undefined
                        ? ''
                        : checkSensorOnTempate(
                              deviceState?.rpmStatus?.rpm,
                              device.properties.sensorTemplateSettings.rpm1
                          ) == true
                        ? 'eye-active green'
                        : 'fa-exclamation-triangle eye-active red'
                "
                ></i>

                {{ deviceState?.rpmStatus?.rpm ? deviceState?.rpmStatus?.rpm.toLocaleString() : "-" }}
            </h3>
            <div>
                {{ "general.rpm" | translate }}
                <i
                    *ngIf="deviceState?.rpmStatus?.updateTimestamp < deviceState?.communicationState?.updateTimestamp"
                    class="fa fa-fw history-icon hand fa-history"
                    title="{{ deviceState?.rpmStatus?.updateTimestamp }}"
                ></i>
            </div>
        </div>
    </div>
    <div class="col-6 col-lg-4" style="padding: 0" *ngIf="deviceState?.driverIdTagStatus?.tag != undefined && includeDriver">
        <div [title]="deviceState?.driverIdTagStatus?.updateTimestamp" class="kpi-item-small">
            <h3 class="margin-bottom font-bold">
                <a
                    *ngIf="permissions['Drivers_View'] && device.scannedDriver"
                    class="secondary link_bolder"
                    href="/#/DriverDetails/Index/{{ device?.scannedDriver?.id }}"
                >
                    <i class="full-icon fas fa-fw fa-tag"></i>
                    {{ device?.scannedDriver?.name }}</a
                >

                <span *ngIf="!device.scannedDriver">
                    <i class="fas fa-fw fa-tag"></i> {{ deviceState?.driverIdTagStatus?.tag.toLocaleString() }}</span
                >
            </h3>
            <div>
                {{ "general.scannedDriver" | translate }}
                <i
                    *ngIf="deviceState?.driverIdTagStatus?.updateTimestamp < deviceState?.communicationState?.updateTimestamp"
                    class="fa fa-fw history-icon hand fa-history"
                    title="{{ deviceState?.driverIdTagStatus?.updateTimestamp }}"
                ></i>
            </div>
        </div>
    </div>
    <div class="col-6 col-lg-4" style="padding: 0" *ngIf="!deviceState?.driverIdTagStatus?.tag && device?.asset?.driver && permissions['Drivers_View'] && includeDriver">
        <div class="kpi-item-small">
            <h3 class="margin-bottom font-bold">
                <a class="secondary link_bolder" href="/#/DriverDetails/Index/{{ device?.asset?.driver?.id }}">
                    {{ device?.asset?.driver?.name }}</a
                >
            </h3>
            <div>{{ "general.assignedDriver" | translate }}</div>
        </div>
    </div>
    <div class="col-6 col-lg-4" style="padding: 0" *ngIf="deviceState?.tagScanStatus?.tag != undefined && deviceState?.driverIdTagStatus?.tag == undefined && showPosition && includeDriver">
        <div [title]="deviceState?.tagScanStatus?.updateTimestamp" class="kpi-item-small">
            <h3 class="margin-bottom font-bold">
                <a
                    *ngIf="permissions['Drivers_View'] && device.scannedDriver"
                    class="secondary link_bolder"
                    href="/#/DriverDetails/Index/{{ device?.scannedDriver?.id }}"
                >
                    <i class="full-icon fas fa-fw fa-tag"></i>
                    {{ device?.scannedDriver?.name }}</a
                >

                <span *ngIf="!device.scannedDriver">
                    <i class="fas fa-fw fa-tag"></i> {{ deviceState?.tagScanStatus?.tag.toLocaleString() }}</span
                >
            </h3>
            <div>
                {{ "general.scannedDriver" | translate }}
                <i
                    *ngIf="deviceState?.tagScanStatus?.updateTimestamp < deviceState?.communicationState?.updateTimestamp"
                    class="fa fa-fw history-icon hand fa-history"
                    title="{{ deviceState?.tagScanStatus?.updateTimestamp }}"
                ></i>
            </div>
        </div>
    </div>
    <div
        class="col-6 col-lg-4"
        style="padding: 0"
        *ngIf="device?.asset && !device.asset?.isAssetTracker && !deviceState?.odometer?.canBusOdometer"
    >
        <div class="kpi-item-small" [title]="deviceState?.odometer?.updateTimestamp">
            <h3 class="margin-bottom font-bold">
                {{ deviceState?.odometer?.gpsOdometer !== null ? actualRound( (device?.asset?.calibrationOdoOffsetInKm
                ?? 0) + deviceState?.odometer?.gpsOdometer / 1000, 0 ).toLocaleString() : "-" }} {{ translatedKm }}
            </h3>
            <div>
                {{ "general.assetOdo" | translate }}
                <i
                    *ngIf="device?.asset?.calibrationOdoOffsetInKm === null && !hasCanbus"
                    class="fa fa-fw warning fa-exclamation-triangle"
                    title="{{ 'general.addCalibration' | translate }}"
                ></i>
            </div>
        </div>
    </div>
    <div
        class="col-6 col-lg-4"
        style="padding: 0"
        *ngIf="device?.asset && permissions['FleetManagement_EngineHourView'] && !device.asset?.isAssetTracker && showPosition"
    >
        <div
            [title]="deviceState?.engineHour?.updateTimestamp"
            class="kpi-item-small"
            [title]="deviceState?.engineHour?.updateTimestamp"
        >
            <h3 class="margin-bottom font-bold">
                {{ device?.engineHourRunningInSeconds !== null ? actualRound(
                ((device?.asset?.engineHourCalibrationOdoOffset ?? 0) + device?.engineHourRunningInSeconds) / 3600, 0
                ).toLocaleString() : "-" }} H
            </h3>
            <div>{{ "general.engineHours" | translate }}</div>
        </div>
    </div>
    <div
        class="col-6 col-lg-4"
        style="padding: 0"
        *ngIf="device?.asset && deviceState?.odometer?.canBusOdometer && !device.asset?.isAssetTracker"
    >
        <div [title]="deviceState?.odometer?.updateTimestamp" class="kpi-item-small">
            <h3 class="margin-bottom font-bold">
                {{ actualRound(deviceState?.odometer?.canBusOdometer / 1000, 0).toLocaleString() }} {{ translatedKm }}
            </h3>
            <div>{{ "general.canbusOdo" | translate }}</div>
        </div>
    </div>
    <div class="col-6 col-lg-4" style="padding: 0" *ngIf="device?.asset?.activeProject && permissions['Projects_View']">
        <div class="kpi-item-small">
            <h3 class="margin-bottom font-bold">
                <a class="secondary link_bolder" href="/#/ProjectDetails/Index/{{ device?.asset?.activeProject?.id }}">
                    {{ device?.asset?.activeProject?.name }}</a
                >
            </h3>
            <div>{{ "general.assignedProject" | translate }}</div>
        </div>
    </div>
    <div class="col-6 col-lg-4" style="padding: 0">
        <div [title]="deviceState?.currentPosition?.updateTimestamp" class="kpi-item-small">
            <h3 [ngSwitch]="true" class="margin-bottom font-bold">
                <ng-container
                    *ngSwitchCase="
                    deviceState?.hasGpsFix == false &&
                    deviceState?.hasCellFix == true &&
                    deviceState?.cellPosition != null &&
                    !(deviceState?.cellPosition.latitude === 0 && deviceState?.cellPosition.longitude === 0)
                "
                >
                    <i
                        title="Cell Position"
                        class="full-icon fas fa-fw fa-location-crosshairs-slash"
                        [ngStyle]="{ color: 'rgba(151,28,36,0.9)' }"
                    ></i>
                    <a
                        target="_blank"
                        rel="nofollow"
                        href="https://www.google.com/maps/place/{{ deviceState?.cellPosition?.latitude }}+{{
                        deviceState?.cellPosition?.longitude
                    }}/@{{ deviceState?.cellPosition?.latitude }},{{ deviceState?.cellPosition?.longitude }},15z"
                    >
                        {{ deviceState?.cellPosition?.latitude.toLocaleString("en-US", { minimumFractionDigits: 2 }) +
                        "," + deviceState?.cellPosition?.longitude.toLocaleString("en-US", { minimumFractionDigits: 2 })
                        }}
                    </a>
                </ng-container>
                <ng-container *ngSwitchCase="deviceState?.hasGpsFix == true && deviceState?.currentPosition != null">
                    <i
                        title="GPS Position"
                        class="full-icon fas fa-fw fa-location-crosshairs"
                        [ngStyle]="{ color: 'rgba(0,142,67,0.9)' }"
                    ></i>
                    <a
                        target="_blank"
                        rel="nofollow"
                        href="https://www.google.com/maps/place/{{ deviceState?.currentPosition?.latitude }}+{{
                        deviceState?.currentPosition?.longitude
                    }}/@{{ deviceState?.currentPosition?.latitude }},{{ deviceState?.currentPosition?.longitude }},15z"
                    >
                        {{ deviceState?.currentPosition?.latitude.toLocaleString("en-US", { minimumFractionDigits: 2 })
                        + ", " + deviceState?.currentPosition?.longitude.toLocaleString("en-US", {
                        minimumFractionDigits: 2 }) }}
                    </a>
                </ng-container>
                <ng-container *ngSwitchCase="deviceState?.hasGpsFix == false && deviceState?.currentPosition != null">
                    <i
                        title="Outdated position"
                        class="full-icon fas fa-fw fa-location-crosshairs-slash"
                        [ngStyle]="{ color: 'rgba(151,28,36,0.9)' }"
                    ></i>
                    <a
                        target="_blank"
                        rel="nofollow"
                        href="https://www.google.com/maps/place/{{ deviceState?.currentPosition?.latitude }}+{{
                        deviceState?.currentPosition?.longitude
                    }}/@{{ deviceState?.currentPosition?.latitude }},{{ deviceState?.currentPosition?.longitude }},15z"
                    >
                        {{ deviceState?.currentPosition?.latitude.toLocaleString("en-US", { minimumFractionDigits: 2 })
                        + "," + deviceState?.currentPosition?.longitude.toLocaleString("en-US", { minimumFractionDigits:
                        2 }) }}
                    </a>
                </ng-container>
                <ng-container *ngSwitchDefault> &ndash; </ng-container>
            </h3>
            <div>
                {{ "general.location" | translate }}
                <i
                    *ngIf="
                    deviceState?.currentPosition != null &&
                    deviceState?.currentPosition?.updateTimestamp < deviceState?.communicationState?.updateTimestamp
                "
                    class="fa fa-fw history-icon hand fa-history"
                    title="{{ deviceState?.currentPosition?.updateTimestamp }}"
                ></i>
            </div>
        </div>
    </div>
    <div *ngIf="showPosition && deviceState?.currentPosition?.satellites" class="col-6 col-lg-4" style="padding: 0">
        <div [title]="deviceState?.currentPosition?.updateTimestamp" class="kpi-item-small">
            <h3 class="margin-bottom font-bold">
                {{ deviceState?.currentPosition?.satellites ? deviceState?.currentPosition?.satellites.toLocaleString()
                : "-" }}
            </h3>
            <div>{{ "general.satellites" | translate }}</div>
        </div>
    </div>
    <div *ngIf="showPosition && deviceState?.currentPosition?.hdop" class="col-6 col-lg-4" style="padding: 0">
        <div [title]="deviceState?.currentPosition?.updateTimestamp" class="kpi-item-small">
            <h3 class="margin-bottom font-bold">
                {{ deviceState?.currentPosition?.hdop ? deviceState?.currentPosition?.hdop.toLocaleString() : "-" }}
            </h3>
            <div>{{ "general.hdop" | translate }}</div>
        </div>
    </div>
    <div *ngIf="showPosition && deviceState?.currentPosition?.pdop" class="col-6 col-lg-4" style="padding: 0">
        <div [title]="deviceState?.currentPosition?.updateTimestamp" class="kpi-item-small">
            <h3 class="margin-bottom font-bold">
                {{ deviceState?.currentPosition?.pdop ? deviceState?.currentPosition?.pdop.toLocaleString() : "-" }}
            </h3>
            <div>{{ "general.pdop" | translate }}</div>
        </div>
    </div>
    <div *ngIf="showPosition && deviceState?.currentPosition?.radiusInMeters" class="col-6 col-lg-4" style="padding: 0">
        <div [title]="deviceState?.currentPosition?.updateTimestamp" class="kpi-item-small">
            <h3 class="margin-bottom font-bold">
                {{ deviceState?.currentPosition?.radiusInMeters ?
                deviceState?.currentPosition?.radiusInMeters.toLocaleString() + " m" : "-" }}
            </h3>
            <div>{{ "general.radiusInMeters" | translate }}</div>
        </div>
    </div>
    <div
        *ngIf="showPosition && deviceState?.actualAccellerationForce != undefined"
        class="col-6 col-lg-4"
        style="padding: 0"
    >
        <div [title]="deviceState?.actualAccellerationForce?.updateTimestamp" class="kpi-item-small">
            <h3 class="margin-bottom font-bold">
                {{ deviceState?.actualAccellerationForce != undefined ?
                deviceState?.actualAccellerationForce.toLocaleString() + " mg" : "-" }}
            </h3>
            <div>{{ "general.actualAccellerationForce" | translate }}</div>
        </div>
    </div>
    <div *ngIf="showPosition && deviceState?.actualBrakingForce != undefined" class="col-6 col-lg-4" style="padding: 0">
        <div [title]="deviceState?.actualAccellerationForce?.updateTimestamp" class="kpi-item-small">
            <h3 class="margin-bottom font-bold">
                {{ deviceState?.actualBrakingForce != undefined ? deviceState?.actualBrakingForce.toLocaleString() + "
                mg" : "-" }}
            </h3>
            <div>{{ "general.actualBrakingForce" | translate }}</div>
        </div>
    </div>
    <div
        *ngIf="showPosition && deviceState?.actualCorneringForce != undefined"
        class="col-6 col-lg-4"
        style="padding: 0"
    >
        <div [title]="deviceState?.actualAccellerationForce?.updateTimestamp" class="kpi-item-small">
            <h3 class="margin-bottom font-bold">
                {{ deviceState?.actualCorneringForce != undefined ? deviceState?.actualCorneringForce.toLocaleString() +
                " mg" : "-" }}
            </h3>
            <div>{{ "general.actualCorneringForce" | translate }}</div>
        </div>
    </div>

    <div
        *ngIf="
        showPosition &&
        deviceState?.fuelConsumptionState?.fuelConsumed != undefined &&
        deviceState?.fuelConsumptionState?.fuelConsumed != 0
    "
        class="col-6 col-lg-4"
        style="padding: 0"
    >
        <div class="kpi-item-small">
            <h3 class="margin-bottom font-bold">
                {{ deviceState?.fuelConsumptionState?.fuelConsumed != undefined ?
                deviceState?.fuelConsumptionState?.fuelConsumed.toLocaleString() + " L" : "-" }}
            </h3>
            <div>{{ "general.fuelConsumed" | translate }}</div>
        </div>
    </div>

    <div
        *ngIf="
        showPosition &&
        deviceState?.batteryConsumptionState?.batteryChargeLevelInPercentage != undefined &&
        deviceState?.batteryConsumptionState?.batteryChargeLevelInPercentage != 0
    "
        class="col-6 col-lg-4"
        style="padding: 0"
    >
        <div class="kpi-item-small">
            <h3 class="margin-bottom font-bold">
                {{ deviceState?.batteryConsumptionState?.batteryChargeLevelInPercentage != undefined ?
                deviceState?.batteryConsumptionState?.batteryChargeLevelInPercentage.toLocaleString() + "%" : "-" }}
            </h3>
            <div>{{ "general.batteryChargeLevelInPercentage" | translate }}</div>
        </div>
    </div>

    <div
        *ngIf="showPosition && deviceState?.batteryConsumptionState?.batteryChargeState != undefined"
        class="col-6 col-lg-4"
        style="padding: 0"
    >
        <div class="kpi-item-small">
            <h3 class="margin-bottom font-bold">
                <i
                    class="full-icon fas fa-fw fa-car-battery"
                    [ngStyle]="{
                    color:
                        deviceState?.batteryConsumptionState?.batteryChargeState == null
                            ? '#ccc'
                            : deviceState?.batteryConsumptionState?.batteryChargeState
                            ? 'rgba(0,142,67,0.9)'
                            : 'rgba(151,28,36,0.9)'
                }"
                ></i>
                {{ "general." + deviceState?.batteryConsumptionState?.batteryChargeState | translate }}
            </h3>
            <div>{{ "general.batteryChargeState" | translate }}</div>
        </div>
    </div>

    <div
        *ngIf="
        showPosition &&
        deviceState?.batteryConsumptionState?.batteryPowerConsumptionInKWhPer100Km != undefined &&
        deviceState?.batteryConsumptionState?.batteryPowerConsumptionInKWhPer100Km != 0
    "
        class="col-6 col-lg-4"
        style="padding: 0"
    >
        <div class="kpi-item-small">
            <h3 class="margin-bottom font-bold">
                {{ deviceState?.batteryConsumptionState?.batteryPowerConsumptionInKWhPer100Km != undefined ?
                deviceState?.batteryConsumptionState?.batteryPowerConsumptionInKWhPer100Km.toLocaleString() +
                "KWhPer100Km " : "-" }}
            </h3>
            <div>{{ "general.batteryPowerConsumptionInKWhPer100Km" | translate }}</div>
        </div>
    </div>

    <div
        *ngIf="
        showPosition &&
        deviceState?.batteryConsumptionState?.remainingDistanceInKm != undefined &&
        deviceState?.batteryConsumptionState?.remainingDistanceInKm != 0
    "
        class="col-6 col-lg-4"
        style="padding: 0"
    >
        <div class="kpi-item-small">
            <h3 class="margin-bottom font-bold">
                {{ deviceState?.batteryConsumptionState?.remainingDistanceInKm != undefined ?
                deviceState?.batteryConsumptionState?.remainingDistanceInKm.toLocaleString() + " " + translatedKm : "-"
                }}
            </h3>
            <div>{{ "general.remainingDistanceInKm" | translate }}</div>
        </div>
    </div>

    <div *ngIf="showPosition && deviceState?.angle1InDegrees != undefined" class="col-6 col-lg-4" style="padding: 0">
        <div [title]="deviceState?.angle1InDegrees?.updateTimestamp" class="kpi-item-small">
            <h3 class="margin-bottom font-bold">
                <i
                    [ngStyle]="{
                    transform: 'rotate(' + deviceState?.angle1InDegrees + 'deg)',
                    '-webkit-transform': 'rotate(' + deviceState?.angle1InDegrees + 'deg)',
                    '-ms-transform': 'rotate(' + deviceState?.angle1InDegrees + 'deg)'
                }"
                    class="full-icon fas fa-fw fa-arrow-up"
                ></i>

                {{ deviceState?.angle1InDegrees != undefined ? deviceState?.angle1InDegrees.toLocaleString() + " °" :
                "-" }}
            </h3>
            <div>{{ "general.angle1InDegrees" | translate }}</div>
        </div>
    </div>

    <div *ngIf="showPosition && deviceState?.angle2InDegrees != undefined" class="col-6 col-lg-4" style="padding: 0">
        <div [title]="deviceState?.angle2InDegrees?.updateTimestamp" class="kpi-item-small">
            <h3 class="margin-bottom font-bold">
                <i
                    [ngStyle]="{
                    transform: 'rotate(' + deviceState?.angle2InDegrees + 'deg)',
                    '-webkit-transform': 'rotate(' + deviceState?.angle2InDegrees + 'deg)',
                    '-ms-transform': 'rotate(' + deviceState?.angle2InDegrees + 'deg)'
                }"
                    class="full-icon fas fa-fw fa-arrow-up"
                ></i>

                {{ deviceState?.angle2InDegrees != undefined ? deviceState?.angle2InDegrees.toLocaleString() + " °" :
                "-" }}
            </h3>
            <div>{{ "general.angle2InDegrees" | translate }}</div>
        </div>
    </div>

    <div *ngIf="showPosition && deviceState?.angle3InDegrees != undefined" class="col-6 col-lg-4" style="padding: 0">
        <div [title]="deviceState?.angle3InDegrees?.updateTimestamp" class="kpi-item-small">
            <h3 class="margin-bottom font-bold">
                <i
                    [ngStyle]="{
                    transform: 'rotate(' + deviceState?.angle3InDegrees + 'deg)',
                    '-webkit-transform': 'rotate(' + deviceState?.angle3InDegrees + 'deg)',
                    '-ms-transform': 'rotate(' + deviceState?.angle3InDegrees + 'deg)'
                }"
                    class="full-icon fas fa-fw fa-arrow-up"
                ></i>

                {{ deviceState?.angle3InDegrees != undefined ? deviceState?.angle3InDegrees.toLocaleString() + " °" :
                "-" }}
            </h3>
            <div>{{ "general.angle3InDegrees" | translate }}</div>
        </div>
    </div>

    <div *ngIf="showPosition && deviceState?.angle4InDegrees != undefined" class="col-6 col-lg-4" style="padding: 0">
        <div [title]="deviceState?.angle4InDegrees?.updateTimestamp" class="kpi-item-small">
            <h3 class="margin-bottom font-bold">
                <i
                    [ngStyle]="{
                    transform: 'rotate(' + deviceState?.angle4InDegrees + 'deg)',
                    '-webkit-transform': 'rotate(' + deviceState?.angle4InDegrees + 'deg)',
                    '-ms-transform': 'rotate(' + deviceState?.angle4InDegrees + 'deg)'
                }"
                    class="full-icon fas fa-fw fa-arrow-up"
                ></i>

                {{ deviceState?.angle4InDegrees != undefined ? deviceState?.angle4InDegrees.toLocaleString() + " °" :
                "-" }}
            </h3>
            <div>{{ "general.angle4InDegrees" | translate }}</div>
        </div>
    </div>

    <ng-container *ngIf="showPosition">
        <div
            *ngIf="deviceState?.calculatedIgnitionState && !device.asset?.isAssetTracker"
            class="col-6 col-lg-4"
            style="padding: 0"
        >
            <div [title]="deviceState?.calculatedIgnitionState?.stateChangedTimestamp" class="kpi-item-small">
                <h3 class="margin-bottom font-bold">
                    <i
                        class="full-icon fas fa-fw fa-key"
                        [ngStyle]="{
                        color: !deviceState?.calculatedIgnitionState
                            ? '#ccc'
                            : deviceState?.calculatedIgnitionState.isIgnitionOn
                            ? 'rgba(0,142,67,0.9)'
                            : 'rgba(151,28,36,0.9)'
                    }"
                    ></i>
                    {{ "general.stateSince" | translate }} {{
                    deviceState?.calculatedIgnitionState?.stateChangedTimestamp | amTimeAgo : true }}
                </h3>
                <div>
                    {{ "general.stateIgnition" | translate }}
                    <i
                        *ngIf="
                        deviceState?.calculatedIgnitionState?.updateTimestamp <
                        deviceState?.communicationState?.updateTimestamp
                    "
                        class="fa fa-fw history-icon hand fa-history"
                        title="{{ deviceState?.calculatedIgnitionState?.updateTimestamp }}"
                    ></i>
                </div>
            </div>
        </div>
        <div
            class="col-6 col-lg-4"
            style="padding: 0"
            *ngIf="deviceState?.idlingState?.isCurrentlyIdling && !device.asset?.isAssetTracker"
        >
            <div [title]="deviceState?.idlingState?.stateChangedTimestamp" class="kpi-item-small">
                <h3 class="margin-bottom font-bold">
                    <i
                        class="full-icon fas fa-fw fa-snooze"
                        [ngStyle]="{
                        color: !deviceState?.idlingState
                            ? '#ccc'
                            : deviceState?.idlingState.isCurrentlyIdling
                            ? 'rgba(238, 146, 52, 0.9)'
                            : 'rgba(255, 255, 255, 0.3)'
                    }"
                    ></i>
                    <span *ngIf="deviceState?.idlingState?.isCurrentlyIdling"
                        >{{ "general.stateSince" | translate }} {{ deviceState?.idlingState?.stateChangedTimestamp |
                        amTimeAgo : true }}</span
                    >
                    <span *ngIf="!deviceState?.idlingState?.isCurrentlyIdling">-</span>
                </h3>
                <div>
                    {{ "general.stateIdling" | translate }}
                    <i
                        *ngIf="
                        deviceState?.idlingState?.calculatedTimestamp < deviceState?.communicationState?.updateTimestamp
                    "
                        class="fa fa-fw history-icon hand fa-history"
                        title="{{ deviceState?.idlingState?.calculatedTimestamp }}"
                    ></i>
                </div>
            </div>
        </div>
        <div class="col-6 col-lg-4" style="padding: 0">
            <div [title]="deviceState?.calculatedCommunicatingState?.updateTimestamp" class="kpi-item-small">
                <h3 class="margin-bottom font-bold">
                    <i
                        class="full-icon fas fa-fw fa-satellite-dish"
                        [ngStyle]="{
                        color: !deviceState?.calculatedCommunicatingState
                            ? '#ccc'
                            : deviceState?.calculatedCommunicatingState.isCommunicating
                            ? 'rgba(0,142,67,0.9)'
                            : 'rgba(151,28,36,0.9)'
                    }"
                    ></i>
                    {{ deviceState?.communicationState?.updateTimestamp | amTimeAgo : true }}
                </h3>
                <div>
                    {{ "general.lastCommunication" | translate }}
                    <i
                        *ngIf="
                        deviceState?.calculatedCommunicatingState?.updateTimestamp <
                        deviceState?.communicationState?.updateTimestamp
                    "
                        class="fa fa-fw history-icon hand fa-history"
                        title="{{ deviceState?.calculatedCommunicatingState?.updateTimestamp }}"
                    ></i>
                </div>
            </div>
        </div>
        <div
            *ngIf="deviceState?.calculatedExternalPowerState && !device.asset?.isAssetTracker"
            class="col-6 col-lg-4"
            style="padding: 0"
        >
            <div [title]="deviceState?.calculatedExternalPowerState?.updateTimestamp" class="kpi-item-small">
                <h3 class="margin-bottom font-bold">
                    <i
                        class="full-icon fas fa-fw fa-car-battery"
                        [ngStyle]="{
                        color: !deviceState?.calculatedExternalPowerState
                            ? '#ccc'
                            : deviceState?.calculatedExternalPowerState.isExternalPowerOn
                            ? 'rgba(0,142,67,0.9)'
                            : 'rgba(151,28,36,0.9)'
                    }"
                    ></i>
                    {{ "general." + deviceState?.calculatedExternalPowerState?.isExternalPowerOn | translate }}
                </h3>
                <div>
                    {{ "general.stateExternalPower" | translate }}
                    <i
                        *ngIf="
                        deviceState?.calculatedExternalPowerState?.updateTimestamp <
                        deviceState?.communicationState?.updateTimestamp
                    "
                        class="fa fa-fw history-icon hand fa-history"
                        title="{{ deviceState?.calculatedExternalPowerState?.updateTimestamp }}"
                    ></i>
                </div>
            </div>
        </div>
    </ng-container>
</div>
<div style="clear: both"></div>
