import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AuthenticationService } from "../authentication/authentication.service";
import { catchError, Observable, throwError } from "rxjs";
import { MaintenanceInvoice, MaintenanceStatus, MaintenanceV2 } from "app/models/maintenancev2.model";
import { CommandResult } from "app/models/commandresult.model";
import { WorkOrder } from "app/models/workOrder.model";
import { ServiceItem } from "app/models/serviceItem.model";

@Injectable()
export class MaintenanceV2Service {
  url = "";
  base_url = "";
  workOrderUrl = "";
  serviceItemUrl = "";

  constructor(private http: HttpClient, private authenticationService: AuthenticationService) {
    this.url = this.authenticationService.getWebserviceURL("MaintenanceV2");
    this.base_url = this.authenticationService.getWebserviceURL("");
    this.workOrderUrl = this.authenticationService.getWebserviceURL("WorkOrder");
    this.serviceItemUrl = this.authenticationService.getWebserviceURL("ServiceItem");
  }

  getPagingDueUrl(date: number) {
    return this.url + "PagingDue?date=" + date;
  }

  getPagingHistoryUrl(assetId: number, date: number) {
    return this.url + `PagingHistory?assetId=${assetId}&fromDate=${date}`;
  }

  getMaintenancesDueByAssetId(assetId: number): Observable<MaintenanceV2[]> {
    return this.http
      .get<MaintenanceV2[]>(this.url + "Asset/" + assetId + "/Due", {
        headers: this.authenticationService.headers,
      })
      .pipe(catchError(this.handleError));
  }

  getMaintenancesHistoryByAssetId(assetId: number): Observable<MaintenanceV2[]> {
    return this.http
      .get<MaintenanceV2[]>(this.url + "Asset/" + assetId + "/History", {
        headers: this.authenticationService.headers,
      })
      .pipe(catchError(this.handleError));
  }

  getMaintenanceStatusByAccountId(accountId: number): Observable<MaintenanceStatus> {
    return this.http
      .get<MaintenanceStatus>(this.base_url + "Account/" + accountId + "/MaintenanceStatus", {
        headers: this.authenticationService.headers,
      })
      .pipe(catchError(this.handleError));
  }

  getMaintenanceDueByAccountId(accountId: number): Observable<MaintenanceStatus> {
    return this.http
      .get<MaintenanceStatus>(this.url + "Due?accountId=" + accountId, {
        headers: this.authenticationService.headers,
      })
      .pipe(catchError(this.handleError));
  }

  getMaintenanceHistoryByAccountId(accountId: number): Observable<MaintenanceStatus> {
    return this.http
      .get<MaintenanceStatus>(this.url + "History?accountId=" + accountId, {
        headers: this.authenticationService.headers,
      })
      .pipe(catchError(this.handleError));
  }

  getMaintenanceInvoicesByAssetId(assetId: number): Observable<MaintenanceInvoice[]> {
    return this.http
      .get<MaintenanceInvoice[]>(this.url + "Invoices/Asset/" + assetId, {
        headers: this.authenticationService.headers,
      })
      .pipe(catchError(this.handleError));
  }

  // Workorders

  getWorkOrders(): Observable<WorkOrder[]> {
    return this.http
      .get<WorkOrder[]>(this.workOrderUrl, { headers: this.authenticationService.headers })
      .pipe(catchError(this.handleError));
  }

  getWorkOrderById(workOrderId: number): Observable<WorkOrder> {
    return this.http
      .get<WorkOrder>(this.workOrderUrl + workOrderId, { headers: this.authenticationService.headers })
      .pipe(catchError(this.handleError));
  }

  createWorkOrder(request: WorkOrder): Observable<CommandResult> {
    return this.http
      .post<CommandResult>(this.workOrderUrl, request, { headers: this.authenticationService.headers })
      .pipe(catchError(this.handleError));
  }

  updateWorkOrder(workOrderId: number, request: WorkOrder): Observable<CommandResult> {
    return this.http
      .put<CommandResult>(this.workOrderUrl + workOrderId, request, { headers: this.authenticationService.headers })
      .pipe(catchError(this.handleError));
  }

  deleteWorkOrder(workOrderId: number, assetId: number): Observable<CommandResult> {
    return this.http
      .delete<CommandResult>(this.workOrderUrl + workOrderId + "/asset/" + assetId, {
        headers: this.authenticationService.headers,
      })
      .pipe(catchError(this.handleError));
  }

  // Service items

  getServiceItems(): Observable<ServiceItem[]> {
    return this.http
      .get<ServiceItem[]>(this.serviceItemUrl, { headers: this.authenticationService.headers })
      .pipe(catchError(this.handleError));
  }

  getServiceItemsByAccount(accountId): Observable<ServiceItem[]> {
    return this.http
      .get<ServiceItem[]>(this.serviceItemUrl + "?accountId=" + accountId, {
        headers: this.authenticationService.headers,
      })
      .pipe(catchError(this.handleError));
  }

  getServiceItemById(serviceItemId: number): Observable<ServiceItem> {
    return this.http
      .get<ServiceItem>(this.serviceItemUrl + serviceItemId, { headers: this.authenticationService.headers })
      .pipe(catchError(this.handleError));
  }

  createServiceItem(request: ServiceItem): Observable<CommandResult> {
    return this.http
      .post<CommandResult>(this.serviceItemUrl, request, { headers: this.authenticationService.headers })
      .pipe(catchError(this.handleError));
  }

  updateServiceItem(serviceItemId: number, request: ServiceItem): Observable<CommandResult> {
    return this.http
      .put<CommandResult>(this.serviceItemUrl + serviceItemId, request, { headers: this.authenticationService.headers })
      .pipe(catchError(this.handleError));
  }

  deleteServiceItem(serviceItemId: number, accountId: number): Observable<CommandResult> {
    return this.http
      .delete<CommandResult>(this.serviceItemUrl + serviceItemId + "/account/" + accountId, {
        headers: this.authenticationService.headers,
      })
      .pipe(catchError(this.handleError));
  }

  private handleError(error: Response) {
    return throwError(error);
  }
}
