import { Component, OnDestroy, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { getGridButtons, getGridLanguages, getDefaultDpConfig } from "app/common/gridhelper";

import { AuthenticationService } from "../../services/authentication/authentication.service";

import { forkJoin } from "rxjs";
import { EntityType } from "app/common/enums";

import { ActivatedRoute } from "@angular/router";
import { GridBase360Directive } from "app/common/360Grid.base";

// Moment timezone
import { StorageHelper } from "app/common/storagehelper";
import { roundAsString } from "app/common/globals";
import { TripService } from "app/services/trip/trip.service";
import { BsDaterangepickerConfig } from "ngx-bootstrap/datepicker";

// Moment timezone
import Moment from "moment-timezone";
import { DistanceUnitService } from "app/common/distanceunit.service";
import { ColorService } from "app/services/common/color.service";

window["moment"] = Moment;

@Component({
  selector: "fh-trips-overview",
  templateUrl: "tripsOverview.template.html",
})
export class DevicesTripsOverViewComponent extends GridBase360Directive implements OnInit, OnDestroy {
  excludingColumns = ["id", "id_export", "isActive"];

  timezoneIana: string;

  loading = false;
  languageLoaded: boolean;
  permissions: {};

  permissionName = "FleetManagement_Trips";
  constructorName = "TripsOverviewViewComponent";

  error: any;
  success: any;
  warning: any;

  enableSelecting = true;

  entityTypes = EntityType.Geofence;

  // Daterange
  public dpConfig: Partial<BsDaterangepickerConfig> = new BsDaterangepickerConfig();
  daterangepickerModel: Date[];

  selectedAccountId: any;
  selectedResellerId;
  orderBy: any;
  start: any;
  end: any;
  accountId: any;

  constructor(
    private route: ActivatedRoute,
    private translateService: TranslateService,
    private distance: DistanceUnitService,
    private authenticationService: AuthenticationService,
    private tripService: TripService,
    protected storageHelper: StorageHelper,
    private colorService: ColorService
  ) {
    super(storageHelper);

    this.permissions = this.authenticationService.permissions;

    this.timezoneIana = this.authenticationService.getTimeZoneIana();

    this.daterangepickerModel = [
      Moment().tz(this.timezoneIana).subtract(2, "days").startOf("day").toDate(),
      Moment().tz(this.timezoneIana).endOf("day").toDate(),
    ];

    this.dpConfig = getDefaultDpConfig(authenticationService);

    // Get all the date for dropdown boxes
    forkJoin({ dateTranslation: this.translateService.get("general.date") }).subscribe({
      next: ({ dateTranslation }) => {
        this.languageLoaded = true;
        this.loading = false;

        this.initGrid();
      },
      error: (err) => console.error(err),
    });
  }

  ngOnInit() {
    this.permissions = this.authenticationService.permissions;

    this.translateService.get("general.date").subscribe((value) => {
      this.languageLoaded = true;
      this.initGrid();
    });

    this.route.queryParams.subscribe((params) => {
      this.orderBy = params["orderBy"];
      this.start = params["start"];
      this.end = params["end"];
      this.accountId = params["accountId"];

      if (this.start && this.end) {
        this.daterangepickerModel = [
          Moment(this.start).tz(this.timezoneIana).startOf("day").toDate(),
          Moment(this.end).tz(this.timezoneIana).endOf("day").toDate(),
        ];
      }

      if (this.accountId) {
        this.selectedAccountId = this.accountId;
      }
    });
  }

  resellerChanged(event) {
    this.selectedAccountId = null;
    this.accountChanged(null);
  }

  accountChanged(accountId) {
    this.selectedAccountId = accountId;

    this.error = null;
    this.warning = null;

    if (accountId) {
      this.dateChanged(true);
    }
  }

  // Check route to apply filters
  checkRoute() {
    this.route.queryParams.subscribe((params) => {
      const companyName = params["companyName"];
      const resellerDescription = params["resellerDescription"];

      const orderBy = params["orderBy"];
      let requireDraw = false;

      this.datatableElement?.dtInstance.then((dtInstance: DataTables.Api) => {
        if (orderBy == "maxSpeed") {
          dtInstance.column("maxSpeed:name").order("desc");
        }

        if (companyName) {
          dtInstance.column("resellerDescription:name").search("");
          dtInstance.column("companyName:name").search(companyName);
          dtInstance.column("name:name").search("");
          requireDraw = true;
        }

        if (resellerDescription) {
          dtInstance.column("resellerDescription:name").search(resellerDescription);
          dtInstance.column("companyName:name").search("");
          dtInstance.column("name:name").search("");
          requireDraw = true;
        }
        // Fire the request
        if (requireDraw) {
          dtInstance.draw();
        }
      });
    });
  }

  dateChanged(event) {
    console.log("Date changed");

    if (event !== null) {
      this.datatableElement?.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.ajax
          .url(
            this.tripService.getPagingUrl(
              this.selectedAccountId,
              Moment.utc(this.daterangepickerModel[0]).tz(this.timezoneIana).startOf("day"),
              Moment.utc(this.daterangepickerModel[1]).tz(this.timezoneIana).endOf("day")
            )
          )
          .load();
      });
    }
  }

  cancel() {
    // this.mySubscription.unsubscribe();

    this.loading = false;

    this.warning = {};
    this.warning.warning = "Call was cancelled.";
    this.warning.statusText = "Warning";
  }

  initGrid(): void {
    $.fn["dataTable"].ext.search.pop();

    this.loading = true;

    const excludedColumns = ["id", "id_export", "isSelected", "form"];
    let selectorVisible = true;

    if (!this.permissions["FleetManagement_Actions"]) {
      selectorVisible = false;
    }

    this.columns = [
      {
        name: "id",
        data: "id",
        className: "noVis",
        orderable: false,
        title: '<div class="hideDropdown"></div>',
        width: "20",
        render: (data, type, row) => {
          const detailsText = this.translateService.instant("general.details");
          return `
          <a class='btn btn-primary btn-grid' title='${detailsText}' href='/#/DeviceDetails/Trips/${row.deviceId}?tripId=${data}'>
            <span class="d-none d-md-inline-flex" style="padding-left: 7px">${detailsText}</span>
            <i class='fas fa-fw fa-angle-right'></i>
          </a>`;
        },
      },
      {
        name: "id_export",
        data: "id",
        className: "noVis",
        title: this.translateService.instant("general.id"),
        visible: false,
      },
      {
        name: "assetName",
        data: "assetName",
        defaultContent: "-",
        title: this.translateService.instant("general.assetName"),
      },
      {
        name: "beginTS",
        data: "beginTS",
        defaultContent: "-",
        title: this.translateService.instant("general.start"),
        render: (data, type, row) => {
          const date = Moment.utc(data)["tz"](this.timezoneIana);
          return data ? '<span title=" ' + date.toLocaleString() + '">' + date.format("lll") + "</span>" : "-";
        },
      },
      {
        name: "endTS",
        data: "endTS",
        defaultContent: "-",
        title: this.translateService.instant("general.end"),
        render: (data, type, row) => {
          const date = Moment.utc(data)["tz"](this.timezoneIana);
          return data ? '<span title=" ' + date.toLocaleString() + '">' + date.format("lll") + "</span>" : "-";
        },
      },
      {
        name: "segmentDistanceInKilometers",
        data: "segmentDistanceInKilometers",
        defaultContent: "-",
        title: this.translateService.instant("general.distance"),
        render: (data, type, row) => {
          if (type && type === "display") {
            return data
              ? roundAsString(data, 2) + " " + this.translateService.instant(this.distance.getDistanceUnit())
              : "-";
          } else {
            return data;
          }
        },
      },
      {
        name: "calibratedOdoValueEnd",
        data: "calibratedOdoValueEnd",
        defaultContent: "-",
        title: this.translateService.instant("general.odo"),
        render: (data, type, row) => {
          if (type && type === "display") {
            return data
              ? roundAsString(data / 1000, 0) + " " + this.translateService.instant(this.distance.getDistanceUnit())
              : "-";
          } else {
            return data;
          }
        },
      },
      {
        name: "maxSpeed",
        data: "maxSpeed",
        defaultContent: "-",
        title: this.translateService.instant("general.maxSpeed"),
        render: (data, type, row) => {
          if (type && type === "display") {
            return data
              ? roundAsString(data, 0) + "  " + this.translateService.instant(this.distance.getDistanceUnitPerHour())
              : "-";
          } else {
            return data;
          }
        },
      },
      {
        name: "beginAddressFull",
        data: "beginAddressFull",
        defaultContent: "-",
        title: this.translateService.instant("general.start"),
      },
      {
        name: "endAddressFull",
        data: "endAddressFull",
        defaultContent: "-",
        title: this.translateService.instant("general.end"),
      },
      {
        name: "accountId",
        data: "accountId",
        defaultContent: "-",
        title: this.translateService.instant("general.accountId"),
        visible: false,
      },
      {
        name: "resellerDescription",
        data: "resellerDescription",
        defaultContent: "-",
        title: this.translateService.instant("general.resellerDescription"),
        visible: false,
      },
    ];

    this.commonExportOptions = {
      // orthogonal: 'export',
      // format: {
      //   body: function (data, row, column, node) {
      //     return data;
      //   }
      // },
      modifier: {
        page: "all",
        search: "none",
      },
      columns: ["id_export:name", "accountId:name", ":visible[tabindex]"],
    };

    this.dtOptions = {
      buttons: getGridButtons(
        this.commonExportOptions,
        "geofence_overview",
        this.translateService.instant("menu.geofenceoverview"),
        this.colorService
      ),
      pagingType: "simple_numbers",
      serverSide: true,
      processing: true,
      searchDelay: 500,
      deferRender: true,
      scrollX: true,
      colReorder: { fixedColumnsLeft: 2 },
      deferLoading: 0,
      stateSave: true,
      stateSaveCallback: (settings, data) => {
        this.saveState(this.constructorName, data);
      },
      stateLoadCallback: (_, callback) => {
        (async () => {
          try {
            const columnSettings = await this.loadState(this.constructorName);
            this.searchTerm = columnSettings && columnSettings.search && columnSettings.search.search;
            return columnSettings;
          } catch (e) {
            this.error = {};
            this.error.error = e;
            this.error.statusText = "Error fetching column settings";

            return null;
          }
        })().then((result) => {
          callback(result);
        });
      },
      order: [[this.orderBy == "maxSpeed" ? 6 : 3, "desc"]],
      ajax: {
        beforeSend: () => {
          this.drawFilterRow();

          $(".dataTables_info").html(this.translateService.instant("grid.loadingData"));
        },
        url: this.tripService.getPagingUrl(
          this.selectedAccountId,
          Moment.utc(this.daterangepickerModel[0]).tz(this.timezoneIana).startOf("day"),
          Moment.utc(this.daterangepickerModel[1]).tz(this.timezoneIana).endOf("day")
        ),
        data: (d) => {
          return d;
        },
        dataSrc: (json) => {
          return json.data;
        },
        type: "POST",
      },
      initComplete: (settings, json) => {
        this.checkRoute();
        this.checkFilters();
        this.setEvents();
        this.drawFilterRow();
        $("#grid_select_or").appendTo($("th > #selectorHeader"));
        this.loading = false;
      },
      colVis: {
        restore: this.translateService.instant("general.restore"),
        showAll: this.translateService.instant("general.showAll"),
        showNone: this.translateService.instant("general.hideAll"),
        exclude: excludedColumns,
      },
      columns: this.columns,
      pageLength: 17,
      lengthMenu: [
        [10, 17, 25, 50, -1],
        [10, 17, 25, 50, this.translateService.instant("general.all")],
      ],
      language: getGridLanguages(this.translateService),
    };
  }
}
