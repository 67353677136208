import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { FormMode } from "app/common/enums";
import { Driver } from "app/models/driver.model";
import { DriverService } from "app/services/driver/driver.service";
import { AuthenticationService } from "../../services/authentication/authentication.service";
import { DriverGroupsService } from "app/services/driver/driverGroups.service";
import { DriverGroup } from "app/models/group.model";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "fh-drivers-add",
  templateUrl: "add.template.html",
})
export class DriversAddViewComponent implements OnInit {
  loading = false;
  loadingGroups = false;
  driver: Driver;

  formMode = FormMode.add;
  error: any;
  permissions: {};
  success: { statusText: string; success: string };
  driverGroups: DriverGroup[];
  selectedGroups = [];
  selectedGroupsSource = [];
  groupName;

  permissionName = "Drivers_Add";

  constructor(
    private translateServe: TranslateService,
    private driverService: DriverService,
    private driverGroupService: DriverGroupsService,
    private cd: ChangeDetectorRef,
    private authenticationService: AuthenticationService,
    private router: Router
  ) {
    this.permissions = this.authenticationService.permissions;

    this.driverGroups = [];
    this.selectedGroups = [];
  }

  ngOnInit() {
    this.driver = new Driver();
    this.driver.dateOfBirth = new Date("01-01-1980");
    this.driver.driverLicenceExpirationDate = new Date();
    this.driver.identificationCardExpirationDate = new Date();
  }

  accountChanged(accountId) {
    if (accountId == null) {
      this.driverGroups = [];
    } else {
      this.loadingGroups = true;
      this.driverGroupService.getDriverGroups(accountId).subscribe((res) => {
        this.driverGroups = res;
        this.loadingGroups = false;
      });
    }

    this.cd.markForCheck();
  }

  onInsert() {
    this.loading = true;

    this.driverService.saveDriver(this.driver).subscribe({
      next: (result) => {
        this.success = {
          statusText: "Success",
          success: this.translateServe.instant("general.saveSuccessRedirect", { entity: "driver" }),
        };

        setTimeout(() => {
          this.router.navigate(["/DriverDetails/Index/" + result.id]);
        }, 2000);

        this.loading = false;
      },
      error: (error) => {
        this.error = error;
        this.loading = false;
      },
    });
  }
}
