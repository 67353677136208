import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { throwError } from "rxjs/internal/observable/throwError";
import { Observable, catchError, map } from "rxjs";
import {
  Device,
  DeviceCalibrationPoints,
  DeviceFuelThreshold,
  DeviceSettings,
  DeviceUtilization,
  Properties,
} from "../../models/device.model";
import { AuthenticationService } from "../authentication/authentication.service";
import { LoggingService } from "../logging/logging.service";
import { Asset, RegisterOperatingAsset, WaslInquiry } from "app/models/asset.model";
import { Driver } from "app/models/driver.model";
import { TranslateService } from "@ngx-translate/core";
import { auxilaryOptionCount, localizeSystemGroupNames, canOptionCount } from "app/common/globals";

// Moment timezone
import Moment from "moment-timezone";
import { DeviceStatesItem } from "app/models/StateObject";
import { DistanceUnitService } from "app/common/distanceunit.service";
window["moment"] = Moment;

@Injectable()
export class DeviceService {
  url = "";
  baseUrl = "";
  urlFleetOverview = "";
  timezoneIana: string;
  devices = [];
  permissions = {};

  constructor(
    private http: HttpClient,
    private distance: DistanceUnitService,
    private loggingService: LoggingService,
    private authenticationService: AuthenticationService,
    public translateService: TranslateService
  ) {
    this.url = this.authenticationService.getWebserviceURL("device");
    this.baseUrl = this.authenticationService.getWebserviceURL("");
    this.timezoneIana = this.authenticationService.getTimeZoneIana();
    this.permissions = this.authenticationService.permissions;
  }

  getPagingUrl() {
    return this.url + "Paging";
  }

  getStatePagingUrl(key, includeGeofenceNames, includeAddresses) {
    return (
      this.url + `State?rnd=${key}&includeGeofenceNames=${includeGeofenceNames}&includeAddresses=${includeAddresses}`
    );
  }

  getExternalPagingUrl() {
    return this.url + "External";
  }

  getDevices(): Observable<Device[]> {
    return this.http.get(this.url, { headers: this.authenticationService.headers }).pipe(
      map((data) => this.parseResponse(data)),
      catchError(this.handleError)
    );
  }

  getDevicesLimited(
    accountId = null,
    resellerId = null,
    isActive = null,
    includeGroups = false,
    includeGroupsIds = false,
    uniqueDevices = false
  ): Observable<Device[]> {
    return this.http
      .get(
        this.url +
          "Limited?isActive=" +
          isActive +
          "&includeGroups=" +
          includeGroups +
          "&uniqueDevices=" +
          uniqueDevices +
          "&includeGroupIds=" +
          includeGroupsIds +
          (accountId !== null ? "&accountId=" + accountId : "") +
          (resellerId !== null ? "&resellerId=" + resellerId : ""),
        { headers: this.authenticationService.headers }
      )
      .pipe(
        map((data) => this.parseResponse(data)),
        catchError(this.handleError)
      );
  }

  getDevicesWithLocation(
    accountId = null,
    limitData = true,
    includeGroups = false,
    IncludeGroupIds = false,
    includeEmptyLocations = false
  ): Observable<Device[]> {
    return this.http
      .get(
        this.url +
          "Locations?limitData=" +
          limitData +
          "&includeEmptyLocations=" +
          includeEmptyLocations +
          "&includeGroups=" +
          includeGroups +
          "&IncludeGroupIds=" +
          IncludeGroupIds +
          (accountId !== null ? "&accountId=" + accountId : ""),
        { headers: this.authenticationService.headers }
      )
      .pipe(
        map((data) => this.parseResponse(data)),
        catchError(this.handleError)
      );
  }

  getDevicesUtilization(deviceId, start, end): Observable<any> {
    return this.http
      .get(this.url + deviceId + "/Utilization?start=" + start.unix() + "&end=" + end.unix(), {
        headers: this.authenticationService.headers,
      })
      .pipe(
        map((data: any) => {
          this.loggingService.log(
            this.constructor.name,
            "Retrieved " + data.length + " Devices (getDevicesUtilization)."
          );

          const ident = 1;
          const devices: DeviceUtilization[] = [];

          data.forEach((item) => {
            const device = new DeviceUtilization();
            device.deviceId = item.deviceId;
            device.assetId = item.assetId;
            device.accountId = item.accountId;
            device.assetName = item.assetName;
            device.unitId = item.unitId;
            device.iconId = item.iconId;

            try {
              device.assetGroups = item.assetGroups != null ? JSON.parse(item.assetGroups.replace("\t", "")) : [];
            } catch (error) {
              console.log(error);
            }

            if (device.assetGroups && device.assetGroups.length > 0) {
              device.assetGroups.forEach((assetGroup) => {
                assetGroup.name = localizeSystemGroupNames(assetGroup.name, this.translateService);
                assetGroup.color = assetGroup.color;
              });
            }

            device.speedingDurationInSeconds = item.speedingDurationInSeconds;
            device.tripCount = item.tripCount;
            device.tripCountPrivate = item.tripCountPrivate;
            device.tripCountBusiness = item.tripCountBusiness;
            device.tripDurationInSeconds = item.tripDurationInSeconds;
            device.maxSpeed = this.distance.calculateDistanceUnitFromKmFixed(item.maxSpeed, 0) ?? 0;
            device.segmentsDistance = this.distance.calculateDistanceUnitFromKmFixed(item.segmentsDistance, 2) ?? 0;
            device.segmentsDistanceBusiness =
              this.distance.calculateDistanceUnitFromKmFixed(item.segmentsDistanceBusiness, 2) ?? 0;
            device.segmentsDistancePrivate =
              this.distance.calculateDistanceUnitFromKmFixed(item.segmentsDistancePrivate, 2) ?? 0;
            device.totalDurationInSeconds = item.totalDurationInSeconds;
            device.geofenceEventCount = item.geofenceEventCount;
            device.utilization = item.utilization;
            device.idlingDurationInSeconds = item.idlingDurationInSeconds;
            device.speedingCount = item.speedingCount;
            device.roadSpeedingCount = item.roadSpeedingCount;
            device.accelCount = item.accelCount;
            device.decelCount = item.decelCount;
            device.corneringCount = item.corneringCount;

            device.brakingEventScore = item.brakingEventScore;
            device.accellerationEventScore = item.accellerationEventScore;
            device.corneringEventScore = item.corneringEventScore;

            device.workingHoursInSeconds = item.workingHoursInSeconds;
            device.pureDrivingDurationInSeconds = item.pureDrivingDurationInSeconds;
            device.idlingPercentage = item.idlingDurationInSeconds / item.workingHoursInSeconds;

            device.totalEmissionCO2 = item.totalEmissionCO2;
            device.totalEmissionParticlesLight = item.totalEmissionParticlesLight;
            device.totalEmissionParticlesHeavy = item.totalEmissionParticlesHeavy;
            device.totalConsumptionMixed = item.totalConsumptionMixed;

            device.avgKmh = item.avgKmh;

            device.active = true;
            if (device.tripCount < 1 || device.utilization < 0 || device.segmentsDistance < 0) {
              device.active = false;
            }

            devices.push(device);
          });

          return devices;
        }),
        catchError(this.handleError)
      );
  }

  getDeviceUtilization(deviceId, start, end): Observable<any> {
    return this.http
      .get(this.url + deviceId + "/Utilization?start=" + start.unix() + "&end=" + end.unix(), {
        headers: this.authenticationService.headers,
      })
      .pipe(
        map((data: any) => {
          const item = data;

          const utilization = new DeviceUtilization();
          utilization.deviceId = item.deviceId;
          utilization.assetId = item.assetId;
          utilization.accountId = item.accountId;
          utilization.assetName = item.assetName;
          utilization.unitId = item.unitId;
          utilization.iconId = item.iconId;

          try {
            utilization.assetGroups = item.assetGroups != null ? JSON.parse(item.assetGroups.replace("\t", "")) : [];
          } catch (error) {
            console.log(error);
          }

          if (utilization.assetGroups && utilization.assetGroups.length > 0) {
            utilization.assetGroups.forEach((assetGroup) => {
              assetGroup.name = localizeSystemGroupNames(assetGroup.name, this.translateService);
            });
          }

          utilization.speedingDurationInSeconds = item.speedingDurationInSeconds;
          utilization.tripCount = item.tripCount;
          utilization.tripCountPrivate = item.tripCountPrivate;
          utilization.tripCountBusiness = item.tripCountBusiness;
          utilization.tripDurationInSeconds = item.tripDurationInSeconds;
          utilization.maxSpeed = this.distance.calculateDistanceUnitFromKmFixed(item.maxSpeed, 0) ?? 0;
          utilization.segmentsDistance = this.distance.calculateDistanceUnitFromKmFixed(item.segmentsDistance, 2) ?? 0;
          utilization.segmentsDistanceBusiness =
            this.distance.calculateDistanceUnitFromKmFixed(item.segmentsDistanceBusiness, 2) ?? 0;
          utilization.segmentsDistancePrivate =
            this.distance.calculateDistanceUnitFromKmFixed(item.segmentsDistancePrivate, 2) ?? 0;
          utilization.totalDurationInSeconds = item.totalDurationInSeconds;
          utilization.geofenceEventCount = item.geofenceEventCount;

          utilization.idlingDurationInSeconds = item.idlingDurationInSeconds;
          utilization.speedingCount = item.speedingCount;
          utilization.roadSpeedingCount = item.roadSpeedingCount;
          utilization.accelCount = item.accelCount;
          utilization.decelCount = item.decelCount;
          utilization.corneringCount = item.corneringCount;

          utilization.brakingEventScore = item.brakingEventScore;
          utilization.accellerationEventScore = item.accellerationEventScore;
          utilization.corneringEventScore = item.corneringEventScore;

          utilization.workingHoursInSeconds = item.workingHoursInSeconds;
          utilization.pureDrivingDurationInSeconds = item.pureDrivingDurationInSeconds;

          utilization.utilization = (utilization.workingHoursInSeconds / utilization.totalDurationInSeconds) * 100;
          utilization.idlingPercentage =
            (utilization.idlingDurationInSeconds / utilization.workingHoursInSeconds) * 100;

          utilization.avgKmh = item.avgKmh;

          // Equipment
          utilization.equipmentIdlingDurationInSeconds = item.equipmentIdlingDurationInSeconds;
          utilization.equipmentIdlingPercentage =
            (item.equipmentIdlingDurationInSeconds / item.totalDurationInSeconds) * 100;
          utilization.equipmentCrossOverDurationInSeconds = item.equipmentCrossOverDurationInSeconds;
          utilization.equipmentWorkDurationInSeconds = item.equipmentWorkDurationInSeconds;
          utilization.equipmentUtilization = (item.equipmentWorkDurationInSeconds / item.totalDurationInSeconds) * 100;

          utilization.totalEmissionCO2 = item.totalEmissionCO2;
          utilization.totalEmissionParticlesLight = item.totalEmissionParticlesLight;
          utilization.totalEmissionParticlesHeavy = item.totalEmissionParticlesHeavy;
          utilization.totalConsumptionMixed = item.totalConsumptionMixed;

          utilization.fuelUsedWhileDriving = item.fuelUsedWhileDriving;
          utilization.fuelLostWhileIdling = item.fuelLostWhileIdling;
          utilization.fuelLostPercentage = item.fuelLostPercentage;
          utilization.fuelUsedTotal = item.fuelUsedTotal;
          utilization.fuelEfficiency = item.fuelEfficiency;

          utilization.active = true;
          if (utilization.tripCount < 1 || utilization.utilization < 0 || utilization.segmentsDistance < 0) {
            utilization.active = false;
          }

          return utilization;
        }),
        catchError(this.handleError)
      );
  }

  getDeviceLocations(lookupDate): Observable<any> {
    return this.http
      .get(this.urlFleetOverview + "Location/" + lookupDate + "/All", { headers: this.authenticationService.headers })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError(this.handleError)
      );
  }

  getDeviceById(id: string, shared = false): Observable<Device> {
    let headers;
    if (shared) {
      this.url = this.authenticationService.getWebserviceURL("device");
      headers = this.authenticationService.shareheaders;
    } else {
      headers = this.authenticationService.headers;
    }

    return this.http.get(this.url + id + `?shared=${shared}`, { headers: headers }).pipe(
      map((data) => this.parseReponseDetails(data)),
      catchError(this.handleError)
    );
  }

  getVepamonCalibrationsById(deviceId: string): Observable<DeviceCalibrationPoints[]> {
    return this.http
      .get(this.url + deviceId + "/VepamonCalibrations", { headers: this.authenticationService.headers })
      .pipe(
        map((data: any) => {
          const calibrationPoints = [];
          data.forEach((item) => {
            const calibrationPoint = new DeviceCalibrationPoints();
            calibrationPoint.deviceId = +deviceId;
            calibrationPoint.sensorNumber = item.sensorNumber;
            calibrationPoint.reportedValue = item.reportedValue;
            calibrationPoint.calibratedValue = item.calibratedValue;
            calibrationPoints.push(calibrationPoint);
          });
          return calibrationPoints;
        }),
        catchError(this.handleError)
      );
  }

  getFuelThresholdsById(deviceId: string): Observable<DeviceFuelThreshold> {
    return this.http.get(this.url + deviceId + "/FuelThresholds", { headers: this.authenticationService.headers }).pipe(
      map((data: any) => {
        const thresholds = new DeviceFuelThreshold();
        thresholds.deviceId = +deviceId;
        thresholds.fillupThreshold = data.fillupThresholdPercentage;
        thresholds.fuelTank1Capacity = data.fuelTank1CapacityInLiters;
        thresholds.fuelTank2Capacity = data.fuelTank2CapacityInLiters;
        thresholds.theftThreshold = data.theftThresholdPercentage;
        return thresholds;
      }),
      catchError(this.handleError)
    );
  }

  getCalibrationPoints(deviceId: string): Observable<DeviceCalibrationPoints[]> {
    return this.http
      .get(this.url + deviceId + "/CalibrationPoints", { headers: this.authenticationService.headers })
      .pipe(
        map((data: any) => {
          const calibrationPoints = [];
          data.forEach((item) => {
            const calibrationPoint = new DeviceCalibrationPoints();
            calibrationPoint.sensorNumber = item.sensorNumber;
            calibrationPoint.reportedValue = item.reportedValue;
            calibrationPoint.calibratedValue = item.calibratedValue;
            calibrationPoints.push(calibrationPoint);
          });
          return calibrationPoints;
        }),
        catchError(this.handleError)
      );
  }

  updateCalibrationPoints(deviceId: string, deviceCalibrationPointObject): Observable<any> {
    return this.http
      .post(this.url + deviceId + "/CalibrationPoints", deviceCalibrationPointObject, {
        headers: this.authenticationService.headers,
      })
      .pipe(catchError(this.handleError));
  }

  updateFuelThresholds(deviceId, thresholds): Observable<any> {
    return this.http
      .put(this.url + deviceId + "/FuelThresholds", thresholds, { headers: this.authenticationService.headers })
      .pipe(catchError(this.handleError));
  }

  getDriversByDeviceId(id: string): Observable<any> {
    return this.http.get(this.url + id + "/Drivers", { headers: this.authenticationService.headers }).pipe(
      map((data: any) => {
        data.forEach((item) => {
          item.dateStart =
            item.dateStart !== undefined ? Moment.utc(item.dateStart)["tz"](this.timezoneIana) : undefined;
          item.dateEnd = item.dateEnd !== undefined ? Moment.utc(item.dateEnd)["tz"](this.timezoneIana) : undefined;
          item.sourceFormatted = this.translateService.instant("enums.assetDriverSource." + item.source);
        });
        return data;
      }),
      catchError(this.handleError)
    );
  }

  getDriverTagAssignmentsById(id: string): Observable<any> {
    return this.http.get(this.url + id + "/DriverTagAssignments", { headers: this.authenticationService.headers }).pipe(
      map((data: any) => {
        data.forEach((item) => {
          item.dateStart =
            item.dateStart !== undefined ? Moment.utc(item.dateStart)["tz"](this.timezoneIana) : undefined;
          item.dateEnd = item.dateEnd !== undefined ? Moment.utc(item.dateEnd)["tz"](this.timezoneIana) : undefined;
        });

        return data;
      }),
      catchError(this.handleError)
    );
  }

  saveDriverTagAssignmentsById(id: string, driverTagAssignments): Observable<any> {
    const object = {
      deviceId: id,
      whiteListDriverTags: [],
      blackListDriverTags: [],
    };

    driverTagAssignments.forEach((driverTagAssignment) => {
      object.whiteListDriverTags.push({
        tag: driverTagAssignment.driverTag,
        outputPort: undefined,
        durationInMillisecondsSeconds: undefined,
      });
    });

    return this.http
      .post(this.url + id + "/DriverTagAssignments", object, { headers: this.authenticationService.headers })
      .pipe(
        map((data: any) => {
          return data;
        }),
        catchError(this.handleError)
      );
  }

  getVirtualSensors(id: string): Observable<any> {
    return this.http.get(this.url + id + "/VirtualSensors", { headers: this.authenticationService.headers }).pipe(
      map((data: any) => {
        return data;
      }),
      catchError(this.handleError)
    );
  }

  saveVirtualSensors(id: string, virtualSensors: any[]): Observable<any> {
    const virtualSensorsObject = {
      deviceId: id,
      virtualSensors: virtualSensors,
    };

    return this.http
      .post(this.url + id + "/VirtualSensors", virtualSensorsObject, { headers: this.authenticationService.headers })
      .pipe(
        map((data: any) => {
          return data;
        }),
        catchError(this.handleError)
      );
  }

  getTrailersById(id: string): Observable<any> {
    return this.http.get(this.url + id + "/Trailers", { headers: this.authenticationService.headers }).pipe(
      map((data: any) => {
        data.forEach((item) => {
          item.dateStart =
            item.dateStart !== undefined ? Moment.utc(item.dateStart)["tz"](this.timezoneIana) : undefined;
          item.dateEnd = item.dateEnd !== undefined ? Moment.utc(item.dateEnd)["tz"](this.timezoneIana) : undefined;
        });
        return data;
      }),
      catchError(this.handleError)
    );
  }

  getProjectsById(id: string): Observable<any> {
    return this.http.get(this.url + id + "/Projects", { headers: this.authenticationService.headers }).pipe(
      map((data: any) => {
        data.forEach((item) => {
          item.dateStart =
            item.dateStart !== undefined ? Moment.utc(item.dateStart)["tz"](this.timezoneIana) : undefined;
          item.dateEnd = item.dateEnd !== undefined ? Moment.utc(item.dateEnd)["tz"](this.timezoneIana) : undefined;
        });
        return data;
      }),
      catchError(this.handleError)
    );
  }

  saveDeviceSettings(device: Device): Observable<any> {
    console.log("save device");

    return this.http
      .put(this.url + device.id + "/Settings", device, { headers: this.authenticationService.headers })
      .pipe(catchError(this.handleError));
  }

  saveDevice(device: Device): Observable<any> {
    console.log("save device");

    const deviceModel = {
      id: device.id,
      imsi: device.imsi,
      msisdn: device.msisdn,
      deviceStatus: device.deviceStatus,
      simIdentifier: device.simIdentifier,
      simActivationStatus: device.simActivationStatus,
      deviceInventoryStatus: device.deviceInventoryStatus,
      name: device.name,
      unitId: device.unitId,
      deviceTypeId: device.deviceTypeId,
    };

    return this.http
      .put(this.url + device.id, deviceModel, { headers: this.authenticationService.headers })
      .pipe(catchError(this.handleError));
  }

  saveDeviceProperties(device: Device): Observable<any> {
    console.log("save device");

    const object = {
      id: device.id,
      properties: device.properties,
    };

    const body = JSON.stringify(object);

    return this.http
      .put(this.url + device.id + "/Properties", body, { headers: this.authenticationService.headers })
      .pipe(catchError(this.handleError));
  }

  saveSettings(deviceSettings): Observable<any> {
    console.log("save device");

    deviceSettings["auxiliaryAttachements"] = deviceSettings["auxiliaryAttachements"].reduce(
      (acc, current) => acc + +current,
      0
    );
    deviceSettings["canBusParameters"] = deviceSettings["canBusParameters"].reduce((acc, current) => acc + +current, 0);

    return this.http
      .put(this.url + deviceSettings.id + "/Settings", deviceSettings, { headers: this.authenticationService.headers })
      .pipe(catchError(this.handleError));
  }

  createDevice(device: Device): Observable<any> {
    console.log("save device");

    return this.http
      .post(this.url, device, { headers: this.authenticationService.headers })
      .pipe(catchError(this.handleError));
  }

  resetDeviceCache(): Observable<boolean> {
    return this.http.get(this.url + "ResetCache", { headers: this.authenticationService.headers }).pipe(
      map((data: any) => {
        return data;
      }),
      catchError(this.handleError)
    );
  }

  saveNote(device: Device): Observable<Boolean> {
    const post = { Note: device.note, isNoteWarning: device.isNoteWarning };
    const body = JSON.stringify(post);

    return this.http.post(this.url + device.id + "/Note", body, { headers: this.authenticationService.headers }).pipe(
      map((result) => {
        return true;
      }),
      catchError(this.handleError)
    );
  }

  saveFlagged(device: Device): Observable<Boolean> {
    const post = { IsFlagged: device.isFlagged };
    const body = JSON.stringify(post);

    return this.http
      .post(this.url + device.id + "/Flagged", body, { headers: this.authenticationService.headers })
      .pipe(
        map((result) => {
          return true;
        }),
        catchError(this.handleError)
      );
  }

  rerun(device, startDate, useBeta): Observable<any> {
    const post = {};
    const body = JSON.stringify(post);

    return this.http
      .post(this.url + device.id + "/Rerun?startDate=" + startDate.toJSON() + "&useBeta=" + useBeta, body, {
        headers: this.authenticationService.headers,
      })
      .pipe(
        map((result) => {
          return result;
        }),
        catchError(this.handleError)
      );
  }

  getDeviceData(devices: any[]): Observable<any> {
    return this.http
      .post(
        this.url + "List",
        devices.map((d) => +d),
        { headers: this.authenticationService.headers }
      )
      .pipe(catchError(this.handleError));
  }

  // Other properties

  getDashboardById(id: string): Observable<any> {
    console.log("Fetch device by id " + id);
    return this.http.get(this.url + id + "/Dashboard", { headers: this.authenticationService.headers }).pipe(
      map((data) => {
        return data;
      }),
      catchError(this.handleError)
    );
  }

  getStateById(id: string): Observable<any> {
    console.log("Fetch device by id " + id);
    return this.http.get(this.url + id + "/State", { headers: this.authenticationService.headers }).pipe(
      map((data) => {
        return data;
      }),
      catchError(this.handleError)
    );
  }

  getDeviceSensorsById(id: string): Observable<any> {
    console.log("Fetch device sensors by id " + id);
    return this.http.get(this.url + id + "/Sensors/", { headers: this.authenticationService.headers }).pipe(
      map((data) => {
        return data;
      }),
      catchError(this.handleError)
    );
  }

  getDevicesByDeviceType(id: string) {
    console.log("getting devices from service");
    return this.http.get(this.url + "deviceType/" + id, { headers: this.authenticationService.headers }).pipe(
      map((data) => this.parseResponse(data)),
      catchError(this.handleError)
    );
  }

  getDevicesByCustomer(id: string) {
    console.log("getting devices from service");
    return this.http.get(this.url + "customer/" + id, { headers: this.authenticationService.headers }).pipe(
      map((data) => this.parseResponse(data)),
      catchError(this.handleError)
    );
  }

  getDevicesByDriverId(id: string) {
    console.log("getting devices from service");
    return this.http.get(this.url + "driver/" + id, { headers: this.authenticationService.headers }).pipe(
      map((data) => this.parseResponse(data)),
      catchError(this.handleError)
    );
  }

  getDevicesByAccountPlatform(id: string) {
    console.log("getting devices from service");
    return this.http.get(this.url + "accountplatform/" + id, { headers: this.authenticationService.headers }).pipe(
      map((data) => this.parseResponse(data)),
      catchError(this.handleError)
    );
  }

  getLocationCount(id: string, start, end): Observable<any> {
    return this.http
      .get(this.url + id + "/locationCount?start=" + start.unix() + "&end=" + end.unix(), {
        headers: this.authenticationService.headers,
      })
      .pipe(catchError(this.handleError));
  }

  getLiveLocation(id: string): Observable<any> {
    return this.http
      .get(this.url + id + "/liveLocation", { headers: this.authenticationService.headers })
      .pipe(catchError(this.handleError));
  }

  getAssets(id: string): Observable<Asset[]> {
    return this.http.get(this.url + id + "/Assets", { headers: this.authenticationService.headers }).pipe(
      map((data: any) => {
        const ident = 1;
        const assets: Asset[] = [];

        data.forEach((item) => {
          const asset = this.parseAsset(item);
          assets.push(asset);
        });

        return assets;
      }),
      catchError(this.handleError)
    );
  }

  getIssueCount(id: string, start, end): Observable<any> {
    return this.http
      .get(
        this.url + id + "/GetIssueCountByDay/?start=" + Math.round(start / 1000) + "&end=" + Math.round(end / 1000),
        { headers: this.authenticationService.headers }
      )
      .pipe(
        map((data: any) => {
          if (data) {
            if (Object.keys(data).length && typeof data[Object.keys(data)[0]] === "number") {
              return {
                issueCount: Object.keys(data).map((k) => [parseFloat(k) * 1000, data[k]]),
              };
            }
            return {
              issueCount: Object.keys(data).map((k) => [parseFloat(k) * 1000, data[k].issueCount]),
              issueTypes: Object.keys(data).map((k) => [parseFloat(k) * 1000, data[k].issueTypeCSV.split(",")]),
            };
          }
          return { issueCount: [] };
        }),
        catchError(this.handleError)
      );
  }

  getFiles(imei: string): Observable<any> {
    return this.http.get(this.baseUrl + "RawData?imei=" + imei, { headers: this.authenticationService.headers }).pipe(
      map((data) => {
        return data;
      }),
      catchError(this.handleError)
    );
  }

  getFileDetails(imei: string, file: string): Observable<any> {
    return this.http
      .get(this.baseUrl + "RawData/Details?imei=" + imei + "&file=" + file, {
        headers: this.authenticationService.headers,
      })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError(this.handleError)
      );
  }

  getFileDetailsRaw(imei: string, file: string): Observable<any> {
    return this.http
      .get(this.baseUrl + "RawData/Raw?imei=" + imei + "&file=" + file, { headers: this.authenticationService.headers })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError(this.handleError)
      );
  }

  private handleError(error: Response) {
    return throwError(() => error);
  }

  parseResponse(json: any): Device[] {
    this.loggingService.log(this.constructor.name, "Retrieved " + json.length + " Devices (parseResponse).");

    const ident = 1;
    const devices: Device[] = [];

    json.forEach((item) => {
      const device = this.parseReponseDetails(item);

      devices.push(device);
    });

    return devices;
  }

  parseReponseDetails(item) {
    const device = new Device();
    device.id = item.id;
    device.deviceStatus = item.deviceStatus;
    device.createdDate = item.createdDate ? Moment.utc(item.createdDate.replace(" ", "")).toDate() : new Date();
    device.activationDate = item.activationDate ? Moment.utc(item.activationDate.replace(" ", "")).toDate() : null;
    device.expirationDate = item.expirationDate ? Moment.utc(item.expirationDate.replace(" ", "")).toDate() : null;
    device.name = item.name;
    device.assetSearchName = item.asset?.name !== undefined ? item.asset?.name + " - " + item.unitId : item.unitId;
    device.customFields = item.customFields;
    device.modifiedDate = item.modifiedDate ? Moment.utc(item.modifiedDate.replace(" ", "")).toDate() : new Date();
    device.endDate = item.endDate ? Moment.utc(item.endDate.replace(" ", "")).toDate() : null;
    device.isEnded = device.endDate != null && device.endDate.getTime() < new Date().getTime();

    device.phone = item.phone;
    device.referenceId = item.referenceId;
    device.unitId = item.unitId;
    device.unitId2 = item.unitId2;
    device.modelName = item.modelName;
    device.modelId = item.modelId;
    device.modelThumb = item.modelThumb;
    device.manufacturer = item.manufacturer;
    device.manufacturerName = item.manufacturerName;
    device.sensors = item.sensors;
    device.issueCount = item.issueCount;
    device.isActive = item.isActive;
    device.isArchived = item.isArchived;

    device.platformId = item.platformId;
    device.platformName = item.platformName;

    device.engineHourRunningInSeconds = item.engineHourRunningInSeconds;
    device.incrementalEndOdo = item.incrementalEndOdo;

    device.accountPlatformId = item.accountPlatformId;
    device.accountPlatformName = item.accountPlatformName;

    device.accountId = item.accountId;

    device.companyName = item.companyName;

    device.note = item.note;
    device.isNoteWarning = item.isNoteWarning;

    device.isFlagged = item.isFlagged;

    device.deviceState = item.deviceState;
    if (item.deviceState) {
      device.deviceState = this.parseDeviceStateReponseDetails(device.deviceState);
    }

    device.headingInDegrees = item.headingInDegrees;

    device.firmware = item.firmware;

    device.simIdentifier = item.simIdentifier;
    device.deviceInventoryStatus = item.deviceInventoryStatus;
    device.simActivationStatus = item.simActivationStatus;
    device.msisdn = item.msisdn;
    device.imsi = item.imsi;

    device.deviceTypeId = item.deviceTypeId;

    device.resellerDescription = item.resellerDescription;
    device.resellerId = item.resellerId;

    // Inputs
    device.ignition = item.ignition;
    device.externalPower = item.externalPower;
    device.input1 = item.input1;
    device.input2 = item.input2;
    device.input3 = item.input3;
    device.input4 = item.input4;
    device.output1 = item.output1;
    device.output2 = item.output2;
    device.deviceAuxiliary = item.deviceAuxiliary;
    device.canBusParameters = item.canBusParameters;

    device.properties = new Properties();

    device.serviceRequests = item.serviceRequests;
    device.tags = item.tags;

    if (item.properties) {
      if (item.properties.sensorTemplateSettings) {
        device.properties.sensorTemplateSettings.temp1 = item.properties.sensorTemplateSettings.temp1;
        device.properties.sensorTemplateSettings.temp2 = item.properties.sensorTemplateSettings.temp2;
        device.properties.sensorTemplateSettings.temp3 = item.properties.sensorTemplateSettings.temp3;
        device.properties.sensorTemplateSettings.temp4 = item.properties.sensorTemplateSettings.temp4;
        device.properties.sensorTemplateSettings.weight1 = item.properties.sensorTemplateSettings.weight1;
        device.properties.sensorTemplateSettings.weight2 = item.properties.sensorTemplateSettings.weight2;
        device.properties.sensorTemplateSettings.humidity1 = item.properties.sensorTemplateSettings.humidity1;
        device.properties.sensorTemplateSettings.humidity2 = item.properties.sensorTemplateSettings.humidity2;
        device.properties.sensorTemplateSettings.fuel1 = item.properties.sensorTemplateSettings.fuel1;
        device.properties.sensorTemplateSettings.rpm1 = item.properties.sensorTemplateSettings.rpm1;
      }
    }

    device.settings = new DeviceSettings();

    if (item.settings) {
      device.settings.permittedIdlingInMinutes = item.settings.permittedIdlingInMinutes;
      device.settings.idlingCalculationMethod = item.settings.idlingCalculationMethod;
      device.settings.idlingRpmDuration = item.settings.idlingRpmDuration;
      device.settings.idlingRpmIsActive = item.settings.idlingRpmIsActive;
      device.settings.idlingRpmValue = item.settings.idlingRpmValue;
      device.settings.idlingInputDuration = item.settings.idlingInputDuration;
      device.settings.idlingInputIsActive = item.settings.idlingInputIsActive;
      device.settings.idlingInputValue = item.settings.idlingInputValue;
      device.settings.idlingVoltageDuration = item.settings.idlingVoltageDuration;
      device.settings.idlingVoltageIsActive = item.settings.idlingVoltageIsActive;
      device.settings.idlingVoltageValue = item.settings.idlingVoltageValue;

      device.settings.inputPorts = item.settings.inputs;
      device.settings.analogPorts = item.settings.analogInputs;
      device.settings.outputPorts = item.settings.outputs;

      device.settings.externalPower = item.settings.externalPower;
      device.settings.ignition = item.settings.ignition;
      device.settings.isStickyDriver = item.settings.isStickyDriver;
      device.settings.useCanSpeed = item.settings.useCanSpeed;

      device.settings.homeIntervalInSeconds = item.settings.homeIntervalInSeconds;
      device.settings.roamingIntervalInSeconds = item.settings.roamingIntervalInSeconds;
      device.settings.notCommunicatingThresholdInMinutes = item.settings.notCommunicatingThresholdInMinutes;
      device.settings.reportExternalPowerLostImmediately = item.settings.reportExternalPowerLostImmediately;

      // Device aux
      const deviceAuxiliary = [];

      if (item.settings.deviceAuxiliary > 0) {
        for (let i = 0; i < auxilaryOptionCount; i++) {
          const flag = BigInt(2 ** i);

          flag === (BigInt(item.settings.deviceAuxiliary) & flag) &&
            deviceAuxiliary.push({
              id: flag,
              itemName: this.translateService.instant("enums.deviceAuxiliary." + flag),
            });
        }
      }

      device.settings.deviceAuxiliary = deviceAuxiliary;

      // Canbus
      const canBusParameters = [];

      if (item.settings.canBusParameters > 0) {
        for (let i = 0; i < canOptionCount; i++) {
          const flag = BigInt(2 ** i);

          flag === (BigInt(item.settings.canBusParameters) & flag) &&
            canBusParameters.push({
              id: flag,
              itemName: this.translateService.instant("enums.canBusParameters." + flag),
            });
        }
      }

      device.settings.canBusParameters = canBusParameters;
    }

    device.deviceTypeNameFull = item.deviceTypeName;

    if (this.permissions["Platform_IsReseller"]) {
      device.deviceTypeNameFull = item.deviceTypeNameFull;
    }

    if (item.asset) {
      const asset = this.parseAsset(item.asset);
      device.asset = asset;
    }

    return device;
  }

  parseAsset(item) {
    const asset = new Asset();
    asset.id = item.id;
    asset.deviceId = item.deviceId;
    asset.name = item.name;
    asset.year = item.year;
    asset.vin = item.vin;
    // asset.referenceId = item.referenceId;
    asset.plateNumber = item.plateNumber;
    asset.color = item.color;
    asset.code = item.code;
    asset.brand = item.brand;
    asset.model = item.model;
    asset.comment = item.comment;
    asset.isCommentWarning = item.isCommentWarning;
    asset.vehicleType = item.vehicleType ? item.vehicleType.toString() : "0";

    asset.isAssetTracker = item.vehicleType == 8 || item.vehicleType == 7;
    asset.icon = item.iconId;

    asset.calibratedOdo = item.calibratedOdo;
    asset.lastCalibration = item.lastCalibration;

    asset.assetTypeId = item.assetTypeId;
    asset.assetTypeName = item.assetTypeName;

    asset.owner = item.owner;
    asset.clientAssetCategory = item.clientAssetCategory;
    asset.clientAssetSubCategory = item.clientAssetSubCategory;
    asset.ownership = item.ownership;
    asset.fuelType = item.fuelType;
    asset.capacity = item.capacity;
    asset.bodyCode = item.bodyCode;
    asset.engineSerialNumber = item.engineSerialNumber;
    asset.engineType = item.engineType;
    asset.costCenter = item.costCenter;
    asset.madeIn = item.madeIn;
    asset.plateMunicipality = item.plateMunicipality;
    asset.dateAcquired = item.dateAcquired !== undefined ? Moment.utc(item.dateAcquired).toDate() : undefined;
    asset.dealerName = item.dealerName;
    asset.purchasePrice = item.purchasePrice;
    asset.monthlyInsurance = item.monthlyInsurance;
    asset.monthlyRoadTax = item.monthlyRoadTax;
    asset.currentValue = item.currentValue;
    asset.totalDepreciation = item.totalDepreciation;
    asset.dateDepreciation =
      item.dateDepreciation !== undefined ? Moment.utc(item.dateDepreciation).toDate() : undefined;
    asset.dateSold = item.dateSold !== undefined ? Moment.utc(item.dateSold).toDate() : undefined;
    asset.soldTo = item.soldTo;
    asset.soldPrice = item.soldPrice;
    asset.insurancePolicy = item.insurancePolicy;
    asset.dateExpireInsurance =
      item.dateExpireInsurance !== undefined ? Moment.utc(item.dateExpireInsurance).toDate() : undefined;
    asset.registrationNumber = item.registrationNumber;
    asset.dateExpireRegistration =
      item.dateExpireRegistration !== undefined ? Moment.utc(item.dateExpireRegistration).toDate() : undefined;
    asset.permitOne = item.permitOne;
    asset.permitOneExpirationDate =
      item.permitOneExpirationDate !== undefined ? Moment.utc(item.permitOneExpirationDate).toDate() : undefined;
    asset.permitTwo = item.permitTwo;
    asset.permitTwoExpirationDate =
      item.permitTwoExpirationDate !== undefined ? Moment.utc(item.permitTwoExpirationDate).toDate() : undefined;
    asset.safetyCertificate = item.safetyCertificate;
    asset.safetyCertificateExpirationDate =
      item.safetyCertificateExpirationDate !== undefined
        ? Moment.utc(item.safetyCertificateExpirationDate).toDate()
        : undefined;
    asset.safetyTestedBy = item.safetyTestedBy;
    asset.erpCode = item.erpCode;
    asset.branding = item.branding;
    asset.equipment = item.equipment;
    asset.garageId = item.garageId;
    asset.garageName = item.garageName;
    asset.assignedScheduleId = item.assignedScheduleId;

    asset.emissionCO2 = item.emissionCO2;
    asset.emissionParticlesLight = item.emissionParticlesLight;
    asset.emissionParticlesHeavy = item.emissionParticlesHeavy;
    asset.urbanConsumption = item.urbanConsumption;
    asset.extraUrbanConsumption = item.extraUrbanConsumption;
    asset.mixedConsumption = item.mixedConsumption;

    // Calibration
    asset.calibrationPoints = item.calibrationPoints;
    asset.calibratedOdo = item.calibratedOdo;
    asset.calibrationOdoOffset = item.calibrationOdoOffset;
    asset.calibrationOdoOffsetInKm = item.calibrationOdoOffset ? Math.round(item.calibrationOdoOffset / 1000) : null;
    asset.incrementalOdoDistanceOffset = item.incrementalOdoDistanceOffset;
    asset.lastCalibration =
      item.lastCalibration !== undefined
        ? Moment.utc(item.lastCalibration)["tz"](this.timezoneIana)?.toDate()
        : undefined;

    asset.assignmentEnd = item.assetDateEnd ? Moment.utc(item.assetDateEnd)["tz"](this.timezoneIana)?.toDate() : null;
    asset.assignmentStart = item.assetDateStart
      ? Moment.utc(item.assetDateStart)["tz"](this.timezoneIana)?.toDate()
      : Moment.utc();

    // Engine hours
    asset.engineHourCalibrationOdoOffset = item.engineHourCalibrationOdoOffset;
    asset.tripEngineHourInSeconds = item.tripEngineHourInSeconds;
    asset.engineHourCalibrationPoints = item.engineHourCalibrationPoints;
    asset.engineHourLastCalibration =
      item.engineHourLastCalibration !== undefined
        ? Moment.utc(item.engineHourLastCalibration)["tz"](this.timezoneIana)?.toDate()
        : undefined;

    // Driver
    if (item.driver) {
      const driver = new Driver();
      driver.id = item.driver.id;
      driver.name = item.driver.name;

      driver.firstName = item.driver.firstName;
      driver.lastName = item.driver.lastName;

      driver.mobilePhone = item.driver.mobilePhone;
      driver.officePhone = item.driver.officePhone;
      driver.homePhone = item.driver.homePhone;

      driver.assignmentStart = item.assignmentStart;
      driver.assignmentEnd = item.assignmentEnd;

      asset.driver = driver;
    }

    if (
      item.currentAddress &&
      (item.currentAddress.adress || item.currentAddress.city || item.currentAddress.country)
    ) {
      asset.currentAddress = {
        address: item.currentAddress.address,
        city: item.currentAddress.city,
        country: item.currentAddress.country,
      };
    }

    if (item.activeProject) {
      asset.activeProject = {
        id: item.activeProject.id,
        name: item.activeProject.name,
      };
    }

    // Wasl and SFDA registration
    const wasl = new RegisterOperatingAsset();

    if (item.properties) {
      if (item.properties.custom) {
        asset.properties.custom = [];

        item.properties.custom.forEach((row, index) => {
          asset.properties.custom.push({ key: row.key, value: row.value });
        });
      }

      if (item.properties.dashcams) {
        asset.properties.dashcams = [];

        item.properties.dashcams.forEach((row, index) => {
          asset.properties.dashcams.push({ name: row.name, url: row.url });
        });
      }

      if (item.properties.wasl) {
        wasl.plateType = item.properties.wasl.plateType;
        wasl.sequenceNumber = item.properties.wasl.sequenceNumber;
        wasl.overrideAccountId = item.properties.wasl.overrideAccountId;

        wasl.vehiclePlateNumber = item.properties.wasl.vehiclePlateNumber;
        wasl.vehiclePlateLeftLetter = item.properties.wasl.vehiclePlateLeftLetter;
        wasl.vehiclePlateMiddleLetter = item.properties.wasl.vehiclePlateMiddleLetter;
        wasl.vehiclePlateRightLetter = item.properties.wasl.vehiclePlateRightLetter;
        wasl.storingCategory = item.properties.wasl.storingCategory;

        wasl.referenceKey = item.properties.wasl.referenceKey;

        wasl.registerDateWasl = item.properties.wasl.registerDateWasl;
        wasl.registerDateSfda = item.properties.wasl.registerDateSfda;
        wasl.registerDateTow = item.properties.wasl.registerDateTow;
        wasl.registerDateSpecialityTransport = item.properties.wasl.registerDateSpecialityTransport;
        wasl.registerDateBusRental = item.properties.wasl.registerDateBusRental;
        wasl.registerDateEducationalTransport = item.properties.wasl.registerDateEducationalTransport;

        if (item.properties.wasl.inquiryStatus) {
          wasl.inquiryStatus = new WaslInquiry();
          wasl.inquiryStatus.inquiryTime = item.properties.wasl.inquiryStatus.inquiryTime;
          wasl.inquiryStatus.isVehicleValid = item.properties.wasl.inquiryStatus.isVehicleValid;
          wasl.inquiryStatus.vehicleRejectionReason = item.properties.wasl.inquiryStatus.vehicleRejectionReason;
          wasl.inquiryStatus.registrationTime = item.properties.wasl.inquiryStatus.registrationTime;
          wasl.inquiryStatus.vehicleStatus = item.properties.wasl.inquiryStatus.vehicleStatus;
          wasl.inquiryStatus.actualTime = item.properties.wasl.inquiryStatus.actualTime;
          wasl.inquiryStatus.receivedTime = item.properties.wasl.inquiryStatus.receivedTime;
        }
      }
    }

    asset.properties.wasl = wasl;

    asset.companyName = item.companyName;
    asset.accountId = item.accountId;

    asset.resellerDescription = item.resellerDescription;
    asset.resellerId = item.resellerId;

    try {
      asset.assetGroups = item.assetGroups != null ? JSON.parse(item.assetGroups.replace("\t", "")) : [];
    } catch (error) {
      console.log(error);
    }

    if (asset.assetGroups && asset.assetGroups.length > 0) {
      asset.assetGroups.forEach((assetGroup) => {
        assetGroup.name = localizeSystemGroupNames(assetGroup.name, this.translateService);
        assetGroup.color = assetGroup.color;
      });
    }

    asset.assetGroupIds = [];
    if (item.assetGroupIds) {
      item.assetGroupIds.forEach((assetGroupId) => {
        asset.assetGroupIds.push(assetGroupId);
      });
    }

    return asset;
  }

  public parseDeviceStateReponseDetails(item): DeviceStatesItem {
    const formatTimestamps = (data) =>
      Object.keys(data).every((v) => {
        const value = data[v];

        if (value === null || value === 0) {
          return true;
        }

        if (v === "updateTimestamp" || v === "UpdateTimeStamp" || v === "Timestamp") {
          data[v] = Moment.utc(value)["tz"](this.timezoneIana);
        }

        if (typeof value === "object") {
          formatTimestamps(value);
        }

        return true;
      });

    let locationObject = new DeviceStatesItem();
    locationObject = item;

    formatTimestamps(locationObject);

    return locationObject;
  }
}
