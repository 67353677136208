<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12 flex header-content">
        <nav class="breadcrumb-container" aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a href="/#/"> <i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
                </li>
                <li class="breadcrumb-item">
                    <a href="/#/MaintenancesV2">{{'general.maintenance' | translate }}</a>
                </li>
                <li class="breadcrumb-item active">{{'general.dashboard' | translate }}</li>
            </ol>
        </nav>
    </div>
    <fh-maintenance-v2-navigation></fh-maintenance-v2-navigation>
</div>

<div class="wrapper wrapper-content">
    <div class="row" data-equalizer="">
        <div class="col-lg-12">
            <fh-error [error]="error" [success]="success" [warning]="warning"></fh-error>

            <div class="ibox">
                <div class="ibox-title">
                    <h5><i class="fas fa-fw fa-filter"></i> {{'general.filter' | translate }}</h5>
                </div>
                <div class="ibox-content slick_toppadding flex-container" style="position: relative">
                    <ngx-loading [show]="loading"></ngx-loading>
                    <div class="col-lg-6">
                        <div class="form-group input-group" style="margin-bottom: 6px; display: block">
                            <fh-reseller-input
                                name="reseller"
                                #resellerVar="ngModel"
                                (onChanged)="resellerChanged($event)"
                                required
                                placeholder="{{ 'general.chooseReseller' | translate}}"
                                [(ngModel)]="selectedResellerId"
                            >
                            </fh-reseller-input>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="form-group input-group" style="margin-bottom: 6px; display: block">
                            <fh-account-input
                                name="account"
                                #accountVar="ngModel"
                                (onChanged)="accountChanged($event)"
                                required
                                [selectedResellerId]="selectedResellerId"
                                [showEmptyAccountsButton]="false"
                                placeholder="{{ (selectedResellerId ? 'general.chooseAccount' : 'general.firstChooseReseller') | translate}}"
                                [(ngModel)]="selectedAccountId"
                            >
                            </fh-account-input>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-lg-7">
            <div class="ibox">
                <div class="ibox-title">
                    <h5><i class="fas fa-fw fa-dollar"></i> {{'general.costPerMonth' | translate }}</h5>
                    <span class="float-right">
                        <ibox-chart-export [chartObject]="chartCostPerMonth"> </ibox-chart-export>
                    </span>
                </div>
                <div class="ibox-content slicker" style="position: relative; min-height: 178px">
                    <ngx-loading [show]="loading"></ngx-loading>

                    <highcharts-chart
                        *ngIf="chartCostPerMonth"
                        [runOutsideAngular]="true"
                        style="display: block; width: 100%; height: 178px"
                        [Highcharts]="Highcharts"
                        [options]="chartCostPerMonth"
                    ></highcharts-chart>
                </div>
            </div>

            <div class="ibox">
                <div class="ibox-title">
                    <h5><i class="fas fa-fw fa-dollar"></i> {{'general.costPerAsset' | translate }}</h5>
                    <span class="float-right">
                        <ibox-chart-export [chartObject]="chartDistanceCompare"> </ibox-chart-export>
                    </span>
                </div>
                <div class="ibox-content slicker" style="position: relative; min-height: 178px">
                    <ngx-loading [show]="loading"></ngx-loading>

                    <highcharts-chart
                        *ngIf="chartDistanceCompare"
                        [runOutsideAngular]="true"
                        style="display: block; width: 100%; height: 178px"
                        [Highcharts]="Highcharts"
                        [options]="chartDistanceCompare"
                    ></highcharts-chart>
                </div>
            </div>

            <div class="ibox">
                <div class="ibox-title">
                    <h5><i class="fa fa-fw fa-clock"></i> {{'general.expectedMaintenance' | translate }}</h5>
                    <span class="float-right" style="margin-top: -6px; margin-bottom: -10px">
                        <div class="btn-group" dropdown>
                            <button
                                id="button-basic"
                                dropdownToggle
                                type="button"
                                class="btn btn-info dropdown-toggle"
                                aria-controls="dropdown-animated"
                            >
                                {{ viewDate | calendarDate:(view + 'ViewTitle'):locale:weekStartsOn }}
                                <span class="caret"></span>
                            </button>
                            <ul
                                id="dropdown-basic"
                                *dropdownMenu
                                class="dropdown-menu"
                                role="menu"
                                aria-labelledby="button-basic"
                            >
                                <li role="menuitem">
                                    <a mwlCalendarToday [(viewDate)]="viewDate" class="dropdown-item"
                                        >{{ 'general.today' | translate }}</a
                                    >
                                </li>
                                <li class="divider dropdown-divider"></li>
                                <li role="menuitem">
                                    <a (click)="setView(CalendarView.Month)" class="dropdown-item"
                                        ><i class="fa fa-fw fa-calendar-days"></i> {{ 'general.month' | translate }}</a
                                    >
                                </li>
                                <li role="menuitem">
                                    <a (click)="setView(CalendarView.Week)" class="dropdown-item"
                                        ><i class="fa fa-fw fa-calendar-week"></i> {{ 'general.week' | translate }}</a
                                    >
                                </li>
                                <li role="menuitem">
                                    <a (click)="setView(CalendarView.Day)" class="dropdown-item"
                                        ><i class="fa fa-fw fa-calendar"></i> {{ 'general.day' | translate }}</a
                                    >
                                </li>
                            </ul>
                        </div>

                        <div class="btn-group">
                            <div
                                class="btn btn-info"
                                mwlCalendarPreviousView
                                [view]="view"
                                [(viewDate)]="viewDate"
                                (viewDateChange)="closeOpenMonthViewDay()"
                            >
                                <i class="fa fa-fw fa-arrow-left"></i>
                            </div>
                            <div
                                class="btn btn-info"
                                mwlCalendarNextView
                                [view]="view"
                                [(viewDate)]="viewDate"
                                (viewDateChange)="closeOpenMonthViewDay()"
                            >
                                <i class="fa fa-fw fa-arrow-right"></i>
                            </div>
                        </div>
                    </span>
                    <div class="ibox-content slick" style="position: relative; overflow-y: auto">
                        <ngx-loading [show]="loadingMaintenanceDue"></ngx-loading>

                        <div [ngSwitch]="view">
                            <mwl-calendar-month-view
                                *ngSwitchCase="CalendarView.Month"
                                [viewDate]="viewDate"
                                [events]="events"
                                [refresh]="refresh"
                                [activeDayIsOpen]="activeDayIsOpen"
                                (dayClicked)="dayClicked($event.day)"
                                (eventClicked)="handleEvent('Clicked', $event.event)"
                                [locale]="locale"
                                [weekStartsOn]="weekStartsOn"
                                (eventTimesChanged)="eventTimesChanged($event)"
                            >
                            </mwl-calendar-month-view>
                            <mwl-calendar-week-view
                                *ngSwitchCase="CalendarView.Week"
                                [viewDate]="viewDate"
                                [events]="events"
                                [refresh]="refresh"
                                (eventClicked)="handleEvent('Clicked', $event.event)"
                                [locale]="locale"
                                [weekStartsOn]="weekStartsOn"
                                (eventTimesChanged)="eventTimesChanged($event)"
                            >
                            </mwl-calendar-week-view>
                            <mwl-calendar-day-view
                                *ngSwitchCase="CalendarView.Day"
                                [viewDate]="viewDate"
                                [events]="events"
                                [refresh]="refresh"
                                (eventClicked)="handleEvent('Clicked', $event.event)"
                                [locale]="locale"
                                (eventTimesChanged)="eventTimesChanged($event)"
                            >
                            </mwl-calendar-day-view>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-lg-5">
            <div class="ibox">
                <div class="ibox-title">
                    <h5><i class="fa fa-fw fa-clock"></i> {{'general.status' | translate }}</h5>
                </div>

                <div class="ibox-content slick" style="min-height: 140px; position: relative">
                    <ngx-loading [show]="loadingMaintenance"></ngx-loading>
                    <table style="width: 100%" class="table nowrap dtr-inline no-footer">
                        <tbody>
                            <tr
                                class="hand"
                                [routerLink]="'/MaintenancesV2/Due'"
                                [queryParams]="{ accountId: selectedAccountId, maintenanceStatus: 1}"
                            >
                                <td>{{'general.healthy' | translate }}</td>
                                <td style="text-align: right">
                                    <span class="badge badge-success" style="min-width: 70px"
                                        >{{ maintenanceStatus?.healthy?.toLocaleString() }}</span
                                    >
                                </td>
                            </tr>
                            <tr
                                class="hand"
                                [routerLink]="'/MaintenancesV2/Due'"
                                [queryParams]="{ accountId: selectedAccountId, maintenanceStatus: 4}"
                            >
                                <td>{{'general.inWorkshop' | translate }}</td>
                                <td style="text-align: right">
                                    <span class="badge badge-primary" style="min-width: 70px"
                                        >{{ maintenanceStatus?.inWorkshop?.toLocaleString() }}</span
                                    >
                                </td>
                            </tr>
                            <tr
                                class="hand"
                                [routerLink]="'/MaintenancesV2/Due'"
                                [queryParams]="{ accountId: selectedAccountId, maintenanceStatus: 2}"
                            >
                                <td>{{'general.dueSoon' | translate }}</td>
                                <td style="text-align: right">
                                    <span class="badge badge-warning" style="min-width: 70px"
                                        >{{ maintenanceStatus?.dueSoon?.toLocaleString() }}</span
                                    >
                                </td>
                            </tr>
                            <tr
                                class="hand"
                                [routerLink]="'/MaintenancesV2/Due'"
                                [queryParams]="{ accountId: selectedAccountId, maintenanceStatus: 3}"
                            >
                                <td>{{'general.overdue' | translate }}</td>
                                <td style="text-align: right">
                                    <span class="badge badge-danger" style="min-width: 70px"
                                        >{{ maintenanceStatus?.overdue?.toLocaleString() }}</span
                                    >
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div class="ibox">
                <div class="ibox-title">
                    <h5><i class="fas fa-fw fa-dollar"></i> {{'general.costPerType' | translate }}</h5>
                    <span class="float-right">
                        <ibox-chart-export [chartObject]="chartCostPerType"> </ibox-chart-export>
                    </span>
                </div>
                <div class="ibox-content slicker" style="position: relative; min-height: 278px">
                    <ngx-loading [show]="loading"></ngx-loading>

                    <highcharts-chart
                        *ngIf="chartCostPerType"
                        [runOutsideAngular]="true"
                        style="display: block; width: 100%; height: 278px"
                        [Highcharts]="Highcharts"
                        [options]="chartCostPerType"
                    ></highcharts-chart>
                </div>
            </div>
        </div>
    </div>
</div>
