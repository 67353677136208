<div class="ibox-content slick" style="position: relative">
    <table style="width: 100%" class="table table-fixed nowrap dtr-inline no-footer">
        <tbody *ngIf="!assetType">
            <tr>
                <td colspan="2">
                    {{ "general.noAssetType" | translate }}
                </td>
            </tr>
        </tbody>
        <tbody *ngIf="assetType">
            <ng-container *ngIf="formMode != 2">
                <tr [hidden]="!permissions['Resellers_View']">
                    <td class="row_opening">
                        {{ "general.resellerDescription" | translate }}
                    </td>
                    <td class="padding-readonly-correct row_content">
                        <a class="secondary link_bolder" href="/#/AccountDetails/Index/{{ assetType?.resellerId }}">{{
                            assetType?.resellerDescription
                        }}</a>
                    </td>
                </tr>
                <tr [hidden]="!permissions['Accounts_View']">
                    <td class="row_opening">
                        {{ "general.account" | translate }}
                    </td>
                    <td class="row_content padding-readonly-correct">
                        <a class="secondary link_bolder" href="/#/AccountDetails/Index/{{ assetType?.accountId }}">{{
                            assetType?.companyName
                        }}</a>
                    </td>
                </tr>
            </ng-container>
            <ng-container *ngIf="formMode == 2">
                <tr [hidden]="!permissions['Resellers_View'] && selectedResellerId">
                    <td class="row_opening">
                        {{ "general.resellerDescription" | translate }}
                        <span class="error" *ngIf="resellerVar.invalid">*</span>
                    </td>
                    <td class="row_content" style="position: relative; overflow: visible">
                        <fh-reseller-input
                            name="reseller"
                            #resellerVar="ngModel"
                            (onChanged)="resellerChanged($event)"
                            required
                            placeholder="{{ 'general.chooseReseller' | translate }}"
                            [tableInline]="true"
                            [(ngModel)]="selectedResellerId"
                        >
                        </fh-reseller-input>
                    </td>
                </tr>
                <tr [hidden]="!permissions['Accounts_View'] && assetType.accountId">
                    <td class="row_opening">
                        {{ "general.account" | translate }}
                        <span class="error" *ngIf="accountVar.invalid">*</span>
                    </td>
                    <td class="row_content" style="position: relative; overflow: visible">
                        <fh-account-input
                            name="account"
                            #accountVar="ngModel"
                            (onChanged)="accountChanged($event)"
                            required
                            [selectedResellerId]="selectedResellerId"
                            [tableInline]="true"
                            placeholder="{{
                                (selectedResellerId ? 'general.chooseAccount' : 'general.firstChooseReseller')
                                    | translate
                            }}"
                            [(ngModel)]="assetType.accountId"
                        >
                        </fh-account-input>
                    </td>
                </tr>
            </ng-container>
            <tr *ngIf="formMode != 2">
                <td class="row_opening">
                    {{ "general.assetsLinked" | translate }}
                </td>
                <td class="padding-readonly-correct row_content">
                    <div
                        class="secondary link_bolder hand"
                        [routerLink]="['/Devices/Overview/']"
                        [queryParams]="{ assetType: assetType.name, companyName: assetType?.companyName }"
                    >
                        <span>{{ assetType?.assetCount }}</span>
                    </div>
                </td>
            </tr>
            <tr>
                <td class="row_opening">
                    {{ "general.name" | translate }}
                    <span class="error" *ngIf="name.invalid && formMode != 1">*</span>
                </td>
                <td class="row_content">
                    <input
                        type="text"
                        [readonly]="formMode == 1"
                        name="name"
                        class="form-control table-inline"
                        placeholder="{{ 'placeholder.name' | translate }}"
                        [(ngModel)]="assetType.name"
                        required
                        minlength="4"
                        #name="ngModel"
                    />
                </td>
            </tr>
            <tr>
                <td colspan="2" style="background-color: unset; border-bottom: none !important; padding: 2px">
                    <textarea
                        name="note"
                        required
                        [readonly]="formMode == 1"
                        name="note"
                        class="noresize stickypad"
                        [ngClass]="{ error: description.invalid }"
                        style="height: 100px; width: 100%; resize: none"
                        id="encCss"
                        placeholder="{{ 'placeholder.comments' | translate }}"
                        #description="ngModel"
                        [(ngModel)]="assetType.description"
                    ></textarea>
                </td>
            </tr>
        </tbody>
    </table>
</div>
