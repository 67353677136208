<nav [class.fo-hidden-sidebar]="sidebarHidden === true">
    <div>
        <ul style="max-height: 100%" class="nav metismenu" id="side-menu">
            <li class="nav-header nav-overview">
                <div class="dropdown profile-element hand" [routerLink]="['/']">
                    <span style="display: none" class="fleet-overview-logo">
                        <i
                            id="mainIcon"
                            class="fa fa-fw"
                            [class.fa-map]="!fetchingStates"
                            [class.fa-spin]="fetchingStates"
                            [class.fa-sync]="fetchingStates"
                        ></i>

                        {{'general.fleetOverview' | translate}}</span
                    >
                </div>
            </li>
            <li class="fleet-overview-tabs">
                <button
                    [disabled]="mode !== 'Overview'"
                    [class.active]="tab === 'Assets'"
                    (click)="changeTab('Assets')"
                    class="fleet-overview-tab"
                >
                    <i class="fas fa-fw fa-car-bus"></i>
                    <span *ngIf="tab === 'Assets'"> {{'general.assets' | translate}}</span>
                </button>
                <button
                    [disabled]="mode !== 'Overview'"
                    [class.active]="tab === 'Drivers'"
                    (click)="changeTab('Drivers')"
                    class="fleet-overview-tab"
                >
                    <i class="fas fa-fw fa-id-card-alt"></i>
                    <span *ngIf="tab === 'Drivers'"> {{'general.drivers' | translate}}</span>
                </button>
                <button
                    [disabled]="mode !== 'Overview'"
                    [class.active]="tab === 'Geofences'"
                    (click)="changeTab('Geofences')"
                    class="fleet-overview-tab"
                >
                    <i class="fas fa-fw fa-draw-polygon"></i>
                    <span *ngIf="tab === 'Geofences'"> {{'general.geofences' | translate}}</span>
                </button>
                <!-- <button class="fleet-overview-tab" style="width:40px">
          <i *ngIf="mode !== 'History'" class='fas fa-fw fa-filter'></i>
        </button> -->

                <div class="btn-group">
                    <div class="btn" style="display: none"></div>
                    <button
                        style="padding: 7.5px 12.5px; box-shadow: none"
                        (click)="toggleAssetStatus()"
                        type="button"
                        [disabled]="mode == 'History'"
                        class="btn dropdown-toggle-split fleet-overview-tab"
                        data-toggle="dropdown"
                        aria-expanded="false"
                    >
                        <span class="sr-only">{{ 'general.enable' | translate }}</span>
                        <i class="fas fa-fw fa-filter"></i>
                    </button>
                    <ul [class.show]="filterToggle === true" class="dropdown-menu">
                        <li class="dropdown-header">{{ 'general.deviceStatus' | translate }}</li>

                        <li
                            *ngFor="let dropdown of dropdownStates"
                            class="btn dropdown-item"
                            style="padding: 0; margin: 0"
                        >
                            <div style="margin-bottom: 0" class="fa_checkbox">
                                <input
                                    [id]="'filterSelection_' + dropdown.state"
                                    [name]="'filterSelection_' + dropdown.state"
                                    type="checkbox"
                                    checked
                                    [disabled]="state !== 'Loaded' || assetGroups.size === 0"
                                    (change)="$event.stopPropagation?.(); visibilityAssetStatus($event, dropdown.state)"
                                />
                                <label
                                    style="padding: 8.5px 12.5px; transform: translateY(2px); width: 100%"
                                    [for]="'filterSelection_' + dropdown.state"
                                >
                                    <i
                                        style="margin: 4px"
                                        class="eye-active fa fa-fw {{dropdown.image}} {{dropdown.color}}"
                                    ></i>
                                    {{ 'general.deviceState_' + dropdown.state | translate }} ({{
                                    dropdown.count.call(this) }})
                                </label>
                            </div>
                        </li>

                        <li style="margin-top: 0" class="dropdown-divider"></li>

                        <li class="btn dropdown-item" style="padding: 0">
                            <div style="margin-bottom: 0" class="fa_checkbox">
                                <input
                                    [id]="'filterGps_' + '0'"
                                    [name]="'filterGps_' + '0'"
                                    type="checkbox"
                                    [disabled]="state !== 'Loaded' || assetGroups.size === 0"
                                    (change)="$event.stopPropagation?.(); visibilityAssetStatus($event, 6)"
                                />
                                <label
                                    style="padding: 8.5px 12.5px; transform: translateY(2px); width: 100%"
                                    [for]="'filterGps_' + '0'"
                                >
                                    <i style="margin: 4px" class="eye-active fa fa-rss fa-fw green"></i> {{
                                    'general.deviceState_6' | translate }}
                                </label>
                            </div>
                        </li>
                    </ul>
                </div>
            </li>

            <div class="fleet-overview-sidebar">
                <div style="padding: 4px">
                    <!-- Search -->
                    <div
                        [style.display]="mode !== 'History' ? 'flex' : 'none'"
                        class="input-group"
                        style="padding: 3px; max-height: 55px; border-radius: 4px; background-color: #fff; color: #555"
                    >
                        <span class="input-group-prepend" style="padding: 10px 6px 10px 6px">
                            <i class="fa fa-fw fa-search"></i>
                        </span>
                        <input
                            style="width: 100%; padding: 6px"
                            type="text"
                            min="0"
                            class="form-control"
                            #ref
                            placeholder="{{ 'general.search' | translate}}"
                            [(ngModel)]="filter"
                            (ngModelChange)="onSearchChanged(ref.value)"
                        />
                    </div>

                    <!-- Datepicker -->
                    <div
                        [style.display]="mode === 'History' ? 'flex' : 'none'"
                        class="input-group"
                        style="padding: 3px; max-height: 55px; border-radius: 4px; background-color: #fff; color: #555"
                    >
                        <span style="padding-top: 10px" class="input-group-prepend" style="padding: 10px 6px 10px 6px">
                            <i class="fa fa-fw fa-calendar"></i>
                        </span>
                        <input
                            placeholder="Daterangepicker"
                            style="width: 100%; margin-top: 0px; width: 100%; padding: 6px; margin-bottom: -5px"
                            placeholder="Datepicker"
                            type="text"
                            [bsConfig]="dpConfig"
                            class="form-control"
                            placement="bottom"
                            bsDaterangepicker
                            (onHidden)="dateChanged('hidden')"
                            [(ngModel)]="daterangepickerModel"
                        />
                        <div style="clear: both"></div>
                    </div>
                </div>

                <!-- List -->
                <div class="fleet-overview-list">
                    <div *ngIf="state !== 'Loaded'">
                        <div class="fleet-overview-header">
                            <a style="display: block; align-items: center">
                                <span style="padding: 12px" *ngIf="state === 'Initialize'">
                                    {{ 'general.loadingData' | translate }}
                                </span>
                                <span style="padding: 12px" *ngIf="state === 'FetchingState'">
                                    {{ 'general.fetchingState' | translate }}
                                </span>
                                <span style="padding: 12px" *ngIf="state === 'LoadingMap'">
                                    {{ 'general.loadingMap' | translate }}
                                </span>
                            </a>
                        </div>
                    </div>
                    <div
                        *ngIf="state === 'Loaded' && ((tab === 'Assets' && emptyGroups(assetGroups)) || (tab === 'Drivers' && emptyGroups(driverGroups)) || (tab === 'Geofences' && emptyGroups(geofenceGroups)))"
                        class="fleet-overview-header"
                    >
                        <a style="display: block; align-items: center">
                            <span style="padding: 12px"> {{ 'general.noGroupsFound' | translate }} </span>
                        </a>
                    </div>

                    <!-- Assets -->
                    <div
                        [style.display]="(state === 'Loaded' || state === 'FetchingState') && tab === 'Assets' ? 'block' : 'none'"
                    >
                        <div
                            *ngIf="extendFilterMap(assetGroups) as groups"
                            [style.display]="mode === 'Overview' ? 'block' : 'none'"
                        >
                            @for (assetGroup of groups | keyvalue: valueAscOrder; track assetGroup.key; let i = $index;)
                            {
                            <ng-container *ngIf="i < (maxGroups + currentMarker)">
                                <ng-container
                                    *ngIf="(assetGroup.value.items | stringFilter: filter | hiddenFilter) as filteredGroup"
                                >
                                    <!-- [style.display]="!(filteredGroup.length === 0) ? 'block' : increaseViewCount() && 'none'"  -->
                                    <li
                                        *ngIf="!(filteredGroup.length === 0 && increaseViewCount())"
                                        class="fleet-overview-header"
                                        style="position: relative"
                                    >
                                        <div
                                            style="position: absolute; top: 14px; left: 5px"
                                            class="fa_checkbox"
                                            [class.intermediate]="!watchGroupSelected(deviceSelection, filteredGroup)"
                                        >
                                            <input
                                                [id]="'assetGroupSelection_' + assetGroup.key"
                                                type="checkbox"
                                                [name]="'assetGroupSelection_' + assetGroup.key"
                                                [ngModel]="assetGroupSelection.get(assetGroup.key)"
                                                (ngModelChange)="$event.stopPropagation?.(); selectAssetGroup(assetGroup, filteredGroup, $event)"
                                            />
                                            <label [for]="'assetGroupSelection_' + assetGroup.key"></label>
                                        </div>

                                        <a
                                            style="display: flex; align-items: center; justify-content: space-between"
                                            (click)="openGroup(assetGroup)"
                                        >
                                            <div [title]="assetGroup.value.name" class="fleet-overview-header-label">
                                                {{ assetGroup.value.name }}
                                            </div>

                                            <div style="flex: 0 0 auto">
                                                <span style="padding: 0 2.5px">({{filteredGroup.length}})</span>

                                                <span
                                                    style="float: unset; margin-left: 5px"
                                                    class="fa arrow"
                                                    [ngClass]="(assetGroup.value.filterClose === false && filter.length > 0) || assetGroup.value.open === true ? 'fa-rotate-270' : 'fa-flip-horizontal'"
                                                >
                                                </span>

                                                <ng-container
                                                    *ngIf="watchGroupVisibility(hiddenAssets, assetGroup.value.items); else groupInvisible;"
                                                >
                                                    <i
                                                        (click)="$event.stopPropagation?.(); changeGroupVisibility(hiddenAssets, false, filteredGroup);"
                                                        class="fa fa-fw fa-eye fleet-overview-eye-style"
                                                    ></i>
                                                </ng-container>

                                                <ng-template #groupInvisible>
                                                    <i
                                                        (click)="$event.stopPropagation?.(); changeGroupVisibility(hiddenAssets, true, filteredGroup);"
                                                        class="fa fa-fw fa-eye-slash fleet-overview-eye-style"
                                                        style="opacity: 0.4"
                                                    ></i>
                                                </ng-template>
                                            </div>
                                        </a>
                                        <ul
                                            *ngIf="(assetGroup.value.filterClose === false && filter.length > 0) || assetGroup.value.open === true"
                                            class="nav nav-second-level"
                                        >
                                            @for (device of filteredGroup | orderBy:['_order']:false |
                                            slice:0:maxListItems; track $any(device).id;) {
                                            <li
                                                [ngClass]="{ 'active': $any(device).name === selectedDevice }"
                                                class="fleet-overview-item fontColor base-color {{calculatedDeviceState.get($any(device).id)?.color}}"
                                                (click)="selectAsset($any(device))"
                                            >
                                                <div class="fa_checkbox">
                                                    <input
                                                        [id]="'deviceSelection_' + $any(device).id"
                                                        type="checkbox"
                                                        [name]="'deviceSelection_' + $any(device).id"
                                                        [ngModel]="deviceSelection.get($any(device).id)?.checked"
                                                        (change)="shouldToggleGroup($any($event.target).checked, assetGroups, $any(device).id, assetGroupSelection, deviceSelection); checkForUpdates();"
                                                        (ngModelChange)="triggerCheckbox($event, device);"
                                                    />
                                                    <label [for]="'deviceSelection_' + $any(device).id">
                                                        <span
                                                            [title]="$any(device).name?.length > 0 ? $any(device).name : $any(device).id"
                                                            class="fleet-overview-subtitle"
                                                        >
                                                            {{ $any(device).name?.length > 0 ? $any(device).name :
                                                            $any(device).id }}

                                                            <ng-container
                                                                *ngIf="lastStateUpdated.get($any(device).id) as momentTime;"
                                                            >
                                                                <ng-container>
                                                                    <span [title]="$any(device).displayLabel | stripHtml" [innerHTML]="$any(device).displayLabel | sanitizeHtml"
                                                                        ></span
                                                                    >
                                                                </ng-container>
                                                            </ng-container>
                                                        </span>
                                                        <i
                                                            (click)="$event.preventDefault?.(); changeVisibility(hiddenAssets, $any(device).id);"
                                                            [ngClass]="hiddenAssets.get($any(device).id) === true ? 'fa-eye-slash' : 'fa-eye'"
                                                            class="fa fa-fw eye-active fleet-overview-eye {{calculatedDeviceState.get($any(device).id)?.color}}"
                                                        ></i>
                                                    </label>
                                                </div>
                                            </li>
                                            }
                                            <li
                                                *ngIf="filteredGroup.length > maxListItems"
                                                class="fontColor"
                                                style="
                                                    display: flex;
                                                    align-items: center;
                                                    padding: 15px 2px 15px 9px;
                                                    background: white;
                                                "
                                            >
                                                <span style="display: flex"
                                                    >We do only show {{maxListItems}} items per group. If you want to
                                                    see more please refine search.</span
                                                >
                                            </li>
                                        </ul>
                                    </li>
                                </ng-container>
                            </ng-container>
                            }

                            <li
                                *ngIf="filter.length === 0 && assetGroups.size > maxGroups"
                                class="fleet-overview-header"
                                style="position: relative"
                            >
                                <a style="pointer-events: none">
                                    <span>
                                        <i style="margin-right: 8.5px">&ndash;</i>
                                        Showing {{maxGroups}} groups only
                                    </span>
                                </a>
                            </li>
                        </div>

                        <!-- Trips -->
                        <ng-container *ngIf="mode === 'History'">
                            <li *ngFor="let asset of history | keyvalue; index as i;" class="fleet-overview-header">
                                <a (click)="activateHistoryToggle(asset.key, asset.value.id);">
                                    <span class="fleet-overview-trip-indicator">{{i + 1}}</span>
                                    <span
                                        style="
                                            display: flex !important;
                                            flex: auto;
                                            overflow: hidden;
                                            text-overflow: ellipsis;
                                            white-space: nowrap;
                                        "
                                        >{{asset.value.name}}</span
                                    >
                                    <span
                                        style="margin-right: 7.5px"
                                        class="fa arrow"
                                        [ngClass]="selectedHistoryDevice === asset.key ? 'fa-rotate-270' : 'fa-flip-horizontal'"
                                    >
                                    </span>
                                </a>

                                <fh-overview-trip-history
                                    *ngIf="selectedHistoryDevice === asset.key"
                                    [asset]="asset"
                                    [openTrip]="openTrip.bind(this)"
                                    [openStop]="openStop.bind(this)"
                                    [changeTripVisibility]="changeTripVisibility.bind(this)"
                                    [displayLocation]="displayLocation.bind(this)"
                                    [playTrip]="playTrip.bind(this)"
                                    [flipLocations]="flipLocations.bind(this)"
                                    [driversMap]="driversMap"
                                >
                                </fh-overview-trip-history>
                            </li>
                        </ng-container>

                        <ng-container *ngIf="mode === 'Live'">
                            <ng-container *ngFor="let liveGroup of live | keyvalue: valueAscOrder | slice:0:maxGroups">
                                <ng-container
                                    *ngIf="(liveGroup.value.items | stringFilter: filter | hiddenFilter) as filteredLiveGroup"
                                >
                                    <li
                                        [style.display]="!(filteredLiveGroup.length === 0) ? 'block' : 'none'"
                                        class="fleet-overview-header"
                                        style="position: relative"
                                    >
                                        <a
                                            [title]="liveGroup.value.name"
                                            (click)="openLiveTab(liveGroup)"
                                            style="display: flex; align-items: center"
                                        >
                                            <span
                                                style="
                                                    display: inline-block !important;
                                                    flex: auto;
                                                    overflow: hidden;
                                                    text-overflow: ellipsis;
                                                    white-space: nowrap;
                                                    padding-left: 8.5px;
                                                "
                                                >{{liveGroup.value.name}}</span
                                            >
                                            <span style="padding: 0 8.5px">({{filteredLiveGroup.length}})</span>

                                            <span
                                                class="fa arrow"
                                                [ngClass]="(liveGroup.value.filterClose === false && filter.length > 0) || selectedLiveGroup === liveGroup.key ? 'fa-rotate-270' : 'fa-flip-horizontal'"
                                            >
                                            </span>

                                            <ng-container
                                                *ngIf="watchGroupVisibility(liveHidden, liveGroup.value.items); else groupInvisible;"
                                            >
                                                <i
                                                    (click)="$event.stopPropagation?.(); changeGroupVisibility(liveHidden, false, filteredLiveGroup);"
                                                    class="fa fa-fw fa-eye fleet-overview-eye-style"
                                                ></i>
                                            </ng-container>

                                            <ng-template #groupInvisible>
                                                <i
                                                    (click)="$event.stopPropagation?.(); changeGroupVisibility(liveHidden, true, filteredLiveGroup);"
                                                    class="fa fa-fw fa-eye-slash fleet-overview-eye-style"
                                                    style="opacity: 0.4"
                                                ></i>
                                            </ng-template>
                                        </a>

                                        <ul
                                            *ngIf="(liveGroup.value.filterClose === false && filter.length > 0) || selectedLiveGroup === liveGroup.key"
                                            class="nav nav-second-level"
                                        >
                                            <li
                                                [ngClass]="{ 'active': device.name === selectedDevice }"
                                                class="fleet-overview-item fontColor base-color {{calculatedDeviceState.get(device.id)?.color}}"
                                                (click)="selectAsset(device)"
                                                *ngFor="let device of filteredLiveGroup | slice:0:maxListItems | orderBy:['time', 'status']:true"
                                            >
                                                <span class="fleet-overview-subtitle">
                                                    {{device.name?.length > 0 ? device.name : device.id}}
                                                    <ng-container
                                                        *ngIf="lastCommunication.get(device.id) as momentTime;"
                                                    >
                                                        <span
                                                            title="{{ momentTime | amTimeAgo }} {{ device.driverName ? '| ' + device.driverName.name : '' }}"
                                                        >
                                                            {{ momentTime | amTimeAgo }} {{ device.driverName ? '| ' +
                                                            device.driverName.name : '' }}
                                                        </span>
                                                    </ng-container>
                                                </span>
                                                <i
                                                    (click)="$event.stopPropagation?.(); changeVisibility(liveHidden, device.id);"
                                                    [ngClass]="liveHidden.get(device.id) === true ? 'fa-eye-slash' : 'fa-eye'"
                                                    class="fa fa-fw eye-active fleet-overview-eye {{calculatedDeviceState.get(device.id)?.color}}"
                                                >
                                                </i>
                                            </li>
                                            <li
                                                *ngIf="filteredLiveGroup.length > maxListItems"
                                                class="fontColor"
                                                style="
                                                    display: flex;
                                                    align-items: center;
                                                    padding: 15px 2px 15px 9px;
                                                    background: white;
                                                "
                                            >
                                                <span
                                                    >We do only show {{maxListItems}} items per group. If you want to
                                                    see more please refine search</span
                                                >
                                            </li>
                                        </ul>
                                    </li>
                                </ng-container>
                            </ng-container>

                            <li *ngIf="live.size > maxGroups" class="fleet-overview-header" style="position: relative">
                                <a style="display: flex; align-items: center; pointer-events: none">
                                    <span>
                                        <i style="margin-right: 8.5px">&ndash;</i>
                                        Showing {{maxGroups}} groups only
                                    </span>
                                </a>
                            </li>
                        </ng-container>
                    </div>

                    <!-- Drivers -->
                    <div [style.display]="state === 'Loaded' && tab === 'Drivers' ? 'block' : 'none'">
                        <div [style.display]="mode === 'Overview' ? 'block' : 'none'">
                            <ng-container *ngFor="let driverGroup of driverGroups | keyvalue: valueAscOrder">
                                <ng-container *ngIf="(driverGroup.value.items | stringFilter: filter) as filteredGroup">
                                    <li
                                        [style.display]="!(filteredGroup.length === 0) ? 'block' : 'none'"
                                        class="fleet-overview-header"
                                        style="position: relative"
                                    >
                                        <div
                                            style="position: absolute; top: 14px; left: 5px"
                                            class="fa_checkbox"
                                            [class.intermediate]="!watchGroupSelected(driverSelection, filteredGroup)"
                                        >
                                            <input
                                                [id]="'driverGroupSelection_' + driverGroup.key"
                                                type="checkbox"
                                                [name]="'driverGroupSelection_' + driverGroup.key"
                                                [ngModel]="driverGroupSelection.get(driverGroup.key)"
                                                (ngModelChange)="$event.stopPropagation?.(); selectDriverGroup(driverGroup, filteredGroup, $event)"
                                            />
                                            <label [for]="'driverGroupSelection_' + driverGroup.key"></label>
                                        </div>

                                        <a
                                            style="display: flex; align-items: center; justify-content: space-between"
                                            (click)="openGroup(driverGroup)"
                                        >
                                            <div class="fleet-overview-header-label">{{driverGroup.value.name}}</div>

                                            <div style="flex: 0 0 auto; padding: 4px 0px">
                                                <span style="padding: 0 5px">({{filteredGroup.length}})</span>

                                                <span
                                                    style="margin-left: 2.5px; margin-right: 5px"
                                                    class="fa arrow"
                                                    [ngClass]="(driverGroup.value.filterClose === false && filter.length > 0) || driverGroup.value.open === true ? 'fa-rotate-270' : 'fa-flip-horizontal'"
                                                ></span>
                                            </div>
                                        </a>
                                        <ul
                                            *ngIf="(driverGroup.value.filterClose === false && filter.length > 0) || driverGroup.value.open === true"
                                            class="nav nav-second-level"
                                        >
                                            <li
                                                *ngFor="let driver of filteredGroup | slice:0:maxListItems"
                                                class="fleet-overview-item fontColor align-border"
                                            >
                                                <div style="flex: 1" class="fa_checkbox">
                                                    <input
                                                        [id]="'driverSelection_' + driver.id"
                                                        type="checkbox"
                                                        [name]="'driverSelection_' + driver.id"
                                                        [ngModel]="driverSelection.get(driver.id)?.checked"
                                                        (change)="shouldToggleGroup($any($event.target).checked, driverGroups, driver.id, driverGroupSelection, driverSelection); checkForUpdates();"
                                                        (ngModelChange)="$event.stopPropagation?.(); updateCheckbox($event, driverSelection, driver); checkForUpdates();"
                                                    />
                                                    <label [for]="'driverSelection_' + driver.id">
                                                        <span class="fleet-overview-subtitle">
                                                            {{driver.name}}
                                                            <span title="{{ driver.id }}">
                                                                {{ driver.assetName }}
                                                            </span>
                                                        </span>
                                                    </label>
                                                </div>
                                            </li>
                                            <li
                                                *ngIf="filteredGroup.length > maxListItems"
                                                class="fontColor"
                                                style="
                                                    display: flex;
                                                    align-items: center;
                                                    padding: 15px 2px 15px 9px;
                                                    background: white;
                                                "
                                            >
                                                <span style="display: inline-block"
                                                    >We do only show {{maxListItems}} items per group. If you want to
                                                    see more please refine search</span
                                                >
                                            </li>
                                        </ul>
                                    </li>
                                </ng-container>
                            </ng-container>
                        </div>

                        <ng-container *ngIf="mode === 'History'">
                            <li *ngFor="let driver of history | keyvalue; index as i;" class="fleet-overview-header">
                                <a
                                    (click)="activateHistoryToggle(driver.key, driver.value.id);"
                                    style="
                                        display: flex;
                                        align-items: center;
                                        padding: 7.5px 9px 7.5px 9px;
                                        min-height: 45px;
                                    "
                                >
                                    <span class="fleet-overview-trip-indicator">{{i + 1}}</span>
                                    <span
                                        style="
                                            display: flex !important;
                                            flex: auto;
                                            overflow: hidden;
                                            text-overflow: ellipsis;
                                            white-space: nowrap;
                                        "
                                        >{{driver.value.name}}</span
                                    >
                                    <span
                                        class="fa arrow"
                                        [ngClass]="selectedHistoryDevice === driver.key ? 'fa-rotate-270' : 'fa-flip-horizontal'"
                                    ></span>
                                </a>

                                <fh-overview-trip-history
                                    *ngIf="selectedHistoryDevice === driver.key"
                                    [asset]="driver"
                                    [openTrip]="openTrip.bind(this)"
                                    [openStop]="openStop.bind(this)"
                                    [changeTripVisibility]="changeTripVisibility.bind(this)"
                                    [displayLocation]="displayLocation.bind(this)"
                                    [playTrip]="playTrip.bind(this)"
                                    [flipLocations]="flipLocations.bind(this)"
                                    [driversMap]="driversMap"
                                >
                                </fh-overview-trip-history>
                            </li>
                        </ng-container>

                        <ng-container *ngIf="mode === 'Live'">
                            <ng-container *ngFor="let liveGroup of live | keyvalue: valueAscOrder | slice:0:maxGroups">
                                <ng-container
                                    *ngIf="(liveGroup.value.items | stringFilter: filter | hiddenFilter) as filteredLiveGroup"
                                >
                                    <li
                                        [style.display]="!(filteredLiveGroup.length === 0) ? 'block' : 'none'"
                                        class="fleet-overview-header"
                                        style="position: relative"
                                    >
                                        <a
                                            style="display: flex; align-items: center; justify-content: space-between"
                                            (click)="openLiveTab(liveGroup)"
                                        >
                                            <div class="fleet-overview-header-label" style="padding-left: 8.5px">
                                                {{liveGroup.value.name}}
                                            </div>
                                            <div style="flex: 0 0 auto">
                                                <span style="padding: 0 5px">({{filteredLiveGroup.length}})</span>
                                                <span
                                                    style="float: unset"
                                                    class="fa arrow"
                                                    [ngClass]="(liveGroup.value.filterClose === false && filter.length > 0) || selectedLiveGroup === liveGroup.key ? 'fa-rotate-270' : 'fa-flip-horizontal'"
                                                ></span>
                                                <ng-container
                                                    *ngIf="watchGroupVisibility(liveHidden, liveGroup.value.items); else groupInvisible;"
                                                >
                                                    <i
                                                        (click)="$event.stopPropagation?.(); changeGroupVisibility(liveHidden, false, filteredLiveGroup);"
                                                        class="fa fa-fw fa-eye fleet-overview-eye-style"
                                                    ></i>
                                                </ng-container>
                                                <ng-template #groupInvisible>
                                                    <i
                                                        (click)="$event.stopPropagation?.(); changeGroupVisibility(liveHidden, true, filteredLiveGroup);"
                                                        class="fa fa-fw fa-eye-slash fleet-overview-eye-style"
                                                        style="opacity: 0.4"
                                                    ></i>
                                                </ng-template>
                                            </div>
                                        </a>
                                        <ul
                                            *ngIf="(liveGroup.value.filterClose === false && filter.length > 0) || selectedLiveGroup === liveGroup.key"
                                            class="nav nav-second-level"
                                        >
                                            <li
                                                [ngClass]="{ 'active': device.name === selectedDevice }"
                                                class="fleet-overview-item fontColor base-color {{calculatedDeviceState.get(device.id)?.color}}"
                                                (click)="selectAsset(device)"
                                                *ngFor="let device of filteredLiveGroup | slice:0:maxListItems | orderBy:['time', 'status']:true"
                                            >
                                                <span class="fleet-overview-subtitle">
                                                    {{device.name?.length > 0 ? device.name : device.id}}
                                                    <ng-container
                                                        *ngIf="lastCommunication.get(device.id) as momentTime;"
                                                    >
                                                        <span
                                                            title="{{ momentTime | amTimeAgo }} | {{ device.driverName }}"
                                                        >
                                                            {{ momentTime | amTimeAgo }} {{ device.driverName ? '| ' +
                                                            device.driverName.name : '' }}
                                                        </span>
                                                    </ng-container>
                                                </span>
                                                <i
                                                    (click)="$event.stopPropagation?.(); changeVisibility(liveHidden, device.id);"
                                                    [ngClass]="liveHidden.get(device.id) === true ? 'fa-eye-slash' : 'fa-eye'"
                                                    class="fa fa-fw eye-active fleet-overview-eye-style {{calculatedDeviceState.get(device.id)?.color}}"
                                                    style="margin-right: 11px"
                                                ></i>
                                            </li>
                                            <li
                                                *ngIf="filteredLiveGroup.length > maxListItems"
                                                class="fontColor"
                                                style="
                                                    display: flex;
                                                    align-items: center;
                                                    padding: 15px 2px 15px 9px;
                                                    background: white;
                                                "
                                            >
                                                <span
                                                    >We do only show {{maxListItems}} items per group. If you want to
                                                    see more please refine search</span
                                                >
                                            </li>
                                        </ul>
                                    </li>
                                </ng-container>
                            </ng-container>
                            <li *ngIf="live.size > maxGroups" class="fleet-overview-header" style="position: relative">
                                <a style="display: flex; align-items: center; pointer-events: none">
                                    <span>
                                        <i style="margin-right: 8.5px">&ndash;</i>
                                        Showing {{maxGroups}} groups only
                                    </span>
                                </a>
                            </li>
                        </ng-container>
                    </div>

                    <!-- Geofences -->
                    <div [style.display]="state === 'Loaded' && tab === 'Geofences' ? 'block' : 'none'">
                        <div [style.display]="mode === 'Overview' ? 'block' : 'none'">
                            <ng-container *ngFor="let geofenceGroup of geofenceGroups | keyvalue: valueAscOrder">
                                <ng-container
                                    *ngIf="(geofenceGroup.value.items | stringFilter: filter) as filteredGroup"
                                >
                                    <li
                                        [style.display]="!(filter.length > 0 && filteredGroup.length === 0) ? 'block' : 'none'"
                                        class="fleet-overview-header"
                                        style="position: relative"
                                    >
                                        <div
                                            style="position: absolute; top: 14px; left: 5px"
                                            class="fa_checkbox"
                                            [class.intermediate]="!watchGroupSelected(geofenceSelection, filteredGroup)"
                                        >
                                            <input
                                                [id]="'geofenceGroupSelection_' + geofenceGroup.key"
                                                type="checkbox"
                                                [name]="'geofenceGroupSelection_' + geofenceGroup.key"
                                                [ngModel]="geofenceGroupSelection.get(geofenceGroup.key)"
                                                (ngModelChange)="$event.stopPropagation?.(); selectGeofenceGroup(geofenceGroup, filteredGroup, $event)"
                                            />
                                            <label [for]="'geofenceGroupSelection_' + geofenceGroup.key"></label>
                                        </div>

                                        <a
                                            style="display: flex; align-items: center; justify-content: space-between"
                                            (click)="openGroup(geofenceGroup)"
                                        >
                                            <div [title]="geofenceGroup.value.name" class="fleet-overview-header-label">
                                                {{geofenceGroup.value.name}}
                                            </div>

                                            <div style="flex: 0 0 auto">
                                                <span style="padding: 0 2.5px">({{filteredGroup.length}})</span>

                                                <span
                                                    style="float: unset; margin-left: 5px"
                                                    class="fa arrow"
                                                    [ngClass]="(geofenceGroup.value.filterClose === false && filter.length > 0) || geofenceGroup.value.open === true ? 'fa-rotate-270' : 'fa-flip-horizontal'"
                                                >
                                                </span>

                                                <ng-container
                                                    *ngIf="watchGroupVisibility(hiddenGeofences, geofenceGroup.value.items); else groupInvisible;"
                                                >
                                                    <i
                                                        (click)="$event.stopPropagation?.(); changeGeofenceGroupVisibility(hiddenGeofences, false, filteredGroup);"
                                                        class="fa fa-fw fa-eye fleet-overview-eye-style"
                                                    ></i>
                                                </ng-container>

                                                <ng-template #groupInvisible>
                                                    <i
                                                        (click)="$event.stopPropagation?.(); changeGeofenceGroupVisibility(hiddenGeofences, true, filteredGroup);"
                                                        class="fa fa-fw fa-eye-slash fleet-overview-eye-style"
                                                        style="opacity: 0.4"
                                                    ></i>
                                                </ng-template>
                                            </div>
                                        </a>
                                        <ul
                                            *ngIf="(geofenceGroup.value.filterClose === false && filter.length > 0) || geofenceGroup.value.open === true"
                                            class="nav nav-second-level"
                                        >
                                            <li
                                                class="fleet-overview-item fontColor align-border"
                                                *ngFor="let geofence of filteredGroup | slice:0:maxListItems"
                                            >
                                                <div style="flex: 1" class="fa_checkbox">
                                                    <input
                                                        [id]="'geofenceSelection_' + geofence.id"
                                                        type="checkbox"
                                                        [name]="'geofenceSelection_' + geofence.id"
                                                        [ngModel]="geofenceSelection.get(geofence.id)?.checked"
                                                        (change)="shouldToggleGroup($any($event.target).checked, geofenceGroups, geofence.id, geofenceGroupSelection, geofenceSelection); checkForUpdates();"
                                                        (ngModelChange)="$event.stopPropagation?.(); updateCheckbox($event, geofenceSelection, geofence); selectGeofence($event, geofence.id);"
                                                    />
                                                    <label [for]="'geofenceSelection_' + geofence.id">
                                                        <span style="display: inline-block; margin-left: 4px"
                                                            >{{geofence.name}}
                                                            ({{insideGeofences.get(geofence.id.toString())?.length ??
                                                            0}})</span
                                                        >
                                                        <i
                                                            (click)="$event.preventDefault?.(); changeGeofenceVisibility(hiddenGeofences, $any(geofence).id);"
                                                            [ngClass]="hiddenGeofences.get($any(geofence).id) === true ? 'fa-eye-slash' : 'fa-eye'"
                                                            style="margin: 0 5px 0 auto"
                                                            class="fa fa-fw eye-active fleet-overview-eye"
                                                        ></i>
                                                    </label>
                                                </div>
                                            </li>
                                            <li
                                                *ngIf="filteredGroup.length > maxListItems"
                                                class="fontColor"
                                                style="
                                                    display: flex;
                                                    align-items: center;
                                                    padding: 15px 2px 15px 9px;
                                                    background: white;
                                                "
                                            >
                                                <span style="display: inline-block"
                                                    >We do only show {{maxListItems}} items per group. If you want to
                                                    see more please refine search</span
                                                >
                                            </li>
                                        </ul>
                                    </li>
                                </ng-container>
                            </ng-container>
                        </div>

                        <ng-container *ngIf="mode === 'History'">
                            <li *ngFor="let geofence of history | keyvalue; index as i;" class="fleet-overview-header">
                                <a
                                    (click)="activateHistoryToggle(geofence.key, geofence.value.id);"
                                    style="
                                        display: flex;
                                        align-items: center;
                                        padding: 7.5px 9px 7.5px 9px;
                                        min-height: 45px;
                                    "
                                >
                                    <span class="fleet-overview-trip-indicator">{{i + 1}}</span>
                                    <span
                                        style="
                                            display: flex !important;
                                            flex: auto;
                                            overflow: hidden;
                                            text-overflow: ellipsis;
                                            white-space: nowrap;
                                        "
                                        >{{geofence.value.name}}</span
                                    >
                                    <span
                                        class="fa arrow"
                                        [ngClass]="selectedHistoryDevice === geofence.key ? 'fa-rotate-270' : 'fa-flip-horizontal'"
                                    ></span>
                                </a>

                                <fh-overview-geofence-history
                                    *ngIf="selectedHistoryDevice === geofence.key"
                                    [geofence]="geofence"
                                >
                                </fh-overview-geofence-history>
                            </li>
                        </ng-container>

                        <ng-container *ngIf="mode === 'Live'">
                            <ng-container *ngFor="let liveGroup of live | keyvalue: valueAscOrder | slice:0:maxGroups">
                                <ng-container
                                    *ngIf="(liveGroup.value.items | stringFilter: filter | hiddenFilter) as filteredLiveGroup"
                                >
                                    <li
                                        [style.display]="!(filteredLiveGroup.length === 0) ? 'block' : 'none'"
                                        class="fleet-overview-header"
                                        style="position: relative"
                                    >
                                        <a
                                            style="display: flex; align-items: center; justify-content: space-between"
                                            (click)="openLiveTab(liveGroup)"
                                        >
                                            <div class="fleet-overview-header-label" style="padding-left: 8.5px">
                                                {{liveGroup.value.name}}
                                            </div>
                                            <div style="flex: 0 0 auto">
                                                <span style="padding: 0 5px">({{filteredLiveGroup.length}})</span>
                                                <span
                                                    style="float: unset"
                                                    class="fa arrow"
                                                    [ngClass]="(liveGroup.value.filterClose === false && filter.length > 0) || selectedLiveGroup === liveGroup.key ? 'fa-rotate-270' : 'fa-flip-horizontal'"
                                                ></span>
                                                <ng-container
                                                    *ngIf="watchGroupVisibility(liveHidden, liveGroup.value.items); else groupInvisible;"
                                                >
                                                    <i
                                                        (click)="$event.stopPropagation?.(); changeGroupVisibility(liveHidden, false, filteredLiveGroup);"
                                                        class="fa fa-fw fa-eye fleet-overview-eye-style"
                                                    ></i>
                                                </ng-container>
                                                <ng-template #groupInvisible>
                                                    <i
                                                        (click)="$event.stopPropagation?.(); changeGroupVisibility(liveHidden, true, filteredLiveGroup);"
                                                        class="fa fa-fw fa-eye-slash fleet-overview-eye-style"
                                                        style="opacity: 0.4"
                                                    ></i>
                                                </ng-template>
                                            </div>
                                        </a>
                                        <ul
                                            *ngIf="(liveGroup.value.filterClose === false && filter.length > 0) || selectedLiveGroup === liveGroup.key"
                                            class="nav nav-second-level"
                                        >
                                            <li
                                                [ngClass]="{ 'active': device.name === selectedDevice }"
                                                class="fleet-overview-item fontColor base-color {{calculatedDeviceState.get(device.id)?.color}}"
                                                (click)="selectAsset(device)"
                                                *ngFor="let device of filteredLiveGroup | slice:0:maxListItems | orderBy:['time', 'status']:true"
                                            >
                                                <span class="fleet-overview-subtitle">
                                                    {{device.name?.length > 0 ? device.name : device.id}}
                                                    <ng-container
                                                        *ngIf="lastCommunication.get(device.id) as momentTime;"
                                                    >
                                                        <span
                                                            title="{{ momentTime | amTimeAgo }} | {{ device.driverName }}"
                                                        >
                                                            {{ momentTime | amTimeAgo }} {{ device.driverName ? '| ' +
                                                            device.driverName : '' }}
                                                        </span>
                                                    </ng-container>
                                                </span>
                                                <i
                                                    (click)="$event.stopPropagation?.(); changeVisibility(liveHidden, device.id);"
                                                    [ngClass]="liveHidden.get(device.id) === true ? 'fa-eye-slash' : 'fa-eye'"
                                                    class="fa fa-fw eye-active fleet-overview-eye-style {{calculatedDeviceState.get(device.id)?.color}}"
                                                    style="margin-right: 2px"
                                                ></i>
                                            </li>
                                            <li
                                                *ngIf="filteredLiveGroup.length > maxListItems"
                                                class="fontColor"
                                                style="
                                                    display: flex;
                                                    align-items: center;
                                                    padding: 15px 2px 15px 9px;
                                                    background: white;
                                                "
                                            >
                                                <span
                                                    >We do only show {{maxListItems}} items per group. If you want to
                                                    see more please refine search</span
                                                >
                                            </li>
                                        </ul>
                                    </li>
                                </ng-container>
                            </ng-container>
                            <li *ngIf="live.size > maxGroups" class="fleet-overview-header" style="position: relative">
                                <a style="display: flex; align-items: center; pointer-events: none">
                                    <span>
                                        <i style="margin-right: 8.5px">&ndash;</i>
                                        Showing {{maxGroups}} groups only
                                    </span>
                                </a>
                            </li>
                        </ng-container>
                    </div>
                </div>
            </div>
        </ul>
    </div>

    <!-- Navigation -->
    <div style="position: relative; padding: 0px 0px 0px 0px" class="buttonContent fo-sidebar-content">
        <div class="fo-sidebar-buttons col-12 pl-0 pr-0">
            <button
                *ngIf="mode !== 'Overview'"
                (click)="changeMode('Overview')"
                class="btn app-btn-main"
                style="width: 50%"
                type="button"
            >
                <i class="fa fa-history"></i> {{'general.overview' | translate}}
            </button>
            <button
                *ngIf="mode !== 'History'"
                [disabled]="!(tab === 'Assets' && hasAnySelected([deviceSelection, assetGroupSelection])) && !(tab === 'Drivers' && hasAnySelected([driverSelection])) && !(tab === 'Geofences' && hasAnySelected([geofenceSelection, geofenceGroupSelection]))"
                (click)="changeMode('History')"
                class="btn app-btn"
                type="button"
            >
                <i class="fa fa-history"></i> {{'general.history' | translate}}
            </button>
            <button
                *ngIf="mode !== 'Live'"
                [disabled]="!(tab === 'Assets' && hasAnySelected([deviceSelection, assetGroupSelection])) && !(tab === 'Drivers' && hasAnySelected([driverSelection])) && !(tab === 'Geofences' && hasAnySelected([geofenceSelection, geofenceGroupSelection]))"
                (click)="changeMode('Live')"
                class="btn app-btn"
                type="button"
            >
                <i class="fa fa-heartbeat"></i> {{'general.live' | translate}}
            </button>

            <button
                type="button"
                class="app-btn fo-toggle-button"
                [class.fo-toggle-button__popout]="sidebarHidden === true"
                (click)="toggleSidebar()"
            >
                <i class="fa fa-window-maximize"></i>
            </button>
        </div>
    </div>
</nav>
