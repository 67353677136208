import { Component, OnInit } from "@angular/core";
import { AuthenticationService } from "../../services/authentication/authentication.service";
import { Router } from "@angular/router";
import { appVersion } from "app/app.version";

@Component({
  selector: "fh-app-sso-signin",
  templateUrl: "./sso-signin.component.html",
  styleUrls: ["./login.template.css"],
})
export class SsoSignInComponent implements OnInit {
  appVersion: string;
  error = false;
  errorMessage: string;
  loading = false;

  constructor(private authService: AuthenticationService, private router: Router) {
    this.appVersion = appVersion;
  }

  ngOnInit() {
    this.authService.SSOLogin().catch((err) => {
      console.error(err);
      this.error = true;
    });
  }

  failed(): boolean {
    return this.error;
  }

  redirectToLogin() {
    setTimeout((timeout) => {
      this.router.navigate(["/Login"]);
    }, 3000);
  }
}
