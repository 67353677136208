<div dropdown [isDisabled]="!entity || batchActions.length == 0" style="position: relative">
    <button
        id="button-basic"
        dropdownToggle
        type="button"
        class="btn btn-info dropdown-toggle"
        aria-controls="dropdown-basic"
        style="width: 100%; margin: 0px"
    >
        <i class="fa fa-fw fa-bolt"></i>
    </button>
    <ul
        id="dropdown-basic"
        *dropdownMenu
        class="dropdown-menu dropdown-menu-fix dropdown-menu-right"
        role="menu"
        aria-labelledby="button-basic"
    >
        <li
            role="menuitem"
            *ngFor="let action of batchActions | orderBy : 'name':false"
            [ngClass]="action.disabled ? 'disabled' : ''"
        >
            <a
                [ngClass]="action.disabled ? 'disabled' : ''"
                class="dropdown-item"
                href="javascript:void(0)"
                (click)="!action.disabled ? showModal(action, actionModal) : null"
            >
                <i
                    class="fas fa-fw"
                    [ngClass]="action.icon ? action.icon : 'fa-angle-right'"
                    style="padding-right: 5px"
                ></i>
                {{("enums.batchAction." + action.batchActionType) | translate}}</a
            >
        </li>
    </ul>
</div>

<ng-template #actionModal>
    <div class="modal-header">
        <h4 header class="modal-title pull-left">
            <i class="fa fa-fw fa-bolt"></i> {{'general.action' | translate }}: {{('enums.batchAction.' +
            selectedAction?.batchActionType) | translate }}
        </h4>
        <button type="button" class="btn-close close float-right" aria-label="Close" (click)="modalRef?.hide()">
            <span aria-hidden="true" class="visually-hidden">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form body #groupForm="ngForm" style="width: 100%; border-top: 0px">
            <fh-handle-tasks
                #handleAction
                [selectedAction]="selectedAction"
                [batchProcessing]="false"
                [entityTypes]="entityTypes"
                [gridSelection]="[entity.id]"
                [entity]="entity"
                (onFinish)="process($event)"
            ></fh-handle-tasks>
        </form>
    </div>
    <div *ngIf="(selectedAction?.batchActionType == 27)" class="modal-footer">
        <button
            type="button"
            class="btn btn-primary"
            (click)="finish()"
            style="min-width: 120px"
            [disabled]="!groupForm.valid"
        >
            <i class="fa fa-save"></i> {{ 'general.yes' | translate }}
        </button>
    </div>
</ng-template>
