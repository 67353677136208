import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { getDefaultDpConfig } from "app/common/gridhelper";
import { AuthenticationService } from "app/services/authentication/authentication.service";
import { BsDaterangepickerConfig } from "ngx-bootstrap/datepicker";

// Moment timezone
import Moment from "moment-timezone";
import { roundAsNumber, roundMinutes, roundSeconds } from "app/common/globals";
import { AccountService } from "app/services/account/account.service";
import { FhChartService } from "app/services/charts/charts.service";
import { DurationGroupingType } from "app/common/enums";

window["moment"] = Moment;

import * as Highcharts from "highcharts";

@Component({
  providers: [FhChartService],
  selector: "fh-account-trends",
  templateUrl: "trends.template.html",
})
export class AccountTrendsViewComponent implements OnInit {
  Highcharts: typeof Highcharts = Highcharts;

  loading: boolean;
  asset: any;
  sub: any;
  messages: any;

  theMarker;

  // Datepicker
  public dpConfig: Partial<BsDaterangepickerConfig> = new BsDaterangepickerConfig();
  to: any;
  from: any;
  daterangepickerModel: any[];
  permissions: {};
  languageLoaded: boolean;

  maxDate = new Date();
  sensors = [];
  constructorName = "AccountTrendsViewComponent";

  filterZeroValues = true;
  splitVehicleTypes = false;
  showFilter = true;

  timezoneIana: string;

  error: any;
  success: any;
  warning: any;

  limit = 1000;

  loadingLocations = false;
  previousLookupTimestamp;
  locationSubscription: any;
  loadingLocation: boolean;

  groupBy = DurationGroupingType.Week;

  updatesActive = true;
  isLoaded = false;
  loadingCount = false;
  randomKey: number;
  distanceChartData = [];
  devices = [];
  chartUtilization;
  chartIdling;
  chartAssets;
  kpis;
  trends = [];
  chartWorkingHours;
  chartPureDriving;
  chartDistance;

  chartThresholdIdling;
  chartThresholdUtilization;
  chartThresholdDistance;

  targetIdling = 10;
  targetUtilization = 25;
  targetDistance = 8000;

  selectedResellerId;
  selectedAccountId;
  mySubscription: any;

  resellerChanged(resellerId) {
    this.selectedAccountId = null;

    this.selectedResellerId = resellerId;
  }

  accountChanged(accountId) {
    this.selectedAccountId = accountId;

    this.fetchTrends();
  }

  constructor(
    private accountService: AccountService,
    private chartService: FhChartService,
    private translateService: TranslateService,
    private authenticationService: AuthenticationService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.sensors = [];

    this.permissions = this.authenticationService.permissions;

    this.timezoneIana = this.authenticationService.getTimeZoneIana();

    this.daterangepickerModel = [
      Moment().tz(this.timezoneIana).subtract(3, "months").startOf("day").toDate(),
      Moment().tz(this.timezoneIana).subtract(1, "days").startOf("day").toDate(),
    ];

    this.dpConfig = getDefaultDpConfig(authenticationService);
  }

  ngOnInit() {
    this.translateService.get("general.date").subscribe((value) => {
      this.languageLoaded = true;
    });

    this.dateChanged(true);
  }

  actualRound(value, decimals) {
    return roundAsNumber(value, decimals);
  }

  actualRoundMinutes(value) {
    return roundMinutes(value);
  }

  actualRoundSeconds(value) {
    return roundSeconds(value);
  }

  processData(data) {
    const categories = [];
    const theUtilizationData = [];
    const theIdlingData = [];
    const theAssetData = [];
    const theAvgWorkingHoursData = [];
    const theAvgPureDrivingData = [];
    const theDistanceData = [];

    const theThresholdDistanceData = [];
    const theThresholdIdlingData = [];
    const theThresholdUtilizationData = [];

    data.forEach((value, index) => {
      if (index === data.length - 1) {
        return;
      }

      // Add utilization percentage
      value.utilization = (value.workingHoursInMinutes / value.totalDurationInMinutes) * 100;
      value.idlingPercentage = (value.idlingDurationInMinutes / value.workingHoursInMinutes) * 100;

      if (!theUtilizationData[value.vehicleType]) {
        theUtilizationData[value.vehicleType] = [];
      }
      if (!theIdlingData[value.vehicleType]) {
        theIdlingData[value.vehicleType] = [];
      }
      if (!theAssetData[value.vehicleType]) {
        theAssetData[value.vehicleType] = [];
      }
      if (!theAvgWorkingHoursData[value.vehicleType]) {
        theAvgWorkingHoursData[value.vehicleType] = [];
      }
      if (!theAvgPureDrivingData[value.vehicleType]) {
        theAvgPureDrivingData[value.vehicleType] = [];
      }
      if (!theDistanceData[value.vehicleType]) {
        theDistanceData[value.vehicleType] = [];
      }

      if (!theThresholdDistanceData[value.vehicleType]) {
        theThresholdDistanceData[value.vehicleType] = [];
      }
      if (!theThresholdIdlingData[value.vehicleType]) {
        theThresholdIdlingData[value.vehicleType] = [];
      }
      if (!theThresholdUtilizationData[value.vehicleType]) {
        theThresholdUtilizationData[value.vehicleType] = [];
      }

      theUtilizationData[value.vehicleType].push({
        x: Moment.utc(value.startDate).toDate().getTime(),
        y: this.actualRound(value.utilization, 1),
        clientData: value.customerId,
      });
      theIdlingData[value.vehicleType].push({
        x: Moment.utc(value.startDate).toDate().getTime(),
        y: this.actualRound(value.idlingPercentage, 1),
        clientData: value.customerId,
      });
      theAssetData[value.vehicleType].push({
        x: Moment.utc(value.startDate).toDate().getTime(),
        y: value.assetCount,
        clientData: value.customerId,
      });
      theAvgWorkingHoursData[value.vehicleType].push({
        x: Moment.utc(value.startDate).toDate().getTime(),
        y: this.actualRound(value.avgWorkingHoursInMinutes / 60, 1),
        clientData: value.customerId,
      });
      theAvgPureDrivingData[value.vehicleType].push({
        x: Moment.utc(value.startDate).toDate().getTime(),
        y: this.actualRound(value.avgPureDrivingInMinutes / 60, 1),
        clientData: value.customerId,
      });
      theDistanceData[value.vehicleType].push({
        x: Moment.utc(value.startDate).toDate().getTime(),
        y: value.segmentsDistance,
        clientData: value.customerId,
      });

      theThresholdDistanceData[value.vehicleType].push({
        x: Moment.utc(value.startDate).toDate().getTime(),
        y: this.actualRound(value.thresholdPercentageDistance, 1),
        clientData: value.customerId,
      });
      theThresholdIdlingData[value.vehicleType].push({
        x: Moment.utc(value.startDate).toDate().getTime(),
        y: this.actualRound(value.thresholdPercentageIdling, 1),
        clientData: value.customerId,
      });
      theThresholdUtilizationData[value.vehicleType].push({
        x: Moment.utc(value.startDate).toDate().getTime(),
        y: this.actualRound(value.thresholdPercentageWorkingHours, 1),
        clientData: value.customerId,
      });
    });

    const theDataUtilization = [];
    const theDataIdling = [];
    const theDataAssets = [];
    const theDataWorkingHours = [];
    const theDataPureDriving = [];
    const theDataDistance = [];

    const theDataThresholdDistance = [];
    const theDataThresholdUtilization = [];
    const theDataThresholdIdling = [];

    theUtilizationData.forEach((item, index) => {
      theDataUtilization.push({
        data: item,
        type: "column",
        name: this.translateService.instant("enums.vehicleType." + index),
        turboThreshold: 5000,
        dashStyle: "dash",
        fillOpacity: 0.5,
        opacity: 1,
        labels: {
          format: "{value} %",
        },
        marker: {
          enabled: false,
          lineWidth: 1,
          symbol: "square",
        },
      });
    });

    theIdlingData.forEach((item, index) => {
      theDataIdling.push({
        data: item,
        type: "column",
        name: this.translateService.instant("enums.vehicleType." + index),
        turboThreshold: 5000,
        dashStyle: "dash",
        fillOpacity: 0.5,
        opacity: 1,
        labels: {
          format: "{value} %",
        },
        marker: {
          enabled: false,
          lineWidth: 1,
          symbol: "square",
        },
      });
    });

    theAssetData.forEach((item, index) => {
      theDataAssets.push({
        data: item,
        type: "area",
        name: this.translateService.instant("enums.vehicleType." + index),
        turboThreshold: 5000,
        dashStyle: "dash",
        fillOpacity: 0.5,
        opacity: 1,
        marker: {
          enabled: false,
          lineWidth: 1,
          symbol: "square",
        },
      });
    });

    theAvgWorkingHoursData.forEach((item, index) => {
      theDataWorkingHours.push({
        data: item,
        type: "column",
        name: this.translateService.instant("enums.vehicleType." + index),
        turboThreshold: 5000,
        dashStyle: "dash",
        fillOpacity: 0.5,
        opacity: 1,
        marker: {
          enabled: false,
          lineWidth: 1,
          symbol: "square",
        },
      });
    });

    theAvgPureDrivingData.forEach((item, index) => {
      theDataPureDriving.push({
        data: item,
        type: "column",
        name: this.translateService.instant("enums.vehicleType." + index),
        turboThreshold: 5000,
        dashStyle: "dash",
        fillOpacity: 0.5,
        opacity: 1,
        marker: {
          enabled: false,
          lineWidth: 1,
          symbol: "square",
        },
      });
    });

    theDistanceData.forEach((item, index) => {
      theDataDistance.push({
        data: item,
        type: "area",
        name: this.translateService.instant("enums.vehicleType." + index),
        turboThreshold: 5000,
        dashStyle: "dash",
        fillOpacity: 0.5,
        opacity: 1,
        labels: {
          format: "{value} km",
        },
        marker: {
          enabled: false,
          lineWidth: 1,
          symbol: "square",
        },
      });
    });

    theThresholdDistanceData.forEach((item, index) => {
      theDataThresholdDistance.push({
        data: item,
        type: "area",
        name: this.translateService.instant("enums.vehicleType." + index),
        turboThreshold: 5000,
        dashStyle: "dash",
        fillOpacity: 0.5,
        opacity: 1,
        labels: {
          format: "{value} km",
        },
        marker: {
          enabled: false,
          lineWidth: 1,
          symbol: "square",
        },
      });
    });

    theThresholdIdlingData.forEach((item, index) => {
      theDataThresholdIdling.push({
        data: item,
        type: "area",
        name: this.translateService.instant("enums.vehicleType." + index),
        turboThreshold: 5000,
        dashStyle: "dash",
        fillOpacity: 0.5,
        opacity: 1,
        labels: {
          format: "{value} km",
        },
        marker: {
          enabled: false,
          lineWidth: 1,
          symbol: "square",
        },
      });
    });

    theThresholdUtilizationData.forEach((item, index) => {
      theDataThresholdUtilization.push({
        data: item,
        type: "area",
        name: this.translateService.instant("enums.vehicleType." + index),
        turboThreshold: 5000,
        dashStyle: "dash",
        fillOpacity: 0.5,
        opacity: 1,
        labels: {
          format: "{value} km",
        },
        marker: {
          enabled: false,
          lineWidth: 1,
          symbol: "square",
        },
      });
    });

    this.chartIdling = this.chartService.generateColumnChartDates(
      theDataIdling,
      categories,
      null,
      null,
      null,
      null,
      " %",
      100
    );
    this.chartUtilization = this.chartService.generateColumnChartDates(
      theDataUtilization,
      categories,
      null,
      null,
      null,
      null,
      " %",
      100
    );
    this.chartAssets = this.chartService.generateColumnChartDates(theDataAssets, categories, null, null, true, null);
    this.chartWorkingHours = this.chartService.generateColumnChartDates(
      theDataWorkingHours,
      categories,
      null,
      null,
      false,
      null,
      " H"
    );
    this.chartPureDriving = this.chartService.generateColumnChartDates(
      theDataPureDriving,
      categories,
      null,
      null,
      false,
      null,
      " H"
    );
    this.chartDistance = this.chartService.generateColumnChartDates(
      theDataDistance,
      categories,
      null,
      null,
      true,
      null,
      " km"
    );

    this.chartThresholdIdling = this.chartService.generateColumnChartDates(
      theDataThresholdIdling,
      categories,
      null,
      null,
      null,
      null,
      " %",
      100
    );
    this.chartThresholdUtilization = this.chartService.generateColumnChartDates(
      theDataThresholdUtilization,
      categories,
      null,
      null,
      null,
      null,
      " %",
      100
    );
    this.chartThresholdDistance = this.chartService.generateColumnChartDates(
      theDataThresholdDistance,
      categories,
      null,
      null,
      null,
      null,
      " %",
      100
    );

    // public int? EquipmentIdlingDurationInSeconds { get; set; }

    // public int? CrossOverDurationInSeconds { get; set; }

    // public int? WorkDurationInSeconds { get; set; }
  }

  dateChanged(event) {
    console.log("Changed date");
    if (event !== null) {
      this.loadingLocations = true;

      this.fetchTrends();
    }
  }

  cancel() {
    this.mySubscription.unsubscribe();

    this.loading = false;

    this.warning = {};
    this.warning.warning = "Call was cancelled.";
    this.warning.statusText = "Warning";
  }

  fetchTrends() {
    this.loading = true;

    this.error = null;
    this.warning = null;

    this.mySubscription = this.accountService
      .getAccountTrends(
        this.selectedAccountId,
        this.groupBy,
        this.splitVehicleTypes,
        Moment.utc(this.daterangepickerModel[0]).tz(this.timezoneIana).startOf("day"),
        Moment.utc(this.daterangepickerModel[1]).tz(this.timezoneIana).endOf("day"),
        {
          targetIdling: this.targetIdling,
          targetDistance: this.targetDistance,
          targetUtilization: this.targetUtilization,
        },
        null,
        null,
        null,
        null,
        null,
        true
      )
      .subscribe({
        next: (resp) => {
          this.trends = resp;

          if (resp.length === 0) {
            this.warning = {};
            this.warning.warning = "No data found for selection.";
            this.warning.statusText = "Warning";
          }

          this.processData(resp);
          this.loading = false;
        },
        error: (error) => {
          this.loading = false;
          this.error = error;
        },
      });
  }
}
