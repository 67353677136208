import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { Observable, catchError, map } from "rxjs";
import { AuthenticationService } from "../authentication/authentication.service";
import { LoggingService } from "../logging/logging.service";
import { Geofence } from "app/models/geofence.model";

declare var L;

import { Form } from "app/common/enums";
import { throwError } from "rxjs/internal/observable/throwError";

// Moment timezone
import Moment from "moment-timezone";
window["moment"] = Moment;

@Injectable()
export class GeofenceService {
  url = "";
  base_url = "";
  inventoryUrl = "";
  Geofences: Geofence[] = [];
  timezoneIana: string;

  constructor(
    private http: HttpClient,
    private loggingService: LoggingService,
    private authenticationService: AuthenticationService
  ) {
    this.url = this.authenticationService.getWebserviceURL("geofence");
    this.base_url = this.authenticationService.getWebserviceURL("");
    this.timezoneIana = this.authenticationService.getTimeZoneIana();
  }

  getPagingUrl() {
    return this.url + "Paging";
  }

  getGeofences(): Observable<Geofence[]> {
    console.log("getting geofences from service");
    return this.http.get(this.url, { headers: this.authenticationService.headers }).pipe(
      map((data) => {
        return this.parseResponse(data);
      }),
      catchError(this.handleError)
    );
  }

  getGeofencesByAccount(accountId): Observable<Geofence[]> {
    console.log("getting geofences from service");
    return this.http
      .get(this.base_url + "Account/" + accountId + "/Geofences", { headers: this.authenticationService.headers })
      .pipe(
        map((data) => {
          return this.parseResponse(data);
        }),
        catchError(this.handleError)
      );
  }

  getGeofenceById(id: number): Observable<Geofence> {
    console.log("Fetch geofence by id " + id);
    return this.http.get(this.url + id, { headers: this.authenticationService.headers }).pipe(
      map((data) => {
        return this.parseReponseDetails(data);
      }),
      catchError(this.handleError)
    );
  }

  getGeofenceData(geofences: any[]): Observable<Geofence[]> {
    return this.http
      .post<Geofence[]>(
        this.url + "List",
        geofences.map((d) => +d),
        { headers: this.authenticationService.headers }
      )
      .pipe(catchError(this.handleError));
  }

  saveGeofence(geofence: Geofence): Observable<any> {
    console.log("save geofence");

    return this.http
      .post(this.url, geofence, { headers: this.authenticationService.headers })
      .pipe(catchError(this.handleError));
  }

  updateGeofence(geofence: Geofence): Observable<any> {
    console.log("update geofence");

    return this.http
      .put(this.url + geofence.id, geofence, { headers: this.authenticationService.headers })
      .pipe(catchError(this.handleError));
  }

  deleteGeofence(geofence: Geofence): Observable<any> {
    console.log("delete geofence");

    return this.http
      .delete(this.url + geofence.id, { headers: this.authenticationService.headers })
      .pipe(catchError(this.handleError));
  }

  addToGroup(geofenceId: number, geofenceGroups): Observable<any> {
    return this.http
      .post(
        this.url + geofenceId + "/AddToGroups",
        { geofenceId, geofenceGroups },
        { headers: this.authenticationService.headers }
      )
      .pipe(catchError(this.handleError));
  }

  removeFromGroups(geofenceId: any, object: object): Observable<any> {
    return this.http
      .post(this.url + geofenceId + "/RemoveFromGroups", object, { headers: this.authenticationService.headers })
      .pipe(catchError(this.handleError));
  }

  private handleError(error: Response) {
    return throwError(() => error);
  }

  parseResponse(json: any): Geofence[] {
    this.loggingService.log(this.constructor.name, "Retrieved " + json.length + " Geofences.");

    const ident = 1;
    const geofences: Geofence[] = [];

    json.forEach((item) => {
      const geofence = this.parseReponseDetails(item);
      geofences.push(geofence);
    });

    return geofences;
  }

  parseReponseDetails(item) {
    const geofence = new Geofence();
    geofence.id = item.id;
    geofence.name = item.name;
    geofence.color = item.color;
    geofence.referenceId = item.referenceId;
    geofence.isArchived = item.isArchived;
    geofence.geoJson = item.geoJson;
    geofence.radius = item.radius;
    geofence.routeProperty = item.routeProperty;
    geofence.latitude = item.latitude;
    geofence.longitude = item.longitude;
    geofence.companyName = item.companyName;
    geofence.accountId = item.accountId;
    geofence.created = item.timestamp !== undefined ? Moment.utc(item.timestamp)["tz"](this.timezoneIana) : undefined;
    geofence.isPersonal = item.userId != null;
    geofence.isPop = item.isPop;
    geofence.userId = item.userId;
    geofence.userName = item.userName;
    geofence.resellerId = item.resellerId;
    geofence.resellerDescription = item.resellerDescription;

    if (item.properties) {
      if (item.properties.custom) {
        geofence.properties.custom = [];

        item.properties.custom.forEach((row, index) => {
          geofence.properties.custom.push({ key: row.key, value: row.value });
        });
      }
    }
    return geofence;
  }

  // Helper funtions

  getShapeType(layer) {
    if (layer instanceof L.Circle) {
      return Form.Point;
    }
    if (layer instanceof L.Polyline && !(layer instanceof L.Polygon)) {
      return Form.LineString;
    }
    if (layer instanceof L.Polygon && !(layer instanceof L.Rectangle)) {
      return Form.Polygon;
    }
    if (layer instanceof L.Rectangle) {
      return Form.Polygon;
    }
    return Form.Unknown;
  }

  parseLayer(layer: any, geofence: any) {
    geofence.geoJson = JSON.stringify(layer.toGeoJSON());
    geofence.form = this.getShapeType(layer);

    if (layer instanceof L.Circle) {
      if (layer && layer.getRadius()) {
        geofence.radius = layer.getRadius();
      }
    } else if (!geofence.radius) {
      geofence.radius = 0;
    }

    const bounds = layer.getBounds();
    const latLng = bounds.getCenter();

    switch (geofence.form) {
      case Form.Polygon:
        const vertCnt1 = layer._latlngs[0].length;
        geofence.polygonIncludingRadius = "POLYGON((";

        for (let i = 0; i < vertCnt1; i++) {
          const punt1 = layer._latlngs[0][i];
          geofence.polygonIncludingRadius += punt1.lng + " " + punt1.lat + ",";
        }

        // add first point againt at the end to close the polygon
        const punt2 = layer._latlngs[0][0];
        geofence.polygonIncludingRadius += punt2.lng + " " + punt2.lat + "))";
        break;
      case Form.LineString:
        const vertCnt2 = 0;
        let punt = null;
        const vertCnt = layer._latlngs.length;
        geofence.polygonIncludingRadius = "LINESTRING(";

        for (let i = 0; i < vertCnt2; i++) {
          punt = layer._latlngs[i];
          geofence.polygonIncludingRadius += punt.lng + " " + punt.lat + (i === vertCnt2 - 1 ? "" : ",");
        }
        geofence.polygonIncludingRadius += ")";
        break;
      case Form.Point:
        const center = layer._latlng;
        geofence.polygonIncludingRadius = "POINT(" + center.lng + " " + center.lat + ")";
        break;
      default:
        break;
    }

    geofence.latitude = latLng.lat;
    geofence.longitude = latLng.lng;
  }
}
