<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12 flex header-content">
        <nav class="breadcrumb-container" aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a href="/#/"> <i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
                </li>
                <li class="breadcrumb-item">
                    <a href="/#/MaintenancesV2">{{'general.maintenance' | translate }}</a>
                </li>
                <li class="breadcrumb-item">
                    <a href="/#/MaintenancesV2/WorkOrders/Overview">{{'menu.workOrders' | translate }}</a>
                </li>
                <li class="breadcrumb-item active">{{'general.add' | translate }}</li>
            </ol>
        </nav>
    </div>
    <fh-maintenance-v2-navigation>
        <li class="active">
            <a href="/#/MaintenancesV2/WorkOrders/Add"
                ><i class="fas fa-fw fa-garage-car"></i>
                <div>{{ 'general.add' | translate }}</div>
            </a>
        </li>
    </fh-maintenance-v2-navigation>
</div>

<fh-permission-handler [permissionName]="permissionName">
    <div class="wrapper wrapper-content">
        <form #form="ngForm">
            <fh-maintenance-work-order-details
                [form]="form"
                [formMode]="formMode"
                [workOrder]="workOrder"
                (insert)="onInsert($event)"
                (showDeleteModal)="showDeleteModal($event)"
            >
            </fh-maintenance-work-order-details>
        </form>
    </div>
</fh-permission-handler>

<fh-confirmation-modal #deleteModal (onFinish)="deleteWorkOrderItem()">
    <div body class="modal-body">{{ "general.warningWorkOrderItemDelete" | translate }}</div>
</fh-confirmation-modal>
