import { ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import {
  CalendarEvent,
  CalendarEventTimesChangedEvent,
  CalendarView,
  DateFormatterParams,
  DAYS_OF_WEEK,
} from "angular-calendar";
import { FhChartService } from "app/services/charts/charts.service";
import { isSameDay, isSameMonth } from "date-fns";
import { Subject } from "rxjs";
import { formatDate } from "@angular/common";
import { roundAsNumber } from "app/common/globals";
import Highcharts from "highcharts";
import moment from "moment";
import { MaintenanceStatus } from "app/models/maintenancev2.model";
import { MaintenanceV2Service } from "app/services/maintenancev2/maintenanceV2.service";

@Component({
  providers: [FhChartService],
  selector: "fh-maintenance-v2-dashboard",
  templateUrl: "dashboard.template.html",
})
export class MaintenanceV2DashboardViewComponent implements OnInit, OnDestroy {
  Highcharts: typeof Highcharts = Highcharts;

  success;
  error;
  warning;

  loading = false;
  loadingMaintenance = false;
  loadingMaintenanceDue = false;
  chartDistanceCompare: any;
  chartCostPerType: any;
  chartCostPerMonth: Highcharts.Options;
  maintenanceStatus: MaintenanceStatus;
  selectedAccountId: null;
  selectedResellerId: any;

  constructor(
    private translateService: TranslateService,
    private chartService: FhChartService,
    private maintenanceService: MaintenanceV2Service,
    private cd: ChangeDetectorRef
  ) {}

  ngOnDestroy(): void {}

  resellerChanged(resellerId) {
    this.selectedAccountId = null;

    this.selectedResellerId = resellerId;
  }

  accountChanged(accountId) {
    this.selectedAccountId = accountId;

    this.error = null;
    this.warning = null;

    if (accountId) {
      this.fetchMaintenance();
    }
  }

  fetchMaintenance() {
    if (!this.selectedAccountId) {
      return;
    }

    this.loading = true;
    this.loadingMaintenance = true;
    this.loadingMaintenanceDue = true;

    this.maintenanceService.getMaintenanceStatusByAccountId(this.selectedAccountId).subscribe((result) => {
      this.maintenanceStatus = result;
      this.loadingMaintenance = false;
      this.cd.markForCheck();
    });

    this.maintenanceService.getMaintenanceDueByAccountId(this.selectedAccountId).subscribe((result) => {
      this.loadingMaintenanceDue = false;
      this.cd.markForCheck();
      this.fillCalendar(result);
    });

    this.maintenanceService.getMaintenanceHistoryByAccountId(this.selectedAccountId).subscribe((result) => {
      this.generateCharts(result);
    });
  }

  generateCharts(result) {
    // Chart cost per asset

    const maintenanceByAsset = [];

    const months = [];
    const monthsAsDate = [];
    const planned = [];
    const unplanned = [];

    for (let index = 0; index < 30; index++) {
      var newDate = moment()
        .add(-1 * index, "month")
        .toDate();

      var monthName = new Date(newDate).getFullYear() + " " + (new Date(newDate).getMonth() + 1);

      monthsAsDate.push(newDate);

      planned[monthName] = 0;
      unplanned[monthName] = 0;
    }

    const costPerType = [];

    result.forEach((element) => {
      if (!maintenanceByAsset[element.assetId]) {
        maintenanceByAsset[element.assetId] = {
          assetName: element.assetName,
          total: 0,
        };
      }
      maintenanceByAsset[element.assetId].total += element.totalCost;

      var monthName =
        new Date(element.serviceDate).getFullYear() + " " + (new Date(element.serviceDate).getMonth() + 1);

      if (planned[monthName] != undefined && unplanned[monthName] != undefined) {
        if (element.planned) {
          planned[monthName] += element.totalCost;
        } else {
          unplanned[monthName] += element.totalCost;
        }
      }

      if (!costPerType[element.serviceItemName]) {
        costPerType[element.serviceItemName] = 0;
      }
      costPerType[element.serviceItemName] += element.totalCost ?? 0;
    });

    const categoriesDistance = [];
    const theDataDistance = [];
    const theContentDistance = [];

    maintenanceByAsset.sort((a, b) => (a.total < b.total ? 1 : -1));
    maintenanceByAsset.forEach((device) => {
      categoriesDistance.push(device.assetName);
    });

    maintenanceByAsset.sort((a, b) => (a.total < b.total ? 1 : -1));
    maintenanceByAsset.forEach((device) => {
      categoriesDistance.push(device.assetName);
      theContentDistance.push({ y: roundAsNumber(device.total, 0), deviceId: device.deviceId });
    });

    theDataDistance.push({
      data: theContentDistance,
      type: "column",
      name: "Cost",
      turboThreshold: 5000,
    });

    this.chartDistanceCompare = this.chartService.generateUtilizationScoreChart(
      theDataDistance,
      categoriesDistance,
      null,
      ""
    );

    // Chart cost per type

    const dataViolationsPerType = [
      {
        name: this.translateService.instant("general.costPerType"),
        colorByPoint: true,
        minPointSize: 20,
        innerSize: "20%",
        zMin: 0,
        data: Object.keys(costPerType).map((key) => ({ name: key, y: roundAsNumber(costPerType[key], 0) })),
      },
    ];

    this.chartCostPerType = this.chartService.generateVarPieChartDevice(dataViolationsPerType, {}, null, true);

    const theCategories = [];

    const theDataPlanned = Object.values(planned).map((value, index) => ({
      x: monthsAsDate[index],
      y: roundAsNumber(value, 0),
    }));

    const theDataUnPlanned = Object.values(unplanned).map((value, index) => ({
      x: monthsAsDate[index],
      y: roundAsNumber(value, 0),
    }));

    const theChartData = [
      {
        name: this.translateService.instant("general.planned"),
        data: theDataPlanned,
        type: "area",
        dashStyle: "dash",
        fillOpacity: 0.5,
        opacity: 1,
        marker: {
          enabled: false,
          lineWidth: 1,
          symbol: "square",
        },
      },
      {
        name: this.translateService.instant("general.unplanned"),
        data: theDataUnPlanned,
        type: "area",
        dashStyle: "dash",
        fillOpacity: 0.5,
        opacity: 1,
        marker: {
          enabled: false,
          lineWidth: 1,
          symbol: "square",
        },
      },
    ];

    this.chartCostPerMonth = this.chartService.generateColumnChartDates(
      theChartData,
      {},
      null,
      undefined,
      "stacked",
      true
    );

    this.loading = false;
    this.cd.markForCheck();
  }

  ngOnInit() {
    // Maintenance status
  }

  // Calendar
  viewDate: Date = new Date();
  events: CalendarEvent[] = [];
  daysInWeek = 7;
  refresh = new Subject<void>();
  locale = "en";
  weekStartsOn: number = DAYS_OF_WEEK.MONDAY;
  weekendDays: number[] = [DAYS_OF_WEEK.FRIDAY, DAYS_OF_WEEK.SATURDAY];
  activeDayIsOpen = false;
  view: CalendarView = CalendarView.Month;

  CalendarView = CalendarView;
  calenderEditMode = false;

  fillCalendar(results) {
    this.events = [];

    results
      .filter((x) => x.expectedServiceDate != null)
      .forEach((item) => {
        this.events.push({
          title: item.assetName + ":" + item.serviceItemName,
          start: new Date(item.expectedServiceDate),
        });
      });

    this.cd.markForCheck();
    this.refresh.next();
  }

  eventClicked({ event }: { event: CalendarEvent }): void {
    console.log("Event clicked", event);
  }

  public dayViewHour({ date, locale }: DateFormatterParams): string {
    return formatDate(date, "HH:mm", locale);
  }

  public weekViewHour({ date, locale }: DateFormatterParams): string {
    return this.dayViewHour({ date, locale });
  }

  closeOpenMonthViewDay() {
    this.activeDayIsOpen = false;
  }

  setView(view: CalendarView) {
    this.view = view;
  }

  handleEvent(action: string, event: CalendarEvent): void {}

  eventTimesChanged({ event, newStart, newEnd }: CalendarEventTimesChangedEvent): void {
    this.events = this.events.map((iEvent) => {
      if (iEvent === event) {
        return {
          ...event,
          start: newStart,
          end: newEnd,
        };
      }
      return iEvent;
    });
    this.handleEvent("Dropped or resized", event);
  }

  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    if (isSameMonth(date, this.viewDate)) {
      if ((isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) || events.length === 0) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
      }
      this.viewDate = date;
    }
  }
}
