<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12 flex header-content">
        <nav class="breadcrumb-container" aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a href="/#/"> <i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
                </li>
                <li class="breadcrumb-item" *ngIf="device?.asset && device?.asset.accountId">
                    <a href="/#/AccountDetails/Index/{{device?.asset.accountId}}">{{device?.asset?.companyName}}</a>
                </li>
                <li class="breadcrumb-item">
                    <a href="/#/Devices/Overview?companyName={{device?.companyName}}"
                        >{{'general.devices' | translate }}</a
                    >
                </li>
                <li class="breadcrumb-item">
                    <a href="/#/DeviceDetails/Index/{{device?.id}}"
                        >{{device?.asset?.name ? device?.asset?.name : device?.name}}</a
                    >
                </li>
                <li class="breadcrumb-item active">{{'general.dashboard' | translate }}</li>
            </ol>
        </nav>

        <span class="fa-filter-container noselect" style="margin-right: 8px">
            <a
                style="margin-left: 2px"
                class="btn btn-info margin-fix d-none d-md-inline-flex"
                title="Help"
                href="https://help.360locate.com/knowledge-base/device-dashboard/"
                target="_blank"
                ><i class="fa fa-fa fa-book" style="margin-right: 6px"></i>
                <span class="d-none d-md-inline-flex">{{'general.needHelp' | translate }}</span></a
            >
        </span>
    </div>
    <fh-device-details-navigation [deviceId]="deviceId" [device]="device">
        <span class="top-car-icon" *ngIf="device?.asset?.icon" [innerHTML]="formatIconId(device?.asset?.icon)"></span>
    </fh-device-details-navigation>
    <div *ngIf="!showMapOnSide" class="col-lg-12" style="padding: 1px 0 0 0">
        <ngx-loading [show]="loadingGeofences"></ngx-loading>
        <fh-leaflet-map
            (mapReady)="onMapReady($event)"
            [device]="device"
            [locations]="locationData"
            [isSpinning]="isSpinning"
            [geofences]="geofences"
            [geofenceEnabled]="false"
            [allowBookmarks]="false"
            [height]="mapHeight"
        >
        </fh-leaflet-map>
    </div>
</div>

<fh-permission-handler [permissionName]="permissionName">
    <div class="wrapper wrapper-content">
        <div class="row">
            <div class="col-lg-12">
                <fh-error [error]="error" [warning]="warning" [success]="success"></fh-error>

                <div class="alert alert-warning shadow" *ngIf="showWarningMessage && device?.asset?.comment">
                    <p style="font-size: 15px"><strong>{{ 'general.warning' | translate }}</strong></p>
                    <p>{{device.asset.comment}}</p>
                </div>

                <div class="alert alert-warning shadow" *ngIf="violations.length > 0">
                    <i class="fas fa-fw fa-times float-right" style="cursor: pointer" (click)="clearViolations()"></i>
                    <p style="font-size: 15px">
                        <strong>{{ 'general.violations' | translate }} ({{violations.length}})</strong>
                    </p>
                    <p *ngFor="let violation of violations">{{violation}}</p>
                </div>
            </div>
        </div>

        <div class="row" data-equalizer="">
            <div class="col-lg-6">
                <form #deviceForm="ngForm">
                    <div class="ibox" style="position: relative">
                        <div class="ibox-title">
                            <h5><i class="fas fa-fw fa-microchip"></i> {{ 'general.device' | translate }}</h5>

                            <span class="float-right" style="margin-top: -5px">
                                <fh-share-link type="2" [entityId]="device?.id"></fh-share-link>
                            </span>
                        </div>
                        <fh-device-details [device]="device" [formMode]="formMode" [loading]="loading || saving">
                        </fh-device-details>
                        <fh-save-footer
                            [valid]="!deviceForm.invalid"
                            (onSave)="onSave()"
                            [allowEdit]="permissions['Devices_Edit']"
                            [allowDelete]="false"
                            (setFormMode)="setFormMode($event)"
                            (onDelete)="onDelete()"
                            [formMode]="formMode"
                        >
                            <span class="float-right">
                                <fh-task-dropdown
                                    (onFinishAction)="finishTask($event)"
                                    #taskDropdown
                                    [entityTypes]="tagType"
                                    [entity]="device"
                                ></fh-task-dropdown>
                            </span>
                        </fh-save-footer>
                    </div>
                </form>

                <form #assetForm="ngForm">
                    <div class="ibox" style="position: relative">
                        <div class="ibox-title">
                            <h5><i class="fas fa-fw fa-car-bus"></i> {{'general.asset' | translate }}</h5>
                        </div>
                        <fh-asset-details
                            [asset]="device.asset"
                            [formMode]="formModeAsset"
                            [loading]="loading || saving"
                        ></fh-asset-details>
                        <fh-save-footer
                            [valid]="!assetForm.invalid"
                            (onInsert)="onInsertAsset()"
                            (onSave)="onSaveAsset()"
                            (onDelete)="onArchiveAsset($event)"
                            [allowAdd]="((!device?.asset?.id || device?.asset?.assignmentEnd) && device?.accountId) && permissions['Assets_Add']"
                            [allowCancelOnInsert]="true"
                            [allowEdit]="(device?.asset?.id) && permissions['Assets_Edit']"
                            [allowDelete]="false"
                            (setFormMode)="setFormModeAsset($event)"
                            [formMode]="formModeAsset"
                        >
                            <span class="float-right">
                                <span *ngIf="(device && device?.asset && device?.asset?.id)">
                                    <ng-container *ngIf="!device.asset.assignmentEnd; else reactivate">
                                        <button
                                            class="btn btn-danger"
                                            (click)="showArchive(device.asset?.id);"
                                            *ngIf="permissions['Actions_Archive']"
                                            title="{{ 'general.archive' | translate }}"
                                        >
                                            <i class="fa fa-fw fa-file-archive"></i> {{'general.archive' | translate }}
                                        </button>
                                    </ng-container>
                                    <ng-template #reactivate>
                                        <button
                                            class="btn btn-primary"
                                            (click)="reactivateAsset(device.asset.id)"
                                            *ngIf="permissions['Actions_Archive']"
                                            title="{{ 'general.unArchiveDevice' | translate }}"
                                        >
                                            <i class="fa fa-fw fa-recycle"></i> {{'general.reactivate' | translate }}
                                        </button>
                                    </ng-template>
                                </span>
                            </span>
                        </fh-save-footer>
                    </div>
                </form>
            </div>
            <div class="col-lg-6">
                <div class="ibox" style="position: relative" *ngIf="showMapOnSide">
                    <ngx-loading [show]="loadingGeofences"></ngx-loading>
                    <fh-leaflet-map
                        (mapReady)="onMapReady($event)"
                        [device]="device"
                        [locations]="locationData"
                        [isSpinning]="isSpinning"
                        [borderRadius]="8"
                        [geofences]="geofences"
                        [geofenceEnabled]="false"
                        [allowBookmarks]="false"
                        [height]="mapHeight"
                    >
                    </fh-leaflet-map>
                </div>

                <div *ngIf="permissions['Dashcams_View'] && sources.length > 0" class="ibox">
                    <div class="ibox-title">
                        <h5><i class="fas fa-fw fa-video"></i> {{'general.dashcam' | translate }}</h5>
                    </div>
                    <div class="ibox-content slicker" style="min-height: 224px; position: relative">
                        <app-vjs-player #videoJs [options]="playerOptions"></app-vjs-player>
                    </div>
                </div>

                <div class="ibox">
                    <div class="ibox-title">
                        <span class="pull-left">
                            <h5><i class="fas fa-fw fa-satellite"></i> {{'general.deviceState' | translate }}</h5>
                        </span>
                        <span class="float-right" [tooltip]="'Show GPS items'">
                            <i
                                (click)="flipPosition()"
                                class="fa-duotone fa-lg fa-fw"
                                [ngClass]="showPosition ? 'fa-toggle-on' : 'fa-toggle-off'"
                            >
                            </i>
                        </span>
                        <span
                            style="padding-right: 7px"
                            class="float-right"
                            tooltip="Device communicated: {{ lastCommunication | amDateFormat: 'LL h:mm:ss A'
                        }} | {{ lastCommunication | amTimeAgo:true }} ago"
                        >
                            <span class="d-none d-md-inline-flex"
                                ><strong> {{ lastCommunication | amDateFormat: 'LLL' }}</strong></span
                            >
                        </span>
                    </div>

                    <!-- DeviceState -->
                    <div
                        class="ibox-content"
                        style="position: relative"
                        *ngIf="deviceState == null && !loadingLocation"
                    >
                        {{ ('general.noDeviceState') | translate }}
                    </div>
                    <div class="ibox-content" style="position: relative" *ngIf="deviceState == null && loadingLocation">
                        <ngx-loading [show]="loadingLocation"></ngx-loading>
                        {{ ('general.loadingDeviceState') | translate }}
                    </div>
                    <div class="ibox-content slick" style="position: relative" *ngIf="deviceState != null">
                        <ngx-loading [show]="loadingLocation"></ngx-loading>

                        <fh-device-state-display
                            style="width: 100%"
                            [deviceState]="deviceState"
                            [device]="device"
                            [loading]="loadingLocation"
                            [showPosition]="showPosition"
                            [sensorTemplates]="sensorTemplates"
                            [hasCanbus]="hasCanbus"
                        ></fh-device-state-display>
                    </div>
                </div>

                <!-- IO States -->
                <div class="ibox" *ngIf="!device.asset?.isAssetTracker">
                    <div class="ibox-title">
                        <span class="pull-left">
                            <h5><i class="fas fa-fw fa-random"></i> {{'general.ioStates' | translate }} &nbsp;</h5>
                            <span [tooltip]="'Some of your input ports are unmapped'" *ngIf="unmappedPorts">
                                <i style="color: #cb711d" cclass="fas fa-fw fa-exclamation-triangle"></i>
                            </span>
                        </span>
                        <span class="float-right" [tooltip]="'Show unmapped items'">
                            <i
                                (click)="flipUnMapped()"
                                class="fa-duotone fa-lg fa-fw"
                                [ngClass]="showUnMapped ? 'fa-toggle-on' : 'fa-toggle-off'"
                            >
                            </i>
                        </span>
                    </div>
                    <div
                        class="ibox-content"
                        style="position: relative"
                        *ngIf="deviceState == null && !loadingLocation"
                    >
                        {{ ('general.noDeviceState') | translate }}
                    </div>
                    <div class="ibox-content" style="position: relative" *ngIf="deviceState == null && loadingLocation">
                        <ngx-loading [show]="loadingLocation"></ngx-loading>
                        {{ ('general.loadingDeviceState') | translate }}
                    </div>
                    <div class="ibox-content slick" style="position: relative" *ngIf="deviceState != null">
                        <ngx-loading [show]="loadingLocation"></ngx-loading>

                        <fh-device-io-display
                            style="width: 100%"
                            [deviceState]="deviceState"
                            [device]="device"
                            [loading]="loadingLocation"
                            [showUnMapped]="showUnMapped"
                            [sensorTemplates]="sensorTemplates"
                            [hasCanbus]="hasCanbus"
                        ></fh-device-io-display>
                    </div>
                </div>

                <div class="ibox" *ngIf="deviceState != null && deviceState.virtualSensors?.length > 0">
                    <div class="ibox-title">
                        <span class="pull-left">
                            <h5>
                                <i class="fas fa-fw fa-sensor"></i> {{'general.virtualSensors' | translate }} &nbsp;
                            </h5>
                        </span>
                    </div>
                    <div class="ibox-content slick" style="position: relative" *ngIf="deviceState != null">
                        <div class="flex-container" [@enter]>
                            <div
                                class="col-6 col-lg-4"
                                style="padding: 0"
                                *ngFor="let virtualSensor of deviceState.virtualSensors"
                            >
                                <div class="kpi-item-small">
                                    <h3 class="margin-bottom font-bold">
                                        <i
                                            class="fas fa-fw fa-sensor"
                                            [ngStyle]="{'color': (virtualSensor.value == null || virtualSensor.value == '') ? '#ccc' : (virtualSensor.value == 'True' ? 'rgba(0,142,67,0.9)' : 'rgba(151,28,36,0.9)') }"
                                        ></i>
                                        {{('enums.virtualSensorOutputType.' + virtualSensor.sensorOutputType) |
                                        translate}}
                                    </h3>
                                    <div>{{ virtualSensor.name }}</div>
                                </div>
                            </div>
                            <div style="clear: both"></div>
                        </div>
                    </div>
                </div>

                <div
                    class="ibox"
                    *ngIf="permissions['FleetManagement_DeviceUtilization'] && showUtilization && !hideUtilization && !device.asset?.isAssetTracker"
                >
                    <div class="ibox-title">
                        <h5><i class="fas fa-fw fa-engine"></i> {{'general.utilization' | translate }}</h5>

                        <span
                            class="float-right form-group input-group hand"
                            style="
                                margin-bottom: 6px;
                                width: 220px;
                                margin-top: -6px;
                                margin-bottom: -6px;
                                background: none;
                            "
                        >
                            <input
                                placeholder="Daterangepicker"
                                style="margin-top: 0px; margin-bottom: -5px; background: none"
                                placeholder="Datepicker"
                                type="text"
                                [bsConfig]="dpConfig"
                                class="form-control hand"
                                bsDaterangepicker
                                (onHidden)="dateChanged('hidden')"
                                [(ngModel)]="daterangepickerModel"
                            />
                            <span class="input-group-calendar-datatables">
                                <i class="fa fa-calendar-alt"></i>
                            </span>
                        </span>
                    </div>
                    <div class="ibox-content" style="display: none">
                        <input
                            placeholder="Daterangepicker"
                            style="width: 100%"
                            placeholder="Datepicker"
                            type="text"
                            [bsConfig]="dpConfig"
                            class="form-control"
                            #dp="bsDaterangepicker"
                            bsDaterangepicker
                            (onHidden)="dateChanged('hidden')"
                            [(ngModel)]="daterangepickerModel"
                        />
                    </div>
                    <div class="ibox-content slick" style="position: relative">
                        <ngx-loading [show]="loadingUtilization"></ngx-loading>
                        <div style="padding: 10px" *ngIf="!deviceUtilization && loadingUtilization">
                            {{ ('general.loadingData') | translate }}
                        </div>
                        <div style="padding: 10px" *ngIf="!deviceUtilization && !loadingUtilization">
                            {{ ('general.noDataFound') | translate }}
                        </div>
                        <div *ngIf="deviceUtilization">
                            <div class="flex-container">
                                <div class="col-6 col-lg-4" style="padding: 0">
                                    <div class="kpi-item-small">
                                        <h3 class="margin-bottom font-bold">
                                            {{ deviceUtilization?.segmentsDistance ?
                                            deviceUtilization?.segmentsDistance.toLocaleString() : 0 }} {{translatedKm}}
                                        </h3>
                                        <div>{{'general.distance' | translate }}</div>
                                    </div>
                                </div>
                                <div class="col-6 col-lg-4" style="padding: 0">
                                    <div class="kpi-item-small">
                                        <h3 class="margin-bottom font-bold">
                                            {{ deviceUtilization?.workingHoursInSeconds ?
                                            actualRoundSeconds(deviceUtilization?.workingHoursInSeconds) : '-' }}
                                        </h3>
                                        <div>{{'general.duration' | translate }}</div>
                                    </div>
                                </div>
                                <div class="col-6 col-lg-4" style="padding: 0">
                                    <div class="kpi-item-small">
                                        <h3 class="margin-bottom font-bold">
                                            {{ deviceUtilization?.idlingDurationInSeconds ?
                                            actualRoundSeconds(deviceUtilization?.idlingDurationInSeconds) : '-' }}
                                        </h3>
                                        <div>{{'general.idling' | translate }}</div>
                                    </div>
                                </div>
                                <div class="col-6 col-lg-4" style="padding: 0">
                                    <div class="kpi-item-small">
                                        <h3 class="margin-bottom font-bold">
                                            <a
                                                class="secondary link_bolder"
                                                (click)="$event.stopPropagation();"
                                                href="/#/DeviceDetails/Trips/{{device.id}}"
                                            >
                                                {{ deviceUtilization?.tripCount ?
                                                deviceUtilization?.tripCount?.toLocaleString() : 0 }}</a
                                            >
                                        </h3>
                                        <div>{{'general.trips' | translate }}</div>
                                    </div>
                                </div>
                                <div class="col-6 col-lg-4" style="padding: 0">
                                    <div class="kpi-item-small">
                                        <h3 class="margin-bottom font-bold">
                                            {{actualRound(deviceUtilization?.utilization,2)?.toLocaleString() }} %
                                        </h3>
                                        <div>{{'general.utilization' | translate }}</div>
                                    </div>
                                </div>
                                <div class="col-6 col-lg-4" style="padding: 0">
                                    <div class="kpi-item-small">
                                        <h3 class="margin-bottom font-bold">
                                            {{ deviceUtilization?.maxSpeed ? actualRound(deviceUtilization?.maxSpeed,0)
                                            : '-' }} {{translatedKmh}}
                                        </h3>
                                        <div>{{'general.maxSpeed' | translate }}</div>
                                    </div>
                                </div>
                                <div
                                    *ngIf="permissions['FleetManagement_Equipment']"
                                    class="col-6 col-lg-4"
                                    style="padding: 0"
                                >
                                    <div class="kpi-item-small">
                                        <h3 class="margin-bottom font-bold">
                                            {{ deviceUtilization?.equipmentWorkDurationInSeconds ?
                                            actualRoundSeconds(deviceUtilization?.equipmentWorkDurationInSeconds) : '-'
                                            }}
                                        </h3>
                                        <div>{{'general.equipmentWorkDurationInSeconds' | translate }}</div>
                                    </div>
                                </div>
                                <div
                                    *ngIf="permissions['FleetManagement_Equipment']"
                                    class="col-6 col-lg-4"
                                    style="padding: 0"
                                >
                                    <div class="kpi-item-small">
                                        <h3 class="margin-bottom font-bold">
                                            {{ deviceUtilization?.equipmentIdlingDurationInSeconds ?
                                            actualRoundSeconds(deviceUtilization?.equipmentIdlingDurationInSeconds) :
                                            '-' }}
                                        </h3>
                                        <div>{{'general.equipmentIdlingDurationInSeconds' | translate }}</div>
                                    </div>
                                </div>
                                <div
                                    *ngIf="permissions['FleetManagement_Equipment']"
                                    class="col-6 col-lg-4"
                                    style="padding: 0"
                                >
                                    <div class="kpi-item-small">
                                        <h3 class="margin-bottom font-bold">
                                            {{ deviceUtilization?.equipmentCrossOverDurationInSeconds ?
                                            actualRoundSeconds(deviceUtilization?.equipmentCrossOverDurationInSeconds) :
                                            '-' }}
                                        </h3>
                                        <div>{{'general.equipmentCrossOverDurationInSeconds' | translate }}</div>
                                    </div>
                                </div>

                                <div class="col-6 col-lg-4" style="padding: 0">
                                    <div class="kpi-item-small">
                                        <h3 class="margin-bottom font-bold">
                                            {{ deviceUtilization?.fuelLostWhileIdling ?
                                            actualRound(deviceUtilization?.fuelLostWhileIdling, 1).toLocaleString() :
                                            '-' }} L
                                        </h3>
                                        <div>{{'general.fuelLostWhileIdling' | translate }}</div>
                                    </div>
                                </div>
                                <div class="col-6 col-lg-4" style="padding: 0">
                                    <div class="kpi-item-small">
                                        <h3 class="margin-bottom font-bold">
                                            {{ deviceUtilization?.fuelLostPercentage ?
                                            actualRound(deviceUtilization?.fuelLostPercentage, 1).toLocaleString() : '-'
                                            }}%
                                        </h3>
                                        <div>{{'general.fuelLostPercentage' | translate }}</div>
                                    </div>
                                </div>
                                <div class="col-6 col-lg-4" style="padding: 0">
                                    <div class="kpi-item-small">
                                        <h3 class="margin-bottom font-bold">
                                            {{ deviceUtilization?.fuelUsedTotal ?
                                            actualRound(deviceUtilization?.fuelUsedTotal, 1).toLocaleString() : '-' }} L
                                        </h3>
                                        <div>{{'general.fuelUsedTotal' | translate }}</div>
                                    </div>
                                </div>

                                <div class="col-6 col-lg-4" style="padding: 0">
                                    <div class="kpi-item-small">
                                        <h3 class="margin-bottom font-bold">
                                            {{ deviceUtilization?.totalEmissionCO2 ?
                                            actualRound(deviceUtilization?.totalEmissionCO2, 1).toLocaleString() : '-'
                                            }} kg
                                        </h3>
                                        <div>{{'general.totalEmissionCO2' | translate }}</div>
                                    </div>
                                </div>
                                <div class="col-6 col-lg-4" style="padding: 0">
                                    <div class="kpi-item-small">
                                        <h3 class="margin-bottom font-bold">
                                            {{ deviceUtilization?.totalEmissionParticlesLight ?
                                            actualRound(deviceUtilization?.totalEmissionParticlesLight,
                                            1).toLocaleString() : '-' }} / {{
                                            deviceUtilization?.totalEmissionParticlesHeavy ?
                                            actualRound(deviceUtilization?.totalEmissionParticlesHeavy,
                                            1).toLocaleString() : '-' }}
                                        </h3>
                                        <div>
                                            {{'general.totalEmissionParticlesLight' | translate }} /
                                            {{'general.totalEmissionParticlesHeavy' | translate }}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-6 col-lg-4" style="padding: 0">
                                    <div class="kpi-item-small">
                                        <h3 class="margin-bottom font-bold">
                                            {{ deviceUtilization?.totalConsumptionMixed ?
                                            actualRound(deviceUtilization?.totalConsumptionMixed, 1).toLocaleString() :
                                            '-' }} L
                                        </h3>
                                        <div>{{'general.totalConsumptionMixed' | translate }}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style="clear: both"></div>
                    </div>
                </div>
                <div class="ibox">
                    <div class="ibox-title">
                        <h5><i class="fas fa-fw fa-ruler"></i> {{'general.distanceperday' | translate }}</h5>
                    </div>
                    <div class="ibox-content slicker" style="min-height: 224px; position: relative">
                        <ngx-loading [show]="loadingCount"></ngx-loading>

                        <highcharts-chart
                            *ngIf="chartDistance"
                            [runOutsideAngular]="true"
                            style="display: block; width: 100%; height: 214px"
                            [Highcharts]="Highcharts"
                            [options]="chartDistance"
                        >
                        </highcharts-chart>
                    </div>
                </div>

                <div class="ibox" *ngIf="permissions['Platform_IsReseller']">
                    <div class="ibox-title">
                        <h5><i class="fa fa-fw fa-comment-alt-dots"></i> {{'general.locationCount' | translate }}</h5>
                    </div>
                    <div class="ibox-content slicker" style="min-height: 224px; position: relative">
                        <ngx-loading [show]="loadingCount"></ngx-loading>

                        <highcharts-chart
                            *ngIf="chartLocationCount"
                            [runOutsideAngular]="true"
                            style="display: block; width: 100%; height: 214px"
                            [Highcharts]="Highcharts"
                            [options]="chartLocationCount"
                        >
                        </highcharts-chart>
                    </div>
                </div>

                <div class="ibox" *ngIf="!hideTriggers && permissions['Triggers_View']">
                    <div class="ibox-title">
                        <h5><i class="fas fa-fw fa-bell-on"></i> {{'general.triggers' | translate }}</h5>
                    </div>
                    <div class="ibox-content slick" style="position: relative">
                        <ngx-loading [show]="loadingTriggers"></ngx-loading>

                        <table style="width: 100%" class="table table-fixed nowrap dtr-inline no-footer">
                            <thead>
                                <tr>
                                    <th>{{'general.name' | translate }}</th>
                                    <th
                                        class="elipses_overflow"
                                        style="width: 100px"
                                        title="{{'general.episodeCountLastWeek' | translate }}"
                                    >
                                        {{'general.episodeCountLastWeek' | translate }}
                                    </th>
                                    <th
                                        class="elipses_overflow"
                                        style="width: 100px"
                                        title="{{'general.notificationCountLastWeek' | translate }}"
                                    >
                                        {{'general.notificationCountLastWeek' | translate }}
                                    </th>
                                </tr>
                            </thead>
                            <tbody *ngIf="triggers.length == 0">
                                <tr>
                                    <td colspan="3">{{'general.noTriggersFound' | translate }}</td>
                                </tr>
                            </tbody>
                            <tbody>
                                <tr *ngFor="let trigger of triggers">
                                    <td class="elipses_overflow" title="{{trigger?.name}}">
                                        <a href="/#/Notifications/TriggerDetails/Index/{{trigger?.id}}">
                                            <span class="secondary link_bolder"
                                                ><i cclass="fas fa-fw fa-angle-right"></i
                                            ></span>
                                            {{trigger?.name}}</a
                                        >
                                    </td>
                                    <td class="elipses_overflow" style="width: 100px">{{trigger?.episodeCount}}</td>
                                    <td class="elipses_overflow" style="width: 100px">
                                        {{trigger?.notificationCount}}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <fh-group-overview
                    name="{{'general.assetGroups' | translate }}"
                    [loading]="loadingGroups"
                    [childId]="device.asset?.id"
                    (onFinish)="getDeviceInfo()"
                    [groups]="assetGroups"
                    [linkUrl]="assetGroupLink"
                    [deletePermission]="'Assets_Edit'"
                >
                    <span
                        groupAddSlot
                        (click)="confirmationModal.showModal(device.asset.id)"
                        *ngIf="permissions['AssetGroups_Edit']"
                        [title]="'general.addToGroup' | translate"
                        container="body"
                    >
                        <button class="btn btn-primary pull-left">
                            <i class="fa fa-fw fa-add"></i> {{ 'general.assign' | translate}}
                        </button>
                    </span>
                </fh-group-overview>

                <fh-schedule-assignments
                    [assetId]="device?.asset?.id"
                    [selectedAccountId]="device?.accountId"
                    [deletePermission]="'Assets_Edit'"
                >
                </fh-schedule-assignments>

                <fh-tagging [objectId]="device.id" [objectType]="tagType"> </fh-tagging>
            </div>
        </div>
    </div>
</fh-permission-handler>

<fh-confirmation-modal
    #confirmationModal
    (onFinish)="addToGroup($event, confirmationModal, groupInputVar)"
    [invalid]="!(groupInputVar.value?.length > 0) || groupInputVar.loadingAssetGroups"
>
    <h4 header class="modal-title pull-left">
        <i class="fa fa-fw fa-bolt"></i> {{'general.action' | translate }}: {{ 'general.addToGroup' | translate }}
    </h4>
    <form body #groupForm="ngForm" style="width: 100%; border-top: 0px">
        <div class="modal-body">
            <fh-asset-group-input
                #groupInputVar
                ngDefaultControl
                required
                [tableInline]="false"
                [selectedAccountId]="device.accountId"
                [hideSystemGroups]="true"
                [hideEmptyGroups]="false"
                [activeOnly]="true"
                [autoSelect]="false"
                [canAddNew]="true"
                placeholder="{{ 'general.chooseAssetGroups' | translate }}"
            >
            </fh-asset-group-input>
        </div>
    </form>
</fh-confirmation-modal>

<fh-confirmation-modal #archiveModal (onFinish)="onArchiveAsset($event)">
    <h4 header class="modal-title pull-left">
        <i class="fa fa-fw fa-bolt"></i> {{'general.action' | translate }}: {{ 'general.archiveDevice' | translate }}
    </h4>
    <fh-archiving-modal #archiveBody [device]="device" body></fh-archiving-modal>
</fh-confirmation-modal>
