<div class="ibox-content slick" style="position: relative">
    <ngx-loading [show]="loading"></ngx-loading>
    <table style="width: 100%" class="table table-fixed nowrap dtr-inline no-footer">
        <tbody *ngIf="!group">
            <tr>
                <td colspan="4">
                    {{ "general.noGroup" | translate }}
                </td>
            </tr>
        </tbody>
        <tbody *ngIf="group">
            <ng-container *ngIf="formMode != 2">
                <tr [hidden]="!permissions['Resellers_View']">
                    <td class="row_opening">
                        {{ "general.resellerDescription" | translate }}
                    </td>
                    <td class="padding-readonly-correct row_content">
                        <a class="secondary link_bolder" href="/#/ResellerDetails/Index/{{ group?.resellerId }}">{{
                            group?.resellerDescription
                        }}</a>
                    </td>
                </tr>
                <tr [hidden]="!permissions['Accounts_View']">
                    <td class="row_opening">
                        {{ "general.account" | translate }}
                    </td>
                    <td class="row_content padding-readonly-correct">
                        <a class="secondary link_bolder" href="/#/AccountDetails/Index/{{ group?.accountId }}">{{
                            group?.companyName
                        }}</a>
                    </td>
                </tr>
            </ng-container>
            <ng-container *ngIf="formMode == 2">
                <tr [hidden]="!permissions['Resellers_View'] && selectedResellerId">
                    <td class="row_opening">
                        {{ "general.resellerDescription" | translate }}
                        <span class="error" *ngIf="resellerVar.invalid">*</span>
                    </td>
                    <td class="row_content" style="position: relative; overflow: visible">
                        <fh-reseller-input
                            name="reseller"
                            #resellerVar="ngModel"
                            (onChanged)="resellerChanged($event)"
                            required
                            placeholder="{{ 'general.chooseReseller' | translate }}"
                            [tableInline]="true"
                            [(ngModel)]="selectedResellerId"
                        >
                        </fh-reseller-input>
                    </td>
                </tr>
                <tr [hidden]="!permissions['Accounts_View'] && group.accountId">
                    <td class="row_opening">
                        {{ "general.account" | translate }}
                        <span class="error" *ngIf="accountVar.invalid">*</span>
                    </td>
                    <td class="row_content" style="position: relative; overflow: visible">
                        <fh-account-input
                            name="account"
                            #accountVar="ngModel"
                            (onChanged)="accountChanged($event)"
                            required
                            [selectedResellerId]="selectedResellerId"
                            [tableInline]="true"
                            placeholder="{{
                                (selectedResellerId ? 'general.chooseAccount' : 'general.firstChooseReseller')
                                    | translate
                            }}"
                            [(ngModel)]="group.accountId"
                        >
                        </fh-account-input>
                    </td>
                </tr>
            </ng-container>
            <tr>
                <td class="row_opening">
                    {{ "general.name" | translate }}
                    <span class="error" *ngIf="name.invalid && formMode != 1">*</span>
                </td>
                <td class="row_content">
                    <input
                        type="text"
                        [readonly]="formMode == 1"
                        name="name"
                        class="form-control table-inline"
                        [(ngModel)]="group.groupName"
                        required
                        minlength="1"
                        #name="ngModel"
                    />
                </td>
            </tr>

            <tr *ngIf="formMode == 1">
                <td class="row_opening">
                    {{ "general.parent" | translate }}
                </td>
                <td class="padding-readonly-correct row_content">
                    <a
                        *ngIf="group['parentName']; else noParent"
                        class="secondary link_bolder"
                        href="/#/System/HierarchyGroupDetails/Index/{{ group['parentId'] }}"
                        >{{ group["parentName"] }}</a
                    >
                    <ng-template #noParent>
                        <span>-</span>
                    </ng-template>
                </td>
            </tr>
            <tr *ngIf="formMode != 1">
                <td class="row_opening">
                    {{ "general.parent" | translate }}
                    <span class="error" *ngIf="driverGroupParentVar?.invalid">*</span>
                </td>
                <td style="overflow: unset" class="padding-readonly-correct row_content">
                    <fh-hierarchyparent-selector
                        name="hierarchyGroupParent"
                        #driverGroupParentVar="ngModel"
                        [(ngModel)]="group['parentId']"
                        [accountId]="group.accountId"
                        [placeholder]="'general.chooseParent' | translate"
                    >
                    </fh-hierarchyparent-selector>
                </td>
            </tr>
        </tbody>
    </table>
</div>
