<div style="margin-left: -6px; margin-right: -6px">
    <nav class="navbar navbar-static-top" role="navigation" style="padding: 2px 0px; margin-bottom: 0; z-index: 2001">
        <div class="navbar-header">
            <a class="minimalize-styl-2 btn" (click)="toggleNavigation()">
                <i class="fa fa-bars"></i>
            </a>

            <!-- <input type="text" (blur)="searchTerm$.next('')" (keyup)="searchTerm$.next($event.target.value)" class="form-control" style="width:100%; height:40px;"
            placeholder="{{'topnav.searchforsomething' | translate }}" name="q"> -->
            <form
                *ngIf="includeSearch === true"
                class="navbar-form-custom float-right"
                role="search"
                style="padding: 4px"
            >
                <div class="input-group search-background">
                    <input
                        [typeahead]="autoComplete$"
                        [(ngModel)]="asyncSelected"
                        [ngModelOptions]="{standalone: true}"
                        [typeaheadMinLength]="2"
                        (typeaheadLoading)="changeTypeaheadLoading($event)"
                        (typeaheadOnSelect)="typeaheadOnSelect($event)"
                        [typeaheadWaitMs]="1000"
                        typeaheadGroupField="groupname"
                        [typeaheadOptionsLimit]="50"
                        [typeaheadScrollable]="true"
                        [typeaheadOptionsInScrollableView]="10"
                        typeaheadOptionField="name"
                        placeholder="{{ permissions['Platform_IsReseller'] || isImpersonated ? ('topnav.searchforsomethingAdmin' | translate) : ('topnav.searchforsomething' | translate)}}"
                        class="form-control typeahead"
                    />

                    <div class="input-group-btn" style="margin-top: 5px">
                        <button
                            class="btn btn-default"
                            style="border: none; background: none; color: #fff"
                            type="submit"
                        >
                            <span *ngIf="typeaheadLoading">
                                <i class="fa fa-spinner fa-spin fa-hourglass-start"></i>
                            </span>
                            <span *ngIf="!typeaheadLoading">
                                <i class="fa fa-search"></i>
                            </span>
                        </button>
                    </div>
                </div>
            </form>
        </div>

        <ul class="nav noselect navbar-top-links navbar-right">
            <!-- <li *ngIf="permissions['Platform_IsReseller'] && !activeRoute('Overview') ">
        <a [routerLink]="['/Overview']" aria-expanded="true" aria-disabled="true">
          <i class="fas fa-fw fa-map" title="{{ 'menu.overview' | translate }}"></i>
        </a>
      </li> -->

            <!-- <li *ngIf="permissions['Platform_IsReseller'] && activeRoute('Overview') ">
        <a [routerLink]="['/Home']" aria-expanded="true" aria-disabled="true">
          <i class="fas fa-fw fa-th-large" title="{{ 'menu.overview' | translate }}"></i>
        </a>
      </li> -->

            <!-- SignalrConnector -->
            <li class="noselect d-none d-md-inline-flex" dropdown fh-signal-r></li>

            <!-- Notifications -->
            <li class="noselect d-none d-md-inline-flex" dropdown fh-signalr-notifications></li>

            <!-- Feature flags -->
            <li class="noselect d-none d-md-inline-flex" dropdown *ngIf="featureFlags?.length > 0">
                <a aria-expanded="false" role="button" dropdownToggle>
                    <i class="fa fa-flag"></i>
                </a>

                <ul
                    id="notifications-tray"
                    role="menu"
                    *dropdownMenu
                    class="dropdown-menu dropdown-menu-fix dropdown-menu-right notifications-tray"
                >
                    <li *ngFor="let featureFlag of featureFlags">
                        <a>
                            <i class="fa fa-fw fa-flag" style="margin-right: 4px"></i>
                            <span>{{ featureFlag }}</span>
                        </a>
                    </li>
                </ul>
            </li>

            <!-- Dropdown menu -->
            <li class="noselect" dropdown>
                <a aria-expanded="false" role="button" dropdownToggle>
                    <ng-container *ngIf="isImpersonated; else normalUser">
                        <i class="d-md-none d-lg-none d-xl-none fa fa-user-secret" style="color: rgba(255, 177, 177, 1)"></i>
                        <span class="d-none d-md-inline-flex" style="color: rgba(255, 177, 177, 1)">
                            <i class="fa fa-user-secret"></i> {{id}}
                        </span>
                    </ng-container>
                    <ng-template #normalUser>
                        <i class="d-lg-none d-xl-none fa fa-user"></i>
                        <span class="d-none d-md-inline-flex"> {{id}}</span>
                    </ng-template>
                    <span class="d-none d-md-inline-flex caret"></span>
                </a>
                <ul role="menu" *dropdownMenu class="dropdown-menu dropdown-menu-fix dropdown-menu-right">
                    <li *ngIf="isImpersonated" style="background-color: rgba(146, 0, 0, 0.05)">
                        <a (click)="stopImpersonation()">
                            <i class="fas fa-fw fa-user-secret"></i> {{'general.stopImpersonation' | translate }}</a
                        >
                    </li>
                    <li>
                        <a href="/#/Profile"> <i class="fas fa-fw fa-user"></i> {{'general.profile' | translate }}</a>
                    </li>
                    <li *ngIf="permissions['Platform_IsReseller'] || permissions['Platform_IsFleetManager']">
                        <a href="/#/Shared"> <i class="fas fa-fw fa-podcast"></i> {{'general.shared' | translate }}</a>
                    </li>
                    <li>
                        <a href="/#/ShareTokens">
                            <i class="fas fa-fw fa-share-nodes"></i> {{'general.shareTokens' | translate }}</a
                        >
                    </li>
                    <li>
                        <a href="/#/Tokens"> <i class="fas fa-fw fa-key"></i> {{'general.tokens' | translate }}</a>
                    </li>
                    <li role="menuitem">
                        <a aria-expanded="false" role="button" class="dropdown-toggle" (click)="submenu($event)">
                            <i class="fas fa-fw fa-th-large"></i> {{'general.modules' | translate }}
                            <span class="caret" style="margin-right: 7px; margin-top: 10px; float: right"></span>
                        </a>
                        <ul
                            role="menu"
                            class="dropdown-menu dropdown-submenu dropdown-menu-fix"
                            style="right: auto; left: -210px; width: 210px; top: 0"
                        >
                            <li class="module_link" *ngIf="permissions['Modules_HasFleetOverview']">
                                <a target="_blank" [href]="replaceLinks('fleetoverview')">
                                    <i class="fas fa-fw fa-map"></i> {{'general.fleetOverview' | translate }}</a
                                >
                            </li>
                            <li class="module_link" *ngIf="permissions['Modules_HasReporting']">
                                <a target="_blank" [href]="replaceLinks('reporting')">
                                    <i class="fas fa-fw fa-book"></i> {{'general.reports' | translate }}</a
                                >
                            </li>
                            <li class="module_link" *ngIf="permissions['Modules_HasSetup']">
                                <a target="_blank" [href]="replaceLinks('setup')">
                                    <i class="fas fa-fw fa-cog"></i> {{'general.setup' | translate }}</a
                                >
                            </li>
                            <li class="module_link" *ngIf="permissions['Modules_HasMileageRegistration']">
                                <a target="_blank" [href]="replaceLinks('trips')">
                                    <i class="fas fa-fw fa-road"></i> {{'general.trips' | translate }}</a
                                >
                            </li>
                            <li class="module_link" *ngIf="permissions['Modules_HasMaintenance']">
                                <a target="_blank" [href]="replaceLinks('maintenance')">
                                    <i class="fas fa-fw fa-wrench"></i> {{'general.maintenance' | translate }}</a
                                >
                            </li>
                            <li class="module_link" *ngIf="permissions['Modules_HasPrivateFleet']">
                                <a target="_blank" [href]="replaceLinks('privatefleet')">
                                    <i class="fas fa-fw fa-sign-out"></i> {{'general.privateFleet' | translate }}</a
                                >
                            </li>
                        </ul>
                    </li>
                    <li role="menuitem">
                        <a aria-expanded="false" role="button" class="dropdown-toggle" (click)="submenu($event)">
                            <i class="fa fa-fw fa-question"></i> {{'general.help' | translate }}
                            <span class="caret" style="margin-right: 7px; margin-top: 10px; float: right"></span>
                        </a>
                        <ul
                            role="menu"
                            class="dropdown-menu dropdown-submenu dropdown-menu-fix"
                            style="right: auto; left: -210px; width: 210px; top: 0"
                        >
                            <!-- <li>
                <a href="/#/Help">
                  <i class="fas fa-fw fa-question"></i> {{'general.help' | translate }}</a>
              </li> -->
                            <li>
                                <a
                                    href="https://help.360locate.com/index.php/article-categories/fleet-management/"
                                    target="_blank"
                                >
                                    <i class="fas fa-fw fa-question"></i> {{'general.help' | translate }}</a
                                >
                            </li>
                            <li>
                                <a href="/#/About">
                                    <i class="fas fa-fw fa-info"></i> {{'general.about' | translate }}</a
                                >
                            </li>
                            <li>
                                <a href="/#/ChangeLog">
                                    <i class="fas fa-fw fa-pencil-ruler"></i> {{'general.changeLog' | translate }}</a
                                >
                            </li>
                        </ul>
                    </li>
                    <li>
                        <a href="#" (click)="logout()">
                            <i class="fas fa-fw fa-sign-out-alt"></i> {{'general.logout' | translate }}</a
                        >
                    </li>
                </ul>
            </li>
        </ul>
    </nav>
</div>
